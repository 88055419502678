import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Grid from '@mui/material/Grid';
import { RenderPopper } from '../../../utils/utils';
import {
  GridWrapper, InfoText, ItemTypo, ItemTypoBox
} from './filterPopper.styles';

const filterPopper = ({
  columns, filterButton, handleOptionToggle,
  handleTriggerButtonClick, selectedOptionFilter
}) => {
  const open = Boolean(filterButton);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <RenderPopper
      open={open}
      anchorEl={filterButton}
      onClickAway={() => handleTriggerButtonClick()}
      popperPlacement="bottom-left"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
      style={{
        position: 'absolute', top: '20px !important', right: '0px', transformOrigin: 'bottom'
      }}
    >
      <GridWrapper container>
        <InfoText>Filter by columns</InfoText>
        {
          columns?.map((column) => (
            <ItemTypoBox onClick={() => handleOptionToggle(column)} item container direction="row" alignItems="center">
              <Grid item xs={1}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ color: '#FCED50' }}
                  checked={!selectedOptionFilter.get(column)}
                />
              </Grid>
              <ItemTypo item xs={11}>
                {column}
              </ItemTypo>
            </ItemTypoBox>
          ))
        }
      </GridWrapper>
    </RenderPopper>
  );
};

filterPopper.propTypes = {
  filterButton: PropTypes.instanceOf(Object),
  handleTriggerButtonClick: PropTypes.func.isRequired,
  handleOptionToggle: PropTypes.func.isRequired,
};

filterPopper.defaultProps = {
  filterButton: {},
};

export default filterPopper;
