import { Typography, InputBase, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #606060;
  line-height: 3;
  width: 100%;
  height: max-content;

  & .MuiInputBase-input {
    padding: ${({ cSize }) => (cSize === 'lg' ? '18px' : '12px')};
    line-height: normal;
  }

  &:active,
  &:focus,
  &:focus-within {
    border: 0.5px solid #303030;
  }
`;

export const FormSelectStyled = styled(Select)`
  border: 0.5px solid #d8d8d8;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #606060;
  line-height: 3;
  width: 100%;
  height: 3rem;
  padding: 0 15px !important;
`;

export const Label = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: #303030;
  opacity: 0.5;
  margin-bottom: 0.2rem;
`;
