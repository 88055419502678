import { gql } from '@apollo/client';

export const CUSTOMER_QUERY = gql`
  query customer($id: Int){
    customer(id: $id){
      id
      name
      dateCreated
      meta
      contacts
      loyaltyPoint
      storeCredit
      createdBy{
        username
      }
      business{
        id
      }
  }
  }
`;

export const FIND_CUSTOMERS_QUERY = gql`
  query customers(
    $pageCount: Int, $pageNumber: Int, $search: String
  ){
    findCustomer(
      pageCount: $pageCount
      pageNumber: $pageNumber
      name: $search
    ){
        id
        name
        meta
        dateCreated
        contacts
        loyaltyPoint
        storeCredit
        business {
            id
        }
      }
    businessTotalNumber
  }
`;

export const ALL_CUSTOMERS_QUERY = gql`
  query allSuppliers($onlyBusinessSuppliers: Boolean, $onlyProductVendorSuppliers: Boolean){
    allSuppliers(onlyBusinessSuppliers: $onlyBusinessSuppliers, onlyProductVendorSuppliers: $onlyProductVendorSuppliers){
      id
      name
      paymentTerm
    }
    customerTotalNumber
  }
`;
