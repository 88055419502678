
import { v4 as uuid } from 'uuid';

export const TabSessionIdempotentKey = String(uuid());

export function clearLocalStorageIdempotentEntries(matchKey) {
    const matchedEntries = [];
    for (let i = 0, len = localStorage.length; i < len; i++) {
        const key = localStorage.key(i);
        if (key.includes(matchKey)) {
            matchedEntries.push(key);
        }
    }
    for (const entryKey of matchedEntries) {
        localStorage.removeItem(entryKey);
    }
}
