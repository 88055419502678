import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

export const SUPPLIER_QUERY = gql`
  query supplier($id: Int){
    supplier(id: $id){
      id
      name
      logo
      dateCreated
      meta
      contacts
      business{
        id
      }
      settings
      supplierorderSet {
        id
        total
        productCount
        orderproductSet {
          unitCost
          quantityRecieved
        }
        order {
          id
          business {
            id
            name
          }
          businessUser {
            id
            role {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const SUPPLIER_PRODUCTS_QUERY = gql`
    ${PRODUCT_VERSIONS}
  query supplier(
    $supplierId: Int!
    $pageCount: Int
    $pageNumber: Int
    $productName: String
  ){
    supplierProducts(
      supplierId: $supplierId
      pageCount: $pageCount
      pageNumber: $pageNumber
      productName: $productName
    ) {
      id
      brandName
      genericName
      meta
      ...productVersions
    }
    supplierProductsTotalNumber
  }
`;

export const FIND_SUPPLIERS_QUERY = gql`
  query suppliers(
    $pageCount: Int, $pageNumber: Int, $search: String, $onlyBusinessSuppliers: Boolean
  ){
    findSupplier(
      pageCount: $pageCount
      pageNumber: $pageNumber
      name: $search
      onlyBusinessSuppliers: $onlyBusinessSuppliers
    ){
        id
        logo
        name
        supplierProductsCount
        paymentTerm
        meta
        supplierProductsValue
        providerPromo
        contacts
      }
    supplierTotalNumber
  }
`;

export const ALL_SUPPLIERS_QUERY = gql`
  query allSuppliers(
    $onlyBusinessSuppliers: Boolean, $onlyProductVendorSuppliers: Boolean
  ){
    allSuppliers(
      onlyBusinessSuppliers: $onlyBusinessSuppliers,
      onlyProductVendorSuppliers: $onlyProductVendorSuppliers
    ){
      id
      name
      paymentTerm
      providerPromo
    }
    supplierTotalNumber
  }
`;

export const BUSINESS_SUPPLIERS_QUERY = gql`
  query businessSuppliers {
    businessSuppliers {
      id
      name
      paymentTerm
      providerPromo
    }
  }
`;

export const SUPPLIER_ORDER_PERCENTAGE_QUERY = gql`
  query supplierOrderPercentage($dateFrom: String, $dateTo: String){
    supplierOrderPercentage(dateFrom: $dateFrom, dateTo: $dateTo){
      total,
      suppliers {
        name,
        total
      }
    }
  }
`;

export const SUPPLIER_FULFILMENT_RATE_QUERY = gql`
  query supplierFulfilmentRate($dateFrom: String, $dateTo: String){
    supplierFulfilmentRate(dateFrom: $dateFrom, dateTo: $dateTo){
      suppliers {
        name,
        received,
        ordered
      }
    }
  }
`;

export const SUPPLIER_ORDER_PAYMENT_QUERY = gql`
  query supplierPayment($supplierId: String!) {
    supplierPayment(supplierId: $supplierId) {
      reference,
      amount,
      dateCreated,
      meta,
      orderProducts
    }
  }
`;

export const SUPPLIER_STATEMENT_QUERY = gql`
  query supplierStatement(
    $pageCount: Int
    $pageNumber: Int
  ){
    supplierStatement(
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
      id,
      dateCreated,
      name,
      orderAmount,
      paidAmount,
      accountNumber
    }
    deliveryLocationTotalNumber
  }
`;
