import React, { useState } from 'react';
import {
  Paper, TableRow, TableBody, Grid, useMediaQuery, Box
} from '@mui/material';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import {
  TableContainerWrapper, TableComponent, TableHeader,
  MainTableHead, FiltersGrid, CustomButton, TableTitle, MobileContainer, MobileFiltersGrid, FooterGrid, HeaderContainer
} from './topSellingProduct.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import MobileReturnRow from './mobileReturnRow';
import TablePagination from '../../shared/tablePagination';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import ActionButton from '../actionButton';
import { ALL_TOP_SELLING_PRODUCTS } from '../../../queries/dashboard';
import { addHyphen } from '../../shared/methods';

const headers = [
  'Product Name', 'Unit Price', 'Pack Size', 'Total Value', 'Qty in Stock', 'Qty Sold in Pcks'
];

const TopSellingProduct = () => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [active, setActive] = useState('count');
  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} data-testid={`mp-uat-topSelling-${addHyphen(header)}`}>{header}</TableHeader>
  ));

  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');

  const [dateFilter, setDateFilter] = useState({
    dateFrom: startDate,
    dateTo: endDate
  });
  const [, setFilterBy] = useState('');

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom,
      dateTo
    });
  };

  const sortHandler = (val, setOpen) => {
    setFilterBy(val);
    setOpen(false);
  };

  const handleSetValue = () => {
    setActive('value');
  };

  const handleSetCount = () => {
    setActive('count');
  };
  const { dateFrom: newDateFrom, dateTo: newDateTo } = dateFilter;

  const variables = {
    pageCount,
    pageNumber,
    dateFrom: newDateFrom,
    dateTo: newDateTo,
    filterBy: active
  };

  const {
    loading, error, data
  } = useQuery(ALL_TOP_SELLING_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const viewPort = useMediaQuery('(min-width:992px)');
  if (error) return <div>{error.message}</div>;
  const {
    allTopSellingProducts = [], allTopSellingProductsTotalNumber = 0
  } = data || {};

  const filterGrid = () => (
    <>
      <CustomButton
        onClick={handleSetValue}
        active={(active === 'value') && viewPort}
      >
        By Value
      </CustomButton>
      <CustomButton
        onClick={handleSetCount}
        active={active === 'count' && viewPort}
      >
        By Count
      </CustomButton>
    </>
  );

  const pagination = () => (
    <FooterGrid item container>

      <TablePagination
        total={allTopSellingProductsTotalNumber}
        pageCount={pageCount}
        setPageCount={setPageCount}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </FooterGrid>
  );

  return viewPort ? (
    <TableContainerWrapper component={Paper}>
      <HeaderContainer item container xs={12}>
        <TableTitle xs={6}>Top Selling Products</TableTitle>

        <FiltersGrid xs={6} container item>
          <Grid>
            <CustomDateRanges
              handleSubmit={handleSubmit}
              xs={12}
              styles={{
                width: '100%', border: '1px solid #bababa', borderRadius: '7px'
              }}
            />
          </Grid>
          <Box style={{ marginLeft: '1rem' }} fontSize={16} color="inherit">
            <ActionButton sortHandler={sortHandler} />
          </Box>
        </FiltersGrid>

      </HeaderContainer>
      {loading ? <ProductsTableLoader /> : (
        <TableComponent aria-label="affiliates table" data-testid="mp-uat-topSelling">
          <MainTableHead>
            <TableRow>
              {returnHeaders()}
            </TableRow>
          </MainTableHead>
          <TableBody>
            {allTopSellingProducts.map((product, index) => (
              <ReturnRow
                key={product.totalSales}
                row={product}
                index={index}
              />
            ))}
          </TableBody>
        </TableComponent>
      )}
      {pagination()}
    </TableContainerWrapper>
  ) : (
    <MobileContainer data-testid="mobile-row">
      <Grid item xs={12}>
        <TableTitle>Top Selling Products</TableTitle>
        <MobileFiltersGrid container item>
          {filterGrid()}
        </MobileFiltersGrid>

      </Grid>
      {loading ? <ProductsTableLoader /> : (
        allTopSellingProducts.map((business) => (
          <MobileReturnRow
            key={business.id}
            row={business}
          />
        ))
      )}
      {pagination()}
    </MobileContainer>
  );
};

TopSellingProduct.propTypes = {
};

TopSellingProduct.defaultProps = {
};

export default TopSellingProduct;
