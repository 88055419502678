import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow, TableCell, IconButton, Collapse, Box, Table,
  TableHead, TableBody, useMediaQuery
} from '@mui/material';
import ArrowDownIcon from '../../../../assets/images/supplier/Expand Icon.png';
import ArrowUpIcon from '../../../../assets/images/supplier/Collapse Icon .png';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  ProductImage, BodyCell, BodyCell2, HeaderCell, MainTableRow, ButtonImage,
  CustomTableHead, CustomTableBody, TableRowHead, TableRowBody,
  ProductImageContainer
} from './returnRow.styles';

const ReturnRow = ({ row }) => {
  const [open, setOpen] = useState(false);

  const isMedium = useMediaQuery('(min-width:992px)');

  return (
    <>
      <MainTableRow>
        <BodyCell />
        <BodyCell align="right">
          <ProductImageContainer>
            <ProductImage alt={row.brandName} src={row.image} />
          </ProductImageContainer>
        </BodyCell>
        <BodyCell component="th" scope="row">
          {row.brandName}
        </BodyCell>
        <BodyCell>
          {isMedium ? (open ? '' : row.packSize || 'None') : ''}
        </BodyCell>
        <BodyCell>
          {isMedium ? (open ? '' : currencyFormatter(row.resolvedPriceInUseValue)) : ''}
        </BodyCell>
        <BodyCell>
          {isMedium ? (open ? '' : currencyFormatter(row.marketRrp)) : ''}
        </BodyCell>
        <BodyCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open
              ? <ButtonImage alt="close" src={ArrowUpIcon} />
              : <ButtonImage alt="collapse" src={ArrowDownIcon} />}
          </IconButton>
        </BodyCell>
        <BodyCell />
      </MainTableRow>
      <TableRow style={{ background: '#F4F4F4' }}>
        <TableCell style={{ padding: 0, borderBottom: 0 }} />
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRowHead>
                    <HeaderCell>Generic Name</HeaderCell>
                    <HeaderCell>Pack Size</HeaderCell>
                    <HeaderCell>Manufacturer</HeaderCell>
                    <HeaderCell>Qty/Ctn</HeaderCell>
                    <HeaderCell>Item Price</HeaderCell>
                    <HeaderCell>Mkt RPP*</HeaderCell>
                  </TableRowHead>
                </TableHead>
                <TableBody>
                  <TableRowBody key={row.id}>
                    <BodyCell2 component="th" scope="row">
                      {row.genericName || 'No Generic Name'}
                    </BodyCell2>
                    <BodyCell2>{row.packSize || 'None'}</BodyCell2>
                    <BodyCell2>{row.manufacturer}</BodyCell2>
                    <BodyCell2>{row.qpc}</BodyCell2>
                    <BodyCell2>{currencyFormatter(row.resolvedPriceInUseValue)}</BodyCell2>
                    <BodyCell2>{currencyFormatter(row.marketRrp)}</BodyCell2>
                  </TableRowBody>
                </TableBody>
                <CustomTableHead>
                  <TableRow>
                    <HeaderCell>Qty/Ctn</HeaderCell>
                    <HeaderCell>Item Price</HeaderCell>
                    <HeaderCell>Mkt RPP*</HeaderCell>
                  </TableRow>
                </CustomTableHead>
                <CustomTableBody>
                  <TableRow key={row.id}>
                    <BodyCell2>{row.qpc}</BodyCell2>
                    <BodyCell2>{currencyFormatter(row.resolvedPriceInUseValue)}</BodyCell2>
                    <BodyCell2>{currencyFormatter(row.marketRrp)}</BodyCell2>
                  </TableRow>
                </CustomTableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell style={{ padding: 0, borderBottom: 0, width: '4%' }} />
      </TableRow>
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
