import BusinessActionTypes from './businessTypes';

const businessReducer = (business, action) => {
  switch (action.type) {
    case BusinessActionTypes.UPDATE_STATE_PROPERTY:
      return {
        ...business,
        ...action.payload
      };
    case BusinessActionTypes.ADD_BUSINESS:
      return {
        ...business,
        businessId: action.payload
      };
    case BusinessActionTypes.UPDATE_DASHBOARD_STATE:
      return {
        ...business,
        dashboardState: { ...business.dashboardState, ...action.payload }
      };
    case BusinessActionTypes.ADD_BUSINESS_USER:
      return {
        ...business,
        businessUser: action.payload
      };
    default:
      return business;
  }
};

export default businessReducer;
