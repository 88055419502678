import { gql } from '@apollo/client';

export const CREATE_LOAN_MUTATION = gql`
  mutation createLoan(
    $businessName: String! $applicantName: String! $email: String
    $phoneNumber: String! $address: String! $cartAmount: Float!
    $provider: String $city: String! $state: String $country: String!
  ) {
    createLoan(
      businessName: $businessName
      applicantName: $applicantName
      email: $email
      phoneNumber: $phoneNumber
      addressLineOne: $address
      cartAmount: $cartAmount
      provider: $provider
      city: $city
      state: $state
      country: $country
    ){
      message
    }
  }
`;
