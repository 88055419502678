import {
  Grid,
  Typography,
  MenuItem, Button, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 1.5rem 0.9rem 1rem;
  font-size: 15px;
  padding: 0px;
  width: 16rem;
  height: 3.063rem;
  margin-bottom: .5rem;
  &:hover {
    background-color: #F3F9FF;
  }
`;

export const ButtonEllipsis = styled(Button)`
  padding: 0;
  margin-left: -10px;
  background: none !important;
`;

export const GridContainer = styled(Button)`
    padding: 15px 25px;
    width: 20rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 16rem;
      height: 3.063rem;
      margin-bottom: .5rem;
    }
    &:hover {
      background-color: #F3F9FF;
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 2rem;
`;

export const ItemWrapper = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
`;
