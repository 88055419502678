import { Button, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 15px;
`;

export const ButtonDarkStyled = styled(Button)`
  width: 170px;
  height: 45px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 12px;

  disabled:hover {
    cursor:not-allowed
 }
`;
