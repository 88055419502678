import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import {
  RoleGridContainer,
} from './rolesContainer.styles';
import { useStateValue } from '../../providers/stateProvider';
import Roles from './roles/roles';

const RolesContainer = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  return (
    <MainContent>
      <RoleGridContainer container>
        <Grid item container spacing={2}>
          <Grid item container xs={12}>
            <Roles />
          </Grid>
        </Grid>
      </RoleGridContainer>
    </MainContent>
  );
};

export default RolesContainer;
