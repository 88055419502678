import React from 'react';
import PropTypes from 'prop-types';
import {
  TabsGrid, UnderlineGrid, Typo
} from './tabButton.styles';

const TabButton = ({
  tabVal, handleTabChange, tab
}) => (
  <TabsGrid container item>
    {tab.map(({
      label, value, width, marginTop
    }) => (
      <UnderlineGrid
        key={value}
        item
        container
        className={`erp-uat-${value}`}
        onClick={() => handleTabChange(value)}
        active={value === tabVal}
        width={width}
        marginTop={marginTop}
      >
        <Typo data-testid={value} active={value === tabVal}>
          {label}
        </Typo>
      </UnderlineGrid>
    ))}
  </TabsGrid>
);

TabButton.propTypes = {
  tabVal: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tab: PropTypes.instanceOf(Array).isRequired
};

export default TabButton;
