import {
  Grid, Typography, IconButton, Icon, Paper,
  Divider, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogTitleMainGrid = styled(Grid)`
  justify-content: space-between;
`;

export const OgaImage = styled('img')`
  margin-bottom: 20px;
  margin-top: 20px;
  height: 100px;
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CloseIconGrid = styled(Grid)`
  text-align: end;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
`;

export const DialogContentHeader = styled(Grid)`
  font-size: 20px
`;

export const HeaderBar = styled(Grid)`
  justify-content: space-around;
`;

export const HeaderPaper = styled(Paper)`
  background: #78AADA 0% 0% no-repeat padding-box;
  color: white;
  box-shadow: 0px 1px 3px #00000029;
  border: 5px solid #78AADA;
  border-radius: 7px 7px 0px 0px;
`;

export const HeaderPaperGrid = styled(Grid)`
  justify-content: space-between;
  padding: 1rem 2.5rem;
`;

export const SupplierPaper = styled(Paper)`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #CCCCCC;
  border-radius: 0px;
`;

export const SupplierPaperGrid = styled(Grid)`

`;

export const SupplierPaperHeader = styled(Grid)`
  justify-content: space-between;
  padding: 1rem 2.5rem;
  border-bottom: 2px solid #efefef;
`;

export const ContentHeaderText = styled(Typography)`
  font-size: 1.8rem;
  margin-bottom: 60px;
  font-weight: 900;
`;

export const HeaderPaperText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
`;

export const SupplierPaperText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #A3A3A3;
`;

export const SubTotalGridWrapper = styled(Grid)`
  align-items: center;
  padding: 1rem 0;
  margin: 0 2.5rem;
`;

export const SupplierSubTotal = styled(Grid)`
  &:first-child {
    text-align: end;
  }
`;

export const SubTotalText = styled(Typography)`
  font-weight: 600;
`;

export const ContentHeaderID = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
`;

export const ContentHeaderSubText = styled(Typography)`
  font-size: 18px;
`;

export const ContentHeaderItem = styled('div')`
  span{
    display: inline-block;
    width: 80px;
    margin-right: 40px;
    color: #707070;
  }
  color: #424242;
  display: flex;
  margin-bottom: 10px;
`;

export const WideContentHeaderItem = styled(ContentHeaderItem)`
  text-align: left;
  span{
    width: 150px;
  }
`;

export const SpacedWideContentHeaderItem = styled(WideContentHeaderItem)`
  display: flex;
  justify-content: space-between;
`;

export const ContentDivider = styled(Divider)`
  margin: 3rem 0;
  background-color: #D0D0D0;
  box-shadow: 1px 2px 3px #00000029;
`;

export const DeliverToText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 400;
  color: #A3A3A3;
  margin-bottom: 1rem;
`;

export const HeaderBarText = styled(Typography)`
  font-size: .8rem;
  font-weight: 400;
  color: #A3A3A3;
`;

export const Attention = styled('b')`
  font-weight: 600;
`;

export const BottomTextGrid = styled(Grid)`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 50px;
`;

export const BottomText = styled(Typography)`
  font-size: .7rem;
`;

export const LinkTag = styled('a')`
  color: #235A91;
`;

export const CheckIcon = styled(Icon)`
  color: #424242;
  font-size: 30px;
`;

export const CheckButton = styled(IconButton)`
  color: #A3A3A3;
  background: #FCED50;
  box-shadow: 0px 3px 6px #00000029;
  margin-right: 1rem;

  &:hover {
    background: #E2D444;
  }
`;

export const TotalsWraper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 50px;
`;

export const AmountWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  span{
    color: #A3A3A3;
    font-size: 17px;
    line-height: 45px;
  }
  div{
    &::first-letter{
      text-transform: uppercase;
    }
  }
`;

export const AmountBreakdown = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SignatureWrapper = styled(Box)`
  height: 152px;
  display: flex;
  justify-content: space-between;
  padding: 30px 50px;
  box-sizing: border-box;

  span{
    display: inline-block;
    width: 500px;
  }
`;

export const TermsAndConditions = styled(Box)`
  line-height: 32px;
  span{
    color: #A3A3A3;
    font-size: 17px;
  }
`;
