import {
  Grid, TableRow, Typography, Table, TableCell, Paper, DialogTitle, DialogContentText,
  IconButton
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainContainer = styled(Grid)`
  background: #F0F0F0;
`;

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 32px;
`;

export const PageTitleText = styled('p')`
  color: #424242;
  font-size: 4rem;
  font-weight: 600;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
  @media (min-width: 992px) {
    font-size: 1.6rem;
  }
`;

export const HeaderWrapper = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  margin-bottom: 20px;
`;

export const DialogHeader = styled(DialogTitle)`
    padding: 15px 60px;
    text-align: center;
    font-size: 1.5rem;
    border-bottom: 2px solid rgba(224, 224, 224, 1);
  `;
export const DialogBody = styled(DialogContentText)`
    padding: 20px 60px;
    min-height: 200px;
  `;
export const Row = styled(TableRow)`
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
  background: '#fff';
  border-bottom: 2px solid rgba(224, 224, 224, 1);
`;

export const TableComponent = styled(Table)`
  min-width: 100%;
`;

export const TableColumn = styled(TableCell)`

`;

export const TableWrapper = styled('div')`
  margin: 3rem;
  margin-bottom: 1.5rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-bottom: 1rem;
  }
`;

export const TableColumnHeaderTitle = styled('p')`
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 23px;
  white-space: pre-wrap;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &:nth-child(1) {
    width: 10rem;
  }
  &:nth-child(2) {
    width: 25rem;
  }
  &:nth-child(3) {
    width: 50rem;
  }

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
  padding: 0 35px;
`;

export const TableContainer = styled(Paper)`
  border: 1px solid #eee;
`;

export const TableColumnRow = styled('div')`
  display: flex;
  margin: 0;
`;

export const TableColumnRowText = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 23px;
  white-space: nowrap;
  padding-bottom: 12px;
  overflow: hidden;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  &:nth-child(1) {
    width: 10rem;
  }
  &:nth-child(2) {
    width: 25rem;
    cursor: pointer;
  }
  &:nth-child(3) {
    width: 50rem;
  }

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;
export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #FCED50;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  border: 2px solid #424242;
  `;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
  `;

export const MenuButton = styled(IconButton)`
  color: #424242;
  `;

export const SelectNameTypo = styled(Typography)`
  font-size: 1.2rem;
  padding: 6px;
`;
