import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import { HR } from '../../sales-xxx/detail/styles';
import {
  BoldTitle, InfoHeader, MainContainer, PharmacyNameText, OrderTagInfo, SmallHeader,
  TableHeader, TableRow, InlineGreyText
} from './supplierOrderPrint.styles';
import currencyFormatter from '../../shared/currencyFormatter';
import { parseJson } from '../../affiliates/utils';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { useStateValue } from '../../../providers/stateProvider';

export function SupplierOrderPrint({ // To Be Deleted during refactoring
  cart, selectedOutletInfo, orderId, toBePrintedManualSupplierOrderForms, printSupplierOrder, handleSupplierOrderPrint
}) {
  const [{ user: { platform } }] = Object.values(useStateValue());
  const componentRef = useRef();
  const filteredCart = cart.filter((supplierForm) => supplierForm.supplier === toBePrintedManualSupplierOrderForms.get(supplierForm.supplier));
  const supplierCategories = _.groupBy(filteredCart, 'supplier');
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: handleSupplierOrderPrint
  });
  if (printSupplierOrder) {
    setTimeout(() => handlePrint(), 0);
  }
  const renderSupplierOrderForms = () => Object.entries(supplierCategories).map(([key, value]) => {
    const supplierSubTotal = value.reduce((sum, obj) => sum + (Number(obj.quantity) * Number(obj.resolvedPriceInUseValue)), 0);
    let contacts = {};
    if (selectedOutletInfo) {
      contacts = selectedOutletInfo.contacts;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
      } catch (e) {
        return null;
      }
    }
    return (
      <MainContainer key={key}>
        <BoldTitle>{key}</BoldTitle>
        <br />
        <HR />
        <br />
        <Grid container>
          <Grid item xs={6}>
            <InfoHeader>Deliver to:</InfoHeader>
            <br />
            <PharmacyNameText>Alpha Pharmacy</PharmacyNameText>
            <Grid>
              {contacts.address_line_1 ?? ''}
              ,
            </Grid>
            <Grid>
              { contacts.city ?? '' }
              ,
              {' '}
              { contacts.country ?? ''}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <InfoHeader>Delivery contact:</InfoHeader>
            <br />
            <Grid>{ contacts.primary_contact_name ?? '' }</Grid>
            <Grid>{ contacts.primary_email_address ?? '' }</Grid>
            <Grid>{ contacts.mobile_number ?? '' }</Grid>
          </Grid>
        </Grid>
        <br />
        <br />
        <HR />
        <OrderTagInfo container>
          <Grid item xs="4">
            <SmallHeader>Order number:</SmallHeader>
            <Grid>{orderId}</Grid>
          </Grid>
          <Grid item xs="4">
            <SmallHeader>Placed on:</SmallHeader>
            <Grid>{moment().format('dddd MMMM Do YYYY')}</Grid>
          </Grid>
          <Grid item xs="4">
            <SmallHeader>Supplier</SmallHeader>
            <Grid>{key}</Grid>
          </Grid>
        </OrderTagInfo>
        <HR />
        <br />
        <Paper>
          <TableHeader container>
            <Grid item xs="6">
              Product Name(
              {value.length}
              )
            </Grid>
            <Grid item xs="2">Qty</Grid>
            <Grid item xs="2">Unit cost</Grid>
            <Grid item xs="2">Price</Grid>
          </TableHeader>
          {value.filter((p) => p.quantity > 0).map((productItem) => {
            const {
              name: brandName, quantity,
              orderCost, packSize, resolvedPriceInUse
            } = new Product(productItem, platform);
            const unitCost = platform === 'erp' ? orderCost : resolvedPriceInUse;
            return (
              <>
                <TableRow container>
                  <Grid item xs="6">
                    <Grid>{brandName}</Grid>
                    <Grid>
                      Pack Size:
                      {' '}
                      {packSize}
                    </Grid>
                  </Grid>
                  <Grid item xs="2">{quantity}</Grid>
                  <Grid item xs="2">{unitCost}</Grid>
                  <Grid item xs="2">
                    ₦&nbsp;
                    { currencyFormatter(Number(unitCost) * Number(quantity)) }
                  </Grid>
                </TableRow>
                <HR />
              </>
            );
          })}

        </Paper>

        <br />
        <HR />
        <br />
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <InlineGreyText>Grand Total</InlineGreyText>
          </Grid>
          <Grid style={{ paddingLeft: 35 }} item>
            <BoldTitle>
              ₦&nbsp;
              {currencyFormatter(Number(supplierSubTotal))}
            </BoldTitle>
          </Grid>
        </Grid>
        <br />

        <HR />
      </MainContainer>
    );
  });
  return (
    <Grid ref={componentRef}>
      {renderSupplierOrderForms()}
    </Grid>
  );
}

SupplierOrderPrint.propTypes = {
  cart: PropTypes.instanceOf(Object).isRequired,
  selectedOutletInfo: PropTypes.instanceOf(Object),
  orderId: PropTypes.number.isRequired,
  toBePrintedManualSupplierOrderForms: PropTypes.instanceOf(Object).isRequired,
  printSupplierOrder: PropTypes.bool.isRequired,
  handleSupplierOrderPrint: PropTypes.func.isRequired,

};
SupplierOrderPrint.defaultProps = {
  selectedOutletInfo: {}
};
