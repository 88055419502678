import { gql } from '@apollo/client';

export const CREATE_TICKET = gql`
  mutation createHelpdeskTicket($description: String!){
    createHelpdeskTicket(description: $description){
      message
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation updateHelpdeskTicket(
    $status: String!, $ticketId: String!, $comment: String
  ) {
    updateHelpdeskTicket(
      status: $status, ticketId: $ticketId, comment: $comment
    ) {
      message
      helpdesk {
        id
        description
        ticketNumber
        comment
        status
        lastUpdated
        lastStatus
        createdBy{
          username
        }
        dateCreated
      }
    }
  }
`;
