import React from 'react';
import {
  Dialog, DialogContent, DialogContentText,
  Slide, Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import { CircularProgressLoader, DialogHeader, DialogDesc } from './cart.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CartProcessingModal = ({
  orderProcessing,
  handleClose,
  message
}) => (
  <Dialog
    open={orderProcessing}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    onBackdropClick="false"
  >
    <DialogContent>
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
          <CircularProgressLoader
              size={50}
            />
          </Box>
          <DialogHeader>
            {message?.title}
          </DialogHeader>
          <DialogDesc>
            {message?.desc}
          </DialogDesc>
        </Box>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

CartProcessingModal.propTypes = {
  orderProcessing: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
};

export default CartProcessingModal;
