import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './tabsButtons.styles';

const TabsButtons = ({ active, handleStatusValueChange }) => (
  <StatusesGrid container item>
    <UnderlineGrid
      item
      container
      onClick={() => handleStatusValueChange('open')}
      active={active === 'open'}
      width="12%"
      className="mpAdmin-uat-ordersPageTab-open-orders"
    >
      <Typo active={active === 'open'}>Open Orders</Typo>
    </UnderlineGrid>
    <UnderlineGrid
      item
      container
      onClick={() => handleStatusValueChange('closed')}
      active={active === 'closed'}
      width="14%"
      className="mpAdmin-uat-ordersPageTab-close-orders"
    >
      <Typo active={active === 'closed'}>Closed Orders</Typo>
    </UnderlineGrid>
    <UnderlineGrid container width="74%" marginTop="1.3rem">
      <Typo>{' '}</Typo>
    </UnderlineGrid>
  </StatusesGrid>
);
TabsButtons.propTypes = {
  active: PropTypes.string,
  handleStatusValueChange: PropTypes.func.isRequired
};

TabsButtons.defaultProps = {
  active: '',
};

export default TabsButtons;
