import {
  Grid, Typography, Paper
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const CardGridContainer = styled(Paper)`
    width: 300px;
    height: 210px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #CCCCCC;
    border: 0.5px solid #CCCCCC80;
    border-radius: 7px;
    opacity: 1;
    box-sizing: border-box;
    padding:15px 0;
    position: relative;
    cursor: pointer;

    @media(max-width: 776px){
    width: 454px;
    height: 210px;
    }
`;

export const CardHeader = styled(Grid)`
    height: 30px;
    border-bottom: 0.5px solid rgba(204,204,204,.45);
    justify-content: flex-end;
`;

export const HeaderText = styled(Typography)`
font-size: 12px;
font-weight: 500;
color: #A3A3A3;
text-transform: uppercase;
padding-right: 15px;
padding-bottom: 10px;
`;

export const CardContentGrid = styled(Grid)`
    margin-top: 35px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 70%;
`;

export const ContentHeader = styled(Typography)`
    color: #424242;
    font-size: 16px;
    font-weight: 600;
`;

export const ContentMeta = styled(Typography)`
    font-size: 12px;
    color: #A3A3A3;
`;

export const ContentSubMeta = styled(ContentMeta)`
    margin-top: -30px;
`;

export const HeaderIcon = styled('img')`
height: 60px;
width: 60px;
position: absolute;
top: 10px;
left: 20px;
`;

export const FooterMeta = styled(Grid)`
`;
