import {
  TableCell, TableRow, TableHead, Button, MenuItem, DialogContent,
  Grid, Typography, Table, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../customComponents/customButton';
import { PrinterIcon, EmailIcon } from '../../assets/svgs';

export const CDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    max-height: 95vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  width: 38rem;
  padding: 3rem 1.5rem;
`;

export const CardTitle = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;
`;

export const ContainerBody = styled('div')`
  margin-top: 10px;
  padding: 20px 40px;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #f4f5f6;
`;

export const TableRowStyled = styled(TableRow)`
  overflow-x: scroll;
`;

export const TableCellStyled = styled(TableCell)`
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  border-bottom: none;
`;

export const ButtonOutlined = styled(Button)`
  width: 178px;
  height: 48px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #303030;
`;

export const ButtonDark = styled(Button)`
  width: 178px;
  height: 48px;
  background: #303030;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;

  &:hover {
    background: #303030;
  }
`;

export const ButtonGray = styled(Button)`
  width: 178px;
  height: 48px;
  background: #C0C0C1;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;

  &:hover {
    background: #C0C0C1;
  }
`;

export const Printer = styled(PrinterIcon)`
  margin-right: .5rem;
  color: #303030;
  font-size: 1rem;
`;

export const Email = styled(EmailIcon)`
  margin-right: .5rem;
  color: #C0C0C1;
  font-size: 1rem;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const TableCellBodyStyled = styled(TableCell)`
  white-space: nowrap;
  font-size: 15px;
  border-bottom: none;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 35rem !important;
  padding: 4rem 1.5rem;
`;

export const HeaderGrid = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0 0;
  width: 100%;
  height: 60px;
  line-height: 100px;
  background: #303030;
  color: #fff;
  padding: 0 2rem;
`;

export const DateText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
`;

export const LocationGrid = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
`;

export const InnerText = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #606060;
`;

export const CustomerDetailLt = styled('span')`
  font-weight: 400;
  font-size: 15px;
  color: #606060;
`;

export const CTable = styled(Table)`
  width: 100%;
  margin-top: 1rem;
`;

export const HeaderRow = styled(TableRow)`
  background: #f4f5f7;
`;

export const DefaultTr = styled('tr')``;

export const TableHeader = styled(TableCell)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  color: #606060;
  line-height: 26.27px;
  padding: 10px;
  border-bottom: 0;
  background: #F4F5F7;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 36%;
    }
    &:nth-child(3) {
      width: 22%;
    }
    &:nth-child(4) {
      width: 32%;
    }
  }
`;

export const CTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F4F5F6;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: 16px;

    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 36%;
    }
    &:nth-child(3) {
      width: 22%;
    }
    &:nth-child(4) {
      width: 32%;
    }
  }
`;

export const DefaultTdBody = styled('td')`
  padding: 0.9rem 2rem 0 2rem !important;
  font-size: 14px;
  color: #606060;
`;

export const Separator = styled('div')`
  background: #f4f5f7;
  height: 0.1rem;
  width: 100%;
`;

export const ReceiptFooter = styled(Grid)`
  margin-top: 3rem;
  align-items: center;
  justify-content: space-between;
`;

export const SmallText = styled('span')`
  font-size: 14px;
`;
