import { useQuery } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Checkbox,
  Grid,
  Paper,
  TableBody,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ascImg from '../../../assets/images/Sort Ascending Icon.png';
import descImg from '../../../assets/images/Sort Descending Icon.png';
import { useStateValue } from '../../../providers/stateProvider';
import { GET_MISSED_SALES } from '../../../queries/sales';
import paginationShowing from '../../../utils/pagination';
import CTablePagination from '../../customComponents/cTablePagination';
import GoToTop from '../../customComponents/scrollToTop';
import {
  OrderCountText,
  OrderItemsGrid,
} from '../../orders/admin/order.styles';
import Loader from '../../shared/loader';
import {
  MainTableHead,
  TableComponent
} from '../../toBeDeleted/supplierDetails/supplierProducts.styles';
import {
  AffiliateTableContainerWrapper,
  FooterGrid,
  HeaderCell,
  PaddingBottom,
  SortCart, SortImage
} from '../dashboard/dashboard.styles';
import ReturnMissedSalesRow from './returnMissedSalesRow';

const MissedSaleTab = ({
  history
}) => {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    data: {},
  };
  const [{ business: { businessId, businessUser: businessUserId } },] = Object.values(useStateValue());

  const [state, setState] = useState(initialState);
  const [allData, setAllData] = useState({});
  const [sortCart, setSortCart] = useState(false);
  const [asc, setAsc] = useState(false);
  const [desc, setDesc] = useState(false);

  const {
    currentPage, currentPageCount
  } = state;

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const sortByCart = (direction) => {
    if (direction === 'asc') {
      setDesc(false);
      setAsc(!asc);
      // eslint-disable-next-line no-use-before-define
      data.missedSales.sort((a, b) => ((a.total > b.total) ? 1 : -1));
    } else if (direction === 'desc') {
      setDesc(!desc);
      setAsc(false);
      // eslint-disable-next-line no-use-before-define
      data.missedSales.sort((a, b) => ((a.total < b.total) ? 1 : -1));
    }
    // eslint-disable-next-line no-use-before-define
    setAllData(data);
  };
  const variables = {
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  const {
    data, loading, error
  } = useQuery(GET_MISSED_SALES, {
    variables,
  });

  useEffect(() => {
    if (data && data.missedSales) {
      setAllData(data);
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;
  const {
    missedSales = [], missedSalesTotalNumber = 0
  } = allData;
  const showing = paginationShowing(missedSales, currentPageCount);

  return (
    <>
      <OrderItemsGrid>
        <OrderCountText>
          Showing
          <b>
            {` ${showing()} - ${showing('end')} `}
          </b>
          of
          <b>
            {` ${missedSalesTotalNumber} `}
          </b>
          sales(s)
        </OrderCountText>
      </OrderItemsGrid>

      <AffiliateTableContainerWrapper component={Paper}>
        <TableComponent aria-label="supplier products table">
          <MainTableHead>
            <TableRow>
              <HeaderCell />
              <HeaderCell>Date Sold</HeaderCell>
              <HeaderCell>Product</HeaderCell>
              <HeaderCell>Pack Size</HeaderCell>
              <HeaderCell>Qty Missed</HeaderCell>
              <HeaderCell>Sold By</HeaderCell>
              <HeaderCell style={{ cursor: 'pointer' }} onClick={() => setSortCart(!sortCart)}>
                Sale Amount
                <ArrowDropDownIcon />
              </HeaderCell>
            </TableRow>

          </MainTableHead>
          {sortCart
            ? (
              <SortCart>
                <PaddingBottom>Sort</PaddingBottom>
                <SortImage src={ascImg} />
                Ascending
                <Checkbox
                  checked={asc}
                  onChange={() => sortByCart('asc')}
                  disabled={loading}
                  style={{ position: 'absolute', left: '265px' }}
                  name="asc"
                  color="primary"
                />
                <div style={{ padding: '3%' }} />
                <SortImage src={descImg} />
                Decending
                <Checkbox
                  checked={desc}
                  onChange={() => sortByCart('desc')}
                  disabled={loading}
                  style={{ position: 'absolute', left: '265px' }}
                  name="desc"
                  color="primary"
                />
              </SortCart>
            )
            : ''}
          <TableBody>
            {missedSales.map((missedSale) => (
              <ReturnMissedSalesRow
                key={missedSale.id}
                missedSale={missedSale}
                history={history}
              />
            ))}
          </TableBody>
        </TableComponent>
        {!missedSales && <Grid style={{ textAlign: 'center' }}>No Data</Grid>}
      </AffiliateTableContainerWrapper>

      <FooterGrid item container xs={12} justifyContent="flex-end">
        <Grid item>
          <CTablePagination
            rowsPerPageOptions={[20, 50, 100, 150, 200]}
            component="div"
            count={missedSalesTotalNumber}
            page={currentPage - 1}
            onChangePage={handleChangePage}
            rowsPerPage={currentPageCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </FooterGrid>
      {
        (missedSales.length)
          ? <GoToTop /> : ''

      }
    </>
  );
};

MissedSaleTab.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default MissedSaleTab;
