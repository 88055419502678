import {
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FiltersGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
  }
`;
