import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

export const ORDER_QUERY = gql`
${PRODUCT_VERSIONS}
  query($bizId: Int!, $isManualOrder: Boolean, $pageCount: Int, $pageNumber: Int, $search: String) {
    isBusinessLoanEligible
    business(id: $bizId) {
      id
      name
      contacts
      dateCreated
      tax
      deliveryLocations {
        id
        name
        contacts
        profile
      }
    }
    order(isManualOrder: $isManualOrder, affiliateId: $bizId, pageCount: $pageCount, pageNumber: $pageNumber, search: $search){
      id
      meta
      name
      price
      unitCost
      quantity
      savings
      hybrid
      alternativeSupplier {
        id
        name
      }
      supplierOrder {
        order{
          id
          paymentSummary
        }
        supplier {
          name
          settings
        }
      }
      product {
        id
        meta
        price
        business{
            id
        }
       ...productVersions
      }
    }
    cartOrderProductsCount
  }
`;

export const MP_CART_ORDER_QUERY = gql`
  query($bizId: Int!, $isManualOrder: Boolean, $pageCount: Int, $pageNumber: Int, $search: String) {
    isBusinessLoanEligible
    business(id: $bizId) {
      id
      name
      contacts
      dateCreated
      tax
      deliveryLocations {
        id
        name
        contacts
        profile
      }
    }
    order(isManualOrder: $isManualOrder, affiliateId: $bizId, pageCount: $pageCount, pageNumber: $pageNumber, search: $search){
      id
      meta
      name
      price
      unitCost
      quantity
      savings
      hybrid
      availableQty
      orderProductStatus
      alternativeSupplier {
        id
        name
      }
      supplierOrder {
        order{
          id
          paymentSummary
          meta
        }
        supplier {
          name
          settings
        }
      }
      product {
        id
        meta
        price
        business{
            id
        }
        marketplaceVersion{
          itemPrice
          productVariant
          minimumOrderQuantity
          supplier{
            id
            name
          }
          brandName
          packSize
          image
          therapeuticType{
            id
            therapeuticType
          }
          quantityInStock
        }
      }
    }
    cartOrderProductsCount
  }
`;

export const SAVED_CART = gql`
query saveCartOrder($pageCount: Int, $pageNumber: Int){
  saveCartOrder(pageCount: $pageCount, pageNumber: $pageNumber){
    id
    name
    meta
  }
}
`;
