import React from 'react';
import MapCsvDialog from '../../../toBeDeleted/uploadSupplier/mapCsvDialog';

function MapCustomerCsvDialog() {
  const fields = [
    ['Customer Name', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Mobile', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false
    }],
    ['Email', {
      required: false, unique: true, value: null, empty: true, touched: false, hover: false
    }],
    ['Address Line 1', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Address Line 2', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Region', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['City', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Notes', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Country', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Loyalty Point', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],

    ['Store Credit', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }]
  ];
  return (
    <MapCsvDialog
      fields={fields}
      model="customer"
      endpoint="customers"
      backRoute="customers"
    />
  );
}

MapCustomerCsvDialog.propTypes = {
};
export default MapCustomerCsvDialog;
