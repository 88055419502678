import { Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import currencyFormatter from '../../../utils/currencyFormatter';
import { AffiliateBodyCell, MainTableRow } from '../dashboard/dashboard.styles';

const ReturnMissedSalesRow = ({
  missedSale,
}) => {
  const {
    sale: {
      dateCreated,
      businessUser: {
        user: {
          username
        }
      }
    },
    name,
    packSize,
    quantity,
    total,
  } = missedSale;
  return (
    <MainTableRow>
      <AffiliateBodyCell />
      <AffiliateBodyCell>
        {dateCreated ? moment(dateCreated).format('lll') : 'N/A'}
      </AffiliateBodyCell>

      <AffiliateBodyCell>
        <Grid>{name}</Grid>
      </AffiliateBodyCell>

      <AffiliateBodyCell>
        <Grid>{packSize}</Grid>
      </AffiliateBodyCell>
      <AffiliateBodyCell>
        <Grid>{quantity}</Grid>
      </AffiliateBodyCell>
      <AffiliateBodyCell>
        <Grid>{username.split('@')[0]}</Grid>
      </AffiliateBodyCell>
      <AffiliateBodyCell>
        {currencyFormatter(total)}
      </AffiliateBodyCell>

    </MainTableRow>
  );
};

ReturnMissedSalesRow.propTypes = {
  missedSale: PropTypes.instanceOf(Object).isRequired,
};

export default ReturnMissedSalesRow;
