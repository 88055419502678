import {
  Dialog,
  Grid,
  Typography,
  TableCell,
  TableRow,
  Table
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const PartiallyReceivedDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  width: 47rem;
  padding: 0 1.5rem 3rem 1.5rem;
`;

export const DialogHeader = styled(Grid)`
  width: 42rem;
  padding: 1.5rem 0 0.5rem;
  background: #fff;
  position: fixed;
  z-index: 1;
`;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;

  @media (min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const DialogBody = styled(Grid)`
  height: max-content;
  margin-top: 5rem;
  overflow-y: auto;
`;

export const DialogActions = styled(Grid)`
  justify-content: flex-end;
  padding: 1rem 0 1.5rem;
  background: #fff;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

export const TableHeaderRow = styled(TableRow)`
  width: 100%;
  border: 0;
  background: #F3F9FF;
`;

export const MainTable = styled(Table)`
  width: 100%;
  border: 0;
`;

export const TableHeader = styled(TableCell)`
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 3.2;
  padding: 5px;
  border: 0;
  // background: #f4f5f7;
`;

export const MainTableRow = styled(TableRow)`
  width: 100%;

  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const EmptyTableRow = styled(TableRow)`
  width: 100%;
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 12px;
  color: #606060;
  padding: 12px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
  margin-right: -30px;
`;

export const CenterTextGrid = styled(Grid)`
  padding: 1rem 0;
`;

export const DFlex = styled('div')`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const DColumn = styled('div')`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const BrandName = styled('span')`
  font-weight: 700;
  font-size: 14px;
  color: #606060;
`;

export const PackSize = styled('span')`
  font-weight: 500;
  font-size: 12px;
`;
