import React from 'react';
import PropTypes from 'prop-types';
import {
  MobileContainer, DetailText, HeaderText, TextBox, DateText,
  ContentBox,
} from './returnRow.styles';
import currencyFormatter from '../../shared/currencyFormatter';

export default function MobileReturnRow({
  row
}) {
  const {
    brandName, packSize, price, totalSales, qtyInStock, qtySold
  } = row;

  const salesTotal = currencyFormatter(totalSales);
  const productPrice = currencyFormatter(price);

  return (
    <MobileContainer>
      <DateText>
        {brandName}
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container>
          <HeaderText xs={6}>Unit Price</HeaderText>
          <DetailText xs={6}>
            {`NGN ${productPrice}`}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Pack size</HeaderText>
          <DetailText xs={6}>
            {packSize}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Total value</HeaderText>
          <DetailText xs={6}>
            {`NGN ${salesTotal}`}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Qty in stock</HeaderText>
          <DetailText xs={6}>
            {qtyInStock}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Qty sold pack</HeaderText>
          <DetailText xs={6}>
            {qtySold}
          </DetailText>
        </TextBox>
      </ContentBox>
    </MobileContainer>
  );
}

MobileReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

MobileReturnRow.defaultProps = {
  row: {},
};
