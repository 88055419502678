import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  HeaderWrapper, SupplierPaper, ContactPaper, ItemImage,
  NormalText, NameText, LowerGrids, BottomContainer, ContactGrid, IconImage,
  NameGrid, SubHeaderText, ContactHeaderText, LightDivider, LowerLighterText,
  LowerDarkerText, LowerLighter2Text, SmallHeading, ContactsGrid, NormalText2
} from './supplierHeader.styles';
import OrderIcon from '../../../assets/images/order/Total Orders Icon.png';
import OrderCashIcon from '../../../assets/images/order/Gross Order Value Icon.png';
import currencyFormatter from '../../shared/currencyFormatter';

const SupplierHeader = ({
  id, name, dateCreated, logo, address, terms,
  orderVolume, orderValue, mobile, email
}) => {
  const datePlaced = moment(dateCreated).format('DD/MM/YYYY');
  const supplierId = `SU${id}`;

  return (
    <HeaderWrapper container spacing={6}>
      <NameGrid item xs={12} md={6}>
        <SupplierPaper elevation={2}>
          <SubHeaderText variant="subtitle1" align="right">
            {supplierId}
          </SubHeaderText>
          <LightDivider light />
          <ItemImage alt="logo" src={logo} />
          <BottomContainer item direction="column">
            <NameText>{name}</NameText>
            <NormalText>
              {`Created on ${datePlaced}`}
            </NormalText>
            <NormalText>
              {terms}
            </NormalText>
            <LowerGrids container item>
              <IconImage alt="lower logo" src={OrderIcon} />
              <LowerLighterText variant="subtitle1">
                Order Volume:
              </LowerLighterText>
              <LowerDarkerText variant="subtitle1">
                {`${orderVolume} Orders`}
              </LowerDarkerText>
            </LowerGrids>
            <LowerGrids container item>
              <IconImage alt="lower logo" src={OrderCashIcon} />
              <LowerLighter2Text variant="subtitle1">
                Order Value:
              </LowerLighter2Text>
              <LowerDarkerText variant="subtitle1">
                {currencyFormatter(orderValue)}
              </LowerDarkerText>
            </LowerGrids>
          </BottomContainer>
        </SupplierPaper>
      </NameGrid>
      <ContactGrid item xs={12} md={6}>
        <ContactPaper elevation={2}>
          <ContactHeaderText variant="h6" align="left">
            Contact Information
          </ContactHeaderText>
          <LightDivider light />
          <ContactsGrid item direction="column">
            <SmallHeading variant="caption">Mobile</SmallHeading>
            <NormalText2>{mobile || 'N/A'}</NormalText2>
            <SmallHeading variant="caption">Email</SmallHeading>
            <NormalText2>{email || 'N/A'}</NormalText2>
            <SmallHeading variant="caption">Address</SmallHeading>
            <NormalText>{`${address[0]},`}</NormalText>
            {address[1] && <NormalText>{`${address[1]},`}</NormalText>}
            {address[2] && <NormalText>{`${address[2]},`}</NormalText>}
            <NormalText>{`${address[3]}, ${address[4]}`}</NormalText>
          </ContactsGrid>
        </ContactPaper>
      </ContactGrid>
    </HeaderWrapper>
  );
};

SupplierHeader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  dateCreated: PropTypes.string,
  logo: PropTypes.string,
  address: PropTypes.arrayOf(String),
  terms: PropTypes.string,
  orderVolume: PropTypes.number,
  orderValue: PropTypes.number,
  mobile: PropTypes.number,
  email: PropTypes.string
};

SupplierHeader.defaultProps = {
  id: '',
  name: '',
  dateCreated: '',
  logo: '',
  address: [],
  terms: '',
  orderVolume: 0,
  orderValue: 0,
  mobile: 0,
  email: ''
};

export default SupplierHeader;
