import OrderActionTypes from './orderTypes';

const orderReducer = (order, action) => {
  switch (action.type) {
    case OrderActionTypes.UPDATE_ORDER:
      return {
        ...order,
        ...action.payload
      };
    case OrderActionTypes.TOGGLE_DIALOG_OPEN:
      return {
        ...order,
        addOrderDialogOpen: !order.addOrderDialogOpen
      };
    default:
      return order;
  }
};

export default orderReducer;
