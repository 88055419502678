import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputLabel, Select, useMediaQuery, MenuItem
} from '@mui/material';
import {
  CheckboxImage, FormField, CSMenuItem, LocationImg,
  LinkAddrsTitle, LinkSubAddrsTitle, SelectNameTypo, FieldCheckbox
} from './returnSelectField.styles';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../assets/images/supplier/Checkbox-resting.png';
import { addHyphen } from '../../shared/methods';
import { PharmacyIcon } from '../../../assets/svgs';
import LocationIcon from '../../../assets/images/locationIcon.png';
import { useStateValue } from '../../../providers/stateProvider';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ReturnSelectField = ({
  field, value, handleChange, size, returns, cart,
  handleCreditDaysOpen, creditDays, fullWidth, showCheckBox, showLabel, header
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();
  const isSmall = useMediaQuery('(max-width: 991px)');

  const [{ user: { platform } }] = Object.values(useStateValue());

  const isMP = platform === 'marketplace';

  const handleInput = (event) => {
    const { value: _val } = event.target;
    setSelected(event.target.value);
    handleChange(event);

    if (_val === 'On-credit payment') {
      handleCreditDaysOpen(selectRef.current);
    }
  };

  const handleMPInput = (name) => {
    setSelected(name);
    handleChange(name);
    if (name === 'On-credit payment') {
      handleCreditDaysOpen(selectRef.current);
    }
  };

  const renderFunc = (val) => {
    if (val === 'On-credit payment') {
      return `${creditDays} credit days`;
    }
    return val;
  };

  return (
    <FormField
      fullWidth={fullWidth}
      variant="filled"
      size={size}
      ref={selectRef}
    >
      { showLabel && (
        <InputLabel
          style={{
            top: returns ? '5px' : '', left: returns ? '10px' : '', color: returns ? '#858383' : '',
            fontSize: returns ? '.9rem' : ''
          }}
          id="select-label"
        >
          {field.label}
        </InputLabel>
      )}
      <Select
        labelId="select-filled-label"
        id="select-filled"
        value={value}
        onChange={(!isMP && !cart) && handleInput}
        data-testid={`erp-uat-${addHyphen(header)}`}
        renderValue={(val) => renderFunc(val)}
        style={{ height: returns ? '4rem' : (cart && !isSmall) ? '60px' : '', borderRadius: '10px' }}
        inputProps={{
          name: field.name,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {(isMP && cart) ? (
          field.options?.map(
            ({ name, address, remotePos }, index) => (
              <CSMenuItem key={name} onClick={() => handleMPInput(name)}>
                <Grid container>
                  <Grid item xs={1}>
                    {remotePos ? <PharmacyIcon style={{ fontSize: '2.75rem' }} />
                      : <LocationImg src={LocationIcon} alt="Marketplace Location" />}
                  </Grid>
                  <Grid item xs={11}>
                    <LinkAddrsTitle>{name}</LinkAddrsTitle>
                    <LinkSubAddrsTitle>{address}</LinkSubAddrsTitle>
                  </Grid>
                </Grid>

                {index < field.options.length - 1 && (
                  <hr style={{ border: '0.5px solid #e7e8e9' }} />
                )}
              </CSMenuItem>
            )
          )
        )
          : (
            field.options?.map(
              (option) => (
                <MenuItem key={option} value={option}>
                  <Grid item container justifyContent="space-between" alignItems="center">
                    <SelectNameTypo>{option}</SelectNameTypo>
                    { showCheckBox && (
                    <FieldCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected.indexOf(option) > -1}
                    />
                    )}
                  </Grid>
                </MenuItem>
              )
            )
          )}
      </Select>
    </FormField>
  );
};

ReturnSelectField.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  size: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleCreditDaysOpen: PropTypes.func.isRequired,
  creditDays: PropTypes.number,
  fullWidth: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  showLabel: PropTypes.bool,
  returns: PropTypes.bool,
  cart: PropTypes.bool,
  header: PropTypes.string,
};

ReturnSelectField.defaultProps = {
  field: {},
  value: '',
  size: 'small',
  creditDays: 0,
  fullWidth: false,
  showCheckBox: true,
  showLabel: true,
  returns: false,
  cart: false,
  header: '',
};

export default ReturnSelectField;
