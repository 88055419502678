import {
  Dialog, Grid, DialogActions, Avatar, Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`

`;

export const CDialogTitle = styled(Grid)`
  padding: 24px 24px 0;
  justify-content: space-between;
`;

export const DialogHeaderText = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: #303030;

`;

export const CDialogContent = styled(Grid)`
  padding: 8px 24px 0;
`;

export const DialogText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #606060;
`;

export const CDialogActions = styled(DialogActions)`
  padding: 24px;
`;

export const ProdAvatar = styled(Avatar)`
  margin: 10;
  width: 80;
  height: 80;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;
