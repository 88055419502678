import React from 'react';
import PropTypes from 'prop-types';
import {
  WrapperContainer, CheckButton, CheckIcon, ImgContainer, Image, LicenseImage
} from './finishedImage.styles';

const FinishedImage = ({ img, onClose, isLicense }) => (
  <WrapperContainer item container>
    <CheckButton onClick={onClose}>
      <CheckIcon>close</CheckIcon>
    </CheckButton>
    <ImgContainer>
      {isLicense
        ? <LicenseImage src={img} alt="Cropped" />
        : <Image src={img} alt="Cropped" />}
    </ImgContainer>
  </WrapperContainer>
);

FinishedImage.propTypes = {
  img: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default FinishedImage;
