import {
  TableRow, TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  border-bottom: 1px solid #cccc;
  padding: 0 100px;
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.5rem;

  &:first-child, :last-child {
    border-bottom: 0;
  }
  &:nth-child(1){
    padding: 8px;
  }
  &:nth-child(2), :nth-child(3), :nth-last-child(2) {
    padding: 0;
  }
  &:first-child{
    padding-left: 25px;
  }
  &:last-child {
    padding-right: 10px;
  }

  @media(min-width: 992px) {
    font-size: 1rem;
    padding: 16px;
    padding-left: 0;
  }
`;
