import React from 'react';
import PropTypes from 'prop-types';
import {
  AddImg, AddProductButton, BodyCell, MainTableRow
} from './returnRow.styles';
import { DeleteIcon } from '../../../../../assets/svgs';
import CustomInputBase from '../../../../customComponents/customInputBase';
import currencyFormatter from '../../../../shared/currencyFormatter';
import { blockInvalidChar } from '../../../../../utils/mobileCheck';

const ReturnRow = ({
  row, index, quantityHandler, productChanges, deleteProductHandler, addProductHandler
}) => {
  const {
    productName, manufacturer, realTimeInvoiceQty, unitCost, realTimeInvoicePrice, excludeFromRealTimeInvoice, orderProductId
  } = row;

  const updatedVal = productChanges?.find(({ orderProductId: id }) => id === orderProductId);
  const isDeleted = updatedVal?.excluded === false ? updatedVal?.excluded : (updatedVal?.excluded || excludeFromRealTimeInvoice);
  return (
    <MainTableRow>
      <BodyCell style={isDeleted ? { opacity: 0.3 } : {}}>{index + 1}</BodyCell>
      <BodyCell style={isDeleted ? { opacity: 0.3 } : {}}>
        <CustomInputBase
          value={manufacturer}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '250px', height: '3.5rem' }}
          readOnly
        />
      </BodyCell>
      <BodyCell style={isDeleted ? { opacity: 0.3 } : {}}>
        <CustomInputBase
          value={productName}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '250px', height: '3.5rem' }}
          readOnly
        />
      </BodyCell>
      <BodyCell style={isDeleted ? { opacity: 0.3 } : {}}>
        <CustomInputBase
          value={updatedVal?.quantity || realTimeInvoiceQty}
          variant="filled"
          size="small"
          placeholder="0"
          step={0.01}
          type="number"
          style={{ width: '200px', height: '3.5rem' }}
          onChange={(e) => quantityHandler(e.target.value, row)}
          readOnly={isDeleted}
          onWheel={(e) => e.target.blur()}
          onKeyDown={blockInvalidChar}
        />
      </BodyCell>
      <BodyCell style={isDeleted ? { opacity: 0.3 } : {}}>
        <CustomInputBase
          value={`₦ ${currencyFormatter(unitCost)}`}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '150px', height: '3.5rem' }}
          readOnly
        />
      </BodyCell>
      <BodyCell style={isDeleted ? { opacity: 0.3 } : {}}>
        <CustomInputBase
          value={`₦ ${currencyFormatter(realTimeInvoicePrice)}`}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '150px', height: '3.5rem' }}
          readOnly
        />
      </BodyCell>
      <BodyCell style={{ cusor: 'pointer' }}>
        {
          (isDeleted)
            ? (
              <AddProductButton onClick={() => addProductHandler(row)}>
                <AddImg size={25} />
              </AddProductButton>
            )
            : <DeleteIcon data-testid="deleteRow" style={{ cursor: 'pointer', pointerEvents: '' }} onClick={() => deleteProductHandler(row)} />
        }
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  quantityHandler: PropTypes.func,
  productChanges: PropTypes.instanceOf(Object),
  addProductHandler: PropTypes.func,
  deleteProductHandler: PropTypes.func
};

ReturnRow.defaultProps = {
  quantityHandler: () => null,
  productChanges: [],
  addProductHandler: () => null,
  deleteProductHandler: () => null,
};

export default ReturnRow;
