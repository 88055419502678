import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery, Box
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import allImg from '../../../../assets/images/marketOrder/all.png';
import consumableImg from '../../../../assets/images/marketOrder/consumables.png';
import counterImg from '../../../../assets/images/marketOrder/counter.png';
import beautyImg from '../../../../assets/images/marketOrder/beauty.png';
import medicalImg from '../../../../assets/images/marketOrder/medical.png';
import prescriptionImg from '../../../../assets/images/marketOrder/prescription.png';
import sexualImg from '../../../../assets/images/marketOrder/sexual.png';
import supplementsImg from '../../../../assets/images/marketOrder/supplements.png';

import {
  CategoryGrid, CardPaper, CategoryImg, CategoryText, CardBox, AllCategoriesPaper, CategoriesWrapper
} from './categoriesContainer.styles';
import { AllCat } from '../../../../assets/svgs';
import { MARKETPLACE_CATEGORIES } from '../../../../queries/categories';

export default function CategoriesContainer({
  isSelected, productPage
}) {
  const categoryImg = [
    consumableImg, medicalImg, counterImg, beautyImg, prescriptionImg, supplementsImg, sexualImg
  ];
  const carouselRef = useRef();
  const navigate = useNavigate();
  const {
    loading, data: categoriesList
  } = useQuery(MARKETPLACE_CATEGORIES, {
    fetchPolicy: 'cache-first',
  });
  const handleAllSelect = () => {
    navigate('/new-order');
  };

  const handleSelect = (id, name) => {
    const category = name;
    const categoryId = id;
    navigate(`/new-order/${category}/${categoryId}`);
  };

  const categories = categoriesList?.categories || [];
  const allCategories = { id: 1, therapeuticType: 'All Categories', img: allImg };
  const smallScreen = useMediaQuery('(max-width: 991px)');
  const scrollToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
    }
  };

  return (
    <Box>
      {!smallScreen ? (
        <CategoriesWrapper>
          <CategoryGrid>
            <AllCategoriesPaper active={isSelected === 'All Categories'} onClick={() => handleAllSelect()}>
              <CardBox>
                <AllCat />
                <CategoryText>
                  {allCategories.therapeuticType}
                </CategoryText>
              </CardBox>
            </AllCategoriesPaper>
            {categories.map((category, index) => (
              <CardPaper
                active={isSelected === category.therapeuticType}
                key={category.id}
                onClick={() => handleSelect(category.id, category.therapeuticType)}
              >
                <CardBox>
                  <CategoryImg src={categoryImg[index]} alt={category.therapeuticType} />
                  <CategoryText>
                    {category.therapeuticType}
                  </CategoryText>
                </CardBox>
              </CardPaper>
            ))}
          </CategoryGrid>
        </CategoriesWrapper>
      )
        : (
          <CategoriesWrapper productPage={productPage}>
            <CategoryGrid container ref={carouselRef}>
              <CardPaper active={isSelected === 'All Categories'} onClick={() => handleAllSelect()}>
                <CardBox>
                  <CategoryImg src={allCategories.img} alt={allCategories.therapeuticType} />
                  <CategoryText>
                    {allCategories.therapeuticType}
                  </CategoryText>
                </CardBox>
              </CardPaper>
              {categories.map((category, index) => (
                <CardPaper
                  active={isSelected === category.therapeuticType}
                  key={category.id}
                  onClick={() => handleSelect(category.id, category.therapeuticType)}
                >
                  <CardBox>
                    <CategoryImg src={categoryImg[index]} alt={category.therapeuticType} />
                    <CategoryText>
                      {category.therapeuticType}
                    </CategoryText>
                  </CardBox>
                </CardPaper>
              ))}
              {/* <NextImage
                src={NextButton}
                onClick={() => scrollToNext()}
              /> */}
            </CategoryGrid>
          </CategoriesWrapper>
        )}
    </Box>
  );
}

CategoriesContainer.propTypes = {
  // handleSelect: PropTypes.func.isRequired,
  // handleCategories: PropTypes.func.isRequired,
  isSelected: PropTypes.isRequired
};
