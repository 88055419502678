import AffiliateActionTypes from './affiliateTypes';

const affiliateReducer = (affiliate, action) => {
  switch (action.type) {
    case AffiliateActionTypes.UPDATE_AFFILIATE:
      return {
        ...affiliate,
        ...action.payload
      };
    case AffiliateActionTypes.TOGGLE_DIALOG_OPEN:
      return {
        ...affiliate,
        addAffiliateDialogOpen: !affiliate.addAffiliateDialogOpen
      };
    default:
      return affiliate;
  }
};

export default affiliateReducer;
