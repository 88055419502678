import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  CustomCheckbox, ColorGrid, StatusColor, StatusText
} from './returnRow.styles';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';

const ReturnRow = ({
  row, selected, handleSelect
}) => {
  const isSelected = () => selected.indexOf(row.id) !== -1;
  const {
    brandName, resolvedPriceInUseValue,
    orderCost, supplier, manufacturer, promoStatus, packSize
  } = row;

  const price = `₦ ${currencyFormatter(resolvedPriceInUseValue)}`;
  const cost = `₦ ${currencyFormatter(orderCost)}`;
  const getStatus = () => {
    if (promoStatus === 'Promo Set') {
      return (
        <Box style={{ display: 'flex' }}>
          <StatusColor active />
          <StatusText>Promo Set</StatusText>
        </Box>
      );
    }
    return (
      <Box style={{ display: 'flex' }}>
        <StatusColor />
        <StatusText>No Promo</StatusText>
      </Box>
    );

    // if (promoStatus === 'Promo Set') return <ColorGrid item container>Promo Set</ColorGrid>;
    // return <ColorGrid item container active>No Promo</ColorGrid>;
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  return (
    <TableColumnRows>
      <TableColumnRowText>{renderCheckbox()}</TableColumnRowText>
      <TableColumnRowText>{brandName}</TableColumnRowText>
      <TableColumnRowText>{getStatus()}</TableColumnRowText>
      <TableColumnRowText>{packSize}</TableColumnRowText>
      <TableColumnRowText>{manufacturer}</TableColumnRowText>
      <TableColumnRowText>{supplier?.name}</TableColumnRowText>
      <TableColumnRowText>{cost}</TableColumnRowText>
      <TableColumnRowText>{price}</TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  selected: []
};

export default ReturnRow;
