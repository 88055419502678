import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Slide
} from '@mui/material';
import {
  DialogActionButtonText, DialogCancelButton, DialogOkButton
} from '../../../cart/cart.styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const RemoveUserDialog = ({
  open, setOpen, deleteUser
}) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setOpen(false)}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">Delete User</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to delete this user!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <DialogCancelButton
        onClick={() => setOpen(false)}
      >
        <DialogActionButtonText>
          Cancel
        </DialogActionButtonText>
      </DialogCancelButton>
      <DialogOkButton onClick={deleteUser}>
        <DialogActionButtonText>
          OK
        </DialogActionButtonText>
      </DialogOkButton>
    </DialogActions>
  </Dialog>
);

RemoveUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

export default RemoveUserDialog;
