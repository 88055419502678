import {
  TableRow, TableCell, Checkbox, Grid, Box, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #78AADA;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const ColorGrid = styled(Grid)`
  background: ${({ active }) => (active ? '#E1FFE2' : '#FBDADA')};
  border-radius: 7px;
  text-transform: capitalize;
  color: ${({ active }) => (active ? '#07DE10' : '#FA3636')};
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  width: 110px;
  height: 40px;
  line-height: 40px;
`;

export const StatusColor = styled(Box)`
  width: 0.563rem;
  height: 0.563rem;
  background: ${({ active }) => (active ? '#00B588' : '#FF0F0F')};
  border-radius: 50%;
  margin-top: 7px;
`;

export const StatusText = styled(Typography)`
  margin-left: 10px;
`;
