import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputLabel, Select, MenuItem
} from '@mui/material';
import {
  SelectNameTypo, FieldCheckbox, CheckboxImage, FormField
} from './returnSelectField.styles';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../assets/images/supplier/Checkbox-resting.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ReturnSelectField = ({
  field, value, handleChange, size,
  handleCreditDaysOpen, creditDays, fullWidth, showCheckBox, showLabel
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    const { value: _val } = event.target;
    setSelected(event.target.value);
    handleChange(event);

    if (_val === 'On-credit payment') {
      handleCreditDaysOpen(selectRef.current);
    }
  };

  const renderFunc = (val) => {
    if (val === 'On-credit payment') {
      return `${creditDays} credit days`;
    }
    return val;
  };

  return (
    <FormField
      fullWidth={fullWidth}
      variant="filled"
      size={size}
      ref={selectRef}
    >
      { showLabel && <InputLabel id="select-label">{field.label}</InputLabel> }
      <Select
        labelId="select-filled-label"
        id="select-filled"
        value={value}
        renderValue={(val) => renderFunc(val)}
        onChange={handleInput}
        inputProps={{
          name: field.name,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {field.options?.map(
          (option) => (
            <MenuItem key={option} value={option}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <SelectNameTypo>{option}</SelectNameTypo>
                { showCheckBox && (
                <FieldCheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected.indexOf(option) > -1}
                />
                )}
              </Grid>
            </MenuItem>
          )
        )}
      </Select>
    </FormField>
  );
};

ReturnSelectField.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  size: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleCreditDaysOpen: PropTypes.func.isRequired,
  creditDays: PropTypes.number,
  fullWidth: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  showLabel: PropTypes.bool
};

ReturnSelectField.defaultProps = {
  field: {},
  value: '',
  size: 'small',
  creditDays: 0,
  fullWidth: false,
  showCheckBox: true,
  showLabel: true
};

export default ReturnSelectField;
