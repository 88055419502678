import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Grid, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  TableContainerWrapper, TableComponent, TableHeader,
  MainTableHead, TableGrid, MainTableBody, PrimaryTitle, TitleGridContainer, TitleTextGridContainer, CreateRole, TextWrapper,
  CreateUser, ViewUsers
} from './roles.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import Filter from './filter';
import CreateRoleDialog from './createRoleDialog/CreateRoleDialog';
import { ALL_BUSINESS_ROLES_QUERY } from '../../../queries/businesses';
import BusinessActionTypes from '../../../providers/reducers/business/businessTypes';
import { useStateValue } from '../../../providers/stateProvider';
import CreateUserDialog from '../users/createUserDialog/CreateUserDialog';
import { DivFullWidth } from '../users/usersContainer.styles';
import { addHyphen } from '../../shared/methods';

const headers = [
  'Roles', 'Users', 'Actions',
];

const startDate = moment().startOf('day').format('YYYY-MM-DD');
const endDate = moment().endOf('day').format('YYYY-MM-DD');
const initialState = {
  searchText: '',
  dateFilter: {
    dateFrom: startDate,
    dateTo: endDate
  }
};

const Roles = () => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [, dispatch] = Object.values(useStateValue());
  const [pageCount, setPageCount] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [createUserDialog, setCreateUserDialog] = useState(false);
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());
  const { searchText } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleUserDialog = () => {
    setCreateUserDialog(!createUserDialog);
  };

  const toggleDialog = () => {
    setOpen(!open);
  };

  const handleViewUsers = () => navigate('/business-users');
  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} data-testid={`mp-uat-roles-${addHyphen(header)}`}>{header}</TableHeader>
  ));

  const variables = {
    pageCount,
    pageNumber,
    search: searchValue
  };

  const {
    loading, error, data, refetch
  } = useQuery(ALL_BUSINESS_ROLES_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
  });

  // if (error) return <div>{error.message}</div>;
  const {
    allSystemRoles = [],
  } = data || {};

  const rows = allSystemRoles;
  return (
    <>
      <TitleGridContainer container item xs={12} justifyContent="space-between">
        <TitleTextGridContainer item md={5}>
          <PrimaryTitle variant="h5">Roles and Permissions</PrimaryTitle>
        </TitleTextGridContainer>
        <Grid container md={7} spacing={2} justifyContent="flex-end">
          <ViewUsers
            data-testid="export"
            item
            onClick={handleViewUsers}
            disabled={!userPermissions?.includes('role_permissions_view_users')}
          >
            <TextWrapper>
              View All Users
            </TextWrapper>
          </ViewUsers>

          <CreateUser
            data-testid="export"
            item
            onClick={() => setCreateUserDialog(true)}
            disabled={!userPermissions?.includes('role_permissions_add_user')}
          >
            <TextWrapper>
              Create Users
            </TextWrapper>
          </CreateUser>
          <CreateRole
            data-testid="export"
            item
            onClick={toggleDialog}
            disabled={!userPermissions?.includes('role_permissions_create_new_role')}
          >
            <TextWrapper>
              Create Role
            </TextWrapper>
          </CreateRole>
        </Grid>
      </TitleGridContainer>
      <TableContainerWrapper elevation={0}>
        <Filter
          state={state}
          handleSearch={handleSearch}
        />
        <TableGrid item container>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableComponent item container data-testid="mp-uat-roles-table">
                <MainTableHead item container>
                  {returnHeaders()}
                </MainTableHead>
                <MainTableBody item container>
                  {rows.map((_row) => (
                    <ReturnRow
                      row={_row}
                      key={_row}
                    />
                  ))}
                </MainTableBody>
              </TableComponent>
            )}
        </TableGrid>
      </TableContainerWrapper>
      <CreateRoleDialog
        dialogOpen={open}
        closeDialog={() => toggleDialog()}
        refetch={refetch}
      />
      <CreateUserDialog
        open={createUserDialog}
        close={handleUserDialog}
      />
    </>
  );
};

export default Roles;
