import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  BodyCell, MainTableRow, CustomCheckbox, ColorGrid, Dot,
} from './returnRow.styles';
import { parseJson } from '../../affiliates/utils';

const ReturnRow = ({
  row, selected, handleSelect, index
}) => {
  const navigate = useNavigate();
  const isSelected = () => selected.indexOf(row.id) !== -1;
  const {
    id, name, contacts, status: statuses, dateCreated, salesRepresentative, businessCategory
  } = row;
  const { mobile_number: mobile, primary_contact_name: accountHandler, primary_email_address: email } = parseJson(contacts);
  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');
  const handleClick = () => {
    navigate(`/manage-affiliates/${id}/details`);
  };

  const getColorGrid = (color, bkg, status) => (
    <ColorGrid item container className={`mpAdmin-uat-manageAffiliatePage-${status}`}>
      <Dot background={bkg} />
      {status}
    </ColorGrid>
  );
  const getStatus = () => {
    switch (statuses) {
      case 'VERIFIED':
        return getColorGrid('#07DE10', '#00B588', 'Verified');
      case 'UNVERIFIED':
        return getColorGrid('#606060', '#F29053', 'Unverified');
      case 'SUSPENDED':
        return getColorGrid('#FA3636', '#FF0F0F', 'Suspended');
      default:
        return getColorGrid('white', '#BF0505', 'N/A');
    }
  };

  const erpCells = [name, businessCategory?.categoryName, salesRepresentative, email, mobile];

  const returnCell = (val) => val || 'N/A';

  const renderBodyCells = () => erpCells.map((cell) => (
    <BodyCell key={cell} onClick={() => handleClick()} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select affiliate' }}
    />
  );

  return (
    <MainTableRow style={index % 2 ? { backgroundColor: ' #F3F9FF' } : { backgroundColor: '#FFF' }}>
      <BodyCell />
      {/* <BodyCell>{renderCheckbox()}</BodyCell> */}
      <BodyCell>{renderDate}</BodyCell>
      {renderBodyCells()}
      <BodyCell>
        {getStatus()}
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
  index: PropTypes.instanceOf(Number),
};

ReturnRow.defaultProps = {
  row: {},
  selected: [],
  index: 0,
};

export default ReturnRow;
