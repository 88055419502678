import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid, TextField
} from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import ReturnSelectField from '../shared/crudModel/uploadModel/individual/returnSelectField';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader,
  GridWrapper, TopGrid, MiddleGrid, BottomGrid
} from '../suppliers/individual/individualSupplierDialog.styles';
import { validateEmail } from '../auth/utils';
import { CREATE_LOAN_MUTATION } from '../../mutations/loans';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const MCFDialog = ({
  dialogOpen, closeDialog, returnBusinessData,
  oldState
}) => {
  const initialState = {
    businessName: '',
    applicantName: '',
    phoneNumber: '',
    cartAmount: 0,
    provider: 'MCF',
    email: '',
    address: '',
    city: 'Lagos',
    state: 'Lagos',
    country: 'Nigeria',
  };

  const initialErrorState = {
    businessNameError: false,
    applicantNameError: false,
    cartAmountError: false,
    phoneNumber: false,
    emailError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);

  useEffect(() => {
    const [
      businessName, ownerName, phoneNumber, email,
      address, city, country
    ] = returnBusinessData();
    const {
      cart, serviceFee, deliveryFee, priceFactor
    } = oldState;
    const orderTotal = cart.reduce((sum, obj) => sum + (Number(obj.quantity) * Number(obj.resolvedPriceInUseValue)), 0) * priceFactor;
    const cartAmount = serviceFee + deliveryFee + orderTotal;
    setState((_state) => ({
      ..._state, businessName, applicantName: ownerName, phoneNumber, email,
      address, city, country, cartAmount
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldState]);

  const { businessNameError } = errorState;

  const handleCloseDialog = () => {
    setState(initialState);
    closeDialog();
  };

  const [createLoan] = useMutation(CREATE_LOAN_MUTATION);

  const handleCreateLoan = () => {
    setLoading(true);
    const {
      businessName, applicantName, phoneNumber, cartAmount,
      provider, email, address, city, state: theState, country
    } = state;
    createLoan({
      variables: {
        businessName, applicantName, phoneNumber, cartAmount,
        provider, email, address, city, state: theState, country
      }
    })
      .then(({ data }) => {
        const { message } = data?.createLoan || {};
        toast.success(message);
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    handleCreateLoan();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'businessName':
        return setErrorState({
          ...errorState,
          businessNameError: !(value.length > 1)
        });
      case 'applicantName':
        return setErrorState({
          ...errorState,
          applicantNameError: !(value.length > 1)
        });
      case 'phoneNumber':
        return setErrorState({
          ...errorState,
          phoneNumberError: !(value.length > 7)
        });
      case 'cartAmount':
        return setErrorState({
          ...errorState,
          cartAmountError: !(value !== 0)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'businessName',
      label: 'Business Name',
      helperText: 'Business name required'
    },
    {
      name: 'applicantName',
      label: "Applicant's Name",
      helperText: "Applicant's name required"
    },
    { name: 'phoneNumber', label: 'Phone Number' },
    { name: 'email', label: 'Email' },
    { name: 'cartAmount', label: 'Cart Amount', disabled: true },
    { name: 'provider', label: 'Provider', disabled: true }
  ];

  const middleFields = [
    { name: 'address', label: 'Address' },
  ];

  const bottomFields = [
    { name: 'city', label: 'City' },
    { name: 'state', label: 'State' },
    {
      name: 'country',
      label: 'Country',
      options: ['Nigeria', 'Uganda', 'Kenya']
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, disabled = false
    } = field;
    const value = state[fieldName];
    if (fieldName === 'term' || fieldName === 'country') {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
        />
      );
    }
    return (
      <TextField
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={fieldName === 'phoneNumber' ? 'number' : 'text'}
        error={fieldName === 'businessName' ? businessNameError : false}
        helperText={businessNameError && helperText}
        name={fieldName}
        disabled={disabled}
        onChange={handleChange}
      />
    );
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <Grid container>
        <GridWrapper container item>
          <DialogTitle id="alert-dialog-slide-title">
            <DialogTitleWrapper container>
              <DialogTitleText>
                Loan Details
              </DialogTitleText>
              <DialogTitleSubText>
                Review, edit and submit the details below
              </DialogTitleSubText>
            </DialogTitleWrapper>
          </DialogTitle>
          <DialogContent>
            <TopGrid item>
              {topFields.map((field) => returnTextField(field))}
            </TopGrid>
            <MiddleGrid item>
              {middleFields.map((field) => returnTextField(field))}
            </MiddleGrid>
            <BottomGrid item>
              {bottomFields.map((field) => returnTextField(field))}
            </BottomGrid>
          </DialogContent>

          <DialogActions>
            <DialogCancelButton
              onClick={handleCloseDialog}
            >
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </DialogCancelButton>

            <DialogOkButton
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : (
                <DialogActionButtonText>
                  Submit
                </DialogActionButtonText>
              )}
            </DialogOkButton>
          </DialogActions>
        </GridWrapper>
      </Grid>
    </Dialog>
  );
};

MCFDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  returnBusinessData: PropTypes.func.isRequired,
  oldState: PropTypes.instanceOf(Object)
};

MCFDialog.defaultProps = {
  oldState: {}
};

export default MCFDialog;
