import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { StatusColor, StatusText } from './returnRow.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { parseJson } from '../../affiliates/utils';

const ReturnRow = ({
  row, state
}) => {
  const navigate = useNavigate();
  const {
    id, datePlaced, status, xeroStatus, metaStatus, business, paymentSummary
  } = row;
  const paymentValue = parseJson(paymentSummary);
  const total = paymentValue?.total;
  const affiliateName = business?.name;
  const estDelivery = moment(datePlaced).add(2, 'days').format('DD/MM/YY');

  const renderDate = moment(datePlaced).format('DD/MM/YY (h:mm)');
  const value = currencyFormatter(total);

  const getColorGrid = (color, bkg, name) => (
    <Box style={{ display: 'flex' }}>
      <StatusColor bkg={bkg} />
      <StatusText>{name}</StatusText>
    </Box>
  );

  const getStatus = () => {
    if (status.includes('MANUAL')) {
      return getColorGrid('#303030', '#00B588', 'in-house');
    }
    if (status === 'CLOSED') {
      return getColorGrid('#FA3636', '#FF0F0F', 'closed');
    }
    if (status === 'OPEN') {
      switch (xeroStatus) {
        case 'authorised':
          return getColorGrid('#07DE10', '#00B588', 'authorized');
        case 'paid':
          return getColorGrid('#07DE10', '#00B588', 'paid');
        case 'submitted':
          return getColorGrid('#303030', '#00B588', 'submitted');
        case 'draft':
          return getColorGrid('#303030', '#00B588', 'draft');
        case 'deleted':
          return getColorGrid('#FA3636', '#FF0F0F', 'deleted');
        case 'voided':
          return getColorGrid('#FA3636', '#FF0F0F', 'voided');
        default:
          return getColorGrid('#FA3636', '#FF0F0F', 'new');
      }
    }
  };

  const handleRoute = () => {
    if (status === 'CLOSED') {
      return navigate(`/closed-order/${id}`);
    }
    if (status.includes('MANUAL')) {
      switch (metaStatus) {
        case 'ORDER_SENT':
        case 'ORDER_RECEIVED':
          return navigate(`/all-po-products/${id}`);
        default:
      }
    }
    return navigate(`/affiliate-order/${id}/${state.orderStatus}`);
  };

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '200px' }}>{renderDate}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '200px' }} onClick={handleRoute}>{`#${id}`}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{value || '-' }</TableColumnRowText>
      <TableColumnRowText style={{ width: '320px' }}>{affiliateName || '-'}</TableColumnRowText>
      <TableColumnRowText style={{ width: '220px' }}>{estDelivery || '-'}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{getStatus()}</TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
  state: PropTypes.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
