import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid, Paper, TableRow, TableBody, Grow
} from '@mui/material';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleWrapper, GridWrapper, SupplierTextField, SupplierItem, OrderSubHeader,
  ProductItemsContainer, ProductItemsTotal, IconsGridContainer, TableContainerWrapper,
  TableComponent, MainTableHead, CustomCheckbox, HeaderCell, MainTableRow, BodyCell,
  SearchPaper, SearchPopper
} from './alternativeSuppliers.styles';
import { CREATE_MANUAL_REORDER_MUTATION } from '../../../../mutations/orders';
import { FIND_SUPPLIERS_QUERY } from '../../../../queries/suppliers';
import { toTitleCase } from '../../../../utils/toTitleCase';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AlternativeSupplierDialog = ({
  dialogOpen, closeDialog, products, clearNotReceived,
  closeOrder
}) => {
  const initialState = {
    id: '',
    supplier: {},
  };

  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedSupplier, setSearchedSupplier] = useState([]);
  const [searchingSuppliers, setSearchingSuppliers] = useState({});
  const [selected, setSelected] = useState([]);
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);

  const searchEls = useRef({});

  const { supplier } = state;

  const [createManualReorder] = useMutation(CREATE_MANUAL_REORDER_MUTATION);

  const handleCloseDialog = () => {
    setState(initialState);
    closeDialog();
  };

  const handleReorder = async () => {
    const items = async () => Promise.all(
      products.map(({ id }) => {
        const { prodQty, supId } = supplier[id];
        return createManualReorder({
          variables: {
            cart: [{ productId: id, quantity: prodQty }],
            supplierId: supId
          }
        });
      })
    );
    return items().then(() => {
      setState(initialState);
      closeDialog();
      clearNotReceived();
      closeOrder();
    }).catch((err) => console.log('err :>>>> ', err));
  };

  const [findSupplier, { loading, data }] = useLazyQuery(FIND_SUPPLIERS_QUERY);
  useEffect(() => {
    if (data && data.findSupplier) {
      const suppliers = data.findSupplier
        .filter(({ supplierProductsCount }) => supplierProductsCount > 1)
        .map(({ id, name }) => ({ id, name }));
      setSearchedSupplier(suppliers);
    }
    if (loading && !data) setIsLoading(true);
    else setIsLoading(false);
  }, [data, loading]);

  const handleSupplierChange = (event, prod) => {
    const { value } = event.target;
    const supplierValue = supplier;
    supplierValue[prod.id] = { name: value, prodQty: prod.quantity };
    setState({ ...state, supplier: supplierValue });

    if (value.length > 2) {
      setSearchingSuppliers({
        ...searchingSuppliers,
        [prod.id]: true
      });
      findSupplier({
        fetchPolicy: 'no-cache',
        variables: { search: value }
      });
    }
  };

  const handleSetSupplier = (sup, prod) => {
    const { id, name } = sup;
    const supplierValue = supplier;
    supplierValue[prod.id] = {
      name,
      supId: id,
      ...(prod.quantity && { prodQty: prod.quantity })
    };
    setState({ ...state, supplier: supplierValue });
    if (prod.id === 'all' && selected.length === products.length) {
      const newSupplier = {};
      products.forEach(({ id: prodId, quantity }) => {
        newSupplier[prodId] = {
          name,
          supId: id,
          prodQty: quantity
        };
      });
      setState({ ...state, supplier: { ...supplier, ...newSupplier } });
    }
    setSearchingSuppliers({
      ...searchingSuppliers,
      [prod.id]: false
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSearchFieldFocused(true);
      const newSelections = products.map((product) => product.id);
      return setSelected(newSelections);
    }
    setSearchFieldFocused(false);
    return setSelected([]);
  };

  const mainCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === products.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const isSelected = (prod) => selected.indexOf(prod.id) !== -1;

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const bodyCheckbox = (prod) => (
    <CustomCheckbox
      size="small"
      checked={isSelected(prod)}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, prod.id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const buttonEls = (el, prodId) => {
    if (el) {
      searchEls.current = {
        ...searchEls.current,
        [prodId]: el
      };
    }
  };

  let textId = 0;
  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      fullWidth
      fileslimit={1}
    >
      <Grid container style={{ backgroundColor: '#F0F0F0' }}>
        <GridWrapper container item>
          <DialogTitle id="alert-dialog-slide-title">
            <DialogTitleWrapper container>
              <DialogTitleText>
                Receive products from other suppliers
              </DialogTitleText>
            </DialogTitleWrapper>
          </DialogTitle>
          <DialogContent>
            <OrderSubHeader container item>
              <ProductItemsContainer container item xs={12} md={6}>
                <ProductItemsTotal>
                  {`${products.length} Product(s)`}
                </ProductItemsTotal>
              </ProductItemsContainer>
              <IconsGridContainer item container md={6}>
                <SupplierTextField
                  variant="outlined"
                  label="Search supplier"
                  focused={selected.length === products.length}
                  value={supplier?.all?.name}
                  size="small"
                  name="supplier"
                  onChange={(e) => handleSupplierChange(e, { id: 'all' })}
                  ref={(el) => buttonEls(el, 'all')}
                />
                <SearchPopper
                  open={searchingSuppliers.all}
                  placement="bottom-left"
                  anchorEl={searchEls.current.all}
                  transition
                  disablePortal
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'viewport',
                    },
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <SearchPaper elevation={0} square>
                        {/* <ClickAwayListener onClickAway={() => handleCloseDropdown(prod.id)}> */}
                        {searchedSupplier?.map((s) => (
                          <SupplierItem onClick={() => handleSetSupplier(s, { id: 'all' })}>{s.name}</SupplierItem>
                        ))}
                        {/* </ClickAwayListener> */}
                      </SearchPaper>
                    </Grow>
                  )}
                </SearchPopper>
              </IconsGridContainer>
            </OrderSubHeader>
            <TableContainerWrapper component={Paper}>
              <TableComponent aria-label="supplier products table">
                <MainTableHead>
                  <TableRow>
                    <HeaderCell>{mainCheckbox()}</HeaderCell>
                    <HeaderCell>Product Name</HeaderCell>
                    <HeaderCell>Pack Size</HeaderCell>
                    <HeaderCell>Qty</HeaderCell>
                    <HeaderCell>Supplier</HeaderCell>
                  </TableRow>
                </MainTableHead>
                <TableBody>
                  {products.map((prod) => (
                    <MainTableRow>
                      <BodyCell>{bodyCheckbox(prod)}</BodyCell>
                      <BodyCell component="th" scope="row">
                        {toTitleCase(prod.name)}
                      </BodyCell>
                      <BodyCell>{prod.meta.pack_size}</BodyCell>
                      <BodyCell>{prod.quantity}</BodyCell>
                      <BodyCell>
                        <SupplierTextField
                          id={`text-id-${textId++}`}
                          variant="outlined"
                          label=""
                          value={supplier[prod.id]?.name}
                          size="small"
                          name="supplier"
                          onChange={(e) => handleSupplierChange(e, prod)}
                          InputLabelProps={{ shrink: false }}
                          ref={(el) => buttonEls(el, prod.id)}
                        />
                        <SearchPopper
                          open={searchingSuppliers[prod.id]}
                          placement="bottom-left"
                          anchorEl={searchEls.current[prod.id]}
                          transition
                          disablePortal
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: 'viewport',
                            },
                          }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              id="menu-list-grow"
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <SearchPaper elevation={0} square>
                                {/* <ClickAwayListener onClickAway={() => handleCloseDropdown(prod.id)}> */}
                                {searchedSupplier?.map((s) => (
                                  <SupplierItem onClick={() => handleSetSupplier(s, prod)}>{s.name}</SupplierItem>
                                ))}
                                {/* </ClickAwayListener> */}
                              </SearchPaper>
                            </Grow>
                          )}
                        </SearchPopper>
                      </BodyCell>
                    </MainTableRow>
                  ))}
                </TableBody>
              </TableComponent>
            </TableContainerWrapper>
          </DialogContent>

          <DialogActions>
            <DialogCancelButton
              onClick={handleCloseDialog}
            >
              <DialogActionButtonText>
                Cancel
              </DialogActionButtonText>
            </DialogCancelButton>
            <DialogOkButton
              onClick={handleReorder}
            >
              {/* {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : ( */}
              <DialogActionButtonText>
                Done
              </DialogActionButtonText>
              {/* )} */}
            </DialogOkButton>
          </DialogActions>
        </GridWrapper>
      </Grid>
    </Dialog>
  );
};

AlternativeSupplierDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  clearNotReceived: PropTypes.func.isRequired,
  closeOrder: PropTypes.func.isRequired,
  businessState: PropTypes.instanceOf(Object),
  updateOrder: PropTypes.bool,
  products: PropTypes.instanceOf(Array),
};

AlternativeSupplierDialog.defaultProps = {
  businessState: {},
  updateOrder: false,
  products: []
};

export default AlternativeSupplierDialog;
