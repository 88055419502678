import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ClickAwayListener } from '@mui/material';
import {
  InfoLogo, ServiceText, PopperContainer, WrapperPaper,
} from './serviceFeePopper.styles';

const ServiceFeePopper = ({ action, handleOpenTip }) => {
  const open = Boolean(action);

  return (
    <PopperContainer
      anchorEl={action}
      placement="top"
      open={open}
    >
      <ClickAwayListener onClickAway={handleOpenTip}>
        <WrapperPaper>
          <Grid container item>
            <Grid item xs={3}>
              <InfoLogo />
            </Grid>
            <Grid item xs={9}>
              <ServiceText>
                Please note, that the service charge helps us run our app, operations, and support teams. It is a flat fee of ₦200.
              </ServiceText>
            </Grid>
          </Grid>
        </WrapperPaper>
      </ClickAwayListener>
    </PopperContainer>
  );
};

ServiceFeePopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleOpenTip: PropTypes.func.isRequired
};

export default ServiceFeePopper;
