import React from 'react';
import { useNavigate } from 'react-router';
import ModelContainer from '../shared/crudModel/modelsContainer';
import { FIND_CUSTOMERS_QUERY } from '../../queries/customers';
import CustomerCard from './customerCard';
import IndividualCustomerDialog from './individualCustomerDialog';

function Customers() {
  const navigate = useNavigate();
  // const [, dispatch] = Object.values(useStateValue());
  const loop = false;
  return (
    <ModelContainer
      findModelsQuery={FIND_CUSTOMERS_QUERY}
      modelName="Customer"
      modelNamePlural="Customers"
      modelTotalNumber="businessTotalNumber"
      modelQueryName="findCustomer"
      loop={loop}
      renderIndividualModel={(addModelDialogOpen, toggleIndividualDialog) => (
        <IndividualCustomerDialog
          dialogOpen={addModelDialogOpen}
          closeDialog={toggleIndividualDialog}
        />
      )}
      renderModelCard={(model) => (
        <CustomerCard
          modelData={model}
          // id={model.id}
          // customer={model}
        />
      )}

      // eslint-disable-next-line react/jsx-props-no-multi-spaces
      modelCardOnclickAction={(model) => navigate(`/customers/${model.id}/details`)}
    />
  );
}

export default Customers;
