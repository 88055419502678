import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Typography, Box, MenuItem
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import {
  CDialog, DialogTitleText, CircularProgressLoader, TextFieldBox, CTextField,
  GridWrapper, CloseIcon, FieldHeader
} from './createUserDialog.styles';
import { CREATE_OUTLET_USER } from '../../../../mutations/outlets';
import { ALL_BUSINESS_ROLES_QUERY } from '../../../../queries/businesses';
import SuccessDialog from '../successDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateUserDialog = ({
  open, close
}) => {
  const initialState = { fullName: '', email: '', roleId: '' };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [createOutletUser] = useMutation(CREATE_OUTLET_USER);
  const [successText, setSuccessText] = useState({ fullName: '', roleName: '' });
  const { fullName, email, roleId } = state;

  const textFieldHeader = [
    {
      name: "User's Name", type: 'fullName',
      label: "Enter User's Full Name"
    },
    {
      name: "User's Email Address", type: 'email',
      label: "Enter User's Email Address"
    },
  ];

  const roleVariables = {
    pageCount: 50,
    pageNumber: 1,
  };

  const {
    loading: roleLoading, data: roleData
  } = useQuery(ALL_BUSINESS_ROLES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: roleVariables
  });

  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  // const status = [
  //   { name: 'Chain Manager', value: '1' },
  //   { name: 'Super Admin', value: '2' },
  //   { name: 'Sales Representatives', value: '3' },
  //   { name: 'Store Manager', value: '4' },
  // ];

  const {
    allSystemRoles = [],
  } = roleData || {};
  const status = allSystemRoles;

  const selectField = {
    name: 'Role', type: 'roleId',
    label: 'Select Role'
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'fullName') setSuccessText({ ...successText, fullName: value });
    if (name === 'roleId') {
      const findName = status.find((val) => val.id === value);
      setSuccessText({ ...successText, roleName: findName.name });
    }
    setState({ ...state, [name]: value });
  };

  const formValidation = () => {
    const emailValidation = (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(email);
    if (!fullName || fullName.length < 3) {
      toast.error("User's Full Name is required");
      return false;
    }
    if (!email || !emailValidation) {
      toast.error('Enter a valid Email Address');
      return false;
    }
    if (!roleId) {
      toast.error('Select Role');
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!formValidation()) return;
    setLoading(true);
    const variables = { fullName, email, roleId };
    createOutletUser({ variables })
      .then(({ data }) => {
        // const { message } = data?.createOutletUser || {};
        setState(initialState);
        close();
        setSuccessDialog(true);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const returnDescription = () => (
    <Typography>
      Hi Pharm, &nbsp;
      <b>
        {successText.roleName}
      </b>
      &nbsp; has been assigned
      <br />
      to &nbsp;
      <b>
        {successText.fullName}
      </b>
    </Typography>
  );

  const textField = (field, select) => {
    const {
      name: fieldName, label, shrink, type
    } = field;
    const _value = state[type];
    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          label={label}
          value={_value}
          size="large"
          name={type}
          onChange={handleChange}
          type={!select ? 'text' : ''}
          select={select}
          fullWidth
          required
          InputProps={{
            style: { height: '4rem', fontSize: '1rem', borderRadius: '.6rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '.9rem', textAlign: 'center' },
            shrink
          }}
          SelectProps={select && {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  borderRadius: '10px'
                }
              }
            }
          }}
        >
          {select && status.map(({ name, id }) => (
            <MenuItem
              key={id}
              value={id}
              style={{
                fontSize: '1rem', marginTop: '15px',
                padding: '1rem 2.5rem .5rem 2.5rem'
              }}
            >
              {name}
            </MenuItem>
          ))}
        </CTextField>
      </TextFieldBox>
    );
  };
  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  const select = true;

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ marginTop: '2.7rem', padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              Create User
            </DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Box>
          {textFieldHeader.map((fields) => (
            <Box>
              <FieldHeader>
                {fields.name}
              </FieldHeader>
              {textField(fields)}
            </Box>
          ))}
          <FieldHeader>
            Role
          </FieldHeader>
          {textField(selectField, select)}
        </Box>

        <DialogActions>
          <CustomButton
            type="tertiary"
            style={{
              width: '11rem', height: '3.2rem', marginRight: '1.2rem'
            }}
            onClick={handleCloseDialog}
          >
            Cancel
          </CustomButton>
          <CustomButton
            style={{ width: '11rem', height: '3.2rem' }}
            onClick={handleSave}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : 'Create User' }
          </CustomButton>
        </DialogActions>
      </GridWrapper>
      <SuccessDialog
        openConfirmation={successDialog}
        handleClose={handleSuccessDialog}
        title="User Created!"
        discreption={returnDescription()}
      />
    </CDialog>
  );
};

CreateUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default CreateUserDialog;
