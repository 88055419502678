import { gql } from '@apollo/client';

export const GET_STOCK_TRANSFERS = gql`
  query stockTransfers (
    $businesses: [String], $businessUserIds: [String], $dateFrom: DateTime
    $dateTo: DateTime, $pageCount: Int, $pageNumber: Int, $filter: String,
    $status: String, $search: String
  ) {
    stockTransfers(
      businesses: $businesses, businessUserIds: $businessUserIds, dateFrom: $dateFrom
      dateTo: $dateTo, pageCount: $pageCount, pageNumber: $pageNumber, filter: $filter,
      status: $status, search: $search
    ) {
      id
      status
      receiptNumber
      dateCreated
      businessFrom {
        id
        name
      }
      businessTo {
        id
        name
      }
      businessUser {
        id
        user {
          id
          username
          profile
        }
      }
      productBatches {
        productId
        brandName
        packSize
        batches {
          id
          transferQty
          batch {
            id
            batchNo
            name
            orderCost
          }
        }
      }
    }
    stockTransferTotalNumber
  }
`;

export const GET_LIVE_DAILY_STOCK_TRANSFERS = gql`
  query stockTransfers (
    $dateFrom: DateTime, $dateTo: DateTime, $status: String
  ) {
    stockTransfers(
      dateFrom: $dateFrom, dateTo: $dateTo, status: $status
    ) {
      id
      status
    }
  }
`;

export const GET_SEARCHABLE_STOCK_TRANSFERS = gql`
  query searchableStockTransfer (
    $id: Int, $search: String, $dateFrom: DateTime, $dateTo: DateTime,
    $pageCount: Int, $pageNumber: Int
  ) {
    searchableStockTransfer(
      id: $id, search: $search, dateFrom: $dateFrom, dateTo: $dateTo,
      pageCount: $pageCount, pageNumber: $pageNumber
    ) {
      result {
        date
        productId
        productName
        qtyTransferred
        batchNos
        totalProductValue
        transferLocation
        handler
        status
      }
      receiptNumber
    }
    stockTransferTotalNumber
  }
`;
