import {
  TableCell, Checkbox, Grid
} from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F4F5F6;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1), &:nth-child(2) {
      width: 6%;
    }
    &:nth-child(3) {
      width: 28%;
    }
    &:nth-child(4), &:nth-child(5), :nth-child(6), :nth-child(7), :nth-child(8) {
      width: 12%;
    }
  }
`;

const SoldOutStyles = css`
  background-color: #FBF777;
  color: #606060;
`;

const activeStyles = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;

const inactiveStyles = css`
  background-color: #FBDADA;
  color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'active':
      return activeStyles;
    case 'inactive':
      return inactiveStyles;
    default:
      return SoldOutStyles;
  }
};

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;
