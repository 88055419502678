import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdminLogo } from '../../assets/svgs';

export const GridContainer = styled(Grid)`
  flex-direction: column;
  background-color:  #F3F9FF;
  min-height: 100vh;
  padding: 2rem 3rem;

  @media(min-width: 992px) {
    padding: 2rem 6rem;
  }
`;

export const TopGrid = styled(Grid)`
  @media(max-width: 991px) {
    justify-content: center;
    margin-bottom: 4rem;
  }
`;

export const OgaAdminLogo = styled(AdminLogo)`
  &.MuiSvgIcon-root {
    width: 48%;
    height: 48%;
  }

  @media (min-width: 991px) {
    &.MuiSvgIcon-root {
      width: 15%;
      height: 15%;
    }
  }
`;

export const ImageContainer = styled(Grid)`
  height: fit-content;
`;

export const TextContainer = styled(Grid)`
  background-color: #e9f4ff;
  padding: 40px 32px;
  border-radius: 12px;

  @media(max-width: 991px) {
    margin-top: 3rem;
  }
`;

export const HeaderText = styled(Typography)`
  font-weight: 600;
  font-size: 28px;
  color: #235A91;
  margin-bottom: 1rem;

  @media(max-width: 991px) {
    font-size: 5rem;
  }
`;

export const InnerText = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  color: #3C6382;

  @media(max-width: 991px) {
    font-size: 3rem;
  }
`;

export const Img = styled('img')`
  width: 100%;

  @media (min-width: 992px) {
    width: 85%;
  }
`;

export const SpacemanLogo = styled(AdminLogo)`
  width: 15rem;
  height: 5rem;

  @media (max-width: 991px) {
    width: 28rem;
    height: 13rem;
  }
`;
