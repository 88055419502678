import {
  Grid, Typography, IconButton, Dialog, Popper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
    padding: 10px 25px;
    width: 20rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 200px
    }

    &:hover {
      background-color: #F3F9FF;
    }
`;

export const CDialog = styled(Popper)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 1.7rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1rem;
    padding: 2px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;
