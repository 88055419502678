import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const getButtonStyles = ({ type }) => {
  switch (type) {
    case 'left':
      return '4px 0 0 4px';
    case 'right':
      return '0 4px 4px 0';
    default:
      return 0;
  }
};

export const GroupButton = styled(Button)`
  text-transform: capitalize;
  color: #424242;
  border: 1px solid #E8E8E8;
  padding: 5px 12px;
  box-shadow: 0px 2px 3px #0000000D;
  background-color: ${({ active }) => (active ? '#FAF33E' : '#FFF')};
  border-radius: ${getButtonStyles};

  &:last-child {
    padding: 5px 10px;
  }
  &:hover {
    background-color: ${({ active }) => (active ? '#FAF33E' : '#EAEAEA')};
    box-shadow: 1px 3px 3px #0000000D;
  }
`;
