import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  TableBody, Grid
} from '@mui/material';
import MainContent from '../customComponents/mainContent';
import { GET_ALL_DATA_ANALYSIS } from '../../queries/dataAnalysis';
import {
  MainContainer, TableWrapper, TableContainer, TableColumnHeaders, TableColumnHeaderTitle,
  HeaderWrapper, PageTitleText
} from './dataAnalysis.styles';
import ReturnRow from './returnRow';
import withAuth from '../../utils/withAuth';

const DataAnalysis = () => {
  const [dataAnalysis, setDataAnalysis] = useState([]);

  const {
    data
  } = useQuery(GET_ALL_DATA_ANALYSIS, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (data) {
      const { allDataAnalysis } = data;
      const analysis = allDataAnalysis.map((item) => item);
      setDataAnalysis(analysis);
    }
  }, [data]);

  const tableHeaders = {
    id: (
      <TableColumnHeaderTitle>
        S/N
      </TableColumnHeaderTitle>),
    name: (
      <TableColumnHeaderTitle>
        Name
      </TableColumnHeaderTitle>),
    description: (
      <TableColumnHeaderTitle>
        Description
      </TableColumnHeaderTitle>),
  };

  return (
    <MainContent>
      <br />
      <MainContainer item container xs>
        <HeaderWrapper item container>
          <PageTitleText>Data Analysis</PageTitleText>
        </HeaderWrapper>
        <Grid container>
          <TableWrapper>
            <TableContainer style={{ padding: '28px', marginTop: '1em' }}>
              <TableColumnHeaders style={{ padding: 0 }}>
                {tableHeaders.id}
                {tableHeaders.name}
                {tableHeaders.description}
              </TableColumnHeaders>
              <TableBody>
                {dataAnalysis.map((item, index) => (
                  <ReturnRow
                    key={index}
                    item={item}
                    index={index}
                  />
                ))}
              </TableBody>
            </TableContainer>
          </TableWrapper>
        </Grid>
      </MainContainer>
    </MainContent>
  );
};

export default withAuth(DataAnalysis);
