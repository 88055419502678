import {
  Popper, Grid, ListItemText, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CPopper = styled(Popper)`
  z-index: 15000000
`;

export const PaperWrapper = styled(Paper)`
  width: 16rem;
  padding: 1.5rem 0;
  box-shadow: -1px 2px 8px 1px #00000017;
  transition: transform .3s;
  z-index: 500;
`;

export const CListItemText = styled(ListItemText)`

`;

export const TextGrid = styled(Grid)`
`;
