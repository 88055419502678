import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  ContainerGrid, InnerWrapper, CreditText, AddButton, AddText
} from './creditDaysPopper.styles';
import { RenderPopper } from '../../../utils/utils';

const CreditDaysPopper = ({
  creditDaysEl,
  handleCreditDaysOpen,
  creditDays,
  handleCreditDays,
}) => {
  const open = Boolean(creditDaysEl);
  const [days, setDays] = useState(0);

  useEffect(() => {
    setDays(creditDays);
  }, [creditDays]);

  return (
    <RenderPopper
      open={open}
      anchorEl={creditDaysEl}
      onClickAway={(e) => handleCreditDaysOpen(e)}
      popperPlacement="bottom-end"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <ContainerGrid container>
        <InnerWrapper container item xs={12}>
          <Grid item xs={8}>
            <CreditText
              name="creditDays"
              label="Add credit days"
              value={days}
              onChange={(e) => setDays(e.target.value)}
              size="small"
              type="number"
              margin="normal"
              variant="outlined"
              autoFocus
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 100
                }
              }}
            />
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <AddButton
              variant="contained"
              color="primary"
              onClick={() => handleCreditDays(days)}
            >
              <AddText display="inline" variant="h6">
                ADD
              </AddText>
            </AddButton>
          </Grid>
        </InnerWrapper>
      </ContainerGrid>
    </RenderPopper>
  );
};

CreditDaysPopper.propTypes = {
  creditDaysEl: PropTypes.instanceOf(Object),
  handleCreditDaysOpen: PropTypes.func.isRequired,
  creditDays: PropTypes.number,
  handleCreditDays: PropTypes.func.isRequired,
};

CreditDaysPopper.defaultProps = {
  creditDaysEl: {},
  creditDays: ''
};

export default CreditDaysPopper;
