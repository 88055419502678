import { styled } from '@mui/material/styles';
import {
  DialogTitle, DialogContentText, FormControlLabel, DialogActions,
} from '@mui/material';

export const ContainerHead = styled(DialogTitle)`
margin:20px 20px 0px 20px;
padding:0;
   h1{
    font-size:30px;
    font-weight: 600;
    color:#235A91;
    text-align: left;
   };
`;

export const DialogActionsWrapper = styled(DialogActions)`
  padding: 20px 0px 20px 30px;

`;

export const FormLabel = styled(FormControlLabel)`
background: #ECF6FF;
padding: 20px 0px;
font-weight: 600;
font-size: 16px;
margin-left: 0px;
line-height: 20px;
color: #385F84;

`;
export const Content = styled(DialogContentText)`
margin:5px 10px;
h2{
    color: #303030;
    opacity: 1;
    font-size: 24px;
    line-height: 30px;
    font-family:Montserrat;
    font-weight:500;
}
h3{
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}
h4{
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
}
 p{
     font-size: 14px;
     color: #606060;
     padding:0;
     font-weight: 400;
 }
  ul {
    counter-reset: item;
    padding-left: 0;
  }
  li{ display: block; }

  li:not(:last-child) {
    margin-bottom: 1em;
  }

  li > b:first-child {
    counter-increment: item;
  }

  li > b:first-child:before {
    content: counter(item) ". ";
  }

  ul ul {
    list-style-type: disc;
  }

  ul ul li {
    list-style-type: disc;
  }

  ul ul ul {
    list-style-type: disc;
  }

  ul ul ul li {
    list-style-type: disc;
  }

  ul ul ul li > b:first-child:before {
    content: none;
    counter-increment: none;
  }

  ul ul li:not(:last-child) {
    margin-bottom: 0.5em;
  }

  ul ul ul li:not(:last-child) {
    margin-bottom: 0.25em;
  }
`;
