const supplier = {
  supplierProductsCount: 0,
  suppliers: [],
  supplier: null,
  suppliersCount: 0,
  refetch: null,
  supplierDetailRefetch: null,
  addSupplierDialogOpen: false,
  receivedOrderBatch: [],
};

export default supplier;
