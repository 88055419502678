import { createTheme, styled } from '@mui/material/styles';
import {
  Typography, Grid, Divider, Button
} from '@mui/material';

export const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%'
      },
      root: {
        backgroundColor: '#FFF',
        borderColor: '#78AADA',
        minHeight: '50px',
      }
    }
  }
});

export const TitleText = styled(Typography)`
  font-size: .85rem;
  font-family: Montserrat,medium,sans-serif;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #000;

`;

export const Dash = styled(Divider)`
  width: 32%;
  margin: 2px 8px;
`;

export const MiddleText = styled(Typography)`
  font-family: Montserrat,medium,sans-serif;
  font-size: .72rem;
  color: rgba(0, 0, 0, 0.40);
  font-weight: 400;
  text-align: left;
`;
export const BackgroundImage = styled('img')`
  height: 70px;
  margin-bottom: 20px;
`;

export const MiddleGroup = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 30px 0 40px;
`;

export const BrowseFileButton = styled(Button)`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #235A91;
  background: #FBFDFE;
  color: #235A91;
  font-size: .7rem;
  font-style: normal;
  font-weight: 400;
`;

export const DropZoneContainer = styled(Grid)`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;
