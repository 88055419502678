import React from 'react';
import PropTypes from 'prop-types';
import CameraIcon from '../../../assets/images/Change Image Icon.png';
import DeleteIcon from '../../../assets/images/Delete.png';
import {
  GridWrapper, Image, ChangeImgButton, ChangeImgButtonIcon,
  ChangeImgButtonText,
} from './initialImage.styles';

const initialImage = ({
  image, handleImageChange, handleImageDelete
}) => (
  <GridWrapper container item>
    <ChangeImgButton onClick={handleImageChange}>
      <ChangeImgButtonIcon alt="change image" src={CameraIcon} />
      <ChangeImgButtonText variant="subtitle1">
        Change image
      </ChangeImgButtonText>
    </ChangeImgButton>
    <Image src={image} alt="image" />
    <ChangeImgButton onClick={handleImageDelete}>
      <ChangeImgButtonIcon alt="delete image" src={DeleteIcon} />
      <ChangeImgButtonText variant="subtitle1">
        Delete image
      </ChangeImgButtonText>
    </ChangeImgButton>
  </GridWrapper>
);
initialImage.propTypes = {
  image: PropTypes.string.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleImageDelete: PropTypes.func.isRequired,
};
export default initialImage;
