import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatusesGrid = styled(Grid)`
  // padding: 0.5rem 0rem;
  // align-items: center;
  // margin-bottom: .8rem;

  // /* @media(min-width: 992px) {
  //   padding: 1.8rem;
  // } */
  padding: .5rem 0;
  align-items: center;
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ active }) => (active ? '4px solid #235A91' : '4px solid #EEEEEE')};
  width: ${({ width }) => (width)};
  margin-top: ${({ marginTop }) => (marginTop)}
`;

export const Typo = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  color: ${({ active }) => (active ? '#303030' : '#C0C0C1')};
  display: flex;
  align-items: center;
`;
