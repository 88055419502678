import {
  Grid,
  IconButton, Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const SideNavWrapper = styled(Grid)`
  min-width: 330px;
  width: 330px;
  height: 34rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #42424240;
  border: 1px solid #42424240;
  opacity: 1;
  position: sticky;
  top: 6rem;
`;

export const SideNavItem = styled(IconButton)`
  border-radius: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  width: 100%;
  justify-content: flex-start;
  color: #424242;
  opacity: 0.7;
`;

export const SideNavItems = styled(Grid)`
  margin-top:50px;
  width: 400px;
`;

export const SideNavText = styled(Typography)`
    margin-left: 35px;
    font-size: 16px;
    height: 19px;
    font-weight: 600;
`;
export const SideNavItemImage = styled('img')`
  height: 30px;
`;
