import { useMutation } from '@apollo/client';
import {
  Dialog,
  Grid,
  Slide
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Arrow from '../../assets/images/Arrow.png';
import Basket from '../../assets/images/Basket.png';
import {
  ADD_SAVE_CART_TO_CART
} from '../../mutations/cart';
import { CloseIcon } from '../shared/uploadProduct/individual/individualProductDialog.styles';
import {
  CDialog,
  DialogText,
  GridItem,
  GridTitle,
  Item
} from './renderPopper.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SavedCartDialog = ({
  savedCartDialog,
  handleClose,
  cartOrder,
  handleSave,
  handleAction,
}) => {
  const initialState = {
    saveCartId: null,
  };
  const [state, setState] = useState(initialState);
  const [restoreSaveOrderCart] = useMutation(ADD_SAVE_CART_TO_CART);
  const [loading, setLoading] = useState(false);

  const handleAddCartOrder = (id) => {
    toast.loading('Waiting...');
    setLoading(true);
    const { saveCartId } = state;
    const variables = { saveCartId: id };
    restoreSaveOrderCart({ variables })
      .then(({ data }) => {
        const { message } = data?.completeSaveOrderCart || {};
        toast.success(message);
        window.location.reload();
      })
      .catch((e) => {
        toast.error(e.message);
      }).finally(() => setLoading(false));
  };
  return (
    <Dialog
      open={savedCartDialog}
      ransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: 15,
          overflowY: 'scroll',
          width: '650px',
          maxHeight: '350px'
        }
      }}
    >
      <Grid container>
        <Grid item container xs={6}>
          <CDialog>
            Saved Cart
          </CDialog>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon
              onClick={handleClose}
              style={{
                position: 'absolute',
                right: '3.2rem',
                top: '1rem'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <GridTitle>
        <DialogText
          style={{
            color: '#606060',
            fontWeight: '400'
          }}
        >
          a quick identifier when you decide to continue with this cart.
        </DialogText>
      </GridTitle>
      {cartOrder.map((item) => (
        <GridItem container key={item.id} style={{ paddingRight: '7px' }}>
          <Grid item xs={2}>
            <Item>
              <img
                src={Basket}
                alt="basket"
                width={20}
                style={{
                  paddingTop: '5px',
                }}
              />
            </Item>
          </Grid>
          <Grid item xs={8}>
            <DialogText
              style={{
                color: '#606060',
                paddingTop: '12px',
              }}
            >
              {item.name.slice(0, 50)}
            </DialogText>
          </Grid>
          <Grid
            item
            xs={2}
            onClick={() => handleAddCartOrder(item.id)}
            disabled={loading}
          >
            <img
              src={Arrow}
              alt="Arrow"
              width={25}
              style={{
                paddingTop: '12px',
                cursor: 'pointer',
              }}
            />
          </Grid>
        </GridItem>
      ))}
    </Dialog>
  );
};

SavedCartDialog.propTypes = {
  savedCartDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  cartOrder: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default SavedCartDialog;
