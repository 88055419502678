import {
  Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  min-width: 12rem;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

export const ItemTypoBox = styled(Grid)`
  cursor: pointer;
  &:hover {
    background-color: #F4F5F6;
  }
`;

export const ItemTypo = styled(Typography)`
  font-size: 1rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1rem;
    padding: 12px 24px;
  }
`;
