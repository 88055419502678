import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ProductDetail, ProductDetailImage, ProductDetailBrandNameText,
  ProductDetailPackSizeText, HideMd, ShowMd, ProductCounter, ProductCounterRemoveButton, ProductCounterAddButton,
  ProductCounterMobileContainer, ProductDetailMainContainer, ProductDetailPriceMobileContainer,
  ProductDetailNameMainContainer, ProductCounterQuantityInput, Add, Remove, SubItemContent
} from '../dispatch/product-item/styles';
import { ReturnProductContainer } from './returns.styles';

import ReturnSelectField from '../../suppliers/individual/returnSelectField';

import ReasonSelectBoxField from './returnReasonOptions';

const ReturningProductItem = ({
  product,
  incrementProductQuantity,
  decrementProductQuantity,
  changeInputQuantity,
  handleReasonChange
}) => {
  const {
    id, name: brandName, meta, recieved, condition,
    quantityRecieved, quantityReturned
  } = product;
  const { image, pack_size: packSize } = meta;
  const productConditions = ['Good', 'Not Good'];

  return (
    <ReturnProductContainer
      style={!recieved || !quantityRecieved ? { opacity: 0.4, cursor: 'not-allowed' } : {}}
    >
      <ProductDetail>
        <ProductDetailImage src={image} alt="" />
        <ProductDetailNameMainContainer>
          <ProductDetailBrandNameText>
            { brandName }
          </ProductDetailBrandNameText>
          <HideMd>
            <ProductDetailPackSizeText>
              Pack Size:&nbsp;
              { packSize }
            </ProductDetailPackSizeText>
          </HideMd>
        </ProductDetailNameMainContainer>
      </ProductDetail>
      <ShowMd>
        <ProductDetailPriceMobileContainer>
          <ProductDetailPackSizeText>
            Pack Size:&nbsp; &nbsp;
            {packSize}
            &nbsp; &nbsp;
          </ProductDetailPackSizeText>
        </ProductDetailPriceMobileContainer>
        <ProductCounterMobileContainer>
          <ProductCounter>
            <ProductCounterRemoveButton
              onClick={() => decrementProductQuantity(id)}
            >
              <Remove />
            </ProductCounterRemoveButton>
            <ProductCounterQuantityInput
              value={quantityReturned}
              onChange={(event) => changeInputQuantity(id, event.target.value)}
            />
            <ProductCounterAddButton
              onClick={() => incrementProductQuantity(id)}
            >
              <Add />
            </ProductCounterAddButton>
          </ProductCounter>
        </ProductCounterMobileContainer>
      </ShowMd>
      <HideMd>

        <SubItemContent>

          <ProductDetailBrandNameText>
            {quantityRecieved}
          </ProductDetailBrandNameText>

          <ProductCounter>
            <ProductCounterRemoveButton
              onClick={() => decrementProductQuantity(id)}
            >
              <Remove />
            </ProductCounterRemoveButton>

            <ProductCounterQuantityInput
              value={quantityReturned}
              onChange={(event) => changeInputQuantity(id, event.target.value)}
            />

            <ProductCounterAddButton
              onClick={() => incrementProductQuantity(id)}
            >
              <Add />
            </ProductCounterAddButton>
          </ProductCounter>

          <ProductDetailMainContainer>
            <ReturnSelectField
              field={ReasonSelectBoxField}
              label="Select reason"
              value={productConditions.includes(condition) ? '' : condition}
              fullWidth
              handleCreditDaysOpen={() => {}}
              handleChange={(e) => handleReasonChange(id, e.target.value, 'condition')}
              showCheckBox={false}
            />
          </ProductDetailMainContainer>
        </SubItemContent>
      </HideMd>
    </ReturnProductContainer>
  );
};

ReturningProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  incrementProductQuantity: PropTypes.func.isRequired,
  decrementProductQuantity: PropTypes.func.isRequired,
  changeInputQuantity: PropTypes.func.isRequired,
  handleReasonChange: PropTypes.func.isRequired
};

ReturningProductItem.defaultProps = {
};

export default ReturningProductItem;
