import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  SideNavWrapper, SideNavItem,
  SideNavItemImage, SideNavText
} from './sideNav.styles';
import AccountImage from '../../../../assets/images/sidenav/Account.png';
import BusinessImage from '../../../../assets/images/sidenav/Business.png';
import OutletImage from '../../../../assets/images/sidenav/Outlet.png';
import UsersImage from '../../../../assets/images/sidenav/Users.png';

const SIDENAV_ICONS = [
  [AccountImage, 'Account', 'account'],
  [BusinessImage, 'Business', 'business'],
  [OutletImage, 'Outlets', 'outlets'],
  [UsersImage, 'User(s)', 'users'],
  [UsersImage, 'Roles & Permissions', 'permissions'],
  [UsersImage, 'Preference(s)', 'preferences'],
];
const ACTIVE_STYLES = {
  backgroundColor: '#F0F0F0',
  borderLeft: '8px solid yellow',
  paddingLeft: '30px',
  color: 'black',
  fontWeight: '1000',
  opacity: '1'
};

function SideNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const currentPath = pathname.split('/').pop();

  const goTo = (route) => {
    if (['business', 'users', 'permissions', 'preferences', 'outlets'].includes(route)) {
      navigate(`/settings/${route}`);
    }
  };

  return (
    <SideNavWrapper>
      {SIDENAV_ICONS.map((sideItem) => (
        <SideNavItem
          style={(currentPath === sideItem[2])
            ? ACTIVE_STYLES : {}}
          onClick={() => goTo(sideItem[2])}
        >
          <SideNavItemImage
            src={sideItem[0]}
          />
          <SideNavText>
            {sideItem[1]}
          </SideNavText>
        </SideNavItem>
      ))}
    </SideNavWrapper>
  );
}

export default SideNav;
