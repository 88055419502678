import { useMutation } from '@apollo/client';
import {
  DialogActions, DialogContent,
  Grid,
  Slide
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  CREATE_MANUFACTURER_MUTATION, UPDATE_MANUFACTURER_MUTATION
} from '../../../../mutations/manufacturers';
import { blockInvalidChar } from '../../../../utils/mobileCheck';
import { FilledCustomButton, OutlinedCustomButton } from '../../../customComponents/customButton';
import CustomInputBase from '../../../customComponents/customInputBase';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import SuccessDialog from '../../successDialog';
import {
  CDialog,
  CircularProgressLoader,
  CloseIcon,
  DialogTitleSubText,
  DialogTitleText,
  GridWrapper
} from './individualManufacturerDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualManufacturerDialog = ({
  dialogOpen, closeDialog, manufacturer, refetch
}) => {
  const initialState = {
    name: '',
    term: 'Cash on delivery',
    city: 'Lagos',
    country: 'Nigeria',
    manufacturerId: '',
    providerPromo: '',
    manufacturerCreditDays: ''
  };

  const initialErrorState = {
    nameError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [creditDays,] = useState(14);
  const [editing, setEditing] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [dialogDesc, setDialogDesc] = useState({});

  const toggleSuccessModal = () => setSuccessModal(!successModal);

  const { nameError } = errorState;

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    closeDialog();
    setState(initialState);
    refetch ? refetch() : null;
  };

  useLayoutEffect(() => {
    if (manufacturer) {
      const {
        id, name, city, country, paymentTerm, providerPromo
      } = manufacturer;
      setEditing(true);
      setState((_state) => ({
        ..._state, manufacturerId: id, name, term: paymentTerm,
        city, country, providerPromo
      }));
    }
  }, [manufacturer, editing]);

  const [createManufacturer] = useMutation(CREATE_MANUFACTURER_MUTATION);
  const addManufacturer = () => {
    const {
      name, term, city, country, providerPromo
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    createManufacturer({
      variables: {
        name,
        term: newTerm,
        city,
        country,
        providerPromo,
      }
    })
      .then(() => {
        setDialogDesc({ title: 'Manufacturer Created', desc: 'You have successfully created a Manufacturer.' });
        toggleSuccessModal();
        // toast.success(message);
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [updateManufacturer] = useMutation(UPDATE_MANUFACTURER_MUTATION);
  const editManufacturer = () => {
    const {
      manufacturerId, name, term, city, country, providerPromo
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    updateManufacturer({
      variables: {
        manufacturerId,
        name,
        term: newTerm,
        city: city || 'lagos',
        country,
        providerPromo,
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateManufacturer || {};
        toast.success(message);
        setDialogDesc({ title: 'Updated', desc: 'You have successfully updated Manufacturer’s details' });
        toggleSuccessModal();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setLoading(true);
    if (editing) return editManufacturer();
    return addManufacturer();
  };

  const handleSaveButtonClick = () => {
    const { name } = state;
    if (name.length < 3) {
      return setErrorState({
        ...errorState, nameError: true
      });
    }
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'name':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 3)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'mobile' && value.length > 11) return;
    setState({ ...state, [name]: value });
  };

  const fields = [
    {
      name: 'name', label: "Manufacturer's Name", error: nameError, helperText: 'Manufacturer name requires more than 3 letters', required: true,
      placeholder: "Enter Manufacturer's Name",
    },
    {
      name: 'term', label: 'Payment Term', options: ['Payment before delivery', 'Cash on delivery', 'On-credit payment'], placeholder: 'Select'
    },
    {
      name: 'providerPromo', label: 'Provider Promo', options: ['GPS'], placeholder: 'Select'
    },
    { name: 'city', label: 'City', placeholder: 'Select' },
    {
      name: 'country', label: 'Country', options: ['Nigeria', 'Uganda', 'Kenya'], placeholder: 'Select'
    },
    // { name: 'manufacturerCreditDays', label: 'No. of Credit Days', placeholder: 'Enter No. of Credit Days ' },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, error, required, placeholder
    } = field;
    const value = state[fieldName];
    const numberFields = ['mobile', 'markup'];
    if (['term', 'country', 'providerPromo'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          placeholder={placeholder}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
        />
      );
    }
    return (
      <CustomInputBase
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={numberFields.includes(fieldName) ? 'number' : 'text'}
        error={error}
        helperText={error && helperText}
        required={required}
        name={fieldName}
        placeholder={placeholder}
        onKeyDown={fieldName === 'mobile' && blockInvalidChar}
        onChange={handleChange}
        cSize="lg"
      />
    );
  };

  return (
    <>
      <CDialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
        data-testid="manufacturer-dialog"
      >
        <GridWrapper container item>
          <Grid container style={{ paddingTop: '1.5rem' }}>
            <Grid item xs={6}>
              <DialogTitleText>
                {editing ? 'Edit' : 'Add Manufacturer'}
              </DialogTitleText>
              <DialogTitleSubText>
                {editing
                  ? 'Edit Manufacturer Information To The Fields In The Form'
                  : 'Add Manufacturer information to the fields in the form'}
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={2} style={{ paddingTop: '8px' }}>
              {fields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: '2rem' }}>
            <OutlinedCustomButton
              style={{ fontSize: '12px', fontWeight: '700', marginRight: '1.2rem' }}
              onClick={handleCloseDialog}
            >
              Cancel
            </OutlinedCustomButton>
            <FilledCustomButton
              disabled={loading}
              onClick={handleSaveButtonClick}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : editing ? 'Save' : 'Create'}
            </FilledCustomButton>
          </DialogActions>
        </GridWrapper>
      </CDialog>

      <SuccessDialog
        openDialog={successModal}
        setOpenDialog={toggleSuccessModal}
        title={dialogDesc.title}
        desc={dialogDesc.desc}
        option="Ok"
      />
    </>
  );
};

IndividualManufacturerDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default IndividualManufacturerDialog;
