import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import dayjs from 'dayjs';
import FormTemplate from '../shared/fieldTemplate/template';

import {
  CaretUpIcon,
  CaretDownIcon,
  DeleteIcon,
  UpdateIcon
} from '../../assets/svgs';
import {
  MainTableRow,
  BodyCell,
  CustomInputBase,
  RemoveButton
} from './returnRow.styles';
import { JSONParse } from '../../utils/json';

const ReturnRow = ({
  row, updateHandler, reportLoading, setDisableButton
}) => {
  const {
    batch: { batchNo, expiryDate },
    product: { brandName, meta },
    id,
    quantityInStock
  } = row;

  const [expdate, setExpdate] = useState(expiryDate);
  const [counter, setCounter] = useState(quantityInStock);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setCounter(quantityInStock);
  }, [quantityInStock]);

  const { pack_size: packSize } = JSONParse(meta) || {};

  const handleDateChange = (e) => {
    const newExpDate = e?.target?.value;
    setExpdate(newExpDate);
  };

  const expireDateField = {
    type: 'date',
    name: 'expiryDate',
    value: dayjs(expdate).format('YYYY-MM-DD'),
    onChange: handleDateChange,
    style: { width: '75%', fontSize: '14px' }
  };

  const handleValChange = (value) => {
    setTrigger(true);
    setDisableButton(true);
    if (value < 0) setCounter(0);
    else setCounter(value);
  };

  const handleActionButtons = (value) => {
    setTrigger(true);
    setDisableButton(true);
    if (value === -1 && counter <= 0) setCounter(0);
    else setCounter(counter + value);
  };

  const triggerUpdateHandler = (type) => {
    if (type === 'UPDATE') {
      setDisableButton(false);
    }
    setTrigger(false);
    updateHandler(row, counter, type);
  };

  return (
    <MainTableRow
      key={id}
      item
      container
      // style={{ color: (!row.quantityInStock && !counter) && '#EE0101' }}
      style={{ cursor: 'auto' }}
    >
      <BodyCell>{batchNo || 'N/A'}</BodyCell>
      <BodyCell>{brandName}</BodyCell>
      <BodyCell>{packSize}</BodyCell>
      <BodyCell>
        <FormTemplate type="date" fields={expireDateField} />
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          data-testid="counter"
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          error={'quantity Counter' || false}
          helperText={'quantity Counter' && 'Please add a value'}
          required
          value={counter || ''}
          onChange={(e) => handleValChange(Number(e.target.value))}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  data-testid="increment"
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => handleActionButtons(1)}
                />
                <CaretDownIcon
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => handleActionButtons(-1)}
                />
              </Grid>
            </InputAdornment>
          )}
        />
      </BodyCell>
      <BodyCell>
        <RemoveButton
          data-testid="update"
          onClick={() => triggerUpdateHandler('UPDATE')}
          disabled={reportLoading || counter === 0 || !trigger}
        >
          <UpdateIcon style={{ fontSize: '1.3rem', marginRight: '4px' }} />
          Update
        </RemoveButton>
        <RemoveButton
          data-testid="remove"
          onClick={() => triggerUpdateHandler('REMOVE')}
          style={{ marginLeft: '2px' }}
        >
          <DeleteIcon style={{ fontSize: '1.3rem', marginRight: '4px' }} />
          Remove
        </RemoveButton>
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  updateHandler: PropTypes.func.isRequired,
  reportLoading: PropTypes.func.isRequired,
  setDisableButton: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
