import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  MobileContainer, DetailText, HeaderText, TextBox, DateText,
  ContentBox,
} from './affiliatesPaymentHistory.styles';
import currencyFormatter from '../../../shared/currencyFormatter';

const MobileReturnRow = ({
  dateCreated, total, id, status, amountPaid
}) => {
  const createdDate = moment(dateCreated).format('DD/MM/YY (h:mm)');
  return (
    <MobileContainer>
      <DateText>
        {createdDate}
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container>
          <HeaderText xs={4}>Invoice Value</HeaderText>
          <DetailText xs={8}>
            {`₦ ${currencyFormatter(total)}`}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>Amount paid</HeaderText>
          <DetailText xs={8}>
            {`₦ ${currencyFormatter(amountPaid)}`}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>Invoice  number</HeaderText>
          <DetailText xs={8}>
            {id}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>Payment status</HeaderText>
          <DetailText xs={8}>
            {status}
          </DetailText>
        </TextBox>
      </ContentBox>
    </MobileContainer>
  );
};

MobileReturnRow.propTypes = {
  dateCreated: PropTypes.instanceOf(DateText),
};

MobileReturnRow.defaultProps = {
  dateCreated: {},
};

export default MobileReturnRow;
