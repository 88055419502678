import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Grid, InputAdornment, TableBody, useMediaQuery, Button, Box, TableContainer, Table
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import { FIND_SUPPLIERS_QUERY } from '../../queries/suppliers';
import { FIND_MANUFACTURERS_QUERY } from '../../queries/manufacturers';
import IndividualSupplierDialog from './individual/individualSupplierDialog';
import SupplierDropdown from '../toBeDeleted/uploadSupplier/supplierCreatePopper';
import MainContent from '../customComponents/mainContent';
import IndividualManufacturerDialog from '../shared/uploadManufacturer/individual/individualManufacturerDialog';
import {
  TableHeader, FooterGrid, GridContainer, MainTableHead, SearchContainer, SearchTextField, SearchButton, SearchImage,
  MobileContainer, PaperWrapper, TableGrid, FooterWrapper, SearchGrid,
  TitleGrid, TitleTextGridContainer, PrimaryTitle
} from './suppliers.styles';
import { EXPORT_MUTATION } from '../../mutations/reports';
import { FilledCustomButton, OutlinedCustomButton } from '../customComponents/customButton';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import ReturnRow from './returnRow';
import CTablePagination from '../customComponents/cTablePagination';
import { useStateValue } from '../../providers/stateProvider';
import SupplierActionTypes from '../../providers/reducers/supplier/supplierTypes';
import SearchStatus from '../../assets/images/marketOrder/searchStatus.png';
import MobileReturnRow from './mobileReturnRow';
import Filter from '../inventory/filter';
import useSearchParamsState from '../shared/helpers/ulrSearchParams';

const supplierHeaders = ['S/N', 'Supplier', 'Total Products Per Supplier', 'Total Products Value', 'Action'];
const manufacturerHeaders = ['S/N', 'Manufacturer', 'Total Products Per Manufacturer', 'Total Products Value', 'Action'];

const initialState = {
  currentPage: 1,
  currentPageCount: 20,
  searchText: '',
};

function Suppliers({ viewType }) {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);
  const [toggleTab, setToggleTab] = useState(true);
  const [supplierButton,] = useState(null);
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [openManufacturerDialog, setOpenManufacturerDialog] = useState(false);
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '20');
  const [search, setSearch] = useSearchParamsState('search', '');
  const [tabVal, setTabVal] = useState(viewType);


  useEffect(() => {
    if (viewType === 'manufacturers') {
      setToggleTab(!toggleTab);
    }
  }, []);


  const [{
    supplier: { addSupplierDialogOpen }, user: { allowedPermissionsMap: { userPermissions } },
    navbar: { openSideDrawer }
  }, dispatch] = Object.values(useStateValue());

  const {
    currentPage, currentPageCount, searchText
  } = state;
  const toggleIndividualDialog = () => {
    dispatch({ type: SupplierActionTypes.TOGGLE_DIALOG_OPEN });
  };
  const handleSupplierButtonClick = () => {
    toggleIndividualDialog();
  };
  const toggleManufacturerDialog = () => setOpenManufacturerDialog(!openManufacturerDialog);
  const handleToggleTab = () => {
    setState(initialState); setToggleTab(!toggleTab); setPageNumber(1);
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const csvDownloadVariable = !toggleTab
    ? { type: 'manufacturers', name: 'Manufacturer Report', variable: {} }
    : { type: 'suppliers', name: 'Supplier Report', variable: {} };

  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const handleDownload = async () => {
    const { type, name, variable } = csvDownloadVariable;
    const randomNum = Math.floor(Math.random() * 10000);
    const NewfileName = `${name} ${randomNum}`;

    exportMutation({
      variables: {
        type,
        name: NewfileName,
        status: '',
        ...variable
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearch(searchText);
    }
  }, [searchText]);
  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  useEffect(() => {
    if (search) {
      setState({ ...state, searchText: search });
    }
  }, [search]);

  const renderMobileSearch = () => (
    <SearchContainer item xs={12}>
      <SearchTextField
        variant="outlined"
        label=""
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search by Supplier"
        fullWidth
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
  >
    <SearchButton
      aria-label="Search Order"
      edge="end"
      disabled
    >
      <SearchImage
        alt="search icon"
        src={SearchStatus}
      />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const returnHeaders = () => {
    switch (toggleTab) {
      case false:
        return manufacturerHeaders.map((header) => <TableHeader key={header} className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}>{header}</TableHeader>);
      case true:
        return supplierHeaders.map((header) => <TableHeader key={header} className={`erp-uat-${header.toLowerCase().replace(/ /g, '-')}`}>{header}</TableHeader>);
      default:
        return null;
    }
  };

  const variables = {
    pageCount: isSmall ? currentPageCount : pageCount,
    pageNumber: isSmall ? currentPage : pageNumber,
    search
  };

  const {
    loading, error, data, refetch: supplierRefetch
  } = useQuery(FIND_SUPPLIERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
    onlyBusinessSuppliers: true,
    skip: !isSmall && !toggleTab
  });

  const {
    loading: manLoading, data: manData, error: manError, refetch: manRefetch
  } = useQuery(FIND_MANUFACTURERS_QUERY, {
    variables,
    fetchPolicy: 'no-cache',
    skip: toggleTab
  });

  if (error || manError) return <div>{error.message || manError.message}</div>;
  const totalNumber = (!toggleTab ? manData?.manufacturersTotalNumber : data?.supplierTotalNumber) || 0;
  const rows = (!toggleTab ? manData?.manufacturers : data?.findSupplier) || [];
  const mobileTotalNumber = data?.supplierTotalNumber || 0;
  const mobileRows = data?.findSupplier || [];

  const refetch = !toggleTab ? manRefetch : supplierRefetch;

  const handleTabChange = (val) => {
    if (!val) return;
    handleToggleTab();
    navigate(`/${val}`);
    switch (tabVal) {
      case 'suppliers':
        return setTabVal('manufacturers');
      case 'manufacturers':
        return setTabVal('suppliers');
      default:
        return setTabVal('suppliers');
    }
  };

  const tab = [
    { label: 'Suppliers', value: 'suppliers', width: '10%' },
    { label: 'Manufacturers', value: 'manufacturers', width: '10%' },
    {
      label: '', value: '', width: '80%', marginTop: '1.3rem'
    }
  ];

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Suppliers & Manufacturers</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        {!isSmall ? (
          <PaperWrapper elevation={0}>
            <Filter
              tabVal={tabVal}
              handleTabChange={handleTabChange}
              tab={tab}
            />
            <SearchGrid item container spacing={1}>
              <Grid item container xs={!toggleTab && openSideDrawer ? 7 : 8}>
                <CustomSearchField
                  name="search"
                  value={searchText}
                  placeholder="Search"
                  handleChange={(e) => handleSearch(e.target.value)}
                  style={{ width: '100%' }}
                />
              </Grid>
              {/* <Grid item xs={2}>
                {
                  toggleTab
                    ? (
                      <TCustomButton
                        type="tertiary"
                        header
                        style={{ width: '100%' }}
                        onClick={handleToggleTab}
                      >
                        View Suppliers
                      </TCustomButton>
                    ) : (
                      <TCustomButton
                        type="tertiary"
                        header
                        style={{ width: '100%' }}
                        onClick={handleToggleTab}
                      >
                        View Manufacturer
                      </TCustomButton>
                    )
                }
              </Grid> */}

              <Grid item xs={!toggleTab && openSideDrawer ? 3 : 2}>
                {
                  !toggleTab
                    ? (
                      <FilledCustomButton style={{ width: '100%' }} onClick={toggleManufacturerDialog}>
                        Create New Manufacturer
                      </FilledCustomButton>
                    )
                    : (
                      <FilledCustomButton style={{ width: '100%' }} onClick={handleSupplierButtonClick}>
                        Create New Supplier
                      </FilledCustomButton>
                    )
                }
              </Grid>
              <Grid item xs={2}>
                <OutlinedCustomButton style={{ width: '100%' }} onClick={handleDownload}>
                  Download CSV
                </OutlinedCustomButton>
              </Grid>
            </SearchGrid>
            <TableGrid item container>
              {loading || manLoading ? (
                <ProductsTableLoader />
              ) : (
                <TableContainer>
                  <Table>
                    <MainTableHead>
                      {returnHeaders()}
                      <TableHeader />
                    </MainTableHead>
                    <TableBody>
                      {rows.map((item, index) => (
                        <ReturnRow
                          key={item.id}
                          row={item}
                          index={index}
                          pageNumber={pageNumber}
                          pageCount={pageCount}
                          toggleTab={toggleTab}
                          refetch={refetch}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </TableGrid>
            <FooterWrapper item container>
              {totalNumber > 0 && (
                <TablePagination
                  total={totalNumber}
                  pageCount={+pageCount}
                  setPageCount={setPageCount}
                  pageNumber={+pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            </FooterWrapper>
          </PaperWrapper>
        )
          : (
            <MobileContainer>
              <Box>
                {renderMobileSearch()}
              </Box>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSupplierButtonClick}
                disabled={!userPermissions?.includes('suppliers_create_new_supplier')}
                style={{
                  backgroundColor: '#000000', borderRadius: '20px',
                  color: '#fff', height: '7rem', fontSize: '3rem', marginBottom: '3rem'
                }}
              >
                +   Create new supplier
              </Button>
              {loading ? <ProductsTableLoader /> : (
                mobileRows.map((supplier, index) => (
                  <MobileReturnRow
                    key={supplier.id}
                    row={supplier}
                    index={index}
                    pageCount={currentPageCount}
                    pageNumber={currentPage}
                  />
                )))}
              <FooterGrid item container>
                <CTablePagination
                  rowsPerPageOptions={[100]}
                  component="div"
                  count={mobileTotalNumber}
                  page={currentPage - 1}
                  onChangePage={handleChangePage}
                  rowsPerPage={currentPageCount}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </FooterGrid>
            </MobileContainer>
          )}
        <SupplierDropdown
          supplierButton={supplierButton}
          handleSupplierButtonClick={handleSupplierButtonClick}
          individualDialogHandler={toggleIndividualDialog}
        />
        <IndividualManufacturerDialog
          dialogOpen={openManufacturerDialog}
          closeDialog={() => setOpenManufacturerDialog(false)}
          fromProduct
          refetch={refetch}
        />
        <IndividualSupplierDialog
          dialogOpen={addSupplierDialogOpen}
          closeDialog={toggleIndividualDialog}
          refetchSuppliers={refetch}
        />
      </GridContainer>
    </MainContent>
  );
}

Suppliers.propTypes = {
  viewType: PropTypes.string
};

Suppliers.defaultProps = {
  viewType: ''
};

export default Suppliers;
