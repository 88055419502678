import {
  Grid, Typography, IconButton, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding: 0 25px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 600;
  color: #424242;
  font-size: 30px;
  margin-bottom: 10px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const UploadFileWrapper = styled(Grid)`
  margin: 12% 0;
`;

export const DropzoneWrapper = styled(Grid)`
  margin: 30px 15px;
`;

export const DividerLine = styled('hr')`
  border: 1px solid #CCCCCC;
  width:96%;
  min-width: 300px;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-bottom: 10px;
  justify-content: flex-start;
  text-align: left;
`;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  margin-right: 30px;
  background-color: #FCED50;
  color: #424242;
  margin-bottom: 40px;

  &:hover {
    background-color: #D2C647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 40px;
  border: 2px solid #424242;
  `;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;
