import { styled } from '@mui/material/styles';
import { Divider, TextField, Typography } from '@mui/material';

export const StyledDivider = styled(Divider)`
  background-color: #636363;
  margin: 3rem 0;

  @media (min-width: 992px) {
    margin: 2vw 0;
  }
`;

export const HaveAccountTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  padding-top: 1rem;
  text-align: center;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
`;

export const HaveAccount = styled(Typography)`
  padding-top: 2rem;
  text-align: center;
  @media (max-width: 991px) {
    padding-top: 2.5rem;
    text-align: center;
  }
`;

export const CustomField = styled(TextField)`
border-radius: 10px;


  & .MuiOutlinedInput-root {
    background-color: #FFFFFF;
    border-radius: 10px;
    border-color: #235A91;
    border: 0.5px solid  #235A91;

    &fieldset: {
      border-color: #235A91;
    },
    &:hover fieldset: {
      border-color: #235A91;
    },
    &.Mui-focused fieldse: {
      border-color: #235A91;
    },
  }
  & .MuiInputBase-input {
    height: 5rem;
    font-size: 3rem;
  }
  @media (min-width: 992px) {
    & .MuiInputBase-input {
      height: 2rem;
      font-size: 1rem;
    }
  }

`;

export const InputWrapper = styled('div')`
  padding: 0px;
`;

export const Label = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  margin-bottom: .4rem;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;
