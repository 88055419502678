/* eslint-disable no-param-reassign */
import { toast } from 'react-hot-toast';
import ReceiveMOActionTypes from './receiveManaulOrderTypes';

const receiveManualOrderReducer = (receiveManualOrder, action) => {
  switch (action.type) {
    case ReceiveMOActionTypes.ADD_TO_LIST: {
      const pl = action.payload;
      const state = receiveManualOrder.orderList;
      // eslint-disable-next-line radix

      // Find the order in the storage
      const isExist = state.find((item) => item.order_id === pl.order_id);

      // If order exist return the previous state else update the state
      const updateStorage = isExist ? state : [...state, pl];

      // Update the local storage
      localStorage.setItem(
        'receive_manual_order',
        JSON.stringify({ orderList: updateStorage })
      );
      return { ...receiveManualOrder, orderList: updateStorage };
    }

    case ReceiveMOActionTypes.ADD_BATCH: {
      const { batch, orderId } = action.payload;
      const state = receiveManualOrder.orderList;

      // Find the order in the storage
      const order = state.find((item) => item.order_id === orderId);

      // Select batches list
      const listBatch = order.listBatches;

      // Check if added batch exist in the list
      const batchExist = listBatch.find(
        (item) => item.batchNo === batch.batchNo
      );
      if (batchExist) {
        return { ...receiveManualOrder, orderList: state };
      }
      // toast.error('Order can not have a duplicate batch');

      // If order exist return the previous state else update the state
      const updateBatch = batchExist ? listBatch : [...listBatch, batch];

      // Update the batch list
      order.listBatches = updateBatch;

      // Update the cost price across
      const updatePrice = listBatch.filter((item) => item?.orderProductId === batch?.orderProductId);
      updatePrice.forEach((item) => {
        item.reconciledUnitCost = batch.reconciledUnitCost;
      });

      // Update global price
      const price = order.order.find((item) => item?.opSetId === batch?.orderProductId);
      price.newPrice = batch?.reconciledUnitCost;

      // Update the local storage
      localStorage.setItem(
        'receive_manual_order',
        JSON.stringify({ orderList: state })
      );
      if (!batchExist) toast.success('Batch added to product!');

      return { ...receiveManualOrder, orderList: state };
    }

    case ReceiveMOActionTypes.UPDATE_LIST: {
      const { batch, orderId } = action.payload;
      const state = receiveManualOrder.orderList;

      // Find the order in the storage
      const order = state.find((item) => item.order_id === orderId);

      // Select batches list
      const listBatch = order.listBatches;

      // Check if added batch exist in the list
      const batchExist = listBatch.find(
        (item) => item.batchNo === batch.batchNo
      );

      // If order exist update the state
      if (batchExist) {
        batchExist.quantityReceived = batch?.quantityReceived;
      }

      // Update the cost price across
      const updatePrice = listBatch.filter((item) => item?.orderProductId === batch?.orderProductId);
      updatePrice.forEach((item) => {
        item.reconciledUnitCost = batch.reconciledUnitCost;
      });

      // Update the batch list
      order.listBatches = listBatch;

      // Update global price
      const price = order.order.find((item) => item?.opSetId === batch?.orderProductId);
      price.newPrice = batch?.reconciledUnitCost;

      // Update the local storage
      localStorage.setItem(
        'receive_manual_order',
        JSON.stringify({ orderList: state })
      );
      toast.success('Batch successfully updated');

      return { ...receiveManualOrder, orderList: state };
    }

    case ReceiveMOActionTypes.DELETE_BATCH: {
      const { batchNo, orderId } = action.payload;
      const state = receiveManualOrder.orderList;

      // Find the order in the storage
      const order = state.find((item) => item.order_id === orderId);

      // Select batches list
      const listBatch = order.listBatches;

      // Remove filtered object
      const newList = listBatch.filter((item) => item.batchNo !== batchNo);

      // Update the batch list
      order.listBatches = newList;

      // Update the local storage
      localStorage.setItem(
        'receive_manual_order',
        JSON.stringify({ orderList: state })
      );
      toast.success('Batch successfully deleted');

      return { ...receiveManualOrder, orderList: state };
    }

    case ReceiveMOActionTypes.DELETE_ORDER: {
      // const { orderId } = action.payload;
      // eslint-disable-next-line radix
      const state = [];
      // receiveManualOrder.orderList.filter((item) => item.order_id !== orderId);

      // Update the local storage
      localStorage.setItem(
        'receive_manual_order',
        JSON.stringify({ orderList: state })
      );

      return { ...receiveManualOrder, orderList: state };
    }

    default:
      return receiveManualOrder;
  }
};

export default receiveManualOrderReducer;
