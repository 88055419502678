import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import SideNav from '../shared/sideNav/sideNav';
import Loader from '../../shared/loader';

import { GET_MY_BUSINESS } from '../../../queries/affiliates';

import TitleGrid from './titleGrid';
import Navigation from '../shared/navigation/navigation';
import BusinessSettingCard from './businessInformation/businessSettingCard';

import {
  TitleGridWrapper, OutletContentWrapper
} from './business.styles';
import { JSONParse } from '../../../utils/json';
import { UPDATE_BUSINESS_META_MUTATION } from '../../../mutations/business';

export default function BusinessPreferencesContainer() {
  const [updateBusinessMeta] = useMutation(UPDATE_BUSINESS_META_MUTATION);
  const [preferences, setPreferences] = useState({
    orderCostFormat: 'average_order_cost',
    orderEmailingPolicy: 'suppliers',
    productPriceFormat: 'default',
    centralProcurementEmail: null,
  });
  const {
    dataLoading, error, data
  } = useQuery(GET_MY_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(localStorage.getItem('ogarx_business_id'))
    },
  });

  useEffect(() => {
    if (!dataLoading && data && data.business) {
      const parsedSettings = JSONParse(data.business?.settings);
      const {
        orderCostFormat, orderEmailingPolicy, centralProcurementEmail, productPriceFormat
      } = parsedSettings;
      setPreferences({
        ...preferences, orderCostFormat, orderEmailingPolicy, centralProcurementEmail, productPriceFormat
      });
    }
  }, [dataLoading, data]);

  const handleUpdatePreference = () => {
    const keys = Object.keys(preferences);
    const keyValues = [];
    keys.forEach((k) => keyValues.push({ key: k, value: preferences[k] }));
    updateBusinessMeta({
      variables: {
        keyValues,
        jsonKey: 'settings'
      }
    }).then(() => {
      toast.success('Business preference updated successfully');
    });
  };

  if (dataLoading || !data) return <Loader />;
  if (error) return <div>{error.message}</div>;
  return (
    <>
      <Navigation />

      <TitleGridWrapper>
        <TitleGrid handleUpdatePreference={handleUpdatePreference} />
      </TitleGridWrapper>
      <OutletContentWrapper>
        <SideNav />
        <BusinessSettingCard
          preferences={preferences}
          setPreferences={setPreferences}
        />
      </OutletContentWrapper>
    </>
  );
}
