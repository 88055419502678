import PropTypes from 'prop-types';
import React from 'react';
import { UpdatePreferenceButton } from './businessInformation/businessSettingCard.styles';
import {
  ButtonsGrid,
  OrderButtonText,
  PrimaryTitle,
  SecondaryTitle,
  TitleGridContainer, TitleTextGridContainer
} from './titleGrid.styles';

export function TitleGrid({ handleUpdatePreference }) {
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Preferences
        </PrimaryTitle>
        <SecondaryTitle>
          Configure settings for your business
        </SecondaryTitle>
      </TitleTextGridContainer>
      <ButtonsGrid item>
        <UpdatePreferenceButton onClick={() => handleUpdatePreference()}>
          <OrderButtonText>
            Update Preferences
          </OrderButtonText>
        </UpdatePreferenceButton>
      </ButtonsGrid>

    </TitleGridContainer>
  );
}

TitleGrid.propTypes = {
  handleUpdatePreference: PropTypes.func.isRequired
};

export default TitleGrid;
