import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Select
} from '@mui/material';
import {
  CFormControl, Label, SelectNameTypo, FieldCheckbox, CheckboxImage,
  MenuItems, HelperText
} from './customSelectInputBase.styles';
import CheckboxIcon from '../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../assets/images/supplier/Checkbox-resting.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const CustomSelectInputBase = ({
  field, value, handleChange, showCheckBox, placeholder, helperText, error, required, ...props
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected([event.target.value]);
    handleChange(event);
  };

  const renderFunc = (val) => val;

  return (
    <Grid item container direction="column">
      {field.label ? (
        <Label error={error}>
          {field.label}
          {' '}
          {required && '*'}
          {/* <span style={{ color: '#EE4223', fontSize: '16px' }}></span> */}
        </Label>
      ) : ''}

      <CFormControl
        fullWidth
        variant="filled"
        size="small"
        ref={selectRef}
        {...props}
      >
        <Select
          className={`erp-uat-${field.label.replace(/ /g, '-')}`}
          labelId="select-filled-label"
          id="select-filled"
          value={value}
          displayEmpty
          renderValue={(val) => (
            value !== ''
              ? renderFunc(val)
              : <span style={{ color: '#bdbdbd' }}>{placeholder}</span>
          )}
          onChange={handleInput}
          inputProps={{
            name: field.name,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {field.options?.map(
            (option) => (
              <MenuItems key={option} value={option}>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <SelectNameTypo>{option}</SelectNameTypo>
                  { showCheckBox && (
                    <FieldCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected.indexOf(option) > -1}
                    />
                  )}
                </Grid>
              </MenuItems>
            )
          )}
        </Select>
      </CFormControl>
      {helperText && error ? <HelperText>{helperText}</HelperText> : ''}
    </Grid>
  );
};

CustomSelectInputBase.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  showCheckBox: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

CustomSelectInputBase.defaultProps = {
  field: {},
  value: '',
  showCheckBox: true,
  placeholder: '',
  error: false,
  required: false,
  helperText: '',
};

export default CustomSelectInputBase;
