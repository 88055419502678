import { styled } from '@mui/material/styles';
import {
  Grid, Paper, TableCell, Typography, Box,
  TableContainer, Button, TextField, IconButton
} from '@mui/material';

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 30px;
`;

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HeaderGridItem = styled(Grid)``;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const HeaderCell = styled(TableCell)`
  background-color: #F3F9FF;
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.5rem;
  padding: 10px;
  color: #303030;

  @media (min-width: 992px) {
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 10%;
    }
  }

  @media(max-width: 991px) {
    font-size: 1.4rem;
  }
`;

export const TableBox = styled(Box)`
  margin-top: 20px;
`;

export const TableContent = styled(TableContainer)`
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 2rem;
`;

export const BackButton = styled(Button)`
  border: none;
  padding: 30px 30px 0 40px;
  font-size: .9rem;
  color: #235A91;
  &:hover {
    border: none;
  }
`;

export const ArrowImg = styled('img')`
  width: 7px;
`;

export const TitleGridContainer = styled(Grid)`
  padding: 1rem 3rem 1rem 2.5rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const CreateUser = styled(Button)`
  min-width: 13rem;
  height: 45px;
  background: #235A91;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;

export const Wrapper = styled(Grid)`
  border-radius: 12px;
  background: #FFF;
  margin: 0px 30px;
  padding: 30px 0px 100px 0px;
  display: grid;
  grid-template-columns: ${(props) => (props.isCategory ? 'auto auto' : 'auto')};
`;

export const UploadContainer = styled(Grid)`
  padding: 0px 2rem;
  width: ${(props) => (props.isCategory ? '100%' : '50%')};
`;

export const Title = styled(Typography)`

`;

export const FieldHeader = styled(Typography)`
  margin-bottom: .7rem;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.5;
`;

export const TextFieldGrid = styled(Grid)`
  margin-bottom: 1.3rem;
  width: 100%;
  display: block;
`;

export const CTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 2rem;
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;

  @media(max-width: 991px) {
    font-size: 30px;
  }
`;

export const EditButton = styled(IconButton)`
  padding: 13px 40px;
  color: #FFFFFF;
  background-color: #235A91;
  border-radius: 5px;
  margin-right: 15px;
  &:hover {
    background-color: #235A91;
  }

`;

export const ButtonContainer = styled(Grid)`
  width: ${(props) => (props.isCategory ? '100%' : '50%')};
  justify-content: flex-end;
  align-items: center;

`;

export const EmptyState = styled(Grid)`
  margin: 5rem auto;
  justify-content: center;
  align-items: center;
  text-align: center;

`;

export const EmptyStateContainer = styled(Grid)`

`;

export const EmptyHeader = styled(Typography)`
  margin-bottom: .7rem;
  color: #303030;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const EmptyText = styled(Typography)`
  margin-bottom: .7rem;
  color: #606060;
  font-size: .7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 164.2%;
`;

export const UrlText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 1rem;
`;
