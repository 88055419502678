import { styled } from '@mui/material/styles';
import { Button, Dialog, Typography } from '@mui/material';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    width: 28rem;
    border-radius: 15px;

    @media (max-width: 992px) {
      min-width: 40rem;
    }
  }
`;

export const Header = styled(Typography)`
  font-size: 1rem;
  @media (max-width: 992px) {
    font-size: 2.5rem
  }
`;

export const Description = styled(Typography)`
  font-size: 1rem;
  @media (max-width: 992px) {
    font-size: 2rem
  }
`;

export const CustomButton = styled(Button)`
  padding: 1rem 2rem;
  background: #235A91;
  color: #FFF;

  font-size: 1rem;
  @media (max-width: 992px) {
    font-size: 2rem
    padding: 2rem 2rem;
  }

`;
