import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import { BodyCell, MainTableRow } from './returnRow.styles';
import currencyFormatter from '../shared/currencyFormatter';
import ActionPopper from './actionPopper';
import { Dots } from '../../assets/svgs';
import IndividualManufacturerDialog from '../shared/uploadManufacturer/individual/individualManufacturerDialog';
import IndividualSupplierDialog from './individual/individualSupplierDialog';
import ConfirmDialog from '../shared/confirmDialog';
import { FIND_MANUFACTURERS_QUERY } from '../../queries/manufacturers';
import SuccessDialog from '../shared/successDialog';
import {
  DELETE_SUPPLIER,
  DELETE_MANUFACTURER
} from '../../mutations/suppliers';

const ReturnRow = ({
  row,
  index,
  pageNumber,
  pageCount,
  toggleTab,
  refetch
}) => {
  const [action, setAction] = useState(null);
  const [openManufacturerDialog, setOpenManufacturerDialog] = useState(false);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState({
    title: '',
    desc: ''
  });
  const [confirmStatus, setConfirmStatus] = useState(false);

  const {
    id,
    name,
    supplierProductsCount,
    manufacturerProductCount,
    totalProductValue,
    supplierProductsValue
  } = row;

  const value = currencyFormatter(
    !toggleTab ? totalProductValue : supplierProductsValue
  );
  const serialNumber = pageNumber === 1 ? index + 1 : (pageNumber - 1) * pageCount + index + 1;
  const itemCells = [
    serialNumber,
    name,
    !toggleTab ? manufacturerProductCount : supplierProductsCount,
    value
  ];

  const returnCell = (val) => val ?? 'N/A';

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const toggleManufacturerDialog = () => setOpenManufacturerDialog(!openManufacturerDialog);
  const toggleSupplierDialog = () => setOpenSupplierDialog(!openSupplierDialog);
  const toggleModal = () => (!toggleTab ? toggleManufacturerDialog() : toggleSupplierDialog());
  const handleAction = (event, type) => {
    switch (type) {
      case 'edit': {
        toggleModal();
        break;
      }
      case 'delete': {
        setDialogText({
          title: toggleTab ? 'Delete Supplier' : 'Delete Manufacturer',
          desc: toggleTab
            ? 'Are you sure you want to delete this supplier?'
            : 'Are you sure you want to delete this manufacturer?'
        });
        setOpenConfirmDialog(!openConfirmDialog);
        break;
      }
      default:
        break;
    }
    handleOptions(event);
  };

  const renderBodyCells = () => itemCells.map((cell) => <BodyCell key={cell}>{returnCell(cell)}</BodyCell>);
  const [deleteSupplier, { loading }] = useMutation(DELETE_SUPPLIER);
  const [deleteManufacturer, { loading: manLoading }] = useMutation(DELETE_MANUFACTURER);

  if (confirmStatus && toggleTab) {
    setConfirmStatus(false);
    deleteSupplier({
      variables: {
        supplierId: id
      }
    })
      .then(() => {
        // const { message } = data?.deleteSupplier || {};
        // toast.success(message);
        setSuccessOpenDialog(true);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setConfirmStatus(false);
      });
    setOpenConfirmDialog(false);
  }

  if (confirmStatus && !toggleTab) {
    setConfirmStatus(false);
    deleteManufacturer({
      variables: {
        manufacturerId: id
      },
      refetchQueries: [FIND_MANUFACTURERS_QUERY]
    })
      .then(() => {
        // const { message } = data?.deleteManufacturer || {};
        // toast.success(message);
        setSuccessOpenDialog(true);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setConfirmStatus(false);
      });
    setOpenConfirmDialog(false);
  }

  return (
    <MainTableRow>
      {renderBodyCells()}
      <BodyCell>
        <Dots
          style={{ cursor: 'pointer' }}
          fontSize="large"
          onClick={handleOptions}
        />
      </BodyCell>
      <BodyCell />
      <ActionPopper
        action={action}
        handleAction={handleAction}
        row={row}
        handleClose={handleCloseModal}
      />
      <IndividualManufacturerDialog
        dialogOpen={openManufacturerDialog}
        closeDialog={() => setOpenManufacturerDialog(false)}
        manufacturer={row}
        refetch={refetch}
      />
      <IndividualSupplierDialog
        dialogOpen={openSupplierDialog}
        closeDialog={toggleSupplierDialog}
        supplier={row}
        refetchSuppliers={refetch}
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title={dialogText.title}
        desc={dialogText.desc}
        options={['Cancel', 'Yes, Delete']}
        setStatus={setConfirmStatus}
        buttonAction={false}
        loading={loading || manLoading}
      />
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={toggleTab ? 'Supplier Deleted' : 'Manufacturer Deleted'}
        desc={toggleTab ? 'Your selected supplier has been deleted successfully' : 'Your selected manufacturer has been deleted successfully'}
        option="ok"
        refetch={refetch}
      />
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
  pageCount: PropTypes.instanceOf(Number).isRequired,
  pageNumber: PropTypes.instanceOf(Number).isRequired,
  toggleTab: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  index: 0
};

export default ReturnRow;
