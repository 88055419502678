import {
  TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
    & > * {
      border-bottom: unset;
    }
  `;

export const BodyCell = styled(TableCell)`
    font-size: 2rem;
    padding:25px;

    &:first-child, :last-child {
      border-bottom: 0;
    }
    &:nth-child(2) {
      padding: 0;
    }

    @media(min-width: 992px) {
      font-size: 1.2rem;
      padding: 16px;
    }
  `;

export const HeaderCell = styled(TableCell)`
    font-size: 1.2rem;
    font-weight: 500;
    border-bottom: 0;
    padding-bottom: 0;
    color: #B1B1B1;

    @media(min-width: 992px) {
      font-size: .8rem;
    }
  `;

export const BodyCell2 = styled(TableCell)`
    font-size: 1.7rem;
    border-bottom: 0;
    padding-top: 0;

    @media(min-width: 992px) {
      font-size: 1.2rem;
    }
  `;

export const TableRowHead = styled(TableRow)`
    & > * {
      @media(max-width: 991px) {
        &:nth-child(4), :nth-child(5), :nth-child(6) {
          display: none;
        }
      }
    }
  `;

export const TableRowBody = styled(TableRow)`
    & > * {
      @media(max-width: 991px) {
        &:nth-child(4), :nth-child(5), :nth-child(6) {
          display: none;
        }
      }
    }
  `;

export const CustomTableHead = styled(TableHead)`
    display: table-header-group;

    @media(min-width: 992px) {
      display: none;
    }
  `;

export const CustomTableBody = styled(TableBody)`
    display: table-row-group;

    @media(min-width: 992px) {
      display: none;
    }
  `;

export const ProductImageContainer = styled('div')`
    width: 7rem;
    height: 7rem
    display: block;
    overflow: hidden;

    @media(min-width: 992px) {
      width: 5rem;
      height: 5rem
    }
  `;

export const ProductImage = styled('img')`
    width: 7rem;
    display: block;

    @media(min-width: 992px) {
      width: 5rem;
    }
  `;

export const ButtonImage = styled('img')`
    width: 5rem;
    display: block;

    @media(min-width: 992px) {
      width: 2rem;
    }
  `;
