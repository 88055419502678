import {
  TableRow, TableCell, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #F3F9FF;
  }
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  padding: 10px;
  color: #606060;

  :first-child {
    width: 2%;
  }

  @media(min-width: 992px) {
    font-size: 1rem;
    &:nth-child(1), :nth-child(2), :nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 10%;
    }
  }

`;

export const ColorGrid = styled(Grid)`
  text-transform: capitalize;
  color: #606060;
  font-size: 1rem;
  font-weight: 400;
  justify-content: flex-start;
  padding: 12px 0;
  align-items: center;
`;

export const Dot = styled(Grid)`
  background: ${({ background }) => (background)};
  border-radius: 50%;
  text-transform: capitalize;
  width: 9px;
  height: 9px;
  margin-right: 5px;
`;
