import { styled } from '@mui/material/styles';
import {
  Grid, IconButton
} from '@mui/material';

export const TableColumnRows = styled('div')`
  display: flex;
  margin: 0 35px;
`;

export const TableColumnRowText = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 23px;
  white-space: nowrap;
  padding: 12px 0;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const ButtonContainer = styled(Grid)`
  width: 50px;
  textAlign: center;
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
`;

export const TabImage = styled('img')`
  width: 26px;
`;
