import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMediaQuery } from '@mui/material';
import {
  BodyCell, MainTableRow,
} from './returnRow.styles';

const ReturnRow = ({
  row, index
}) => {
  const {
    dateCreated, actionDone, editedBy, reasonForUpdate
  } = row;
  const isLarge = useMediaQuery('(min-width:992px)');
  const renderDate = moment(dateCreated).format('DD/MM/YY');

  const erpCells = [actionDone, editedBy, reasonForUpdate];

  const returnCell = (val) => val || 'N/A';

  const renderBodyCells = () => {
    return erpCells.map((cell) => (
      <BodyCell>{isLarge ? returnCell(cell) : ''}</BodyCell>
    ));
  };

  return (
    <MainTableRow style={index % 2 ? { backgroundColor: '#F3F9FF' } : { backgroundColor: '#FFF' }}>
      <BodyCell>{renderDate}</BodyCell>
      {renderBodyCells()}
      <BodyCell />

    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
};

ReturnRow.defaultProps = {
  row: {},
  index: 0,
};

export default ReturnRow;
