import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText, Slide, Grid, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  CardTitle,
  DialogContentStyled,
  TitleDesc,
  DialogContainer,
  ListContainer,
  ErrorIconWrapper,
  SuccessContainer,
  ListItems,
  ErrDetails,
  SuccessTitle,
  SuccessDesc
} from './productStatusDialog.style';
import { FilledCustomButton } from '../customComponents/customButton';
import { ErrorIcon, SuccessIcon } from '../../assets/svgs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const FailedProductDialog = ({
  openDialog, toggleHandler, data, title
}) => (
  <DialogContainer
    open={openDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={toggleHandler}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContentStyled>
      <DialogContentText>
        <Grid style={{ marginTop: '1rem' }}>
          <Box display="flex" justifyContent="space-between">
            <CardTitle>{title}</CardTitle>
            <Close
              fontSize="small"
              onClick={toggleHandler}
              style={{ cursor: 'pointer', color: '#235A91' }}
            />
          </Box>
          <TitleDesc>
            View the failed uploads in your csv and the reason why
          </TitleDesc>
          {data?.length < 1 ? (
            <SuccessContainer>
              <SuccessIcon style={{ fontSize: '120px' }} />
              <SuccessTitle>No Failed Uploads!</SuccessTitle>
              <SuccessDesc>All your uploads were successful.</SuccessDesc>
              <FilledCustomButton onClick={toggleHandler}>
                Ok
              </FilledCustomButton>
            </SuccessContainer>
          ) : (
            data?.map((item, idx) => {
              const { row, desc } = item;
              return (
                <ListContainer key={idx}>
                  <ListItems>
                    <ErrorIconWrapper>
                      <ErrorIcon />
                    </ErrorIconWrapper>
                    <ErrDetails>
                      <strong>{`Row ${row || 'N/A'} : `}</strong>
                      {desc || 'Error not defined'}
                    </ErrDetails>
                  </ListItems>
                </ListContainer>
              );
            })
          )}
        </Grid>
      </DialogContentText>
    </DialogContentStyled>
  </DialogContainer>
);

FailedProductDialog.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired
};

FailedProductDialog.defaultProps = {
  openDialog: false,
  data: []
};

export default FailedProductDialog;
