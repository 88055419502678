import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LoaderContainer = styled('div')`
  position: relative;
`;

export const LoaderTypo = styled(Typography)`
  position: absolute;
  left: 20vw;
  top: 3%;
  z-index: 2;
  font-size: 32px;
  color: #4D4F5C;

  @media(min-width: 768px) {
    left: 35vw;
  }
  @media(min-width: 992px) {
    left: 40vw;
  }
`;

export const LoaderBackground = styled('img')`
  width: 100%;
  height: calc(100vh - 110px);
`;

export const LoaderWaiting = styled('img')`
  position: absolute;
  left: 20vw;
  top: 22%;
  z-index: 2;
  width: 18rem;

  @media(min-width: 768px) {
    left: 35vw;
  }

  @media(min-width: 992px) {
    left: 40vw;
  }
`;

export const LinearGrid = styled(Grid)`
  position: absolute;
  bottom: 2%;
  z-index: 2;
  padding: 0 2rem;
  width: 100%
`;
