import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import affiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import orderActionTypes from '../../providers/reducers/order/orderTypes';
import { useStateValue } from '../../providers/stateProvider';
import {
  BoxWrapper, CardContainer, Icons, ViewWrapper, Title, Description, TitleWrapper, ContainerWrapper, Arrows,
  CountTypo, CountBox
} from './overview.styles';
import FolderIcon from '../../assets/images/FolderIcon.png';
import FileIcon from '../../assets/images/FileIcon.png';
import Mark from '../../assets/images/Mark.png';
import { ORDER_COUNTS_QUERY } from '../../queries/orders';
import { AFFILIATES_COUNTS_QUERY } from '../../queries/affiliates';
import { addHyphen } from '../shared/methods';

const OverviewCard = () => {
  const navigate = useNavigate();

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }, dispatch] = Object.values(useStateValue());

  const routeHanlder = (path, data) => {
    if (data === 'closed' || data === 'open') {
      dispatch({
        type: orderActionTypes.UPDATE_ORDER,
        payload: {
          ordersStatus: data
        }
      });
      navigate(path);
    } else {
      dispatch({
        type: affiliateActionTypes.UPDATE_AFFILIATE,
        payload: {
          affiliateStatus: data
        }
      });
      navigate(path);
    }
  };

  const {
    data: order
  } = useQuery(ORDER_COUNTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: affiliate
  } = useQuery(AFFILIATES_COUNTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { totalClosedOrders, totalOpenOrders, totalOrders } = order?.orderCounts || {};
  const { totalActiveAffiliates, totalInactiveAffiliates, totalAffiliates } = affiliate?.affiliateCounts || {};

  const cards = [
    {
      id: 0,
      icon: Mark,
      title: 'Total Affiliates',
      desc: 'View Total Affiliates',
      link: '/manage-affiliates',
      value: totalAffiliates,
      style: { pointerEvents: !userPermissions?.includes('manage_affiliates_view_manage_affiliates') ? 'none' : '' }
    },
    {
      id: 1,
      icon: FolderIcon,
      title: 'Active Affiliates',
      desc: 'View Active Affiliates',
      link: '/manage-affiliates',
      state: 'VERIFIED',
      value: totalActiveAffiliates,
      style: { pointerEvents: !userPermissions?.includes('manage_affiliates_view_manage_affiliates') ? 'none' : '' }
    },
    {
      id: 2,
      icon: FileIcon,
      title: 'Inactive Affiliates',
      desc: 'View Inactive Affiliates',
      link: '/manage-affiliates',
      state: 'UNVERIFIED',
      value: totalInactiveAffiliates,
      style: { pointerEvents: !userPermissions?.includes('manage_affiliates_view_manage_affiliates') ? 'none' : '' }
    },
    {
      id: 3,
      icon: Mark,
      title: 'Total Orders',
      desc: 'View Total Orders',
      link: '/orders-admin',
      state: 'open',
      value: totalOrders,
      style: { pointerEvents: !userPermissions?.includes('manage_orders_view_manage_orders') ? 'none' : '' }
    },
    {
      id: 4,
      icon: FolderIcon,
      title: 'Total Open',
      desc: 'View Total Open Orders',
      link: '/orders-admin',
      state: 'open',
      value: totalOpenOrders,
      style: { pointerEvents: !userPermissions?.includes('manage_orders_view_manage_orders') ? 'none' : '' }
    },
    {
      id: 5,
      icon: FileIcon,
      title: 'Total Closed',
      desc: 'View Total Closed Orders',
      link: '/orders-admin',
      state: 'closed',
      value: totalClosedOrders,
      style: { pointerEvents: !userPermissions?.includes('manage_orders_view_manage_orders') ? 'none' : '' }
    }
  ];

  return (
    <ContainerWrapper container spacing={3} data-testid="overview-card">
      {cards?.map((card) => (
        <BoxWrapper
          onClick={() => routeHanlder(card?.link, card?.state)}
          item
          sm={12}
          md={4}
          key={card?.id}
          style={card.style}
          className={`mpAdmin-uat-dashboardPage-${addHyphen(card.title)}`}
        >
          <CardContainer>
            <TitleWrapper container>
              <Icons src={card.icon} />
              <Title>{card.title}</Title>
              <CountBox>
                <CountTypo>{card.value}</CountTypo>
              </CountBox>
            </TitleWrapper>
            <ViewWrapper container>
              <Description>{card.desc}</Description>
              <Arrows fontSize="large" />
            </ViewWrapper>
          </CardContainer>
        </BoxWrapper>
      ))}
    </ContainerWrapper>
  );
};
export default OverviewCard;
