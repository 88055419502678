import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  GridContainer, ItemTypo, ItemWrapper, GridWrapper,
} from './invoiceDownloadDropdown.styles';
import { RenderPopper } from '../../../../utils/utils';
import { MAIL_PROFORMA_INVOICE_BUSINESS } from '../../../../mutations/orders';
import { OgaToken } from '../../../../graphql/token';

const InvoiceDropdown = ({
  openInvoiceDialog, handleOptionClick, anchorEl, orderId
}) => {
  const open = Boolean(openInvoiceDialog);
  const [mailProformaInvoiceBusiness] = useMutation(MAIL_PROFORMA_INVOICE_BUSINESS);
  const handleDownload = () => {
    // window.open(
    //   `${OgaToken.SERVER_LINK}download_order_proforma_order/${orderId}`,
    //   '_blank'
    // );
    window.open(
      `${OgaToken.SERVER_LINK}download_order_proforma/${orderId}.pdf`,
      '_blank'
    );
  };

  const handleEmail = () => {
    mailProformaInvoiceBusiness({
      variables: {
        orderId
      }
    }).then(() => {
      toast.success('Email sent successfully');
    }).catch((err) => {
      toast.error('Mail Not sent');
    });
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={anchorEl}
      onClickAway={() => handleOptionClick()}
      popperPlacement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer container item onClick={() => handleDownload()}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Print
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer container item onClick={() => handleEmail()}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Email
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
};

InvoiceDropdown.propTypes = {
  openInvoiceDialog: PropTypes.bool.isRequired,
};

export default InvoiceDropdown;
