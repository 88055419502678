import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { ALL_BUSINESS_ROLES_QUERY } from '../../../queries/businesses';
import Users from './users';

const UsersContainer = () => {
  const location = useLocation();
  const param = location?.state;

  const [pageCount, setPageCount] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const variables = {
    pageCount,
    pageNumber,
    search: ''
  };

  const {
    data,
  } = useQuery(ALL_BUSINESS_ROLES_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const {
    allSystemRoles = [],
  } = data || {};

  return (
    <Users
      userRole={allSystemRoles}
      param={param}
    />
  );
};

export default UsersContainer;
