import { styled } from '@mui/material/styles';
import {
  Grid, Typography, IconButton, Dialog, Box
} from '@mui/material';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: .6rem;
  }
`;

export const BoxHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
`;

export const Text = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #24292E;
`;

export const ContentGrid = styled(Grid)`
  padding: 0 1.5rem 1.5rem 1.5rem;
`;

export const InputGrid = styled(Grid)`
  padding: 15px 0 0;
`;

export const ActionsGrid = styled(Grid)`
  padding: 15px 20px;
`;
export const DoneButton = styled(IconButton)`
  padding: 6px 25px;
  border-radius: 5px;
  background-color: #FCED50!important;
  font-size: .95rem;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;

export const CancelButton = styled(DoneButton)`
  background-color: #fff!important;
  border: 1px solid black;

  &:hover {
    background-color: #fff;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: 22px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const SmallText = styled(Typography)`
  display: block;
  font-size: 0.85rem
`;

export const Hybrid = styled('div')`
  padding: 0.4rem 1rem 0.4rem 1rem;
  display flex;
  justify-content: space-between;
  align-items: center;
  background: #ECF6FF;
  margin: 0 1.5rem 0 1.5rem;
`;
