import {
  Dialog, Grid, Typography, DialogContent, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 16px;
    padding: .8rem;
    max-height: 43.313rem;
    width: 38.25rem;
    overflow: hidden;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 0.5rem 1.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 0 1.5rem;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 24px;
`;

export const DialogActions = styled(Box)`
  max-height: 31.5rem;
  margin-top: 1.5rem;
  overflow: auto;
  width: 35.625rem;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #235A91;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track{
    margin: 40px;    
  }
`;

export const DialogActionGrid = styled(Grid)`
  height: 5.25rem;
  cursor: pointer;
`;

export const IndividualGrid = styled(Grid)`
`;

export const SupplierLogo = styled('img')`
  height: 51px;
  width: 51px;
`;

export const SupplierName = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  margin-top: 1rem;
`;
