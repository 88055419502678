const ordersReport = {
  productBatches: [],
  ordersReport: {},
  productBatchesCount: 0,
  totalCount: 0,
  expiryCount: 0,
  inventoryCount: 0,
  refetch: null,
  addProductDialogOpen: false,
  productRow: null
};

export default ordersReport;
