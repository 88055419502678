export const addItemToCart = (cartItems, payload) => {
  const { product, counter } = payload;
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.id === product.id
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) => (cartItem.id === existingCartItem.id
      ? { ...cartItem, quantity: cartItem.quantity + counter }
      : cartItem));
  }

  return [...cartItems, { ...product, quantity: counter }];
};

export const updateCartCounter = (supplierOrderSet) => {
  const cartTot = supplierOrderSet.length && supplierOrderSet.map(
    (supplierOrder) => supplierOrder?.orderproductSet.length
  ).reduce((sum, i) => sum + i, 0);

  return cartTot;
};

export const updateCartCount = (total) => {
  return total;
};
