// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const raspEnv = process.env.RASPBERRY_ENV;

const firebaseConfig = {
  apiKey: `${process.env.FIREBASE_API_KEY}`,
  authDomain: `${process.env.AUTH_DOMAIN}`,
  projectId: `${process.env.PROJECT_ID}`,
  storageBucket: `${process.env.STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.MESSAGING_SENDER_ID}`,
  appId: `${process.env.APP_ID}`,
  measurementId: `${process.env.MEASUREMENT_ID}`
};

// Initialize Firebase
initializeApp(firebaseConfig);

let messaging = null;
if (raspEnv !== 'pi') {
  messaging = getMessaging();
}

export const requestForToken = async () => {
  if (raspEnv !== 'pi') {
    try {
      const currentToken = await getToken(messaging, { vapidKey: `${process.env.VAPID_PUBLIC_KEY}` });
      if (currentToken) {
        return currentToken;
      }
      return '';
    } catch (err) {
      return '';
    }
  } else return '';
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () => new Promise((resolve, reject) => {
  if (raspEnv === 'pi') reject(new Error('We are on PI'));
  else {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  }
});
