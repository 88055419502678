import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Grow, Paper, Popper, MenuList
} from '@mui/material';
import { Dots } from '../../../assets/svgs';
import {
  MenuItemStyled, ButtonEllipsis, GridWrapper, GridContainer, ItemWrapper, ItemTypo
} from './actionPopper.styles';
import { RenderPopper } from '../../../utils/utils';

export default function ActionPopper({ row, handleAction }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { status } = row;
  const statusOption = () => {
    if (status !== 'IN_ACTIVE') return true;
    return false;
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonEllipsis
        data-testid="btn-popper"
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
      >
        <Dots fontSize="small" />
      </ButtonEllipsis>
      <RenderPopper
        data-testid="popper"
        open={open}
        anchorEl={anchorRef.current}
        popperPlacement="bottom"
        onClickAway={handleClose}
        style={{ marginTop: '6rem !important' }}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
        productInfoPopper
      >
        <GridWrapper container item>
          <GridContainer
            container
            item
            data-testid="product_status"
            onClick={() => handleAction(setOpen)}
            autoFocusItem
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                {statusOption() ? 'Disable' : 'Enable'}
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </RenderPopper>
      {/* <Popper
        data-testid="popper"
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled
                    data-testid="product_status"
                    onClick={() => handleAction(setOpen)}
                  >
                    {statusOption() ? 'Disable' : 'Enable'}
                  </MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
};
