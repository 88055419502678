import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  BellImg,
  Container,
  Duration,
  Header,
  HeaderContainer,
  Info,
  NotificationImg,
  TestWrapper
} from './styles';
import bell from '../../../assets/images/bell.gif';

const NotificationDialog = ({
  message, setMessage
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    setMessage({
      message: null,
    });
  }, [message]);

  const handleView = () => {
    if (message?.order_id) {
      navigate(`/orders-admin/dispatch/${message?.order_id}`);
    } else {
      navigate(`/new-order/All Categories/0/${message?.product_id}`);
    }
  };
  const notificationTitle = (type) => {
    switch (type) {
      case 'new_product_alert':
        return 'New Product Alert';
      case 'order_approval':
        return 'Order Approved';
      case 'order_sorted':
        return 'Order Sorted';
      case 'order_dispatched':
        return 'Order Dispatched';
      case 'order_ready_for_dispatched':
        return 'Order Ready For Dispatch';
      case 'order_returned':
        return 'Order Returned';
      default:
        return 'Product Back in Stock';
    }
  };
  return (
    toast((t) => (
      <Container data-testid="toastId" onClick={handleView}>
        <BellImg src={bell} alt="warning" width={120} />
        <TestWrapper>
          <HeaderContainer>
            <Header>
              {notificationTitle(message?.type)}
            </Header>
            <NotificationImg />
          </HeaderContainer>
          <Info>{message?.product_name || message?.message}</Info>
        </TestWrapper>
        <Duration>Now</Duration>
      </Container>
    ),
    {
      duration: 10000,
      position: 'top-right',
    }
    )
  );
};

NotificationDialog.propTypes = {
  message: PropTypes.shape(Object),
};

NotificationDialog.defaultProps = {
  message: {}
};

export default NotificationDialog;
