import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import PayHippo from '@payhippo/embedded-credit-sdk';
import '@payhippo/embedded-credit-sdk/dist/styles.css';
import {
  PaperContainer, TeaserGrid, LoanText, ArrowIcon, ProvidersGrid,
  PayhippoImage, MCFImage
} from './floater.styles';
import Payhippo from '../../assets/images/payhippo.png';
import MCF from '../../assets/images/mcf.png';
import MCFDialog from './mcfDialog';

const Floater = ({
  state, priceFactor, handleOpenMCF, handleCloseDialog
}) => {
  const [open, setOpen] = useState(false);
  const { openMCF } = state;
  const {
    cart, serviceFee, deliveryFee,
  } = state;

  useEffect(() => {
    PayHippo.init({
      env: 'TEST',
      partnerId: process.env.PAY_HIPPO_PARTNER_ID,
      secretKey: process.env.PAY_HIPPO_SECRET_KEY,
    });
  }, []);

  const returnBusinessData = () => {
    const {
      business: {
        name: businessName,
        contacts,
        dateCreated
      }
    } = state;
    const contactsMeta = JSON.parse(contacts.replace(/'/g, '"'));
    const customerCreatedDate = new Date(dateCreated).getTime();
    const {
      address_line_1: address,
      city,
      country,
      mobile_number: phoneNumber,
      primary_contact_name: ownerName,
      primary_email_address: email
    } = contactsMeta;

    return [
      businessName, ownerName, phoneNumber, email,
      address, city, country, customerCreatedDate
    ];
  };

  const handlePayHippoWidget = () => {
    const [
      businessName, ownerName, phoneNumber, email,
      address, city, country, customerCreatedDate
    ] = returnBusinessData();
    const orderTotal = cart.reduce((sum, obj) => sum + (Number(obj.quantity) * Number(obj.resolvedPriceInUseValue)), 0) * priceFactor;
    const cartAmount = serviceFee + deliveryFee + orderTotal;

    const data = {
      businessLocation: {
        address,
        city: city || 'Lagos',
        country: country || 'Nigeria',
        state: 'Lagos',
        zipCode: '123-123',
      },
      businessOwner: {
        birthDate: 1234567891,
        firstName: ownerName,
        lastName: 'X',
        gender: 'FEMALE',
      },
      businessName,
      businessType: 'PHARMACY',
      cartAmount,
      email,
      phoneNumber,
      customerCreatedDate,
      customerActivationDate: customerCreatedDate
    };

    PayHippo.openWidget({
      ...data,
      bvn: '20021221121',
      // customerCreatedDate: 1597590921,
      // customerActivationDate: 1597590921,
      phoneNumber: '2346505553495', // Use this number while Testing | Token = 123456
      transactionHistory: {
        totalAmount: 1000000,
        totalCount: 5,
      },
    });
  };

  return (
    <>
      <PaperContainer elevation={0} square open={open}>
        <TeaserGrid onClick={() => setOpen(!open)}>
          <ArrowIcon open={open} />
          <LoanText>
            get a loan
          </LoanText>
        </TeaserGrid>
        <ProvidersGrid container>
          <Grid item>
            <PayhippoImage
              alt="PayHippo icon"
              src={Payhippo}
              onClick={handlePayHippoWidget}
            />
          </Grid>
          <Grid item>
            <MCFImage
              alt="MCF icon"
              src={MCF}
              onClick={handleOpenMCF}
            />
          </Grid>
        </ProvidersGrid>
      </PaperContainer>
      <MCFDialog
        dialogOpen={openMCF}
        closeDialog={handleCloseDialog}
        returnBusinessData={returnBusinessData}
        oldState={{
          cart, serviceFee, deliveryFee, priceFactor
        }}
      />
    </>
  );
};

Floater.propTypes = {
  state: PropTypes.instanceOf(Object),
  priceFactor: PropTypes.number,
  handleOpenMCF: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired
};

Floater.defaultProps = {
  state: {},
  priceFactor: 0
};

export default Floater;
