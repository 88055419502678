import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { TableBody, Grid } from '@mui/material';
import toast from 'react-hot-toast';
import { TICKETS_QUERY } from '../../queries/helpDesk';
import { UPDATE_TICKET } from '../../mutations/helpDesk';
import DevTicketModel from './devTicketModel/devTicketModel';
import {
  TableWrapper, TableContainer, TableHeaderTitle, TableHeaderGrid, FooterGrid
} from './tickets.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import CTablePagination from '../customComponents/cTablePagination';
import HelpDestActionTypes from '../../providers/reducers/helpDesk/helpDeskTypes';

import { useStateValue } from '../../providers/stateProvider';

const Tickets = ({
  state, handleChangePage, page, handleChangeRowsPerPage,
  isDev
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [ticket, setTicket] = useState({});
  const [openTicketModel, setOpenTicketModel] = useState(false);
  const {
    searchText, status, currentPage, currentPageCount
  } = state;

  const [{
    helpDesk: { refetch },
  }, dispatch] = Object.values(useStateValue());

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleOpenTicketModel = (_ticket) => {
    setTicket(_ticket);
    setOpenTicketModel(true);
  };

  const [updateTicket] = useMutation(UPDATE_TICKET);
  const handleUpdateTicket = (_status, comment, handleReset) => {
    updateTicket({
      variables: {
        ticketId: ticket?.id,
        status: _status,
        comment
      }
    }).then(({ data }) => {
      const { updateHelpdeskTicket: { message } } = data;
      toast.success(message);
      refetch();
      handleReset();
      setOpenTicketModel(false);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const variables = {
    status,
    pageCount: currentPageCount,
    pageNumber: currentPage,
  };

  const {
    loading, error, data, refetch: ticketsRefetch
  } = useQuery(TICKETS_QUERY, {
    fetchPolicy: 'cache-and-network', variables
  });

  useEffect(() => {
    if (data && data.tickets) {
      const { tickets, ticketsTotalNumber } = data;
      dispatch({
        type: HelpDestActionTypes.UPDATE_HELP_DESK,
        payload: {
          tickets, ticketsCount: ticketsTotalNumber, refetch: ticketsRefetch
        }
      });
    }
  }, [data]);

  if (loading) return <ProductsTableLoader />;
  if (error) return <div>{error.message}</div>;
  const {
    tickets = {}, ticketsTotalNumber = 0
  } = data || {};

  const tableHeaders = {
    dateCreated: (
      <TableHeaderTitle style={{ width: '160px' }}>
        Date Created
      </TableHeaderTitle>),
    createdBy: (
      <TableHeaderTitle style={{ width: '190px' }}>
        Created By
      </TableHeaderTitle>),
    issueID: (
      <TableHeaderTitle style={{ width: '140px' }}>
        Issue ID
      </TableHeaderTitle>),
    description: (
      <TableHeaderTitle style={{ width: '380px' }}>
        Description
      </TableHeaderTitle>),
    status: (
      <TableHeaderTitle style={{ width: '130px' }}>
        Status
      </TableHeaderTitle>),
    lastStatus: (
      <TableHeaderTitle style={{ width: '190px' }}>
        Last Status
      </TableHeaderTitle>),
    lastUpdated: (
      <TableHeaderTitle style={{ width: '190px' }}>
        Last Updated
      </TableHeaderTitle>),
  };

  return (
    <>
      {ticketsTotalNumber ? (
        <>
          <Grid container style={{ overflowX: 'auto' }}>
            <TableWrapper>
              <TableContainer style={{ padding: '10px' }}>
                <Grid container>
                  <TableHeaderGrid item>{tableHeaders.dateCreated}</TableHeaderGrid>
                  <TableHeaderGrid item>{tableHeaders.createdBy}</TableHeaderGrid>
                  <TableHeaderGrid item>{tableHeaders.issueID}</TableHeaderGrid>
                  <TableHeaderGrid item>{tableHeaders.description}</TableHeaderGrid>
                  <TableHeaderGrid item>{tableHeaders.status}</TableHeaderGrid>
                  <TableHeaderGrid item>{tableHeaders.lastStatus}</TableHeaderGrid>
                  <TableHeaderGrid item>{tableHeaders.lastUpdated}</TableHeaderGrid>
                </Grid>
                <TableBody>
                  {tickets.map((_row) => (
                    <ReturnRow
                      key={_row.id}
                      ticket={_row}
                      isDev={isDev}
                      handleOpenTicketModel={() => handleOpenTicketModel(_row)}
                    />
                  ))}
                </TableBody>
              </TableContainer>
            </TableWrapper>
          </Grid>
          <FooterGrid item container>
            <CTablePagination
              rowsPerPageOptions={[20, 50, 100, 150, 200]}
              component="div"
              count={ticketsTotalNumber}
              page={page - 1}
              onChangePage={handleChangePage}
              rowsPerPage={currentPageCount}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </FooterGrid>
        </>
      ) : (
        <h3 style={{ textAlign: 'center' }}>
          No tickets found.
        </h3>
      )}

      <DevTicketModel
        open={openTicketModel}
        handleClose={() => setOpenTicketModel(false)}
        ticket={ticket}
        handleUpdateTicket={handleUpdateTicket}
      />
    </>
  );
};

Tickets.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  state: PropTypes.instanceOf(Object),
  isDev: PropTypes.func.isRequired
};

Tickets.defaultProps = {
  state: {},
  page: 1,
};

export default Tickets;
