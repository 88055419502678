import {
  TableRow, TableCell, Grid, Typography, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo, VerticalDots } from '../../../assets/svgs';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.3rem;
  padding: 15px;
  border-bottom: .5px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: .9rem;
    padding: 10px;
    cursor: pointer;
  }
`;

export const ItemGrid = styled(Grid)`
  cursor: pointer;
  margin-bottom: 15px;
  min-height: 200px;
  border: 0.5px solid #E7E8E9;
  @media(max-width: 991px) {
    margin-bottom: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border: 0.5px solid #E7E8E9;
    border-radius: 20px;
  }
`;

export const ItemContent = styled(Grid)`
  padding: 20px;
  @media(max-width: 991px) {
    padding: 0px 30px;
  }
`;

export const ActionItem = styled(Grid)`
  display: flex;
  height: 86%;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;

export const ImgContent = styled(Grid)`
  height: 90%;
  width: 100%;
  background: #F3F9FF;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 991px) {
    height: 90%;
    border-radius: 25px;
  }
`;

export const ItemLogo = styled('img')`
  height: 80%;
  width: 85%;
  @media(max-width: 991px) {
    height: 60%;
    width: 65%;
  }
`;

export const ItemTypo = styled(Typography)`
  margin-bottom: 20px;
  font-size: 33px;
  @media(min-width: 992px) {
    font-size: 17px;
    margin-bottom: 10px;
  }
`;

export const DetailContainer = styled('div')`
  display: flex;
  margin-bottom: 15px;
  @media(max-width: 991px) {
    margin-bottom: 25px;
  }
`;

export const TypeImg = styled('img')`
  height: 50px;
  @media(min-width: 992px) {
    height: 20px;
  }
`;

export const OpenButton = styled(Button)`
  background-color: #FBF777;
`;

export const DetailButton = styled(Button)`
  margin-top: 4rem;

  @media (min-width: 992px) {
    height: 3rem;
  }
  @media(max-width: 991px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 2.2rem;
    padding: 2rem;
    width: 100%;
    font-weight: 600;
  }
`;

export const TrackOrderButton = styled(Button)`
  margin-top: 4rem;
  background-color: #333333;
  color: #ffffff;
  width: 100%;

  @media (min-width: 992px) {
    height: 3rem;
  }
  @media(max-width: 991px) {
    margin-top: .5rem;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    padding: 2rem;
  }

  &:hover {
    background-color: #333333;
  }
`;

export const DetailContent = styled(Grid)`
  text-align: left;
  display: block;
  font-size: 2.5rem;
  @media(min-width: 992px) {
    text-align: right;
    font-size: 1.8rem;
  }
`;

export const DetailsContent = styled(Grid)`
  text-align: left;
  @media(min-width: 992px) {
    text-align: right;
  }
`;

export const VisibleImg = styled('img')`
  @media(max-width: 991px) {
    height: 3rem;
  }
`;

export const ColorGrid = styled(Grid)`
  text-transform: capitalize;
  color: #606060;
  font-size: .7rem;
  font-weight: 500;
  justify-content: flex-start;
  padding: 12px 0;
  align-items: center;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const Dot = styled(Grid)`
  background: ${({ background }) => (background)};
  border-radius: 50%;
  text-transform: capitalize;
  width: 9px;
  height: 9px;
  margin-right: 6px;
  @media(max-width: 991px) {
    width: 30px;
    height: 25px;
  }
`;

export const CardLogo = styled(Logo)`
  width: 100%;
  height: 50%;
`;

export const Dots = styled(VerticalDots)`
  &.MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }
`;
