import {
  Grid, Typography, IconButton, CircularProgress,
  TextField, TextareaAutosize, Dialog
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 1rem 1.5rem;
    width: 100%;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
  color: #235A91;
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 12px;
  color: #858383;
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const DividerLine = styled('hr')`
  border: 1px solid #CCCCCC;
  width:96%;
  min-width: 300px;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #E0E0E0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const DialogActionButtonText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 5px;
  background-color: #235A91;;
  color: #FFFFFF;
  margin-bottom: 40px;

  &:hover {
    background-color: #235A91;
    color: #FFFFFF;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #235A91;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 40px;
  border: 2px solid #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const UploadGrid = styled(Grid)`
  background-color: #E0E0E0;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const SupplierTextField = styled(TextField)`

`;

export const SupplierTextArea = styled(TextareaAutosize)`
  padding: 20px 12px 10px;
  background: #E0E0E0;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid #424242;
  outline: none;

  &:focus{
    border: 0px solid red;
    border-bottom: 1px solid yellow;
  }

  &:focus-visible{
    border: 0px solid red !important;
    border-bottom: 1px solid yellow !important;
    outline: none !important;
  }
`;

export const TopGrid = styled(Grid)`
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const MiddleGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
`;

export const BottomGrid = styled(Grid)`
    & > * {
      width: calc(33.33% - 1rem);
      margin: 0 1.5rem 1.5rem 0;

      :last-child {
        margin-right: 0;
      }
    }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CustomDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
    max-width: 30rem;
  }
  & .MuiDialog-paperWidthSm {
    max-width: 450px;
}
`;

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperFullWidth {
    width: 744px;
    max-height: 618px;
    border-radius: 12px;
  }
`;
