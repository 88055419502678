import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModelContainer from '../shared/crudModel/modelsContainer';
import TherapeuticTypeCard from './therapeuticTypeCard';
import { SupplierCardGrid } from './therapeuticTypeCard.styles';
import IndividualTherapeuticTypeDialog from './individual/individualTherapeuticTypeDialog';
import { MARKETPLACE_CATEGORIES } from '../../queries/categories';

function TherapeuticTypes() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState('');
  const handleDropdown = (name) => {
    if (dropdown === name) return setDropdown('');
    return setDropdown(name);
  };
  return (
    <ModelContainer
      findModelsQuery={MARKETPLACE_CATEGORIES}
      modelName="Product type"
      modelNamePlural="Product Type(s)"
      modelTotalNumber="categoriesTotalNumber"
      modelQueryName="categories"
      renderIndividualModel={(addModelDialogOpen, toggleIndividualDialog) => (
        <IndividualTherapeuticTypeDialog
          dialogOpen={addModelDialogOpen}
          closeDialog={toggleIndividualDialog}
        />
      )}
      therapeuticTypes
      renderModelCard={(model, refetch) => (
        model.map((mod, index) => (
          <SupplierCardGrid key={mod.id} item xs={12}>
            <TherapeuticTypeCard
              id={mod.id}
              name={mod.therapeuticType}
              productCount={mod.therapeuticTypeProductCount}
              subType={mod.subTherapeuticType}
              index={index}
              dropdown={dropdown}
              handleDropdown={handleDropdown}
              refetch={refetch}
            />
          </SupplierCardGrid>
        ))
      )}
      modelCardOnclickAction={(model) => navigate(`/therapeutic-type/${model.id}/details`)}
    />
  );
}

export default TherapeuticTypes;
