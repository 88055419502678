import BackOrderCartActionTypes from './backOrderTypes';

const backOrderReducer = (cart, action) => {
  switch (action.type) {
    case BackOrderCartActionTypes.UPDATE_CART:
      return {
        ...cart,
        ...action.payload
      };
    default:
      return cart;
  }
};

export default backOrderReducer;
