import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Grid, Table, TableHead, TableBody, TableRow, TableCell,
  TextField, InputAdornment,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import toast from 'react-hot-toast';
import MainContent from '../../customComponents/mainContent';
import {
  SummaryGridContainer, SummaryH1, SummaryConfirmButton,
  SaleSummarySection, SalePayment, SummaryTableCellHead,
  SummaryTableCellNoBorder, PaymentP, Qty
} from './summary.styles';
import { BackArrowIconContainer, KeyboardArrowLeft } from '../../cart/cart.styles';
import { SaleFooterIcon, ReceiptDialog } from '../../sales-xxx/sales.styles';
import ReturnReceipt from '../../sales-xxx/returnReceipt';
import { MAKE_RETURN_MUTATION } from '../../../mutations/sales';
import { GET_SALE_QUERY } from '../../../queries/sales';
import AccountIcon from '../../../assets/images/sidenav/Account.png';
import OutletIcon from '../../../assets/images/sidenav/Outlet.png';
import Confirm from '../../../assets/images/Confirm.png';
import Add from '../../../assets/images/Add.png';
import ReturnActionTypes from '../../../providers/reducers/returns/returnsTypes';
import { useStateValue } from '../../../providers/stateProvider';
import currencyFormatter from '../../../utils/currencyFormatter';
import { addRawSaleData } from '../../../lib/saleObject';
import { returnLocalSale } from '../../../lib/utils/sales';

const ReturnsSummary = ({ session }) => {
  const navigate = useNavigate();
  const [{
    returns: {
      total, subTotal: subtotal, returnCartItems, returnSaleId, oldCartId
    }
  }, dispatch] = Object.values(useStateValue());
  const [cash, setCash] = useState();
  const [card, setCard] = useState();
  const [bankTransfer, setBankTransfer] = useState();
  const [storeCredit, SetStoreCredit] = useState();
  const [subTotal, setSubTotal] = useState(subtotal);
  const [balanceDue, setBalanceDue] = useState(subTotal);
  const [dialogBox, setDialogBox] = useState(false);
  const [saleReturnData, setSaleReturnData] = useState({ businessUser: {}, receiptNumber: '' });

  useLayoutEffect(() => {
    const methodsSum = cash + card + bankTransfer;
    const balance = total + methodsSum;

    if (balance >= total) {
      setBalanceDue(balance);
    }
  });

  const cart = [];
  returnCartItems.forEach((value) => {
    cart.push(value);
  });

  const handleChange = (props, v) => {
    let value = Number(v);
    if (value < 1) {
      value = '';
    }
    if (props === 'card') {
      setCard(value);
    }

    if (props === 'cash') {
      setCash(value);
    }

    if (props === 'bankTransfer') {
      setBankTransfer(value);
    }
  };

  const [returnSale] = useMutation(MAKE_RETURN_MUTATION);

  const openDialog = async () => {
    const paymentMethod = [];

    if (cash > 0) {
      paymentMethod.push({ paymentMethod: 'CASH', amount: cash });
    }
    if (card > 0) {
      paymentMethod.push({ paymentMethod: 'CARD', amount: card });
    }
    if (bankTransfer > 0) {
      paymentMethod.push({ paymentMethod: 'BANK_TRANSFER', amount: bankTransfer });
    }

    if (balanceDue === 0) {
      const sale = [];
      let returnTotal = 0;
      returnCartItems.forEach((item) => {
        returnTotal += item.quantity * item.itemPrice;
        sale.push({
          productId: item.productId,
          quantity: item.quantity,
          resellable: item.resellable,
          itemBatches: item.itemBatches,
          reason: item.reason
        });
      });
      const date = new Date();
      const params = {
        cart: sale,
        receiptNumber: uuid().slice(0, 12),
        transactionDate: date.toISOString(),
        returnSaleId: returnSaleId * 1,
        paymentsMade: paymentMethod,
      };
      const rawDataId = await addRawSaleData(params);
      const key = 'id';
      delete params[key];

      const variables = {
        ...params,
        version: rawDataId
      };
      const saleId = await returnLocalSale(params, session, date);

      const saleData = await returnSale({ variables });
      if (saleData && saleData.data) {
        const { data: { createReturn: { sale: { businessUser, receiptNumber }, message } } } = saleData;
        setSaleReturnData({ businessUser, receiptNumber, message, });
        toast.success('Sales returned successfully');
        setDialogBox(!dialogBox);
      }
    } else if (balanceDue > 0) {
      toast.error('Amount selected is too much');
    } else {
      toast.error('Please enter amount to return');
    }
  };

  const closeReceiptDialog = () => {
    setDialogBox(false);
    toast.success(saleReturnData.message);
    dispatch({ type: ReturnActionTypes.CLEAR_RETURN_CART });
    setTimeout(() => navigate(`/sale-details/${returnSaleId}`), 3000);
  };

  const { data } = useQuery(GET_SALE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      id: returnSaleId,
    }
  });

  let oldCart = [];
  let cust;
  let sReturns;

  if (data && data.sale) {
    const { sale: { saleitemSet = [], customer, saleReturns } } = data;
    oldCart = saleitemSet;
    cust = customer;
    sReturns = saleReturns;
  }

  const x = [];

  oldCart.map((item) => {
    x.push({ brandName: item.name, itemPrice: item.unitCost, quantity: item.quantity });
    return null;
  });

  return (
    <MainContent>
      <SummaryGridContainer>
        <Grid container>
          <Grid item md={4}>
            <Grid container style={{ paddingLeft: '10%' }}>
              <Grid item md={2} style={{ paddingTop: '5%' }}>
                <BackArrowIconContainer onClick={() => navigate(`/sale-details/${returnSaleId}`)}>
                  <KeyboardArrowLeft />
                </BackArrowIconContainer>
              </Grid>
              <Grid item md={10}>
                <SummaryH1>Return Summary</SummaryH1>
                <p>View details of a return before refund</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container style={{ paddingLeft: '5%', fontSize: '16px', paddingTop: '5%' }}>
              <Grid item md={6}>
                <SaleFooterIcon src={OutletIcon} alt="outlet icon" />
                {' '}
              </Grid>
              <Grid item md={6} style={{ textAlign: 'center', cursor: 'pointer' }}>
                <SaleFooterIcon src={AccountIcon} alt="account icon" />
                {' '}
                Bunmi Adewunmi
                {' '}
                <i>is logged in</i>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} style={{ paddingTop: '2%' }}>
            <SummaryConfirmButton onClick={() => openDialog()}>
              <img style={{ width: '10%', marginRight: '10%' }} src={Confirm} alt="confirm" />
              Confirm Return
            </SummaryConfirmButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={9}>
            <SaleSummarySection>
              <Table>
                <TableHead>
                  <TableRow>
                    <SummaryTableCellHead>Qty</SummaryTableCellHead>
                    <SummaryTableCellHead>
                      Items (
                      {' '}
                      { cart.length }
                      {' '}
                      )
                    </SummaryTableCellHead>
                    <SummaryTableCellHead>Pack Size</SummaryTableCellHead>
                    <SummaryTableCellHead>Item Cost</SummaryTableCellHead>
                    <SummaryTableCellHead>Item Price</SummaryTableCellHead>
                    <SummaryTableCellHead>Reason</SummaryTableCellHead>
                    <SummaryTableCellHead>Resellable</SummaryTableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { cart.map((item) => (
                    <TableRow key={item.id}>
                      <SummaryTableCellNoBorder>
                        <Qty>{item.quantity}</Qty>
                      </SummaryTableCellNoBorder>
                      <SummaryTableCellNoBorder>{item.brandName}</SummaryTableCellNoBorder>
                      <SummaryTableCellNoBorder>{item.packSize}</SummaryTableCellNoBorder>
                      <SummaryTableCellNoBorder>{currencyFormatter(item.itemPrice)}</SummaryTableCellNoBorder>
                      <SummaryTableCellNoBorder>{currencyFormatter(item.itemPrice * item.quantity)}</SummaryTableCellNoBorder>
                      <SummaryTableCellNoBorder>{item.reason}</SummaryTableCellNoBorder>
                      <SummaryTableCellNoBorder>{item.resellable ? 'Yes' : 'No'}</SummaryTableCellNoBorder>
                    </TableRow>
                  ))}
                  <TableRow>
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder />
                    <TableCell><b>Amount to Refund</b></TableCell>
                    <TableCell />
                    <TableCell>
                      <b>{currencyFormatter(total)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder>Balance Due</SummaryTableCellNoBorder>
                    <SummaryTableCellNoBorder />
                    <SummaryTableCellNoBorder>{currencyFormatter(balanceDue)}</SummaryTableCellNoBorder>
                  </TableRow>
                </TableBody>
              </Table>
            </SaleSummarySection>
          </Grid>
          <Grid item md={3}>
            <SalePayment>
              <PaymentP>Refund</PaymentP>
              <div>
                <Grid container>
                  <Grid style={{ padding: '20px' }} item md={6}>
                    <TextField
                      label="Cash"
                      value={cash}
                      placeholder="0.00"
                      onChange={(e) => handleChange('cash', e.target.value)}
                      type="number"
                      id="outlined-start-adornment-x"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid style={{ padding: '20px' }} item md={6}>
                    <TextField
                      label="Card [POS]"
                      type="number"
                      value={card}
                      placeholder="0.00"
                      disabled
                      onChange={(e) => handleChange('card', e.target.value)}
                      id="outlined-start-adornment-xx"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid style={{ padding: '20px' }} item md={6}>
                    <TextField
                      label="Bank Transfer"
                      type="number"
                      value={bankTransfer}
                      placeholder="0.00"
                      onChange={(e) => handleChange('bankTransfer', e.target.value)}
                      id="outlined-start-adornment-x"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid style={{ padding: '20px' }} item md={6}>
                    <TextField
                      label="Store Credit"
                      disabled
                      placeholder="0.00"
                      value={storeCredit}
                      type="number"
                      id="outlined-start-adornment-xx"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₦</InputAdornment>,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <br />
                <PaymentP>Customer</PaymentP>
                <div>
                  <p style={{ paddingTop: '25%', textAlign: 'center', color: '#b1afaf' }}>No customer added</p>
                  <div style={{ width: '15%', margin: '20% auto' }}>
                    <img style={{ width: '100%' }} src={Add} alt="add" />
                  </div>
                </div>
              </div>
            </SalePayment>
          </Grid>

        </Grid>
      </SummaryGridContainer>
      <ReceiptDialog
        open={dialogBox}
        maxWidth="lg"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        style={{ zIndex: '10000000' }}
      >
        <ReturnReceipt
          cart={cart}
          returns={sReturns}
          customer={cust}
          saleReturnData={saleReturnData}
          subTotal={subTotal}
          total={subTotal}
          onClose={() => closeReceiptDialog()}
        />
      </ReceiptDialog>
    </MainContent>
  );
};

ReturnsSummary.propTypes = {
  session: PropTypes.shape(Object),
};

ReturnsSummary.defaultProps = {
  session: {},
};

export default ReturnsSummary;
