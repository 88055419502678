import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Grid } from '@mui/material';
import {
  BodyCell, MainTableRow, CustomCheckbox, CTextField, TextFieldBox, CustomInputBase,
  ActionText, ActionButton
} from './returnRow.styles';
import BatchDialog from './batchDialog/batchDialog';
import {
  CaretUpIcon, CaretDownIcon, EyeOutline
} from '../../../assets/svgs';

const ReturnRow = ({
  row, selected, handleSelect, disableField, handleChange,
  handleBatchUpdate, handleReasonChange
}) => {
  const isSelected = () => selected.indexOf(row.id) !== -1;
  const [batchDialog, setBatchDialog] = useState(false);
  const [orderBatch, setOrderBatch] = useState([]);
  const {
    id, brandName, quantityRecieved, quantityReturned, reason, name, returned, reasonForReturn,
    orderproductSetMeta
  } = row;
  const cells = [brandName || name, quantityRecieved];

  const returnCell = (val) => val || 'N/A';
  const handleBatchDialog = () => {
    setBatchDialog(!batchDialog);
  };
  const handleBatches = () => {
    const { batches } = orderproductSetMeta;
    setOrderBatch(batches);
    setBatchDialog(!batchDialog);
  };
  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell key={cell}>{returnCell(cell)}</BodyCell>
  ));

  const returnAction = () => (
    <ActionButton onClick={handleBatches}>
      <EyeOutline style={{ fontSize: '1.7rem' }} />
      <ActionText>View Batches</ActionText>
    </ActionButton>
  );

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select affiliate' }}
    />
  );

  const returnQtyReceived = () => (
    <CustomInputBase
      type="number"
      placeholder="0"
      value={(returned > 0) ? returned : (!returned && (quantityReturned === 0)) ? 0 : quantityReturned}
      disabled={disableField}
      min={1}
      size="medium"
      onClick={disableField ? '' : handleBatches}
      onChange={disableField ? '' : handleBatches}
      // onChange={(event) => changeInputQuantity(id, event.target.value)}
      endAdornment={(
        <InputAdornment position="end">
          <Grid item container direction="column">
            <CaretUpIcon
              style={{
                width: '1.1rem', height: '1.1rem', cursor: 'pointer',
                pointerEvents: disableField ? 'none' : ''
              }}
              onClick={disableField ? '' : handleBatches}
              // onClick={() => incrementProductQuantity(id)}
            />
            <CaretDownIcon
              style={{
                width: '1.1rem', height: '1.1rem', cursor: 'pointer',
                pointerEvents: disableField ? 'none' : ''
              }}
              onClick={disableField ? '' : handleBatches}
              // onClick={() => decrementProductQuantity(id)}
            />
          </Grid>
        </InputAdornment>
      )}
    />
  );

  const returnReasonField = () => (
    <TextFieldBox>
      <CTextField
        variant="outlined"
        placeholder="Reason"
        value={reason || reasonForReturn}
        size="large"
        name="reason"
        onChange={(e) => handleReasonChange(id, e.target.value, 'reason')}
        type="text"
        fullWidth
        disabled={disableField}
      />
    </TextFieldBox>
  );

  return (
    <>
      <MainTableRow>
        <BodyCell>{renderCheckbox()}</BodyCell>
        {renderBodyCells()}
        <BodyCell>
          {returnQtyReceived()}
        </BodyCell>
        <BodyCell>{returnReasonField()}</BodyCell>
        {!disableField && (
          <BodyCell>{returnAction()}</BodyCell>
        )}
      </MainTableRow>
      <BatchDialog
        open={batchDialog}
        close={handleBatchDialog}
        rowId={id}
        handleChange={handleChange}
        orderBatch={orderBatch}
        handleBatchUpdate={handleBatchUpdate}
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
  handleReasonChange: PropTypes.func.isRequired,
  disableField: PropTypes.bool,
  handleBatchUpdate: PropTypes.bool.isRequired,
  handleChange: PropTypes.bool.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  selected: [],
  disableField: false
};

export default ReturnRow;
