import {
  Paper, Typography, Grid, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MobileContainer = styled(Paper)`
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 100%;
  padding-bottom: 3rem;

`;

export const DateText = styled(Typography)`
  font-weight: 600;
  font-size: 2.5rem;
  padding: 2rem;
`;

export const HeaderText = styled(Grid)`
  font-weight: 600;
  font-size: 2.1rem;
`;

export const DetailText = styled(Grid)`
  text-align: right;
  font-size: 2rem;
  justify-content: flex-end;
`;

export const TextBox = styled(Grid)`
  padding: 0 2rem 2rem 2rem;
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;

export const StatusBox = styled(Box)`
`;

export const ColorGrid = styled(Box)`
  background: ${({ background }) => (background)};
  border-radius: 11px;
  color: ${({ color }) => (color)};
  font-size: 2rem;
  font-weight: 500;
  padding: 2px 0;
  width: 65%;
  text-align: center;
  margin-left: auto;
  @media(max-width: 991px) {
    padding: 5px 0;
    font-size: 1.7rem;
    width: 50%;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;
