import StockTransferTypes from './stockTransferTypes';
import { updateTransferBatches } from './stockTransferUtils';

const stockTransferReducer = (stockTransfer, action) => {
  switch (action.type) {
    case StockTransferTypes.UPDATE_TRANSFER:
      return {
        ...stockTransfer,
        ...action.payload
      };
    case StockTransferTypes.UPDATE_TRANSFER_BATCHES:
      return {
        ...stockTransfer,
        transferItems: updateTransferBatches(stockTransfer.transferItems, action.payload)
      };
    default:
      return stockTransfer;
  }
};

export default stockTransferReducer;
