import {
  TableCell, Grid, TableContainer, Paper, Typography
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const HeaderCell = styled(TableCell)`
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0px;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &:nth-child(1) {
    width: 8%;
  }
  &:nth-child(2) {
    width: 20%;
    padding-right: 0;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 14%;
  }
  &:nth-child(5) {
    width: 12%;
    padding-right: 0;
  }
  &:nth-child(6) {
    width: 13%;
  }
  &:nth-child(7) {
    width: 15%;
    padding-right: 0;
  }

  @media(min-width: 992px) {
    font-size: 0.59rem;
  }
`;
export const AffiliateBodyCell = styled(TableCell)`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  font-size: 1rem;

  &:nth-child(2), :nth-child(3), :nth-last-child(2) {
    padding: 0;
  }

  @media(min-width: 992px) {
    font-size: 0.6rem;
    padding: 10px 0;
  }
`;
export const FooterGrid = styled(Grid)`
  align-items: center;
  font-size: 0.6rem;
  margin-bottom: 15px;
`;
export const AffiliateTableContainerWrapper = styled(TableContainer)`
  box-shadow: 0px 3px 4px #42424240;
  border-radius: 7px;
  border: 1px solid #79797940;
  overflow-y: hidden;
`;
export const AffiliateRowImageContainer = styled('div')`
  width: 1.5rem;
  height: 1.5rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
`;
export const AffiliateLocation = styled(Grid)`
  margin-top: 5px;
  font-size: 0.9rem;
  color: #A3A3A3;

  @media(min-width: 992px) {
    font-size: 0.56rem;
  }
`;

export const SortCart = styled('div')`
  position: absolute;
  background: white;
  border: 0.1px solid #f0eaea;
  padding: 1%;
  right: 51%;
  margin-top: -0.2%;
  width: 219px;
  box-shadow: 1px 2px 3px #00000029;
  border-radius: 0px 0px 7px 7px;
`;

export const SortImage = styled('img')`
  width: 7%;
  margin-right: 2%;
`;

export const PaddingBottom = styled('div')`
  padding-bottom: 10%;
`;

export const StyledCheckBox = styled(CheckBox)`
  position: abosolute;
  left: 265px;
`;

export const GraphicsCard = styled(Paper)`
  box-shadow: 0px 3px 4px #42424240;
  border-radius: 7px;
  border: 1px solid #79797940;
`;

export const DashboardContainer = styled('div')`
  padding: 30px;
  max-height: 42rem;
  overflow-y: scroll;
`;

export const OrderSummaryPrimaryContent = styled('div')`
  color: #424242;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.4rem;

  &:first-child::before {
    content: " ";
    height: 8px;
    width: 8px;
    background: ${({ color }) => (color || 'red')};
    border-radius: 50%;
    position: relative;
    margin-right: 12px;
    padding: 0 8px;
  }

  @media(min-width: 992px) {
    font-size: 0.8rem;
  }
`;

export const OrderSummarySecondaryContent = styled('div')`
  color: #A3A3A3;
  font-size: 1rem;
  margin-bottom: 0.4rem;

  @media(min-width: 992px) {
    font-size: 0.6rem;
  }
`;

export const DashboardContainerTitle = styled(Typography)`
  color: #424242;
  font-size: 20px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const InfoContainerGrid = styled(Grid)`
  justify-content: space-between;
`;
export const NotificationBodyCell = styled(TableCell)`
  font-size: 1rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-transform: capitalize;
  padding: 10px 8px;
  padding-left: 0;

  @media(min-width: 992px) {
    font-size: 0.6rem;
    padding: 14px;
  }
`;

export const NotificationTypeContainer = styled('div')`
  width: 3rem;
  height: 1rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;
export const NotificationType = styled('span')`
  font-size: 0.8rem;
  padding: 3px 8px;
  margin-right: 15px;

  @media(min-width: 992px) {
    font-size: 0.5rem;
    margin-right: 0px;
  }
`;
export const NotificationStatus = styled('div')`
  border-radius: 50%;
  display: inline-block;
  width: 7px;
  height: 7px;
`;

export const NotificationsHeaderCell = styled(TableCell)`
  padding-left: 15px;
  font-size: 0.7rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &:nth-child(2), :nth-child(4), :nth-last-child(2), :nth-last-child(1) {
    padding: 0;
  }

  @media(min-width: 992px) {
    font-size: 0.59rem;
  }
`;

export const DatePicker = styled(Grid)`
  margin: 20px 50px;
  margin-bottom: 0px;

  @media(min-width: 992px) {
    margin: 0px;
  }
`;

export const DashboardInfoContainer = styled(Grid)`
  max-width: 100%;
  margin-bottom: 40px;

  @media(min-width: 992px) {
    max-width: 49%;
    margin-bottom: 0px;
  }
`;

export const NotificationContainer = styled(Grid)`
  max-width: 100%;

  @media(min-width: 992px) {
    max-width: 49%;
  }
`;

export const PerformanceChartContainer = styled(Grid)`
  max-width: 100%;

  @media(min-width: 992px) {
    max-width: 65%;
  }
`;

export const DashboardOrdersContainer = styled(Grid)`
  max-width: 100%;

  @media(min-width: 992px) {
    max-width: 33%;
  }
`;
