import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Hidden, Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import toast from 'react-hot-toast';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  CartGridContainer, PageTitleText,
  PageTitleSubText, GoBack,
  PageTitleContainer, CartGridContainerHeader, KeyboardArrowLeft
} from '../../../cart/cart.styles';
import CustomButton from '../../../customComponents/customButton';
import ReturnRow from './returnRow';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, GridButton, MenuButton, BackText,
  PaperContainer, TableComponent, Header, MainTableHead, MainTableRow, SubtotalGrid, SubtotalText, FooterGrid
} from './styles';
import { WH_SINGLE_ORDER_QUERY } from '../../../../queries/orders';
import {
  JUST_IN_TIME_MUTATION, UPDATE_ORDER_STATUS, APPROVE_ORDER
} from '../../../../mutations/orders';
import MainContent from '../../../customComponents/mainContent';
import { JSONParse, reformatAddress } from '../../../../utils/json';
import DeliveryInfoCard from '../../../shared/deliveryInfoCard/deliveryInfoCard';
import { OgaToken } from '../../../../graphql/token';
import OrderInfoCard from '../../../shared/orderInfoCard/orderInfoCard';
import { useStateValue } from '../../../../providers/stateProvider';
import TablePagination from '../../../shared/tablePagination';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import useSearchParamsState from '../../../shared/helpers/ulrSearchParams';

const headers = [
  'Product Items', 'Qty Ordered', 'Qty Received', 'Unit Cost', 'Price',
];

const AffiliateFormDetail = () => {
  const navigate = useNavigate();
  const { id, param } = useParams();
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const handleDownload = () => {
    window.open(
      `${OgaToken.SERVER_LINK}download_order_invoice/${id}`,
      '_blank'
    );
  };

  const [justInTimeOrder] = useMutation(JUST_IN_TIME_MUTATION);
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);
  const [approveOrder] = useMutation(APPROVE_ORDER);
  const [active, setActive] = useState(false);
  const serverType = process.env.SERVER;

  const handleJITOrdering = () => {
    justInTimeOrder({
      variables: { orderId: id }
    })
      .then(({ data }) => {
        const { message } = data?.justInTimeOrder || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const {
    loading, error, data, refetch
  } = useQuery(WH_SINGLE_ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { orderId: id, pageCount, pageNumber }
  });

  const handleApproveOrder = () => {
    updateOrderStatus({
      variables: {
        orderId: id,
        status: 'ORDER_RECEIVED',
        changes: [],
        idempotentKey: String(uuid())
      }
    })
      .then(() => {
        toast.success('Order successfully approved');
        setActive(true);
        refetch();
      }).catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleOrderApproval = () => {
    approveOrder({
      variables: {
        orderId: id
      }
    })
      .then(() => {
        toast.success('Order successfully approved');
        refetch();
      }).catch((err) => {
        toast.error(err?.message);
      });
  };

  if (error) return `Error! ${error.message}`;

  const {
    business, lastUpdated, meta, metaStatus, deliveryOutlet
  } = data?.singleOrder ?? {};

  const orderProducts = data?.orderProducts || {};
  const orderProductsTotalNumber = data?.orderProductsTotalNumber || 0;

  const businessContact = reformatAddress(deliveryOutlet?.contacts);
  const prods = orderProducts || [];

  const { xero_status: xeroStatus, status } = reformatAddress(meta);

  const returnHeaders = () => headers.map((header) => (
    <Header key={header}>{header}</Header>
  ));

  const handleGeneratePickList = () => {
    window.open(`${OgaToken.SERVER_LINK}download_pick_list/${id}`, '_blank');
  };

  let subtotal;
  if (data?.orderProducts) {
    subtotal = prods?.map((item) => {
      const { meta: oMeta, price } = item;
      const { status: oStatus } = JSONParse(oMeta);
      if (oStatus === 'DISPATCHED') return price;
      return 0;
    }).reduce((sum, price) => sum + price, 0);
  }
  return (
    <MainContent>
      <CartGridContainer>
        <GoBack container item alignItems="center">
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
          </MenuButton>
          <BackText variant="caption">back</BackText>
        </GoBack>
        <Grid container item>
          <CartGridContainerHeader item container xs={12} md={5}>
            <PageTitleContainer>
              <PageTitleText>Order Detail</PageTitleText>
              <Hidden mdDown>
                <PageTitleSubText>View details of an order</PageTitleSubText>
              </Hidden>
            </PageTitleContainer>
          </CartGridContainerHeader>
          <GridButton xs={12} md={7}>
            {serverType !== 'production' && (
              <CustomButton
                type="tertiary"
                header
                onClick={handleOrderApproval}
                style={{ width: '9rem', height: '2.9rem' }}
                disabled={xeroStatus === 'submitted'}
              >
                Xero Approve
              </CustomButton>
            )}
            {xeroStatus === 'submitted' && param !== 'own_orders' && param !== 'closed_orders'
              ? (
                <CustomButton
                  type="tertiary"
                  header
                  disabled={!(status === 'ORDER_SENT' || status === 'ORDER_APPROVED') || !userPermissions?.includes('inventory_approve_order')}
                  onClick={handleApproveOrder}
                  style={{ width: '9rem', height: '2.9rem' }}
                >
                  Approve Order
                </CustomButton>
              ) : ''}
            {
              (param === 'own_orders' || param === 'closed_orders') ? null : (
                <CustomButton
                  type="tertiary"
                  header
                  onClick={handleJITOrdering}
                  disabled={loading}
                  style={{ width: '9rem', height: '2.9rem' }}
                >
                  Add To Cart
                </CustomButton>
              )
            }
            <CustomButton
              type="tertiary"
              header
              onClick={handleGeneratePickList}
              disabled={loading || data?.singleOrder?.status === 'CLOSED'}
              style={{ width: '9rem', height: '2.9rem' }}
            >
              Pick List
            </CustomButton>
            <CustomButton
              type="tertiary"
              header
              onClick={handleDownload}
              disabled={loading || data?.singleOrder?.status !== 'CLOSED'}
              style={{ width: '12rem', height: '2.9rem' }}
            >
              Download Invoice
            </CustomButton>
          </GridButton>
        </Grid>
        <Grid container spacing={4}>
          <SupplierDetailCardWrapper item container lg={6}>
            <OrderInfoCard
              id={`ID${id}`}
              business={business}
              dateCreated={moment(lastUpdated).format('DD/MM/YYYY')}
            />
          </SupplierDetailCardWrapper>
          <SupplierDetailCardWrapper item container lg={6}>
            <SupplierDetailCard>
              <DeliveryInfoCard businessContact={businessContact} businessName={business?.name} />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>
        <PaperContainer elevation={2}>
          <TableComponent>
            <MainTableHead>
              <MainTableRow>
                {returnHeaders()}
              </MainTableRow>
            </MainTableHead>
            {loading ? <ProductsTableLoader /> : prods.map((prod, index) => <ReturnRow key={prod?.id} product={prod} index={index} orderStatus={metaStatus} />)}
          </TableComponent>
          <SubtotalGrid>
            <SubtotalText>
              <span style={{ marginRight: '1rem' }}>Subtotal: </span>
              {`₦${currencyFormatter(subtotal)}`}
            </SubtotalText>
          </SubtotalGrid>
          <FooterGrid item container>
            <TablePagination
              total={orderProductsTotalNumber}
              pageCount={+pageCount}
              setPageCount={setPageCount}
              pageNumber={+pageNumber}
              setPageNumber={setPageNumber}
            />
          </FooterGrid>
        </PaperContainer>
      </CartGridContainer>
    </MainContent>
  );
};

export default AffiliateFormDetail;
