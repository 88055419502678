import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import { JSONParse } from '../../../../utils/json';
import { RenderPopper } from '../../../../utils/utils';
import OutletUser from './outletUser';
import paginationShowing from '../../../../utils/pagination';
import RenderPagination from '../../../shared/pagination';

import {
  AllUsersWrapper, FilterBar, OutletUsersWrapper,
  IconsGridContainer, TabIcon, PopperContent,
  TabImage, CheckBoxWrapper, CheckBoxHeader,
  FooterGrid
} from './allUsers.styles';
import {
  SelectNameTypo, FieldCheckbox, CheckboxImage
} from './returnSelectField.styles';
import GoToTop from '../../../customComponents/scrollToTop';
import CTablePagination from '../../../customComponents/cTablePagination';
import CheckboxIcon from '../../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../../assets/images/supplier/Checkbox-resting.png';

import sortAsc from '../../../../assets/images/supplier/Sort Ascending Icon.png';
import sortDesc from '../../../../assets/images/supplier/Sort Descending Icon.png';
// import exportIcon from '../../../../assets/images/supplier/Export Icon.png';
import filterIcon from '../../../../assets/images/supplier/filterIcon.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const TEMP_OUTLETS = [
  { id: 0, value: 'Loading Outlets...' }
];

export default function AllUsers({
  data, searchText, handleChangePage,
  handleChangeRowsPerPage,
  currentPage, currentPageCount
}) {
  const [ascending, setAscending] = useState(false);
  const [totalNumber,] = useState(0);
  const [allOutlets,] = useState(TEMP_OUTLETS);
  const [filteredOutlets, setFilteredOutlets] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOutletDropdown = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleSort = () => {
    setAscending(!ascending);
  };
  const handleChange = (event) => {
    const { value, checked } = event.target;
    let oldSelectedOutlets = [...filteredOutlets];
    // if checked add it to teh filtered array
    if (checked) {
      oldSelectedOutlets.push(value);
    } else {
      oldSelectedOutlets = oldSelectedOutlets.filter((os) => os !== value);
    }
    setFilteredOutlets(oldSelectedOutlets);
    // if unchecked, remove it fom the filtered array
  };

  /**
   *  Initialise the state .
   */
  useEffect(() => {
    setAllUsers(data.allBusinessUsers);
    setFilteredUsers(data.allBusinessUsers);
  }, [data]);
  /**
   * watch for changes in the outlet filter
   */
  useEffect(() => {
    let tempFilteredUsers = [...allUsers];
    if (filteredOutlets.length > 0) {
      tempFilteredUsers = tempFilteredUsers.filter(({ outlet: { id } }) => (
        filteredOutlets.includes(id)
      ));
    }
    setFilteredUsers(tempFilteredUsers);
  }, [filteredOutlets, allUsers]);

  /**
   * watch for changes in the sort filter
   */
  useEffect(() => {
    const sortIndex = ascending ? 1 : -1;
    const tempFilter = allUsers.sort(({ user: { username: a } }, { user: { username: b } }) => (
      a > b ? sortIndex : -sortIndex
    ));
    setFilteredUsers(tempFilter);
  }, [ascending]);

  /**
   * watch for changes in the search text
   */
  useEffect(() => {
    let tempFilteredUsers = [...allUsers];
    if (searchText.length > 3) {
      tempFilteredUsers = tempFilteredUsers.filter(({ user }) => {
        const { last_name: lastName, first_name: firstName } = JSONParse(user.profile);
        const fullname = `${firstName || ''} ${lastName || ''}`;
        return fullname.toLowerCase().search(searchText.toLowerCase()) !== -1;
      });
    }
    setFilteredUsers(tempFilteredUsers);
  }, [searchText]);

  const sortImage = (ascending) ? sortDesc : sortAsc;
  const showing = paginationShowing(allUsers, totalNumber);
  return (
    <AllUsersWrapper>
      <FilterBar>
        <RenderPagination
          from={showing()}
          to={showing('end')}
          total={totalNumber}
          noun="Users"
        />
        <Hidden mdDown>
          <IconsGridContainer item container>
            <TabIcon onClick={handleSort}>
              <TabImage src={sortImage} alt="Search" />
            </TabIcon>
            <TabIcon>
              <TabImage
                src={filterIcon}
                onClick={handleOutletDropdown}
                alt="Search"
              />
            </TabIcon>
          </IconsGridContainer>
        </Hidden>
      </FilterBar>

      <OutletUsersWrapper>
        {
            filteredUsers.map((bizUser) => (
              <OutletUser
                bizUser={bizUser}
              />
            ))
          }
        {
            (filteredUsers.length === 0) && 'No Filtered Users'
          }
      </OutletUsersWrapper>
      <FooterGrid item container>

        <CTablePagination
          rowsPerPageOptions={[4, 8]}
          component="div"
          count={totalNumber}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
      {
          (filteredUsers.lenght > 5) && <GoToTop />
        }

      <RenderPopper
        open={open}
        anchorEl={anchorEl}
        onClickAway={() => setAnchorEl(null)}
          // popperPlacement="bottom-end"
        modifiers={{
          offset: {
            enabled: true,
            offset: '2vw, 2vh',
          }
        }}
      >
        <PopperContent>
          <CheckBoxHeader>
            Filter Outlet
          </CheckBoxHeader>
          {
              allOutlets.map((tempOutlet) => (
                <CheckBoxWrapper item container justifyContent="space-between" alignItems="center">
                  <SelectNameTypo>{tempOutlet.name}</SelectNameTypo>
                  <FieldCheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    value={tempOutlet.id}
                    onClick={handleChange}
                  />
                </CheckBoxWrapper>
              ))
            }
        </PopperContent>
      </RenderPopper>
    </AllUsersWrapper>
  );
}

AllUsers.propTypes = {
  data: PropTypes.instanceOf(Object),
  searchText: PropTypes.string,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  currentPageCount: PropTypes.number
};

AllUsers.defaultProps = {
  data: {},
  searchText: '',
  currentPage: 1,
  currentPageCount: 1
};
