import {
  Grid, Checkbox, TableContainer, Table, TableHead, TableCell, Box, Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const TitleHeader = styled(Typography)`
  padding: 1.8rem 1rem;
  font-size: 2rem;
  font-weight: 600;
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
  padding: 5px 20px;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
  /**text-transform: uppercase;**/
  padding: 15px 10px;
  color: #303030;
  font-weight: 700;
  font-size: 17px;
  word-wrap: nowrap;
  background: #F3F9FF;
  :first-child,{
    width: 2%;
  }

  @media(min-width: 992px) {
    &:nth-child(3) {
      width: 24%;
    }
    &:nth-child(2) {
      width: 12%;
    }
    &:nth-child(4), nth-child(6) {
      width: 16%;
    }
    &:nth-child(5) {
      width: 20%;
    }
    &:last-child {
      width: 10%;
    }
  }
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;
  padding: 1.5rem 0;

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const MobileContainer = styled(Box)`
  width: 100%;
`;

export const FilterBox = styled(Box)`
  margin-bottom: 1.5rem;
  text-align: right;
`;

export const FilterImg = styled('img')`
  height: 8rem;
  width: 8rem;
`;
