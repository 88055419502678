import React from 'react';
import {
  Button, DialogActions, DialogContent, DialogContentText,
  Slide, Box, Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../../assets/images/Checksuccesful.gif';
import { DialogContainer } from './successDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SuccessDialog = ({
  openConfirmation,
  handleClose,
  title,
  discreption
}) => (
  <DialogContainer
    open={openConfirmation}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="large"
        style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            <img src={success} alt="success" width={155} />
          </Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            { title || 'Download Changed Succcesfully!' }
          </Typography>
          <Typography variant="subtitle1" style={{ paddingTop: '1rem' }}>
            { discreption || 'Hi Pharm, Download has been successfully Changed' }
          </Typography>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <Button
          fullWidth
          onClick={handleClose}
          variant="contained"
          style={{
            background: '#235A91',
            color: '#FFF',
            padding: '1rem 2rem'
          }}
          size="large"
        >
          Ok
        </Button>
      </DialogActions>
    </DialogContent>
  </DialogContainer>
);

SuccessDialog.propTypes = {
  openConfirmation: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  discreption: PropTypes.string
};

SuccessDialog.defaultProps = {
  title: '',
  discreption: ''
};

export default SuccessDialog;
