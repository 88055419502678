import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import CardLoader from '../../shared/cardLoader';
import { numberFormater } from '../../shared/utils';
import {
  TypographyStyled,
  TypographyCountStyled,
  Title, ArrowImg
} from '../dashboardContainer.styles';
import { PassMark, FileIcon } from '../../../assets/svgs';
import arrowRight from '../../../assets/images/marketOrder/arrowRight.png';

const ProductActivityCards = ({ response, getDate }) => {
  const { loading, error, data } = response;
  const { from, to } = getDate;

  const activity = [
    {
      id: 0,
      title: 'Total Products',
      total: data?.supplyChainDashboard?.totalProductsInInventory || 0,
      link: `/inventory/products/all${from && to && `/${from}/${to}`}`,
      dataTest: 'totalProduct',
      img: <PassMark style={{ fontSize: '31px' }} />,
      className: 'total-products'
    },
    {
      id: 1,
      title: 'Enabled Products',
      total: data?.supplyChainDashboard?.totalEnabledProducts || 0,
      link: `/inventory/products/enabled${from && to && `/${from}/${to}`}`,
      dataTest: 'enabled',
      img: <FileIcon style={{ fontSize: '31px' }} />,
      className: 'enabled-products'
    },
    {
      id: 2,
      title: 'Disabled Products',
      total: data?.supplyChainDashboard?.totalDisabledProducts || 0,
      link: `/inventory/products/disabled${from && to && `/${from}/${to}`}`,
      dataTest: 'disabled',
      img: <PassMark style={{ fontSize: '31px' }} />,
      className: 'disabled-products'
    },
    {
      id: 3,
      title: 'Sold Out',
      total: data?.supplyChainDashboard?.totalSoldOutProducts || 0,
      link: `/inventory/products/sold_out${from && to && `/${from}/${to}`}`,
      dataTest: 'soldOut',
      img: <FileIcon style={{ fontSize: '31px' }} />,
      className: 'sold-out'
    }
  ];

  const navigate = useNavigate();
  const onRouteHanlder = (path) => {
    navigate(path);
  };

  if (loading || error) return <CardLoader cols={4} />;
  return (
    <>
      <div style={{ marginTop: '3rem' }} />
      <Title className="erp-uat-product-overview">Product Overview</Title>
      <Grid container spacing={4} style={{ marginTop: '.7rem' }}>
        {activity?.map((card) => (
          <Grid item xs={3} key={card?.id} data-testid={card?.dataTest} onClick={() => onRouteHanlder(card?.link)}>
            <Box
              p={5}
              bgcolor="background.paper"
              borderRadius={20}
              // className="text-center"
              style={{ cursor: 'pointer', padding: '25px 0px 25px 20px', borderRadius: '12px' }}
              className={`erp-uat-${card?.className}`}
            >
              <Grid container spacing={4}>
                <Grid item xs={2}>
                  {card.img}
                </Grid>
                <Grid item xs={10}>
                  <TypographyStyled>{card?.title}</TypographyStyled>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={8} style={{ padding: '32px 0 0 32px' }}>
                  <TypographyCountStyled>
                    {numberFormater(card?.total || 0)}
                  </TypographyCountStyled>
                </Grid>
                <Grid item xs={4}>
                  <ArrowImg src={arrowRight} alt="arrow right" />
                </Grid>
              </Grid>
              {/* <TypographyStyled>{card?.title}</TypographyStyled>
              <TypographyCountStyled>
                {numberFormater(card?.total || 0)}
              </TypographyCountStyled> */}
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ProductActivityCards.propTypes = {
  response: PropTypes.instanceOf(Object).isRequired,
  getDate: PropTypes.instanceOf(Object).isRequired
};

export default ProductActivityCards;
