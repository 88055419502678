import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_RETURN } from '../../../../../mutations/returnProduct';

export const useCreateReturn = () => {
  const [complete, setComplete] = useState(false);
  const [createReturn, { loading, error, data }] = useMutation(CREATE_RETURN);
  function createReturnMethod(reqBody) {
    createReturn({
      variables: { ...reqBody }
    });
    setComplete(true);
  }
  if (!loading && data && complete) {
    setComplete(false);
  }
  if (!loading && error && complete) {
    setComplete(false);
  }
  return {
    createReturnMethod, loading, error, data, complete
  };
};
