import {
  Grid, Button, Box, Typography, IconButton, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExportIcon } from '../../../../assets/svgs';

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem 0px;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0rem 0px 1.8rem 0px;
    justify-content: space-between;
  }
`;

export const ButtonsGrid = styled(Grid)`
  padding: 0 0rem 1.8rem 0px;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0 0rem 0rem 0px;
  }
`;

export const DispatchButton = styled(Button)`
  font-size: 3rem;
  text-align: center;
  border-radius: 20px;
  width: 100%;
  padding: 12px 0px;
  border: 1px solid #235A91;
  color: #235A91;
  @media(max-width: 991px) {
    font-size: 2.5rem;
    font-weight: 600;
  }
`;

export const CreateButton = styled(Button)`
  font-size: 3rem;
  text-align: center;
  border-radius: 20px;
  width: 100%;
  background-color: #235A91;
  color: #fff;
  padding: 12px 0px;
  @media(max-width: 991px) {
    font-size: 2.5rem;
    font-weight: 600;
    & .MuiButton-iconSizeMedium > *:first-child {
      font-size: 50px;
  }

  }
`;

export const MobileButtonGrid = styled(Grid)`
  margin-bottom: 2rem;
`;

export const MobileGrid = styled(Grid)`
`;

export const FilterBox = styled(Box)`
  margin-bottom: 1.5rem;
  text-align: right;
`;

export const FilterImg = styled('img')`
  height: 8rem;
  width: 8rem;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;

  @media(max-width: 991px) {
    justify-content: center;
    display: grid;
  }
`;

export const HeaderWraper = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
`;

export const Header = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;

  @media (max-width: 991px) {
    font-weight: 700;
  }
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding-right: 30px;
  .MuiIconButton-root {
    padding: 0px;
  }
  @media(max-width: 991px) {
    .MuiSvgIcon-fontSizeLarge {
      font-size: 4rem;
  }
  }
`;

export const ExportIconStyled = styled(ExportIcon)`
  font-size: 1.2rem;
  color: '#606060';
  margin-right: 5px;
`;

export const ExportText = styled('span')`
  font-size: 13px;
  font-weight: 400;
  color: #235A91;
  @media(max-width: 991px) {
     font-size: 2.5rem;
  }
`;

export const MenuItems = styled(MenuItem)`
  &.Mui-selected, .Mui-selected:hover {
    background-color: #F3F9FF;
  }
  &:hover {
    background-color: #F3F9FF;
  }
`;
