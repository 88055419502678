import React, { useState } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import TabButton from './tabButton';
import {
  GridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid,
  PaperWrapper, Back, ProductName
} from './orderLogsContainer.styles';
import MainContent from '../../../customComponents/mainContent';
import OrderLogs from './orderLog';

const OrderLogsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const param = location?.state;
  const [tabVal, setTabVal] = useState('orders');
  const tabItems = [
    {
      label: 'Order Log', value: 'orders', width: '10%'
    },
    {
      label: 'Returns Log', value: 'return', width: '10%'
    },
    {
      label: '', value: '', width: '80%', marginTop: '1.3rem'
    },
  ];
  const brandName = param?.brandName;

  const handleTabChange = (val) => {
    if (!val) return;
    setTabVal(val);
  };

  return (
    <MainContent>
      <GridContainer container>
        <Back onClick={() => navigate(-1)}>
          <ArrowBackIos fontSize="small" />
          back
        </Back>
        <TitleGrid container item>
          <TitleTextGridContainer item>
            <PrimaryTitle container variant="h5">Order Log</PrimaryTitle>
            <ProductName container>{brandName}</ProductName>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <TabButton
            tabVal={tabVal}
            handleTabChange={handleTabChange}
            tab={tabItems}
          />
          <OrderLogs tabVal={tabVal} />
        </PaperWrapper>
      </GridContainer>

    </MainContent>
  );
};

export default OrderLogsContainer;
