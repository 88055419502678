import React from 'react';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';

export default styled(({ ...otherProps }) => (
  <TablePagination {...otherProps} />
))`
  text-transform: capitalize;
  
  @media (max-width: 991px) {
    & .MuiTablePagination-caption,
      .MuiTablePagination-input {
      font-size: 1.8rem;
    }
    & .MuiTablePagination-actions {
      & .MuiIconButton-root {
        & .MuiIconButton-label {
          & .MuiSvgIcon-root {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
    }
  }
`;
