import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { NewDownloadIcon } from '../../../assets/svgs';
import {
  ActionButton,
  BodyCell,
  ColorGrid,
  MainTableRow,
  TextWrapper
} from './returnRow.styles';

const ReturnRow = ({ row }) => {
  const {
    id,
    status,
    dateCreated,
    downloadType,
    businessUser,
    downloadUrl
  } = row;
  const dategenerated = moment(dateCreated).format('DD MMM YYYY h:mm a');
  const getStatus = () => {
    if (status === 'COMPLETED') return <ColorGrid item container status="completed">Completed</ColorGrid>;
    if (status === 'PENDING') return <ColorGrid item container status="pending">In Progress</ColorGrid>;
    if (status === 'FAILED') return <ColorGrid item container status="failed">FAILED</ColorGrid>;
  };
  const { fullName } = businessUser;

  const cells = [id, dategenerated, downloadType, fullName];

  const returnCell = (value) => value || '-';

  const handleDownload = () => {
    window.open(
      `${downloadUrl}`,
      '_blank'
    );
  };

  const renderBodyCells = () => {
    return cells.map((cell) => (
      <BodyCell>{returnCell(cell)}</BodyCell>
    ));
  };

  return (
    <MainTableRow item container key={id}>
      {renderBodyCells()}
      <BodyCell>{getStatus()}</BodyCell>
      <BodyCell>
        <ActionButton
          data-testid="create-product"
          disabled={status === 'PENDING' || status === 'FAILED'}
          onClick={handleDownload}
        >
          <NewDownloadIcon style={{ fontSize: '1rem', }} />
          <TextWrapper>
            Download
          </TextWrapper>
        </ActionButton>
      </BodyCell>
      <BodyCell />
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
