import {
  Grid, Typography, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const TitleGridContainer = styled(Grid)`
  margin: 1rem 0 1.5rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 170px;
  margin-right: 1rem;
  height: 45px;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  font-weight: 700;
  color: #303030 !important;
  font-size: 12px;
`;
