import {
  TableRow, TableCell, Typography, DialogContent, Grid, Button,
  Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }

  &:nth-of-type(even) {
    background-color: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.3rem;
  padding: 15px;
  line-height: 26px;
  color: #606060;

  @media(min-width: 992px) {
    font-size: 1rem;
    padding: 15px;

    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(5) {
      width: 10%;
    }
  }
`;

export const EditText = styled(Typography)`
`;

export const ContentDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 15px;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 45rem !important;
  min-height: 15rem;
`;

export const CloseStyled = styled('div')`
  text-align: right;
`;

export const RoleHandle = styled(Grid)`
  cursor: pointer;
  margin-bottom: 10px;
`;

export const RoleNameTypo = styled(Typography)`
  font-size: 1.3rem;
`;

export const SaveButton = styled(Button)`
  font-size: 1rem;
  text-align: center;
  border-radius: 10px;
  background-color: #235A91;
  color: #fff;
  height: 3.5rem;
`;

export const ButtonGrid = styled(Grid)`
  margin-top: 1rem;
`;

export const CancelButton = styled(Button)`
  font-size: 1rem;
  text-align: center;
  border-radius: 10px;
  color: #235A91;
  height: 3.5rem;
`;

export const RoleWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0px 16px 16px;
`;
