import {
  Grid, Paper, TableCell, TableBody, Typography, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const MainTableHead = styled(Grid)`
  width: 100%;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  color: #303030;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 50%;
      padding-left: 20px;
    }
    &:nth-child(2) {
      width: 24%;
    }
    &:nth-child(3) {
      width: 24%;
    }

    &:nth-child(4) {
      width: 2%;
    }
  }
`;

export const TitleGridContainer = styled(Grid)`
  margin: 2rem 1rem 2rem 0;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: #24292E;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const CreateRole = styled(Button)`
  width: 170px;
  height: 45px;
  background: #235A91;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;
export const CreateUser = styled(Button)`
  width: 170px;
  height: 45px;
  border: 1px solid #235A91;
  border-radius: 5px;
  color: #235A91;
  font-weight: 700;
  font-size: 12px;
  margin: 0px 5px;

  &:hover {
    color: #000000;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const ViewUsers = styled(Button)`
  width: 170px;
  height: 45px;
  border: 1px solid #235A91;
  border-radius: 5px;
  color: #235A91;
  font-weight: 700;
  font-size: 12px;
  margin: 0px 5px;

  &:hover {
    color: #235A91;
  }

  &.MuiButton-root.Mui-disabled {
    cursor: not-allowed !important;
  }
`;

export const TextWrapper = styled(Typography)`
  font-weight: 550;
`;
