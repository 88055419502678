import React, { useState } from 'react';
import {
  Paper, Table, TableRow, TableHead, Box, Grid
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  TableContainerWrapper, ArrowImg, TableBox, TableContent,
  HeaderGrid, DivFullWidth, HeaderCell,
  BackButton,
  TitleTextGridContainer,
  PrimaryTitle,
  TitleGridContainer,
} from './categoryContainer.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../shared/tablePagination';
import MainContent from '../../customComponents/mainContent';
import backImg from '../../../assets/images/marketOrder/Arrow.png';
import { MainTableBody } from '../customerCategories/customerCategories.styles';
import AffiliateFilter from './affiliateFilter';
import { AFFILIATE_CATEGORY_QUERY } from '../../../queries/affiliates';

const initialState = {
  phoneNumber: '',
  status: '',
  affiliateName: '',
  setStatus: '',
  dateFrom: '',
  dateTo: '',
  filters: {},
  email: ''
};

export default function Category({ param }) {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const [state, setState] = useState(initialState);
  const navigate = useNavigate();
  const headers = [
    'Affiliate Name', 'Email Address', 'Phone Number', 'Status'
  ];
  const {
    phoneNumber, status,
    affiliateName, dateFrom, dateTo, email, setStatus, filters
  } = state;

  const { categoryName, id } = param || {};

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const handleSetFilter = () => {
    setState({
      ...state,
      setStatus: status,
      filters: {
        phoneNumber, name: affiliateName, dateFrom, dateTo,
        email
      }
    });
  };

  const variables = {
    pageCount,
    pageNumber,
    category: id,
    status: setStatus,
    ...filters
  };

  const {
    loading, error, data,
  } = useQuery(AFFILIATE_CATEGORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    allBusinesses = [], businessTotalNumber = 0,
  } = data || {};

  return (
    <MainContent>
      <div>
        <BackButton
          variant="outlined"
          startIcon={<ArrowImg src={backImg} alt="back" />}
          onClick={() => navigate(-1)}
          container
          xs={12}
        >
          back
        </BackButton>
        <TitleGridContainer container item xs={12} justifyContent="space-between">
          <TitleTextGridContainer item md={6}>
            <PrimaryTitle variant="h5">
              { categoryName }
            </PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGridContainer>
        <Box style={{ padding: '30px' }}>
          <TableContainerWrapper elevation={0}>
            <HeaderGrid>
              <Grid item xs={12}>
                <AffiliateFilter
                  state={state}
                  handleFilterChange={handleFilterChange}
                  handleSetFilter={handleSetFilter}
                />
              </Grid>
            </HeaderGrid>

            {loading ? <ProductsTableLoader /> : (
              <TableBox>
                <TableContent component={Paper}>
                  <Table aria-label="users table">
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <HeaderCell key={header} className={`mpAdmin-uat-customerCategory-page-${header}`}>{header}</HeaderCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <MainTableBody item container>
                      {allBusinesses?.map((business) => (
                        <ReturnRow
                          key={business?.id}
                          row={business}
                        />
                      ))}
                    </MainTableBody>
                  </Table>
                </TableContent>
              </TableBox>
            )}
            <DivFullWidth>
              <TablePagination
                total={businessTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </DivFullWidth>
          </TableContainerWrapper>
        </Box>
      </div>
    </MainContent>
  );
}
