import { gql } from '@apollo/client';

export const BACK_ORDER_QUERY = gql`
  query backOrders($search: String, $pageCount: Int, $pageNumber: Int, $dateFrom: DateTime, $dateTo: DateTime) {
    backOrders(search: $search, pageCount: $pageCount, pageNumber: $pageNumber, dateFrom: $dateFrom, dateTo: $dateTo ) {
      id
      order {
        id
        business {
          name
        }
      }
      product {
        id
        externalId
        brandName
      }
      quantityOrdered
      sellingPrice
      costPrice
      hybrid
    }
    backOrdersTotalNumber
  }
`;
