import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { CustomCheckbox, StatusColor, StatusText } from './returnRow.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import currencyFormatter from '../../../utils/currencyFormatter';
import { Dots } from '../../../assets/svgs';
import ActionPopper from './actionPopper';
import { CANCEL_AND_REPLACE } from '../../../mutations/orders';

const ReturnRow = ({
  row, selected, handleSelect, refetch
}) => {
  const navigate = useNavigate();
  const {
    id, dateCreated, orderproductSet, supplier, order, status
  } = row;

  const [action, setAction] = useState(null);
  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');

  const getColorGrid = (bkg, name) => (
    <Box style={{ display: 'flex' }}>
      <StatusColor bkg={bkg} />
      <StatusText>{name}</StatusText>
    </Box>
  );

  const getStatus = () => {
    // closed-order/
    if (status === 'MANUAL_SUPPLIER_ORDER_RECONCILED') return getColorGrid('#FF0F0F', 'Closed Order');
    if (order?.metaStatus === 'ORDER_IN_DRAFT') return getColorGrid('#F29053', 'Draft');
    if (order?.metaStatus === 'ORDER_CANCELED') return getColorGrid('#EE4223', 'Cancelled');
    return getColorGrid('#00B588', 'Open orders');
  };

  const orderVolume = orderproductSet.reduce((previous, current) => previous + current.quantity, 0);
  const orderAmount = orderproductSet.reduce((previous, current) => previous + (current.quantity * current.unitCost), 0);

  const disableRoute = order?.metaStatus === 'ORDER_CANCELED';
  const handleRoute = () => {
    if (status === 'MANUAL_SUPPLIER_ORDER_RECONCILED' && !disableRoute) return navigate(`/closed-order/${id}`);
    if (order?.metaStatus === 'ORDER_IN_DRAFT' && !disableRoute) return navigate(`/update-purchase-order/${order?.id}`);
    return !disableRoute && navigate(`/receive-manual-order/${id}`);
  };
  const isSelected = () => selected.map((item) => item).indexOf(id) !== -1;
  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      disabled={order?.metaStatus !== 'ORDER_SENT'}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, id);
      }}
      inputProps={{ 'aria-label': 'select product', 'data-testid': 'test-checkbox' }}
    />
  );

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const [mutateCAR, { loading }] = useMutation(CANCEL_AND_REPLACE);

  const cancelAndReplaceHandler = () => {
    mutateCAR({
      variables: { orderId: order?.id }
    }).then(({ data }) => {
      const { cancelAndReplacePo } = data;
      toast.success(cancelAndReplacePo.message);
      refetch();
    }).catch((err) => toast.error(err.message));
  };

  const handleAction = (event, type, _) => {
    if (type === 'cancel&replace') {
      cancelAndReplaceHandler();
    }
    handleOptions(event);
  };

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '80px' }}>{renderCheckbox()}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{renderDate}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{supplier?.name}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '200px' }} onClick={handleRoute}>{`#${id}`}</TableColumnRowText>
      <TableColumnRowText style={{ cursor: 'pointer', width: '200px' }} onClick={handleRoute}>{`#${order?.id}`}</TableColumnRowText>
      <TableColumnRowText style={{ width: '220px' }}>{orderVolume}</TableColumnRowText>
      <TableColumnRowText style={{ width: '220px' }}>{currencyFormatter(orderAmount)}</TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>{getStatus()}</TableColumnRowText>
      <TableColumnRowText style={{ width: '150px' }}>
        {
          loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
        }
        <ActionPopper
          action={action}
          handleAction={handleAction}
          row={row}
          handleClose={handleCloseModal}
        />
      </TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  selected: []
};

export default ReturnRow;
