import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Grid, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { JSONParse } from '../../utils/json';

import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, SelectNameTypo
} from './dataAnalysis.styles';
import { useStateValue } from '../../providers/stateProvider';

const ParamsDialog = ({
  open, close, id, item
}) => {
  const navigate = useNavigate();
  const [param, setParam] = useState({});
  const [paramData, setParamData] = useState([]);
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const handleView = (data) => {
    const jsonObject = JSON.stringify(param);
    navigate({
      pathname: `/data-analysis/${data}`,
      state: jsonObject
    });
    close(false);
  };
  useEffect(() => {
    if (item) {
      const { params } = item;
      const paramObject = params.length > 0 ? JSONParse(params) : params;
      setParamData(paramObject);
    }
  }, [item]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setParam({ ...param, [name]: value });
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogTitle>{item.name}</DialogTitle>
      <DialogContent>
        <Grid item container spacing={2}>
          {paramData.length ? paramData?.map((paramValue, index) => (
            <Grid key={index} item xs={6} tyle={{ minHeight: '30px' }}>
              {((paramValue.type === 'select')
                ? (
                  <FormControl fullWidth>
                    <InputLabel id="select-label">{paramValue.name}</InputLabel>
                    <Select
                      labelId="select-filled-label"
                      id="select-filled"
                      renderValue={(val) => val}
                      name={paramValue.name}
                      onChange={handleChange}
                      variant="filled"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {paramValue.values.map(
                        (option) => (
                            <MenuItem key={option} value={option}>
                              <Grid item container justifyContent="space-between" alignItems="center">
                                <SelectNameTypo>{option}</SelectNameTypo>
                              </Grid>
                            </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    variant="filled"
                    label={paramValue.name}
                    name={paramValue.name}
                    type={paramValue.type}
                    fullWidth
                    onChange={handleChange}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                      name: paramValue.name,
                    }}
                  />
                )
                  )}
            </Grid>
          )) : ''}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <DialogOkButton
          onClick={() => handleView(id)}
          disabled={!userPermissions?.includes('data_analysis_view_data_analysis')}
        >
          <DialogActionButtonText>
            View
          </DialogActionButtonText>
        </DialogOkButton>
        <DialogCancelButton onClick={() => close(false)}>
          <DialogActionButtonText>
            Cancel
          </DialogActionButtonText>
        </DialogCancelButton>
      </DialogActions>
    </Dialog>
  );
};

ParamsDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  id: PropTypes.number,
  item: PropTypes.instanceOf(Object)
};

ParamsDialog.defaultProps = {
  open: false,
  id: 0,
  item: {},
};

export default ParamsDialog;
