import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import DateRangePicker from './dateRange';
import { GroupButton } from './dateSelectorButtons.styles';

const DateSelectorButtons = ({
  active, handleDateButtons
}) => {
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  return (
    <>
      <GroupButton
        size="medium"
        variant={active === 'day' ? 'contained' : 'outlined'}
        onClick={() => handleDateButtons('day')}
        active={active === 'day'}
        type="left"
      >
        <Typography variant="caption">Day</Typography>
      </GroupButton>
      <GroupButton
        size="medium"
        variant={active === 'week' ? 'contained' : 'outlined'}
        onClick={() => handleDateButtons('week')}
        active={active === 'week'}
        type="center"
      >
        <Typography variant="caption">Week</Typography>
      </GroupButton>
      <GroupButton
        size="medium"
        variant={active === 'month' ? 'contained' : 'outlined'}
        onClick={() => handleDateButtons('month')}
        active={active === 'month'}
        type="center"
      >
        <Typography variant="caption">Month</Typography>
      </GroupButton>
      <GroupButton
        size="medium"
        variant={active === 'custom' ? 'contained' : 'outlined'}
        onClick={() => {
          setTimePickerOpen(true);
        }}
        active={active === 'custom'}
        type="center"
      >
        <Typography variant="caption">Custom</Typography>
      </GroupButton>
      <DateRangePicker
        open={timePickerOpen}
        setOpen={setTimePickerOpen}
      />
    </>
  );
};
DateSelectorButtons.propTypes = {
  active: PropTypes.string,
  handleDateButtons: PropTypes.func.isRequired
};

DateSelectorButtons.defaultProps = {
  active: '',
};

export default DateSelectorButtons;
