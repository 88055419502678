import React from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from '../shared/currencyFormatter';
import {
  MobileContainer, DetailText, HeaderText, TextBox, SerialText,
  ContentBox
} from './mobileReturnRow.styles';

export default function MobileReturnRow({
  row, index, pageCount, pageNumber
}) {
  const {
    name, supplierProductsCount, supplierProductsValue
  } = row;

  const value = currencyFormatter(supplierProductsValue);
  const serialNumber = pageNumber === 1 ? index + 1 : (pageNumber - 1) * pageCount + index + 1;

  return (
    <>
      <MobileContainer>
        <SerialText>
          {serialNumber <= 9 ? `0${serialNumber}` : serialNumber}
        </SerialText>
        <hr />
        <ContentBox>
          <TextBox container>
            <HeaderText xs={6}>Supplier</HeaderText>
            <DetailText xs={6}>
              {name}
            </DetailText>
          </TextBox>
          <TextBox container>
            <HeaderText xs={7}>Total products per supplier</HeaderText>
            <DetailText xs={5}>
              {supplierProductsCount}
            </DetailText>
          </TextBox>
          <TextBox container>
            <HeaderText xs={6}>Total products value</HeaderText>
            <DetailText xs={6}>
              {`₦ ${value}`}
            </DetailText>
          </TextBox>
        </ContentBox>
      </MobileContainer>
    </>
  );
}

MobileReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
  pageCount: PropTypes.instanceOf(Number).isRequired,
  pageNumber: PropTypes.instanceOf(Number).isRequired,
};

MobileReturnRow.defaultProps = {
  row: {},
  index: 0,
};
