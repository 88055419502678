import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BellNotifyIcon } from '../../assets/svgs';
import { CHANGE_NOTIFICATION_STATUS } from '../../mutations/notifications';
import {
  CListItem, CListItemAvatar, CAvatar, CListItemSecondaryAction, TimingText,
  RedBadge, SmallText, HeaderContainer, NotificationImg, NotifyTextBody, NotifyTextContainer, NotifyTextHeader
} from './notificationListItem.styles';
import { JSONParse } from '../../utils/json';

const NotificationListItem = ({ item }) => {
  const navigate = useNavigate();

  const {
    id, title, productName, dateCreated, status, deletedBy, productId, meta, message
  } = item;

  const { fullName } = deletedBy ?? {};
  const { order_id: orderId } = JSONParse(meta) || {};

  const [updateNotification] = useMutation(CHANGE_NOTIFICATION_STATUS);
  const changeNotificationHandler = (notificationId, _status) => {
    updateNotification({
      variables: { notificationId, status: _status }
    });
  };

  const handleCLick = () => {
    if (status === 'NOT_READ') changeNotificationHandler(id, 'read');
    if (orderId) {
      navigate(`/orders-admin/dispatch/${orderId}`);
    } else {
      navigate(`/new-order/All Categories/0/${productId}`);
    }
  };
  const notificationTitle = (type) => {
    switch (type) {
      case 'new_product_alert':
        return 'New Product Alert';
      case 'order_approval':
        return 'Order Approved';
      case 'order_sorted':
        return 'Order Sorted';
      case 'order_dispatched':
        return 'Order Dispatched';
      case 'order_ready_for_dispatched':
        return 'Order Ready For Dispatch';
      case 'order_canceled':
        return 'Order Canceled';
      case 'order_returned':
        return 'Order Returned';
      default:
        return 'Product Back in Stock';
    }
  };

  const formatMessages = () => {
    const perserMeta = meta?.replace(/'/g, '"').replace(/(\w)\"(\w)/g, '$1\\"$2');
    const { insufficient_products: errorMessages, insufficient_products_batches: batchError } = JSONParse(perserMeta) || {};
    let formattedMessages = message;
    if (errorMessages) {
      const errorMessagesFormatted = errorMessages.map((error) => {
        const {
          product_name: pName, quantity_available: availableQty, quantity_requested: requestedQty
        } = error;
        let formattedMessage = `${pName}: `;
        if (availableQty !== undefined && requestedQty !== undefined) {
          formattedMessage += ` available QTY: ${availableQty}, requested QTY: ${requestedQty}`;
        }
        return formattedMessage;
      }).join(', ');

      formattedMessages += `, ${errorMessagesFormatted}`;
    }

    if (batchError) {
      const errorMessagesFormatted = batchError.map((error) => {
        const {
          product_name: pName, message: itemMessage
        } = error;
        let formattedMessage = `${pName}: `;
        if (itemMessage) {
          formattedMessage += ` ${itemMessage}`;
        }
        return formattedMessage;
      }).join(', ');

      formattedMessages += `, ${errorMessagesFormatted}`;
    }

    return formattedMessages;
  };

  return (
    <CListItem disableGutters button onClick={handleCLick}>
      <CListItemAvatar>
        {status === 'NOT_READ' && <RedBadge />}
        <CAvatar>
          <BellNotifyIcon />
        </CAvatar>
      </CListItemAvatar>
      <NotifyTextContainer>
        <HeaderContainer>
          <NotifyTextHeader>{notificationTitle(title)}</NotifyTextHeader>
          <NotificationImg />
        </HeaderContainer>
        <NotifyTextBody>{title === 'order_ready_for_dispatched' ? formatMessages(item) : productName || message}</NotifyTextBody>
      </NotifyTextContainer>
      <CListItemSecondaryAction>
        <TimingText>{moment.utc(dateCreated).local().startOf('seconds').fromNow()}</TimingText>
        {!!fullName && <SmallText>{`Archived By ${fullName}`}</SmallText>}
      </CListItemSecondaryAction>
    </CListItem>

  );
};

NotificationListItem.propTypes = {
  item: PropTypes.shape(Object),

};

NotificationListItem.defaultProps = {
  item: {},
};

export default NotificationListItem;
