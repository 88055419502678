import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchPaper = styled(Paper)`
  background: #ffffff;
  width: 43.5rem;
  padding: 1rem 2rem;
  max-height: 26rem;
  overflow-y: scroll;
  border-radius: 12px;

   @media(max-width: 991px) {
    border-radius: 40px;
    width: 48rem;
  }
`;

export const ProductItem = styled(Grid)`
  align-items: center;
  cursor: pointer;
  padding: 1.3rem 0;
  border-bottom: 1px solid #e4e4e4;

  &:hover {
    background: #F3F9FF
  }
`;

export const PackSizeGrid = styled(Grid)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #606060;
  padding-top: .5rem;
`;

export const ProdName = styled(Grid)`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #303030;
`;

export const PriceNameTag = styled(Grid)`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #303030;
`;


export const ValueText = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 164.2%;
  color: #303030;
`;

export const ImgDiv = styled('div')`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ prodImg }) => `url(${prodImg})`};
`;
