import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'react-mui-dropzone';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { UploadCloud } from '../../assets/svgs';
import {
  theme, TitleText, SubTitleText, DropZoneContainer, BrowseFileButton
} from './logoDropzone.styles';

const DropzoneContent = ({ titleInfo }) => (
  <DropZoneContainer container item direction="column">
    <UploadCloud style={{ fontSize: '2.5rem', fill: 'none' }} />
    <TitleText>
      {titleInfo}
    </TitleText>
    <SubTitleText>
      .jpeg, .png file size no more than 2MB
    </SubTitleText>
    <BrowseFileButton>
      SELECT FILE
    </BrowseFileButton>
  </DropZoneContainer>
);
DropzoneContent.propTypes = {
  titleInfo: PropTypes.string
};
DropzoneContent.defaultProps = {
  titleInfo: 'Drag and drop your logo here'
};

const DropZone = ({
  handleFile, titleInfo
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <DropzoneArea
        dropzoneClass="erp-uat-file-dropzone"
        titleInfo={titleInfo}
        onChange={handleFile}
        acceptedFiles={['image/*']}
        dropzoneText={<DropzoneContent titleInfo={titleInfo} />}
        fileslimit={1}
      />
    </ThemeProvider>
  </StyledEngineProvider>
);

DropZone.propTypes = {
  handleFile: PropTypes.func.isRequired,
  titleInfo: PropTypes.string
};
DropZone.defaultProps = {
  titleInfo: 'Select a file or drag and drop here'
};

export default DropZone;
