import { styled } from '@mui/material/styles';
import {
  Grid, Typography, TableCell, Button
} from '@mui/material';

export const SearchWrapper = styled('div')`
  background: white;
  position: sticky;
  top: 0px;
  padding-top: 20px;
  z-index: 100;

`;
export const AffiliateWrapper = styled(Grid)`
  margin-top: 20px;
`;

export const OneAffiliate = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

export const StyledText = styled(Typography)`
  font-size: 17px;
  font-weight: 500;
`;

export const DropdownWrapper = styled(Grid)`
  background: white;
  position: absolute;
  top: 35px;
  right: -20px;
  border-radius: 10px;
  padding: 20px;
  padding-top: 0;
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
  z-index: 1;
  box-shadow: 0 4px 20px rgb(22 35 89 / 8%);
  width: 350px;
`;

export const SearchInput = styled('input')`
  width: 100%;
  background: rgba(204, 204, 204, 0.4);
  border: 0;
  outline: none;
  padding: 10px 20px;
  border-radius: 7px;
`;

export const HeaderWrapper = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin-bottom: 10px;
`;

export const BorderWrapper = styled(Grid)`
  height:10px;
  background: #CCCCCC;
  width: 100%;
`;

export const FilterIcon = styled('img')`
  width: 25px;
  cursor: pointer;
`;

export const FilterIconGrid = styled(Grid)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
`;

export const SubContentHandler = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 15px;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.5rem;
  padding-left: 0px;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &:not(first-child) {
    cursor: pointer;
  }

  &:nth-child(1) {
    width: 5%;
    text-align: center;
  }
  &:nth-child(2) {
    width: 35%;
  }
  &:nth-child(3) {
    width: 7%;
  }
  &:nth-child(4) {
    width: 8%;
  }
  &:nth-child(5) {
    width: 15%;
  }
  &:nth-child(6) {
    width: 10%;
  }
  &:nth-child(7) {
    width: 10%;
  }
  &:nth-child(8) {
    width: 10%;
  }
`;
export const RowBodyCell = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 23px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;
export const RowMainTableRow = styled('div')`
  display: flex;
  margin: 0;
`;

export const OrderItemsGrid = styled(Grid)`
  margin-left: 30px;
`;

export const OrderCountText = styled(Typography)`
  font-size: 25px;
  color: #4D4F5C;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 30px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const TopButton = styled(Button)`
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  background-color: ${({ bkColor }) => (bkColor)};
  border-radius: 7px;
  margin-left: 1.5rem;
  padding: 6px 10px;

  &:hover {
    background-color: ${({ bkColor }) => (bkColor)};
  }

  @media(min-width: 768px) {
    font-size: 25px;
    margin-top: .5rem;
  }

  @media(min-width: 992px) {
    font-size: 15px;
    margin-top: .5rem;
  }
`;

const getColor = (stat) => {
  switch (stat) {
    case 'LOGGED':
      return 'orange';
    case 'FIXING':
      return '#ebe31d';
    case 'REVIEW':
      return 'blue';
    case 'RESOLVED':
      return 'green';
    default:
      return '#424242';
  }
};

export const StatusText = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ stat }) => (getColor(stat))};
  cursor: { dev }) => (dev ? 'pointer' : 'not-allowed')};
`;
export const CommentText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
`;
