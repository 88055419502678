import { gql } from '@apollo/client';

// outlets(businessId: $businessId) {
//   id
//   name
// }

export const OUTLET_USER_QUERY = gql`
  query allBusinessUsers{
    allBusinessUsers {
      id
      permissions
      meta
      business {
        id
        name
        contacts
      }
      role {
        id
        name
        permissions
      }
      user {
        id
        username
        profile
        contacts
        dateCreated
      }
    }
  }
`;
// To be removed
export const ALL_OUTLETS_QUERY = gql`
  query allOutlets {
    allOutlets {
      id
      business {
        id
      }
      name
      contacts
      profile
      dateCreated
    }
  } 
`;
// To be removed
export const NP_ALL_OUTLET_USERS_QUERY = gql`
  query npAllOutletUsers {
    npAllOutletUsers {
      id
      outlet {
        id
      }
      user {
        id
      }
      businessUser {
        id
      }
      meta
    }
  } 
`;
