import {
  Grid, Typography, IconButton,
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainWrapper = styled(Grid)`
  margin-left: 35px;
  align-items: flex-start;
`;

export const FilterBar = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const CardsWrapper = styled(Grid)`

`;

export const PaginationText = styled(Typography)`
  font-size: 25px;
  color: #4D4F5C;
  white-space: nowrap;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

export const PopperContent = styled(Grid)`
  width: 319px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin-top: 15px;
`;

export const CheckBoxWrapper = styled(Grid)`
  padding: 16px 20px;
  color: #424242;
  font-size: 16px;
`;

export const CheckBoxHeader = styled(Grid)`
  padding: 16px 20px;
  color:#A3A3A3;
  font-size: 16px
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
`;
