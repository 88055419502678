import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, TableBody } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import RangePicker from 'react-range-picker';
import moment from 'moment';
import { ALL_PRODUCT_BATCHES_QUERY } from '../../queries/products';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import UpdateBatchDialog from './updateBatchDialog';
import ReturnRow from './returnRow';
import TablePagination from '../shared/tablePagination';
import { EXPORT_MUTATION } from '../../mutations/reports';
import CustomNewTextField from '../shared/CustomNewTextField';
import SuccessDialog from '../shared/successDialog';
import StatusDropdown from './statusDropdown';
import CustomButton from '../customComponents/customButton';
import { useStateValue } from '../../providers/stateProvider';
import {
  TableGrid, TableHeader,
  TableComponent, TableColumnHeaders, FooterWrapper, FilterGrid, TCustomButton, MenuItems, DateRangePickerContainer
} from './batchDetails.styles';
import { addHyphen } from '../shared/methods';
import CustomNewTextFieldClick from '../shared/CustomNewTextFieldClickEvent';
import useSearchParamsState from '../shared/helpers/ulrSearchParams';

const headers = [
  { name: 'S/N', width: '70px' }, { name: 'Date Created', width: '250px' }, { name: 'Suppliers', width: '250px' }, { name: 'Product Name', width: '250px' },
  { name: 'Batch#', width: '150px' }, { name: 'Expiry status', width: '150px' }, { name: 'Order Cost', width: '130px' }, { name: 'Expiry Date', width: '150px' }, { name: 'Status', width: '170px' }, { name: 'Quantity', width: '100px' },
  { name: 'Last Updated Field', width: '175px' }, { name: 'Last Update By', width: '150px' }, { name: 'Last Update Date', width: '250px' },
  { name: 'Previous (Dynamic) Info', width: '230px' }, { name: 'Current (Dynamic) Info', width: '220px' }, { name: 'Action', width: '220px' }
];

const BatchDetails = () => {
  const { batchNumber, productName } = useParams();
  const [state, setState] = useState({
    searchProductName: '',
    searchBatchNumber: '',
    status: '',
    dateFilterBy: ''
  });
  useEffect(() => {
    if ((productName && productName.length >= 3) && (batchNumber && batchNumber.length >= 3)) {
      setState({ ...state, searchProductName: productName, searchBatchNumber: batchNumber });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchNumber, productName]);

  const [batchToEdit, setBatchToEdit] = useState('');
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [dateRange, setDateRange] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });
  const [dates, setDates] = useState(null);

  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [searchProductName, setSearchProductName] = useSearchParamsState('searchP', '');
  const [searchBatchNumber, setSearchBatchNumber] = useSearchParamsState('searchB', '');
  const [status, setStatus] = useSearchParamsState('status', '');
  const [dFilter, setDFilter] = useSearchParamsState('dts', '');
  const [dateFilterBy, setDateFilterBy] = useSearchParamsState('dtb', '');
  const valState = { searchProductName, searchBatchNumber, dateFilterBy };
  const [expiryStatus, setExpiryStatus] = useSearchParamsState('expiryStatus', '');

  const [{
    user: { allowedPermissionsMap: { userPermissions } }, navbar: { openSideDrawer }
  }] = Object.values(useStateValue());

  useEffect(() => {
    if (dFilter) setDates(JSON.parse(dFilter));
  }, [dFilter]);

  useEffect(() => {
    if ((productName && productName.length >= 3) && (batchNumber && batchNumber.length >= 3)) {
      setSearchProductName(productName);
      setSearchBatchNumber(batchNumber);
    }
  }, [batchNumber, productName]);

  const FilterFields = [
    { name: 'searchProductName', label: 'Enter Product Name' },
    { name: 'searchBatchNumber', label: 'Batch Number' },
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'searchProductName': return setSearchProductName(value);
      case 'expiryStatus': return setExpiryStatus(value);
      default: return setSearchBatchNumber(value);
    }
  };

  const handleBatchEdit = (row) => {
    setBatchToEdit(row);
    setOpenBatchDialog(true);
  };

  const handleUrlDates = (range) => {
    setDFilter(JSON.stringify(range));
  };

  const handleSubmit = (range) => {
    setDates(range);
    handleUrlDates(dateRange);
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader data-testid={`erp-uat-batch-details-${addHyphen(name)}`} className={`erp-uat-batch-details-${addHyphen(name)}`} style={{ width }} key={name}>
      {name}
    </TableHeader>
  ));

  const handleStatusChange = (_status) => {
    setDotsButtonEl(null);
    setStatus(_status);
  };

  const rangePickerRef = useRef();

  function dateChangeHandler(start, end) {
    setDateRange({
      dateFrom: start,
      dateTo: end
    });
  }

  const handleRange = () => {
    const { dateFrom, dateTo } = dateRange;
    handleSubmit({ dateFrom, dateTo });
  };

  const handleDateChange = (event) => {
    rangePickerRef.current.toggleCalendar();
    const { value } = event.target.dataset || {};
    setDateFilterBy(value);
  };

  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const handleDownloadCSV = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    const newFileName = `batch_details ${randomNum}`;
    const { dateFrom, dateTo } = dates || {};
    exportMutation({
      variables: {
        type: 'batch_details',
        name: newFileName,
        productName: searchProductName,
        batchNumber: searchBatchNumber,
        dateFrom: dateFrom && moment(dateFrom).add(2, 'hour'),
        dateTo,
        status,
        dateFilterBy,
        expiryStatus
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const options = [
    { name: 'sellable', disabled: false },
    { name: 'damaged', disabled: false },
    { name: 'expired', disabled: false }
  ];

  const dateOption = [
    { name: 'Created Date', value: 'created_date' },
    { name: 'Expiry Date', value: 'expiry_date' },
  ];

  const variables = {
    pageCount,
    pageNumber,
    ...(dates && { dateFrom: dates?.dateFrom }),
    ...(dates && { dateTo: dates?.dateTo }),
    ...(status && { status }),
    ...(searchProductName.length >= 3 ? { searchProductName } : { searchProductName: '' }),
    ...(searchBatchNumber.length >= 3 ? { searchBatchNumber } : { searchBatchNumber: '' }),
    ...((dateFilterBy && dates) && { dateFilterBy }),
    expiryStatus: expiryStatus === 'all' ? '' : expiryStatus
  };

  const {
    loading, error, data, refetch
  } = useQuery(ALL_PRODUCT_BATCHES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    allProductBatches = [], allProductBatchesTotalNumber = 0
  } = data || {};

  const expiryStatuses = [
    { name: 'All', value: 'all' },
    { name: '0 - 3 Months', value: '0_3_months' },
    { name: '3 - 7 Months', value: '3_7_months' },
    { name: 'Normal', value: 'normal' },
  ];
  return (
    <>
      <FilterGrid item container spacing={1}>
        {FilterFields.map((field) => (
          <Grid item container style={{ width: openSideDrawer ? '20.5%' : '21.5%' }} key={field?.name}>
            <CustomNewTextField
              field={field}
              state={valState}
              handleChange={handleFilterChange}
              straight
              style={{ width: '100%', marginRight: 0 }}
              disabled={!userPermissions?.includes('batch_details_set_filter')}
            />
          </Grid>
        ))}
        <Grid item container style={{ width: '12%' }}>
          <CustomNewTextField
            field={{ name: 'expiryStatus', label: 'Expiry Status' }}
            state={state}
            select
            handleChange={handleFilterChange}
            style={{ width: '100%', borderRadius: '.5rem' }}
            marginLeft="2.5rem"
          >
            {expiryStatuses.map(({ name, value }) => (
              <MenuItems key={value} value={value} data-testid={`erp-uat-filter-by-${name}`} className={`erp-uat-filter-by-${name}`}>
                {name}
              </MenuItems>
            ))}
          </CustomNewTextField>
        </Grid>
        <Grid item container style={{ width: '10%' }}>
          <TCustomButton
            type="secondary"
            header
            onClick={(e) => setDotsButtonEl(e.currentTarget)}
            disabled={!userPermissions?.includes('batch_details_set_filter')}
          >
            <FilterList
              style={{
                width: '20px', fill: '#235A91', color: '#235A91'
              }}
            />
            Filter
          </TCustomButton>
        </Grid>
        <Grid item container style={{ width: '10px' }}>
          <DateRangePickerContainer>
            <RangePicker
              onDateSelected={(start, end) => dateChangeHandler(start, end)}
              rangeTillEndOfDay
              onClose={handleRange}
              editableDateInputs={false}
              ref={rangePickerRef}
            />
          </DateRangePickerContainer>
        </Grid>
        <Grid item container style={{ width: '17%' }}>
          <CustomNewTextFieldClick
            field={{ name: 'dateFilterBy', label: 'Date' }}
            state={valState}
            select
            handleChange={handleDateChange}
            style={{ width: '100%', borderRadius: '.5rem' }}
            marginLeft="2rem"
          >
            {dateOption.map(({ name, value }) => (
              <MenuItems key={value} value={value}>
                {name}
              </MenuItems>
            ))}
          </CustomNewTextFieldClick>
        </Grid>
        <Grid item container style={{ width: '15%' }}>
          <CustomButton
            type="tertiary"
            header
            style={{ height: '3rem', background: '#235A91', color: '#ffffff' }}
            onClick={handleDownloadCSV}
            data-testid="download"
          >
            Download CSV
          </CustomButton>
        </Grid>
      </FilterGrid>
      <TableGrid item container>
        {loading
          ? <ProductsTableLoader />
          : (
            <TableComponent item container>
              <TableColumnHeaders item container>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {allProductBatches.map((_row, indx) => (
                  <ReturnRow
                    key={_row.id}
                    row={_row}
                    rowIndx={indx}
                    handleBatchEdit={handleBatchEdit}
                    refetch={refetch}
                  />
                ))}
              </TableBody>
            </TableComponent>
          )}
      </TableGrid>
      <FooterWrapper item container>
        {allProductBatchesTotalNumber > 0 && (
          <TablePagination
            total={allProductBatchesTotalNumber}
            pageCount={+pageCount}
            setPageCount={setPageCount}
            pageNumber={+pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </FooterWrapper>
      {/* </PaperWrapper> */}

      <UpdateBatchDialog
        open={openBatchDialog}
        onClose={setOpenBatchDialog}
        batchToEdit={batchToEdit}
        setSuccessOpenDialog={setSuccessOpenDialog}
      />
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={`${batchToEdit?.product?.brandName} Edited Successfully!`}
        desc={`Hi Pharm, You have successfully updated ${batchToEdit?.product?.brandName}`}
        option="Ok"
        refetch={refetch}
      />
      <StatusDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
        action={handleStatusChange}
      />
      {/* </Grid> */}
      {/* </MainContent> */}
    </>
  );
};

export default BatchDetails;
