import React, { useState } from 'react';
import { Grid } from '@mui/material';

import { FilterIcon } from './busniess.style';
import Dialog from './dialogBox';
import filterIcon from '../../../assets/images/filter-icon.png';

const Business = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const role = localStorage.getItem('oga_user_role');
  if (role !== 'chain-manager') {
    return;
  }
  const openD = () => setOpenDialog(true);

  return (
    <>
      <Grid item style={{ width: '25px' }}>
        <FilterIcon title="View order by Business" src={filterIcon} type="secondary" onClick={() => openD()} />
      </Grid>
      <Dialog openDialog={openDialog} closeDialog={() => setOpenDialog(false)} />
    </>
  );
};

export default Business;
