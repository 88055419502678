import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Grid } from '@mui/material';
import {
  GridWrapper, Image, UploadWrapper, UrlLink, ImageWrapper, SizeText, SizeWrapper,
  FileContainer, FileText, Preview, CDialog, DialogTitleContainer, DialogTitleWrapper, DialogTitleText, DialogsubText, CloseIcon, PreviewImage, DeleteImage,
} from './initialImage.styles';

import DropZone from './fileDropzone';
import CropImageContainer from './cropImage/cropImageContainer';

const InitialImage = ({
  name, files, handleFile, handleFinalImage, editing, openImageDialog, handleCloseImageDialog, handleImgDelete,
  handleSave, isLoading, handleCancle, state, isCategory
}) => {
  const [openPreviewDialog, setOpenPreviewDialog] = useState({});

  const kbToMb = ((kb) => {
    const val = kb / (1024 * 1024);
    return `${val.toFixed(2)}mb`;
  });

  const handlePreview = () => {
    setOpenPreviewDialog((prev) => ({ ...prev, [name]: true }));
  };

  const closePreview = () => {
    setOpenPreviewDialog((prev) => ({ ...prev, [name]: false }));
  };

  const confirmation = () => {
    handleImgDelete(state?.name);
  };

  const truncateString = (data, maxLength = 40) => {
    if (data?.length > maxLength) return `${data.substring(0, maxLength)}...`;
    return data;
  };

  return (
    <GridWrapper container item>
      {!editing ? (
        <>
          <FileContainer container xs={12}>
            <ImageWrapper container xs={10}>
              <Image src={state?.carouselImage} alt="url" />
              <FileText>{state?.name}</FileText>
              <Preview onClick={handlePreview}>Preview</Preview>
            </ImageWrapper>
            <SizeWrapper container xs={2}><SizeText>{kbToMb(state?.size)}</SizeText></SizeWrapper>
          </FileContainer>
          {isCategory && (
            <UrlLink>
              {`${state?.name}:`}
              {' '}
              <span style={{ color: '#303030' }}>{truncateString(state?.carouselUrl)}</span>
            </UrlLink>
          )}
        </>
      ) : (
        <>
          <UploadWrapper className="mpAdmin-uat-banner-upload-container">
            <DropZone
              handleFile={handleFile}
              handleCancle={handleCancle}
              name={name}
            />
          </UploadWrapper>
          {state?.carouselImage ? (
            <FileContainer container xs={12} style={{ marginTop: '2rem' }}>
              <ImageWrapper container xs={10}>
                <Image src={state?.carouselImage} alt="url" />
                <FileText>{state?.name}</FileText>
                <Preview onClick={handlePreview}>Preview</Preview>
              </ImageWrapper>
              <SizeWrapper container xs={2}><SizeText>{kbToMb(state?.size)}</SizeText></SizeWrapper>
              <DeleteImage className="mpAdmin-uat-banner-delete" onClick={confirmation} />
            </FileContainer>
          ) : '' }
        </>
      )}
      { openImageDialog[name] && (
        <CDialog
          open={openImageDialog[name]}
          keepMounted
          onClose={handleCloseImageDialog[name]}
          maxWidth="sm"
          fullWidth
          filesLimit={1}
        >
          <Grid container>
            <GridWrapper container item xs={12}>
              <DialogTitleContainer id="alert-dialog-slide-title">
                <Grid container>
                  <DialogTitleWrapper container xs={10}>
                    <DialogTitleText>
                      Profile Photo
                    </DialogTitleText>
                    <DialogsubText>Crop and adjust it to perfection</DialogsubText>
                  </DialogTitleWrapper>
                  <Grid item container xs={2} justifyContent="flex-end" style={{ marginTop: '1rem' }}>
                    <CloseIcon onClick={() => handleCloseImageDialog(name)} />
                  </Grid>
                </Grid>
              </DialogTitleContainer>
              <DialogContent>
                <CropImageContainer
                  files={files}
                  setFinalImage={handleFinalImage}
                  handleSave={handleSave}
                  name={name}
                  isLoading={isLoading}
                />
              </DialogContent>
            </GridWrapper>

          </Grid>
        </CDialog>
      )}
      <CDialog
        open={openPreviewDialog[name]}
        keepMounted
        onClose={handleCloseImageDialog[name]}
        maxWidth="sm"
        fullWidth
        filesLimit={1}
      >
        <Grid container>
          <GridWrapper styled={{ minHeight: '25rem' }} container item xs={12}>
            <DialogTitleContainer id="alert-dialog-slide-title">
              <Grid container>
                <Grid item container xs={12} justifyContent="flex-end">
                  <CloseIcon onClick={closePreview} />
                </Grid>
              </Grid>
            </DialogTitleContainer>
            <DialogContent styled={{ minHeight: '25rem' }}>
              <PreviewImage src={state?.carouselImage} alt="url" />
            </DialogContent>
          </GridWrapper>
        </Grid>
      </CDialog>
    </GridWrapper>
  );
};
InitialImage.propTypes = {
  name: PropTypes.string.isRequired,
  files: PropTypes.instanceOf(Array).isRequired,
  handleFile: PropTypes.func.isRequired,
  handleFinalImage: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  openImageDialog: PropTypes.string.isRequired,
  handleCloseImageDialog: PropTypes.string.isRequired,
  handleImgDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleCancle: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  isCategory: PropTypes.bool.isRequired,
};
export default InitialImage;
