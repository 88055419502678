import {
  Grid,
  IconButton,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const AllUsersWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  margin-left: 35px;
`;

export const FilterBar = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 10px;
`;

export const OutletUsersWrapper = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 50vh;
`;

export const PaginationText = styled(Typography)`
  font-size: 25px;
  color: #4D4F5C;
  white-space: nowrap;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

export const PopperContent = styled(Grid)`
  width: 319px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin-top: 15px;
`;

export const CheckBoxWrapper = styled(Grid)`
  padding: 16px 20px;
  color: #424242;
  font-size: 16px;
`;

export const CheckBoxHeader = styled(Grid)`
  padding: 16px 20px;
  color:#A3A3A3;
  font-size: 16px
`;

export const FooterGrid = styled(Grid)`
  display: flex;
  height: 75px;
  justify-content: flex-end;
  padding-left: 20px;
  align-items: center;
`;
