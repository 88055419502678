import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid, InputAdornment
} from '@mui/material';
import { useMutation, useLazyQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { JSONParse } from '../../utils/json';
import SearchPopper from './searchPopper';
import DropZone from '../shared/logoDropzone';
import CropImageContainer from '../shared/logoProcess/cropImageContainer';
import InitialImage from '../shared/logoProcess/initialImage';
import SearchIcon from '../../assets/images/Search Icon.png';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader, UploadGrid,
  GridWrapper, TopGrid, BottomGrid, CTextField, SearchImg
} from './addInvoiceDialog.styles';
import {
// CREATE_ORDER_INVOICE_MUTATION, UPDATE_ORDER_INVOICE_MUTATION
} from '../../mutations/orders';
import { FIND_SUPPLIERS_QUERY } from '../../queries/suppliers';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AddInvoiceDialog = ({
  bizId, dialogOpen, closeDialog, invoiceData, refetchInvoice
}) => {
  const initialState = {
    businessId: bizId,
    supplierName: '',
    supplierId: '',
    supplierAddress: '',
    invoiceNumber: '',
    dateReceived: moment().format('YYYY-MM-DD'),
    mobile: '',
    email: '',
    image: '',
    orderInvoiceId: '',
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
  };

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [searchedSuppliers, setSearchedSuppliers] = useState([]);
  const [openPopper, setOpenPopper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [editing, setEditing] = useState(false);
  const [showInitialImage, setShowInitialImage] = useState(false);
  const textRef = useRef();
  const navigate = useNavigate();

  const { nameError } = errorState;

  useEffect(() => {
    if (invoiceData && invoiceData?.orderInvoice?.status) {
      const {
        id, business, supplier, supplierAddress, invoiceNumber,
        dateReceived, invoiceImage, mobile, email
      } = invoiceData?.orderInvoice || {};
      setShowInitialImage(true);
      setState({
        ...state,
        orderInvoiceId: Number(id),
        businessId: Number(business?.id),
        supplierName: supplier?.name,
        supplierId: supplier?.id,
        supplierAddress,
        invoiceNumber,
        dateReceived: moment(dateReceived).format('YYYY-MM-DD'),
        mobile,
        email,
        image: invoiceImage
      });
    }
  }, [invoiceData]);

  useEffect(() => {
    if (bizId) {
      setState({ ...state, businessId: bizId });
    }
  }, [bizId]);

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    setFinalImage(null);
    closeDialog();
  };

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleChangeInitialImage = () => {
    setShowInitialImage(false);
  };

  const handleDeleteInitialImage = () => {
    setShowInitialImage(false);
    setState({ ...state, image: '' });
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.INVOICE_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  // const [createOrderInvoice] = useMutation(CREATE_ORDER_INVOICE_MUTATION);
  const addInvoice = (invoiceImage) => {
    // const {
    //   businessId, supplierName, supplierAddress, invoiceNumber,
    //   dateReceived, mobile, email
    // } = state;

    // createOrderInvoice({
    //   variables: {
    //     businessId, supplierName, supplierAddress, invoiceNumber,
    //     dateReceived, mobile, email, invoiceImage
    //   }
    // }).then(({ data }) => {
    //   const { message, invoice } = data?.createOrderInvoice || {};
    //   const msg = message || 'Order invoice created Successfully';
    //   toast.success(msg);
    //   setTimeout(() => history.push(`/invoices/${invoice?.id}/details`), 1500);
    // }).catch((err) => {
    //   toast.error(err?.message);
    // }).finally(() => {
    //   setLoading(false);
    // });
  };

  // const [updateOrderInvoice] = useMutation(UPDATE_ORDER_INVOICE_MUTATION);
  const editInvoice = (invoiceImage) => {
    // const {
    //   orderInvoiceId, businessId, supplierName, supplierAddress, invoiceNumber,
    //   dateReceived, mobile, email
    // } = state;

    // updateOrderInvoice({
    //   variables: {
    //     orderInvoiceId, businessId, supplierName, supplierAddress, invoiceNumber,
    //     dateReceived, mobile, email, invoiceImage
    //   }
    // }).then(({ data }) => {
    //   const { message } = data?.updateOrderInvoice || {};
    //   const msg = message || 'Order invoice created Successfully';
    //   toast.success(msg);
    //   if (refetchInvoice) refetchInvoice();
    // }).catch((err) => {
    //   toast.error(err?.message);
    // }).finally(() => {
    //   setLoading(false);
    // });
  };

  const handleSave = async () => {
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134267/Placeholders/Product_Placeholder.png';
    const alreadyUploadedImage = state.image || logoPlaceholder;
    const invoiceImage = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    if (state.orderInvoiceId) return editInvoice(invoiceImage);
    return addInvoice(invoiceImage);
  };

  const handleSaveButtonClick = () => {
    const {
      supplierName, supplierAddress, invoiceNumber
    } = state;
    if (supplierName === '') {
      toast.error('Suppliers Name is required');
    } else if (supplierAddress === '') {
      toast.error('Suppliers Address is required');
    } else if (invoiceNumber === '') {
      toast.error('Invoice Number field is required');
    } else {
      return handleSave();
    }
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'brandName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      default:
        return null;
    }
  };

  const [findSupplier, { data }] = useLazyQuery(FIND_SUPPLIERS_QUERY);
  useEffect(() => {
    if (data && data.findSupplier) {
      const suppliers = data.findSupplier
        .filter((supplier) => !!supplier)
        .map(({ id, name, meta }) => ({ id, name, meta }));
      setSearchedSuppliers(suppliers);
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });

    if (name === 'supplierName' && value.length > 2) {
      findSupplier({
        fetchPolicy: 'no-cache',
        variables: { search: value }
      });
      setOpenPopper(true);
    }
  };

  const handleSetSupplier = (sup) => {
    const { id, name, meta } = sup;
    const { city, country } = JSONParse(meta);
    const ci = city ? `${city}, ` : '';
    const count = country ? `${country}` : '';
    setState({
      ...state, supplierId: id, supplierName: name, supplierAddress: `${ci}${count}`
    });
    setOpenPopper(false);
  };

  const topFields = [
    { name: 'supplierName', label: 'Search supplier' },
    { name: 'supplierAddress', label: 'Supplier Address' }
  ];

  const bottomFields = [
    { name: 'invoiceNumber', label: 'Invoice Number' },
    { name: 'dateReceived', label: 'Date Received' },
    { name: 'mobile', label: 'Mobile' },
    { name: 'email', label: 'Email' }
  ];

  const returnInputProps = (name) => {
    switch (name) {
      case 'supplierName':
        return {
          endAdornment: (
            <InputAdornment position="end">
              <SearchImg alt="icon" src={SearchIcon} />
            </InputAdornment>
          ),
        };
      default:
        return null;
    }
  };

  const returnInputLabelProps = (name) => {
    switch (name) {
      case 'dateReceived':
        return {
          shrink: true,
        };
      default:
        return null;
    }
  };

  const returnType = (name) => {
    switch (name) {
      case 'dateReceived':
        return 'date';
      case 'mobile':
        return 'number';
      default:
        return 'text';
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText
    } = field;
    const value = state[fieldName];

    return (
      <CTextField
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={returnType(fieldName)}
        error={fieldName === 'brandName' ? nameError : false}
        helperText={nameError && helperText}
        name={fieldName}
        onChange={handleChange}
        InputProps={returnInputProps(fieldName)}
        InputLabelProps={returnInputLabelProps(fieldName)}
        ref={fieldName === 'supplierName' ? textRef : {}}
      />
    );
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
      >
        <Grid container style={{ maxHeight: '79vh' }}>
          <GridWrapper container item xs={8}>
            <DialogTitle id="alert-dialog-slide-title">
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {state.orderInvoiceId ? 'Edit Invoice' : 'Add Invoice'}
                </DialogTitleText>
                <DialogTitleSubText>
                  {state.orderInvoiceId
                    ? 'Edit order invoice information'
                    : 'Add order invoice information'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent>
              <TopGrid item>
                {topFields.map((field) => returnTextField(field))}
              </TopGrid>
              <BottomGrid item>
                {bottomFields.map((field) => returnTextField(field))}
              </BottomGrid>
            </DialogContent>
            <DialogActions style={{ padding: '0 3rem 2rem' }}>
              <DialogCancelButton onClick={handleCloseDialog}>
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>
              <DialogOkButton onClick={handleSaveButtonClick}>
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>Save</DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
          <UploadGrid container item xs={4}>
            {showInitialImage ? (
              <InitialImage
                image={state.image}
                handleImageChange={handleChangeInitialImage}
                handleImageDelete={handleDeleteInitialImage}
              />
            ) : (files && files.length ? (
              <CropImageContainer
                files={files}
                handleFile={handleFile}
                handleFinalImage={setFinalImage}
              />
            ) : (
              <DropZone
                handleFile={handleFile}
              />
            ))}
          </UploadGrid>
        </Grid>
      </Dialog>

      <SearchPopper
        open={openPopper}
        anchorEl={textRef.current}
        searchedSuppliers={searchedSuppliers}
        handleSetSupplier={handleSetSupplier}
      />
    </>
  );
};

AddInvoiceDialog.propTypes = {
  bizId: PropTypes.string,
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  invoiceData: PropTypes.shape(Object),
  refetchInvoice: PropTypes.func.isRequired,
};

AddInvoiceDialog.defaultProps = {
  bizId: '',
  invoiceData: {}
};

export default AddInvoiceDialog;
