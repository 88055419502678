import {
  Grid, Typography, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const GridContainer = styled(Grid)`
    padding: 5px 10px;
    width: 20rem;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #CCCCCC;

    @media(min-width: 992px) {
      padding: 0px;
      width: 200px
    }
`;

export const ItemTypo = styled(Typography)`
  margin-left: 15px;
  font-size: 2rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1.2rem;
    padding: 6px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: center;
`;
