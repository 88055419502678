import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useMediaQuery } from '@mui/material';
import { CLEARANCE_SALES_DETAILS } from '../../../../queries/products';
import {
  ContentContainer, ProductTypo, ProductNameTypo, MenuButton, BackTypo, CategoriesBox
} from './detailsContainer.styles';
import MainContent from '../../../customComponents/mainContent';
import ProductDetail from './productDetail';
import ProductInfo from './productInfo';
import SimilarProducts from '../../../cart/similarProducts/similarProduct';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';
import CategoriesContainer from '../categories/categoriesContainer';

const ClearanceDetailsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;

  const { loading, data } = useQuery(CLEARANCE_SALES_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      batchId: id,
      affiliateId: _selectedAffiliate?.id
    },
  });
  const smallScreen = useMediaQuery('(max-width: 991px)');

  if (loading) return <NewOrdersLoader />;

  const { clearanceOrderPageProducts = {} } = data || {};
  const { product } = clearanceOrderPageProducts[0];

  const { brandName, marketplaceVersion: { therapeuticType } } = product;
  const { id: theraId } = therapeuticType || {};
  const renderCategories = () => {
    if (!loading) {
      return (
        <CategoriesContainer />
      );
    }
    return null;
  };

  return (
    <MainContent>
      {!smallScreen ? (
        <CategoriesBox>
          {renderCategories()}
        </CategoriesBox>
      ) : ''}
      <ContentContainer data-testid="clearanceSale">
        <MenuButton
          variant="outlined"
          onClick={() => navigate(-1)}
          startIcon={<KeyboardBackspaceIcon fontSize="large" />}
        >
          <BackTypo>back</BackTypo>
        </MenuButton>
        <ProductTypo>Clearance Sale</ProductTypo>
        <ProductNameTypo>
          {brandName}
        </ProductNameTypo>
        <ProductDetail product={clearanceOrderPageProducts} clearanceSale="clearanceSale" />
        <ProductInfo product={clearanceOrderPageProducts} clearanceSale="clearanceSale" />
        <SimilarProducts searchProductIds={[id]} therapeuticTypes={[theraId]} cart />
      </ContentContainer>
    </MainContent>
  );
};

export default ClearanceDetailsContainer;
