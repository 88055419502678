import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_REPORTS } from '../../queries/reports';

import StockReport from './stockReport';
import { useStateValue } from '../../providers/stateProvider';

const StockReportContainer = () => {
  const [{ business: { businessId, businessUser: businessUserId } }] = Object.values(useStateValue());
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');

  const handleStockReportClicked = (id) => {
    navigate(`/reports/${id}/details`);
  };

  const params = {};
  const endpoint = 'stock_count_reports';

  const variables = {
    pageCount,
    pageNumber,
    ...(searchText?.length > 2 && { search: searchText }),
    ...(filterStatus !== 'all' && { filter: filterStatus }),
  };
  if (businessId) {
    variables.businesses = [businessId];
    params.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
    params.businessUserIds = [businessUserId];
  }

  const {
    data, loading, error
  } = useQuery(GET_ALL_REPORTS, {
    fetchPolicy: 'no-cache',
    variables
  });
  if (error) return `Error! ${error.message}`;
  const { stockReports, stockReportTotalNumber = 0 } = data ?? {};

  return (
    <StockReport
      stockReports={stockReports}
      stockReportsLoading={loading}
      setPageCount={setPageCount}
      setPageNumber={setPageNumber}
      handleStockReportClicked={handleStockReportClicked}
      page={pageNumber}
      currentPageCount={pageCount}
      totalCount={stockReportTotalNumber}
      endpoint={endpoint}
      params={params}
      searchText={searchText}
      setSearchText={setSearchText}
      setFilterStatus={setFilterStatus}
    />
  );
};

export default StockReportContainer;
