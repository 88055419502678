import {
  Grid, Typography, Button, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Button)`
    padding: 15px 25px;
    width: 20rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 16rem;
      height: 3.063rem;
      margin-bottom: .5rem;
    }
    &:hover {
      background-color: #F3F9FF;
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 1rem;
`;

export const ItemWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-left: 1.5rem;
`;

export const Delete = styled('img')`
  font-size: 3.5rem;
  height: 3rem;
  color: #A3A3A3;

  @media(min-width: 992px) {
    font-size: 1.5rem;
    height: 1.5rem;
  }
`;
