import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  padding: 10px;
  width: 15rem;
`;

export const ButtonsGrid = styled(Grid)`
  margin-top: 8px;
`;
