import React, { useEffect, useState } from 'react';
import {
  Paper, Table, TableRow, TableHead, Box, Grid
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  TableContainerWrapper, ArrowImg, TableBox, TableContent,
  HeaderGrid, Header, DivFullWidth, HeaderGridItem, HeaderCell,
  BackButton,
  TitleTextGridContainer,
  PrimaryTitle,
  TitleGridContainer,
  CreateUser,
  TextWrapper
} from './usersContainer.styles';
import CustomSearchField from '../../shared/CustomSearchField';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../shared/tablePagination';
import MainContent from '../../customComponents/mainContent';
import backImg from '../../../assets/images/marketOrder/Arrow.png';
import { BUSINESS_USERS_QUERY } from '../../../queries/businesses';
import { MainTableBody } from '../roles/roles.styles';
import CreateUserDialog from './createUserDialog/CreateUserDialog';
import { useStateValue } from '../../../providers/stateProvider';

export default function Users({ param, userRole }) {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [userDialog, setUserDialog] = useState(false);
  const navigate = useNavigate();
  const headers = [
    'S/N', 'Name', 'Email', 'Role', 'Action'
  ];

  const handleDialog = () => {
    setUserDialog(!userDialog);
  };

  const [{
    user: { platform, allowedPermissionsMap: { userPermissions } },
  }, dispatch] = Object.values(useStateValue());

  let variables = {
    pageCount,
    pageNumber,
    search: searchText.length >= 3 ? searchText : ''
  };

  const { name: role, id: roleId } = param || {};
  if (param) {
    variables = {
      ...variables,
      roleId
    };
  }
  const {
    loading, error, data, refetch
  } = useQuery(BUSINESS_USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const {
    businessUsers = [], businessUsersCount = 0
  } = data || {};
  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <MainContent>
      <div>
        <BackButton
          variant="outlined"
          startIcon={<ArrowImg src={backImg} alt="back" />}
          onClick={() => navigate(-1)}
          container
          xs={12}
        >
          back
        </BackButton>
        <TitleGridContainer container item xs={12} justifyContent="space-between">
          <TitleTextGridContainer item md={6}>
            <PrimaryTitle variant="h5">
              { role ? `${role} ` : 'All ' }
              Users
            </PrimaryTitle>
          </TitleTextGridContainer>
          <Grid container md={6} spacing={2} justifyContent="flex-end">

            <CreateUser
              data-testid="export"
              item
              onClick={handleDialog}
              disabled={!userPermissions?.includes('role_permissions_add_user')}
            >
              <TextWrapper>
                Create User
              </TextWrapper>
            </CreateUser>
          </Grid>
        </TitleGridContainer>
        <Box style={{ padding: '30px' }}>
          <TableContainerWrapper elevation={0}>
            {/* <HeaderGrid container>
              <HeaderGridItem>
                <Header>
                  { role ? `${role} ` : 'All ' }
                  Users
                </Header>
              </HeaderGridItem>
            </HeaderGrid> */}
            <HeaderGrid>
              <CustomSearchField
                name="search"
                value={searchText}
                fullWidth
                placeholder="Search for User"
                handleChange={(e) => handleSearch(e?.target?.value)}
              />
            </HeaderGrid>

            {loading ? <ProductsTableLoader /> : (
              <TableBox>
                <TableContent component={Paper}>
                  <Table aria-label="users table">
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <HeaderCell>{header}</HeaderCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <MainTableBody item container>
                      {businessUsers.map(({
                        id, fullName, user, systemRole,
                      }, index) => (
                        <ReturnRow
                          id={id}
                          index={index}
                          fullName={fullName}
                          user={user}
                          systemRole={systemRole}
                          roleName={userRole}
                          refetch={refetch}
                        />
                      ))}
                    </MainTableBody>
                  </Table>
                </TableContent>
              </TableBox>
            )}
            <DivFullWidth>
              <TablePagination
                total={businessUsersCount}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </DivFullWidth>
          </TableContainerWrapper>
          <CreateUserDialog open={userDialog} close={handleDialog} />
        </Box>
      </div>
    </MainContent>
  );
}
