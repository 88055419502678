import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchPaper = styled(Paper)`
  background: #ffffff;
  width: 43.5rem;
  padding: 2%;
  z-index: 1200;
  border: 1px solid #00000021;
  max-height: 22rem;
  overflow-y: scroll;
`;

export const ProductItem = styled(Grid)`
  padding: 5px 15px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f4f4f4
  }
`;

export const PackSizeGrid = styled(Grid)`
  font-size: 9px;
  color: #A3A3A3;
  padding-left: 2%;
  padding-top: 1%;
`;

export const ProdName = styled(Typography)`
  font-size: .85rem;
  padding-left: 2%;
  margin-bottom: .2rem;
`;

export const ImgDiv = styled('div')`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ prodImg }) => `url(${prodImg})`};
`;
