import {
  Grid, IconButton, Paper, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainWrapper = styled(Grid)`
  @media(max-width: 991px) {
    background-color: white;
    background-image: linear-gradient(white, #F0F0F0);
  }
`;

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 5px 30px;
  background-color: #F3F9FF;
`;

export const DataContainer = styled(Grid)`
  background-color: #FFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
border-radius: 12px;
@media(max-width: 991px) {
  background: none;
  box-shadow: 0;

}

`;

export const TitleGridContainer = styled(Grid)`
  margin: .5rem 0 1rem;
  align-items: center;
`;

export const ButtonsPaper = styled(Paper)`
  padding: 1rem 3rem;
  border-radius: 1rem;

  @media(max-width: 991px) {
    padding: 2rem 5rem;
  }
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding-right: 30px;
  font-size: 1rem;
  @media(max-width: 991px) {
    .MuiSvgIcon-fontSizeLarge {
      font-size: 4rem;
  }
  }
`;

export const TitleText = styled(Typography)`
  font-weight: 700;
  color: #24292E;
  font-size: 24px;
  text-align: left;
  padding-bottom: 1rem;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;
