import { styled } from '@mui/material/styles';
import {
  Paper, Grid, IconButton, Typography
} from '@mui/material';

const sm = '960px';

export const OrderDetailCard = styled(Paper)`
  background: #FFFFFF;
  box-shadow: ${(props) => (props.returns ? '' : '0px 2.96px 66.64px rgba(163, 171, 185, 0.18)')};
  width: 95vw;
  max-height: 620px;
  border-radius: 15px;
  padding: ${(props) => (props.returns ? '0px 1rem 1rem 4rem' : '0px 4rem 5rem 4rem')};
  position: relative;
  margin-bottom: 25px;

  @media (min-width: 992px) {
    max-height: 450px;
    padding: 0px 30px;
    margin-bottom: 0px;
    width: 100%;
    border-radius: 15px;
  }
`;

export const OrderDetailCardHeader = styled('div')`
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${sm}) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const OrderDetailText = styled('p')`
  margin: 0 0 1rem 0;
  font-weight: 450;
  font-size: 16px;
  color: #606060;

  @media (max-width: 991px) {
    font-size: ${(props) => (props.returns ? '2rem' : '2.5rem')};
    padding-bottom: 30px;
  }
`;

export const OrderDetailBoldText = styled('span')`
  margin-bottom: 1rem;
  font-weight: 550;
  font-size: 16px;
  color: #606060;
  display: block;

  @media (max-width: 991px) {
    font-size: ${(props) => (props.returns ? '2rem' : '2.5rem')};
    padding-bottom: 30px;
    display: block;
    inline-size: ${(props) => (props.returns && '400px')};
    margin-left: ${(props) => (props.returns && '30px')};
    overflow-wrap: ${(props) => (props.returns && 'break-word')};
  }
`;

export const AffiliateBoldText = styled('span')`
  margin-bottom: 1rem;
  font-weight: 550;
  font-size: 17px;
  color: #606060;

  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const OrderDetailWrapper = styled('div')`
margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OrderDetailLogo = styled('img')`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  background-color: white;

  @media (max-width: 991px) {
    width: 10rem;
    height: 10rem;
  }
`;

export const OrderDetailName = styled('p')`
  margin: 0;
  margin-bottom: 20px;
  color: #424242;
  font-size: 25px;
  font-weight: 600;

  @media (max-width: ${sm}) {
    font-size: 15px;
  }
`;

export const OrderDetailInfoWrapper = styled('div')`
  margin-top: 36px;

  @media (max-width: ${sm}) {
    margin-top: 80px;
  }
`;

export const OrderDetailInfoText = styled('p')`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  color: #606060;

  @media (min-width: 992px) {
    font-size: 17px;
  }
`;

export const OrderFulfilledWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const OrderFulfilledInfoName = styled('p')`
  color: #424242;
  font-size: 1.8rem;
  font-weight: 600;
  height: 18px;

  @media(min-width: 992px) {
    font-size: 17px;
  }
`;

export const SupplierPaymentTermText = styled('p')`
  color: #606060;
  font-size: 17px;

  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const OrderDetailSupplierInfoLogo = styled('img')`
  width: 35px;
  height: 35px;
  margin-right: 10px;

  @media (max-width: ${sm}) {
    width: 20px;
    height: 20px;
  }
`;

export const OrderDetailSupplierInfoName = styled('p')`
  color: #424242;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;

  @media (min-width: 992px) {
    font-size: 17px;
    margin-top: 0px;
  }
`;

