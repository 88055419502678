import React, { useState } from 'react';
import MapCsvDialog from './mapCsvDialog';
import ReconcileTable from './reconcileTable';

const CSV_FIELDS = [
  ['Batch ID', {
    required: true, unique: false, value: null, empty: true, touched: false, hover: false
  }],
  ['Product Name', {
    required: true, unique: true, value: null, empty: true, touched: false, hover: false
  }],
  ['Product ID', {
    required: true, unique: true, value: null, empty: true, touched: false, hover: false
  }],
  ['Quantity Left', {
    required: true, unique: false, value: null, empty: true, touched: false, hover: false
  }]
];
function MapSupplierCsvDialog() {
  const [tableInfo, setTableInfo] = useState([]);
  return (
    tableInfo.length ? (
      <ReconcileTable tableData={tableInfo} />
    ) : (
      <MapCsvDialog
        fields={CSV_FIELDS}
        model="Inventory Detail"
        endpoint="reconcile"
        backRoute="products"
        setData={setTableInfo}
      />
    )
  );
}

MapSupplierCsvDialog.propTypes = {
};
export default MapSupplierCsvDialog;
