import { Paper, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SupplierItem = styled('div')`
  padding: 4px;
  cursor: pointer;

  &:hover {
    background: #e8e8e8;
  }
`;

export const ThePopper = styled(Popper)`
  z-index: 100000000;
`;

export const SearchPaper = styled(Paper)`
  background: #ffffff;
  width: 14.2rem;
  padding: 2%;
  z-index: 1200;
  border: 1px solid #00000021;
  max-height: 520px;
  overflow-y: scroll;
`;
