import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';
import CustomNewTextField from '../../shared/CustomNewTextField';
import {
  FiltersGrid, SetFilter, MenuItems, FilterText
} from './productsFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import { useStateValue } from '../../../providers/stateProvider';

const ProductFilter = ({
  state, setState, handleFilterChange, handleSetFilter
}) => {
  const { categories } = state;

  const [{
    user: { platform, allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const handleSubmit = (dateRange) => {
    const { dateFrom: from, dateTo: to } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(from).format('YYYY-MM-DD'),
      dateTo: moment(to).format('YYYY-MM-DD')
    }));
  };

  const FilterFields = [
    { name: 'productName', label: 'Enter Product Name' },
    { name: 'sku', label: 'Enter SKU' },
    { name: 'supplier', label: 'Supplier' },
  ];

  const productStatuses = [
    { name: 'Enabled', value: 'enabled' },
    { name: 'Disabled', value: 'disabled' },
  ];

  return (
    <Grid item container xs={12}>
      <FiltersGrid container item>
        <CustomNewTextField
          field={{ name: 'status', label: 'Product Status' }}
          state={state}
          select
          handleChange={handleFilterChange}
          style={{ width: '10rem', borderRadius: '.5rem' }}
          marginLeft="2.5rem"
        >
          {productStatuses.map(({ name, value }) => (
            <MenuItems key={value} value={value} data-testid={`erp-uat-filter-by-${name}`} className={`erp-uat-filter-by-${name}`}>
              {name}
            </MenuItems>
          ))}
        </CustomNewTextField>
        {FilterFields.map((field) => (
          <CustomNewTextField
            key={field?.name}
            field={field}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '10rem', borderRadius: '.5rem' }}
            className={`erp-uat-filter-by-${field?.name}`}
            data-testid={`erp-uat-filter-by-${field?.name}`}
          />
        ))}
        <CustomNewTextField
          field={{ name: 'categoryId', label: 'Pricing Category' }}
          state={state}
          select
          handleChange={handleFilterChange}
          style={{ width: '10rem', borderRadius: '.5rem' }}
          marginLeft="2.5rem"
          maxHeight="20rem"
        >
          {categories.map(({ categoryName, id }) => (
            <MenuItems key={id} value={id} data-testid={`erp-uat-filter-by-${categoryName}`} className={`erp-uat-filter-by-${categoryName}`}>
              {categoryName}
            </MenuItems>
          ))}
        </CustomNewTextField>
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            styles={{
              height: '47px', width: '15rem', border: '1px solid #E7E8E9', marginRight: '8px', borderRadius: '.5rem'
            }}
            xs={12}
            className="erp-uat-filter-by-date"
            data-testid="erp-uat-filter-by-date"
          />
        </Grid>
        <SetFilter
          className="erp-uat-set-filter"
          type="tertiary"
          onClick={handleSetFilter}
          disabled={!userPermissions?.includes('inventory_set_filter')}
          data-testid="erp-uat-set-filter"
        >
          <FilterText>Set Filter</FilterText>
        </SetFilter>
      </FiltersGrid>
    </Grid>
  );
};

ProductFilter.propTypes = {
  state: PropTypes.shape(Object),
  setState: PropTypes.shape(Object).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
};

ProductFilter.defaultProps = {
  state: {},
};

export default ProductFilter;
