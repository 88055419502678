import { styled } from '@mui/material/styles';
import {
  MenuItem, TableHead, TableRow, TableCell
} from '@mui/material';

export const ContainerBody = styled('div')`
  margin-top: 10px;
  padding: 20px 40px;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #f4f5f7;
  font-weight: bold;
  font-size: 30px;
`;

export const TableRowStyled = styled(TableRow)`
    ${({ cond }) => cond && `
        background: #f4f5f7;
    `}
`;

export const TableCellStyled = styled(TableCell)`
  font-weight: bold;
  border-bottom: none
`;

export const TableCellBStyled = styled(TableCell)`
  border-bottom: none;
`;
