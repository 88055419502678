import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { RenderPopper } from '../../../../utils/utils';
import {
  GridWrapper, ButtonsGrid
} from './emailPopper.styles';

const filterPopper = ({
  popperEl, handlePopper, email, handleChange, handleAddedEmail
}) => {
  const open = Boolean(popperEl);

  return (
    <RenderPopper
      open={open}
      anchorEl={popperEl}
      onClickAway={(e) => handlePopper(e)}
      popperPlacement="bottom-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '4vw, 0vh',
        }
      }}
    >
      <GridWrapper container>
        <TextField
          variant="filled"
          name="centralProcurementEmail"
          label="Add Procurement email"
          size="small"
          type="email"
          fullWidth
          value={email || ''}
          onChange={handleChange}
        />
        <ButtonsGrid item container justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            disableElevation
            onClick={(e) => handlePopper(e)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            style={{ marginLeft: '5px' }}
            onClick={handleAddedEmail}
          >
            Done
          </Button>
        </ButtonsGrid>
      </GridWrapper>
    </RenderPopper>
  );
};

filterPopper.propTypes = {
  popperEl: PropTypes.instanceOf(Object),
  handlePopper: PropTypes.func.isRequired,
};

filterPopper.defaultProps = {
  popperEl: {},
};

export default filterPopper;
