import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderButton, OrderButtonText, PlusIcon, ButtonsGrid,
} from './titleGrid.styles';
import IndividualSubBusinessDialog from '../../shared/addSubBusiness/individualSubBusinessDialog';
import { useStateValue } from '../../../providers/stateProvider';

export function TitleGrid({ setContentKey }) {
  const [{ user: { parentId } }] = Object.values(useStateValue());
  const [openModal, setOpenModal] = useState(false);
  const closeDialogHandler = () => {
    setOpenModal(false);
    setContentKey(Math.random());
  };
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Outlet(s)
        </PrimaryTitle>
        <SecondaryTitle>
          Add, view and edit outlet(s)
        </SecondaryTitle>
      </TitleTextGridContainer>
      {!parentId && (
      <ButtonsGrid item>
        <OrderButton onClick={() => setOpenModal(true)}>
          <Hidden mdDown>
            <PlusIcon />
          </Hidden>
          <OrderButtonText>
            Create Outlet
          </OrderButtonText>
        </OrderButton>
        <IndividualSubBusinessDialog
          dialogOpen={openModal}
          closeDialog={closeDialogHandler}
        />
      </ButtonsGrid>
      )}

    </TitleGridContainer>
  );
}

TitleGrid.propTypes = {
  setContentKey: PropTypes.instanceOf(Function)
};

TitleGrid.defaultProps = {
  setContentKey: () => 'clicked'
};

export default TitleGrid;
