import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/material/styles';

export const ToTopButton = styled(IconButton)`
  color: #A3A3A3;
  background: #FCED50;
  box-shadow: 0px 3px 6px #00000029;

  @media(min-width: 992px) {

  }
`;

export const ArrowIcon = styled(ArrowUpwardIcon)`
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;
