import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Chart } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import dropdown from '../../../assets/images/marketOrder/dropdown1.png';
import {
  ChangeButton,
  ChartTitle,
  ContentBox,
  Dropdown,
  FlexApart,
  GridContainer,
  GridWrapper,
  ItemTypo,
  ItemWrapper,
  RenderPopperStyled
} from './scChart.styles';

export default function ScChart({ response, filter, handleFilter }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const fontStyle = { size: '16px', weight: 'bolder', color: '#000000' };
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSelect = (type) => {
    handleFilter(type);
    handleToggle();
  };

  const { supplyChainDashboard } = response?.data || {};
  const {
    totalOrderValueAffiliateGraph,
    totalOrderCountAffiliateGraph,
    totalCostOfGoodsGraph
  } = supplyChainDashboard || {};
  const totalOrderValue = totalOrderValueAffiliateGraph?.map(
    (value) => +value.total
  );
  const totalOrderCount = totalOrderCountAffiliateGraph?.map(
    (value) => +value.total
  );
  const totalGoodCost = totalCostOfGoodsGraph?.map((value) => +value.total);
  const totalValueMonth = totalOrderValueAffiliateGraph?.map(
    (value) => value.count
  );

  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const weekLabel = [];
  for (let index = 0; index < totalOrderValueAffiliateGraph?.length; index++) {
    const construct = `Week ${index}`;
    weekLabel.push(construct);
  }

  const monthLabel = [];
  if (totalValueMonth) {
    totalValueMonth.forEach((val) => {
      monthLabel.push(labels[+val - 1]);
    });
  }

  const returnPeriod = () => {
    switch (filter) {
      case 'month':
        return monthLabel;
      case 'week':
        return weekLabel;
      default:
        return monthLabel;
    }
  };

  const data = {
    labels: returnPeriod(),
    datasets: [
      {
        label: 'Order By Value',
        backgroundColor: 'rgba(32, 134, 254, 1)',
        borderColor: 'rgba(32, 134, 254, 1)',
        data: totalOrderValue,
        tension: 0.5
      },

      {
        label: 'Cost of Goods Sold',
        backgroundColor: 'rgba(12, 185, 112, 1)',
        borderColor: 'rgba(12, 185, 112, 1)',
        data: totalGoodCost,
        tension: 0.5
      }
    ]
  };

  const countData = {
    labels: returnPeriod(),
    datasets: [
      {
        label: 'Order By Count',
        backgroundColor: '#FF6B0F',
        borderColor: '#FF6B0F',
        data: totalOrderCount,
        tension: 0.5
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: { display: true, text: 'Period', font: fontStyle },
        grid: { display: false }
      },
      y: { title: { display: true, text: 'Value', font: fontStyle } }
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Order By Value & Cost of Goods Sold',
        align: 'start',
        font: fontStyle
      }
    }
  };

  // Order by count option
  const copiedObj = JSON.parse(JSON.stringify(options));
  copiedObj.plugins.title.text = 'Order by Count';

  return (
    <>
      <FlexApart>
        <ChartTitle>Sales Analytics</ChartTitle>
        <Box style={{ textAlign: 'right' }}>
          <ChangeButton
            variant="outlined"
            onClick={handleToggle}
            ref={anchorRef}
          >
            {`${filter}ly`}
            <Dropdown src={dropdown} alt="dropdown" />
          </ChangeButton>
        </Box>
        <RenderPopperStyled
          open={open}
          anchorEl={anchorRef.current}
          popperPlacement="bottom"
          onClickAway={handleClose}
          modifiers={{
            offset: {
              enabled: true,
              offset: '0vw, 2vh'
            }
          }}
        >
          <GridWrapper container item>
            <GridContainer container item onClick={() => handleSelect('month')}>
              <ItemWrapper>
                <ItemTypo variant="subtitle1">Monthly</ItemTypo>
              </ItemWrapper>
            </GridContainer>
            <GridContainer container item onClick={() => handleSelect('week')}>
              <ItemWrapper>
                <ItemTypo variant="subtitle1">Weekly</ItemTypo>
              </ItemWrapper>
            </GridContainer>
          </GridWrapper>
        </RenderPopperStyled>
      </FlexApart>

      <ContentBox elevation={0} style={{ overflow: 'scroll' }}>
        <Box
          style={{
            height: '512px',
            width: filter === 'month' ? '100%' : '100rem'
          }}
        >
          <Line data={data} options={options} />
        </Box>
      </ContentBox>

      <ContentBox elevation={0} style={{ overflow: 'scroll' }}>
        <Box
          style={{
            height: '512px',
            width: filter === 'month' ? '100%' : '120rem'
          }}
        >
          <Line data={countData} options={copiedObj} />
        </Box>
      </ContentBox>
    </>
  );
}

ScChart.propTypes = {
  response: PropTypes.instanceOf(Object).isRequired,
  filter: PropTypes.string.isRequired,
  handleFilter: PropTypes.func.isRequired
};
