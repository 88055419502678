import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Hidden, Grid } from '@mui/material';
import toast from 'react-hot-toast';

import MainContent from '../../../customComponents/mainContent';
import UserInfo from './userInfo';
import UserPermissions from './userPermissions';
import RemoveUserDialog from './removeUserDialog';
import AddUserDialog from '../addUserDialog/addUserDialog';
import EditIcon from '../../../../assets/images/Edit Icon.png';
import { GET_BUSINESS_USER } from '../../../../queries/user';
import { DELETE_BUSINESS_USER } from '../../../../mutations/outlets';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, EditUserButton, DeleteButton, EditUserButtonText,
  TextWrapper, HeaderIcon, MenuButton,
} from './singleUserDetails.styles';

const ProductDetailsContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const [deleteUserMutation] = useMutation(DELETE_BUSINESS_USER);
  const {
    loading, error, data, refetch
  } = useQuery(GET_BUSINESS_USER, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });
  const role = localStorage.getItem('oga_user_role');
  const closeDialogHandler = async () => {
    setOpenModal(false);
    await refetch();
  };

  if (loading) return 'loading...';
  if (error) return error;
  const { businessUser } = data || {};

  const deleteUser = () => {
    deleteUserMutation({
      variables: { id: +businessUser.id }
    }).then(({ data: { deleteBusinessUser: { message } } }) => {
      toast.success(message);
      setTimeout(() => navigate('/settings/users'), 1500);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item container xs={6}>
            <MenuButton onClick={() => navigate('/settings/users')}>
              <KeyboardArrowLeft fontSize="large" />
            </MenuButton>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                User Detail
              </PrimaryTitle>
              <SecondaryTitle>
                View details of a specified user
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
          <Grid item container xs={6} justifyContent="flex-end">
            {role === 'chain-manager'
              ? (
                <EditUserButton onClick={() => setOpenModal(true)}>
                  <HeaderIcon alt="edit user" src={EditIcon} />
                  <Hidden mdDown>
                    <EditUserButtonText>Edit</EditUserButtonText>
                  </Hidden>
                </EditUserButton>
              ) : null}
            <DeleteButton onClick={() => setDeleteUserOpen(true)}>
              <HeaderIcon alt="delete user" src={EditIcon} />
              <Hidden mdDown>
                <EditUserButtonText>Delete</EditUserButtonText>
              </Hidden>
            </DeleteButton>
          </Grid>
        </TitleGridContainer>
        <UserInfo info={businessUser} />
        <UserPermissions businessUser={businessUser} />
      </OrderGridContainer>

      <AddUserDialog
        dialogOpen={openModal}
        closeDialog={closeDialogHandler}
        info={businessUser}
      />
      <RemoveUserDialog
        open={deleteUserOpen}
        setOpen={setDeleteUserOpen}
        deleteUser={deleteUser}
      />
    </MainContent>
  );
};

export default ProductDetailsContainer;
