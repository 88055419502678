import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';

import {
  RowBodyCell, RowMainTableRow, StatusText, CommentText
} from './helpDeskContainer.styles';

const ReturnRow = ({ ticket, isDev, handleOpenTicketModel }) => {
  const {
    comment, createdBy: { username }, dateCreated, description, lastStatus,
    status, lastUpdated, ticketNumber
  } = ticket;

  const user = username.split('@')[0];

  const handleStatusClick = () => {
    if (isDev()) handleOpenTicketModel();
  };

  const renderStatus = (stat, type) => {
    const isCurrent = type === 'cs';
    if (comment) {
      return (
        <Grid>
          <StatusText
            stat={stat}
            dev={isDev()}
            variant="caption"
            onClick={handleStatusClick}
          >
            {`${stat.toLowerCase()} `}
          </StatusText>
          {isCurrent && (
            <CommentText variant="caption">
              {`${comment.toLowerCase()}`}
            </CommentText>
          )}
        </Grid>
      );
    }
    return (
      <Grid>
        <StatusText
          stat={stat}
          dev={isDev()}
          variant="caption"
          onClick={handleStatusClick}
        >
          {`${stat.toLowerCase()}`}
        </StatusText>
      </Grid>
    );
  };

  const dtFormat = (d) => moment(d).format('DD/MM/YYYY');

  return (
    <RowMainTableRow>
      <RowBodyCell style={{ width: '160px' }}>
        <Grid>{dtFormat(dateCreated)}</Grid>
      </RowBodyCell>
      <RowBodyCell style={{ width: '190px' }}>
        <Grid>{user}</Grid>
      </RowBodyCell>
      <RowBodyCell style={{ width: '140px' }}>
        <Grid>{ticketNumber}</Grid>
      </RowBodyCell>
      <RowBodyCell style={{ width: '380px' }}>
        <Grid>{description}</Grid>
      </RowBodyCell>
      <RowBodyCell style={{ width: '130px' }}>
        {renderStatus(status, 'cs')}
      </RowBodyCell>
      <RowBodyCell style={{ width: '190px' }}>
        {lastStatus ? renderStatus(lastStatus, 'ls') : 'None'}
      </RowBodyCell>
      <RowBodyCell style={{ width: '190px' }}>
        <Grid>{dtFormat(lastUpdated)}</Grid>
      </RowBodyCell>
    </RowMainTableRow>
  );
};

ReturnRow.propTypes = {
  ticket: PropTypes.instanceOf(Object).isRequired,
  isDev: PropTypes.func.isRequired,
  handleOpenTicketModel: PropTypes.func.isRequired,
};

export default ReturnRow;
