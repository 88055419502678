import React from 'react';
import PropTypes from 'prop-types';

import currencyFormatter from '../../../shared/currencyFormatter';

import {
  ProductContainer,
  ProductDetail,
  ProductDetailImage,
  ProductDetailBrandNameText,
  ProductDetailPackSizeText,
  HideMd,
  ShowMd,
  ProductDetailSavingsText,
  ProductDetailItemPriceText,
  ProductCounterMobileContainer,
  ProductDetailMainContainer,
  ProductDetailPriceMobileContainer,
  ProductDetailNameMainContainer
} from '../../../cart/product-item/styles';
import { ProductCounter } from './styles';
import { PromoInfo } from '../../newOrder/promoInfo';
import { isERP } from '../../../../utils/utils';
import { useStateValue } from '../../../../providers/stateProvider';

const ProductItem = ({
  product: {
    supposedAmount,
    supposedUnitCost,
    orderCost,
    unitCostPaid,
    marketRpp,
    name: brandName,
    meta: {
      image,
      pack_size: packSize,
    },
    quantity,
    quantityRecieved
  }
}) => {
  const [{ user: { platform } }] = Object.values(useStateValue());
  const resolvedUnitCostPaid = platform === 'erp' ? orderCost : unitCostPaid;
  return (
    <ProductContainer>
      <ProductDetail>
        <ProductDetailImage src={image} alt="" />

        <ProductDetailNameMainContainer>
          <ProductDetailBrandNameText>
            { brandName }
          </ProductDetailBrandNameText>
          <HideMd>
            <ProductDetailPackSizeText>
              Pack Size:&nbsp;
              { packSize }
            </ProductDetailPackSizeText>
          </HideMd>
          {resolvedUnitCostPaid < supposedUnitCost < 1 ? '' : <PromoInfo info="First Order Promotion" />}
        </ProductDetailNameMainContainer>
      </ProductDetail>

      <ShowMd>
        <ProductDetailPriceMobileContainer>
          <ProductDetailPackSizeText>
            Pack Size:&nbsp;
            { packSize }
          </ProductDetailPackSizeText>
            &nbsp;|&nbsp;
          <ProductDetailPackSizeText>
            ₦&nbsp;
            { currencyFormatter(supposedUnitCost) }
          </ProductDetailPackSizeText>
        </ProductDetailPriceMobileContainer>
        {
          resolvedUnitCostPaid < supposedUnitCost ? (
            <ProductDetailSavingsText style={{ color: '#AF1839' }}>
              Promo: ₦&nbsp;
              { currencyFormatter(Number(resolvedUnitCostPaid)) }
            </ProductDetailSavingsText>
          ) : ''
          }
        {!isERP() && (
          <ProductDetailSavingsText style={{ left: '0rem', top: '9rem' }}>
            Savings: ₦&nbsp;
            { (marketRpp - supposedUnitCost) < 0 ? currencyFormatter(0) : currencyFormatter((Number(marketRpp) || Number(supposedUnitCost)) - Number(supposedUnitCost)) }
          </ProductDetailSavingsText>
        )}

        <ProductDetailItemPriceText>
          ₦&nbsp;
          { currencyFormatter(Number(supposedUnitCost) * Number(quantityRecieved)) }
        </ProductDetailItemPriceText>

        <ProductCounterMobileContainer style={{ paddingLeft: '15rem' }}>
          <ProductCounter>
            Quantity:&nbsp;
            { Number(quantity) }
          </ProductCounter>

          <ProductCounter>
            Price:&nbsp;
            { currencyFormatter(supposedAmount) }
          </ProductCounter>
        </ProductCounterMobileContainer>
      </ShowMd>

      <HideMd>
        <ProductCounter
          style={{
            width: '240px',
            color: '#424242',
            fontSize: '20px',
          }}
        >
            {Number(quantity)}
        </ProductCounter>

        <ProductCounter
          style={{
            width: '270px',
            color: '#424242',
            fontSize: '20px',
          }}
        >
            {quantityRecieved === undefined ? 0 : Number(quantityRecieved)}
        </ProductCounter>

        <ProductDetailMainContainer
          style={{
            width: '280px',
            display: 'grid',
            justifyContent: 'center'
          }}
        >
          <ProductDetailItemPriceText>
            ₦
            { currencyFormatter(supposedUnitCost) }
          </ProductDetailItemPriceText>
          {
            resolvedUnitCostPaid < supposedUnitCost ? (
              <ProductDetailSavingsText style={{ color: '#AF1839' }}>
                Promo: ₦&nbsp;
                { currencyFormatter(Number(resolvedUnitCostPaid)) }
              </ProductDetailSavingsText>
            ) : ''
            }
          {!isERP() && (
            <ProductDetailSavingsText>
              Savings: ₦&nbsp;
              { (marketRpp - supposedUnitCost) < 0 ? currencyFormatter(0) : currencyFormatter((Number(marketRpp) || Number(supposedUnitCost)) - Number(supposedUnitCost)) }
            </ProductDetailSavingsText>
          )}
        </ProductDetailMainContainer>

        <ProductDetailItemPriceText>
          ₦
          { currencyFormatter(Number(supposedUnitCost) * Number(quantityRecieved)) }
        </ProductDetailItemPriceText>
      </HideMd>
    </ProductContainer>
  );
};

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

ProductItem.defaultProps = {
};

export default ProductItem;
