import React from 'react';
import Tabs from '@mui/material/Tabs';
import {
  Tab,
  Grid,
  Typography,
  IconButton,
  TableHead,
  TableCell,
  Paper,
  TextField,
  Button
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import styled, { css } from 'styled-components';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    backgroundColor: '#F0F0F0',
    boxShadow: 'none',
    fontSize: '16px'
  },
  indicator: {
    backgroundColor: 'yellow'
  }
})(Tabs);

export const DivFullWidth = styled('div')`
  width: 100%
`;

export const StyledTab = styled(Tab)`
  * {
    font-size: 1.8rem;
    @media (min-width: 992px) {
      font-size: 16px;
    }
  }
`;

export const MainContainer = styled(Grid)``;

export const OrderGridContainer = styled(Grid)`
  padding: 30px;
`;

export const StatisticsGridWrapper = styled(Grid)`
  overflow: hidden;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media (min-width: 992px) {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  transform: translateX(0px);
  transition: 500ms;
`;

export const OrdersTab = styled(Grid)`
  justify-content: space-between;
  border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;
  padding: 0 10px;

  @media (min-width: 992px) {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
`;

export const ActiveOrderNumber = styled(Typography)`
  font-size: 22px;
  color: #424242;
  font-weight: 600;
  padding: 35px 0px;

  @media (min-width: 992px) {
    font-size: 20px;
    padding: 30px 0px;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    justify-content: space-between;
  }
`;

const getStyles = ({ show }) => {
  if (show) {
    return css`
      display: inline-flex;
    `;
  }
  return css`
    display: none;
  `;
};

export const ArrowButton = styled(({ ...otherProps }) => (
  <IconButton {...otherProps} />
))`
  padding: 0;
  margin-top: 14px;

  @media (min-width: 992px) {
    ${getStyles}
  }
`;

export const ArrowLeftIcon = styled(KeyboardArrowLeft)`
  cursor: pointer;
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  cursor: pointer;
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const DivFlex = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;

export const OrderItemsGrid = styled(Grid)`
  /* margin-bottom: 30px;

  @media(max-width: ${sm}) {
    margin-bottom: 10px;
  } */
`;

export const DarkButtonStyled = styled(Button)`
  width: 178px;
  height: 48px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #fff !important;
`;

export const ButtonsPaper = styled(Paper)`
  margin-top: 1rem;
  padding: 1.5rem 3rem;
  border-radius: 1rem;
`;

export const TitleGridContainer = styled(Grid)`
  margin: 1rem 0 1.5rem;
  align-items: center;
`;

export const OrderCountText = styled(Grid)`
  font-size: 1.6rem;
  color: #4d4f5c;
  margin: 10px 0;

  @media (min-width: 992px) {
    font-size: 12px;
    margin: 14px 0;
  }
`;

export const MainTableHead = styled(TableHead)`
  /* display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  } */
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.5rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }

  /* :last-child {
    width: 1%;
  }
  &:nth-child(1), :nth-last-child(2) {
    width: 3%;
    padding: 0;
  }
  &:nth-child(2) {
    width: 56%;
  }

  @media(min-width: 992px) {
    &:nth-child(2) {
      width: 24%;
    }
    &:nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7) {
      width: 12%;
    }
    &:nth-child(8) {
      width: 9%;
    }
  } */
`;

export const FilterContainer = styled(Paper)`
  width: 100%;
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

export const BodyGrid = styled(Grid)`
  padding: 1.5rem;

  @media (min-width: 992px) {
    padding: 1rem;
  }
`;

export const HeaderTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  padding: 10px 25px;
  line-height: 4.5rem;

  @media (min-width: 992px) {
    font-size: 1rem;
    line-height: 2.5rem;
    padding: 10px;
  }
`;

export const NoDataTypo = styled(Typography)`
  font-size: 2rem;
  padding: 15px;
  line-height: 3.5rem;

  @media (min-width: 992px) {
    font-size: 1rem;
    padding: 10px;
    line-height: 2.5rem;
  }
`;

export const CTextField = styled(TextField)`
  margin-bottom: 1.5rem;

  &:nth-child(3),
  :nth-child(5) {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 991px) {
    & .MuiFilledInput-input {
      font-size: 2rem;
      height: 3.8rem;
      padding: 35px 20px 10px;
    }
    & .MuiInputLabel-filled.MuiInputLabel-shrink {
      transform: translate(20px, 12px) scale(1.5);
    }
  }
`;

export const FilterButton = styled(Button)`
  font-size: 1.5rem;
  height: 2.8rem;
  background: #424242;
  color: #fff;
  margin-right: 25px;

  &.MuiButton-text:hover {
    background-color: #424242;
  }

  @media (min-width: 992px) {
    font-size: 0.875rem;
    height: 1.8rem;
    margin-right: 10px;
  }
`;
