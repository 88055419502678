import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  MobileContainer, DetailText, HeaderText, TextBox, ColorGrid, DateText,
  ContentBox, StatusBox
} from './mobileReturnRow.styles';
import CustomButton from '../../../customComponents/customButton';

export default function MobileReturnRow({
  row, displayFilter
}) {
  const navigate = useNavigate();
  const {
    id, businessName, datePlaced: date, orderTotal, metaStatus, categoryName, mainOrderTotal
  } = row;
  const datePlaced = moment(date).format('DD/MM/YY (h:mm)');
  const amount = currencyFormatter(orderTotal);
  const orderAmount = currencyFormatter(mainOrderTotal);
  const deliveryDate = moment(date).add(3, 'days').format('DD/MM/YY');

  const getColorGrid = (color, bkg, name) => (
    <StatusBox>
      <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-ordersPage-${name}`}>
        {name}
      </ColorGrid>
    </StatusBox>
  );

  const getStatus = () => {
    switch (metaStatus) {
      case 'ORDER_CLOSED':
        return getColorGrid('#07DE10', '#DCFEDD', 'Closed');
      case 'ORDER_CANCELED':
        return getColorGrid('#FA3636', '#FFDAD6', 'Cancelled');
      case 'ORDER_RETURNED':
        return getColorGrid('#FA3636', '#FFDAD6', 'Returned');
      case 'ORDER_RECEIVED':
        return getColorGrid('#07DE10', '#DCFEDD', 'Received');
      case 'ORDER_SORTED':
        return getColorGrid('#606060', '#FBF777', 'Sorted');
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#FBF777', 'Order Sent');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#FBF777', 'Dispatched');
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return getColorGrid('#606060', '#FBF777', 'Ready for disp...');
      case 'APPROVING':
        return getColorGrid('#606060', '#F29053', 'Approving');
      case 'RETURNING':
        return getColorGrid('#606060', '#F29053', 'Returning');
      case 'PREPARING_FOR_DISPATCH':
        return getColorGrid('#606060', '#F29053', 'Preparing for dispatch');
      default:
        return getColorGrid('#606060', '#FBF777', `${metaStatus}`);
    }
  };
  const handleClick = (_id) => {
    navigate(`/orders-admin/dispatch/${_id}`);
  };

  const handleAction = () => {
    navigate(`/generate-invoice/${id}`);
  };

  return (
    !displayFilter && (
      <MobileContainer>
        <DateText>
          {datePlaced}
        </DateText>
        <hr />
        <ContentBox>
          <Grid data-testid="routeBtn" onClick={() => handleClick(id)}>
            <TextBox container>
              <HeaderText xs={6}>Order Number</HeaderText>
              <DetailText xs={6}>
                {`#${id}`}
              </DetailText>
            </TextBox>
            <TextBox container>
              <HeaderText xs={6}>Order Value</HeaderText>
              <DetailText xs={6}>
                {`₦${orderAmount}`}
              </DetailText>
            </TextBox>
            <TextBox container>
              <HeaderText xs={6}>Sorted Value</HeaderText>
              <DetailText xs={6}>
                {`₦${amount}`}
              </DetailText>
            </TextBox>
            <TextBox container>
              <HeaderText xs={6}>Affiliates</HeaderText>
              <DetailText xs={6}>
                {businessName}
              </DetailText>
            </TextBox>
            <TextBox container>
              <HeaderText xs={6}>Estimated Delivery</HeaderText>
              <DetailText xs={6}>
                {deliveryDate}
              </DetailText>
            </TextBox>
            <TextBox container>
              <HeaderText xs={6}>Customer Category</HeaderText>
              <DetailText xs={6}>
                {categoryName || 'N/A'}
              </DetailText>
            </TextBox>
            <TextBox container>
              <HeaderText xs={5}>Order Status</HeaderText>
              <DetailText xs={7}>
                {getStatus()}
              </DetailText>
            </TextBox>
          </Grid>
          <TextBox container>
            <CustomButton
              style={{ width: '100%', height: '5rem', fontSize: '2.5rem' }}
              onClick={handleAction}
              className="mpAdmin-uat-manage-order-action-button"
            >
              Generate Invoice
            </CustomButton>
          </TextBox>
        </ContentBox>
      </MobileContainer>
    )
  );
}

MobileReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  displayFilter: PropTypes.bool.isRequired,
};

MobileReturnRow.defaultProps = {
  row: {},
};
