import React from 'react';
import { styled } from '@mui/material/styles';
import { PaystackButton } from 'react-paystack';

import {
  Paper, Typography,
  Grid, IconButton, Box, TableContainer, Table, TableCell, Button
} from '@mui/material';

const sm = '960px';

export const SupplierDetailCard = styled(Paper)`
  width: 100%;
  min-height: 450px;
  border-radius: 15px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  word-break: break-word;

  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 50px;
    height: 750px;
    border-radius: 15px;
  }
`;

export const SupplierDetailCardHeader = styled('div')`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const SupplierDetailCardWrapper = styled(Paper)`
  margin-bottom: 52px;
  border-radius: 15px;
  width: 100%;

  @media (min-width: 992px) {
    border-radius: 10px;
  }
  @media (min-width: 991px) {
    box-shadow: 0px 2.82416px 63.5818px rgba(163, 171, 185, 0.12);
  }
`;

export const OrderDetailSupplierInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const OrderDetailSupplierInfoLogo = styled('img')`
  width: 35px;
  height: 35px;
  margin-right: 10px;

  @media (max-width: ${sm}) {
    width: 20px;
    height: 20px;
  }
`;

export const OrderDetailSupplierInfoName = styled('p')`
  color: #424242;
  font-size: 17px;
  font-weight: 600;

  @media (max-width: ${sm}) {
    font-size: 10px;
  }
`;

export const SupplierDetailCardActionButton = styled('button')`
  color: #A3A3A3;
  font-size: 18px;
  margin: 0;
  background-color: white;
  border: none;
  text-decoration: underline;

  @media (max-width: ${sm}) {
    font-size: 10px;
  }
`;

export const OrderDeliveryNotesText = styled('p')`
  color: #424242;
  font-size: 35px;

  @media (max-width: ${sm}) {
    font-size: 12px;
  }
`;

export const PayButton = styled(({ ...otherProps }) => (
  (
    <IconButton style={{ borderRadius: '15px', padding: 0 }}>
      <PaystackButton {...otherProps} />
    </IconButton>
  )
))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 35px;
  background-color: #FCED50;
  color: #424242;
  border: none;
  border-radius: 0;
  border-radius: 15px;

  &:hover {
    background-color: #D2C647;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    border-radius: 10px;
  }
`;

export const PayButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const PayIcon = styled('img')`
    width: 30px;
`;

export const HeaderTitle = styled('p')`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  white-space: nowrap;

  &:first-child {
    width: 35%;
  }
  &:nth-child(2) {
    width: 17%;
  }
  &:nth-child(3) {
    width: 18%;
  }
  &:nth-child(4) {
    width: 15%;
  }
  &:nth-child(5) {
    width: 15%;
  }
`;

export const ArrowIcon = styled('img')`
  width: 5%;
  cursor: pointer;
  margin-left: 1rem;
  @media(min-width: 992px) {
    width: 10%;
  }
`;

export const UpdateButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  color: #fff;
  border-radius: 15px;
  height: 125px;
  width: 100%;
  background: #235A91;
  margin-top: 2rem;

  &:hover {
    background-color: #235A91;
    color: #FFFFFF;
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
  }

  &.MuiIconButton-root.Mui-disabled {
    color: #fff;
    background-color: #235A91;
    opacity: 0.9;
  }
  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 7px;
    width: 14rem;
    margin-top: 0rem;
    margin-right: 1.5rem;

    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const OrderButtonText = styled(Typography)`
  font-size: 33px;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-left: 10px;
  }
`;

export const SubTotalBox = styled(Box)`
  width: 95%;
  float: right;
  display: flex;
  padding: 3rem 0rem;
  margin-bottom: 20px;
  border-top: 1px solid #E7E8E9;
  justify-content: space-around;

  @media(min-width: 992px) {
    width: 48%;
    padding: 20px 0px;
  }
`;

export const SubTotalText = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const SubTotalPrice = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 600;
  margin-left: 40px;
  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const TableWrapper = styled(TableContainer)`
  padding: 15px 15px 0px 15px;
`;

export const TableContainerWrapper = styled(TableContainer)`
  padding-top: 30px;
  margin-top: 30px;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 20px;
  font-weight: 600;
  padding-right: 10px;
  color: #A3A3A3;
  width: 20%;

  @media(min-width: 992px) {

  }
`;

export const Header = styled(TableCell)`
  text-transform: uppercase;
  padding: 25px 5px;
  font-weight: 600;
  font-size: 1rem;

  :first-child {
    width: 37%;
  }
  :last-child {
    width: 15%;
  }

  @media(min-width: 992px) {
    &:nth-child(2) {
      width: 18%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 12%;
    }
  }
`;

export const ProductDetailMainContainer = styled('div')`

`;

export const ImageWrapper = styled(Grid)`
  width: 14rem;
  max-height: 14rem;
  margin-right: 20px;
  background: none;
  border-radius: 10px;
  text-align: center;

  @media (min-width: 992px) {
  width: 100px;
  max-height: 100px;
  margin-right: 10px;
  margin-top: 0rem;
  background: #EBEBEB;
  }
`;

export const ProductDetailImage = styled('img')`
  width: 14rem;
  max-height: 14rem;
  margin-top: 2rem;

  @media (min-width: 992px) {
  width: 95px;
  max-height: 70px;
  }
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    font-size: 2rem;
    font-weight: 500;
    margin: 0;
    color: #606060;
  }

`;

export const PromoText = styled('p')`
  margin: 0;
  color: #303030;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 991px) {
    font-size: 30px;
    margin-top: 5px;
  }
`;

export const MobileText = styled(Typography)`
  margin: 1rem 0 .5rem 0;
  font-size: 2rem;
  color: #303030;
  font-weight: 500;
`;

export const PageTitleText = styled('p')`
  color: #303030;
  font-size: 3.6rem;
  font-weight: 650;
  margin: 0;
  padding: 20px 0px;
  @media (min-width: 992px) {
    font-size: 25px;
  }
`;

export const PageTitleSubText = styled('p')`
  color: #606060;
  margin: 0;
  font-size: 2.1rem;
  font-weight: 400;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-top: 5px;
    padding-bottom: 0px;
  }
`;

export const DeliveryAddressCardHeaderSubTitle = styled(Button)`
  margin: 0;
  color: #000000;
  background: #FFFFFF;
  font-size: 2rem;
  line-height: 32px;
  outline: none;
  padding: 2rem 3rem;
  border: 1px solid #858383;
  &:hover {
    color: #FFFFFF;
    background: #000000;
  }
  @media (min-width: 992px) {
    font-size: 16px;
    padding: 5px 10px;
  }

  @media(max-width: 991px) {
    margin-bottom: 35px;
  }
`;
