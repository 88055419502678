import {
  Grid, Paper, TableCell, TableBody, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DivFullWidth = styled('div')`
  width: 100%;
  padding: 0 0rem 1.5rem;
`;

export const TableContainerWrapper = styled(Paper)`
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 2rem 0rem;
  box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;

`;

export const TableGrid = styled(Grid)`
  margin: 1.2rem 0px 3rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const MainTableHead = styled(Grid)`
  width: 100%;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 33%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4){
      width: 28%;
    }
    &:nth-child(5){
      width: 16%;
    }
  }
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  line-height: 39.41px;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;
