import React from 'react';
import { useLocation } from 'react-router-dom';
import Category from './category';

const IndevidualCategory = () => {
  const location = useLocation();
  const param = location?.state;

  return (
    <Category
      param={param}
    />
  );
};

export default IndevidualCategory;
