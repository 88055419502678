import React from 'react';
import PropTypes from 'prop-types';
import { JSONParse } from '../../utils/json';
import {
  MainTableRow, BodyCell, MobileContainer, DetailText, DetailTextMb,
  HeaderText, TextBox, DateText, ContentBox, ContentTextField
} from './stockReportDetailsRow.styles';

const StockReportDetailsRowMobileView = ({ row }) => {
  const {
    batch, expectedQuantity, quantityInStock, product
  } = row;
  const productMeta = product?.meta;
  const { pack_size: packSize } = JSONParse(productMeta);
  const diff = quantityInStock - expectedQuantity;

  return (
    <MobileContainer data-testid="routeBtn" key={row.id} style={{ marginTop: '2rem' }}>
      <DateText container>
        <DetailText>
          {batch?.batchNo || 'N/A'}
        </DetailText>
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container>
          <HeaderText xs={6}>Product Name</HeaderText>
          <DetailTextMb xs={6}>
            {product?.brandName}
          </DetailTextMb>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Pack Size</HeaderText>
          <DetailTextMb xs={6}>
            {packSize}
          </DetailTextMb>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>QTY in Stock</HeaderText>
          <DetailTextMb xs={6}>
            {expectedQuantity || 0}
          </DetailTextMb>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>QTY Counted</HeaderText>
          <DetailTextMb xs={6}>
            {quantityInStock || 0}
          </DetailTextMb>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Difference</HeaderText>
          <DetailTextMb xs={6} style={{ color: diff !== 0 ? '#EE0101' : '#606060' }}>
            {diff}
          </DetailTextMb>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Reason</HeaderText>
        </TextBox>
        <ContentTextField style={{ height: '5rem' }} />
      </ContentBox>
    </MobileContainer>
  );
};

StockReportDetailsRowMobileView.propTypes = {
  row: PropTypes.instanceOf(Object)
};

StockReportDetailsRowMobileView.defaultProps = {
  row: {},
};

export default StockReportDetailsRowMobileView;
