import PropTypes from 'prop-types';
import React from 'react';
import { JSONParse } from '../../../../utils/json';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  BodyCell,
  HideMd,
  MainTableRow, ProductDetail,
  ProductDetailBrandNameText,
  ProductDetailImage,
  ProductDetailNameMainContainer,
  ProductDetailPackSizeText
} from './styles';

const ReturnRow = ({ product, index }) => {
  const {
    unitCost, price, meta: orderMeta, name: brandName, product: { meta }, quantity, quantityRecieved
  } = product;
  const prodMeta = JSONParse(meta.replace(/'/g, '"'));
  const { status, clearance_sale: clearanceSale } = JSONParse(orderMeta.replace(/'/g, '"').replace(/None/g, "null").replace(/True/g, "true").replace(/False/g));
  // console.log(status);

  const getQuantityReceived = () => {
    if (status === 'DISPATCHED') return quantityRecieved;
    return 0;
  };
  const getTotalPrice = () => {
    if (status === 'DISPATCHED') return `₦ ${currencyFormatter(price)}`;
    return 0;
  };
  return (
    <MainTableRow style={index % 2 ? { backgroundColor: '#F4F7FC' } : { backgroundColor: '#FFF' }}>
      <BodyCell>
        <ProductDetail>
          <ProductDetailImage src={prodMeta?.image} alt="" />
          <ProductDetailNameMainContainer>
            <ProductDetailBrandNameText>
              {brandName}
            </ProductDetailBrandNameText>
            <HideMd>
              <ProductDetailPackSizeText>
                {`Pack Size: ${prodMeta?.pack_size ?? 'N/A'}`}
              </ProductDetailPackSizeText>
            </HideMd>
            {clearanceSale === true || clearanceSale === 'true'
              ? (
                <span style={{
                  color: '#FFA621', fontWeight: 'bold', background: '#FFF7F3', padding: '5px 15px', borderRadius: 15, width: 'fit-content', fontSize: 10, marginTop: 5
                }}
                >
                  Short dated
                </span>
              ) : null}
          </ProductDetailNameMainContainer>
        </ProductDetail>
      </BodyCell>
      <BodyCell>{Number(quantity)}</BodyCell>
      <BodyCell>{getQuantityReceived()}</BodyCell>
      <BodyCell>{`₦ ${currencyFormatter(unitCost)}`}</BodyCell>
      <BodyCell>{getTotalPrice()}</BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
};

export default ReturnRow;
