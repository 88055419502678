import { DialogTitle, DialogContentText } from '@mui/material';

import { styled } from '@mui/material/styles';

export const CsvDialogHeader = styled(DialogTitle)`
    padding: 15px 60px;
    text-align: center;
    font-size: 1.5rem;
    border-bottom: 2px solid rgba(224, 224, 224, 1);
  `;
export const CsvDialogBody = styled(DialogContentText)`
    padding: 20px 60px;
    min-height: 200px;
  `;
export const TableRow = styled('td')`
  border-radius: 5px;
  margin: 0px;
  padding: 0px;
  background: '#fff';
  border-bottom: 2px solid rgba(224, 224, 224, 1);
`;
