import CatalogueActionTypes from './catalogueTypes';

const cartReducer = (catalogue, action) => {
  switch (action.type) {
    case CatalogueActionTypes.UPDATE_CATALOGUE:
      return {
        ...catalogue,
        ...action.payload
      };
    default:
      return catalogue;
  }
};

export default cartReducer;
