import {
  Dialog, Grid, Typography, IconButton, Checkbox,
  InputBase
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const BatchDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  padding: 1.5rem;
`;

export const DialogHeader = styled(Grid)`
  padding: 1.5rem 0;
  background: #fff;
`;

export const DialogBody = styled(Grid)`
  height: max-content;
`;

export const SubText = styled(Typography)`
  margin-right: 15px;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #858383;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 60%;
  border-radius: 6px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 7px 12px;
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }
`;

export const HeaderGrid = styled(Grid)`
  display: grid;
`;

export const BatchItems = styled(Grid)`
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  padding: 10px 15px;
  height: 85px;
  margin: 10px 0px;

`;
export const ItemGrid = styled(Grid)`
  display: grid;
  height: 70px;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  margin-right: 30px;
  background-color: #FCED50;
  color: #424242;
  margin-bottom: 40px;

  &:hover {
    background-color: #D2C647;
  }
`;

export const ProductName = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const ColumnHeader = styled(Typography)`
  color: #606060;
  font-size: 12px;
`;

export const Details = styled(Typography)`
  color: #303030;
  font-size: 14px;
`;
