import { styled } from '@mui/material/styles';
import {
  Paper, Grid, IconButton, Typography, TableCell
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const sm = '960px';

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 3rem;

  @media (min-width: 992px) {
    padding: 30px;
  }
`;

export const CIconButton = styled(IconButton)`
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin-right: 21.64px;

  @media(min-width: 992px) {
    margin-right: 5px;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  color: #000;
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;

export const PrimaryTitle = styled(Typography)`
  color: #303030;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0.3rem;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  color: #606060;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const PaperCard = styled(Paper)`
  box-shadow: 1px 1px 20px #f8f8f82e;
  border-radius: 12px;
  width: 100%;
  min-height: 30rem;
  padding: 2rem;
`;

export const CardText = styled(Typography)`
  font-size: 16px;
  color: #606060;
  font-weight: 500;
  margin-bottom: 1rem;

  @media(max-width: 991px) {
    font-size: 1.2rem;
  }
`;

export const CardLogo = styled('img')`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  background-color: white;

  @media (max-width: ${sm}) {
    width: 50px;
    height: 50px;
  }
`;

export const CardHeaderGrid = styled(Grid)`
  padding-bottom: 15px;
  border-bottom: 1px solid #E8E8E8;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: ${sm}) {
    padding-bottom: 10px;
  }
`;

export const CardHeaderText = styled(Typography)`
  color: #303030;
  font-size: 16px;
  font-weight: 700;

  @media (max-width: ${sm}) {
    font-size: 13px;
  }
`;

export const NameHeaderText = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  color: #606060;
  margin-bottom: 1.5rem;
`;

export const TableHeaderCell = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 33%;
    }
    &:nth-child(2), &:nth-child(3), :nth-child(4) {
      width: 17%;
    }
    :nth-child(5) {
      width: 16%;
    }
  }
`;

export const TotalsGrid = styled(Grid)`
  justify-content: flex-end;
  padding: 1rem 2rem;
`;

export const SupplierDetailCard = styled(Paper)`
  width: 95vw;
  height: 450px;
  border-radius: 7px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  margin-bottom: 25px;

  @media (min-width: 992px) {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const SupplierDetailCardWrapper = styled(Grid)`
  @media (max-width: ${sm}) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const OrderDetailSupplierInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const OrderDetailSupplierInfoLogo = styled('img')`
  width: 35px;
  height: 35px;
  margin-right: 10px;

  @media (max-width: ${sm}) {
    width: 20px;
    height: 20px;
  }
`;

export const OrderDetailSupplierInfoName = styled('p')`
  color: #424242;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;

  @media (min-width: 992px) {
    font-size: 17px;
    margin-top: 0px;
  }
`;

export const SupplierDetailCardActionButton = styled('button')`
  color: #A3A3A3;
  font-size: 18px;
  margin: 0;
  background-color: white;
  border: none;
  text-decoration: underline;

  @media (max-width: ${sm}) {
    font-size: 10px;
  }
`;

export const OrderDeliveryNotesText = styled('p')`
  color: #424242;
  font-size: 20px;

  @media (max-width: ${sm}) {
    font-size: 12px;
  }
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
  margin-right:0px;
  justify-content: center;

  @media (min-width: 2560px) {
    margin-right: 22%;
  }

  @media (max-width: 1199px) {
    margin-left: 7.5rem;
    margin-right: 8rem;
  }

  @media (max-width: 991px) {
    margin: 0 13rem;
  }
`;

export const ProductContainer = styled('div')`
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 2px solid #ECECEC;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    padding: 35px 0;
    margin: 0 10px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const ProductDetail = styled('div')`
  display: flex;
  align-items: center;

  @media (min-width: 992px) {
    width: 400px;
  }
`;

export const ProductDetailImage = styled('img')`
  width: 11rem;
  max-height: 11rem;
  margin-right: 20px;

  @media (min-width: 992px) {
  width: 100px;
  max-height: 100px;
  margin-right: 10px;
  }
`;

export const ProductDetailBrandNameText = styled('p')`
  color: #424242;
  font-size: 35px;
  font-weight: 600;
  margin: 0;
  text-transform: title-case;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    line-height: 23px;
    font-size: 18px;
  }
`;

export const ProductDetailPackSizeText = styled('p')`
  margin: 0;
  color: #424242;
  font-size: 16px;
  line-height: 23px;

  @media (max-width: 991px) {
    color: #868686;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 300;
  }
`;

export const HideMd = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const ShowMd = styled(Grid)`
  position: relative;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const ProductDetailItemPriceText = styled('p')`
  margin: 0;
  margin-right: auto;
  color: #424242;
  line-height: 23px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;

  @media (max-width: 991px) {
    font-size: 30px;
    margin-right: 0;
  }
`;

export const ProductDetailMainContainer = styled('div')`
  width: 145px;
  margin-right: 20px;

  @media (min-width: 2560px) {
    margin-right: 12%;
  }
`;

export const ProductDetailPriceMobileContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 991px) {
    height: 15rem;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin: 25px 0px;

  @media (min-width: 992px) {
    justify-content: space-between;
  }
`;
