import React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Fade, List, ListItem, ListItemText, Grid, Typography
} from '@mui/material';
import { CPopper, PaperWrapper } from './cartPopper.styles';

const CartPopper = ({
  dotsButtonEl, setDotsButtonEl, options
}) => {
  const open = Boolean(dotsButtonEl);

  return (
    <CPopper
      open={open}
      anchorEl={dotsButtonEl}
      transition
      disablePortal
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <PaperWrapper>
            <ClickAwayListener onClickAway={() => setDotsButtonEl(null)}>
              <List style={{ width: '100%', padding: 0 }}>
                {options.map(({ name, action, disable }) => (
                  <ListItem
                    disableGutters
                    dense
                    button
                    style={{ padding: '.5rem 1.5rem' }}
                    key={name}
                    disabled={disable}
                    onClick={action}
                  >
                    <ListItemText
                      primary={(
                        <Grid container item>
                          <Typography
                            variant="caption"
                            noWrap
                            style={{ fontSize: '1rem', color: '#606060' }}
                          >
                            {name}
                          </Typography>
                        </Grid>
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </ClickAwayListener>
          </PaperWrapper>
        </Fade>
      )}
    </CPopper>
  );
};

CartPopper.propTypes = {
  dotsButtonEl: PropTypes.shape(Object),
  setDotsButtonEl: PropTypes.func.isRequired,
  options: PropTypes.shape(Array),
};

CartPopper.defaultProps = {
  dotsButtonEl: {},
  options: [],
};

export default CartPopper;
