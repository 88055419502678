import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Checkbox, Grid, Slide, FormControlLabel, Button
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BUSINESS_SUPPLIERS_QUERY } from '../../../../queries/suppliers';
import {
  DialogContainer, BoxHeader, ContentGrid, InputGrid, Hybrid,
  HeaderText, SmallText, Text
} from './suppliersModel.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme?.typography?.pxToRem(13),
  }
}));

const SuppliersModel = ({
  open, handleClose, handleAutomaticOrdering, handleSelected, selected
}) => {
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [allColumns, setAllColumns] = useState(false);

  const { loading, data } = useQuery(BUSINESS_SUPPLIERS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const cols = [];

    if (data && data.businessSuppliers) {
      data.businessSuppliers.forEach(({ id, name }) => {
        cols.push({
          id,
          name,
          allowed: false
        });
      });
      setColumns(cols);
    }
  }, [data, loading]);

  useEffect(() => {
    let newCols = [];
    if (allColumns) newCols = columns.map((col) => ({ ...col, allowed: true }));
    else newCols = columns.map((col) => ({ ...col, allowed: false }));
    setColumns(newCols);
  }, [allColumns]);

  const handleChosenData = () => {
    const cols = [];
    columns.forEach((col) => {
      if (col.allowed) cols.push(Number(col.id));
    });
    return cols;
  };

  const handleStart = () => {
    const supplierIds = handleChosenData();
    handleAutomaticOrdering(supplierIds);
    handleClose();
  };

  const handleColumns = (event) => {
    const { name, checked } = event.target;
    const cols = [...columns];
    const idx = cols.findIndex((col) => col.name === name);
    if (idx !== -1) {
      cols.splice(idx, 1, { ...cols[idx], allowed: checked });
    }
    setColumns(cols);
  };

  const options = [{
    id: 1, name: 'hybrid', value: 'YES', label: 'Yes'
  }, {
    id: 2, name: 'hybrid', value: 'NO', label: 'No'
  }];

  return (
    <DialogContainer
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      data-testid="erp-uat-automatic-order"
    >
      <BoxHeader>
        <Grid>
          <HeaderText>Automatic Order Suppliers</HeaderText>
          <SmallText variant="caption">
            Select suppliers to order from
          </SmallText>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            onClick={handleStart}
            style={{ width: '8rem', height: '3rem' }}
            data-testid="erp-uat-start-automatic-order"
          >
            Start order
          </Button>
        </Grid>
      </BoxHeader>
      <Hybrid>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Text>Is it hybrid?</Text>
            {
              options.map((option) => {
                const {
                  id, name, value, label
                } = option;
                return (
                  <FormControlLabel
                    key={id}
                    control={<Checkbox name={name} />}
                    classes={classes}
                    checked={selected.includes(value)}
                    onChange={handleSelected}
                    value={value}
                    label={label}
                  />
                );
              })
            }
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={allColumns}
              onChange={() => setAllColumns(!allColumns)}
            />
            <Text>Select All Suppliers</Text>
          </div>
        </div>
      </Hybrid>
      <ContentGrid item container alignItems="center">
        <InputGrid item container>
          <Grid container>
            {columns.map((col) => (
              <Grid item md={4} key={col.id}>
                <FormControlLabel
                  control={<Checkbox checked={col.allowed} onChange={handleColumns} name={col.name} />}
                  classes={classes}
                  label={col.name}
                />
              </Grid>
            ))}
          </Grid>
        </InputGrid>
      </ContentGrid>
    </DialogContainer>
  );
};

SuppliersModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSelected: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  handleAutomaticOrdering: PropTypes.func.isRequired
};

export default SuppliersModel;
