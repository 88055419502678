import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchPaper = styled(Paper)`
  background: #ffffff;
  width: 20rem;
  padding: 0 1rem;
  max-height: 26rem;
  overflow-y: scroll;
  border-radius: 12px;
`;

export const ProductItem = styled('div')`
  cursor: pointer;
  padding: 1rem 0;
  &:hover {
    background: #F3F9FF
  }
`;

export const ProdName = styled(Grid)`
  font-size: 14px;
  font-weight: 400;
  color: #303030;
`;
