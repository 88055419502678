import React, { useState } from 'react';
import { onMessageListener } from '../../firebase/index';
import NotificationDialog from './NotificationDialog';

const Notification = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState();

  onMessageListener()
    .then((payload) => {
      setMessage(payload);
      setDialogOpen(true);
    })
    .catch((err) => {
      console.log('Notification err: ', err?.message);
    });

  return (
    <NotificationDialog
      dialogOpen={dialogOpen}
      closeDialog={() => setDialogOpen(false)}
      message={message}
    />
  );
};

export default Notification;
