import {
  Grid, Typography, IconButton, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
    min-width: 250px;
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
`;
export const InfoText = styled(Grid)`
    text-align: center;
    font-family: 1.2rem;
    color: grey;
`;
export const ItemTypoBox = styled(Grid)`
    cursor: pointer;
    &:hover {
      background-color: #CCCCCB;
    }
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 1rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1rem;
    padding: 6px 12px;
  }
`;
export const UpdateButton = styled(Button)`
  margin: 10px 0 10px 0;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 10px 45px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 10px;
  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 35px;
    padding: 12px 20px;
  }
`;
