import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Banner from './banner';
import { BANNERS_DETAIL } from '../../../queries/banners';

const BannerDetails = () => {
  const location = useLocation();
  const param = location?.state;

  const variables = {
    page: param?.page,
  };

  const {
    data, refetch
  } = useQuery(BANNERS_DETAIL, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const {
    bannerPageDetail = [],
  } = data || {};

  return (
    <Banner param={param} bannerPageDetail={bannerPageDetail} refetch={refetch} />

  );
};

export default BannerDetails;
