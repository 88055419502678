import React from 'react';
import {
  Dialog, DialogContentText, Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
// import ConfirmDialog from '../../../../shared/confirmDialog';
import {
  ButtonGrayStyled,
  ButtonDarkStyled,
  FlexDiv,
  CommentHeader,
  DialogActionsStyled,
  CommentSubHeader,
  DialogContentStyled,
  TextAreaStyled,
  CommentFieldDesc,
} from '../return-popper.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CommentDialog = ({
  openCommentDialog,
  handleCloseDialog,
  setReason,
  handleReturnSubmit
}) => (
  <Dialog
    open={openCommentDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseDialog}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContentStyled>
      <FlexDiv>
        <CommentHeader>Reason for Return</CommentHeader>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleCloseDialog}
        />
      </FlexDiv>
      <CommentSubHeader>
        You are about to return your product, you are required to give a
        reason for the return
      </CommentSubHeader>
      <CommentFieldDesc>Add Note</CommentFieldDesc>
      <TextAreaStyled
        placeholder="This note will help us serve you better"
        onChange={(e) => setReason(e.target.value)}
      />
      <DialogContentText style={{ marginTop: '1rem' }} />
      <DialogActionsStyled>
        <ButtonGrayStyled
          style={{ marginLeft: '2rem' }}
          onClick={handleCloseDialog}
        >
          Cancel
        </ButtonGrayStyled>
        <ButtonDarkStyled onClick={handleReturnSubmit}>
          Submit
        </ButtonDarkStyled>
      </DialogActionsStyled>
    </DialogContentStyled>
  </Dialog>
);

CommentDialog.propTypes = {
  setReason: PropTypes.func.isRequired,
  openCommentDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleReturnSubmit: PropTypes.func.isRequired
};

export default CommentDialog;
