import React from 'react';
import PropTypes from 'prop-types';
import { Label, CInputBase } from '../fields.styles';

const TextField = ({ fields }) => {
  const { label } = fields;
  return (
    <>
      {label ? <Label>{label}</Label> : ''}
      <CInputBase {...fields} />
    </>
  );
};

TextField.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired
};
export default TextField;
