import {
  Typography, Grid, ButtonGroup, Button, TextField, Box,
  CircularProgress
} from '@mui/material';
import styled, { css } from 'styled-components';
import {
  MinusFrame, AddFrame, CartBag
} from '../../../../assets/svgs';

export const DetailGrid = styled(Grid)`
    height: 430px;
    margin-top: 20px;
    box-shadow: 0px 2.13419px 48.0481px rgba(163, 171, 185, 0.12);
    border-radius: 15px;
    padding: 0px 7rem 0px;

    @media (max-width: 991px) {
        height: 100%;
        border-radius: 15px;
        margin-top: 40px;
        padding: 0px;

    }
`;

export const DetailContentGrid = styled(Grid)`
    height: 100%;
    width: 100%;
    position: relative;
    @media (max-width: 991px) {
      padding-bottom: 10px;
  }
`;

export const ContentGrid = styled(Grid)`
    height: 100%;
    width: 100%;
`;

export const ImgLogo = styled('img')`
    width: 50%;
    display: block;
    margin: auto;

`;

export const DetailHeader = styled(Typography)`
    color: #787885;
    font-weight: 400;
    font-size: 20px;
    margin-top: 15px;

    @media (max-width: 991px) {
        font-size: 35px;
        font-weight: 400;
    }
`;

export const DetailTypo = styled(Typography)`
    color: #394359;
    font-weight: 650;
    font-size: 20px;

    @media (max-width: 991px) {
        font-size: 37px;
        line-height: 60px;
        font-weight: 700;
    }
`;

export const QuantityGrid = styled(Grid)`
  width: 95%;
  @media (max-width: 991px) {
    width: 80%;

  }
`;

export const QuantityContentGrid = styled(Grid)`
    text-align: center;

@media (max-width: 991px) {
    text-align: left;

  }
`;

export const InputButtonGroup = styled(ButtonGroup)`
    border: none;
    margin-left: -5px;
    margin-top: 5px;
`;

export const ActionButton = styled(Button)`
    border: none;

    &:disabled {
        border: none;
    }

    @media (min-width: 992px) {
        &.MuiButtonGroup-grouped {
            min-width: 20px;
        }

        &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
            margin-left: -15px;
        }
    }

    @media (max-width: 991px) {
        &.MuiButtonGroup-grouped {
            min-width: 30px;
        }

        &.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child) {
            margin-left: -15px;
        }
    }
`;

export const ActionTextField = styled(TextField)`
   & .MuiInputBase-input {
  text-align: center;
  font-weight: 600;
  font-size: 1.313rem;
  color: #212121;
}
  & ::placeholder {
    text-align: center;
    color: #000;
    font-weight: 600;
    font-size: 1.313rem;
  }

  & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
    font-size: 1.3em;
    width: 1.2rem;
    text-align: center;
    color: #212121;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 3rem;
      width: 10rem;
    }
    & .MuiInputBase-root {
      font-size: 2.7rem;
      padding: 5px 15px 5px;
    }
    & .MuiInputBase-input {
      text-align: center;
      font-weight: 600;
      font-size: 2.6rem;
      color: #212121;
    }
    & ::placeholder {
      text-align: center;
      color: #212121;
      font-weight: 600;
      font-size: 2.6rem;
    }
  }

  @media (min-width: 992px) {
    & input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
      font-size: 1.313rem;
    }
  }
`;

export const ActionImg = styled('img')`
    width: 20px;

    @media (max-width: 991px) {
        width: 100px;
    }
`;

export const Content = styled(Box)`
    padding: 20px;
    position: relative;
`;

export const AddButton = styled(Button)`
    border-radius: 8px;
    background-color: #245A91;
    margin-top: 20px;
    color: #fff;
    height: 50px;

    &:hover {
        background-color: #245A91;
        color: #fff;
    }


    &.MuiButton-contained.Mui-disabled {
        color: #fff;
        opacity: 0.5;
    }

    @media(min-width: 992px) {
        border-radius: 4px;
    }

    @media (max-width: 991px) {
        height: 120px;
    border-radius: 20px;

    }
`;

export const AddCartTypo = styled(Typography)`
    font-size: .9rem;

    @media (max-width: 991px) {
        font-size: 2.1rem;
    font-weight: 600;
    }
`;

export const ShoppingCartImg = styled(CartBag)`
    color: #235A91;
&.MuiSvgIcon-root {
  width: 17px;
  height: 15px;
}

@media (max-width: 991px) {
    margin-right: 20px;
    margin-top: -2px;

  &.MuiSvgIcon-root {
    width: 35px;
    height: 35px;
  }
}
`;

export const CircularProgressLoader = styled(CircularProgress)`
    color: #C5C5C5;
`;

export const Typo = styled(Typography)`
    font-size: 30px;
    font-weight: 650;
    margin-top: 5px;

    @media (max-width: 991px) {
        font-size: 50px;
        font-weight: 700;
    }
`;

const isAvailable = css`
  background-color: #7A2ECC;
`;

const isStockOut = css`
  background-color: #e74c3c;
`;

const isMutable = css`
  background-color: #e79800;
`;
const isClearance = css`
  background-color: #235A91;
`;

const getStyles = ({ type }) => {
  switch (type) {
    case '24':
      return isAvailable;
    case '72':
      return isStockOut;
    case 'mutable':
      return isMutable;
    case 'clearanceSale':
      return isClearance;
    default:
      return isAvailable;
  }
};

export const DivBullet = styled('span')`
  height: 8px;
  width: 8px;
  border-radius: 50%;

  @media (max-width: 991px) {
    height: 15px;
    width: 15px;
  }

  ${getStyles}
`;

const getBGColor = (status) => {
  switch (status) {
    case 72:
      return '#fff4f3';
    case 'clearanceSale':
      return '#F3F9FF';
    default:
      return '#F5ECFF';
  }
};

const getTextColor = (status) => {
  switch (status) {
    case 72:
      return '#E42900';
    case 'clearanceSale':
      return '#235A91';
    default:
      return '#7A2ECC';
  }
};

export const ProductVariantText = styled('p')`
  position: absolute;
  right: 30px;
  top: 25px;
  text-align: center;
  font-weight: 500;
  font-weight: 500;
  font-size: 12px;
  padding: 8px 10px;
  color: ${({ status }) => (getTextColor(status))};
  background-color: ${({ status }) => (getBGColor(status))};
  border-radius: 10px;
  border-radius: 5px;

  @media(max-width: 991px) {
    font-size: 1.5rem;
    border-radius: 20px;
    padding: 10px 15px;


  }
`;

export const ButtonWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 4px 20px 0px #00000026;
  background: #FFFFFF;
  padding: 30px 40px;
  width: 100%;
  z-index: 10;
`;

export const PlusIcon = styled(AddFrame)`
&.MuiSvgIcon-root {
  font-size: 3rem;
}

@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    font-size: 7.5rem;
  }
}
`;

export const MinusIcon = styled(MinusFrame)`
  &.MuiSvgIcon-root {
    font-size: 3rem;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      font-size: 7.5rem;
    }
  }
`;
