const product = {
  products: [],
  totalCount: 0,
  expiryCount: 0,
  inventoryCount: 0,
  refetch: null,
  addProductDialogOpen: false,
  productRow: null,
  summary: {}
};

export default product;
