import { gql } from '@apollo/client';

export const BANNERS_PAGE_QUERY = gql`
  query{
    bannerPages{
      id
      page
      meta
      totalBanner
    }
}
`;

export const BANNERS_DETAIL = gql`
query bannerPageDetail($page: String!) {
  bannerPageDetail(page: $page ){
    id
    meta
    }
}
`;
