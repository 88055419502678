import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Box } from '@mui/material';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import { INVENTORY_DASHBOARD } from '../../../queries/dashboard';
import { numberFormater } from '../../shared/utils';
import {
  TotalProductInInventory,
  Enabled,
  Disabled,
  SoldOut
} from '../../../assets/svgs';
import CardLoader from '../../shared/cardLoader';

const inventoryActivity = [
  {
    id: 0,
    icon: TotalProductInInventory,
    title: 'Total Inventory Product',
    total: '',
    link: ''
  },
  {
    id: 1,
    icon: Enabled,
    title: 'Enabled',
    total: '',
    link: ''
  },
  {
    id: 2,
    icon: Disabled,
    title: 'Disabled',
    total: '',
    link: ''
  },
  {
    id: 3,
    icon: SoldOut,
    title: 'Sold Out',
    total: '',
    link: ''
  }
];

const ActivityFourColCard = () => {
  const { loading, error, data } = useQuery(INVENTORY_DASHBOARD, {
    fetchPolicy: 'cache-and-network'
  });

  const [result, setResult] = useState(null);
  const [toggle, setToggle] = useState(true);

  if (!loading && !error && toggle) {
    setResult(Object.values(data?.inventoryDashboard));
    setToggle(false);
  }

  if (loading || error) return <CardLoader cols={4} />;
  return (
    <Grid container spacing={2} style={{ marginTop: '.7rem' }}>
      {inventoryActivity?.map((card, index) => (
        <Grid item xs={6} xl={3} key={card?.id}>
          <Box p={4} bgcolor="background.paper" borderRadius={8}>
            <Box display="flex" alignItems="center">
              <card.icon fontSize="large" />
              <Box fontWeight="fontWeightMedium" marginX={2} fontSize={15}>
                {card?.title}
              </Box>
            </Box>
            <Grid>
              <Box
                marginTop={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box fontWeight="fontWeightBold" fontSize={22} color="inherit">
                  {result && numberFormater(result[index])}
                </Box>
                <Box
                  paddingX={1.2}
                  paddingY={1}
                  borderRadius={4}
                  style={{ background: '#E6EAF0' }}
                >
                  <ArrowForwardIosRounded style={{ fontSize: '.8rem' }} />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default ActivityFourColCard;
