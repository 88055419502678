import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { useQuery } from '@apollo/client';
// import dayjs from 'dayjs';
import { TOP_SELLING_PRODUCTS } from '../../../queries/dashboard';
import TableLoader from '../../shared/tableLoader';
import TablePagination from '../../shared/tablePagination';
import { numberFormater } from '../../shared/utils';
import ActionButton from './actionButton';
import {
  TableHeadStyled, TableRowStyled, TableCellStyled,
  TableCellBStyled
} from '../dashboardContainer.styles';

const ProductTable = ({ dateFilter }) => {
  // Begin pagination props states
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [sort, setSort] = useState('value');
  // End pagination props states

  const { dateFrom, dateTo } = dateFilter;

  // sort = value or count

  const { loading, error, data } = useQuery(TOP_SELLING_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageNumber,
      pageCount,
      dateFrom,
      dateTo,
      sort
    }
  });

  const sortHandler = (val, setOpen) => {
    setSort(val);
    setOpen(false);
  };

  const totalRecord = data?.topSellingProductsTotalNumber || 0;

  return (
    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
      <Grid item xl={12}>
        <Box p={4} bgcolor="background.paper" borderRadius={8}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Box fontWeight="fontWeightBold" fontSize={18} color="inherit">Top Selling Products</Box>
            </Grid>
            <Grid item>
              <Box fontSize={16} color="inherit">
                <ActionButton sortHandler={sortHandler} />
              </Box>
            </Grid>
          </Grid>

          {loading || error ? (
            <TableLoader rows={5} />
          ) : (
            <>
              <TableContainer style={{ marginTop: '2rem' }}>
                <Table>
                  <TableHeadStyled>
                    <TableRow>
                      {/* <TableCellStyled>Created Date</TableCellStyled> */}
                      <TableCellStyled>Product Name</TableCellStyled>
                      <TableCellStyled>Unit Price</TableCellStyled>
                      <TableCellStyled>Pack Size</TableCellStyled>
                      <TableCellStyled>Total Value</TableCellStyled>
                      <TableCellStyled>Qty in Stock</TableCellStyled>
                      <TableCellStyled>Qty Sold in Pcks</TableCellStyled>
                    </TableRow>
                  </TableHeadStyled>
                  <TableBody>
                    {data?.topSellingProducts.length > 0 ? (
                      data?.topSellingProducts?.map((product, index) => (
                        <TableRowStyled
                          cond={index % 2 !== 0}
                          key={product.createdDate}
                        >
                          {/* const createdate = new Date(product.createdDate); */}

                          {/* <TableCellBStyled>
                              {dayjs(createdate).format('DD MM, YY h:mm A')}
                            </TableCellBStyled> */}
                          <TableCellBStyled>{product?.brandName}</TableCellBStyled>
                          <TableCellBStyled>
                            NGN
                            {' '}
                            {` ${numberFormater(product?.price)}`}
                          </TableCellBStyled>
                          <TableCellBStyled>{product?.packSize}</TableCellBStyled>
                          <TableCellBStyled>
                            NGN
                            {' '}
                            {` ${numberFormater(product.averageSellingPrice * parseInt(product?.frequency, 10))}`}
                          </TableCellBStyled>
                          <TableCellBStyled>{product?.qtyInStock}</TableCellBStyled>
                          <TableCellBStyled>{product?.qtySold}</TableCellBStyled>
                        </TableRowStyled>
                      ))

                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>No Product Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {data?.topSellingProducts.length > 0 && (
                <TablePagination
                  total={totalRecord}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

ProductTable.propTypes = {
  dateFilter: PropTypes.isRequired
};

export default ProductTable;
