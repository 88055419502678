import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItemText } from '@mui/material';
import { GridContainer, CustomListItem } from './savePopper.styles';
import { RenderPopper } from '../../../utils/utils';

const SavePopper = ({
  saveButtonEl,
  setSaveButtonEl,
  handleSave,
  handleSaveContinue,
}) => {
  const open = Boolean(saveButtonEl);

  return (
    <RenderPopper
      open={open}
      anchorEl={saveButtonEl}
      onClickAway={() => setSaveButtonEl(null)}
      popperPlacement="right-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <GridContainer container item>
        <List component="nav" aria-label="save options">
          <CustomListItem button onClick={() => handleSaveContinue()}>
            <ListItemText primary="Add another" />
          </CustomListItem>
          <CustomListItem button onClick={() => handleSave()}>
            <ListItemText primary="Close" />
          </CustomListItem>
        </List>

      </GridContainer>
    </RenderPopper>
  );
};

SavePopper.propTypes = {
  saveButtonEl: PropTypes.instanceOf(Object),
  setSaveButtonEl: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSaveContinue: PropTypes.func.isRequired,
};

SavePopper.defaultProps = {
  saveButtonEl: {},
};

export default SavePopper;
