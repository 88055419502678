import React from 'react';
import {
  PrimaryTitle,
  SecondaryTitle,
  TitleGridContainer, TitleTextGridContainer
} from './titleGrid.styles';

export function TitleGrid() {
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Roles & Permissions
        </PrimaryTitle>
        <SecondaryTitle>
          View and update your business user permissions
        </SecondaryTitle>
      </TitleTextGridContainer>
    </TitleGridContainer>
  );
}

export default TitleGrid;
