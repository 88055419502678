import {
  Grid, Typography, Paper, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowIcon } from '../../assets/svgs';

export const ContainerWrapper = styled(Grid)`
  @media(max-width: 991px) {
    padding: 2rem 2.5rem;
  }
`;

export const BoxWrapper = styled(Grid)`
  @media(max-width: 991px) {
    display: flex;
  }
`;

export const CardContainer = styled(Paper)`
  border-radius: 15px;
  height: 160px;
  padding: 1.5rem 2rem 1rem 2.5rem;
  cursor: pointer;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  @media(max-width: 1320px) {
    padding: 1.5rem 1rem 1rem 1.5rem;
    height: 150px;
  }
  @media(max-width: 991px) {
    width: 100%;
    height: auto;
    padding: 4rem 5rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
  }
`;

export const ViewWrapper = styled(Grid)`
  padding-top: 2rem;
  justify-content: space-between;
  align-items: center;
  @media(min-width: 992px) {
    padding-top: 2rem;
  }

  @media(min-width: 1445px){
    padding-top: 1.8rem;
  }
`;

export const Icons = styled('img')`
  width: 10%;
  cursor: pointer;
`;

export const ArrowIcons = styled('img')`
  width: 13%;
  cursor: pointer;
`;

export const TitleWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const Title = styled(Typography)`
  padding-left: 15px;
  font-weight: 600;
  @media(max-width: 991px) {
    font-size: 2.5rem;
    padding-left: 2rem;
  }
`;

export const Description = styled(Typography)`
  opacity: 0.5;
  font-size: 2.5rem;
  @media(min-width: 992px) {
    font-size: .9rem;
  }

  @media(min-width: 1445px){
    font-size: 1rem;
  }
`;

export const CountBox = styled(Box)`
  margin-left: auto;
`;

export const CountTypo = styled(Typography)`
  font-weight: 600;
  font-size: 1.3rem;

  @media (max-width: 991px) {
    font-size: 2.7rem;
  }
`;

export const Arrows = styled(ArrowIcon)`
&.MuiSvgIcon-root {

}

@media (max-width: 991px) {
  font-size: 6rem;
}
`;
