import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  LogoutTypo, LogoutImage, IconButtonWrapper,
  LogoutIconButtonWrapper, MenuTypo, CPopper, GridContainer, RoleTypo
} from './affiliatesPopper.styles';
import { LOGOUT_MUTATION, SWITCH_ROLE_MUTATION } from '../../mutations/auth';
import { RenderPopper } from '../../utils/utils';
import LogoutIcon from '../../assets/images/navigation/Logout_Icon.png';

import POLogo from '../../assets/images/Group PO Icon.png';
import settingsLogo from '../../assets/images/Setup Icon.png';
import ProfileLogo from '../../assets/images/Profile.png';
import HelpDesk from '../../assets/images/Danger-Circle.png';
import Settings from '../../assets/images/Setting.png';

import erpIcon from '../../assets/images/ERP Icon.png';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import { useStateValue } from '../../providers/stateProvider';
import { STORAGE_KEYS } from '../../utils/constants';
import { goToModule } from '../shared/utils';
import { Logout } from '../../assets/svgs';

const AFFILIATE_MENU = [
  [ProfileLogo, 'Profile Settings', 'account-info'],
  [HelpDesk, 'Help desk', 'help-desk'],
];

const AffiliatesPopper = ({
  affiliatesLogoEl,
  handleAffiliateLogoClick,
}) => {
  const open = Boolean(affiliatesLogoEl);
  const [logoutUser] = useMutation(LOGOUT_MUTATION);
  const [{
    user: {
      allowedPermissionsMap, role, platform
    }
  }, dispatch] = Object.values(useStateValue());
  const [switchRole] = useMutation(SWITCH_ROLE_MUTATION);
  const userRole = localStorage.getItem('user_role');
  const navigate = useNavigate();
  const isMP = platform === 'marketplace';

  const ADMIN_MENU = [
    [POLogo, 'OGApharmacy', 'oga'],
    [erpIcon, 'ERP', 'erp'],
    !isMP && [Settings, 'Setup', 'setup'],
    [HelpDesk, 'Help desk', 'help-desk'],
  ].filter(Boolean);

  const MENU_ITEMS = role === 'affiliate-admin' ? AFFILIATE_MENU : ADMIN_MENU;
  const buildNo = process.env.BUILD_NUMBER;

  const handleLogout = () => {
    const authToken = localStorage.getItem('oga_auth_token');
    const restToken = localStorage.getItem('oga_rest_token');
    logoutUser({
      variables: {
        restToken,
        authToken
      }
    }).then((resp) => {
      dispatch({ type: AffiliateActionTypes.UPDATE_AFFILIATE, payload: { isBusinessVirtualStore: false } });
      const { status, message } = resp.data.logoutUser;
      const storageKeys = [...STORAGE_KEYS];
      if (status) {
        storageKeys.forEach((storageKey) => {
          localStorage.removeItem(storageKey);
        });
        toast.success(message);
        localStorage.removeItem('receive_manual_order');
        setTimeout(() => navigate('/'), 1500);
        setTimeout(() => { document.location = ''; }, 2000);
      }
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const controlAlert = (params) => {
    if (params.alertSeverity === 'error') {
      toast.error('Module not available, coming soon...');
    }
  };

  const handleAccountNavigate = (item) => {
    handleAffiliateLogoClick();
    goToModule(navigate, switchRole, allowedPermissionsMap, controlAlert, item);
  };

  return (
    <RenderPopper
      showArrow
      open={open}
      anchorEl={affiliatesLogoEl}
      onClickAway={() => handleAffiliateLogoClick()}
      popperPlacement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '2.5vw, 1vh',
        }
      }}
      affilite={role === 'affiliate-admin'}
    >
      <GridContainer>
        {MENU_ITEMS.map((menuItem) => (
          <IconButtonWrapper
            key={menuItem[2]}
            onClick={() => handleAccountNavigate(menuItem[2])}
          >
            <LogoutImage alt="logout icon" src={menuItem[0]} />
            <MenuTypo variant="subtitle1">
              {menuItem[1]}
            </MenuTypo>
          </IconButtonWrapper>
        ))}
        <LogoutIconButtonWrapper onClick={() => handleLogout()}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item container alignItems="center" xs={6}>
              {/* <LogoutImage alt="logout icon" src={LogoutIcon} /> */}
              <Logout />
              <LogoutTypo variant="subtitle1">
                Logout
              </LogoutTypo>
            </Grid>
            {role !== 'affiliate-admin' ? (
              <Grid item container alignItems="center" xs={6} direction="column">
                <RoleTypo variant="caption">{`(${userRole})`}</RoleTypo>
                {buildNo ? (
                  <Typography variant="caption">
                    {`Build number: ${buildNo}`}
                  </Typography>
                ) : ''}
              </Grid>
            ) : ''}
          </Grid>
        </LogoutIconButtonWrapper>
      </GridContainer>
    </RenderPopper>
  );
};

AffiliatesPopper.propTypes = {
  affiliatesLogoEl: PropTypes.instanceOf(Object),
  handleAffiliateLogoClick: PropTypes.func.isRequired
};

AffiliatesPopper.defaultProps = {
  affiliatesLogoEl: {},
};

export default AffiliatesPopper;
