import { Version } from './Version';

export class ErpVersion extends Version {
  fixedSellingPrice = 0;

  resolvedPriceInUseValue = 0;

  priceInUse = '';

  sellingPrice = 0;

  orderCost = 0;

  isAVersion = false;

  safetyStock = 0;

  maxLeadTime = 0;

  constructor(rawVersion = {}) {
    super();
    const rawKeys = Object.keys(rawVersion);
    for (let key = 0; key < rawKeys.length; key++) {
      this[rawKeys[key]] = rawVersion[rawKeys[key]];
    }
  }
}
