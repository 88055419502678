import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import {
  TextFieldBox, FilterGrid, FilterText,
  MenuButton, CTextField, SetButton, ClearText, ArrowImg
} from './mobileFilter.styles';
import backImg from '../../../../assets/images/marketOrder/Arrow.png';
import { useStateValue } from '../../../../providers/stateProvider';
import CustomNewTextField from '../../../shared/CustomNewTextField';
import { BUSINESS_CATEGORIES } from '../../../../queries/categories';

const MobileFilter = ({
  state, handleFilterChange, handleSetFilter, handleMobile, handleClear
}) => {
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const textField = (field, select) => {
    const { status } = state;

    const openOrderStatus = [
      { name: 'All Orders', value: 'All' },
      { name: 'Dispatched', value: 'order_dispatch' },
      { name: 'Order Received', value: 'Received' },
      { name: 'Order Sorted', value: 'Sorted' },
      { name: 'Order Sent', value: 'order_sent' },
      { name: 'Ready For Dispatch', value: 'queued' },
    ];

    const closeOrderStatus = [
      { name: 'All Orders', value: 'All' },
      { name: 'Cancelled', value: 'cancel' },
      { name: 'Closed', value: 'closed' },
      { name: 'Returned', value: 'order_returned' },
    ];

    const returnType = (name) => {
      if (select) return;
      switch (name) {
        case 'dateFrom':
        case 'dateTo':
          return 'date';
        case 'orderTotalFrom':
        case 'orderTotalTo':
        case 'orderNo':
          return 'number';
        default:
          return 'text';
      }
    };
    const orderStatus = status === 'open' ? openOrderStatus : closeOrderStatus;
    const {
      name: fieldName, label, shrink
    } = field;
    const _value = state[fieldName];

    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          label={label}
          size="large"
          name={fieldName}
          value={_value}
          onChange={handleFilterChange}
          type={returnType(fieldName)}
          className={`mpAdmin-uat-ordersPageFilter-${fieldName}`}
          select={select}
          fullWidth
          InputProps={{
            style: { height: '8rem', fontSize: '2.5rem', borderRadius: '2.5rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '2rem', marginTop: '15px' },
            shrink
          }}
          SelectProps={select && {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  borderRadius: '10px'
                }
              }
            }
          }}
        >
          {select && orderStatus.map(({ name, value }) => (
            <MenuItem key={value} value={value} style={{ fontSize: '2rem' }}>
              {name}
            </MenuItem>
          ))}
        </CTextField>
      </TextFieldBox>
    );
  };

  const affiliateInput = [
    { name: 'orderNo', label: 'Order Number' },
    { name: 'businessName', label: 'Affiliate' },
  ];
  const dateInput = [
    { name: 'dateFrom', label: 'From', shrink: true },
    { name: 'dateTo', label: 'To', shrink: true }
  ];
  const statusField = { name: 'orderStatus', label: 'Order Status' };
  const select = true;
  const handleFilterSet = () => {
    handleSetFilter();
    handleMobile();
  };

  const handleClearFilter = () => {
    handleClear();
    handleMobile();
  };
  const { data } = useQuery(BUSINESS_CATEGORIES, {
    fetchPolicy: 'no-cache',
    pageCount: 10,
    pageNumber: 1,
  });

  const category = data?.businessCategories?.map((item) => {
    const { categoryName } = item;
    return { name: categoryName, value: categoryName };
  }) || [];

  return (
    <Box sx={{ marginTop: '2rem', width: '100%' }}>
      <FilterGrid container>
        <FilterGrid item xs={4}>
          <MenuButton>
            <ArrowImg src={backImg} alt="back" onClick={handleMobile} />
          </MenuButton>
        </FilterGrid>
        <FilterGrid item xs={4}>
          <FilterText>Filter</FilterText>
        </FilterGrid>
        <FilterGrid item xs={4}>
          <ClearText data-testid="clearFilter" onClick={handleClearFilter}>Clear</ClearText>
        </FilterGrid>
      </FilterGrid>
      {affiliateInput.map((fields) => (
        textField(fields)
      ))}
      <TextFieldBox>
        {textField(statusField, select)}
      </TextFieldBox>
      {dateInput.map((fields) => (
        textField(fields)
      ))}
      <CustomNewTextField
        field={{ name: 'businessCategory', label: 'Enter Customer Category' }}
        state={state}
        select
        handleChange={handleFilterChange}
        style={{ width: '100%', height: '9rem' }}
        className="mpAdmin-uat-ordersPageFilter-customerCategory"
      >
        {category.map(({ name, value }) => (
          <MenuItem key={value} value={value} style={{ padding: '1rem 0.5rem', fontSize: '2rem' }}>
            {name}
          </MenuItem>
        ))}
      </CustomNewTextField>
      <SetButton
        variant="contained"
        fullWidth
        data-testid="setFilter"
        onClick={handleFilterSet}
        disabled={!userPermissions?.includes('manage_orders_set_filter')}
        style={{
          backgroundColor: '#235A91', borderRadius: '15px',
          color: '#fff', height: '7rem', fontSize: '3rem'
        }}
      >
        Set Filter
      </SetButton>
    </Box>
  );
};

MobileFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  handleMobile: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
};

MobileFilter.defaultProps = {
  state: {},
};

export default MobileFilter;
