import ProductActionTypes from './productTypes';

const productReducer = (product, action) => {
  switch (action.type) {
    case ProductActionTypes.UPDATE_PRODUCT:
      return {
        ...product,
        ...action.payload
      };
    case ProductActionTypes.TOGGLE_DIALOG_OPEN:
      return {
        ...product,
        addProductDialogOpen: !product.addProductDialogOpen
      };
    default:
      return product;
  }
};

export default productReducer;
