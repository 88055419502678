import { TextField, CircularProgress } from '@mui/material';

import { styled } from '@mui/material/styles';

export const CTextField = styled(TextField)`
  font-size: .75rem;
  & .MuiOutlinedInput-inputMarginDense {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  & .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  & .MuiInputBase-root {
    font-size: .8rem;
    height: 2.938rem;
  }
  & .MuiFormLabel-root {
    font-size: .75rem;
    font-weight: 500;
    line-height: 1.45rem;
  }

  @media(max-width: 991px) {
    & .MuiFilledInput-input {
      font-size: 2rem;
      height: 3.8rem;
      padding: 35px 20px 10px;
    }
    & .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(20px, 12px) scale(1.5);
    }

    & .MuiInputBase-root {
    font-size: 2rem;
    }

    & .MuiInputBase-root {
      font-size: 2rem;
      height: 4.6rem;
      border-radius: 20px
    }
  }
`;

export const SearchImg = styled('img')`
  width: 20px;

  @media(max-width: 991px) {
     width: 30px;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #606060;
  margin-top: 4px;
`;
