import {
  Grid, Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const TitleGridWrapper = styled(Grid)`
    padding: 30px;
`;

export const OutletItems = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

export const OutletContentWrapper = styled(Grid)`
    display: flex;
    flex-wrap: no-wrap;
    padding: 30px 30px 10px;
`;
export const OutletWrapper = styled(Grid)`
  width: 100%;
  flex-direction: column;
  min-height: 73vh;
  margin-left:35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const OutletItem = styled(Grid)`
  margin: 0 20px;
`;

export const FooterGrid = styled(Grid)`
  display: flex;
  height: 75px;
  justify-content: flex-end;
  padding-left: 20px;
  align-items: center;
`;
export const OrderCountText = styled(Typography)`
  font-size: 22px;
  color: #4D4F5C;
  margin: 14px 0;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;
