import {
  Button,
  Grid,
  TableCell,
  Typography
} from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 6%;
    }
    &:nth-child(2), &:nth-child(3) {
      width: 26%;
    }
    &:nth-child(5) {
      width: 10%;
    }
    &:nth-child(4), &:nth-child(6) {
      width: 15%;
    }
    &:nth-child(7) {
      width: 2%;
    }
  }
`;

const PendingStyles = css`
  background-color: #FBF777;
  color: #606060;
`;

const CompletedStyles = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;
const FailedStyles = css`
  background-color: #FBDADA;
  color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'completed':
      return CompletedStyles;
    case 'failed':
      return FailedStyles;
    default:
      return PendingStyles;
  }
};

export const ColorGrid = styled(Grid)`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: .85rem;
  font-weight: 500;
  justify-content: center;
  padding: 1px 0;

  ${getStyles}
`;

export const ActionButton = styled(Button)`
  width: 170px;
  height: 45px;
  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;

  &:hover {
    background-color: #235A91;
    color: #fff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  padding-left: 1rem;
`;
