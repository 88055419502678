import {
  TableCell, Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 26px;
  padding: 10px;
  color: #606060;

  @media(min-width: 992px) {
    font-size: 1rem;

    &:nth-child(1) {
      width: 40%;
      padding-left: 10px;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      width: 20%;
      cursor: pointer;
    }
  }

  @media(max-width: 991px) {
    padding: 8px;
    &:nth-child(1) {
      width: 50%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
`;
