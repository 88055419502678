import React, { useState } from 'react';
import Proptypes from 'prop-types';
import Fade from '@mui/material/Fade';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import toast from 'react-hot-toast';
import axios from '../../../axios';
import UploadFileBar from '../../shared/fileBar';

import DropZone from './affiliateCsvDropzone';
import {
  DialogCancelButton, DialogOkButton,
  DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper,
  DialogContentWrapper, DividerLine,
  CircularProgressLoader, DropzoneWrapper,
  UploadFileWrapper, CsvTextWrapper
} from './affiliatecsvDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function SuppliercsvDialog({
  dialogOpen, closeDialog
}) {
  const initialProgressState = {
    width: 0,
    state: 'ok'
  };

  const [files, setFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadBox, setUploadBox] = useState(true);
  const [progressState, setprogressState] = useState(initialProgressState);

  const handleFile = (file) => {
    setFiles(file[0]);
    if (file[0]) {
      setUploadBox(false);
      setprogressState(initialProgressState);
    }
  };

  const uploadFiles = () => {
    if (!files) {
      toast.error('please upload an affiliate csv before you proceed');
      return;
    }

    const formdata = new FormData();
    formdata.append('file', files);

    setLoading(true);
    const widthInterval = setInterval(() => {
      setprogressState((state) => ({ ...state, width: (state.width + 1) % 100 }));
    }, 300);

    axios.post('/upload/affiliates', formdata)
      .then((res) => {
        const response = res.data;
        const message = `
          ${response.created} affiliate(s) created,
          ${response.updated} affiliate(s) updated and
          ${response.error} error(s) encountered
        `;
        toast.success(message);
        clearInterval(widthInterval);
        setprogressState({ state: 'ok', width: 100 });
      })
      .catch(() => {
        toast.error('Kindly check for affiliate duplicates in your csv, your internet connection, then logout and login again');
        clearInterval(widthInterval);
        setprogressState({ state: 'err', width: 100 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
      fileslimit={1}
    >

      <DialogTitle id="alert-dialog-slide-title">
        <DialogTitleWrapper container>
          <DialogTitleText>
            Add Affiliate
          </DialogTitleText>
          <DialogTitleSubText>
            Upload CSV file to add multiple affiliatess
          </DialogTitleSubText>
        </DialogTitleWrapper>
      </DialogTitle>

      <DialogContent>
        <DialogContentWrapper>

          <DividerLine />
          <CsvTextWrapper>
            <DialogTitleSubText>
              Download a sample template to use for your upload
            </DialogTitleSubText>
          </CsvTextWrapper>
          <UploadFileBar
            csvName="Supplier affiliate template.csv"
            csvSize="1.5 MB"
            action="download"
          />
          <DividerLine />
          {
            uploadBox ? (
              <DropzoneWrapper>
                <DropZone
                  handleFile={handleFile}
                />
              </DropzoneWrapper>
            ) : (
              <UploadFileWrapper>
                <UploadFileBar
                  csvName={files.name.slice(0, 100)}
                  csvSize={`${files.size / (1024 ** 2)} KB`}
                  action="delete"
                  onDelete={() => {
                    setUploadBox(true);
                  }}
                  percent={progressState.width}
                  state={progressState.state}
                />
              </UploadFileWrapper>
            )
          }

        </DialogContentWrapper>
      </DialogContent>

      <DialogActions>
        <DialogCancelButton
          onClick={closeDialog}
        >
          <DialogActionButtonText>
            Cancel
          </DialogActionButtonText>
        </DialogCancelButton>

        <DialogOkButton
          onClick={uploadFiles}
        >
          {loading ? (
            <CircularProgressLoader
              disableShrink
              size={22}
              thickness={5}
            />
          ) : (
            <DialogActionButtonText>
              Add Affiliate
            </DialogActionButtonText>
          )}
        </DialogOkButton>
      </DialogActions>
    </Dialog>
  );
}

SuppliercsvDialog.propTypes = {
  dialogOpen: Proptypes.bool.isRequired,
  closeDialog: Proptypes.func.isRequired,
};
