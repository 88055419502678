import {
  Dialog, Grid, Typography, IconButton, CircularProgress, TextField, Box,
  Button, DialogContent, AccordionDetails, Accordion, AccordionSummary
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
  }

  & .MuiDialog-paperFullWidth {
    width: 40rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 40rem !important;
  padding: 4rem 1.5rem 3rem 1.5rem;
  position: relative;
`;

export const CloseStyled = styled('div')`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 0.5rem 1.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 2.5rem 1.5rem;
    }
  }
`;

export const DialogTitleStyled = styled('span')`
  font-weight: 700;
  font-size: 18px;
  color: #303030;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 1.5rem;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 14px;
  color: #606060;
`;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #fced50;
  color: #424242;

  &:hover {
    background-color: #d2c647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  border: 2px solid #424242;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const Header = styled(AccordionSummary)`
  font-size: 18px;
  margin: 0px 20px;
  border-bottom: 1px solid #E7E8E9;
  &.MuiAccordionSummary-root {
    padding: 0px 0px;
  }
`;
export const AccordionContainer = styled(Accordion)`
  margin: 10px 0px;
  &.MuiPaper-elevation1 {
    border: 1px solid #E7E8E9;
    box-shadow: 0px 6px 24px rgba(32, 30, 93, 0.08);
}
`;

export const AccordionDetailsWrapper = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 0px 16px 16px;
  }
`;

export const TextFieldBox = styled(Box)`
  margin-bottom: 1.3rem;
  width: 100%;
  display: block;
`;

export const CTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 2rem;
  }
`;

export const FieldHeader = styled(Typography)`
  font-size: 1rem;
  margin-bottom: .7rem;
`;
