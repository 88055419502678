import {
  Typography, Checkbox, FormControl, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CFormControl = styled(FormControl)`
  & .MuiFilledInput-root {
    background-color: transparent;
    border: 0.5px solid #d8d8d8;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #606060;
    height: 51px;

    &::before {
      content: " ";
      border-bottom: 0;
    }
  }
  & .MuiFilledInput-inputMarginDense {
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

export const Label = styled(Typography)`
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
  margin-bottom: .2rem;
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;

export const MenuItems = styled(MenuItem)`
  &.Mui-selected, .Mui-selected:hover {
    background-color: #F3F9FF;
  }
  &:hover {
    background-color: #F3F9FF;
  }
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  color: #ff0000;
  opacity: 0.8;
  margin-left: 10px;
`;
