import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import Dispatch from './dispatch';
import { MP_ORDER_QUERY } from '../../../queries/dispatch';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const DispatchContainer = () => {
  const { id } = useParams();
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [searchText, setSearchText] = useSearchParamsState('search', '');
  const {
    loading, data, refetch
  } = useQuery(MP_ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      orderId: id,
      pageCount, pageNumber,
      productName: searchText.length >= 3 ? searchText : ''
    },
  });

  const orderDeliveryLocations = data?.orderDeliveryLocations || [];
  const businessOrder = data?.businessOrder || {};
  const orderProducts = data?.orderProducts || {};
  const orderProductsTotalNumber = data?.orderProductsTotalNumber || 0;

  return (
    <Dispatch
      businessDeliveryLocations={orderDeliveryLocations}
      orderData={businessOrder}
      refetch={refetch}
      ordId={id}
      orderProducts={orderProducts}
      orderProductsTotalNumber={orderProductsTotalNumber}
      setPageCount={setPageCount}
      pageCount={+pageCount}
      setPageNumber={setPageNumber}
      pageNumber={+pageNumber}
      pLoading={loading}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

DispatchContainer.propTypes = {
};

DispatchContainer.defaultProps = {
};

export default DispatchContainer;
