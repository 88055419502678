import { styled } from '@mui/material/styles';
import {
  Grid, Typography, IconButton, CircularProgress
} from '@mui/material';

export const DialogWrapperContainer = styled(Grid)`
  padding: 20px;
  background: #FFFFFF;
`;

export const DialogHeaderContainer = styled(Grid)`

`;

export const DialogHeaderText = styled(Typography)`
  font-weight: 500;
  color: #424242;
  font-size: 1.25rem;
`;
export const DialogHeaderSubText = styled(Typography)`
  font-size: .95rem;
  color: #424242;
  margin-bottom: 15px;
`;

export const DialogFooterContainer = styled(Grid)`
`;

export const DialogDoneButton = styled(IconButton)`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  margin-right: 7px;
  margin-left: 8px;
  background-color: rgb(252, 237, 80);
  color: rgb(66, 66, 66);

  &.MuiIconButton-root.Mui-disabled {
    color: rgb(66, 66, 66);
    background-color: rgb(252, 237, 80);
  }

  &:hover {
    background-color: #D2C647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px;
    color: rgb(66, 66, 66);
    border-radius: 10px;
    margin-right: 7px;
    border: 1px solid rgb(238, 238, 238);
`;

export const DialogActionButtonText = styled('p')`
  font-size: 16px;
  font-family: 'Montserrat';
  margin-bottom: 0;
`;

export const DialogContentText = styled('p')`
  color: #EE0101;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;
