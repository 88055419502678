import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import _ from 'lodash';
import { Grid, TableBody, } from '@mui/material';
import ProductItem from './product-item/index';
import {
  SupplierProductsCardFooter,
  SupplierProductsCardFooterText, FieldCheckbox, CheckboxImage, TableWrapper, TableComponent, TableHeader, MainTableRow
} from '../../cart/cart.styles';

import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../assets/images/supplier/Checkbox-resting.png';
import {
  CancelButton, CancelHeader, FooterGrid, Header, OverviewFooterText, SupplierCardPaper
} from './dispatchContainer.styles';
import Filter from './filter';
import { DropDown } from '../../../assets/svgs';
import { useStateValue } from '../../../providers/stateProvider';
import TablePagination from '../../shared/tablePagination';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const SupplierItems = ({
  state,
  handleChangeCondition,
  handleIncrement,
  handleDecrement,
  handleChangeProductQuantity,
  handleMetaStatusUpdate,
  quantityRecieve,
  handleSearch,
  handleCancelOrderDialog,
  handleBatchSelection,
  handleConfirmedQtyInputValue,
  handleQtyConfirmedIncrement,
  handleQtyConfirmedDecrement,
  qtyConfirmed,
  viewProduct,
  pageCount,
  pageNumber,
  setPageCount,
  setPageNumber,
  orderProductsTotalNumber,
  pLoading,
  searchText,
  handleNextPage,
  handleNextCount
}) => {
  const {
    cart, sortStatuses, orderStatus
  } = state;

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());
  const renderSupplierContainers = () => {
    const copyCart = cart.map((item) => {
      item.supplierName = item.supplier ? item.supplier.name : '';
      return item;
    });
    const supplierCategories = _.groupBy(copyCart, 'supplierName');
    return Object.entries(supplierCategories).map(([key, value]) => {
      const { orderSupplierSetId } = value[0];
      const { supplierOrderStatus } = value[0];
      const recieved = supplierOrderStatus === 'SUPPLIER_ORDER_RECEIVED';

      if (!recieved) {
        handleMetaStatusUpdate(orderSupplierSetId, true, true);
      }
      return (
        <Grid
          container
          key={orderSupplierSetId}
          style={sortStatuses[orderSupplierSetId]
            ? { opacity: 0.4, cursor: 'not-allowed' }
            : {}}
        >
          <TableWrapper>
            <TableComponent aria-label="affiliates table">
              <Hidden mdDown>
                <TableHeader>
                  <MainTableRow item container>
                    {orderStatus !== 'ORDER_CANCELED'
                      ? (
                        <>
                          <Header>Product Items</Header>
                          <Header>Qty in Stock</Header>
                          <Header>Qty Ordered</Header>
                          <Header>Qty Confirmed</Header>
                          <Header>Qty Sent</Header>
                          <Header>Condition</Header>
                          <Header>Not Received</Header>
                          <Header>Action</Header>
                        </>
                      ) : (
                        <>
                          <CancelHeader>Product Items</CancelHeader>
                          <CancelHeader>Qty in Stock</CancelHeader>
                          <CancelHeader>Qty Ordered</CancelHeader>
                          <CancelHeader>Qty Confirmed</CancelHeader>
                          <CancelHeader>Qty Sent</CancelHeader>
                          <CancelHeader>Condition</CancelHeader>
                        </>
                      )}
                  </MainTableRow>
                </TableHeader>
              </Hidden>
              <TableBody>
                {value.filter((p) => p.quantity > 0).map((productItem) => (
                  <ProductItem
                    key={productItem.id}
                    product={productItem}
                    selectedOutlet={state.selectedOutlet}
                    handleConditionChange={handleChangeCondition}
                    incrementProductQuantity={handleIncrement}
                    decrementProductQuantity={handleDecrement}
                    changeInputQuantity={handleChangeProductQuantity}
                    quantityRecieve={quantityRecieve}
                    handleBatchSelection={handleBatchSelection}
                    orderStatus={orderStatus}
                    handleQtyConfirmedDecrement={handleQtyConfirmedDecrement}
                    handleConfirmedQtyInputValue={handleConfirmedQtyInputValue}
                    handleQtyConfirmedIncrement={handleQtyConfirmedIncrement}
                    qtyConfirmed={qtyConfirmed}
                    viewProduct={viewProduct}
                  />
                ))}
              </TableBody>
            </TableComponent>
          </TableWrapper>
          <FooterGrid item container>
            <TablePagination
              total={orderProductsTotalNumber}
              pageCount={pageCount}
              setPageCount={handleNextCount}
              pageNumber={pageNumber}
              setPageNumber={handleNextPage}
              options={[10, 20, 50, 100]}
            />
          </FooterGrid>

          <SupplierProductsCardFooter container>
            {orderStatus !== 'ORDER_CANCELED'
              ? (
                <>
                  <Grid item container xs={5}>
                    {!viewProduct && (
                      <CancelButton
                        onClick={(e) => handleCancelOrderDialog(e)}
                        md={3}
                        disabled={!userPermissions?.includes('manage_orders_cancel_order')}
                      >
                        <OverviewFooterText>
                          Cancel Order
                        </OverviewFooterText>
                        <DropDown style={{ fontSize: '.9rem', color: '#235A91' }} />
                      </CancelButton>
                    )}
                  </Grid>
                  <Grid container xs={7} justifyContent="flex-end">
                    <SupplierProductsCardFooterText>
                      {recieved ? 'Mark as complete' : 'Order Not Recieved' }
                    </SupplierProductsCardFooterText>
                    {recieved && (
                      <FieldCheckbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={sortStatuses[orderSupplierSetId]}
                        disabled={viewProduct}
                        onClick={
                          (e) => handleMetaStatusUpdate(orderSupplierSetId, e.target.checked)
                        }
                      />
                    )}
                  </Grid>
                </>
              ) : (
                ''
              )}
          </SupplierProductsCardFooter>
        </Grid>
      );
    });
  };

  return (
    <SupplierCardPaper
      elevation={2}
    >
      <Filter
        searchText={searchText}
        handleSearch={handleSearch}
      />
      {pLoading ? <ProductsTableLoader /> : renderSupplierContainers()}
    </SupplierCardPaper>
  );
};

SupplierItems.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  handleChangeCondition: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleChangeProductQuantity: PropTypes.func.isRequired,
  handleMetaStatusUpdate: PropTypes.func.isRequired,
  quantityRecieve: PropTypes.instanceOf(Array).isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleCancelOrderDialog: PropTypes.func.isRequired,
  handleBatchSelection: PropTypes.func.isRequired,
  handleConfirmedQtyInputValue: PropTypes.func.isRequired,
  handleQtyConfirmedIncrement: PropTypes.func.isRequired,
  handleQtyConfirmedDecrement: PropTypes.func.isRequired,
  qtyConfirmed: PropTypes.instanceOf(Array).isRequired,
  viewProduct: PropTypes.bool.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handleNextCount: PropTypes.func.isRequired
};

export default SupplierItems;
