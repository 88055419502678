import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';

import { BUSINESS_ROLES_QUERY } from '../../../../queries/businesses';
import { JSONParse } from '../../../../utils/json';
import ReturnSelectField from './returnSelectField';
import SavePopper from './savePopper';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader,
  GridWrapper, TopGrid, SupplierTextField,
} from './addUserDialog.styles';
import { CREATE_OUTLET_USER, UPDATE_BUSINESS_USER } from '../../../../mutations/outlets';

import { validateEmail, validateNigerianNumber } from '../../../auth/utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));
const today = moment().format('YYYY-MM-DD');

const initialState = {
  firstName: '',
  lastName: '',
  mobile: '+234',
  emailAddress: '',
  startingDate: today,
  role: '',
  jobTitle: '',
  businessUserId: '',
  businessId: ''
};
const initialErrorState = {
  firstNameError: false,
  lastNameError: false,
  emailError: false,
  mobileError: false,
  jobTitleError: false,
};

const AddUserDialog = ({
  dialogOpen, closeDialog, info, businessId
}) => {
  const [createOutletUSer] = useMutation(CREATE_OUTLET_USER);
  const [updateBusinessUSer] = useMutation(UPDATE_BUSINESS_USER);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (info && info.id) {
      const {
        id, businessRole, meta, user, business
      } = info;
      const { role } = businessRole;
      const { profile, contacts } = user;
      const { job_title: jobTitle, starting_date: startingDate } = JSONParse(meta);
      const { last_name: lastName, first_name: firstName } = JSONParse(profile);
      const { email, mobile_number: mobile } = JSONParse(contacts);

      setState({
        ...state,
        firstName,
        lastName,
        mobile,
        emailAddress: email,
        startingDate,
        role: role?.name,
        outlet: business?.name,
        jobTitle,
        businessUserId: id,
        businessId: business?.id
      });
    }
  }, [info]);

  const { data } = useQuery(BUSINESS_ROLES_QUERY, {
    variables: { businessId },
    fetchPolicy: 'cache-and-network'
  });
  useEffect(() => {
    if (data && data.businessRoles) {
      setRoles(data?.businessRoles.map((bizRole) => bizRole?.role));
    }
  }, [data]);

  const {
    firstNameError, lastNameError, emailError, mobileError, jobTitleError
  } = errorState;

  const validateState = (name, value) => {
    switch (name) {
      case 'firstName':
        return setErrorState({
          ...errorState,
          firstNameError: !(value.length >= 3)
        });
      case 'lastName':
        return setErrorState({
          ...errorState,
          lastNameError: !(value.length >= 3)
        });
      case 'emailAddress':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: validateNigerianNumber(value)
        });
      case 'jobTitle':
        return setErrorState({
          ...errorState,
          jobTitleError: !(value.length >= 3)
        });
      default:
        return null;
    }
  };

  const handleCloseDialog = () => {
    closeDialog();
  };

  const mapRoleNameToId = (roleName) => (
    roles.filter((role) => role?.name === roleName)[0]?.id
  );

  const handleBusinessUser = (addMore = false) => {
    const {
      firstName, lastName, mobile, emailAddress: email,
      startingDate, role, jobTitle, businessUserId: userId
    } = state;
    const userMutation = userId
      ? updateBusinessUSer
      : createOutletUSer;

    userMutation({
      variables: {
        ...(userId && { businessUserId: +userId }),
        firstName,
        lastName,
        mobile,
        email,
        startingDate,
        roleId: mapRoleNameToId(role),
        jobTitle,
      },
    })
      .then(() => {
        const message = userId
          ? 'Successfully updated User'
          : 'Successfully added User';
        toast.success(message);
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        console.log('err:>>', err);
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    const { firstName } = state;
    if (firstName.length < 3) {
      return setErrorState({
        ...errorState,
        firstNameError: true
      });
    }
    setLoading(true);
    handleBusinessUser(addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'firstName',
      label: 'First Name',
      helperText: 'First Name should be 3 or more characters'
    },
    {
      name: 'lastName',
      label: 'Last Name',
      helperText: 'Last Name should be 3 or more characters'
    },
    {
      name: 'emailAddress',
      label: 'Email',
      helperText: 'Please enter a valid email address'
    },
    {
      name: 'mobile',
      label: 'Mobile',
      helperText: 'Please enter a valid phone number'
    },
    {
      name: 'role',
      label: 'Role',
      options: roles?.map(({ name }) => name)
    },
    {
      name: 'jobTitle',
      label: 'Job Title',
      helperText: 'Job Title should be 3 or more characters'
    },
    {
      name: 'startingDate',
      label: 'Starting Date',
    }
  ];

  // const bottomFields = [];

  const handleSaveButtonClick = (event) => {
    const {
      firstName, lastName, emailAddress, mobile, jobTitle, role, businessUserId
    } = state;
    if (firstName.length < 3) return setErrorState({ ...errorState, firstNameError: true });
    if (lastName.length < 3) return setErrorState({ ...errorState, lastNameError: true });
    if (validateEmail(emailAddress)) return setErrorState({ ...errorState, emailError: true });
    if (validateNigerianNumber(mobile)) return setErrorState({ ...errorState, mobileError: true });
    if (jobTitle.length < 3) return setErrorState({ ...errorState, jobTitleError: true });
    if (!role.length) return toast.error('Please select a Role');

    if (businessUserId) return handleBusinessUser();
    return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
  };

  const renderError = (name) => {
    switch (name) {
      case 'firstName': return firstNameError;
      case 'lastName': return lastNameError;
      case 'emailAddress': return emailError;
      case 'mobile': return mobileError;
      case 'jobTitle': return jobTitleError;
      default:
        return false;
    }
  };

  const getHelperText = (fieldName) => topFields.find(({ name }) => name === fieldName)?.helperText;

  const renderHelperText = (fieldName) => {
    switch (fieldName) {
      case 'firstName': return firstNameError
        ? getHelperText(fieldName) : '';
      case 'lastName': return lastNameError
        ? getHelperText(fieldName) : '';
      case 'emailAddress': return emailError
        ? getHelperText(fieldName) : '';
      case 'mobile': return mobileError
        ? getHelperText(fieldName) : '';
      case 'jobTitle': return jobTitleError
        ? getHelperText(fieldName) : '';
      default:
        return false;
    }
  };

  const returnTextField = (field) => {
    const { name: fieldName, label } = field;
    const value = state[fieldName];

    if (['role'].includes(fieldName)) {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
        />
      );
    }

    return (
      <SupplierTextField
        variant="filled"
        size="small"
        name={fieldName}
        label={label}
        value={value}
        required
        type={fieldName === 'mobile' ? 'text' : fieldName === 'startingDate' ? 'date' : 'text'}
        error={renderError(fieldName)}
        helperText={renderHelperText(fieldName)}
        onChange={handleChange}
      />
    );
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
      >
        <Grid container>
          <GridWrapper container item xs>
            <DialogTitle id="alert-dialog-slide-title">
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {state.businessUserId ? 'Edit User' : 'Add User'}
                </DialogTitleText>
                <DialogTitleSubText>
                  {state.businessUserId
                    ? 'Update your user’s information fields in the form'
                    : 'Add your user’s information to the fields in the form'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent>
              <TopGrid item>
                {topFields.map((field) => returnTextField(field))}
              </TopGrid>
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    {state.businessUserId ? 'Update' : 'Save & ...'}
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </Dialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

AddUserDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  businessId: PropTypes.number.isRequired,
  info: PropTypes.instanceOf(Object),
};

AddUserDialog.defaultProps = {
  info: undefined
};

export default AddUserDialog;
