import {
  Dialog, Grid, Typography, CircularProgress, TextField, Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
    min-width: 55rem;
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 0.5rem 1.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 2.5rem 1.5rem;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 1.5rem;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: .9rem;
  color: #858383;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const TextFieldBox = styled(Box)`
  margin-bottom: 1rem;
  width: 100%;
  display: block;
`;

export const CTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 2rem;
  }
`;

export const FieldHeader = styled(Typography)`
  font-size: .9rem;
  margin-bottom: .7rem;
  color: #303030;
`;
