import {
  Grid, Paper, TableCell, Typography, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExportIcon } from '../../../assets/svgs';

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 1rem;
`;

export const HeaderGridItem = styled(Grid)``;

export const ExportButton = styled(Button)`
  background: #FFFFFF;
  font-size: 12px;
  border: 1px solid #E7E8E9;
  border-radius: 8px;
  padding: 0.6rem 1rem;
`;

export const ExportIconStyled = styled(ExportIcon)`
  margin-right: 0.5rem
`;

export const DivFullWidth = styled('div')`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
`;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`
  width: 70rem;
`;

export const MainTableHead = styled(Grid)`
  width: 70rem;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F4F5F7;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 26%;
    }
    &:nth-child(2), &:nth-child(3), :nth-child(4), :nth-child(5) {
      width: 12%;
    }
    :nth-child(6), :nth-child(7) {
      width: 9%;
    }
    &:nth-child(8) {
      width: 8%;
    }
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;
