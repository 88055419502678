import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Hidden, Grid, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Slide, TableBody
} from '@mui/material';
import toast from 'react-hot-toast';
import {
  Clear as ClearIcon,
  Check as CheckIcon
} from '@mui/icons-material';
import moment from 'moment';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  CartGridContainer, DialogActionButtonText, BackArrowIconContainer,
  PageTitleContainer, CartGridContainerHeader,
  DialogCancelButton, DialogOkButton, DeliveryAddressCardHeaderTitle,
  ChangeLocationButton, ChangeLocationActions, LocationTextField, ChangeLocationContainer, KeyboardArrowLeft, TableHeader, MainTableRow,
} from '../../../cart/cart.styles';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, SupplierDetailCardHeader, OrderDeliveryNotesText,
  ArrowIcon, UpdateButton, OrderButtonText, PageTitleText, PageTitleSubText, DeliveryAddressCardHeaderSubTitle,
  SubTotalText, SubTotalPrice, SubTotalBox, TableWrapper, TableComponent, Header
} from './styles';
import { GET_SINGLE_ORDER, ORDER_INVOICE_TO_ERP } from '../../../../queries/orders';
import { UPDATE_ORDER_META_MUTATION } from '../../../../mutations/cart';
import { CANCEL_SUPPLIER_ORDERS, LINK_ORDER_TO_ERP } from '../../../../mutations/orders';
import MainContent from '../../../customComponents/mainContent';
import SupplierOrderDetailProductItem from './product-item';
import { JSONParse } from '../../../../utils/json';
import PaymentSummary from '../../../shared/paymentSummaryCard/PaymentSummary';
import DeliveryInfoCard from '../../../shared/deliveryInfoCard/deliveryInfoCard';

import { Product } from '../../../../providers/reducers/product/classes/Product';
import OrderInfoCard from '../../../shared/orderInfoCard/orderInfoCard';
import arrowRight from '../../../../assets/images/arrowRight.png';
import { parseJson } from '../../../affiliates/utils';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialState = {
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  totalPromo: 0,
  showCancelOrderDialog: false,
  editNote: false,
  note: '',
  orderId: null,
  supplierPaymentTerm: null,
  orderMeta: null,
  showPaymentDetails: false,
  orderStatus: null,
  deliveryId: null,
  linkOrderInvoicesToErp: [],
  business: {}
};
const SupplierOrderDetailIndex = () => {
  const [state, setState] = useState(initialState);

  const params = useParams();
  const navigate = useNavigate();

  const [cancelSupplierOrders] = useMutation(CANCEL_SUPPLIER_ORDERS);
  const [updateOrderMeta] = useMutation(UPDATE_ORDER_META_MUTATION);
  const [linkOrderToErp] = useMutation(LINK_ORDER_TO_ERP);

  const retrieveProducts = (orderSet) => {
    let products = [];
    let totalPromo = 0;

    products = orderSet?.map((p) => {
      const {
        id,
        name,
        quantity,
        quantityRecieved,
        meta: orderProductMeta,
        product: { meta }
      } = p;

      const {
        supposed_amount: supposedAmount,
        amount_paid: amountPaid,
        supposed_unit_cost: supposedUnitCost,
        unit_cost_paid: unitCostPaid,
        market_rpp: marketRpp,
      } = JSONParse(orderProductMeta?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
      totalPromo += (supposedUnitCost - unitCostPaid) * quantity;

      return {
        id,
        name,
        quantity,
        ...new Product(p.product),
        meta: JSON.parse(meta),
        supposedAmount,
        amountPaid,
        supposedUnitCost,
        unitCostPaid,
        marketRpp,
        quantityRecieved,
      };
    });
    setState((s) => ({
      ...s, cart: products, totalPromo
    }));
  };

  const [orderInvoiceToErp, { data: linkData }] = useLazyQuery(ORDER_INVOICE_TO_ERP);
  useEffect(() => {
    if (linkData && linkData.linkOrderInvoicesToErp) {
      const { linkOrderInvoicesToErp } = linkData;
      setState((s) => ({
        ...s, linkOrderInvoicesToErp: linkOrderInvoicesToErp?.linkOrderInvoicesToErp
      }));
    }
  }, [linkData]);

  const checkLinkOrderToErp = () => {
    orderInvoiceToErp({
      variables: { orderId: params.id },
      fetchPolicy: 'no-cache'
    });
  };

  const { data } = useQuery(GET_SINGLE_ORDER, {
    variables: { id: params.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data && data?.singleOrder) {
      const {
        singleOrder: {
          id: orderId,
          dateCreated,
          status,
          business,
          metaStatus: orderStatus,
          meta,
          supplierorderSet,
          supplier,
          paymentSummary,
          deliveryOutlet
        }
      } = data;
      const orderMeta = JSONParse(meta.replace(/'/g, '"'));
      const dateFormat = 'l h:mm';
      const datePlaced = moment(dateCreated).format(dateFormat);
      const deliveryDate = moment(datePlaced).add(2, 'days').format('DD/MM/YY');
      const prods = [];
      supplierorderSet?.forEach((orderSet) => prods.push(...orderSet.orderproductSet));
      const businessContactMeta = JSONParse(deliveryOutlet?.contacts?.replace(/'/g, '"').replace('None', '"Lagos"'));
      const parseSummary = parseJson(paymentSummary);
      const serviceCharge = parseSummary?.service_charge;
      const {
        note
      } = orderMeta;

      setState((s) => ({
        ...s,
        orderId,
        note,
        businessName: business.name,
        businessContact: businessContactMeta,
        supplier,
        deliveryDate,
        dateCreated: moment(dateCreated).format('DD/MM/YYYY'),
        orderMeta,
        supplierOrderStatus: status,
        orderStatus,
        business,
        serviceCharge
      }));
      retrieveProducts(prods);
    }
    checkLinkOrderToErp();
  }, [data]);

  const openDetailsPage = () => {
    setState((s) => ({ ...s, showPaymentDetails: true }));
  };

  const handleCancelOrder = () => {
    setState((s) => ({ ...s, loading: true, showCancelOrderDialog: false }));

    cancelSupplierOrders({
      variables: { supplierOrderIds: [params.id] }
    }).then((results) => {
      const {
        data: { cancelSupplierOrders: { message } }
      } = results;

      toast.success(message);
      setTimeout(() => navigate('/orders'), 1500);
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, loading: false }));
    });
  };

  const handleEditNoteClicked = () => {
    setState((s) => ({ ...s, editNote: true }));
  };

  const handleEditNote = () => {
    const { note, orderId } = state;
    if (note) {
      const keyValues = [{ key: 'note', value: note }];
      const mutationVariables = {
        keyValues,
        orderId,
        isManualOrder: false
      };

      updateOrderMeta({
        variables: mutationVariables
      }).then((results) => {
        const {
          data: {
            updateOrderMeta: { order: { meta } }
          }
        } = results;

        const orderMeta = JSONParse(meta.replace(/'/g, '"'));
        const { note: orderNote } = orderMeta;

        toast.success('Note updated.');
        setState((s) => ({ ...s, editNote: false, note: orderNote }));
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      toast.error('Note cannot be empty.');
    }
  };

  const sendSupplierOrderToErp = () => {
    linkOrderToErp({
      variables: { orderId: params.id }
    }).then(() => {
      toast.success('Order link to ERP successfully.');
      checkLinkOrderToErp();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const {
    businessContact, deliveryDate, dateCreated, cart, totalPromo, serviceCharge,
    showCancelOrderDialog, loading, editNote, note, supplierPaymentTerm, linkOrderInvoicesToErp, business
  } = state;

  const orderTotal = cart.filter((product) => product.quantityRecieved)
    .reduce((sum, obj) => sum + (Number(obj.quantityRecieved) * Number(obj.supposedUnitCost)), 0);

  const grandTotal = orderTotal + serviceCharge;
  const groupOrderDiscount = cart.reduce((sum, obj) => sum
        + (`${obj.productBusinessId}` === '1' ? (Number(obj.quantityRecieved) * ((Number(obj.marketRrp) || Number(obj.supposedUnitCost)) - Number(obj.supposedUnitCost))) : 0), 0);
  const handleSubtotal = () => {
    let total = 0;
    cart.map((product) => {
      const { supposedUnitCost, quantityRecieved } = product;
      const price = +supposedUnitCost * quantityRecieved || 0;
      total += price;
    });
    return total;
  };

  return (
    <MainContent>
      <CartGridContainer>
        <CartGridContainerHeader container>
          <Grid xs={12}>
            <BackArrowIconContainer onClick={() => navigate('/orders')}>
              <KeyboardArrowLeft />
              <PageTitleSubText>back</PageTitleSubText>
            </BackArrowIconContainer>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <PageTitleContainer>
              <PageTitleText>Order Details</PageTitleText>
              <PageTitleSubText style={{ color: '#303030', paddingBottom: '20px' }}>View details of an order sent to a supplier edited</PageTitleSubText>
            </PageTitleContainer>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center" justifyContent="flex-end">
            <Hidden xsUp>
              <UpdateButton
                onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: true }))}
                disabled={loading}
              >
                <ClearIcon />
                <OrderButtonText>Cancel Order</OrderButtonText>
              </UpdateButton>
            </Hidden>
            <UpdateButton
              onClick={() => sendSupplierOrderToErp()}
              disabled={loading || !linkOrderInvoicesToErp?.length}
            >
              <OrderButtonText>Link to ERP</OrderButtonText>
              <ArrowIcon title="link Orders" src={arrowRight} />
            </UpdateButton>
          </Grid>
        </CartGridContainerHeader>

        <Grid
          container
          justifyContent="space-between"
          spacing={3}
          style={{ marginTop: '42px' }}
        >
          <Grid item container lg={8}>
            <SupplierDetailCardWrapper elevation={2}>
              <TableWrapper>
                <TableComponent aria-label="affiliates table">
                  <TableHeader>
                    <MainTableRow>
                      <Header> Product Item</Header>
                      <Header> Qty Ordered</Header>
                      <Header> Qty Received</Header>
                      <Header> Unit Cost</Header>
                      <Header>Price</Header>
                    </MainTableRow>
                  </TableHeader>
                  <TableBody>
                    {cart.map((productItem) => (
                      <SupplierOrderDetailProductItem
                        key={productItem}
                        product={productItem}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              </TableWrapper>
              <SubTotalBox>
                <SubTotalText>
                  Subtotal
                </SubTotalText>
                <SubTotalPrice>
                  ₦&nbsp;
                  {` ${currencyFormatter(handleSubtotal())}`}
                </SubTotalPrice>
              </SubTotalBox>
            </SupplierDetailCardWrapper>
          </Grid>

          <Grid item container lg={4}>
            <OrderInfoCard
              id="ID0D123-SU12"
              business={business}
              dateCreated={dateCreated}
              businessDateDelivered={deliveryDate}
              supplierPaymentTerm={supplierPaymentTerm}
              displayId="hide"
              affiliateInfoCard="affiliateInfoCard"
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          spacing={3}
          style={{ marginTop: '42px', marginBottom: '50px' }}
        >
          <Grid item container lg={4}>
            <SupplierDetailCardWrapper>
              <SupplierDetailCard>
                <SupplierDetailCardHeader>
                  <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>
                  {editNote ? (
                    <ChangeLocationActions>
                      <ChangeLocationButton
                        role="button"
                        onClick={() => setState((s) => ({ ...s, editNote: false }))}
                      >
                        <ClearIcon />
                      </ChangeLocationButton>

                      <ChangeLocationButton
                        role="button"
                        onClick={() => handleEditNote()}
                      >
                        <CheckIcon />
                      </ChangeLocationButton>
                    </ChangeLocationActions>
                  ) : (
                    <DeliveryAddressCardHeaderSubTitle
                      onClick={() => handleEditNoteClicked()}
                      variant="outlined"
                    >
                      Edit
                    </DeliveryAddressCardHeaderSubTitle>
                  )}
                </SupplierDetailCardHeader>
                {editNote ? (
                  <ChangeLocationContainer>
                    <LocationTextField
                      label="Note"
                      value={note}
                      multiline
                      rows={5}
                      onChange={(event) => setState((s) => ({ ...s, note: event.target.value }))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </ChangeLocationContainer>
                ) : (
                  <OrderDeliveryNotesText>{note}</OrderDeliveryNotesText>
                )}

              </SupplierDetailCard>
            </SupplierDetailCardWrapper>
          </Grid>

          <Grid item container lg={4}>
            <SupplierDetailCardWrapper>
              <SupplierDetailCard>
                <DeliveryInfoCard
                  businessContact={businessContact}
                  openDetailsPage={openDetailsPage}
                />
              </SupplierDetailCard>
            </SupplierDetailCardWrapper>
          </Grid>

          <Grid item container lg={4}>
            <SupplierDetailCardWrapper>
              <SupplierDetailCard>
                <PaymentSummary
                  cart={cart}
                  orderTotal={orderTotal}
                  groupOrderDiscount={groupOrderDiscount}
                  grandTotal={grandTotal}
                  promo={totalPromo}
                  serviceFee={serviceCharge}
                />
              </SupplierDetailCard>
            </SupplierDetailCardWrapper>
          </Grid>
        </Grid>
      </CartGridContainer>

      <Dialog
        open={showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton onClick={() => handleCancelOrder()}>
            <DialogActionButtonText>
              OK
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>
    </MainContent>
  );
};

export default SupplierOrderDetailIndex;
