export class Version {
  brandName = '';

  genericName = '';

  supplier = {};

  packSize = '';

  manufacturer = '';

  image = '';

  description = '';

  therapeuticType = '';

  barcode = '';

  unitOfMeasurement = '';

  qpc = '';

  productVariant = 'readilyAvailable';

  minimumOrderQuantity = 1;
}
