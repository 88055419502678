import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItemText } from '@mui/material';
import { GridContainer, CustomListItem } from './savePopper.styles';
import { RenderPopper } from '../../../utils/utils';

const SavePopper = ({
  addButtonEl,
  setAddButtonEl,
  savePopperHandler,
}) => {
  const open = Boolean(addButtonEl);
  const addMore = true;
  return (
    <RenderPopper
      open={open}
      anchorEl={addButtonEl}
      onClickAway={() => setAddButtonEl(null)}
      popperPlacement="right-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <GridContainer container item>
        <List component="nav" aria-label="save options">
          <CustomListItem button onClick={() => savePopperHandler(addMore)}>
            <ListItemText primary="Add another" />
          </CustomListItem>
          <CustomListItem button onClick={() => savePopperHandler()}>
            <ListItemText primary="Close" />
          </CustomListItem>
        </List>

      </GridContainer>
    </RenderPopper>
  );
};

SavePopper.propTypes = {
  addButtonEl: PropTypes.instanceOf(Object),
  setAddButtonEl: PropTypes.func.isRequired,
  savePopperHandler: PropTypes.func.isRequired,
};

SavePopper.defaultProps = {
  addButtonEl: {},
};

export default SavePopper;
