import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { RenderPopper } from '../../../utils/utils';
import {
  ProductItem,
  SearchPaper,
  ProdName,
} from './searchPopper.styles';

const SearchPopper = ({
  searchFieldEl,
  handleClose,
  searchedProducts,
  handleProductSelection
}) => {
  const open = Boolean(searchFieldEl);

  return (
    <RenderPopper
      open={open}
      anchorEl={searchFieldEl}
      onClickAway={handleClose}
      popperPlacement="bottom-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '-3vw, 1vh'
        }
      }}
    >
      <SearchPaper elevation={0}>
        {searchedProducts?.map((prod) => (
          <ProductItem
            key={prod}
            container
            onClick={() => handleProductSelection(prod)}
          >
            <Grid item md={8} xs={4}>
              <ProdName variant="caption">{prod.brandName}</ProdName>
            </Grid>
          </ProductItem>
        ))}
      </SearchPaper>
    </RenderPopper>
  );
};

SearchPopper.propTypes = {
  searchFieldEl: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  searchedProducts: PropTypes.instanceOf(Array).isRequired,
  handleProductSelection: PropTypes.func.isRequired
};

export default SearchPopper;
