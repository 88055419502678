import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { RESET_PASSWORD_MUTATION } from '../../mutations/auth';
import { validateEmail } from './utils';
import {
  CircularProgressLoader, ButtonGrid, InputWrapper, Label, CustomField
} from './ForgotPassword.styles';
import CustomButton from '../customComponents/customButton';
import { HaveAccount, HaveAccountTypo } from './register.styles';
import SuccessDialog from '../shared/newSuccessDialog';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [validateState, setValidateState] = useState('');
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION);

  useEffect(() => {
    setValidateState(!emailError && !!email);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleChange = (event) => {
    const { value } = event.target;
    setEmailError(validateEmail(value));
    setEmail(value);
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const sendResetPassword = () => {
    setLoading(true);
    resetPassword({
      variables: { email }
    }).then(({ data }) => {
      const { message } = data?.resetPassword || {};
      setEmail('');
      toggleSuccessModal();
      toast.success(message);
    }).catch(() => {
      toast.error('Email address not found!');
    }).finally(() => {
      setLoading(false);
    });
  };
  const isLarge = useMediaQuery('(min-width: 992px)');

  return (
    <Grid>
      <InputWrapper item container direction="column">
        <Grid item container justifyContent="space-between">
          <Label error={email ? emailError : false}>
            Email Address
            {' '}
            <span style={{ color: 'red' }}>*</span>
          </Label>
        </Grid>
        <CustomField
          variant="outlined"
          name="email"
          value={email}
          size={isLarge ? 'small' : 'medium'}
          type="text"
          required
          fullWidth
          error={email ? emailError : false}
          helperText={emailError ? 'Invalid email' : ' '}
          onChange={handleChange}
          InputProps={{ disableUnderline: true }}
          className="uat-forget-password"
        />
      </InputWrapper>
      <ButtonGrid container>
        <CustomButton
          data-testid="auth-button"
          disabled={!validateState}
          onClick={sendResetPassword}
          type="submit"
          className="signin-button"
          id="signin-button"
          style={{ width: '100%', height: isLarge ? '3.5rem' : '5.7rem', fontSize: isLarge ? '' : '2.7rem' }}
        >
          {loading ? (
            <CircularProgressLoader
              disableShrink
              size={16}
              thickness={3}
            />
          ) : 'Submit'}
        </CustomButton>
      </ButtonGrid>
      <HaveAccount item xs={12}>
        <HaveAccountTypo variant="subtitle2" display="inline">
          Remember your password?
          &nbsp;
          &nbsp;
          <Link to="/" style={{ color: '#235A91', textDecoration: 'underline' }}>Sign In</Link>
        </HaveAccountTypo>
      </HaveAccount>
      <SuccessDialog
        openConfirmation={successModal}
        handleClose={toggleSuccessModal}
        title="Link sent!"
        discreption="Hi User, please check your email for link sent"
      />
    </Grid>
  );
};

export default ForgotPassword;
