import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {
  SelectNameTypo, FieldCheckbox, CheckboxImage
} from './returnSelectField.styles';
import CheckboxIcon from '../../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../../assets/images/supplier/Checkbox-resting.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ReturnSelectField = ({
  field, value, handleChange
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected(event.target.value);
    handleChange(event);
  };

  return (
    <FormControl variant="filled" size="small" ref={selectRef}>
      <InputLabel id="select-label">{field.label}</InputLabel>
      <Select
        variant="standard"
        labelId="select-filled-label"
        id="select-filled"
        value={value}
        renderValue={(val) => val}
        onChange={handleInput}
        inputProps={{
          name: field.name,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {field.options.map((option) => (
          <MenuItem key={option} value={option}>
            <Grid item container justifyContent="space-between" alignItems="center">
              <SelectNameTypo>{option}</SelectNameTypo>
              <FieldCheckbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected === option}
              />
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ReturnSelectField.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

ReturnSelectField.defaultProps = {
  field: {},
  value: ''
};

export default ReturnSelectField;
