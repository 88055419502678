import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Grid, useMediaQuery } from '@mui/material';
import toast from 'react-hot-toast';
import MainContent from '../../customComponents/mainContent';
import {
  TitleGridContainer, DetailCard, PrimaryTitle,
  SecondaryTitle, DetailCardText, CIconButton, KeyboardArrowLeft, StatusBullet
} from '../stockReport.styles';
import { useStateValue } from '../../../providers/stateProvider';
import { GET_STOCK_REPORT_QUERY } from '../../../queries/reports';
import { renderStockReportStatus, handleStockReportStatusColor } from '../utils';
import NewReport from './newReport';
import { BATCH_PRODUCT_QUERY, SEARCH_PRODUCT_QUERY } from '../../../queries/products';
import { UPDATE_REPORT_MUTATION, REMOVE_REPORT_MUTATION } from '../../../mutations/reports';
import ConfirmDialog from '../../shared/confirmDialog';
import SearchPopper from './searchPopper';
import BatchDialog from './batchDialog';
import { FilledCustomButton } from '../../customComponents/customButton';

const NewReportContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const reportStatus = 'RECONCILE_QUANTITY';
  const isSmall = useMediaQuery('(max-width: 991px)');

  const businessId = localStorage.getItem('ogarx_business_id');

  const [{ user: { session } }] = Object.values(useStateValue());
  const [business, setBusiness] = useState('');
  const [searchText, setSearchText] = useState('');
  const [batch, setBatch] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [pid, setPid] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    if (session && session.businessuserSet?.length) {
      const { business: biz } = session?.businessuserSet[0] || {};
      setBusiness(biz);
    }
  }, [session]);

  const [getSearchedProducts, { data, loading: searchLoading }] = useLazyQuery(SEARCH_PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: '',
      productName: searchVal,
      status: 'enabled',
      pageCount: 10,
      pageNumber: 1,
    },
  });

  const [getBatches, { data: batchData, loading: batchLoading, refetch: refetchBatch }] = useLazyQuery(BATCH_PRODUCT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      search: '',
      productId: pid
    },
  });

  const createData = (product) => {
    const {
      id: productId, erpVersion: { brandName, resolvedPriceInUseValue, packSize }
    } = product || {};

    return {
      productId, brandName, itemPrice: resolvedPriceInUseValue, packSize
    };
  };

  useEffect(() => {
    if (data && data.erpProducts) {
      const sResults = data.erpProducts.map((product) => createData(product));
      setSearchedProducts(sResults);
      setSearchFieldEl(savedEl);
    }
  }, [searchLoading, data]);

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  // Search for products
  const handleSearch = async (event) => {
    const { value } = event.target;
    setSearchText(value);
    if (value && value.length > 2) {
      setSearchVal(value);
      setSavedEl(event.target);
    } else if (!value) {
      handlePopperClose();
      setSearchText('');
    }
  };

  useLayoutEffect(() => {
    if (searchVal && searchVal.length >= 3) {
      getSearchedProducts();
    } else {
      handlePopperClose();
    }
  }, [searchVal]);

  const handleBatchSelection = (prodId) => {
    setPid(prodId);
    handlePopperClose();
  };

  useEffect(() => {
    if (pid) {
      getBatches();
      setOpenDialog(true);
    }
  }, [pid, getBatches]);

  const { loading: reportLoading, data: reportData, refetch } = useQuery(GET_STOCK_REPORT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: Number(id) },
  });

  const [updateReport] = useMutation(UPDATE_REPORT_MUTATION);
  const [removeReport, { loading: removeLoading }] = useMutation(REMOVE_REPORT_MUTATION);

  const batches = reportData?.stockReportInventory || [];

  const updateHandler = (row, counter, type) => {
    if (type === 'UPDATE') {
      const {
        batch: { id: batchId }, product: { id: productId }, id: inventoryId
      } = row;

      const products = [{
        inventoryId,
        productId: Number(productId),
        quantityCounted: counter,
        batchId
      }];

      const request = {
        stockReportId: Number(id),
        business: Number(businessId),
        products,
        status: 'RECONCILE_QUANTITY'
      };

      updateReport({
        variables: { ...request }
      }).then((response) => {
        const { message } = response?.data?.updateReport || {};
        toast.success(message);
        refetch();
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      setBatch(row);
      setOpenConfirmDialog(true);
    }
  };

  // Remove product if removal status is confirmed
  if (confirmStatus) {
    const { id: inventoryId } = batch;
    const request = {
      stockReportId: Number(id),
      inventoryIds: [Number(inventoryId)]
    };

    removeReport({
      variables: { ...request }
    }).then((response) => {
      const { message } = response?.data?.removeStockInventory || {};
      toast.success(message);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
    setConfirmStatus(false);
    setOpenConfirmDialog(false);
    setBatch({});
  }

  const handleAddProductToReport = (nBatches, product) => {
    if (nBatches.length < 1) return toast.error('Select at least one batch');

    const products = [];
    const { id: productId } = product;

    nBatches.map((item) => {
      const {
        id: batchId,
        batchNo
      } = item;

      const nBatch = {
        productId, quantityCounted: 0, batchId, batchNo
      };
      products.push(nBatch);
    });

    const request = {
      stockReportId: Number(id),
      business: Number(businessId),
      products,
      status: 'RECONCILE_QUANTITY'
    };

    updateReport({
      variables: { ...request }
    }).then((response) => {
      const { message } = response?.data?.updateReport || {};
      refetch();
      toast.success(message);
    }).catch(() => {
      toast.error('Unable to process request, found a duplicate batch');
    });
  };

  return (
    <MainContent>
      <Grid container style={{ padding: '30px' }}>
        {!isSmall ? (
          <TitleGridContainer style={{ marginBottom: '1rem', marginLeft: '5px' }}>
            <CIconButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
              back
            </CIconButton>
          </TitleGridContainer>
        )
          : (
            <>
              <TitleGridContainer style={{ marginTop: '1rem', marginLeft: '5px' }}>
                <CIconButton onClick={() => navigate(-1)}>
                  <KeyboardArrowLeft />
                </CIconButton>
              </TitleGridContainer>
              <PrimaryTitle variant="h5" style={{ marginLeft: '5px' }}>
                Edit Stock Report
              </PrimaryTitle>
            </>
          )}
        <TitleGridContainer container item>
          <Grid container item md={7}>
            <Grid item>
              {!isSmall ? (
                <PrimaryTitle variant="h5">
                  Edit Stock Report
                </PrimaryTitle>
              )
                : ('')}
              <SecondaryTitle variant="caption">Create A New Stock Count</SecondaryTitle>
            </Grid>
          </Grid>
          {!isSmall ? (
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              xs={12}
              md={5}
              spacing={3}
              gap={2}
              style={{ marginTop: '0' }}
            >
              <FilledCustomButton disabled={disableButton} style={{ background: disableButton && '#78AADA' }} onClick={() => navigate('/stock-count-report')}>
                Finish
              </FilledCustomButton>
            </Grid>
          )
            : (
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                xs={12}
                md={5}
                spacing={3}
                gap={2}
                style={{ marginTop: '0', marginLeft: '0.3rem' }}
              >
                <Button
                  variant="contained"
                  style={{
                    marginTop: '3rem',
                    width: '100%',
                    fontSize: '2rem',
                    height: '5rem',
                    borderRadius: '20px'
                  }}
                  disabled={disableButton}
                  onClick={() => navigate('/stock-count-report')}
                >
                  Finish
                </Button>
              </Grid>
            )}
        </TitleGridContainer>
        <DetailCard elevation={0} style={{ borderRadius: isSmall ? '30px' : '12px', height: isSmall ? '10rem' : '' }}>
          <DetailCardText
            variant="caption"
            style={{
              marginTop: isSmall ? '1.5rem' : '0', fontSize: isSmall ? '30px' : '16px',
              marginLeft: isSmall ? '9rem' : undefined
            }}
          >
            {business?.name}
          </DetailCardText>
          <DetailCardText variant="caption" style={{ marginLeft: '10px', marginTop: isSmall ? '1.5rem' : '0', fontSize: isSmall ? '30px' : '16px' }}>
            {`- ${renderStockReportStatus(reportStatus)} `}
            <StatusBullet color={handleStockReportStatusColor(reportStatus)} />
          </DetailCardText>
        </DetailCard>

        <NewReport handleSearch={handleSearch} searchText={searchText} batches={batches} loading={searchLoading} updateHandler={updateHandler} reportLoading={reportLoading} setDisableButton={setDisableButton} />
      </Grid>

      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Remove Product"
        desc="Are you sure you want to remove this product?"
        options={['Cancel', 'Yes, Remove']}
        setStatus={setConfirmStatus}
        loading={removeLoading}
        buttonAction={false}
      />

      <SearchPopper
        searchFieldEl={searchFieldEl}
        handleClose={handlePopperClose}
        searchedProducts={searchedProducts}
        handleBatchSelection={handleBatchSelection}
      />

      <BatchDialog
        openDialog={openDialog}
        handleCloseDialog={() => setOpenDialog(false)}
        product={batchData}
        handleAddProductToReport={handleAddProductToReport}
        refetch={refetchBatch}
        loading={batchLoading}
      />

      {/* <NewReportDialog
        openDialog={openNewReportDialog}
        setOpenDialog={setOpenNewReportDialog}
        stockReportId={stockReportId}
        business={reportBusinessId || business.id}
        productsWithoutCount={productsWithoutCount}
        reportStatus={reportStatus}
        navigate={navigate}
      /> */}
    </MainContent>
  );
};

export default NewReportContainer;
