import { gql } from '@apollo/client';

export const CREATE_MANUFACTURER_MUTATION = gql`
  mutation createManufacturer(
    $name: String!
    $term: String
    $city: String
    $country: String
    $providerPromo: String
  ) {
    createManufacturer(
      name: $name
      paymentTerm: $term
      city: $city
      country: $country
      providerPromo: $providerPromo
    ){
      message
      manufacturer {
        id
        name
      }
    }
  }
`;

export const UPDATE_MANUFACTURER_MUTATION = gql`
  mutation updateManufacturer(
    $manufacturerId: Int!
    $name: String!
    $term: String
    $city: String
    $country: String
    $providerPromo: String
  ) {
    updateManufacturer(
      manufacturerId: $manufacturerId
      manufacturerFields: {
        name: $name,  paymentTerm: $term, providerPromo: $providerPromo, city: $city, country: $country
     }
    ){
      message
      manufacturer {
        id
        name
      }
    }
  }
`;
