import { Dexie } from 'dexie';

const db = new Dexie('pharmiqDB');
db.version(1).stores({
  businessUsers: 'id',
  businesses: 'id, name, status, dateCreated',
  outletUsers: 'id',
  outlets: 'id, name, dateCreated',
  products: 'id, brandName, genericName, productRef, barcode, *BNWords, *GNWords',
  rawSaleData: '++id, transactionDate, paymentsMade',
  saleItemBatch: '++id, status',
  saleItems: '++id, saleId, name, quantity, status, [saleId+productId]',
  salePayments: '++id, paymentMethod, dateCreated',
  sales: '++id, status, total, transactionDate, receiptNumber, paymentMethod',
  users: 'id, username, status',
});

export async function dataCheck(store) {
  let isData = false;
  // eslint-disable-next-line consistent-return
  await db[store].toArray().then((arr) => {
    if (arr.length > 1) {
      isData = true;
      return isData;
    }
  });
  return isData;
}

export async function deleteDb() {
  return db.delete();
}

export default db;
