import {
  Grid, Checkbox, TableCell, TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #78AADA;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;
