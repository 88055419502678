import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { TableColumnRow, TableColumnRowText } from './singleDataAnalysis.styles';

const SingleDataAnalysisRow = ({ row }) => {
  const truncateString = (data, maxLength = 35) => {
    if (data?.length > maxLength) return `${data.substring(0, maxLength)}...`;
    return data;
  };

  return (
    <TableColumnRow>
      {row.length ? row.map((data) => (
        <TableColumnRowText>
          <Grid>{truncateString(data)}</Grid>
        </TableColumnRowText>
      )) : ''}
    </TableColumnRow>
  );
};

SingleDataAnalysisRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

export default SingleDataAnalysisRow;
