import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Hidden, Grid } from '@mui/material';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  CartGridContainer, PageTitleText,
  PageTitleSubText, GoBack,
  PageTitleContainer, CartGridContainerHeader, KeyboardArrowLeft
} from '../../cart/cart.styles';
import CustomButton from '../../customComponents/customButton';
import ReturnRow from './returnRow';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, GridButton, MenuButton, BackText,
  PaperContainer, TableComponent, Header, MainTableHead, MainTableRow, SubtotalGrid, SubtotalText, FooterGrid
} from './styles';

import { ALL_PO_ORDER_QUERY } from '../../../queries/orders';
import MainContent from '../../customComponents/mainContent';
import { JSONParse } from '../../../utils/json';
import DeliveryInfoCard from '../../shared/deliveryInfoCard/deliveryInfoCard';
import { OgaToken } from '../../../graphql/token';
import OrderInfoCard from '../../shared/orderInfoCard/orderInfoCard';
import TablePagination from '../../shared/tablePagination';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const headers = [
  'Product Items', 'Qty Ordered', 'Qty Received', 'Unit Cost', 'Price',
];

const AllPoOrdersDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const handleDownload = () => {
    window.open(
      `${OgaToken.SERVER_LINK}download_order_invoice/${id}`,
      '_blank'
    );
  };

  const {
    loading, error, data
  } = useQuery(ALL_PO_ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { orderId: id, pageCount, pageNumber }
  });

  if (error) return `Error! ${error.message}`;

  const {
    business, lastUpdated
  } = data?.singleOrder ?? {};

  const orderProducts = data?.poOrderProducts || {};
  const orderProductsTotalNumber = data?.poOrderProductsCount || 0;

  const reformatAddress = (str) => JSONParse(str?.replace(/\"*([\w&.+×'÷=/-<>\[\]()])+\"/g, (m, _) => m.replaceAll("'", '`')));

  const businessContact = reformatAddress(business?.contacts);
  const prods =  orderProducts || [];
  const returnHeaders = () => headers.map((header) => (
    <Header key={header}>{header}</Header>
  ));

  let subtotal;
  if (data?.poOrderProducts) {
     subtotal = prods?.reduce((acc, currentItem) => {
      return acc + currentItem.price;
  }, 0);


  }
  return (
    <MainContent>
      <CartGridContainer data-testid="po-order">
        <GoBack container item alignItems="center">
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
          </MenuButton>
          <BackText variant="caption">back</BackText>
        </GoBack>
        <Grid container item>
          <CartGridContainerHeader item container xs={12} md={5}>
            <PageTitleContainer>
              <PageTitleText>PO Order Detail</PageTitleText>
              <Hidden mdDown>
                <PageTitleSubText>View details of purchase order</PageTitleSubText>
              </Hidden>
            </PageTitleContainer>
          </CartGridContainerHeader>
          <GridButton xs={12} md={7}>
            <CustomButton
              type="tertiary"
              header
              data-testid="download-invoice"
              onClick={handleDownload}
              style={{ width: '12rem', height: '2.9rem', marginLeft: '1rem' }}
            >
              Download Invoice
            </CustomButton>
          </GridButton>
        </Grid>
        <Grid container spacing={4}>
          <SupplierDetailCardWrapper item container lg={6}>
            <OrderInfoCard
              id={`ID${id}`}
              business={business}
              dateCreated={moment(lastUpdated).format('DD/MM/YYYY')}
            />
          </SupplierDetailCardWrapper>
          <SupplierDetailCardWrapper item container lg={6}>
            <SupplierDetailCard>
              <DeliveryInfoCard businessContact={businessContact} businessName={business?.name} />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>
        <PaperContainer elevation={2}>
          <TableComponent>
            <MainTableHead>
              <MainTableRow>
                {returnHeaders()}
              </MainTableRow>
            </MainTableHead>
            {loading ? <ProductsTableLoader /> : prods?.map((prod, index) => <ReturnRow key={prod?.id} product={prod} index={index} />)}
          </TableComponent>
          <SubtotalGrid>
            <SubtotalText>
              <span style={{ marginRight: '1rem' }}>Subtotal: </span>
              {`₦${currencyFormatter(subtotal)}`}
            </SubtotalText>
          </SubtotalGrid>
          <FooterGrid item container>
            <TablePagination
              total={orderProductsTotalNumber}
              pageCount={+pageCount}
              setPageCount={setPageCount}
              pageNumber={+pageNumber}
              setPageNumber={setPageNumber}
            />
          </FooterGrid>
        </PaperContainer>
      </CartGridContainer>
    </MainContent>
  );
};

export default AllPoOrdersDetails;
