import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatusesGrid = styled(Grid)`
  padding: 0.5rem 20px;
  align-items: center;
  @media(max-width: 991px) {
    padding: 4rem 40px;
  }
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  border-bottom: ${({ active }) => (active ? '3px solid #235A91' : '3px solid #EEEEEE')};
  width: ${({ width }) => (width)};
`;

export const Typo = styled(Typography)`
  font-size: 13px;
  font-weight: 600;
  padding: 5px 8px;
  color: ${({ active }) => (active ? '#303030' : '#C0C0C1')};
  @media(max-width: 991px) {
    font-size: 35px;
  }
`;
