import React, { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import moment from 'moment';
import { GET_AFFILIATE_ORDERS } from '../../../queries/orders';

import Order from './order';
import Loader from '../../shared/loader';
import { JSONParse } from '../../../utils/json';

export function OrderContainer() {
  const count = sessionStorage.getItem('rowsPerPage') || 10;
  const [pageCount, setPageCount] = useState(count);
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState('');

  const initialState = {
    initialStatus: 'open',
    currentPage: 1,
    currentPageCount: pageCount,
    orderNo: '',
    orderTotalFrom: '',
    orderTotalTo: '',
    productName: '',
    orderStatus: '',
  };

  const [state, setState] = useState(initialState);
  const {
    currentPage, currentPageCount, orderNo, affiliate,
    orderTotalFrom, orderTotalTo, productName, orderStatus,
  } = state;

  const [dateFilter, setDateFilter] = useState({
    dateFrom: '',
    dateTo: ''
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    const newDateFrom = moment(dateFrom).format('YYYY-MM-DD');
    const newDateTo = moment(dateTo).format('YYYY-MM-DD');

    setDateFilter({
      dateFrom: newDateFrom,
      dateTo: newDateTo
    });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  const isFiltering = () => {
    if (orderNo || affiliate || orderTotalFrom
    || orderTotalTo || productName || orderStatus) return true;
    return false;
  };

  const handleSearch = (value) => {
    setState({ ...state, orderNo: value });
  };

  const { dateFrom, dateTo } = dateFilter;

  const handleClearFilters = () => setState(initialState);
  return (
    <Query
      query={GET_AFFILIATE_ORDERS}
      fetchPolicy="cache-and-network"
      variables={{
        pageCount,
        pageNumber,
        ...(orderNo && orderNo.length >= 3 && { orderNo: Number(orderNo) }),
        ...(dateFrom && { dateFrom }),
        ...(dateTo && { dateTo }),
        ...(orderTotalFrom && orderTotalFrom.length >= 4 && { totalFrom: orderTotalFrom }),
        ...(orderTotalTo && orderTotalTo.length >= 4 && { totalTo: orderTotalTo }),
        ...(productName && productName.length >= 3 && { productName }),
        ...(filter && filter.length >= 4 && { metaStatus: filter }),
      }}
    >
      {({ data, loading, error }) => {
        // if (loading) return <Loader />;
        if (error) return `Error! ${error.message}`;

        const businessOrderTotalNumber = data?.businessOrderTotalNumber || 0;
        const affiliateOrders = data?.affiliateOrders || [];

        const affiliateOrdersItems = affiliateOrders
          .map(
            (affiliateOrdersItem) => {
              const {
                datePlaced, id, deliveryDate, dateCreated, paymentSummary, metaStatus: status, business
              } = affiliateOrdersItem;
              const { total, savings } = JSONParse(paymentSummary);
              return {
                datePlaced, id, savings, status, deliveryDate, dateCreated, total, business
              };
            }
          );

        return (
          <Order
            affiliateOrders={affiliateOrdersItems}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={currentPage}
            currentPageCount={currentPageCount}
            pageCount={pageCount}
            pageNumber={pageNumber}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
            totalCount={businessOrderTotalNumber}
            state={state}
            handleFilterChange={handleFilterChange}
            isFiltering={isFiltering}
            handleClearFilters={handleClearFilters}
            loading={loading}
            handleSearch={handleSearch}
            handleSubmit={handleSubmit}
            setFilter={setFilter}
          />
        );
      }}
    </Query>
  );
}

// OrderContainer.propTypes = {
//   history: PropTypes.instanceOf(Object).isRequired,
// };

export default OrderContainer;
