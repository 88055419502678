import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { Grid } from '@mui/material';
import FinishedImage from './finishedImage';
import getCroppedImg from './cropImage';
import AnotherImg from '../../../assets/images/Upload another.png';
import {
  MainWrapper, CropContainer, ControlsContainer, CheckButton, CheckIcon,
  HeaderWrapper, HeaderText, CustomIcon,
  CustomIconButton, ChooseAnother, ChooseAnotherText,
  ChooseAnotherImg
} from './cropImageContainer.styles';

const CropImageContainer = ({
  files, handleFile, handleFinalImage, isLicense
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoomed, setZoomed] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAPixels) => {
    setCroppedAreaPixels(croppedAPixels);
  }, []);

  const fileType = isLicense ? '' : 'round';
  const readFile = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

  const setDataUrl = async (_files) => {
    const imageDataUrl = await readFile(_files[0]);
    setImageSrc(imageDataUrl);
  };

  useEffect(() => {
    setDataUrl(files);
  }, [files]);

  const showCroppedImage = useCallback(async () => {
    try {
      const image = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      setCroppedImage(image);
      handleFinalImage(image);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
    handleFinalImage(null);
  }, []);

  return (
    <Grid item container>
      {croppedImage ? (
        <FinishedImage
          img={croppedImage}
          onClose={onClose}
          isLicense={isLicense}
        />
      ) : (
        <MainWrapper item container>
          <HeaderWrapper container justifyContent="space-between">
            <HeaderText>
              Adjust your logo to fit the crop area and select the tick!
            </HeaderText>
            <ChooseAnother item container xs={6} onClick={() => handleFile(null)}>
              <ChooseAnotherImg src={AnotherImg} alt="choose another" />
              <ChooseAnotherText variant="caption">
                Choose another image
              </ChooseAnotherText>
            </ChooseAnother>
          </HeaderWrapper>
          <CropContainer>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoomed}
              aspect={1 / 1}
              cropShape={fileType}
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoomed}
            />
          </CropContainer>
          <ControlsContainer>
            <CustomIconButton
              onClick={() => setZoomed((_zoomed) => _zoomed + 0.1)}
              disabled={zoomed === 3}
            >
              <CustomIcon>add</CustomIcon>
            </CustomIconButton>
            <CustomIconButton
              onClick={() => setZoomed((_zoomed) => _zoomed - 0.1)}
              disabled={zoomed === 1}
            >
              <CustomIcon>remove</CustomIcon>
            </CustomIconButton>
          </ControlsContainer>
          <CheckButton onClick={showCroppedImage}>
            <CheckIcon>check</CheckIcon>
          </CheckButton>
        </MainWrapper>
      )}
    </Grid>
  );
};

CropImageContainer.propTypes = {
  files: PropTypes.instanceOf(Array).isRequired,
  handleFile: PropTypes.func.isRequired,
  handleFinalImage: PropTypes.func.isRequired,
};
export default CropImageContainer;
