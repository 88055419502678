import React from 'react';
import PropTypes from 'prop-types';
import {
  HeaderWrapper, SupplierPaper, NameText, LowerGrids, BottomContainer,
  NameGrid, SubHeaderText, LightDivider, LowerLighterText,
  LowerDarkerText
} from './therapeuticTypeHeader.styles';

const SupplierHeader = ({
  id, name, productCount
}) => {
  const supplierId = `SU${id}`;

  return (
    <HeaderWrapper container spacing={6}>

      <NameGrid item xs={12} md={6}>
        <SupplierPaper elevation={2}>
          <SubHeaderText variant="subtitle1" align="right">
            {supplierId}
          </SubHeaderText>
          <LightDivider light />
          <BottomContainer item direction="column">
            <NameText>{name}</NameText>
            <LowerGrids container item>
              <LowerLighterText variant="subtitle1">
                Product count:
              </LowerLighterText>
              <LowerDarkerText variant="subtitle1">
                {`${productCount}`}
              </LowerDarkerText>
            </LowerGrids>
          </BottomContainer>
        </SupplierPaper>
      </NameGrid>
    </HeaderWrapper>
  );
};

SupplierHeader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  productCount: PropTypes.number
};

SupplierHeader.defaultProps = {
  id: '',
  name: '',
  productCount: 0,
};

export default SupplierHeader;
