import {
  Grid, Typography, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
`;

export const Image = styled('img')`
  height: 32vh;
  max-height: 100%;
  border-radius: 50%;
`;

export const ChangeImgButton = styled(Button)`
  padding: 2px 8px;
  float: right;
  margin-top: 15px;

  &:last-child {
    margin-top: 15px;
  }
`;

export const ChangeImgButtonIcon = styled('img')`
  width: 20px;

  @media(min-width: 992px) {
    width: 11px;
  }
`;

export const ChangeImgButtonText = styled(Typography)`
    margin-left: 8px;
    font-weight: 500;
    font-size: .7rem;
`;
