import React from 'react';
import PropTypes from 'prop-types';

import {
  GridWrapper, GridContainer, ItemWrapper, ItemTypo
} from './statusDropdown.styles';
import { RenderPopper } from '../../utils/utils';

const CartDropdown = ({
  options, dotsButtonEl, setDotsButtonEl, action, rowStatus
}) => {
  const open = Boolean(dotsButtonEl);

  return (
    <RenderPopper
      open={open}
      anchorEl={dotsButtonEl}
      popperPlacement="bottom"
      onClickAway={() => setDotsButtonEl(null)}
      marginLeft={!rowStatus && '4rem !important'}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        {options.map(({ name, disabled }) => (
          <GridContainer
            key={name}
            container
            item
            onClick={() => action(name)}
            disabled={disabled}
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1" style={{ textTransform: 'capitalize' }}>
                {name}
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        ))}
      </GridWrapper>
    </RenderPopper>
  );
};

CartDropdown.propTypes = {
  dotsButtonEl: PropTypes.shape(Object),
  setDotsButtonEl: PropTypes.func.isRequired,
  options: PropTypes.shape(Array),
  action: PropTypes.func.isRequired,
  rowStatus: PropTypes.bool
};

CartDropdown.defaultProps = {
  dotsButtonEl: {},
  options: [],
  rowStatus: false
};

export default CartDropdown;
