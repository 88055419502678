import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 1.8rem;
  }
`;
