import { round } from '../../../utils/utils';

function getProductTotal(cartItem) {
  const { discount, isPercentage } = cartItem;
  let total = cartItem.quantity * cartItem.resolvedPriceInUseValue;
  if (isPercentage && discount) total -= (total * (discount / 100));
  else if (discount) total -= discount;

  return total;
}

function sumCart(sale) {
  let totalCount = 0;
  let subTotal = 0;
  sale.cart.forEach((cartItem) => {
    if (!cartItem.missedSale) {
      totalCount++;
      subTotal += getProductTotal(cartItem);
    }
  });
  return {
    saleTotal: round(subTotal),
    subTotal: round(subTotal),
    totalCount,
  };
}

function getOutOfStockBatch(quantity) {
  return {
    orderProductId: '0', batchNo: 'OUT OF STOCK', expiryDate: 'N/A', qtyInStock: 'N/A', qtyToSell: quantity, checked: true
  };
}
export function updateBatchToCart(oldSale, { payload: product }) {
  const sale = { ...oldSale };
  const { batches, id: productId } = product;
  const { outOfStockBatches, inStockBatches } = sale;
  const productOutOfStockQty = outOfStockBatches.get(`${productId}`);
  const batchesAlreadyAddedToCart = inStockBatches.get(`${productId}`) || new Map();
  if (batches.length) {
    const modalBatches = new Map();
    const productInCart = sale.cart.get(`${productId}`);
    batches.forEach((batch) => {
      const { batchNo, expiryDate, quantityInStock } = batch;
      let qtyToSell = 0;
      let checked = false;
      if (productInCart) {
        const batchExist = productInCart.batches.get(`${batch.id}`);
        if (batchExist && batchesAlreadyAddedToCart.get(`${batch.id}`)) {
          qtyToSell = +batchExist.qtyToSell;
          checked = true;
        }
      }
      modalBatches.set(`${batch.id}`, {
        orderProductId: +batch.id, batchNo, expiryDate,
        qtyInStock: quantityInStock, qtyToSell, checked,
      });
    });
    if (productOutOfStockQty) {
      modalBatches.set('0', getOutOfStockBatch(productOutOfStockQty));
    }
    sale.batchModalData = {
      openedBatchModal: true,
      product,
      batches: modalBatches
    };
  } else {
    const { cart } = sale;

    const exists = cart.get(`${product.id}`);
    if (!exists) {
      const {
        brandName, resolvedPriceInUseValue, packSize
      } = product;
      cart.set(`${productId}`, {
        productId, brandName, resolvedPriceInUseValue, packSize, quantity: 1, batches: new Map(), product, missedSale: false
      });
      sale.cart = cart;
    }
  }
  return {
    ...sale,
    ...sumCart(sale)
  };
}

export function updateCartQuantity(oldSale, { payload: { product, quantity } }) {
  const { productId } = product;
  const sale = { ...oldSale };
  const { cart, outOfStockBatches } = sale;
  const cartProduct = cart.get(productId);
  if (cartProduct && quantity <= 0) {
    cart.delete(productId);
    outOfStockBatches.delete(productId);
  }
  if (cartProduct && quantity > 0) {
    cartProduct.quantity = quantity;
    cart.set(productId, cartProduct);
  }
  sale.cart = cart;
  return {
    ...sale,
    ...sumCart(sale),
    cart,
    outOfStockBatches
  };
}

export function clearCart(oldSale) {
  const sale = { ...oldSale };
  sale.cart = new Map();
  return {
    ...sale,
    ...sumCart(sale),
    outOfStockBatches: new Map(),
    inStockBatches: new Map(),
    percDiscount: 0,
  };
}

export function openBatchModal(oldSale, { payload: { open, product } }) {
  const sale = { ...oldSale };
  sale.batchModalData = {
    ...sale.batchModalData,
    openedBatchModal: open,
    productId: product.id
  };
  return sale;
}
export function toggleSelectProductBatch(oldSale, { payload: { orderProductId } }) {
  const sale = { ...oldSale };
  const modalBatches = sale.batchModalData.batches;
  const exists = modalBatches.get(`${orderProductId}`);
  if (exists) {
    const checked = !exists.checked;
    modalBatches.set(`${orderProductId}`, { ...exists, checked, qtyToSell: checked ? 1 : 0 });
  }
  sale.batchModalData = {
    ...sale.batchModalData,
    batches: modalBatches,
  };
  return sale;
}
export function updateBatchToSellQuantity(oldSale, { payload: { orderProductId, quantity } }) {
  const sale = { ...oldSale };
  const modalBatches = sale.batchModalData.batches;
  const { batchModalData: { productId } } = sale;
  const exists = modalBatches.get(`${orderProductId}`);
  if (exists) {
    if (`${orderProductId}` === '0') {
      modalBatches.set(`${orderProductId}`, { ...exists, qtyToSell: +quantity });
      const { outOfStockBatches } = sale;
      outOfStockBatches.set(`${productId}`, quantity);
    } else {
      const aboutToOverSell = +quantity > exists.qtyInStock;
      modalBatches.set(`${orderProductId}`, { ...exists, qtyToSell: aboutToOverSell ? exists.qtyInStock : quantity });
    }
  }
  sale.batchModalData = {
    ...sale.batchModalData,
    batches: modalBatches,
  };
  return sale;
}

export function aggregateBatchesToCart(oldSale, { payload: { product } }) {
  const sale = { ...oldSale };
  const modalBatches = sale.batchModalData.batches;
  const { cart, outOfStockBatches, inStockBatches } = sale;
  const {
    id: productId, brandName, resolvedPriceInUseValue, packSize
  } = product;
  let quantity = 0;
  const productInOutOfStock = outOfStockBatches.get(`${productId}`);
  const batchesAlreadyAddedToCart = inStockBatches.get(`${productId}`) || new Map();
  modalBatches.forEach((batch) => {
    const { orderProductId, checked } = batch;
    // delete dynamically added out of stock batch if not checked
    if (+orderProductId === 0 && !checked) {
      if (productInOutOfStock) {
        outOfStockBatches.delete(`${productId}`);
      }
    }
    // added all checked batches qty
    if (checked) {
      batchesAlreadyAddedToCart.set(`${orderProductId}`, +batch.qtyToSell);
      quantity += +batch.qtyToSell;
      // needed so that when we close a batch modal and open another, we should remember if a batch is checked or not
      inStockBatches.set(`${productId}`, batchesAlreadyAddedToCart);
    } else {
      batchesAlreadyAddedToCart.delete(`${orderProductId}`);
      inStockBatches.set(`${productId}`, batchesAlreadyAddedToCart);
    }
  });

  if (!quantity) {
    return oldSale;
  }
  cart.set(productId, {
    productId, brandName, resolvedPriceInUseValue, packSize, quantity: parseFloat(quantity),
    batches: modalBatches, product, missedSale: false
  });
  return {
    ...sumCart(sale),
    cart,
    batchModalData: {
      ...sale.batchModalData,
      openedBatchModal: false
    },
    outOfStockBatches,
    inStockBatches
  };
}

export function addOutOfStockBatch(oldSale, { payload: { quantity } }) {
  const sale = { ...oldSale };
  const modalBatches = sale.batchModalData.batches;
  const exists = modalBatches.get('0');
  if (exists) {
    modalBatches.set('0', { ...exists, qtyToSell: +quantity });
  } else {
    modalBatches.set('0', getOutOfStockBatch(+quantity));
  }
  const { outOfStockBatches, batchModalData: { product: { id: productId } } } = sale;
  outOfStockBatches.set(`${productId}`, quantity);
  sale.batchModalData = {
    ...sale.batchModalData,
    batches: modalBatches,
    outOfStockBatches
  };
  return sale;
}

export function toggleMissedSale(oldSale, { payload: { product: { productId } } }) {
  const sale = { ...oldSale };
  const { cart } = sale;
  const exists = cart.get(`${productId}`);
  if (exists) {
    cart.set(`${productId}`, {
      ...exists, missedSale: !exists.missedSale
    });
    sale.cart = cart;
  }
  return {
    ...sale,
    ...sumCart(sale),
    cart
  };
}

export const addProductDiscount = (oldSale, payload) => {
  const sale = { ...oldSale };
  const { cart } = sale;
  const { prodToDiscount, discount, isPercentage } = payload;
  const { productId } = prodToDiscount;

  const exists = cart.get(`${productId}`);
  if (exists) {
    cart.set(`${productId}`, {
      ...exists, discount, isPercentage
    });
    sale.cart = cart;
  }
  return {
    ...sale,
    ...sumCart(sale),
    cart
  };
};

export const holdSale = (oldSale, heldSale) => {
  const sale = { ...oldSale };
  const { salesOnHold } = sale;
  sale.salesOnHold = [...salesOnHold, heldSale];
  sale.cart = new Map();
  sale.percDiscount = 0;
  return {
    ...sale,
    ...sumCart(sale),
  };
};

export const returnHeldSale = (oldSale, payload) => {
  const sale = { ...oldSale };
  const { cart, salesOnHold } = payload;
  sale.cart = cart;
  sale.salesOnHold = salesOnHold;
  return {
    ...sale,
    ...sumCart(sale),
  };
};
