import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  FilterBox, FilterContainer, FilterHandle, FilterCreditTypo, FilterNameTypo,
  FilterImage, FilterGrid, FilterTypo, HeaderGrid, FilterHeader, SearchGrid,
  FilterSubHeader
} from './newOrderFilter.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import filterIcon from '../../../../assets/images/filter-icon.png';

const icon2 = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon2 = <CheckBoxIcon fontSize="large" />;
export default function ManualOrderFilter(props) {
  const {
    checkedIcon, icon, setState, state
  } = props;
  const { productType } = state;
  const renderFilterOptions = () => {
    const options = [
      { key: 'only_business_products', value: 'My Business Products' },
      { key: 'oga_pharmacy_market_product', value: 'OGApharmacy Marketplace Products' },
      { key: 'other', value: 'Other Products' },
    ];
    const handleSelectOption = (item) => {
      setState({ ...state, productType: item.key });
    };
    return options.map((item, index) => (
      <Grid key={item.key} xs={12}>
        <FilterHandle container justifyContent="space-between" onClick={() => handleSelectOption(item)}>
          <Grid item xs={10}>
            <FilterNameTypo>{item.value}</FilterNameTypo>
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ color: '#FCED50' }}
              checked={item.key === productType}
            />
          </Grid>
        </FilterHandle>
      </Grid>
    ));
  };
  return (
    <FilterContainer style={{ height: 'initial' }} container>
      <HeaderGrid>
        <Grid container alignItems="center">
          <Grid item>
            <FilterImage src={filterIcon} alt="" />
          </Grid>
          <FilterGrid item>
            <FilterTypo>Filter by: Product Type</FilterTypo>
          </FilterGrid>
          <br />
          <br />
          <br />
          {renderFilterOptions()}
        </Grid>
      </HeaderGrid>

    </FilterContainer>
  );
}
ManualOrderFilter.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.instanceOf(Object).isRequired
};

ManualOrderFilter.defaultProps = {
};
