import gql from 'graphql-tag';

export const ORDER_UPDATED_SUBSCRIPTION = gql`
  subscription onOrderUpdated($orderId: String!) {
    orderUpdated(orderId: $orderId) {
      id
      placeOrderStatus
    }
  }
`;

export const ORDER_UPLOADED_SUBSCRIPTION = gql`
  subscription onOrderUploaded($orderId: String!) {
    orderUploaded(orderId: $orderId) {
      id
      uploadStatus
    }
  }
`;
