import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../utils/utils';
import {
  GridWrapper, ItemTypo, ItemTypoBox
} from './filterPopper.styles';

const filterPopper = ({
  filterButtonEl, handleFilterButtonClick, handleStatus
}) => {
  const open = Boolean(filterButtonEl);

  const columns = ['All', 'Pending', 'Rejected', 'Confirmed'];

  return (
    <RenderPopper
      open={open}
      anchorEl={filterButtonEl}
      onClickAway={handleFilterButtonClick}
      popperPlacement="bottom-end"
    >
      <GridWrapper container>
        {columns?.map((column) => (
          <ItemTypoBox item container alignItems="center" onClick={(e) => handleStatus(e, column)}>
            <ItemTypo item container>
              {column}
            </ItemTypo>
          </ItemTypoBox>
        ))}
      </GridWrapper>
    </RenderPopper>
  );
};

filterPopper.propTypes = {
  filterButtonEl: PropTypes.shape(Object),
  handleFilterButtonClick: PropTypes.func.isRequired,
};

filterPopper.defaultProps = {
  filterButtonEl: {}
};

export default filterPopper;
