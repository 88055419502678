import {
  Grid, Typography, Paper, Checkbox, Divider, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled(Grid)`
  margin-bottom: .1rem;

  @media(min-width: 992px) {
  }
`;

export const ContactGrid = styled(Grid)`
`;

export const MainPaper = styled(Paper)`

`;

export const SearchGrid = styled(Grid)`
  padding: .5rem 0;
  align-items: center;
  justify-content: flex-end;
`;

export const ContactsGrid = styled(Grid)`
  margin: 15px 3rem;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #CCCCCC;
`;

export const HeaderText = styled(Typography)`
  font-size: .8rem;
  font-weight: 300;
  text-transform: capitalize;
  padding: 15px 20px 5px;
  color: #606060;
`;

export const LightDivider = styled(Divider)`
  color: #F0F0F0;
`;

export const UpdateButton = styled(IconButton)`
  align-items: center;
  padding: 15px 20px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 5px;
  height: 1rem;
  margin: 0 20px;

  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }
`;

export const UpdateButtonText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
