import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import currencyFormatter from '../../../shared/currencyFormatter';
import { ORDER_QUERY } from '../../../../queries/dispatch';
import { JSONParse } from '../../../../utils/json';
import {
  BodyCell, MainTableRow, ColorGrid, Dot
} from './returnRow.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import ActionPopper from './actionPopper';
import { Dots } from '../../../../assets/svgs';
import { GENERATE_ORDER_INVOICE_MUTATION } from '../../../../mutations/orders';

const ReturnRow = ({
  row, index
}) => {
  const {
    id, businessName, datePlaced: date, orderTotal, metaStatus, status, parentOrderId, categoryName, mainOrderTotal
  } = row;
  const navigate = useNavigate();
  const [action, setAction] = useState(null);
  const datePlaced = moment(date).format('DD/MM/YY (h:mm a)');
  const amount = currencyFormatter(orderTotal);
  const orderAmount = currencyFormatter(mainOrderTotal);
  const deliveryDate = moment(date).add(3, 'days').format('DD/MM/YY');
  const orderId = `#${id}`;
  const [generateRealTimeInvoice] = useMutation(GENERATE_ORDER_INVOICE_MUTATION);

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-ordersPage-${name}`}>
      <Dot background={bkg} />
      {name}
    </ColorGrid>
  );

  const getStatus = () => {
    if (status === 'CLOSED') {
      switch (metaStatus) {
        case 'ORDER_CLOSED':
          return getColorGrid('#07DE10', '#FF0F0F;', 'Closed');
        case 'ORDER_CANCELED':
          return getColorGrid('#FA3636', '#FF0F0F', 'Cancelled');
        default:
          return getColorGrid('#FA3636', '#FF0F0F', `${metaStatus}`);
      }
    } if (status === 'OPEN') {
      switch (metaStatus) {
        case 'ORDER_RECEIVED':
          return getColorGrid('#07DE10', '#00B588', 'Received');
        case 'ORDER_DISPATCHED':
          return getColorGrid('#606060', '#F29053', 'Dispatched');
        case 'ORDER_QUEUED_FOR_DISPATCH':
          return getColorGrid('#606060', '#F29053', 'Ready for ...');
        case 'ORDER_SORTED':
          return getColorGrid('#606060', '#F29053', 'Sorted');
        case 'ORDER_SENT':
          return getColorGrid('#606060', '#F29053', 'Order sent');
        case 'APPROVING':
          return getColorGrid('#606060', '#F29053', 'Approving');
        case 'RETURNING':
          return getColorGrid('#606060', '#F29053', 'Returning');
        case 'PREPARING_FOR_DISPATCH':
          return getColorGrid('#606060', '#F29053', 'Preparing for dispatch');
        case 'ORDER_APPROVED':
          return getColorGrid('#606060', '#F29053', 'Approved');
        case 'ORDER_CLOSED':
        default:
          return getColorGrid('#606060', '#F29053', `${metaStatus}`);
      }
    }
  };
  const erpCells = [
    datePlaced, orderId, categoryName, orderAmount, amount, businessName, deliveryDate,
  ];
  let returnData;

  if (metaStatus === 'ORDER_RETURNED') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useQuery(ORDER_QUERY, {
      fetchPolicy: 'cache-and-network',
      variables: { orderId: id },
    });
    returnData = data;
  }
  const products = [];

  const retrieveProducts = (orderSet) => {
    orderSet.forEach((result) => {
      const filterOrder = result.orderproductSet.filter((orderProductObj) => orderProductObj);
      const orderproductSet = filterOrder?.map((orderproduct) => orderproduct);
      products.push(...orderproductSet);
    });
  };

  const handleClick = (_id, orderStatus) => {
    if (orderStatus === 'ORDER_RETURNED') {
      const { businessOrder, orderDeliveryLocations } = returnData;
      const { supplierorderSet, dateCreated, business: { name, logo } } = businessOrder;
      const { delivery_location_id: deliveryLocationId } = JSONParse(returnData?.meta);
      const defaultDeliveryLocation = orderDeliveryLocations.find((bo) => String(bo.id) === String(deliveryLocationId));
      retrieveProducts(supplierorderSet);
      navigate('/return-order', {
        state: {
          id,
          name,
          logo,
          cart: products,
          businessDateCreated: moment(dateCreated).format('DD/MM/YYYY'),
          businessDateDelivered: moment(dateCreated).add(2, 'days').format('DD/MM/YYYY'),
          dateCreated,
          deliveryLocations: orderDeliveryLocations || [],
          selectedDeliveryLocation: defaultDeliveryLocation?.name,
          parentOrderId,
          orderIsReturned: true
        }
      });
      return;
    }
    if (orderStatus === 'ORDER_CANCELED' && !userPermissions?.includes('manage_orders_view_cancel_order')) {
      return toast.error('Not Authorized');
    }
    navigate(`/orders-admin/dispatch/${_id}`);
  };

  const returnCell = (val) => val || 'N/A';

  const renderBodyCells = () => erpCells.map((cell) => (
    <BodyCell key={cell} className={`mpAdmin-uat-manage-order-table-row-${index}`} onClick={() => handleClick(id, metaStatus)} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const handleGenerateInvoice = () => {
    generateRealTimeInvoice({
      variables: {
        orderId: id,
      }
    }).then(({ data }) => {
      const { generateRealTimeInvoice: { invoice } } = data ?? {};
      navigate(`/generate-invoice/${id}`, {
        state: { invoiceId: invoice?.id }
      });
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleAction = (event, type) => {
    if (type === 'invoice') {
      handleGenerateInvoice();
    } else {
      navigate(`/orders-admin/dispatch/${id}`);
    }
    handleOptions(event);
  };

  return (
    <MainTableRow item container style={index % 2 ? { backgroundColor: '#F3F9FF' } : { backgroundColor: '#FFF' }}>
      {renderBodyCells()}
      <BodyCell>{getStatus(metaStatus, status)}</BodyCell>
      {status === 'OPEN' && (
      <BodyCell className="mpAdmin-uat-manage-order-action-button" style={{ cursor: 'pointer' }}>
        <Dots fontSize="large" onClick={handleOptions} />
      </BodyCell>
      )}
      <ActionPopper
        action={action}
        handleAction={handleAction}
        row={row}
        handleClose={handleCloseModal}
      />
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
