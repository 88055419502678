import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { GET_ALL_SUPPLIER_ORDERS, GET_COMPLETED_MANUAL_SUPPLIER_ORDERS } from '../../../queries/orders';

import Order from './order';
import Loader from '../../shared/loader';
import { useStateValue } from '../../../providers/stateProvider';

const ManualOrderDashboardContainer = () => {
  const [{ business: { businessId, businessUser: businessUserId } }, dispatch] = Object.values(useStateValue());

  // const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  // const initialState = {
  //   currentPage: 1,
  //   currentPageCount: pageCount,
  // };

  // const [state, setState] = useState(initialState);
  // const {
  //   currentPage, currentPageCount
  // } = state;

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();


  // const handleChangePage = (_, newPage) => {
  //   setState({ ...state, currentPage: newPage + 1 });
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   sessionStorage.setItem('rowsPerPage', +event.target.value);
  //   setState({ ...state, currentPageCount: +event.target.value });
  // };

  const handleOrderItemCardClicked = (id) => {
    navigate(`/suppliers-orders/${id}/manual-order-dashboard`);
  };
  const variables = {
    pageCount,
    pageNumber
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  return (
    <Query
      query={GET_COMPLETED_MANUAL_SUPPLIER_ORDERS}
      fetchPolicy="cache-and-network"
      variables={variables}
    >
      {({ data, loading, error }) => {
        if (loading) return <Loader />;
        if (error) return `Error! ${error.message}`;
        const {
          reconciledManualSupplierOrders = [], supplierOrderTotalNumber = 0
        } = data;

        const supplierOrderItems = reconciledManualSupplierOrders
          .map(
            (supplierOrderItem) => {
              const {
                dateCreated, id, order: { business }, orderproductSet, total, savings, supplier
              } = supplierOrderItem || {};
              const { name: supplierName, logo: supplierLogo } = supplier || {};
              const { name, logo } = business || {};
              const orderVolume = orderproductSet.reduce(
                (previous, current) => previous + current.quantity, 0
              );
              return {
                dateCreated, id, name, logo, total, savings, orderVolume,
                supplierName, supplierLogo
              };
            }
          );

        return (
          <Order
            supplierOrders={supplierOrderItems}
            // handleChangePage={handleChangePage}
            // handleChangeRowsPerPage={handleChangeRowsPerPage}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
            page={pageNumber}
            currentPageCount={pageCount}
            totalCount={supplierOrderTotalNumber}
            onOrderItemCardClicked={handleOrderItemCardClicked}
          />
        );
      }}
    </Query>
  );
};

ManualOrderDashboardContainer.propTypes = {
};

ManualOrderDashboardContainer.defaultProps = {
};

export default ManualOrderDashboardContainer;
