import { DialogContent, Typography, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px !important;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 40rem !important;
  height: auto;
  box-shadow: none;
`;

export const CardTitle = styled(Typography)`
  font-size: 21px;
  font-weight: 700;
  color: #303030;
`;

export const SuccessTitle = styled(Typography)`
  font-size: 17px;
  font-weight: 700;
  color: #303030;
`;

export const Count = styled(Typography)`
  font-size: 17px;
  font-weight: 700;
  color: #303030;
  margin-top: 1rem;
`;

export const TitleDesc = styled(Typography)`
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  margin: 0.3rem 0 0.5rem 0;
`;

export const SuccessDesc = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 500;
  margin: 0.2rem 0 1rem 0;
`;

export const SuccessContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
`;

export const ListContainer = styled('div')`
  margin-top: 0.9rem;
`;

export const ListItems = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ErrDetails = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;
export const ErrorIconWrapper = styled('div')`
  background: #ffecec;
  border-radius: 50%;
  padding: 10px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
