import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import searchIcon from '../../assets/images/supplier/Search Icon.png';
import { SearchImage } from './customTableSearchField.styles';
import '../../assets/styles/customTableSearchField.scss';

export const CustomTableSearchField = ({
  id, handleChange, placeholder, searchText, disabled, focused
}) => {
  const focus = () => {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 500);
  };

  useEffect(() => {
    if (focused && document.getElementById(id)) {
      document.getElementById(id).focus();
    }
  }, [focused]);

  return (
    <div className="table__container">
      <div className="table-search__container">
        <input
          type="text"
          id={id}
          key={id}
          name={`${id}-tableSearch`}
          value={searchText}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          className="table-search__input"
        />
        <IconButton
          onClick={focus}
          className="table-search__button"
        >
          <SearchImage src={searchIcon} alt="search" />
        </IconButton>
      </div>
    </div>
  );
};

CustomTableSearchField.propTypes = {
  id: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  focused: PropTypes.bool
};

CustomTableSearchField.defaultProps = {
  searchText: '',
  placeholder: '',
  disabled: false,
  focused: false
};

export default CustomTableSearchField;
