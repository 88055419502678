import {
  Grid, Paper, TableCell, TableBody, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
`;

export const TableGrid = styled(Grid)`
  margin:  .5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const MainTableHead = styled(Grid)`
  width: 100%;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  color: #303030;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 40%;
      padding-left: 10px;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }

  @media(max-width: 991px) {
    padding: 8px;
    &:nth-child(1) {
      width: 50%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
`;

export const TitleGridContainer = styled(Grid)`
  margin: 2rem 1rem 2rem 0;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: #24292E;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const DivFullWidth = styled('div')`
  width: 100%;
`;
