import { gql } from '@apollo/client';

export const GET_RETURN_PRODUCTS = gql`
  query returns(
    $search: String
    $dateFrom: DateTime
    $dateTo: DateTime
    $pageCount: Int
    $pageNumber: Int
  ) {
    returns(
      search: $search
      dateFrom: $dateFrom
      dateTo: $dateTo
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      id
      dateCreated
      receiptNumber
      amountRefunded
      customer {
        name
      }
      returnSale {
        id
      }
    }
    returnsTotalNumber
  }
`;
