import { styled } from '@mui/material/styles';
import {
  Grid, Paper, CircularProgress, Typography
} from '@mui/material';

export const SearchPaper = styled(Paper)`
  background: #ffffff;
  width: ${({ width }) => (width)}px;
  padding: 0 2rem;
  max-height: 42rem;
  overflow-y: auto;
  border-radius: .75rem;

   @media(max-width: 991px) {
    border-radius: 40px;
    width: 47rem;
    max-height: 60rem;
    margin: 1rem 0;
  }
`;

export const ProductItem = styled(Grid)`
  align-items: center;
  cursor: pointer;
  padding: 1.3rem 0;

  &:hover {
    background: #F3F9FF
  }

  @media (max-width: 991px) {
    padding: 1rem;
  }
`;

export const ProdName = styled(Grid)`
  font-size: .875rem;
  font-weight: 400;
  line-height: .5rem;
  color: #000000;

  @media (max-width: 991px) {
    line-height: 3rem;
    font-size: 2rem;
  }
`;

export const LoaderDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #C5C5C5;
`;

export const EmptyItem = styled(Grid)`
  padding: 0;
  text-align: center;
  height: 100%;
  overflow: hidden;
`;

export const EmptyText = styled(Typography)`
  font-size: .875rem;
  font-weight: 400;
  color: #000000;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;
