import React from 'react';
import PropTypes from 'prop-types';
import {
  BodyCell, MainTableRow, ColorGrid, Dot,
} from './returnRow.styles';
import { JSONParse } from '../../../utils/json';

const ReturnRow = ({
  row,
}) => {
  // const navigate = useNavigate();
  const {
    name, contacts, status: statuses,
  } = row;
  const { mobile_number: mobile, primary_email_address: email } = JSONParse(contacts);

  const getColorGrid = (color, bkg, status) => (
    <ColorGrid item container className={`mpAdmin-uat-customer-category-table-${status}`}>
      <Dot background={bkg} />
      {status}
    </ColorGrid>
  );
  const getStatus = () => {
    switch (statuses) {
      case 'VERIFIED':
        return getColorGrid('#07DE10', '#00B588', 'Verified');
      case 'UNVERIFIED':
        return getColorGrid('#606060', '#F29053', 'Unverified');
      case 'SUSPENDED':
        return getColorGrid('#FA3636', '#FF0F0F', 'Suspended');
      default:
        return getColorGrid('white', '#BF0505', 'N/A');
    }
  };

  return (
    <MainTableRow>
      <BodyCell className="mpAdmin-uat-customer-category-table-name" style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{name}</BodyCell>
      <BodyCell style={{ cursor: 'pointer' }} className="mpAdmin-uat-customer-category-table-email">{email}</BodyCell>
      <BodyCell style={{ cursor: 'pointer' }} className="mpAdmin-uat-customer-category-table-mobile">{mobile}</BodyCell>
      <BodyCell>
        {getStatus()}
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
