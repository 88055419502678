import { styled } from '@mui/material/styles';
import {
  TableCell, Typography, Grid, Paper, TableRow
} from '@mui/material';
import { StTransfer } from '../../assets/svgs';
import CustomButton from '../customComponents/customButton';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const CartText = styled('span')`
  font-size: 12px;
  // margin-left: -3px;
  font-weight: 700;
  color: #FFFFFF;
`;

export const SearchGrid = styled(Grid)`
  padding: 2rem 1.5rem .5rem;
  align-items: center;
`;

export const TransferIcon = styled(StTransfer)`
  font-size: 1.2rem;
  color: '#606060';
  margin-right: 5px;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const MainTableHead = styled(TableRow)`
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;
`;

export const TCustomButton = styled(CustomButton)`
  border: 1px solid #000000;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  height: 3rem;
`;

export const TSCustomButton = styled(CustomButton)`
  border: 1px solid #E7E8E9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  height: 3rem;
  color: #858585;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
  align-items: center;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #606060;
  margin-top: .5rem;

  @media(max-width: 991px) {
    display: none;
  }
`;

export const FooterWrapper = styled(Grid)`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
`;
