import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PlaceholderInput = styled(Grid)`
  padding: 7px 10px;
  color: #24292E;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  
  width: 15rem;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #E7E8E9;
  border-radius: 8px;
  align-items: center;
`;
