import { gql } from '@apollo/client';

export const CREATE_BANNER_MUTATION = gql`
  mutation createBanner(
    $banners: [BannerInputType]
    $bannerPageId: String!
  ) {
    createBanner(
      banners: $banners
      bannerPageId: $bannerPageId
    ){
      message
    }
  }
`;

export const UPDATE_CATEGORY_BANNER_MUTATION = gql`
  mutation createBannerCategory(
    $banners: [BannerInputType]
  ) {
    createBannerCategory(
      banners: $banners
    ){
      message
    }
  }
`;
