import React from 'react';
import { Grid, Box } from '@mui/material';
import { ArrowForwardIosRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TSH, SalesReconciliation, EODReport } from '../../../assets/svgs';

const inventoryActivity = [
  {
    id: 0,
    icon: TSH,
    title: 'Total Sales history',
    desc: 'View total sales history',
    link: '/history',
    dataTest: 'salesHistory'
  },
  {
    id: 1,
    icon: SalesReconciliation,
    title: 'Sales Reconciliation',
    desc: 'View sales reconciliation',
    link: '/sales-reconciliation',
    dataTest: 'salesReconciliation'
  },
  {
    id: 2,
    icon: EODReport,
    title: 'EOD report',
    desc: 'View EOD report',
    link: '/eod-report',
    dataTest: 'eodReport'
  }
];

const ActivityThreeColCard = () => {
  const navigate = useNavigate();

  const onRouteHandler = (path) => {
    navigate(path);
  };

  return (
    <Grid container spacing={2}>
      {inventoryActivity?.map((card) => (
        <Grid item xs={6} xl={4} key={card?.id}>
          <Box p={4} bgcolor="background.paper" borderRadius={8}>
            <Box display="flex" alignItems="center">
              <card.icon fontSize="large" />
              <Box fontWeight="fontWeightMedium" marginX={2} fontSize={18}>
                {card?.title}
              </Box>
            </Box>
            <Grid>
              <Box
                fontWeight="fontWeightMedium"
                marginTop={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box fontWeight="fontWeightMedium" color="inherit">
                  {card?.desc}
                </Box>
                <Box
                  onClick={() => onRouteHandler(card?.link)}
                  paddingX={1.2}
                  paddingY={1}
                  borderRadius={4}
                  style={{ background: '#E6EAF0', cursor: 'pointer' }}
                  data-testid={card?.dataTest}
                >
                  <ArrowForwardIosRounded style={{ fontSize: '.8rem' }} />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default ActivityThreeColCard;
