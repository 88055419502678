import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Dialog, Grid, DialogContent, Slide
} from '@mui/material';
import { Mutation } from '@apollo/client/react/components';

import { DateSelectField } from '../affiliates/affiliatesDetails/inputFields';
import {
  DialogWrapperContainer, DialogHeaderContainer, DialogHeaderText, DialogFooterContainer,
  DialogCancelButton, DialogActionButtonText, DialogDoneButton, FormGrid, InputTextField,
  DialogHeaderSubText
} from './newProductBatchDialog.styles';

import { CREATE_BATCH_MUTATION } from '../../mutations/products';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const NewProductBatchDialog = ({
  open, handleCloseModal, stockItem
}) => {
  const initialState = {
    batchNo: '',
    expiryDate: '',
    quantity: '',
    unitCost: '',
  };
  const [state, setState] = useState(initialState);
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    const { batchNo } = state;
    if (!batchNo) setFormCompleted(false);
    else setFormCompleted(true);
  }, [state]);

  const handleCreateNewBatch = (createBatch) => {
    const {
      batchNo, expiryDate, quantity, unitCost
    } = state;
    createBatch({
      variables: {
        batchNo, expiryDate,
        quantity: Number(quantity || 0),
        unitCost: Number(unitCost || 0),
        productId: stockItem.product.id,
        supplierId: stockItem.product.suppliers[0].id
      }
    }).then((response) => {
      const { message } = response?.data?.createBatch || {};
      toast.success(message);
    }).then(() => handleCloseModal())
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const returnTextField = (field) => {
    const { name: fieldName, label, type } = field;
    const value = state[fieldName];
    if (type === 'date') {
      return (
        <DateSelectField
          field={field}
          value={value}
          handleChange={handleChange}
        />
      );
    }
    return (
      <InputTextField
        fullWidth
        variant="filled"
        size="small"
        label={label}
        value={value}
        type={type}
        name={fieldName}
        onChange={handleChange}
      />
    );
  };

  const topFormFields = [
    { name: 'expiryDate', label: 'Expiry Date', type: 'date' },
    { name: 'batchNo', label: 'Batch No', type: 'text' }
  ];

  const bottomFormFields = [
    { name: 'quantity', label: 'Quantity', type: 'text' },
    { name: 'unitCost', label: 'Unit Cost', type: 'text' }
  ];

  return (
    <Mutation mutation={CREATE_BATCH_MUTATION}>
      {(createBatch) => (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => ('')}
          maxWidth="md"
          fullWidth
        >
          <DialogWrapperContainer>
            <DialogHeaderContainer>
              <Grid item container xs={12} wrap="nowrap" alignItems="center">
                <DialogHeaderText>Create New Batch</DialogHeaderText>
              </Grid>
              <DialogHeaderSubText>Add a new batch for product</DialogHeaderSubText>
            </DialogHeaderContainer>

            <DialogContent>
              <FormGrid>
                {topFormFields.map((field) => returnTextField(field))}
              </FormGrid>
              <FormGrid>
                {bottomFormFields.map((field) => returnTextField(field))}
              </FormGrid>
            </DialogContent>

            <DialogFooterContainer container justifyContent="flex-end">
              <DialogCancelButton onClick={handleCloseModal}>
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>
              <DialogDoneButton
                disabled={!formCompleted}
                onClick={() => { handleCreateNewBatch(createBatch); }}
              >
                <DialogActionButtonText>
                  Save
                </DialogActionButtonText>
              </DialogDoneButton>
            </DialogFooterContainer>
          </DialogWrapperContainer>
        </Dialog>
      )}
    </Mutation>
  );
};

NewProductBatchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  stockItem: PropTypes.instanceOf(Object).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default NewProductBatchDialog;
