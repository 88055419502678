import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import affiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import Loader from '../../shared/loader';

import { GET_MY_BUSINESS } from '../../../queries/affiliates';

import { useStateValue } from '../../../providers/stateProvider';

import ProductMerchandise from '../productMerchandise/productMerchandise';
import {
  OutletContentWrapper,
  TitleGridWrapper,
} from './business.styles';

export default function Outlets() {
  const [, dispatch] = Object.values(useStateValue());

  const {
    dataLoading, error, data, refetch
  } = useQuery(GET_MY_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(localStorage.getItem('ogarx_business_id'))
    },
  });

  const updateAffiliateState = (loggedInAffiliate) => {
    dispatch({
      type: affiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        loggedInAffiliate,
        loggedInAffiliateRefetch: refetch
      }
    });
  };

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
    updateAffiliateState(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (dataLoading || !data) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <>
      {/* <Navigation /> */}

      <TitleGridWrapper>
        {/* <TitleGrid setContentKey={setKey} /> */}
      </TitleGridWrapper>
      <ProductMerchandise />

      <OutletContentWrapper>
        {/* <SideNav /> */}
        {/* <MainContent key={key} /> */}
      </OutletContentWrapper>
    </>
  );
}
