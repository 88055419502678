import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Paper, TableRow, TableBody, Grid
} from '@mui/material';
import GoToTop from '../../customComponents/scrollToTop';
import CTablePagination from '../../customComponents/cTablePagination';
import {
  TableContainerWrapper, TableComponent, HeaderCell, FooterGrid, OrderCountText,
  MainTableHead
} from './supplierProducts.styles';
import { PaymentCountText } from './supplierDetailsContainer.styles';
import { MainTableRow, BodyCell } from './returnRow.styles';
import { SUPPLIER_ORDER_PAYMENT_QUERY } from '../../../queries/suppliers';
import Loader from '../../shared/loader';
import { JSONParse } from '../../../utils/json';
import currencyFormatter from '../../shared/currencyFormatter';

const SupplierPayment = () => {
  const [showing, setShowing] = useState([1, 10]);

  const initialState = {
    currentPage: 1,
    currentPageCount: 10,
    data: {},
  };
  const [state, setState] = useState(initialState);

  const {
    currentPage, currentPageCount
  } = state;

  const handleChangePage = (_, newPage) => {
    if (newPage === currentPage) {
      setShowing([showing[0] + 10, showing[1] + 10]);
    } else {
      setShowing([showing[0] - 10, showing[1] - 10]);
    }
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    loading, error, data, refetch
  } = useQuery(SUPPLIER_ORDER_PAYMENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { supplierId: id },
  });

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;
  const supplierPayment = data?.supplierPayment;

  return (
    <>
      <TableContainerWrapper component={Paper}>
        <PaymentCountText style={{ paddingLeft: '5%' }}>
          Showing
          <b>
            {` ${showing[0]} - ${supplierPayment.length < showing[1] ? supplierPayment.length : showing[1]} `}
          </b>
          of
          <b>
            {` ${supplierPayment.length} `}
          </b>
        </PaymentCountText>
        <TableComponent aria-label="supplier products table">
          <MainTableHead>
            <TableRow>
              <HeaderCell>Payment Date</HeaderCell>
              <HeaderCell>Description</HeaderCell>
              <HeaderCell>Reference</HeaderCell>
              <HeaderCell>Channel</HeaderCell>
              <HeaderCell>Amount Paid</HeaderCell>
              <HeaderCell>Paid by</HeaderCell>
            </TableRow>
          </MainTableHead>
          <TableBody>
            {supplierPayment && supplierPayment?.length > 0
              ? supplierPayment
                .sort((a, b) => new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf())
                .map((row) => {
                  const { meta } = row;
                  const parsed = JSONParse(meta);
                  return (
                    <MainTableRow key={row.reference}>
                      <BodyCell component="th" scope="row">
                        {moment(row.dateCreated).format('DD-MMM-YYYY')}
                      </BodyCell>
                      <BodyCell component="th" scope="row">
                        {parsed.description || '-'}
                      </BodyCell>
                      <BodyCell component="th" scope="row">
                        {row.reference}
                      </BodyCell>
                      <BodyCell component="th" scope="row">
                        {parsed.via}
                      </BodyCell>
                      <BodyCell align="right" component="th" scope="row">
                        {currencyFormatter(row.amount)}
                      </BodyCell>
                      <BodyCell component="th" scope="row">
                        {parsed.paid_by.length > 21 ? `${parsed.paid_by.slice(0, 21)}...` : parsed.paid_by}
                      </BodyCell>
                    </MainTableRow>
                  );
                })
              : (
                <MainTableRow>
                  <BodyCell />
                  <BodyCell />
                  <BodyCell>
                    No payments made for this supplier yet
                  </BodyCell>
                  <BodyCell />
                  <BodyCell />
                  <BodyCell />
                </MainTableRow>
              )}
          </TableBody>
        </TableComponent>
        <FooterGrid item container xs={12} justifyContent="flex-end">
          <Grid item container justifyContent="flex-end">
            <CTablePagination
              component="div"
              count={supplierPayment.length}
              page={currentPage - 1}
              onChangePage={handleChangePage}
              rowsPerPage={currentPageCount}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </FooterGrid>
      </TableContainerWrapper>
    </>
  );
};

export default SupplierPayment;
