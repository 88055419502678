import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Grid, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';

export const InputSelectField = ({
  field, value, handleChange, disabled
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected(event.target.value);
    handleChange(event);
  };

  const renderFunc = (val) => val;

  return (
    <FormControl variant="filled" ref={selectRef} style={{ width: '100%' }}>
      <InputLabel id="select-label">Select</InputLabel>
      <Select
        labelId="select-filled-label"
        id="select-filled"
        value={value}
        disabled={disabled}
        renderValue={(val) => renderFunc(val)}
        onChange={handleInput}
        inputProps={{
          name: field.name,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {field.options.map(
          (option) => (
            <MenuItem key={option} value={option}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <p>{option}</p>
              </Grid>
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

InputSelectField.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

InputSelectField.defaultProps = {
  field: {},
  value: '',
  disabled: false
};
