import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@mui/material';
import { UnderlineGrid, Typo, StatusesGrid } from './tabsButtons.styles';

const TabsButtons = ({ active, setActive }) => (
  <>
    <Hidden mdDown>
      <StatusesGrid container item>
        <UnderlineGrid
          item
          onClick={() => setActive('homepage')}
          active={active === 'homepage'}
          width="10%"
          className="mpAdmin-uat-manageAffiliateDetailsPage-homePage"
        >
          <Typo active={active === 'homepage'}>Homepage</Typo>
        </UnderlineGrid>
        <UnderlineGrid
          item
          onClick={() => setActive('history')}
          active={active === 'history'}
          width="10%"
          className="mpAdmin-uat-manageAffiliateDetailsPage-history"
        >
          <Typo active={active === 'closed'}>History</Typo>
        </UnderlineGrid>
        <UnderlineGrid width="80%" style={{ padding: '10px 0', cursor: 'auto' }}>
          <Typo />
        </UnderlineGrid>
      </StatusesGrid>
    </Hidden>
    <Hidden mdUp>
      <StatusesGrid container item>
        <UnderlineGrid
          item
          onClick={() => setActive('homepage')}
          active={active === 'homepage'}
          width="30%"
          className="mpAdmin-uat-manageAffiliateDetailsPage-homePage"
        >
          <Typo active={active === 'homepage'}>Homepage</Typo>
        </UnderlineGrid>
        <UnderlineGrid
          item
          onClick={() => setActive('history')}
          active={active === 'history'}
          width="30%"
          className="mpAdmin-uat-manageAffiliateDetailsPage-history"
        >
          <Typo active={active === 'closed'}>History</Typo>
        </UnderlineGrid>
        <UnderlineGrid width="40%" style={{ padding: '26px 0', cursor: 'auto' }}>
          <Typo />
        </UnderlineGrid>
      </StatusesGrid>
    </Hidden>
  </>
);
TabsButtons.propTypes = {
  active: PropTypes.string,
  setActive: PropTypes.func.isRequired
};

TabsButtons.defaultProps = {
  active: '',
};

export default TabsButtons;
