import {
  Dialog,
  DialogContent,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px !important;
  }
`;

export const CardTitle = styled('span')`
  font-size: 18px;
  font-weight: 700;
  color: #303030;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 40rem !important;
  height: auto;
  box-shadow: none;
`;

export const TitleDesc = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 400;
`;
