import { styled } from '@mui/material/styles';
import {
  Grid, Typography, IconButton,
  TextField
} from '@mui/material';

export const DialogWrapperContainer = styled(Grid)`
  padding: 20px 50px;
  background: #FFFFFF;
`;

export const DialogHeaderContainer = styled(Grid)`
  padding: 24px;
`;

export const DialogHeaderText = styled(Typography)`
  font-weight: 600;
  color: #424242;
  font-size: 30px;
  margin-bottom: 10px;
`;
export const DialogHeaderSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;

export const DialogFooterContainer = styled(Grid)`
  padding: 40px 20px;
  background: #fff;
`;

export const DialogDoneButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #FCED50;
  color: #424242;
  margin-bottom: 40px;

  &:hover {
    background-color: #D2C647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 40px;
  border: 2px solid #424242;
`;

export const DialogActionButtonText = styled('p')`
  font-size: 16px;
  font-family: 'Montserrat';
`;
export const FormGrid = styled(Grid)`
  & > * {
    width: calc(50% - 0.75rem);
    margin: 0 1.5rem 1.5rem 0;

    :nth-child(2n) {
      margin-right: 0;
    }
  }
`;
export const InputTextField = styled(TextField)`
  width: '25ch',
`;
