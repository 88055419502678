import {
  Grid, Icon, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const WrapperContainer = styled(Grid)`
  position: relative;
  justify-content: center;
`;

export const CheckIcon = styled(Icon)`
  color: #6B6B6B;
  font-size: 30px;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const CheckButton = styled(IconButton)`
  position: absolute;
  color: #A3A3A3;
  background: #FCED50;
  padding: 20px;
  top: 1.5rem;
  right: 15rem;
  z-index: 10;
  border: 3px solid #e0e0e0;

  &:hover {
    background: #E2D444;
  }

  @media(min-width: 992px) {
    right: 17.5vw;
    padding: 4px;
  }
`;

export const ImgContainer = styled('div')`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled('img')`
  height: 32vh;
  max-height: 100%;
  border-radius: 50%;

  @media(min-width: 992px) {
    height: 32vh;
  }
`;
export const LicenseImage = styled('img')`
  height: 32vh;
  max-height: 100%;

  @media(min-width: 992px) {
    height: 32vh;
  }
`;
