export const loadingData = (payload) => {
  const { productsLoading, productsTotal, productsLoaded } = payload || 0;
  const loadedPercentage = (productsLoaded / productsTotal) * 100;
  return {
    ...(productsLoading !== undefined && { productsLoading }),
    ...(productsTotal && { productsTotal }),
    ...(productsLoaded && { productsLoaded }),
    ...(loadedPercentage && { loadedPercentage })
  };
};
