import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { RenderPopper } from '../../utils/utils';

const filterPopper = ({
  buttonEl, handleClick, batchNos
}) => {
  const open = Boolean(buttonEl);

  return (
    <RenderPopper
      open={open}
      anchorEl={buttonEl}
      onClickAway={handleClick}
      popperPlacement="bottom-end"
    >
      <Grid container direction="column">
        {batchNos?.map((batchNo) => (
          <Grid item container alignItems="center">
            <Typography item container style={{ padding: '12px 24px' }}>
              {batchNo}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </RenderPopper>
  );
};

filterPopper.propTypes = {
  buttonEl: PropTypes.shape(Object),
  handleClick: PropTypes.func.isRequired,
  batchNos: PropTypes.shape(Array),
};

filterPopper.defaultProps = {
  buttonEl: {},
  batchNos: []
};

export default filterPopper;
