import { useQuery } from '@apollo/client';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Grid, TableBody } from '@mui/material';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_SEARCHABLE_STOCK_TRANSFERS } from '../../../queries/stockTransfer';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import MainContent from '../../customComponents/mainContent';
import CustomExportButton from '../../downloads/exportModule/exportComponent';
import CustomSearchField from '../../shared/CustomSearchField';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import TablePagination from '../../shared/tablePagination';
import ReturnRowLogs from './returnRowLogs';
import {
  ButtonWrapper,
  FooterWrapper,
  MainTableHead,
  MenuButton,
  PaperWrapper,
  PrimaryTitle,
  SearchGrid,
  TableComponent,
  TableGrid, TableHeader,
  TitleGrid, TitleTextGridContainer
} from './stockTransferLogs.styles';

const headers = [
  'S/N', 'Date', 'Product Name', 'Qty Transferred', 'Batch#', 'Total Product Value', 'Location',
  'Handler', 'Status'
];

const StockTransferLogs = () => {
  const [prodBatches, setProdBatches] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [dates, setDates] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });

  const navigate = useNavigate();

  const handleSubmit = (dateRange) => {
    setDates(dateRange);
  };

  const variable = {
    dateFrom: moment(dates?.dateFrom).format('YYYY-M-DD'),
    dateTo: moment(dates?.dateTo).format('YYYY-M-DD')
  };
  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header}>{header}</TableHeader>
  ));

  const variables = {
    ...(searchText.length >= 3 ? { search: searchText } : { search: '' }),
    dateFrom: dates?.dateFrom,
    dateTo: dates?.dateTo,
    pageCount,
    pageNumber,
  };

  const { loading, error, data } = useQuery(GET_SEARCHABLE_STOCK_TRANSFERS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useLayoutEffect(() => {
    if (data && data.searchableStockTransfer) {
      const pBatches = [];
      setProdBatches(data?.searchableStockTransfer.forEach(({ result }) => {
        result.forEach((info) => pBatches.push(info));
      }));
      setProdBatches(pBatches);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    stockTransferTotalNumber = 0
  } = data || {};

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12}>
            <MenuButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            </MenuButton>
            <PrimaryTitle variant="h5">Stock Transfer Logs</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search"
              handleChange={(e) => setSearchText(e?.target?.value)}
              style={{ width: '70%' }}
            />
            <ButtonWrapper item container>
              <Grid item container xs={8}>
                <CustomDateRanges
                  handleSubmit={handleSubmit}
                  xs={12}
                  styles={{ height: '47px', border: '1px solid #bababa' }}
                />
              </Grid>
              <Grid item container xs={4} justifyContent="flex-end">
                <CustomExportButton
                  title="Export"
                  color="secondary"
                  fileName="stock_transfer"
                  icon="icon"
                  name="Stock transfer"
                  variable={variable}
                />
                {/* <CustomButton
                  type="secondary"
                  style={{ width: '6.5rem', height: '2.95rem', marginLeft: '5px' }}
                  onClick={() => handleExport()}
                >
                  <TableExport style={{ fontSize: '1.5rem', marginRight: '.4rem' }} />
                  <FilterText>Export</FilterText>
                </CustomButton> */}
              </Grid>
            </ButtonWrapper>
          </SearchGrid>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <MainTableHead item container>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody style={{ width: '100%' }}>
                    {prodBatches?.map((_row, indx) => (
                      <ReturnRowLogs
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>
          <FooterWrapper item container>
            {stockTransferTotalNumber > 0 && (
              <TablePagination
                total={stockTransferTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                hideRowsPerPage
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default StockTransferLogs;
