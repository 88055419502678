import {
  Grid, Typography, Paper, TableCell, TableRow, TableContainer, Table, TableHead
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const CardGridContainer = styled(Paper)`
    width: 300px;
    height: 210px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #CCCCCC;
    border: 0.5px solid #CCCCCC80;
    border-radius: 7px;
    opacity: 1;
    box-sizing: border-box;
    padding:15px 0;
    position: relative;
    cursor: pointer;

    @media(max-width: 776px){
      width: 454px;
      height: 210px;
    }
`;

export const CardHeader = styled(Grid)`
    height: 30px;
    border-bottom: 0.5px solid rgba(204,204,204,.45);
    justify-content: space-between;
`;

export const HeaderText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: #A3A3A3;
  text-transform: uppercase;
  padding-right: 15px;
  padding-bottom: 10px;
`;

export const CardContentGrid = styled(Grid)`
    margin-top: 35px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 70%;
`;

export const ContentHeader = styled(Typography)`
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
`;

export const ContentText = styled(Typography)`
    color: #424242;
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
`;

export const ContentMeta = styled(Typography)`
    font-size: 16px;
    color: #A3A3A3;
    margin-left: 20px;
`;

export const FooterMeta = styled(Grid)`
`;

export const BodyCell = styled(TableCell)`
  // font-size: 2rem;
  // padding: 25px;
  // border-bottom: 1px solid #e0e0e0;

  // &:first-child, :last-child {
  //   border-bottom: 0;
  // }
  // &:nth-child(2) {
  //   padding: 0;
  // }

  @media(min-width: 992px) {
    text-transform: capitalize;
    font-size: 1.2rem;
    line-height: 2.8;
    padding: 10px;
    font-size: .9rem;
  }
`;

export const MainTableRow = styled(TableRow)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
`;

export const TableComponent = styled(Table)`
  // min-width: 650px;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.5rem;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
`;

export const MainTableHead = styled(TableRow)`
  // display: none;

  // @media(min-width: 992px) {
  //   display: table-header-group;
  // }
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 12px 12px 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
  margin: 1rem 0 0 1.5rem;
`;
