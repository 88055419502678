import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, InputAdornment, } from '@mui/material';
import SearchIcon from '../../../assets/images/marketOrder/searchIcon.png';
import { CTextField, SearchImg, CircularProgressLoader } from './styles';

const CustomNewTextField = ({
  name, value, handleChange, loading, categoriesList, ...props
}) => {
  const isLarge = useMediaQuery('(min-width:992px)');

  return (
    <CTextField
      {...props}
      variant="outlined"
      value={value}
      name={name}
      className={`erp-uat-${name}`}
      size={isLarge ? 'small' : 'medium'}
      onChange={handleChange}
      categoriesList={categoriesList}
      InputProps={{
        autoComplete: 'off',
        'data-testid': name,
        startAdornment: (
          <InputAdornment position="start">
            <SearchImg alt="search icon" src={SearchIcon} />
          </InputAdornment>
        ),
        endAdornment: loading ? (
          <InputAdornment position="end">
            <CircularProgressLoader
              disableShrink
              size={18}
              thickness={4}
            />
          </InputAdornment>
        ) : ''
      }}
    />
  );
};

CustomNewTextField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  loading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  categoriesList: PropTypes.bool
};

CustomNewTextField.defaultProps = {
  name: '',
  value: '',
  loading: false,
  categoriesList: false
};

export default CustomNewTextField;
