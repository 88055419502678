import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Box } from '@mui/material';
import {
  TextFieldBox, FilterGrid, FilterText,
  MenuButton, CTextField, SetButton, ClearText, ArrowImg
} from './mobileFilter.styles';
import backImg from '../../../assets/images/marketOrder/Arrow.png';
import { useStateValue } from '../../../providers/stateProvider';

const MobileFilter = ({
  state, handleFilterChange, handleSetFilter, handleMobile, handleClear
}) => {
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());
  const textField = (field, select) => {
    const status = [
      { name: 'All', value: '' },
      { name: 'Verified', value: 'VERIFIED' },
      { name: 'Unverified', value: 'UNVERIFIED' },
      { name: 'Suspended', value: 'SUSPENDED' },
    ];
    const {
      name: fieldName, label, shrink
    } = field;
    const _value = state[fieldName];
    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          label={label}
          value={_value}
          size="large"
          name={fieldName}
          onChange={handleFilterChange}
          select={select}
          fullWidth
          InputProps={{
            style: { height: '8rem', fontSize: '2.5rem', borderRadius: '2.5rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '2rem', marginTop: '15px' },
            shrink
          }}
          SelectProps={select && {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  borderRadius: '10px'
                }
              }
            }
          }}
        >
          {select && status.map(({ name, value }) => (
            <MenuItem key={value} value={value} style={{ fontSize: '2rem' }}>
              {name}
            </MenuItem>
          ))}
        </CTextField>
      </TextFieldBox>
    );
  };

  const affiliateInput = [
    { name: 'affiliateName', label: 'Affiliate Name' },
    { name: 'phoneNumber', label: 'Phone Number' },
    { name: 'email', label: 'Email Address' }
  ];
  const statusField = { name: 'status', label: 'Status' };
  const select = true;
  const handleFilterSet = () => {
    handleSetFilter();
    handleMobile();
  };
  const handleClearFilter = () => {
    handleClear();
    handleMobile();
  };

  return (
    <Box sx={{ marginTop: '2rem', width: '100%' }}>
      <FilterGrid container>
        <FilterGrid item xs={4}>
          <MenuButton>
            <ArrowImg src={backImg} alt="back" onClick={handleMobile} />
          </MenuButton>
        </FilterGrid>
        <FilterGrid item xs={4}>
          <FilterText>Filter</FilterText>
        </FilterGrid>
        <FilterGrid item xs={4}>
          <ClearText data-testid="clearFilter" onClick={handleClearFilter}>Clear</ClearText>
        </FilterGrid>
      </FilterGrid>
      {affiliateInput.map((fields) => (
        textField(fields)
      ))}
      <TextFieldBox>
        {textField(statusField, select)}
      </TextFieldBox>
      <SetButton
        variant="contained"
        fullWidth
        data-testid="setFilter"
        onClick={handleFilterSet}
        disabled={!userPermissions?.includes('manage_affiliates_set_filter')}
        style={{
          backgroundColor: '#235A91', borderRadius: '15px',
          color: '#fff', height: '7rem', fontSize: '3rem'
        }}
      >
        Set Filter
      </SetButton>
    </Box>
  );
};

MobileFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  handleMobile: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired
};

MobileFilter.defaultProps = {
  state: {},
};

export default MobileFilter;
