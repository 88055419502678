import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  SampleCsvWrapper, SampleCsvDownload,
  CsvFileImage, CsvTitle, CsvDescription,
  CsvSubtitle, DownloadIconWrapper, DownloadIconImage,
  CsvTextWrapper, PercentageWapper
} from './filebar.styles';

import SupplierCSVImage from '../../assets/images/supplier/csv.png';
import DownloadIcon from '../../assets/images/supplier/download.png';

const COLOR_MAP = {
  ok: 'rgba(252,237,80,0.25)',
  err: 'rgba(255,0,0,0.25)'
};

export default function FileBar({
  csvName, csvSize,
  percent, action, getTemplate,
  onDelete, state, margin, background
}) {
  return (
    <SampleCsvWrapper style={{ margin }}>
      <PercentageWapper
        percentage={percent}
        style={{
          width: `${percent}%`,
          background: COLOR_MAP[state]
        }}
      />

      <SampleCsvDownload style={{ background }}>
        <CsvTextWrapper>
          <CsvFileImage src={SupplierCSVImage} alt="sample csv" />
          <CsvDescription>
            <CsvTitle>
              {csvName}
            </CsvTitle>
            <CsvSubtitle>
              {`Size: ${csvSize}`}
            </CsvSubtitle>
          </CsvDescription>
        </CsvTextWrapper>
        {
          (action === 'download') ? (
            <DownloadIconWrapper onClick={getTemplate}>
              <DownloadIconImage src={DownloadIcon} />
            </DownloadIconWrapper>
          ) : (
            <DownloadIconWrapper onClick={onDelete}>
              <DeleteIcon />
            </DownloadIconWrapper>
          )
        }
      </SampleCsvDownload>
    </SampleCsvWrapper>
  );
}

FileBar.propTypes = {
  csvName: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  csvSize: PropTypes.string,
  percent: PropTypes.number,
  onDelete: PropTypes.func,
  getTemplate: PropTypes.func,
  state: PropTypes.string,
  margin: PropTypes.string,
  background: PropTypes.string
};

FileBar.defaultProps = {
  csvSize: '0',
  percent: '0',
  onDelete: console,
  getTemplate: console,
  state: 'ok',
  margin: '20px',
  background: '#E0E0E0'
};
