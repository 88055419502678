import {
  Grid, Typography, Paper, IconButton, TableContainer, Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 0 20px;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleGridContainer = styled(Grid)`
  margin: 1rem 0 1.5rem;
  align-items: center;
`;

export const TextWrapper = styled(Grid)`
`;

export const ButtonsPaper = styled(Paper)`
  margin-top: 1rem;
  padding: 1.5rem 3rem;
  border-radius: 1rem;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #78AADA;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const DivFullWidth = styled('div')`
  margin: 1.5rem 0;
  width: 100%
`;
