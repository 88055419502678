import {
  Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NewMpLogo } from '../../../../assets/svgs';

export const OrderGridContainer = styled(Grid)`
  position: relative;
`;

export const Header = styled(Typography)`
  text-align: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 49px;
`;

export const HeaderSubText = styled(Grid)`
  width: 49%;
  margin: 1rem auto 0 auto;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  @media (max-width: 1450px) {
    width: 57%;
  }
  @media (max-width: 991px) {
    width: 100%;
    font-size: 1.7rem;
  }
`;

export const ContentBox = styled('div')`
  padding: 0rem;
  background: #ffffff;
  @media (max-width: 991px) {
  padding: 2rem 1.5rem 1rem 1.5rem;

  }
`;

export const ProductsBox = styled('div')`
  width: 100%;
  padding: 3rem 4rem;
`;

export const LogoContainer = styled(Grid)`
  width: 100%;
  background-color: #ECF6FF;
  box-shadow: 0px 2px 12px 0px #30303008;
  position: fixed;
  z-index: 10;
`;

export const InfoWrapper = styled(Grid)`
  width: 100%;
  background-color: #235A91;
  color: #FFFFFF;
  padding: 40px 0px;
  margin-top: 4rem;
`;

export const MpLogo = styled(NewMpLogo)`
  margin: auto;
  &.MuiSvgIcon-root {
    width: 70%;
    height: 90%;
  }

  @media (min-width: 992px) {
    &.MuiSvgIcon-root {
      width: 18%;
      height: 75px;
    }
  }
`;
