import {
  Grid, Typography, Divider, Paper, IconButton, Button
} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { styled } from '@mui/material/styles';

export const OverviewPaper = styled(Paper)`
  margin-top: 45px;
  margin-bottom: 20px;
  border-radius: 15px;
  width: 100%;

  @media (min-width: 992px) {
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
`;

export const OverviewHeader = styled('div')`
  align-items: center;
  padding: 28px 35px;

  @media (min-width: 992px) {
    padding: 1.2rem 2rem;
  }
`;

export const OverviewText = styled(Typography)`
  font-size: 30px;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const OverviewHeaderText = styled('p')`
  font-size: 30px;
  font-weight: 600;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const OverviewHeaderDate = styled('p')`
  color: #82849F;
  font-size: 25px;
  font-weight: 600;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 17px;
  }
`;

export const ContentGrid = styled(Grid)`
  padding: 2rem;
`;

export const HeaderBar = styled(Grid)`
  justify-content: space-between;
  padding-top: 20px;
`;

export const HeaderBarText = styled(Typography)`
  font-size: 1.1rem;
  color: #A3A3A3;
`;

export const ContentHeaderSubText = styled(Typography)`
  font-size: 1.6rem;
`;

export const ContentDivider = styled(Divider)`
  margin: 1rem 0;
  background-color: #E7E8E9;
`;

export const OverviewFooter = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding: 0;

  @media (min-width: 992px) {
    padding: 1.7rem 0;
  }
`;

export const OverviewFooterText = styled(Typography)`
  font-size: 29px;
  color: #235A91;
  font-weight: 700;
  margin-right: 15px;

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;

export const OverviewFooterButtons = styled(Grid)`
  justify-content: flex-end;
  margin-top: 10px;

  @media (min-width: 992px) {
    margin-top: 0px;
  }
`;

export const CancelButton = styled(Button)`
  border: 1px solid #235A91;
  padding: 17px 30px;
  &.MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
}
`;

export const LabelButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 40px;
  background: #ffffff;
  color: #235A91;
  border-radius: 15px;
  border: 1px solid #235A91;
  margin: 0 1.5rem;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &:disabled {
    border: 3px solid #235A91;
    opacity: .5;
  }

  @media(min-width: 992px) {
    height: 55px;
    padding: 0px 35px;
    border-radius: 5px;
    min-width: 200px;

    &:disabled {
      border: 1px solid #235A91;
      color: #235A91;

    }
  }
`;

export const PrintIcon = styled('img')`
  width: 18px;
`;

export const OrderButtonText = styled(Typography)`
  font-size: 22px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const OrderButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
  background-color: #235A91;
  color: #ffffff;
  border-radius: 5px;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &:disabled {
    color: #ffffff;
    background-color: #235A91;
    opacity: 0.5;
  }

  @media(min-width: 992px) {
    padding: 15px 25px;
    border-radius: 5px;
    min-width: 200px;
  }
`;

export const PlaceOrderIcon = styled(TelegramIcon)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const NavButton = styled(Button)`
`;

export const NavButtonText = styled(Typography)`
  font-size: 25px;
  font-weight: 500;

  ${'' /* &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  } */}

  @media(min-width: 992px) {
    font-size: 15px;
  }
`;

export const ReturnOrderButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  color: #235A91;
  padding: 0px 25px;
  border: 1px solid #235A91;
  min-width: 200px;
  margin: 0 1.5rem;
}
`;

export const ReturnOrderButtonText = styled(Typography)`
  font-size: 25px;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const ArrowLeft = styled(ArrowLeftIcon)`

  @media(max-width: 991px) {
    font-size: 3rem;
  }
`;

export const ArrowRight = styled(ArrowRightIcon)`

  @media(max-width: 991px) {
    font-size: 3rem;
  }
`;

export const ViewProduct = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  color: #235A91;
  cursor: pointer;
  display: flex;
    align-items: center;
`;
