import { useMutation } from '@apollo/client';
import {
  DialogActions, DialogContent,
  Grid,
  Slide
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  CREATE_SUPPLIER_MUTATION, UPDATE_SUPPLIER_MUTATION
} from '../../../mutations/suppliers';
import ModelActionTypes from '../../../providers/reducers/model/modelTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { JSONParse } from '../../../utils/json';
import { blockInvalidChar } from '../../../utils/mobileCheck';
import { validateEmail } from '../../auth/utils';
import { FilledCustomButton, OutlinedCustomButton } from '../../customComponents/customButton';
import CustomInputBase from '../../customComponents/customInputBase';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';
import SuccessDialog from '../../shared/successDialog';
import CreditDaysPopper from './creditDaysPopper';
import {
  CDialog,
  CircularProgressLoader,
  CloseIcon,
  DialogTitleSubText,
  DialogTitleText,
  GridWrapper
} from './individualSupplierDialog.styles';
import SavePopper from './savePopper';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualSupplierDialog = ({
  dialogOpen, closeDialog, fromProduct, refetchSuppliers, supplier
}) => {
  const initialState = {
    supplierName: '',
    term: 'Cash on delivery',
    mobile: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: 'Lagos',
    country: 'Nigeria',
    supplierId: '',
    providerPromo: '',
    markup: '1',
    supplierCreditDays: ''
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    mobileError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [creditDays, setCreditDays] = useState(14);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [creditDaysEl, setCreditDaysEl] = useState(false);
  const [editing, setEditing] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [dialogDesc, setDialogDesc] = useState({});

  const toggleSuccessModal = () => setSuccessModal(!successModal);

  const [, dispatch] = Object.values(useStateValue());

  const { nameError } = errorState;
  useEffect(() => {
    if (supplier) {
      const {
        id: supplierId, name: supplierName, contacts,
        paymentTerm: term, providerPromo, markup, meta
      } = supplier;
      const {
        address_line_one: addressLine1, address_line_two: addressLine2, primary_email: email, mobile_number: mobile,
      } = JSONParse(contacts) || {};
      const {
        city, country, credit_days: supplierCreditDays
      } = JSONParse(meta) || {};
      setEditing(true);
      setState((_state) => ({
        ..._state, supplierId, supplierName, term,
        addressLine1, addressLine2, city, country,
        email, mobile, providerPromo, markup, supplierCreditDays
      }));
    }
  }, [supplier, editing]);

  const reFetchModel = () => {
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { customer: null, reFetch: true }
    });
    setTimeout(() => dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { reFetch: false }
    }), 0);
  };

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    refetchSuppliers();
    reFetchModel();
    closeDialog();
  };

  const handleCreditDaysOpen = (el) => {
    setCreditDaysEl(creditDaysEl ? null : el);
  };

  const handleCreditDays = (days) => {
    setCreditDays(days);
    handleCreditDaysOpen(null);
  };

  const [createSupplier] = useMutation(CREATE_SUPPLIER_MUTATION);
  const addSupplier = (addMore) => {
    const {
      supplierName, term, mobile, email, addressLine1,
      addressLine2, city, country, providerPromo,
      markup, supplierCreditDays
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    createSupplier({
      variables: {
        supplierName,
        term: newTerm,
        mobile,
        email,
        addressLine1,
        addressLine2,
        city,
        country,
        logo: 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png',
        providerPromo,
        markup,
        creditDays: supplierCreditDays || '0'
      }
    })
      .then(() => {
        setDialogDesc({ title: 'Supplier Created', desc: 'You have successfully created a supplier.' });
        toggleSuccessModal();
        // toast.success(message);
        refetchSuppliers();
        if (!fromProduct) {
          reFetchModel();
        }
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [updateSupplier] = useMutation(UPDATE_SUPPLIER_MUTATION);
  const editSupplier = () => {
    const {
      supplierId, supplierName, term, mobile, email,
      addressLine1, addressLine2, city, country,
      providerPromo, markup, supplierCreditDays
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    updateSupplier({
      variables: {
        supplierId,
        supplierName,
        term: newTerm,
        mobile,
        email,
        addressLine1,
        addressLine2,
        city,
        country,
        logo: 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png',
        providerPromo,
        markup,
        creditDays: supplierCreditDays || '0'
      }
    })
      .then(() => {
        // const { message } = data?.updateSupplier || {};
        // toast.success(message);
        setDialogDesc({ title: 'Updated', desc: 'You have successfully updated supplier’s details' });
        toggleSuccessModal();
        refetchSuppliers();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    if (editing) return editSupplier();
    return addSupplier(addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const handleSaveButtonClick = () => {
    const { supplierName } = state;
    if (supplierName.length < 3) {
      return setErrorState({
        ...errorState, nameError: true
      });
    }
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'supplierName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 3)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'mobile' && value.length > 11) return;
    setState({ ...state, [name]: value });
  };

  const fields = [
    {
      name: 'supplierName', label: "Supplier's Name", error: nameError, helperText: 'Supplier name requires more than 3 letters', required: true,
      placeholder: "Enter Supplier's Name",
    },
    {
      name: 'term', label: 'Payment Term', options: ['Payment before delivery', 'Cash on delivery', 'On-credit payment'], required: true,
      placeholder: 'Select'
    },
    {
      name: 'mobile', label: 'Mobile', placeholder: 'Enter Mobile Number'
    },
    {
      name: 'providerPromo', label: 'Provider Promo', options: ['GPS'], placeholder: 'Select'
    },
    { name: 'email', label: 'Email Address', placeholder: 'Enter Email Address' },
    // { name: 'markup', label: 'Markup' },
    { name: 'supplierCreditDays', label: 'No. of Credit Days', placeholder: 'Enter No. of Credit Days ' },
    { name: 'city', label: 'City', placeholder: 'Select' },
    {
      name: 'addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line 1'
    },
    {
      name: 'country', label: 'Country', options: ['Nigeria', 'Uganda', 'Kenya'], required: true, placeholder: 'Select'
    },
    { name: 'addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line 2' },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, error, required, placeholder
    } = field;
    const value = state[fieldName];
    const numberFields = ['mobile', 'markup', 'supplierCreditDays'];
    if (['term', 'country', 'providerPromo'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          placeholder={placeholder}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
        />
      );
    }
    return (
      <CustomInputBase
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={numberFields.includes(fieldName) ? 'number' : 'text'}
        error={error}
        helperText={error && helperText}
        required={required}
        name={fieldName}
        placeholder={placeholder}
        onKeyDown={fieldName === 'mobile' && blockInvalidChar}
        onChange={handleChange}
        cSize="lg"
      />
    );
  };

  return (
    <>
      <CDialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
        data-testid="supplier-dialog"
      >
        <GridWrapper container item>
          <Grid container style={{ paddingTop: '1.5rem' }}>
            <Grid item xs={8}>
              <DialogTitleText>
                {editing ? 'Edit' : 'Add Supplier'}
              </DialogTitleText>
              <DialogTitleSubText>
                {editing
                  ? 'Edit Supplier Information To The Fields In The Form'
                  : 'Add Supplier Information To The Fields In The Form'}
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={4} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={2} style={{ paddingTop: '8px' }}>
              {fields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: '2rem' }}>
            <OutlinedCustomButton
              style={{ fontWeight: '700', marginRight: '1.2rem', fontSize: '12px' }}
              onClick={handleCloseDialog}
            >
              Cancel
            </OutlinedCustomButton>
            <FilledCustomButton
              disabled={loading}
              onClick={handleSaveButtonClick}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : editing ? 'Save' : 'Create'}
            </FilledCustomButton>
          </DialogActions>
        </GridWrapper>
      </CDialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />

      <CreditDaysPopper
        creditDays={creditDays}
        handleCreditDays={handleCreditDays}
        creditDaysEl={creditDaysEl}
        handleCreditDaysOpen={handleCreditDaysOpen}
      />

      <SuccessDialog
        openDialog={successModal}
        setOpenDialog={toggleSuccessModal}
        title={dialogDesc.title}
        desc={dialogDesc.desc}
        option="Ok"
      />
    </>
  );
};

IndividualSupplierDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  refetchSuppliers: PropTypes.func,
  fromProduct: PropTypes.bool,
};

IndividualSupplierDialog.defaultProps = {
  fromProduct: false,
  refetchSuppliers: () => {},
};

export default IndividualSupplierDialog;
