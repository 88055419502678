import {
  Grid, IconButton, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const StatisticsGridWrapper = styled(Grid)`
  overflow: hidden;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-top: 1rem;
    margin-bottom: .3rem;
  }
`;

export const TitleTextGridContainer = styled(Grid)`

`;

export const CIconButton = styled(IconButton)`
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin-right: 21.64px;

  @media(min-width: 992px) {
    margin-right: 5px;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  color: #000;
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;

export const TextWrapper = styled(Grid)`
  margin-left: 0;
`;

export const PrimaryTitle = styled(Typography)`
  color: #303030;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0.3rem;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  color: #606060;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;
