import { styled } from '@mui/material/styles';

import {
  Paper,
  Grid
} from '@mui/material';

const sm = '960px';

export const SupplierDetailCard = styled(Paper)`
  width: 90vw;
  height: 450px;
  border-radius: 7px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 25px;

  @media (min-width: 992px) {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const SupplierDetailCardHeader = styled('div')`
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${sm}) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const SupplierDetailCardWrapper = styled(Grid)`
  @media (max-width: ${sm}) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const OrderDetailInfoText = styled('p')`
  margin: 0;
  margin-bottom: 10px;
  font-size: 25px;

  @media (min-width: 992px) {
    font-size: 17px;
  }
`;

export const SupplierDetailCardActionButton = styled('button')`
  color: #A3A3A3;
  font-size: 18px;
  margin: 0;
  background-color: white;
  border: none;
  text-decoration: underline;

  @media (max-width: ${sm}) {
    font-size: 10px;
  }
`;

export const OrderDeliveryNotesText = styled('p')`
  color: #424242;
  font-size: 20px;

  @media (max-width: ${sm}) {
    font-size: 12px;
  }
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
  margin-right:0px;
  justify-content: center;
  font-size: 25px;

  @media (min-width: 2560px) {
    margin-right: 22%;
  }

  @media (max-width: 1199px) {
    margin-left: 7.5rem;
    margin-right: 8rem;
  }

  @media (max-width: 991px) {
    margin: 0 1rem;
  }
`;
