import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  HeaderText, AffiliateSearchCardContainer, AffiliateSearchIcon,
  TypoGrid, AffiliateSearchTypo
} from './affiliateCard.styles';

export default function AffiliateSearchCard({
  id, logo,
  name, location,
  handleOnClickCard,
  cardSelected
}) {
  const navigate = useNavigate();
  return (
    <AffiliateSearchCardContainer
      onClick={() => handleOnClickCard(navigate, id)}
      selected={cardSelected}
      container
      alignItems="center"
    >
      <Grid container item xs={9} alignItems="center">
        <Grid item xs={1}>
          <AffiliateSearchIcon src={logo} alt="supplier logo" />
        </Grid>
        <TypoGrid item container direction="column" xs={10}>
          <AffiliateSearchTypo>{name}</AffiliateSearchTypo>
          <AffiliateSearchTypo>{location}</AffiliateSearchTypo>
        </TypoGrid>
      </Grid>
      <Grid item xs={3} style={{ textAlign: 'right' }}>
        <HeaderText>
          {`af${id}`}
        </HeaderText>
      </Grid>
    </AffiliateSearchCardContainer>
  );
}

AffiliateSearchCard.propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  handleOnClickCard: PropTypes.func,
  cardSelected: PropTypes.bool
};
AffiliateSearchCard.defaultProps = {
  handleOnClickCard: (navigate, id) => navigate(`/affiliates/${id}/details`),
  cardSelected: false
};
