import { styled } from '@mui/material/styles';
import {
  Grid, Button, ButtonGroup, TextField, IconButton, TableRow, TableCell,
  InputBase, Box, Paper
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';

export const MainTableRow = styled(TableRow)`
  cursor: pointer;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 16px;
  line-height: 2.5;
  color: #606060;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(min-width: 992px) {
    &:nth-child(1), &:nth-child(4) {
      width: 12%;
    }
    &:nth-child(2) {
      width: 21%;
    }
    &:nth-child(3), &:nth-child(5) {
      width: 14%;
    }
    &:nth-child(6) {
      width: 20%;
    }
  }
`;

export const TableColumnRows = styled('div')`
  display: flex;
  margin: 0 35px;
`;

export const TableColumnRowText = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 23px;
  white-space: nowrap;
  padding: 12px 0;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const TableColumnRowIcon = styled('p')`
  font-family: monospace;
  font-size: 1.5rem;
  border: 2px solid goldenrod;
  border-radius: 50%;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 1px 10px;
  font-weight: 900;
  color: goldenrod;
  margin: .4rem 0;

  &:hover {
    opacity: 0.5;
  }

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const TableColumnRowAction = styled('span')`
`;

export const ActionsContainer = styled(Grid)`
  width: 220px;
  height: 3rem;
  justify-content: center;

  @media(min-width: 992px) {
    height: 2.7rem;
  }
`;

export const ActionButton = styled(Button)`
  font-size: 3rem;
  padding: 0 30px;
  border: 2px solid #E2E2E2;

  &:disabled {
    color: #424242;
    border: 2px solid #E2E2E2;
    border-right: #E2E2E2;
  }

  @media(min-width: 992px) {
    font-size: 1.2rem;
    padding: 5px 13px;
    border: 1px solid #F0F0F0;

    &:disabled {
      border: 1px solid #F0F0F0;
    }
  }
`;

export const InputButton = styled(Button)`
  font-size: 1rem;
  padding: 5px 24px;
  font-weight: 400;
  background-color: #F0F0F0;
  width: 3.7rem;

  &:disabled {
    color: #424242;
    border: 1px solid #F0F0F0;
    border-right: #F0F0F0;
  }
`;

export const InputButtonGroup = styled(ButtonGroup)`
  ${'' /* width: 50%;

  @media(min-width: 992px) {
    width: 60%;
    margin-right: 5%;
  } */}
`;

export const ActionTextField = styled(TextField)`
  background: #FFFFFF;
  & ::placeholder {
    margin-left: 8px
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
    background-color: #E2E2E2;
  }
  & .MuiOutlinedInput-input {
    padding: 15.5px 5px;
    text-align: center;
    font-size: 3rem;
    width: 11rem;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #F0F0F0;
    border-width: 0;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #F0F0F0;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #F0F0F0;
  };

  @media(min-width: 992px) {
    .MuiOutlinedInput-root {
      border-radius: 0;
      background-color: #F0F0F0;
    }
    & .MuiOutlinedInput-input {
      padding: 13px 5px;
      text-align: center;
      font-size: 1rem;
      width: 3.2rem;
    };
  }
`;

export const CheckIcon = styled(Check)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const CloseIcon = styled(Close)`
  font-size: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;
export const CheckIconContainer = styled(Grid)`
    cursor: pointer;
    padding: 4px;
    background-color: transparent;
    border: 2px solid;
    border-radius: 50%;
    font-size: 1px;
`;

export const ButtonContainer = styled(Grid)`
  width: 50px;
  textAlign: center;
`;

export const RemoveButton = styled(Button)`
  font-weight: 500;
  font-size: 12px;
  color: #303030;
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 40%;
  border-radius: 10px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 16px 12px;
    padding-right: 5px;
    line-height: normal;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const MobileContainer = styled(Paper)`
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 100%;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: 18px;
`;

export const DateText = styled(Grid)`
  font-weight: 600;
  font-size: 2.5rem;
  padding: 1rem 1rem 1rem 2rem;
`;

export const HeaderText = styled(Grid)`
  font-weight: 600;
  font-size: 2rem;
`;

export const DetailText = styled(Grid)`
  text-align: right;
  font-size: 2rem;
  overflow-wrap: anywhere;
`;

export const TextBox = styled(Grid)`
  padding: 0 2rem 2rem 2rem;

  // @media(max-width: 991px) {
  //   height: .5rem;
  //   // padding: 0 2rem 9rem 2rem;
  // }
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;
