import {
  Grid, Typography, Paper
} from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeftRounded';

import { styled } from '@mui/material/styles';

export const PaperContainer = styled(Paper)`
  position: fixed;
  right: ${(props) => (props.open ? '0' : '-614px')};
  bottom: 115px;
  -webkit-transition: right .2s;
  transition: right .2s;
  z-index: 60;
  width: 650px;
  height: 320px;
  background-color: #e8e8e8;
`;

export const TeaserGrid = styled(Grid)`
  float: left!important;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 320px;
  color: #424242;
  background-color: #FCED50;
  cursor: pointer;
`;

export const ProvidersGrid = styled(Grid)`
  float: left!important;
  width: 614px;
  height: 320px;
  padding: 25px 45px;
  position: relative;
  align-items: center;
  justify-content: space-around;
`;

export const LoanText = styled(Typography)`
  position: absolute;
  top: 55%;
  left: 50%;
  width: 270px;
  height: 36px;
  line-height: 36px;
  letter-spacing: 5px;
  font-size: 22px;
  color: #424242;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transform: translate(-50%,-50%) rotate(-90deg);
  transform: translate(-50%,-50%) rotate(-90deg);
  text-align: center;
  overflow: hidden;
`;

export const ArrowIcon = styled(ChevronLeft)`
  position: absolute;
  top: 10%;
  left: -10%;
  font-size: 2.5rem;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s,-webkit-transform .2s;
  -webkit-transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};

  @media(max-width: 992px) {
    font-size: 2.5rem;
  }
`;

export const PayhippoImage = styled('img')`
  width: 20rem;
  cursor: pointer;

  @media(min-width: 992px) {
    width: 16rem;
  }
`;

export const MCFImage = styled('img')`
  width: 10rem;
  cursor: pointer;

  @media(min-width: 992px) {
    width: 8rem;
  }
`;
