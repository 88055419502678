import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'react-mui-dropzone';
import { ThemeProvider } from '@mui/material/styles';

import {
  theme, TitleText, DropZoneContainer, BackgroundImage,
  MiddleGroup, Dash, MiddleText, BrowseFileButton
} from './supplierCvsDropzone.styles';
import UploadImage from '../../../assets/images/supplier/uploadIcon.png';

const DropzoneContent = () => (
  <DropZoneContainer>
    <BackgroundImage src={UploadImage} />
    <TitleText>
      Drag and drop your csv file here
    </TitleText>
    <MiddleGroup>
      <Dash />
      <MiddleText>
        or
      </MiddleText>
      <Dash />
    </MiddleGroup>
    <BrowseFileButton>
      Select from your computer
    </BrowseFileButton>
  </DropZoneContainer>
);

export default function SupplierCsvDropzone({
  handleFile
}) {
  return (
    <ThemeProvider theme={theme}>
      <DropzoneArea
        onChange={handleFile}
        acceptedFiles={['.csv']}
        dropzoneText={<DropzoneContent />}
        fileslimit={1}
      />
    </ThemeProvider>
  );
}

SupplierCsvDropzone.propTypes = {
  handleFile: PropTypes.func.isRequired,
};
