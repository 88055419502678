import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import registerServiceWorker from './registerServiceWorker';
import getApolloClient from './graphql';
import theme from './assets/theme';
import App from './components/App';
import StateProvider from './providers/stateProvider';
import './assets/styles/style.css';
import CToaster from './components/shared/CToaster';

const root = ReactDOM.createRoot(document.getElementById('app'));
getApolloClient().then((client) => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CToaster />
        <BrowserRouter>
          <ApolloProvider client={client}>
            <StateProvider>
              <App />
            </StateProvider>
          </ApolloProvider>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
});

if (module.hot) {
  module.hot.accept();
}

registerServiceWorker();
