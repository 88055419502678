import {
  Grid, Typography, Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 0 20px;
  padding-bottom: 1.5rem;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  padding: 15px 0px 8px 0px;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const ProductName = styled(Typography)`
  font-size: 1.5rem;
  color: #24292E;
  font-weight: 600;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const Back = styled('div')`
  display: flex;
  gap: 0.1rem;
  align-items: center;
  cursor: pointer;
  color: #235A91;
  font-size: 14px;
  font-weight: 500;
`;
