import {
  Grid, Typography, IconButton, TableContainer, Table,
  TableHead, TableRow, TableCell, Box, Paper, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const TableGridContainer = styled(Grid)`
  padding: 30px 0px;

  @media(max-width: 991px) {
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  overflow-y: hidden;
  padding: 10px 20px;
`;

export const TableOptionsTab = styled(Grid)`
  justify-content: space-between;
  padding: 0;

`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const TableComponent = styled(Table)`
  min-width: 650px;
  padding: 0px 10px;
`;

export const MainTableHead = styled(TableHead)`
  display: none;
  background-color: #F3F9FF;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const PaymentTableTitle = styled(Typography)`
  font-size: 20px;
  color: #303030;
  font-weight: 700;
  margin: 20px;
  line-height: 23px;


  @media(max-width: 991px) {
    font-size: 3rem;
    margin: 9rem 0 5rem;
    text-align: center;
  }
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;
  padding: 1.5rem 0;

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const PaymentHistoryHeaders = styled('div')`
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  padding: 28px 35px;
  margin-top: 30px;
`;

export const PaymentColumnHeaderTitle = styled(TableCell)`
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  margin: 0;
  white-space: nowrap;

  @media(min-width: 992px) {
    font-size: 17px;
  }
`;

export const PaymentRowText = styled(TableCell)`
  color: #606060;
  font-size: 20px;
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: 992px) {
    width: 340px;
    line-height: 23px;
    font-size: 17px;
  }
`;

export const NoPaymentText = styled(Typography)`
  color: #424242;
  font-size: 20px;
  margin: 20px;

  @media (min-width: 992px) {
    line-height: 23px;
    font-size: 18px;
  }
`;

export const PaginationGrid = styled(Grid)`
  margin-bottom: 30px;

  @media(max-width: ${sm}) {
    margin-bottom: 10px;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;

export const InvoiceButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #FCED50;
  border-radius: 10px;

  &:hover {
    background-color: #D2C647;
  }

  @media (min-width: 992px) {
    line-height: 23px;
    font-size: 18px;
  }

`;

export const MobileContainer = styled(Paper)`
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 97%;
`;

export const HeaderText = styled(Grid)`
  font-weight: 600;
  font-size: 2rem;
`;

export const DetailText = styled(Grid)`
  text-align: right;
  font-size: 2rem;
  justify-content: flex-end;
  overflow: hidden;
`;

export const TextBox = styled(Grid)`
  padding: 0 2rem 2rem 2rem;
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;

export const DateText = styled(Typography)`
  font-weight: 600;
  font-size: 2.5rem;
  padding: 2rem;
`;

export const SearchContainer = styled(Paper)`
  align-items: center;
  padding: 4rem;
  border-radius: 3rem;
  margin: 4rem 1rem;

  & .MuiAutocomplete-popper {
    z-index: 14000;
  }

`;

export const SearchTextField = styled(TextField)`
  background: #ffffff;
  border-radius: 2rem;
  border: 1.5px solid #d9dbe9;
  & ::placeholder {
    color: #24292e;
    opacity: 1;
  }

  & .MuiOutlinedInput-input {
    padding: 25px;
    font-size: 20px;
  }
  & .MuiOutlinedInput-root {
    font-size: 2.2rem;
    border-radius: 10px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffffff;
    border-width: 0;
  }
  }
`;

export const SearchButton = styled(IconButton)`
  cursor: pointer;
  padding: 14px 20px;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #d2c647;
  }

  &:disabled {
    color: #424242;
  }

  @media (min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 30px;

  @media (min-width: 992px) {
    width: 30px;
  }
`;
