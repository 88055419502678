import {
  Grid, Typography, Paper, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled(Grid)`

`;

export const NameGrid = styled(Grid)`

`;

export const ContactGrid = styled(Grid)`
`;

export const AffiliatePaper = styled(Paper)`
  position: relative;
  border-radius: 20px;
  padding-bottom: 15px;
  min-height: 23.5rem;

  @media(min-width: 992px) {
    border-radius: 6px;
    min-height: 31.1rem;
  }
`;

export const ContactPaper = styled(Paper)`
  border-radius: 20px;
  padding: 25px 50px;
  min-height: 35.5rem;

  @media(min-width: 992px) {
    border-radius: 6px;
    padding: 18px 30px;
    min-height: 31.1rem;
  }
`;

export const SubHeaderText = styled(Typography)`
  color: #A3A3A3;
  padding: 18px 20px;

  @media(max-width: 991px) {
    font-size: 2rem;
    padding: 25px 35px;
  }
`;

export const ContactHeaderText = styled(Typography)`
  font-weight: 600;
  padding-bottom: 14px;

  @media(max-width: 991px) {
    font-size: 2.6rem;
    padding-bottom: 30px;
  }
`;

export const LightDivider = styled(Divider)`
  background-color: #D6D6D6;

  @media(min-width: 992px) {
    background-color: #F0F0F0;
  }
`;

export const BottomContainer = styled(Grid)`
  margin: 5rem 3rem 0;
`;

export const ItemImage = styled('img')`
  width: 10rem;
  position: absolute;
  top: 20px;
  left: 2.5rem;

  @media(min-width: 992px) {
    width: 8rem;
    top: 5px;
    left: 2rem;
  }
`;

export const NormalText = styled(Typography)`
  font-size: 20px;
  margin-bottom: 30px;

  &:nth-child(3) {
    margin-bottom: 60px;
  }

  @media(max-width: 991px) {
    font-size: 1.8rem;
    margin-bottom: 5px;

    &:nth-child(3) {
      margin-bottom: 10px;
    }
  }
`;

export const ContactText = styled(Typography)`
  font-size: 20px;
  margin-bottom: 2px;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const NormalText2 = styled(Typography)`
  font-size: 1.55rem;
  margin-bottom: 0;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-bottom: 25px;
  }
`;

export const NameText = styled(Typography)`
  font-size: 3rem;
  margin-bottom: 30px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 25px;
    margin-bottom: 30px;
  }
`;

export const LowerGrids = styled(Grid)`
  align-items: center;
  margin-bottom: 10px;

  @media(min-width: 992px) {
    margin-bottom: 25px;
  }
`;

export const LowerLighterText = styled(Typography)`
  margin-right: 35px;
  color: #A3A3A3;
  font-size: 1.7rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const LowerLighter2Text = styled(Typography)`
  margin-left: 10px;
  margin-right: 45px;
  color: #A3A3A3;
  font-size: 1.7rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const LowerDarkerText = styled(Typography)`
  margin-left: 5px;
  font-weight: 500;
  font-size: 1.7rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const IconImage = styled('img')`
  height: 55px;

  @media(min-width: 992px) {
    height: 35px;
  }
`;

export const ContactsGrid = styled(Grid)`
  margin-top: 35px;

  @media(min-width: 992px) {
    margin-top: 15px;
  }
`;

export const SmallHeading = styled(Typography)`
  color: #A3A3A3;
  font-size: 1.4rem;

  @media(min-width: 992px) {
    font-size: .75rem;
  }
`;
