import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BlueEyeIcon } from '../../../../assets/svgs';
import {
  ActionButton,
  BodyCell,
  MainTableRow,
  TextWrapper
} from './returnRow.styles';
import { JSONParse } from '../../../../utils/json';

const ReturnRow = ({ row, index }) => {
  const {
    id,
    dateCreated,
    generatedBy,
    url
  } = row;
  const dategenerated = moment(dateCreated).format('DD MMM YYYY h:mm a');
  const { last_name: lastName, first_name: firstName } = JSONParse(generatedBy?.profile) || {};

  const fullName = `${firstName} ${lastName}`;

  const cells = [index + 1, dategenerated, id, fullName];

  const returnCell = (value) => value || '-';

  const handleDownload = () => {
    window.open(
      `${url}`,
      '_blank'
    );
  };

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell key={cell}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow item container key={id}>
      {renderBodyCells()}
      <BodyCell>
        <ActionButton
          data-testid="create-product"
          onClick={handleDownload}
        >
          <BlueEyeIcon style={{ fontSize: '1.5rem', }} />
          <TextWrapper>
            View
          </TextWrapper>
        </ActionButton>
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number
};

ReturnRow.defaultProps = {
  row: {},
  index: 0
};

export default ReturnRow;
