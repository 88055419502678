import React from 'react';
import {
  DialogContent,
  Slide,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../assets/images/Checksuccesful.gif';
import {
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  ButtonDarkStyled,
  FooterStyled,
  DialogContainer
} from './successDialog.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SuccessDialog = ({
  openDialog, setOpenDialog, title, desc, option, refetch, therapeuticType
}) => {
  const handleClose = async () => {
    if (refetch) refetch();
    setOpenDialog(false);
  };

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled therapeuticType={therapeuticType}>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={success} alt="warning" width={155} />
            </Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled therapeuticType={therapeuticType}>{desc}</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <ButtonDarkStyled
            onClick={handleClose}
            fullWidth
          >
            {option}
          </ButtonDarkStyled>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

SuccessDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  option: PropTypes.string,
  refetch: PropTypes.func.isRequired,
  therapeuticType: PropTypes.bool
};

SuccessDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  title: '',
  desc: '',
  option: '',
  therapeuticType: false
};

export default SuccessDialog;
