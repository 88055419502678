import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PlaceholderInput = styled(Grid)`
  padding: 5px 7px;
  color: #24292E;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  align-items: center;
`;

export const WrapperInput = styled(Grid)`
  color: #24292E;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  width: 11rem;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #E7E8E9;
  border-radius: 8px;
  align-items: center;
  .date-picker-wrapper {
    width: 100%;
  }
`;
