import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Hidden, Grid } from '@mui/material';
import moment from 'moment';
import CustomButton from '../../customComponents/customButton';
import currencyFormatter from '../../shared/currencyFormatter';
import ReturnRow from './returnRow';
import {
  CIconButton, KeyboardArrowLeft, PrimaryTitle, SecondaryTitle, GridContainer, PaperCard,
  CardLogo, CardText, CardHeaderGrid, CardHeaderText, NameHeaderText,
  TableHeaderCell, TotalsGrid
} from './closedOrders.styles';
import { PURCHASE_ORDER_QUERY, GET_SUPPLIER_CLOSED_ORDER } from '../../../queries/orders';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import { JSONParse } from '../../../utils/json';
import { OgaToken } from '../../../graphql/token';
import DownloadPopper from './downloadPopper';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';

const headers = [
  'Product Items', 'Qty Ordered', 'Qty Received', 'Unit cost', 'Price'
];

const AffiliateFormDetail = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(open ? null : event.currentTarget);
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const { loading: poLoading, data: poData } = useQuery(PURCHASE_ORDER_QUERY, {
    variables: { supplierOrderId: id },
    fetchPolicy: 'no-cache',
  });

  const items = poData?.supplierOrderPurchaseOrders || [];

  const handleDownload = () => {
    window.open(
      `${OgaToken.SERVER_LINK}download_po_order_invoice/${id}`,
      '_blank'
    );
  };

  const {
    loading, error, data
  } = useQuery(GET_SUPPLIER_CLOSED_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const {
    order: { business, metaStatus }, lastUpdated, orderproductSet, total
  } = data?.supplierOrder ?? '';

  const businessContact = JSONParse(business.contacts.replace(/'/g, '"').replace('None', '"Lagos"'));

  const prods = [];
  prods.push(...orderproductSet);
  const dateCreated = moment(lastUpdated).format('DD/MM/YYYY');
  const deliveryDate = moment(lastUpdated).add(2, 'days').format('DD/MM/YYYY');
  const {
    mobile_number: mobileNumber, primary_email_address: primaryEmailAddress,
    address_line_1: addressLineOne, address_line_2: addressLineTwo,
    region, city, country
  } = businessContact ?? '';

  // const handleGeneratePickList = () => {
  //   window.open(`${OgaToken.SERVER_LINK}download_order_pick_list/${id}.pdf`, '_blank');
  // };

  const returnHeaders = () => headers.map((header) => (
    <TableHeaderCell>{header}</TableHeaderCell>
  ));

  const actionButtonHandler = (poId) => {
    window.open(`${OgaToken.SERVER_LINK}export_purchase_order/${poId}`, '_blank');
  };

  return (
    <MainContent>
      <GridContainer>
        <Grid container item style={{ marginTop: '20px' }}>
          <Grid container item xs={12} md={6}>
            <CIconButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
            </CIconButton>
            <Grid item alignItems="center">
              <PrimaryTitle variant="h5">Order Detail</PrimaryTitle>
              <Hidden mdDown>
                <SecondaryTitle variant="caption">View details of an order sent to a supplier edited</SecondaryTitle>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} spacing={3} justifyContent="flex-end" alignItems="center">
            {/* {
              (data?.singleOrder?.status === 'CLOSED') && (
              <CustomButton
                type="secondary"
                header
                onClick={handleGeneratePickList}
                disabled={loading}
                style={{ width: '6rem', height: '2.9rem' }}
              >
                Pick List
              </CustomButton>
              )
            } */}
            {metaStatus === 'ORDER_SENT' && (
              <CustomButton
                type="tertiary"
                header
                onClick={handleOpen}
                style={{ width: '12rem', height: '2.9rem', marginLeft: '1rem' }}
              >
                View PO
              </CustomButton>
            )}
            <CustomButton
              type="tertiary"
              header
              onClick={handleDownload}
              style={{ width: '12rem', height: '2.9rem', marginLeft: '1rem' }}
            >
              Download Invoice
            </CustomButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" spacing={3} style={{ marginTop: '20px' }}>
          <Grid item container md={12} lg={5}>
            <PaperCard>
              <CardLogo src={business && business.logo} />
              <NameHeaderText>{business?.name}</NameHeaderText>
              <CardText>{`ID${id}`}</CardText>
              <CardText>
                Placed on:
                <b>{` ${dateCreated}`}</b>
              </CardText>
              <CardText>
                Estimated Delivery:
                <b>{` ${deliveryDate || 'N/A'}`}</b>
              </CardText>
            </PaperCard>
          </Grid>
          <Grid item container md={12} lg={7}>
            <PaperCard>
              <CardHeaderGrid item container>
                <CardHeaderText>Delivery Location</CardHeaderText>
              </CardHeaderGrid>
              <CardText>Mobile</CardText>
              <CardText>
                <b>{mobileNumber || 'N/A'}</b>
              </CardText>
              <CardText>
                Email Address
              </CardText>
              <CardText>
                <b>{primaryEmailAddress || 'N/A'}</b>
              </CardText>
              <CardText>
                Address
              </CardText>
              <CardText>
                <b>{addressLineOne && `${addressLineOne},`}</b>
                <b>{addressLineTwo && ` ${addressLineTwo},`}</b>
                <b>{region && ` ${region},`}</b>
                <b>{city && ` ${city},`}</b>
                <b>{country && ` ${country}.`}</b>
              </CardText>
            </PaperCard>
          </Grid>
        </Grid>
        <Grid item container style={{ marginTop: '2rem' }}>
          <PaperCard style={{ padding: '2rem 1rem' }}>
            <Hidden mdDown>
              <Grid item container>
                {returnHeaders()}
              </Grid>
            </Hidden>
            {loading ? <ProductsTableLoader /> : prods.map((prod) => (
              <ReturnRow
                prod={prod}
                key={prod}
              />
            ))}
            <TotalsGrid item container>
              <CardHeaderText>SubTotal</CardHeaderText>
                &nbsp;&nbsp;&nbsp;
              <CardHeaderText>{`₦ ${currencyFormatter(total)}`}</CardHeaderText>
            </TotalsGrid>
          </PaperCard>
        </Grid>
        <DownloadPopper
          open={open}
          handleClose={() => setOpen(!open)}
          data={items}
          buttonAction={actionButtonHandler}
        />

        {/* <GoToTop /> */}
      </GridContainer>
    </MainContent>
  );
};

export default AffiliateFormDetail;
