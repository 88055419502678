import { styled } from '@mui/material/styles';
import {
  Dialog, Grid, Typography, TextField, Button, CircularProgress
} from '@mui/material';

export const MainDialog = styled(Dialog)`
  width: 45rem;
  margin: 0 auto;

  @media (max-width: 991px) {
    width: 60rem;
  }
`;

export const Wrapper = styled(Grid)`
  background-color: #424242;
  padding: 3.5rem 2rem;

  @media (max-width: 991px) {
    padding: 5rem 2rem;
  }
`;

export const ContentWrapper = styled(Grid)`

  @media (min-width: 992px) {

  }
`;

export const ForgotTypo = styled(Typography)`
  font-weight: 600;
  margin-bottom: 3rem;

  @media (max-width: 991px) {
    font-size: 3rem;
  }
`;

export const ForgotSubTypo = styled(Typography)`
  font-weight: 400;
  margin: 1rem 0 4rem;
  color: #A3A3A3;

  @media (max-width: 991px) {
    font-size: 1.2rem;
    margin: 1rem 0 10rem;
  }
`;

export const ReturnTextField = styled(TextField)`
  .MuiInputBase-root {
    color: #A3A3A3;
  }
  .MuiInputLabel-marginDense {
    transform: translate(5px, 21px) scale(1);
    color: #A3A3A3;
  }
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    color: #A3A3A3;
  }
  .MuiInput-underline:before {
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid #A3A3A3;
  }

  @media (max-width: 991px) {
    .MuiInputLabel-marginDense {
      transform: translate(20px,20px) scale(2);
    }
    .MuiInputLabel-shrink {
      transform: translate(0, 0.5px) scale(1.3);
    }
    .MuiInputBase-root {
      font-size: 2.3rem;
    }
    .MuiFormHelperText-root {
      font-size: 1.5rem;
    }
  }
`;

export const ButtonGrid = styled(Grid)`
  @media (max-width: 991px) {
    margin-top: 1rem;
    justify-content: center;
  }
`;

export const SendButton = styled(Button)`
  width: 10rem;
  height: 2.2rem;
  box-shadow: 0 0 black;
  padding: 9px 10px;
  border-radius: 6px;
  margin-top: .6rem;

  &.MuiButton-contained.Mui-disabled {
    background-color: #FAF33E;
  }

  @media (max-width: 991px) {
    width: 24rem;
    height: 4.5rem;
    border-radius: 16px;
    margin-top: 0;
  }
`;

export const BackGrid = styled(Grid)`
  margin-top: 2rem;
  font-size: .85rem;
  color: #949494;

  @media (max-width: 991px) {
    font-size: 1.6rem;
    color: #949494;
  }
`;

export const BackButton = styled(Button)`
  text-decoration: underline;
  font-size: .8rem;
  color: #FCED50;

  @media (max-width: 991px) {
    font-size: 1.3rem;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #616161;
`;

export const ButtonText = styled(Typography)`
  font-size: 0.75rem;
  color: #616161;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 1.75rem;
  }
`;

export const InputWrapper = styled('div')`
  padding: 0px;
  @media (max-width: 991px) {
    padding-top: 20px;
  }
`;

export const Label = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  margin-bottom: .4rem;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const CustomField = styled(TextField)`
border-radius: 10px;


  & .MuiOutlinedInput-root {
    background-color: #FFFFFF;
    border-radius: 10px;
    border-color: #235A91;
    border: 0.5px solid  #235A91;

    &fieldset: {
      border-color: #235A91;
    },
    &:hover fieldset: {
      border-color: #235A91;
    },
    &.Mui-focused fieldse: {
      border-color: #235A91;
    },
  }
  & .MuiInputBase-input {
    height: 5rem;
    font-size: 3rem;
  }
  @media (min-width: 992px) {
    & .MuiInputBase-input {
      height: 2rem;
      font-size: 1rem;
    }
  }

`;

export const HaveAccount = styled(Typography)`
  padding-top: 1.5rem;
  text-align: center;

  @media (max-width: 991px) {
    padding-top: 2.5rem;
  }
`;

export const HaveAccountTypo = styled(Typography)`
  padding-top: 1rem;
  font-weight: 600;
  font-size: 2rem;

  @media (min-width: 992px) {
    font-size: .875rem;
  }
`;
