import {
  Grid, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 1.8rem 2rem;
  background: #F3F9FF;
  @media(max-width: 991px) {
    padding: 0px 35px;
  }
`;

export const TitleHeader = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  padding: 25px 0px;
  font-weight: 700;
  line-height: 29px;
  color: #24292E;
`;
