import React from 'react';
import { Grid, Typography, LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import MainContent from '../customComponents/mainContent';
import {
  LoaderContainer, LoaderBackground, LoaderWaiting, LoaderTypo, LinearGrid
} from './loader.styles';
import LoaderBack from '../../assets/images/loading/loader_background.png';
import LoaderWait from '../../assets/images/loading/loader_waiting.png';

import { useStateValue } from '../../providers/stateProvider';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 18,
    borderRadius: 18,
  },
  colorPrimary: {
    backgroundColor: '#d6d6d6',
  },
  bar: {
    borderRadius: 18,
    backgroundColor: '#f7efa2',
  },
}))(LinearProgress);

const Loader = () => {
  const [{
    offline: {
      productsLoading, productsTotal, productsLoaded, loadedPercentage
    }
  }] = Object.values(useStateValue());

  return (
    <MainContent>
      <LoaderContainer>
        <LoaderTypo>Loading, Please wait ...</LoaderTypo>
        <LoaderBackground alt="bg-image" src={LoaderBack} />
        <LoaderWaiting alt="wait-image" src={LoaderWait} />
        {productsLoading
          ? (
            <LinearGrid item>
              <Grid item container justifyContent="center">
                <Typography variant="subtitle2" color="textSecondary">
                  {`${Math.round(loadedPercentage)}%`}
                </Typography>
              </Grid>
              <BorderLinearProgress variant="determinate" value={loadedPercentage} />
              <Grid item container justifyContent="center">
                <Typography variant="subtitle2" color="textSecondary">
                  {`${productsLoaded} / ${productsTotal}`}
                </Typography>
              </Grid>
            </LinearGrid>
          ) : ''}
      </LoaderContainer>
    </MainContent>
  );
};

export default Loader;
