import gql from 'graphql-tag';

export const CREATE_FEEDBACK_MUTATION = gql`
mutation createFeedback(
  $rating: Int!, $message: String!,
  $orderId: Int!, $affiliateId: Int!
) {
  createFeedback(
    rating: $rating,
    message: $message,
    orderId: $orderId,
    affiliateId: $affiliateId
  ){
    message
  }
}`;
