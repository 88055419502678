import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Paper, TableRow, TableBody,
} from '@mui/material';
import GoToTop from '../../customComponents/scrollToTop';
import { CATEGORY_PRODUCTS_QUERY } from '../../../queries/categories';
import {
  TableContainerWrapper, TableComponent, HeaderCell, FooterGrid,
  MainTableHead
} from './categoryProducts.style';
import SupplierActionTypes from '../../../providers/reducers/supplier/supplierTypes';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';

import { useStateValue } from '../../../providers/stateProvider';
import { Product } from '../../../providers/reducers/product/classes/Product';
import TablePagination from '../../shared/tablePagination';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const SupplierProducts = ({
  categoryId,
  searchText
}) => {
  const [{ user: { platform } }, dispatch] = Object.values(useStateValue());
  const [searchValue, setSearchValue] = useState('');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const updateSupplierState = (supplierProductsTotalNumber) => {
    dispatch({
      type: SupplierActionTypes.UPDATE_SUPPLIER,
      payload: {
        supplierProductsCount: supplierProductsTotalNumber
      }
    });
  };

  const { loading, error, data } = useQuery(CATEGORY_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      categoryId,
      pageCount,
      pageNumber,
      productName: searchValue
    },
  });

  useEffect(() => {
    if (data && data.categoryProducts) {
      const { supplierProductsTotalNumber } = data;
      updateSupplierState(supplierProductsTotalNumber);
    }
  }, [data]);

  if (loading) return <ProductsTableLoader />;
  if (error) return <div>{error.message}</div>;
  const {
    categoryProducts = [], productsTotalNumber = 0
  } = data || {};

  const rows = categoryProducts.map((product) => new Product(product, platform));

  const isErp = platform === 'erp';
  return (
    <>
      <TableContainerWrapper component={Paper}>
        <TableComponent aria-label="supplier products table">
          <MainTableHead>
            <TableRow>
              <HeaderCell />
              <HeaderCell />
              <HeaderCell>Product Name</HeaderCell>
              <HeaderCell>Pack Size</HeaderCell>
              <HeaderCell>
                {' '}
                { isErp ? 'Order Cost' : 'Item Price' }
              </HeaderCell>
              <HeaderCell>Mkt. RRP*</HeaderCell>
              <HeaderCell />
              <HeaderCell />
            </TableRow>
          </MainTableHead>
          <TableBody>
            {rows.map((row) => (
              <ReturnRow key={row.id} row={row} />
            ))}
          </TableBody>
        </TableComponent>
      </TableContainerWrapper>
      <FooterGrid item container>
        <TablePagination
          total={productsTotalNumber}
          pageCount={+pageCount}
          setPageCount={setPageCount}
          pageNumber={+pageNumber}
          setPageNumber={setPageNumber}
        />
      </FooterGrid>
      {(categoryProducts.length)
        ? <GoToTop /> : ''}
    </>
  );
};

SupplierProducts.propTypes = {
  categoryId: PropTypes.number,
  searchText: PropTypes.string
};

SupplierProducts.defaultProps = {
  categoryId: 0,
  searchText: ''
};

export default SupplierProducts;
