import React, { useState } from 'react';
import { Grid, Hidden } from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import { MainContainer } from '../orders/admin/order.styles';
import paginationShowing from '../../utils/pagination';
import { CREATE_TICKET } from '../../mutations/helpDesk';
import {
  HeaderWrapper, SubContentHandler, OrderItemsGrid, OrderCountText,
  ProductItemsTotal, TopButton
} from './helpDeskContainer.styles';
import CustomTableSearchField from '../shared/customTableSearchField';
import { PageTitleSubText, PageTitleText } from '../cart/cart.styles';
import Tickets from './tickets';
import TicketModel from './ticketModel/ticketModel';
import { PlusIcon, UndoIcon } from '../../assets/svgs';
import { useStateValue } from '../../providers/stateProvider';

const HelpDeskContainer = () => {
  const [{
    helpDesk: { tickets, ticketsCount, refetch },
  }] = Object.values(useStateValue());

  const initialState = {
    status: '',
    currentPage: 1,
    currentPageCount: 20,
    searchText: '',
  };
  const [state, setState] = useState(initialState);
  const [openTicketModel, setOpenTicketModel] = useState(false);
  const { currentPage } = state;

  const role = localStorage.getItem('oga_user_role');
  const isDev = () => role === 'developer';

  const handleClosed = () => {
    if (state.status === 'RESOLVED') {
      setState({ ...state, status: '' });
    } else setState({ ...state, status: 'RESOLVED' });
  };

  const topButtons = [
    {
      name: 'Report an Issue',
      icon: <PlusIcon />,
      color: '#1BC5BD',
      func: () => setOpenTicketModel(true),
      disabled: isDev()
    },
    {
      name: state.status === 'RESOLVED' ? 'Unresolved Issues' : 'Resolved Issues',
      icon: '',
      color: '#6993FF',
      func: handleClosed
    },
    {
      name: 'Refresh', icon: <UndoIcon />, color: '#FFA800', func: () => refetch()
    },
  ];

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const [createTicket] = useMutation(CREATE_TICKET);
  const handleLogTicket = (details, handleDone) => {
    createTicket({
      variables: { description: details }
    }).then(({ data }) => {
      const { createHelpdeskTicket: { message } } = data;
      toast.success(message);
      refetch();
      handleDone();
      setOpenTicketModel(false);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const toggleSortBy = (newColumn) => {
    const { asc } = state?.filter[newColumn] || false;
    setState({
      ...state,
      filter: {
        [newColumn]: { asc: !asc }
      }
    });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const showing = paginationShowing(tickets, ticketsCount);

  return (
    <MainContent>
      <br />
      <MainContainer item container xs>
        <HeaderWrapper item container>
          <Grid item container md={5} xs={12} direction="column">
            <PageTitleText>Help Desk</PageTitleText>
            <PageTitleSubText>Help desk ticketing portal</PageTitleSubText>
          </Grid>
        </HeaderWrapper>
        <SubContentHandler container alignItems="center">
          <Grid container item md={2} xs={12} alignItems="center" justifyContent="space-between">
            <ProductItemsTotal>{`${ticketsCount} Issue(s)`}</ProductItemsTotal>
            <Hidden mdUp>
              <CustomTableSearchField
                searchText={state.searchText}
                placeholder="Search item..."
                handleChange={(e) => handleSearch(e.target.value)}
              />
            </Hidden>
          </Grid>
          <Grid item container md={10} xs={12} alignItems="center" justifyContent="flex-end">
            <Hidden smDown>
              <CustomTableSearchField
                searchText={state.searchText}
                placeholder="Search item..."
                handleChange={(e) => handleSearch(e.target.value)}
              />
            </Hidden>
            {topButtons.map(({
              name, icon, color, func, disabled
            }) => (
              <TopButton
                key={name}
                variant="contained"
                disableElevation
                disableRipple
                size="small"
                startIcon={icon}
                bkColor={color}
                onClick={func}
                disabled={disabled}
              >
                {name}
              </TopButton>
            ))}
          </Grid>
        </SubContentHandler>
        {ticketsCount ? (
          <OrderItemsGrid>
            <OrderCountText>
              Showing
              <b>
                {' '}
                {showing()}
                -
                {showing('end')}
                {' '}
              </b>
              of
              <b>
                {' '}
                {ticketsCount}
                {' '}
              </b>
              issue
            </OrderCountText>
          </OrderItemsGrid>
        ) : ''}
        <Tickets
          state={state}
          handleChangePage={handleChangePage}
          page={currentPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          toggleSortBy={toggleSortBy}
          isDev={isDev}
        />
      </MainContainer>

      <TicketModel
        open={openTicketModel}
        handleClose={() => setOpenTicketModel(false)}
        handleLogTicket={handleLogTicket}
      />
    </MainContent>
  );
};

export default HelpDeskContainer;
