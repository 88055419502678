import {
  Dialog, Grid, Typography, FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const CDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  width: 40rem;
  padding: 0 1.5rem;
`;

export const DialogHeader = styled(Grid)`
  width: 37rem;
  padding: 1.5rem 0;
  background: #fff;
`;

export const DialogContent = styled(Grid)`
  margin-top: 1rem;
`;

export const DialogActions = styled(Grid)`
  justify-content: flex-end;
  padding: 1rem 0 1.5rem;
  background: #fff;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

export const SubText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  margin-right: 15px;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const HeaderText = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: #303030;
  width: 100%;
`;

export const CFormControl = styled(FormControl)`
  & .MuiFilledInput-root {
    background-color: transparent;
    border: 0.5px solid #d8d8d8;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #606060;

    &::before {
      content: " ";
      border-bottom: 0;
    }
  }
  & .MuiFilledInput-inputMarginDense {
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;
