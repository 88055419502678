import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, TableBody } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import { GET_SEARCHABLE_STOCK_TRANSFERS } from '../../../queries/stockTransfer';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  TitleGrid, PrimaryTitle, PaperWrapper, TableGrid, TableHeader, TableComponent,
  MainTableHead, SecondaryTitle, MenuButton,
  SearchGrid
} from './receivedTransferDetails.styles';

const headers = [
  'S/N', 'Date', 'Product Name', 'Qty Transferred', 'Batch#', 'Total Product Value', 'Transfer Location',
];

const StockTransfer = () => {
  const [prodBatches, setProdBatches] = useState([]);
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header}>{header}</TableHeader>
  ));

  const variables = {
    ...(searchText.length >= 3 ? { search: searchText } : { search: '' }),
    id
  };

  const {
    loading, error, data
  } = useQuery(GET_SEARCHABLE_STOCK_TRANSFERS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.searchableStockTransfer) {
      setProdBatches(data.searchableStockTransfer[0].result);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <Grid container item xs={12} md={4}>
            <MenuButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            </MenuButton>
            <Grid container item xs={11}>
              <PrimaryTitle variant="h5">Received Transfers</PrimaryTitle>
              <SecondaryTitle variant="h5">
                {`Receipt Number #${data?.searchableStockTransfer[0]?.receiptNumber}`}
              </SecondaryTitle>
            </Grid>
          </Grid>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search"
              handleChange={(e) => setSearchText(e?.target?.value)}
              style={{ width: '100%' }}
            />
          </SearchGrid>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <MainTableHead item container>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody style={{ width: '100%' }}>
                    {prodBatches?.map((_row, indx) => (
                      <ReturnRow
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>

        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default StockTransfer;
