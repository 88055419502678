import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../../utils/utils';
import {
  GridContainer,
  GridWrapper,
  ItemTypo,
  ItemWrapper
} from '../../../toBeDeleted/uploadSupplier/supplierCreatePopper.styles';

const CustomerPopper = ({
  customerButton,
  handleCustomerButtonClick,
  individualDialogHandler,
  openDialogHandler
}) => {
  const open = Boolean(customerButton);
  // const navigate = useNavigate();

  const handleManualCustomerAdd = () => {
    handleCustomerButtonClick();
    individualDialogHandler();
  };

  const csvUploadHandler = () => {
    handleCustomerButtonClick();
    openDialogHandler();
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={customerButton}
      onClickAway={() => handleCustomerButtonClick()}
      popperPlacement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh'
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer container item onClick={handleManualCustomerAdd}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">Add individually</ItemTypo>
          </ItemWrapper>
        </GridContainer>

        {/* <GridContainer container item onClick={() => navigate(`/map-${modelName.toLowerCase()}-csv`)}> */}
        <GridContainer container item onClick={csvUploadHandler}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">Upload CSV file</ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
};

CustomerPopper.propTypes = {
  customerButton: PropTypes.instanceOf(Object),
  handleCustomerButtonClick: PropTypes.func.isRequired,
  individualDialogHandler: PropTypes.func.isRequired,
  openDialogHandler: PropTypes.func.isRequired,
};

CustomerPopper.defaultProps = {
  customerButton: {}
};

export default CustomerPopper;
