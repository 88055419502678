import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation createCustomer(
    $customerName: String!
    $photo: String!
    $mobile: String!
    $email: String
    $addressLine1: String!
    $addressLine2: String
    $region: String
    $city: String!
    $country: String!
    $notes: String
    $loyaltyPoint: Float
    $storeCredit: Float
  ) {
    createCustomer(
      customerName: $customerName
      photo: $photo
      mobileNumber: $mobile
      email: $email
      addressLineOne: $addressLine1
      addressLineTwo: $addressLine2
      region: $region
      city: $city
      country: $country,
      notes: $notes
      loyaltyPoint: $loyaltyPoint,
      storeCredit: $storeCredit,
    ){
      message
      customer {
        id
        name
        meta
        dateCreated
        contacts
        loyaltyPoint
        storeCredit
        business {
            id
        }
      }
    }
  }
`;

export const DELETE_CUSTOMER_MUTATION = gql`
mutation deleteCustomer(
  $customerId: String!
  ) {
    deleteCustomer(
      customerId: $customerId
    ) {
      message
    }
  }
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation updateCustomer(
    $customerId: String!
    $customerName: String
    $photo: String
    $mobile: String
    $email: String
    $addressLine1: String
    $addressLine2: String
    $region: String
    $city: String
    $country: String
    $notes: String
    $loyaltyPoint: Float
    $storeCredit: Float
  ) {
    updateCustomer(
      customerId: $customerId
      customerName: $customerName
      photo: $photo
      mobileNumber: $mobile
      email: $email
      addressLineOne: $addressLine1
      addressLineTwo: $addressLine2
      region: $region
      city: $city
      country: $country
      notes: $notes
      loyaltyPoint: $loyaltyPoint,
      storeCredit: $storeCredit,
      
    ){
      message
      customer {
        id
        name
        meta
        dateCreated
        contacts
        loyaltyPoint
        storeCredit
        business {
            id
        }
      }
    }
  }
`;
