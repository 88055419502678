import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TableRow } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
// import {
//   BodyCell,
//   MainTableRow,
//   EditButton,
//   EditButtonIcon,
//   EditButtonText,

// } from './returnRow.styles';
import { TableCellBodyStyled } from './productInfo.style';
import { TableRows } from './returnRow.styles';
// import { userCanUpdateProductBatch } from '../../../providers/reducers/user/userUtils';
// import EditIcon from '../../../assets/images/Edit Icon.png';
// import DeleteIcon from '../../../assets/images/Delete.png';
// import { useStateValue } from '../../../providers/stateProvider';

const ReturnRow = ({
  row, supplier
}) => {
  // const [entered, setEntered] = useState(false);
  // const [
  //   {
  //     user: { allowedPermissionsMap }
  //   }
  // ] = Object.values(useStateValue());

  const dateReceived = moment(row.dateRecieved).format('DD/MM/YYYY');
  const expiryDate = moment(row.expiryDate).format('DD/MM/YYYY');

  return (
    <>
      <TableRows>
        <TableCellBodyStyled>{dateReceived}</TableCellBodyStyled>
        <TableCellBodyStyled>
          {Object.keys(supplier).length > 0 ? supplier?.name : 'N/A'}
        </TableCellBodyStyled>
        <TableCellBodyStyled>{row.batchNo}</TableCellBodyStyled>
        <TableCellBodyStyled>{expiryDate}</TableCellBodyStyled>
        <TableCellBodyStyled>{row.quantityInStock}</TableCellBodyStyled>
        <TableCellBodyStyled>{`₦ ${currencyFormatter(row.orderCost)}`}</TableCellBodyStyled>
        <TableCellBodyStyled>{`₦ ${currencyFormatter(row.orderPrice)}`}</TableCellBodyStyled>
      </TableRows>
      {/* <MainTableRow
        onMouseEnter={() => setEntered(true)}
        onMouseLeave={() => setEntered(false)}
      >

        <BodyCell>
          {expiryDate}
        </BodyCell>
        <BodyCell>
          {row.quantityInStock}
        </BodyCell>
        <BodyCell>
          ₦
          {currencyFormatter(row.orderCost)}
        </BodyCell>
        <BodyCell>
          ₦
          {currencyFormatter(row.orderPrice)}
        </BodyCell> */}
      {/* <BodyCell>
          {entered ? (
            <>
              {allowedPermissionsMap.get('CAN_UPDATE_PRODUCT_BATCH') ? (
                <EditButton onClick={editBatch}>
                  <EditButtonIcon alt="edit icon" src={EditIcon} />
                  <EditButtonText variant="subtitle1">
                    Edit
                  </EditButtonText>
                </EditButton>
              ) : ''}
              {allowedPermissionsMap.get('CAN_DELETE_SALE') ? (
                <EditButton onClick={deleteBatch} style={{ minWidth: '.5rem' }}>
                  <EditButtonIcon alt="delete icon" src={DeleteIcon} style={{ width: '13px' }} />
                </EditButton>
              ) : ''}
            </>
          ) : ''}
        </BodyCell> */}
      {/* </MainTableRow> */}
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  supplier: PropTypes.instanceOf(Array),
  // editBatch: PropTypes.func.isRequired,
  // deleteBatch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  supplier: []
};

export default ReturnRow;
