import { useQuery } from '@apollo/client';
import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ModelActionTypes from '../../../providers/reducers/model/modelTypes';
import { useStateValue } from '../../../providers/stateProvider';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import TablePagination from '../tablePagination';
import useSearchParamsState from '../helpers/ulrSearchParams';

import {
  DivFullWidth,
  ModelCardsContainer
} from './models.styles';

export default function Models({
  state,
  findModelsQuery,
  modelTotalNumber,
  modelQueryName,
  renderModelCard,
  categoriesList, sort, reload
}) {
  const [{ model: { reFetch } }, dispatch] = Object.values(useStateValue());
  const {
    searchText
  } = state;

  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');

  const updateCustomerState = (models, modelsCount) => {
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: {
        models,
        modelsCount,
      }
    });
  };

  const {
    loading, error, data, refetch
  } = useQuery(findModelsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      search: searchText,
      pageCount,
      pageNumber,
      // ...extraModelQueryParam
    },
  });
  let modelsData = [];
  let totalModelNumber = 0;
  useEffect(() => {
    refetch();
    if (data && data[modelQueryName]) {
      modelsData = data[modelQueryName];
      state.model = modelsData;
      totalModelNumber = data[modelTotalNumber] || 0;
      updateCustomerState(modelsData, totalModelNumber);
    }
  }, [data, reFetch, reload]);

  if (loading) return <NewOrdersLoader />;
  if (error) return <div>{error.message}</div>;
  if(data){
    modelsData = data[modelQueryName] || {};
    totalModelNumber = data[modelTotalNumber] || 0;
  }

  if (sort === true) {
    modelsData.sort((a, b) => a.therapeuticType.localeCompare(b.therapeuticType));
  }

  return (
    <>
      <ModelCardsContainer container spacing={3}>
        {renderModelCard(modelsData, refetch)}
      </ModelCardsContainer>

      {categoriesList && (
        <Paper elevation={0} style={{ borderRadius: '0 0 12px 12px', padding: '1.7rem' }}>
          <DivFullWidth>
            {totalModelNumber > 0 && (
            <TablePagination
              total={totalModelNumber}
              pageCount={+pageCount}
              setPageCount={setPageCount}
              pageNumber={+pageNumber}
              setPageNumber={setPageNumber}
            />
            )}
          </DivFullWidth>
        </Paper>
      )}
    </>
  );
}

Models.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  findModelsQuery: PropTypes.instanceOf(Object).isRequired,
  modelTotalNumber: PropTypes.string.isRequired,
  modelQueryName: PropTypes.string.isRequired,
  renderModelCard: PropTypes.instanceOf(Object).isRequired,
  categoriesList: PropTypes.bool,
  sort: PropTypes.bool,
  reload: PropTypes.bool
};
Models.defaultProps = {
  categoriesList: false,
  sort: false,
  reload: false
};
