import { ClickAwayListener, Grow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { PopperContainer, WrapperPaper } from './renderPopper.styles';

export const RenderPopper = ({
  anchorEl, onClickAway, open, children, popperPlacement,
  modifiers
}) => (
  <PopperContainer
    open={open}
    placement={popperPlacement}
    anchorEl={anchorEl}
    transition
    disablePortal
    modifiers={modifiers}
  >
    {({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        id="menu-list-grow"
        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
      >
        <WrapperPaper elevation={2}>
          <ClickAwayListener onClickAway={onClickAway}>
            {children}
          </ClickAwayListener>
        </WrapperPaper>
      </Grow>
    )}
  </PopperContainer>
);

RenderPopper.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onClickAway: PropTypes.func,
  children: PropTypes.element,
  popperPlacement: PropTypes.string,
  open: PropTypes.bool.isRequired,
  modifiers: PropTypes.instanceOf(Object)
};

RenderPopper.defaultProps = {
  onClickAway: () => null,
  anchorEl: <span />,
  popperPlacement: 'bottom',
  children: <span />,
  modifiers: {}
};
