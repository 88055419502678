import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import toast from 'react-hot-toast';
import {
  Checkbox, Button, Typography, Dialog
} from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  ButtonContainer,
  BatchDialogHeader,
  PackSize,
  ProductName,
  BatchDialogBody,
  ColumnHeader,
  BatchItem,
  Input,
  CheckIconContainer,
  CheckIcon,
  CloseIcon,
} from './reconcileProductBatchDialog.styles';
import NewProductBatchDialog from './newProductBatchDialog';

import { JSONParse } from '../../utils/json';
import { UPDATE_BATCH_MUTATION } from '../../mutations/products';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function ReconcileProductBatchDialog({
  selectedProdBatches, openProductBatchDialog,
  handleCloseModal
}) {
  const [state, setState] = useState({});
  const [selected, setSelected] = useState({});
  const [openNewBatchModal, setOpenNewBatchModal] = useState(false);

  const [updateBatch] = useMutation(UPDATE_BATCH_MUTATION);
  const product = selectedProdBatches[0]?.product;
  const productMeta = product?.meta;
  const packSize = productMeta && JSONParse(productMeta).pack_size;

  useEffect(() => {
    if (selectedProdBatches && selectedProdBatches.length) {
      selectedProdBatches.forEach((b) => {
        setState({
          ...state, [b.id]: { quantity: '', batchNo: b.batchNo, checked: false }
        });
      });
    }
  }, [selectedProdBatches]);

  const handleSelectBatch = (batch) => {
    const checked = state[batch]?.checked;
    const selectedBatch = selected[batch] || false;
    setState({ ...state, [batch]: { ...state[batch], checked: !checked } });
    setSelected({ ...selected, [batch]: !selectedBatch });
  };

  const handleCreateNewBatch = () => {
    setOpenNewBatchModal(true);
  };

  const handleReconcileProductBatch = () => {
    const batchIds = Object.keys(state);
    batchIds.forEach((bId) => {
      const { batchNo, quantity } = state[bId];
      updateBatch({
        variables: {
          batchId: bId,
          batchNo,
          quantity
        }
      })
        .then((response) => {
          const { message } = response?.data?.updateBatch || {};
          toast.success(message);
        })
        .then(() => handleCloseModal())
        .catch((err) => {
          toast.error(err?.message);
        });
    });
  };

  const handleChangeQty = ({ target: { value } }, batchId, min = 0) => {
    let targetValue = +value;
    if (min && +value < min) {
      targetValue = +min;
    }
    setState({ ...state, [batchId]: { ...state[batchId], quantity: targetValue } });
  };

  const renderBatchItems = () => {
    const batchList = [];
    if (selectedProdBatches.length) {
      selectedProdBatches.forEach((batch) => {
        batchList.push(batch);
      });
    }
    if (batchList.length === 0) {
      return (
        <Grid style={{ textAlign: 'center' }}>
          <Typography>You have no batch in stock</Typography>
          <Button onClick={() => handleCreateNewBatch()}>Add batch</Button>
        </Grid>
      );
    }
    return batchList.map((batch) => {
      const batchId = state[batch.id];
      return (
        <BatchItem key={batch.id} container direction="row">
          <Grid item xs={1} alignItems="center" justifyContent="center">
            <Checkbox
              onClick={() => handleSelectBatch(batch.id)}
              name="terms"
              color="primary"
              checked={state[batchId]?.checked}
            />
          </Grid>
          <Grid item xs={3}>
            <ColumnHeader item>Batch #</ColumnHeader>
            <Grid item>{batch?.batch?.batchNo}</Grid>
          </Grid>
          <Grid item xs={2}>
            <ColumnHeader item>Expiry date</ColumnHeader>
            <Grid item>{moment(batch?.batch?.expiryDate).format('DD/MM/YYYY')}</Grid>
          </Grid>
          <Grid item xs={3}>
            <ColumnHeader item>Qty in stock</ColumnHeader>
            <Grid item>{batch.quantityInStock}</Grid>
          </Grid>
          {selected[batch.id] && (
            <Grid item xs={3}>
              <ColumnHeader item style={{ whiteSpace: 'nowrap' }}>Qty to reconcile</ColumnHeader>
              <Grid item>
                <Input
                  min={0}
                  size={3}
                  value={state[batchId]?.quantity}
                  onBlur={(event) => handleChangeQty(event, batch.id, 1)}
                  onChange={(event) => handleChangeQty(event, batch.id)}
                />
              </Grid>
            </Grid>
          )}
        </BatchItem>
      );
    });
  };
  return (
    <>
      <Dialog
        open={openProductBatchDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => ({})}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <BatchDialogHeader>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid>
              <ProductName>{product?.brandName}</ProductName>
              <PackSize item>
                Pack Size:
                {' '}
                {packSize}
              </PackSize>
            </Grid>
            <ButtonContainer item>
              <Grid container direction="row" justifyContent="space-between">
                <CheckIconContainer onClick={handleCloseModal}>
                  <CloseIcon />
                </CheckIconContainer>
                <CheckIconContainer onClick={handleReconcileProductBatch}>
                  <CheckIcon />
                </CheckIconContainer>
              </Grid>
            </ButtonContainer>
          </Grid>
        </BatchDialogHeader>
        <BatchDialogBody>
          {renderBatchItems()}
        </BatchDialogBody>
      </Dialog>

      <NewProductBatchDialog
        open={openNewBatchModal}
        stockItem={selectedProdBatches}
        handleCloseModal={() => setOpenNewBatchModal(false)}
      />
    </>
  );
}

ReconcileProductBatchDialog.propTypes = {
  openProductBatchDialog: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.bool.isRequired,
  selectedProdBatches: PropTypes.instanceOf(Object)
};

ReconcileProductBatchDialog.defaultProps = {
  selectedProdBatches: {}
};
