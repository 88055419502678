import React, { useState } from 'react';
import { Slide, Grid } from '@mui/material';

import CustomInputBase from '../../customComponents/customInputBase';
import CustomButton from '../../customComponents/customButton';
import {
  CDialog, CDialogTitle, CDialogContent, CDialogActions, CloseIcon, DialogHeaderText, DialogText
} from './holdSaleDialog.styles';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const HoldSaleDialog = () => {
  const [note, setNote] = useState('');
  const [{
    sale: { cart, openHoldSale }
  }, dispatch] = Object.values(useStateValue());

  const handleDialogClose = () => {
    dispatch({
      type: SaleActionTypes.UPDATE_SALE_STATE,
      payload: { openHoldSale: false }
    });
    setNote('');
  };

  const handleAddHeldSaleButton = () => {
    const heldSale = { note, cart };
    dispatch({
      type: SaleActionTypes.HOLD_SALE,
      payload: heldSale
    });
    handleDialogClose();
  };

  return (
    <CDialog
      open={openHoldSale}
      maxWidth="sm"
      TransitionComponent={Transition}
      onClose={handleDialogClose}
      PaperProps={{ style: { borderRadius: 12 } }}
    >
      <CDialogTitle item container>
        <DialogHeaderText>Hold Sale</DialogHeaderText>
        <CloseIcon onClick={handleDialogClose} />
      </CDialogTitle>
      <CDialogContent item container>
        <DialogText>
          You are about to hold this sale.
          <br />
          This note will be a quick identifier when you decide to continue with this sale.
        </DialogText>
        <Grid item xs={12} style={{ marginTop: '.8rem' }}>
          <CustomInputBase
            multiline
            minRows={3}
            label="Add Note"
            placeholder="This Note will Be A Quick Identifier"
            name="holdSaleNote"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Grid>
      </CDialogContent>
      <CDialogActions>
        <CustomButton
          type="secondary"
          header
          style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
          onClick={handleDialogClose}
        >
          Cancel
        </CustomButton>
        <CustomButton
          type="tertiary"
          header
          onClick={handleAddHeldSaleButton}
          style={{ width: '11rem', height: '2.7rem' }}
        >
          Hold Sale
        </CustomButton>
      </CDialogActions>
    </CDialog>
  );
};

export default HoldSaleDialog;
