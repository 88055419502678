import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  SideGrid,
  SideNavButton,
  SideNavLine,
  SideNavText
} from './sideNavItem.styles';

export const SideNavItem = ({
  sideItem, handleSideClick
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 991px)');

  const location = useLocation();
  const { pathname } = location;

  const setActiveSvg = (route) => (route === pathname?.slice(1) ? '#235A91' : '#FFFFFF');

  const [route, SvgIco, title] = sideItem;

  return (
    <SideGrid item container key={route} className={`navigation-item-${title}`}>
      <SideNavLine
        orientation="vertical"
        flexItem
        active={route === pathname?.slice(1)}
      />
      <SideNavButton
        className={`erp-uat-${route}`}
        disableRipple
        onClick={() => handleSideClick(route)}
        active={route === pathname?.slice(1)}
      >
        <SvgIco
          style={{
            fontSize: isSmallScreen ? '4rem' : '1.5rem', color: setActiveSvg(route),
            marginLeft: isSmallScreen && '2rem', fill: 'none'
          }}
        />
        <SideNavText>{title}</SideNavText>
      </SideNavButton>
    </SideGrid>
  );
};

SideNavItem.propTypes = {
  sideItem: PropTypes.instanceOf(Array),
  handleSideClick: PropTypes.func.isRequired,
};

SideNavItem.defaultProps = {
  sideItem: [],
};

export default SideNavItem;
