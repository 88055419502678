import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './actionPopper.styles';
import { useStateValue } from '../../../../providers/stateProvider';

export default function ActionPopper({
  action, handleAction, handleClose,
}) {
  const open = Boolean(action);

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  },] = Object.values(useStateValue());

  return (
    <RenderPopper
      open={open}
      anchorEl={action}
      popperPlacement="bottom"
      onClickAway={handleClose}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, 'invoice')}
          className="mpAdmin-uat-manage-order-generate-invoice"
          disabled={!userPermissions?.includes('manage_orders_generate_invoice')}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Generate Invoice
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, 'order')}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              View Order Details
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
