import React from 'react';
import PropTypes from 'prop-types';
import { JSONParse } from '../../utils/json';
import {
  MainTableRow, BodyCell
} from './stockReportDetailsRow.styles';

const StockReportDetailsRow = ({ row }) => {
  const {
    batch, expectedQuantity, quantityInStock, product
  } = row;
  const productMeta = product?.meta;
  const { pack_size: packSize } = JSONParse(productMeta);
  const diff = quantityInStock - expectedQuantity;

  return (
    <MainTableRow key={row.id} item container style={{ cursor: 'auto' }}>
      <BodyCell>{batch?.batchNo || 'N/A'}</BodyCell>
      <BodyCell>{product?.brandName}</BodyCell>
      <BodyCell>{packSize}</BodyCell>
      <BodyCell>{expectedQuantity || 0}</BodyCell>
      <BodyCell>{quantityInStock || 0}</BodyCell>
      <BodyCell style={{ color: diff !== 0 ? '#EE0101' : '#606060' }}>{diff}</BodyCell>
      <BodyCell />
    </MainTableRow>
  );
};

StockReportDetailsRow.propTypes = {
  row: PropTypes.instanceOf(Object)
};

StockReportDetailsRow.defaultProps = {
  row: {},
};

export default StockReportDetailsRow;
