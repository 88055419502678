import OrdersReportActionTypes from './ordersReportTypes';

const ordersReportReducer = (ordersReport, action) => {
  switch (action.type) {
    case OrdersReportActionTypes.UPDATE_REPORT:
      return {
        ...ordersReport,
        ...action.payload
      };
    case OrdersReportActionTypes.TOGGLE_DIALOG_OPEN:
      return {
        ...ordersReport,
        addProductDialogOpen: !ordersReport.addProductDialogOpen
      };
    default:
      return ordersReport;
  }
};

export default ordersReportReducer;
