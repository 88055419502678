import {
  Button,
  Grid, MenuItem, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
  padding: 2rem 0rem 30px 0rem;
  @media(max-width: 1320px) {
    padding: 5px 0rem 30px 0rem;
  }

  @media(max-width: 1025px) {
  padding: 5px 0rem 30px 0rem;
  }
`;

export const GridWrapper = styled(Grid)`
  background: #f7fafe;
  box-shadow: 0px 4px 8px rgba(214, 219, 230, 0.25);
  @media(max-width: 991px) {
    padding-top: 1.4rem;
    }
}
`;

export const InnerContainer = styled(Grid)`
  margin: 0 2.5rem 2.5rem;
  @media(max-width: 1320px) {
    margin: 2rem 1.5rem 1.5rem;
  }
`;

export const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 20px;
  color: #24292E;
`;

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem 0;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 2rem 0;
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const ButtonWrapper = styled(Button)`
  height: 2.6rem;
  background: #ffffff;
  border-radius: 7px;
  color: #606060;
  border: 1px solid #E7E8E9;
`;
