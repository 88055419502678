import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonIcon = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 10rem;
`;

export const AddImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: 1rem;
  }
`;
