import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Grid, useMediaQuery
} from '@mui/material';
import toast from 'react-hot-toast';
import ReturnSelectField from './returnSelectField';
import DropZone from '../shared/logoDropzone';
import CropImageContainer from '../shared/logoProcess/cropImageContainer';
import InitialImage from '../shared/logoProcess/initialImage';
import { CREATE_BUSINESS_MUTATION } from '../../mutations/business';
import {
  MainGridContainer, PrimaryTitle, ContentBox, CircularProgressLoader, CreateButton, CustomField,
  BusinessSetupGridContainer, LogoGridContainer, Img, TypoWrapper, LogoTypo, TypoDesc, OgaAdminLogo, SubTypo, FieldWrapper, InputWrapper, Label
} from './businessSetupContainer.styles';
import { validateEmail, validateNigerianNumber } from '../auth/utils';

import { useStateValue } from '../../providers/stateProvider';
import AuthImage from '../../assets/images/AuthImage.png';
import { OgaToken } from '../../graphql/token';
import { GENERATE_TOKEN_MUTATION, SIGNUP_MUTATION } from '../../mutations/auth';

const BusinessSetupContainer = () => {
  const initialState = {
    legalName: '',
    contactName: '',
    userType: 'OGApharmacy market place affiliate',
    mobile: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: 'Lagos',
    state: '',
    secondaryEmailAddress: '',
    salesRepresentative: '',
    landmark: '',
    country: 'Nigeria',
    logo: '',
    validated: false,
    password: '',
    accountStatus: ''
  };

  const initialErrorState = {
    legalNameError: false,
    contactNameError: false,
    addressLine1Error: false,
    mobileError: false,
    emailError: false,
    landmarkError: false,
    stateError: false,
    cityError: false,
    userTypeError: false,
  };

  const nigeriaState = [
    'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo',
    'Ekiti', 'Enugu', 'FCT - Abuja', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos',
    'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara'
  ];

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [loading, setLoading] = useState(false);
  const [showInitialImage, setShowInitialImage] = useState(false);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);

  const needsLoan = false;
  const [, dispatch] = Object.values(useStateValue());
  const location = useLocation();
  const navigate = useNavigate();
  const isLarge = useMediaQuery('(min-width: 992px)');
  const [createBusiness] = useMutation(CREATE_BUSINESS_MUTATION);
  const [createUser] = useMutation(SIGNUP_MUTATION);
  const [generateUserToken] = useMutation(GENERATE_TOKEN_MUTATION);

  const {
    legalNameError, contactNameError, addressLine1Error, mobileError, emailError,
    landmarkError, stateError, cityError, userTypeError
  } = errorState;
  const { userType } = state;

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
    const {
      email: _email, mobile: _mobile, password, accountStatus
    } = location.state && location.state;
    setState((_state) => ({
      ..._state, email: _email, mobile: _mobile, password, accountStatus
    }));
  }, [dispatch, location.state]);

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleChangeInitialImage = () => {
    setShowInitialImage(false);
  };

  const handleDeleteInitialImage = () => {
    setShowInitialImage(false);
    setState({ ...state, logo: '' });
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.SUPPLIER_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const redirectUser = (user) => {
    const { name } = user.businessuserSet[0].role;
    localStorage.setItem('oga_user_role', name);
    const route = name === 'supplier-admin' ? '/orders-supplier' : '/orders';
    setTimeout(() => navigate(route), 1500);
  };

  const handleSave = async () => {
    const {
      legalName, contactName, mobile, email, addressLine1, city, country,
      state: userState, landmark, salesRepresentative, secondaryEmailAddress
    } = state;

    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png';
    const alreadyUploadedImage = state.logo || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    createBusiness({
      variables: {
        legalName,
        contactName,
        businessType: userType,
        logo,
        mobile,
        needsLoan,
        email,
        addressLine1,
        state: userState,
        city,
        country,
        landmark,
        salesRepresentative,
        secondaryEmailAddress,
        userType: 'OGApharmacy market place affiliate',
        terms: false
      }
    })
      .then(({ data }) => {
        const { message, user, business } = data?.createBusiness || {};
        toast.success(message);
        localStorage.setItem('affiliateStatus', business?.status);

        redirectUser(user);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSignup = () => {
    const { email, mobile, password } = state;
    createUser({
      variables: {
        email,
        mobile,
        password
      }
    }).then((resp) => {
      const {
        message, token, restToken
      } = resp.data?.createUser ?? '';
      toast.success(message);
      setState((_state) => ({
        ..._state, accountStatus: 'USER REGISTRATION NOT COMPLETE'
      }));
      localStorage.setItem('oga_auth_token', token);
      OgaToken.authToken = token;
      localStorage.setItem('oga_rest_token', restToken);
      handleSave();
    }).catch((err) => {
      toast.error(err?.message);
      setLoading(false);
    });
  };

  const handleOldAffiliateSignUp = () => {
    const { email } = state;
    generateUserToken({
      variables: {
        userEmail: email,
      }
    }).then((resp) => {
      const {
        token, restToken
      } = resp.data?.generateUserToken ?? '';
      localStorage.setItem('oga_auth_token', token);
      OgaToken.authToken = token;
      localStorage.setItem('oga_rest_token', restToken);
      handleSave();
    }).catch((err) => {
      toast.error(err?.message);
      setLoading(false);
    });
  };

  const handleAccountCreation = async () => {
    const { accountStatus } = state;
    setLoading(true);
    if (accountStatus === 'USER REGISTRATION NOT COMPLETE') {
      handleOldAffiliateSignUp();
    } else {
      await handleSignup();
    }
  };

  const handleButtonClick = () => {
    const {
      legalName, contactName, mobile, email, addressLine1, landmark, state: userState, city
    } = state;
    if (legalName.length < 3) return setErrorState({ ...errorState, legalNameError: true });
    if (contactName.length < 3) return setErrorState({ ...errorState, contactNameError: true });
    if (addressLine1.length < 3) return setErrorState({ ...errorState, addressLine1Error: true });
    if (landmark.length < 3) return setErrorState({ ...errorState, landmarkError: true });
    if (userType.length < 3) return setErrorState({ ...errorState, userTypeError: true });
    if (userState.length < 3) return setErrorState({ ...errorState, stateError: true });
    if (city.length < 3) return setErrorState({ ...errorState, cityError: true });
    if (validateEmail(email)) return setErrorState({ ...errorState, emailError: true });
    if (validateNigerianNumber(mobile)) return setErrorState({ ...errorState, mobileError: true });

    return handleAccountCreation();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          legalNameError: !(value.length >= 3)
        });
      case 'contactName':
        return setErrorState({
          ...errorState,
          contactNameError: !(value.length >= 3)
        });
      case 'addressLine1':
        return setErrorState({
          ...errorState,
          addressLine1Error: !(value.length >= 3)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: validateNigerianNumber(value)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'landmark':
        return setErrorState({
          ...errorState,
          landmarkError: !(value.length >= 3)
        });
      case 'state':
        return setErrorState({
          ...errorState,
          stateError: !(value.length >= 3)
        });
      case 'userType':
        return setErrorState({
          ...errorState,
          userTypeError: !(value.length >= 3)
        });
      case 'city':
        return setErrorState({
          ...errorState,
          cityError: !(value.length >= 3)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'legalName',
      label: 'Pharmacy name / Legal Name',
      required: true,
      helperText: 'Legal name should be 3 or more characters'
    },
    {
      name: 'contactName',
      label: 'Affiliate first & Last name',
      required: true,
      helperText: 'Primary Contact name should be 3 or more characters'
    },
    {
      name: 'mobile',
      label: 'Phone number (+234)',
      required: true,
      helperText: 'Please provide a valid mobile number'
    },
    {
      name: 'email',
      label: 'Email Address',
      required: true,
      helperText: 'Please provide a valid email address'
    },
    {
      name: 'secondaryEmailAddress',
      label: 'Secondary Email Addresss',
      required: false,
      helperText: 'Please provide a valid email address'
    },
    {
      name: 'addressLine1',
      label: 'Pharmacy address',
      required: true,
      helperText: 'Address should be 3 or more characters'
    },
    {
      name: 'salesRepresentative',
      label: 'Sales Representative',
      required: false,
      helperText: 'Sales representative should be 3 or more characters'
    },
    {
      name: 'landmark',
      label: 'Landmark',
      required: true,
      helperText: 'Landmark should be 3 or more characters'
    },
    { name: 'city', label: 'City', required: true },
    {
      name: 'state',
      label: 'State',
      required: true,
      helperText: 'Please select a state',
      options: nigeriaState,
    },
  ];

  // const bottomFields = [
  //   {
  //     name: 'userType',
  //     label: 'User Type',
  //     required: true,
  //     options: [
  //       'OGApharmacy market place affiliate', 'ERP pharmacy management', 'OGA pharmacy market place supplier'
  //     ]
  //   },
  // ];

  const renderError = (name) => {
    switch (name) {
      case 'legalName': return legalNameError;
      case 'contactName': return contactNameError;
      case 'addressLine1': return addressLine1Error;
      case 'mobile': return mobileError;
      case 'email': return emailError;
      case 'landmark': return landmarkError;
      case 'state': return stateError;
      case 'userType': return userTypeError;
      case 'city': return cityError;
      default:
        return false;
    }
  };

  const renderHelperText = (fieldName) => {
    switch (fieldName) {
      case 'legalName': return legalNameError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'contactName': return contactNameError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'addressLine1': return addressLine1Error
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'mobile': return mobileError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'email': return emailError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'landmark': return landmarkError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'city': return cityError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'state': return stateError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'userType': return userTypeError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      default:
        return false;
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required
    } = field;
    const value = state[fieldName];
    if (fieldName === 'userType' || fieldName === 'state') {
      return (
        <InputWrapper item container direction="column">
          <Grid item container justifyContent="space-between">
            <Label error={renderError(fieldName)}>
              {label}
              {' '}
              {required && <span style={{ color: 'red' }}>*</span>}
            </Label>
          </Grid>
          <ReturnSelectField
            field={field}
            value={value}
            handleChange={handleChange}
            fullWidth
            required={required || false}
            helperText={renderHelperText(fieldName)}
          />
        </InputWrapper>
      );
    }
    return (
      <InputWrapper item container direction="column">
        <Grid item container justifyContent="space-between">
          <Label error={renderError(fieldName)}>
            {label}
            {' '}
            {required && <span style={{ color: 'red' }}>*</span>}
          </Label>
        </Grid>
        <CustomField
          variant="outlined"
          name={fieldName}
          value={value}
          size={isLarge ? 'small' : 'medium'}
          type="text"
          required={required || false}
          fullWidth
          error={renderError(fieldName)}
          helperText={renderHelperText(fieldName)}
          onChange={handleChange}
          InputProps={{ disableUnderline: true }}
          className={`mpfe-uat-business-setup-${fieldName}`}
        />
      </InputWrapper>
    );
  };

  return (
    <MainGridContainer container>
      <BusinessSetupGridContainer container item md={12} xs={12}>
        {/* <Grid item container>
          <Logo alt="PharmIQ" src={PharmIQBig} />
        </Grid> */}
        <LogoGridContainer item container xs={12} md={6} lg={6}>

          <Img alt="PharmIQ" src={AuthImage} />
          <TypoWrapper>
            <LogoTypo variant="h5">
              Quality Guaranteed
            </LogoTypo>
            <TypoDesc>
              Your reliable source for trusted pharmaceutical products
            </TypoDesc>
          </TypoWrapper>
        </LogoGridContainer>
        <ContentBox xs={12} md={6} lg={6}>
          <OgaAdminLogo />
          <PrimaryTitle variant="h5">
            Create Affiliate
            {/* Set up your PharmIQ account */}
          </PrimaryTitle>
          <SubTypo variant="h6">
            Welcome, create affiliate and enjoy our marketplace
          </SubTypo>
          <FieldWrapper item container spacing={2}>
            {topFields.map((field) => (
              <Grid key={field} item sm={12}>
                {returnTextField(field)}
              </Grid>
            ))}
          </FieldWrapper>
          <CreateButton fullWidth onClick={handleButtonClick}>
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : (
              'Submit'
            )}
          </CreateButton>
        </ContentBox>
      </BusinessSetupGridContainer>
    </MainGridContainer>
  );
};

export default BusinessSetupContainer;
