import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import {
  FiltersGrid, ExportText, SearchGrid, ExportGrid, DateGrid
} from './filter.styles';
import CustomDateRanges from '../../../shared/customDateRanges/customDateRanges';
import CustomSearchField from '../../../shared/CustomSearchField';
import { EXPORT_MUTATION } from '../../../../mutations/reports';

const Filter = ({
  state, handleDateSubmit, handleSearch, id, tabVal
}) => {
  const { searchText } = state;
  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const handleDownload = async () => {
    const {
      dateFrom, dateTo,
    } = state;
    let variable = {
      dateFrom, dateTo, orderId: searchText, productId: id
    };
    if (tabVal === 'return') {
      variable = {
        ...variable,
        returned: true
      };
    }
    const randomNum = Math.floor(Math.random() * 10000);
    const NewfileName = `Order logs ${randomNum}`;

    exportMutation({
      variables: {
        type: 'order_logs',
        name: NewfileName,
        status: '',
        ...variable
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <Grid item container xs={12}>
      <FiltersGrid container item>
        <SearchGrid item container>
          <CustomSearchField
            name="search"
            value={searchText}
            fullWidth
            placeholder="Search by Order ID"
            handleChange={(e) => handleSearch(e?.target?.value)}
            style={{ width: '100%' }}
          />
        </SearchGrid>
        <DateGrid item>
          <CustomDateRanges
            handleSubmit={handleDateSubmit}
            xs={12}
            styles={{ height: '3rem', width: '100%', border: '1px solid #bababa' }}
          />
        </DateGrid>
        <ExportGrid item container>
          <CustomButton
            style={{ width: '100%', height: '3rem', }}
            onClick={handleDownload}
          >
            <ExportText>Download CSV</ExportText>
          </CustomButton>
        </ExportGrid>
      </FiltersGrid>
    </Grid>
  );
};

Filter.propTypes = {
  state: PropTypes.shape(Object),
  handleSearch: PropTypes.func.isRequired,
  handleDateSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  tabVal: PropTypes.string.isRequired
};

Filter.defaultProps = {
  state: {},
};

export default Filter;
