import HelpDeskActionTypes from './helpDeskTypes';

const helpDeskReducer = (helpDesk, action) => {
  switch (action.type) {
    case HelpDeskActionTypes.UPDATE_HELP_DESK:
      return {
        ...helpDesk,
        ...action.payload
      };
    default:
      return helpDesk;
  }
};

export default helpDeskReducer;
