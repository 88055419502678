import {
  Typography, FormControl, Select
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CreditNameTypo = styled(Typography)`
  font-size: .7rem;
`;

export const SelectFormControl = styled(FormControl)`
  & .MuiSelect-selectMenu {
    height: 1.7rem;
    background-color: #FFF;
    border-radius: 10px;
  }

  & .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  & .MuiOutlinedInput-root.Mui-focused & .MuiOutlinedInput-notchedOutline {
    border-color: #d8d8d8;
    border-width: 0.5px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #d8d8d8;
    border-width: 0.5px;
  }
`;

export const CSelect = styled(Select)`
  border: 0px solid #d8d8d8;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #606060;
  line-height: 3.2;
  @media(max-width: 991px) {
    font-size: 1.5rem;
  }


  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 12px;
    margin-left: 8px;
  }

  &:active, &:focus, &:focus-within {
    border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #303030')};
  }
  & .MuiOutlinedInput-root.Mui-focused & .MuiOutlinedInput-notchedOutline {
    border-color: #d8d8d8;
    border-width: 1px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: #d8d8d8;
    border-width: 1px;
  }
`;

export const Label = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  opacity: 0.5;
  margin-bottom: .2rem;
  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;
