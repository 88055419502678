import moment from 'moment';

export const dateButtons = (event) => {
  moment.updateLocale('en', {
    week: {
      dow: 1, // Monday is the first day of the week.
    }
  });
  const start = moment().startOf(event);
  const end = moment().endOf(event);
  let dateRange;
  if (event === 'allTime') return { start: moment().format(), end: moment().format(), dateRange: moment().calendar() };
  if (event === 'day') dateRange = moment().format('ll');
  else dateRange = `${start.format('ll')} - ${end.format('ll')}`;
  return { start, end, dateRange };
};

export const formatDates = (event) => {
  switch (event) {
    case '1 month':
      return 31;
    case '3 months':
      return 61;
    case '6 months':
      return 183;
    case '12 months':
      return 365;
    default:
      break;
  }
  return null;
};

export const editCustomDateRange = (dateObject) => {
  moment.updateLocale('en', {
    week: {
      dow: 1, // Monday is the first day of the week.
    }
  });
  const {
    startDate, endDate
  } = dateObject;
  const start = moment(startDate);
  const end = moment(endDate).endOf('day');
  const event = 'custom';
  const dateRange = `${moment(start).format('ll')} - ${moment(end).format('ll')}`;

  return {
    start, end, event, dateRange
  };
};

export const iconDateRange = (direction, active, startDate, endDate) => {
  let start;
  let end;

  if (direction === 'backward') {
    if (active === 'day') {
      start = startDate.clone().subtract(1, 'd');
      end = endDate.clone().subtract(1, 'd');
    } else if (active === 'week') {
      start = startDate.clone().subtract(7, 'd');
      end = endDate.clone().subtract(7, 'd');
    } else {
      start = startDate.clone().subtract(1, 'months').date(1);
      end = endDate.clone().subtract(1, 'months').endOf('month');
    }
  } else if (active === 'day') {
    start = startDate.clone().add(1, 'd');
    end = endDate.clone().add(1, 'd');
  } else if (active === 'week') {
    start = startDate.clone().add(7, 'd');
    end = endDate.clone().add(7, 'd');
  } else {
    start = startDate.clone().add(1, 'months').date(1);
    end = endDate.clone().add(1, 'months').endOf('month');
  }

  let dateRange;
  if (active === 'day') dateRange = start.format('ll');
  else dateRange = `${start.format('ll')} - ${end.format('ll')}`;
  return { start, end, dateRange };
};
