import ModelActionTypes from './modelTypes';

const modelReducer = (model, action) => {
  switch (action.type) {
    case ModelActionTypes.UPDATE_MODEL:
      return {
        ...model,
        ...action.payload
      };
    case ModelActionTypes.TOGGLE_DIALOG_OPEN:
      return {
        ...model,
        addModelDialogOpen: !model.addModelDialogOpen
      };
    case ModelActionTypes.CLEAR_MODEL:
      return {
        ...model,
        model: null,
        addModelDialogOpen: false
      };
    default:
      return model;
  }
};

export default modelReducer;
