import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box } from '@mui/material';
import { Dots } from '../../assets/svgs';
import ActionPopper from './actionPopper';
import {
  BodyCell, MainTableRow, ColorGrid, StatusColor, StatusText
} from './returnRow.styles';
import { Product } from '../../providers/reducers/product/classes/Product';
import { useStateValue } from '../../providers/stateProvider';

const ReturnRow = ({ row, handleDeleteAction }) => {
  const [buttonEl, setButtonEl] = useState(null);

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const {
    name, dateCreated, endDate, startDate, status, value, unit
  } = row;

  const handleOpenPopper = (event) => {
    setButtonEl(buttonEl ? null : event.currentTarget);
  };

  const handleDelete = () => {
    handleOpenPopper();
    handleDeleteAction();
  };

  const getDate = (date) => moment(date).format('DD MMM YYYY');
  const getVal = () => {
    if (unit === 'PERCENTAGE') return `${value}%`;
    return `₦ ${value}`;
  };

  const cells = [
    name, getVal(), getDate(dateCreated), getDate(startDate), getDate(endDate)
  ];

  const returnCell = (val) => val || '-';

  const getStatus = () => {
    switch (status) {
      case 'UPCOMING':
        return (
          <Box style={{ display: 'flex' }}>
            <StatusColor status="upcoming" />
            <StatusText>Upcoming</StatusText>
          </Box>
        );
      case 'ONGOING':
        return (
          <Box style={{ display: 'flex' }}>
            <StatusColor status="ongoing" />
            <StatusText>Ongoing</StatusText>
          </Box>
        );
      case 'ENDED':
        return (
          <Box style={{ display: 'flex' }}>
            <StatusColor status="ended" />
            <StatusText>Ended</StatusText>
          </Box>
        );
      default:
        return (
          <Box style={{ display: 'flex' }}>
            <StatusColor status="deleted" />
            <StatusText>Deleted</StatusText>
          </Box>
        );
    }
  };

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell>{ returnCell(cell) }</BodyCell>
  ));

  return (
    <MainTableRow item container>
      {renderBodyCells()}
      <BodyCell>{getStatus()}</BodyCell>
      <BodyCell>
        <Dots onClick={handleOpenPopper} style={{ fontSize: '1.4rem', cursor: 'pointer', pointerEvents: !userPermissions?.includes('promotions_view_actions') ? 'none' : '' }} />
        <ActionPopper
          action={buttonEl}
          row={row}
          handleDelete={handleDelete}
          handleClose={() => setButtonEl(false)}
        />
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  handleDeleteAction: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
