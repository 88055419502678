import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getUser } from '../../utils/utils';
import MainContent from '../customComponents/mainContent';
import { Wave } from '../../assets/svgs';
import ActivityThreeColCard from './components/activityThreeColCard';
import ActivityFourColCard from './components/activityFourColCard';
import ProductTable from './components/productTable';
import { ContainerBody } from './dashboardContainer.styles';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';

const AffiliateDashboard = ({ session }) => {
  useEffect(() => {
    window.scroll(1, 1);
  }, []);

  const loggedInUser = getUser(session);

  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');

  const [dateFilter, setDateFilter] = useState({
    dateFrom: startDate,
    dateTo: endDate
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom,
      dateTo
    });
  };

  return (
    <MainContent>
      <ContainerBody>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box display="flex" alignItems="center" gridGap={8}>
              <h1>
                Hi,
                {` ${loggedInUser} `}
              </h1>
              <Wave fontSize="large" />
            </Box>
            <Box sx={{ mb: 2 }}>Sale activity analytics</Box>
          </Grid>
          <Grid item>
            <CustomDateRanges
              handleSubmit={handleSubmit}
              xs={12}
            />
          </Grid>
        </Grid>

        <ActivityThreeColCard />

        <ActivityFourColCard />

        <ProductTable dateFilter={dateFilter} />
      </ContainerBody>
    </MainContent>
  );
};
AffiliateDashboard.propTypes = {
  session: PropTypes.instanceOf(Object)
};

AffiliateDashboard.defaultProps = {
  session: {}
};

export default AffiliateDashboard;
