import { styled } from '@mui/material/styles';
import { MenuItem, Typography, Button } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';

export const ContainerBody = styled('div')`
  margin-top: 10px;
  padding: 20px 40px;
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 6rem 0.9rem 1rem;
  font-size: 15px;
`;

export const TypographyStyled = styled(Typography)`
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #303030 !important;
  margin-top: .5rem;
`;

export const TypographyCountStyled = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  margin-top: 1rem;
  color: #000000;
`;

// export const TitleText = styled.span`
//   font-size: 14px;
//   font-weight: 700;
//   color: #303030;
// `;

export const Title = styled('span')`
  font-size: 14px;
  font-weight: 700;
  color: #303030;
`;

export const ButtonStyled = styled(Button)`
  min-width: 107px;
  min-height: 48px;
  background: #235A91 !important;
  border-radius: 10px;
  color: #fff;
  &:disabled {
    color: #fff !important;
  }
`;

export const HeaderStyled = styled('div')`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
`;

export const KeyboardDatePickerStyled = styled(DatePicker)`
  width: 12rem;
  border: 0px important;
`;

export const HeaderText = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  color: #24292E;
`;

export const ArrowImg = styled('img')`
  width: 43px;
  height: 41px;
  margin-top: 1rem;
`;
