import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import userPlaceholder from '../../../../assets/images/userplaceholder.png';
import {
  CardContentGrid,
  CardHeader,
  CardPaper,
  ContentHeader,
  HeaderIcon,
  HeaderText
} from './roleCard.styles';

const RoleCard = ({ businessRole, handleRoleSelect }) => {
  const { id, role } = businessRole;

  return (
    <Grid item container xs={4}>
      <CardPaper
        elevation={2}
        onClick={handleRoleSelect}
      >
        <CardHeader container item>
          <HeaderIcon src={userPlaceholder} alt="role logo" />
          <HeaderText>
            {`RID${id}`}
          </HeaderText>
        </CardHeader>
        <CardContentGrid>
          <ContentHeader>
            {role.name}
          </ContentHeader>
        </CardContentGrid>
      </CardPaper>
    </Grid>
  );
};

RoleCard.propTypes = {
  businessRole: PropTypes.instanceOf(Object).isRequired,
  handleRoleSelect: PropTypes.func.isRequired
};

export default RoleCard;
