import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { Grid } from '@mui/material';
import getCroppedImg from './cropImage';
import {
  MainWrapper, CropContainer, ControlsContainer, HeaderText,
  PlusButton,
  ZoomScrollbar,
  ZoomSlider,
  MinusButton,
  ActionContainer,
  ImageWrapper,
  ButtonText,
  EditButton
} from './cropImageContainer.styles';
import { ZoomIn, ZoomOut } from '../../../../../assets/svgs';
import { CircularProgressLoader } from '../../../../affiliateProfile/styles';

const CropImageContainer = ({
  files, setFinalImage, handleSave, name, isLoading
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoomed, setZoomed] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);
  const [aspectRatio, setAspectRatio] = useState();

  const onCropComplete = useCallback((croppedArea, croppedAPixels) => {
    setCroppedAreaPixels(croppedAPixels);
  }, []);

  const setDataUrl = async (_files) => {
    const file = _files[0];
    const imageDataUrl = file.data;
    setImageSrc(imageDataUrl);
  };

  useEffect(() => {
    if (files && files.length > 0) {
      setDataUrl(files);
    }
  }, [files]);

  useEffect(() => {
    const setImageData = async () => {
      if (files && files.length > 0) {
        const file = files[0];
        const imageDataUrl = file.data;
        setImageSrc(imageDataUrl);
        // Get the dimensions of the image using an image element
        const image = new Image();
        image.src = imageDataUrl;
        await image.decode();
        const imageWidth = image.naturalWidth;
        const imageHeight = image.naturalHeight;

        // Calculate the aspect ratio for the crop area based on the image dimensions
        setAspectRatio(imageWidth / imageHeight);

        // Set the initial crop aspect ratio and size based on the image dimensions
        const cropSize = {
          width: 1,
          height: 1 / aspectRatio,
        };
        setCrop({
          x: 0,
          y: 0,
          aspect: aspectRatio,
          ...cropSize,
        });
      }
    };

    setImageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, name]);

  const saveImage = async () => {
    try {
      const image = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      setCroppedImage(image);
      setFinalImage(image);
      handleSave(image, name);
    } catch (e) {
      // console.error(e);
    }
  };

  return (
    <Grid item container>
      <MainWrapper item container>
        <ImageWrapper container>
          <CropContainer>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoomed}
              aspect={aspectRatio}
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoomed}
              overlay={{
                mask: {
                  borderRadius: '50%',
                  overflow: 'hidden',
                },
                maskColor: 'red',
                maskOpacity: 0.6,
                strokeColor: '#fff',
                strokeOpacity: 0.6,
              }}
            />
          </CropContainer>
        </ImageWrapper>
        <ActionContainer container xs={12}>
          <Grid xs={6}>
            <HeaderText>Zoom</HeaderText>
            <ControlsContainer>
              <MinusButton
                onClick={() => setZoomed((_zoomed) => _zoomed - 0.1)}
                disabled={zoomed === 1}
              >
                <ZoomIn />
              </MinusButton>
              <ZoomScrollbar>
                <ZoomSlider value={zoomed * 10} onChange={(_, value) => setZoomed(value)} />
              </ZoomScrollbar>
              <PlusButton
                onClick={() => setZoomed((_zoomed) => _zoomed + 0.1)}
                disabled={zoomed === 3}
              >
                <ZoomOut />
              </PlusButton>
            </ControlsContainer>
          </Grid>

          <Grid container xs={6} style={{ justifyContent: 'flex-end' }}>
            <EditButton
              onClick={saveImage}
            >
              {isLoading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : (
                <ButtonText>
                  Save
                </ButtonText>
              )}
            </EditButton>
          </Grid>
        </ActionContainer>
      </MainWrapper>
    </Grid>
  );
};

CropImageContainer.propTypes = {
  files: PropTypes.instanceOf(Array).isRequired,
  handleSave: PropTypes.func.isRequired,
  setFinalImage: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

CropImageContainer.defaultProps = {
  isLoading: false
};
export default CropImageContainer;
