import {
  Grid,
  Slide, useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import success from '../../assets/images/Checksuccesful.gif';
import warning from '../../assets/images/Warning.gif';
import CustomButton from '../customComponents/customButton';
import {
  ActionContent,
  CircularProgressLoader,
  CloseIcon,
  ContentWrapper,
  CustomDialog,
  GigImg,
  Heading, Subheading,
  WrapperGrid
} from './notificationDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const NotificationDialog = ({
  openDialog, setOpenDialog, type, title, desc, action, actionFunc,
  action2, action2Func, loading
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const handleClose = async () => {
    setOpenDialog(false);
    actionFunc();
  };

  const handleAction2 = async () => {
    action2Func();
    setOpenDialog(false);
  };

  const getGif = () => {
    switch (type) {
      case 'warning': return warning;
      case 'error': return warning;
      default:
        return success;
    }
  };

  return (
    <CustomDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <WrapperGrid item container>
        <Grid item container justifyContent="flex-end">
          <CloseIcon onClick={handleClose} />
        </Grid>
        <ContentWrapper item container direction="column">
          <Grid item container justifyContent="center">
            <GigImg src={getGif()} alt={type} />
          </Grid>
          <Heading>{title}</Heading>
          <Subheading>{desc}</Subheading>
        </ContentWrapper>
        <ActionContent item container spacing={2}>
          <Grid item container xs={action2 ? 6 : 12}>
            <CustomButton
              type="tertiary"
              header
              onClick={handleClose}
              style={{
                width: '100%', height: isSmall ? '7rem' : '2.7rem', fontWeight: '700', fontSize: isSmall ? '30px' : '12px', color: action2 ? '#235A91' : '#ffffff',
                background: !action2 && '#235A91', boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)', borderRadius: isSmall ? '20px' : '5px',
                border: isSmall ? action2 && '3.5px solid #235A91' : action2 && '1.5px solid #235A91'
              }}
            >
              {action}
            </CustomButton>
          </Grid>
          {action2 && (
            <Grid item container xs={6}>
              <CustomButton
                type="tertiary"
                header
                onClick={handleAction2}
                disabled={loading}
                style={{
                  width: '100%', height: isSmall ? '7rem' : '2.7rem', fontWeight: '700', fontSize: isSmall ? '30px' : '12px', color: '#ffffff',
                  background: '#235A91', boxShadow: '0px 4px 14px rgba(151, 151, 151, 0.19)', borderRadius: isSmall ? '20px' : '5px'
                }}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : action2}
              </CustomButton>
            </Grid>
          )}
        </ActionContent>
      </WrapperGrid>
    </CustomDialog>
  );
};

NotificationDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  action: PropTypes.string,
  action2: PropTypes.string,
  actionFunc: PropTypes.func,
  action2Func: PropTypes.func,
  loading: PropTypes.bool,
};

NotificationDialog.defaultProps = {
  openDialog: false,
  title: '',
  desc: '',
  type: 'success',
  action: 'Ok',
  action2: '',
  actionFunc: () => {},
  action2Func: () => {},
  loading: false
};

export default NotificationDialog;
