import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { JSONParse } from '../../../../utils/json';
import {
  CardGridContainer, CardHeader, HeaderText,
  HeaderIcon, CardContentGrid, ContentHeader,
  ContentMeta, FooterMeta, ContentSubMeta
} from './outletUser.styles';
import userPlaceholder from '../../../../assets/images/userplaceholder.png';

export default function SupplierCard({ bizUser }) {
  const navigate = useNavigate();
  const {
    id, meta, user, business
  } = bizUser;
  const { profile, contacts } = user;
  const { name: businessName } = business;
  const { job_title: jobTitle } = JSONParse(meta);
  const { last_name: lastName, first_name: firstName } = JSONParse(profile);
  const { email } = JSONParse(contacts);

  const renderName = () => {
    if (firstName && lastName) return `${firstName} ${lastName}`;
    return email.split('@')[0];
  };

  return (
    <CardGridContainer
      elevation={2}
      item
      container
      onClick={() => navigate(`/settings/users/details/${id}`)}
    >
      <CardHeader container item>
        <HeaderIcon src={userPlaceholder} alt="supplier logo" />
        <HeaderText>
          {`UR${id}`}
        </HeaderText>
      </CardHeader>

      <CardContentGrid>
        <ContentHeader>
          {renderName()}
        </ContentHeader>
        <ContentSubMeta>
          {email}
        </ContentSubMeta>

        <FooterMeta>
          <ContentMeta>
            {jobTitle}
          </ContentMeta>
          <ContentMeta>
            {`${businessName}`}
          </ContentMeta>
        </FooterMeta>

      </CardContentGrid>
    </CardGridContainer>
  );
}

SupplierCard.propTypes = {
  bizUser: PropTypes.instanceOf(Object).isRequired
};
