import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Typography, Box, MenuItem
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import {
  CDialog, DialogTitleText, CircularProgressLoader, TextFieldBox, CTextField,
  GridWrapper, CloseIcon, FieldHeader
} from './createCategoryDialog.styles';
import ConfirmDialog from '../confirmDialog';
import SuccessDialog from '../../indevidualCategory/successDialog';
import { CREATE_BUSINESS_CATEGORY_MUTATION, UPDATE_BUSINESS_CATEGORY_MUTATION } from '../../../../mutations/categories';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateCategoryDialog = ({
  open, close, isEdit, category, categoryCount, refetch
}) => {
  const initialState = { categoryName: '', markup: '', };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [createBusinessCategory] = useMutation(CREATE_BUSINESS_CATEGORY_MUTATION);
  const [updateBusinessCategory] = useMutation(UPDATE_BUSINESS_CATEGORY_MUTATION);

  const [openDialog, setOpenDialog] = useState(false);
  const { categoryName, markup } = state;

  const textFieldHeader = [
    {
      name: 'categoryName', type: 'text',
      label: 'Category Name', placeholder: 'Enter Category Name'
    },
    {
      name: 'markup', type: 'number',
      label: 'Markup (%)', placeholder: 'Enter Markup'
    },
  ];

  useEffect(() => {
    if (category) {
      setState({ ...state, categoryName: category?.categoryName, markup: category.markup });
    }
  }, [category]);
  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  const toggleConfirmation = () => {
    setOpenDialog(!openDialog);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleCreate = () => {
    if (categoryName === '' || markup === '') return toast.error('Category name and markup fields cannot be empty');
    setLoading(true);
    const variables = { categoryName: categoryName.trim(), markup };
    createBusinessCategory({ variables })
      .then(({ data }) => {
        const { message } = data?.createBusinessCategory || {};
        toast.success(message);
        setState(initialState);
        setSuccessDialog(true);
        refetch();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = () => {
    toggleConfirmation();
  };

  const handleSave = () => {
    if (isEdit) return handleEdit();
    return handleCreate();
  };

  const toggleSuccessModal = () => {
    handleSuccessDialog();
    close();
  };

  const handleUpdate = () => {
    if (categoryName === '' || markup === '') {
      setOpenDialog(false);
      return toast.error('Category name and markup fields cannot be empty');
    }
    const variables = {
      categoryId: category?.id, categoryName: categoryName.trim(), markup
    };
    updateBusinessCategory({ variables })
      .then(({ data }) => {
        const { message } = data?.createBusinessCategory || {};
        handleSuccessDialog();
        refetch();
        toggleConfirmation();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const textField = (field) => {
    const {
      name: fieldName, label, shrink, type, placeholder
    } = field;
    const _value = state[fieldName];
    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          value={_value}
          size="large"
          name={fieldName}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
          fullWidth
          required
          className={`mpAdmin-uat-customerCategory-add-category-${fieldName}`}
          InputProps={{
            style: { height: '4rem', fontSize: '1rem', borderRadius: '.6rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '.9rem', textAlign: 'center' },
            shrink
          }}
        />
      </TextFieldBox>
    );
  };
  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ marginTop: '2.7rem', padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              {isEdit ? 'Edit Category' : 'Add Category'}
            </DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Box>
          {textFieldHeader.map((fields) => (
            <Box key={fields}>
              <FieldHeader>
                {fields.label}
              </FieldHeader>
              {textField(fields)}
            </Box>
          ))}
        </Box>

        <DialogActions>
          <CustomButton
            type="tertiary"
            style={{
              width: '11rem', height: '3.2rem', marginRight: '1.2rem'
            }}
            onClick={handleCloseDialog}
            className="mpAdmin-uat-customerCategory-add-category-cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton
            style={{ width: '11rem', height: '3.2rem' }}
            onClick={handleSave}
            className="mpAdmin-uat-customerCategory-add-category-save"
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : (
              isEdit ? 'save' : 'Add Category'
            ) }
          </CustomButton>
        </DialogActions>
      </GridWrapper>
      <SuccessDialog
        openConfirmation={successDialog}
        handleClose={toggleSuccessModal}
        title={isEdit ? 'Category Updated!' : 'Category Added Successfully!'}
        discreption={isEdit ? 'Your category has been successfully updated!' : 'Your category has been successfully added!'}
      />
      <ConfirmDialog
        openConfirmation={openDialog}
        handleClose={toggleConfirmation}
        title="Update Category"
        discreption="Are you sure you want to update this category?"
        buttonTitle="Yes, Update"
        handleConfirm={handleUpdate}
      />
    </CDialog>
  );
};

CreateCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  category: PropTypes.instanceOf(Object).isRequired
};

export default CreateCategoryDialog;
