import * as React from 'react';
import { Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

const CardLoader = ({ cols }) => (
  <Grid container spacing={2} style={{ marginTop: '.4rem' }}>
    {Array.from(new Array(cols)).map((index) => (
      <Grid item xs={6} xl={3} key={index}>
        <Skeleton
          variant="rounded"
          height={150}
          style={{ width: '100%', borderRadius: '.6rem' }}
        />
      </Grid>
    ))}
  </Grid>
);

export default CardLoader;

CardLoader.propTypes = {
  cols: PropTypes.number.isRequired
};
