import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import { AFFILIATE_DETAIL_QUERY } from '../../../queries/affiliates';
import AffiliatesHeader from './affiliatesHeader';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import EditIcon from '../../../assets/images/Edit Icon.png';
import IndividualAffiliateDialog from '../uploadAffiliate/individual/individualAffiliateDialog';
import AffiliatePaymentDialog from './affiliatePaymentDialog';
import { cherryPickOrder } from '../utils';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, ViewCartButton, ViewCartButtonText, TextWrapper,
  HeaderIcon, MenuButton, HeaderButtons, PayButton, PayButtonText, PayIcon, KeyboardArrowLeft
} from './affiliatesDetailsContainer.styles';
import payIcon from '../../../assets/images/Pay Icon.png';

import AffiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import GoToTop from '../../customComponents/scrollToTop';

import { useStateValue } from '../../../providers/stateProvider';
import AffiliatesPaymentHistory from './affiliatesPaymentHistory';

const AffiliatesDetailsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState({});

  const [{
    affiliate: { addAffiliateDialogOpen }
  }, dispatch] = Object.values(useStateValue());

  const openAffiliateEditDialog = (affiliate) => {
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliate,
        addAffiliateDialogOpen: true
      }
    });
  };

  const updateAffiliateState = (refetch) => {
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliateDetailRefetch: refetch
      }
    });
  };

  const toggleAffiliateEditDialog = () => {
    dispatch({ type: AffiliateActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const {
    loading, error, data, refetch
  } = useQuery(AFFILIATE_DETAIL_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const handleSelectedOrders = () => {
    const orders = cherryPickOrder(data);
    setSelectedOrders(orders);
    setOpenPaymentDialog(true);
  };

  useEffect(() => {
    if (data && data.business) {
      updateAffiliateState(refetch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <MenuButton onClick={() => navigate('/affiliates')}>
              <KeyboardArrowLeft fontSize="large" />
            </MenuButton>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                Affiliate Detail
              </PrimaryTitle>
              <SecondaryTitle>
                View details of a specified affiliate
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
          <HeaderButtons item>
            <PayButton onClick={() => handleSelectedOrders()}>
              <Hidden smDown>
                <PayIcon src={payIcon} />
              </Hidden>
              <PayButtonText>
                Add Payment
              </PayButtonText>
            </PayButton>
            <ViewCartButton onClick={() => openAffiliateEditDialog(data)}>
              <Hidden smDown>
                <HeaderIcon alt="cart icon" src={EditIcon} />
              </Hidden>
              <ViewCartButtonText>
                Edit
              </ViewCartButtonText>
            </ViewCartButton>
          </HeaderButtons>
        </TitleGridContainer>
        <AffiliatesHeader
          data={data}
        />
      </OrderGridContainer>

      <AffiliatesPaymentHistory />

      <GoToTop />

      <IndividualAffiliateDialog
        dialogOpen={addAffiliateDialogOpen}
        closeDialog={toggleAffiliateEditDialog}
      />

      <AffiliatePaymentDialog
        open={openPaymentDialog}
        orders={selectedOrders}
        affiliateId={id}
        handleCloseModal={() => setOpenPaymentDialog(false)}
      />
    </MainContent>
  );
};

// export default withAuth(AffiliatesDetailsContainer);
export default AffiliatesDetailsContainer;
