import React, { useState, useLayoutEffect } from 'react';
import Hidden from '@mui/material/Hidden';

import Affiliates from './affiliates';
import MainContent from '../customComponents/mainContent';
import {
  AffiliateGridContainer, AffiliatesTab, AffiliatesNumber,
  IconsGridContainer, TabIcon, TabImage
} from './affiliates.styles';

import TitleGrid from './titleGrid';
import CustomTableSearchField from '../shared/customTableSearchField';
import sortAsc from '../../assets/images/supplier/Sort Ascending Icon.png';
import sortDesc from '../../assets/images/supplier/Sort Descending Icon.png';
import exportIcon from '../../assets/images/supplier/Export Icon.png';

import { useStateValue } from '../../providers/stateProvider';

function AffiliateContainer() {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    searchText: '',
    ascending: true
  };
  const [state, setState] = useState(initialState);
  const [{ affiliate: { affiliatesCount } }] = Object.values(useStateValue());
  const {
    currentPage, searchText
  } = state;

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, searchValue: searchText });
    } else if (!searchText) {
      setState({ ...state, searchValue: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };
  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };
  const handleSort = () => {
    setState({ ...state, ascending: !state.ascending });
  };
  const sortImage = (state.ascending) ? sortDesc : sortAsc;

  return (
    <MainContent>
      <AffiliateGridContainer>
        <TitleGrid />

        <AffiliatesTab item container>
          <AffiliatesNumber>
            {`${affiliatesCount} Affiliates`}
          </AffiliatesNumber>

          <Hidden smDown>
            <IconsGridContainer item container md={6}>
              <CustomTableSearchField
                searchText={searchText}
                placeholder="Search affiliate"
                handleChange={(e) => handleSearch(e.target.value)}
              />
              <TabIcon onClick={handleSort}>
                <TabImage src={sortImage} alt="Search" />
              </TabIcon>
              <TabIcon>
                <TabImage src={exportIcon} alt="Search" />
              </TabIcon>
            </IconsGridContainer>
          </Hidden>

        </AffiliatesTab>

        <Affiliates
          state={state}
          handleChangePage={handleChangePage}
          page={currentPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSearch={handleSearch}
        />
      </AffiliateGridContainer>
    </MainContent>
  );
}

// export default withAuth(AffiliateContainer);
export default AffiliateContainer;
