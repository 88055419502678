import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, Slide, Grid, InputAdornment
} from '@mui/material';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import DropZone from '../../logoDropzone';
import CropImageContainer from '../../logoProcess/cropImageContainer';
import InitialImage from '../../logoProcess/initialImage';
import CustomButton from '../../../customComponents/customButton';
import SavePopper from './savePopper';
import SearchPopper from './searchPopper';
import CustomInputBase from '../../../customComponents/customInputBase';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import {
  CDialog, DialogTitleText, DialogTitleSubText, CircularProgressLoader, UploadGrid,
  GridWrapper, CurrencyTypo, CloseIcon
} from './individualProductDialog.styles';
import {
  CREATE_PRODUCT_MUTATION, UPDATE_PRODUCT_MUTATION
} from '../../../../mutations/products';
import { FIND_CATEGORY_QUERY } from '../../../../queries/categories';
import { FIND_THERAPEUTIC_TYPE_QUERY } from '../../../../queries/therapeuticType';
import { FIND_SUPPLIERS_QUERY } from '../../../../queries/suppliers';
import { FIND_MANUFACTURERS_QUERY } from '../../../../queries/manufacturers';
import ProductActionTypes from '../../../../providers/reducers/product/productTypes';
import IndividualSupplierDialog from '../../../suppliers/individual/individualSupplierDialog';
import IndividualManufacturerDialog from '../../uploadManufacturer/individual/individualManufacturerDialog';

import { useStateValue } from '../../../../providers/stateProvider';
import { extractPrices } from '../../../../providers/reducers/product/productUtils';
import { JSONParse } from '../../../../utils/json';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualProductDialog = ({
  dialogOpen, closeDialog, businessState,
  updateOrder
}) => {
  const initialState = {
    brandName: '',
    molecules: '',
    packSize: '',
    qpc: '',
    barcode: '',
    categoryId: '',
    manufacturer: '',
    businessId: null,
    supplierName: '',
    image: '',
    id: '',
    description: '',
    unitOfMeasurement: '',
    supplierCost: 0,
    fixedItemPrice: '',
    marketRrp: '',
    fixedSellingPrice: 0,
    erpPriceInUse: '',
    marketPlacePriceInUse: '',
    reorderPoint: 0,
    reorderMin: 0.00,
    reorderMax: 0.00,
    reorderUnit: 'weeks',
    productVariant: '',
    safetyStock: 0,
    maxLeadTime: 0,
    minimumOrderQuantity: 0,
    productClass: 'drug',
    orderCost: 0,
    buffer: 5,
    reorderQuantity: 0,
    therapeuticType: '',
    categoryName: '',
    therapeuticTypeName: ''
  };

  const initialErrorState = {
    brandNameError: false,
    moleculesError: false,
    supplierNameError: false,
    manufacturerError: false,
    packSizeError: false,
    descriptionError: false,
    orderCostError: false,
    priceCategoryError: false,
    productClassError: false,
    therapeuticTypeError: false,
    unitOfMeasurementError: false,
    fixedSellingPriceError: false,
  };

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [suppliersNames,] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [supplierButtonEl, setSupplierButtonEl] = useState(null);
  const [manufacturerButtonEl, setManufacturerButtonEl] = useState(null);
  const [categoryButtonEl, setCategoryButtonEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const [showInitialImage, setShowInitialImage] = useState(false);
  const [searchSupplier, setSearchSupplier] = useState([]);
  const [searchManufacturers, setSearchManufacturers] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [openManufacturerDialog, setOpenManufacturerDialog] = useState(false);
  const [allTherapeuticTypeList, setAllTherapeuticTypeList] = useState([]);
  const [manuHelperText, setManuHelperText] = useState('Manufacturer name required');
  const [moleculeHelperText, setMoleculeHelperText] = useState('Molecule name required');

  const [{
    product: { productRow, refetch }, user: { platform }
  }, dispatch] = Object.values(useStateValue());

  const [createProduct] = useMutation(CREATE_PRODUCT_MUTATION);
  const [updateProduct] = useMutation(UPDATE_PRODUCT_MUTATION, {
    refetchQueries: ['productActionTimelines']
  });

  const [
    findCategory, { loading: catLoading, data: catData }
  ] = useLazyQuery(FIND_CATEGORY_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingTherapeuticType, data: allTherapeuticType
  } = useQuery(FIND_THERAPEUTIC_TYPE_QUERY);

  useEffect(() => {
    if (catData && catData.allCategories) {
      const category = catData.allCategories.map(({ categoryName, id }) => (
        { name: categoryName, id }
      ));
      setSearchCategory(category);
    }
  }, [catData, catLoading]);

  useEffect(() => {
    if (loadingTherapeuticType) return;
    const { allTherapeuticTypes } = allTherapeuticType ?? {};
    const mappedTherapeuticTypes = allTherapeuticTypes?.map((ttype) => (
      { therapeuticType: ttype.therapeuticType, id: ttype.id }
    ));
    setAllTherapeuticTypeList(mappedTherapeuticTypes);
  }, [loadingTherapeuticType]);

  const {
    brandNameError, moleculesError, packSizeError, supplierNameError, manufacturerError, descriptionError,
    orderCostError, priceCategoryError, productClassError, therapeuticTypeError, unitOfMeasurementError, fixedSellingPriceError
  } = errorState;
  // let businessId = localStorage.getItem('ogarx_business_id');
  const isErp = platform === 'erp';
  const isMP = platform === 'marketplace';

  useEffect(() => {
    if (productRow) {
      // businessId = localStorage.getItem('ogarx_business_id');
      setEditing(true);
      setShowInitialImage(true);
      let catId = '';
      let supName = '';
      let tType = '';
      let prodClass = '';
      let catName = '';
      let tTypeName = '';
      const {
        category,
        supplier,
        therapeuticType,
        productClass,
        molecule
      } = productRow;

      const moleculeStr = molecule ? JSONParse(molecule).toString().replaceAll(',', ', ') : '';

      if (therapeuticType) {
        tType = therapeuticType.id;
        tTypeName = therapeuticType.therapeuticType;
      }
      if (category) {
        catId = category.id;
        catName = category.categoryName;
      }
      if (supplier) {
        supName = supplier.name;
      }
      if (productClass) {
        prodClass = productClass.toLowerCase();
      }
      setState((_state) => ({
        ..._state, ...productRow, id: productRow.id, categoryId: catId, productClass: prodClass,
        therapeuticType: tType, supplierName: supName, ...extractPrices(productRow, platform),
        molecules: moleculeStr, categoryName: catName, therapeuticTypeName: tTypeName
      }));
    }
  }, [productRow]);

  useEffect(() => {
    if (businessState && businessState.name) {
      const { name } = businessState;
      setState((_state) => ({
        ..._state, supplier: name.split(' ')[0],
      }));
    }
  }, [businessState]);

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: null }
    });
    setShowInitialImage(false);
    setFinalImage(null);
    closeDialog();
  };

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleChangeInitialImage = () => {
    setShowInitialImage(false);
  };

  const handleDeleteInitialImage = () => {
    setShowInitialImage(false);
    setState({ ...state, image: '' });
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.PRODUCT_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const refetchSuppliers = () => {
    refetch();
  };

  const addProduct = (logo, addMore) => {
    const {
      brandName, molecules, description, packSize,
      supplierName, manufacturer, categoryId,
      therapeuticType, barcode, unitOfMeasurement, supplierCost,
      fixedItemPrice,
      marketRrp,
      fixedSellingPrice,
      erpPriceInUse, marketPlacePriceInUse, reorderMax, reorderPoint, reorderUnit, productVariant,
      safetyStock, maxLeadTime, minimumOrderQuantity, productClass, orderCost, buffer
    } = state;

    // const isOgaAdmin = localStorage.getItem('oga_user_role') === 'oga-pharmacy-admin';
    // const productType = isOgaAdmin ? ['GPS'] : ['PharmIQ'];
    let variables = {
      brandName,
      molecules,
      description,
      packSize,
      supplierName,
      manufacturer,
      logo,
      categoryId,
      therapeuticType,
      barcode,
      unitOfMeasurement,
      // eslint-disable-next-line radix
      buffer: parseInt(buffer),
      status: 'ACTIVE',
      supplierCost: supplierCost || 0,
      fixedItemPrice: fixedItemPrice || 0,
      marketRrp: marketRrp || 0,
      fixedSellingPrice: Number(fixedSellingPrice) || 0,
      automaticErpPrice: erpPriceInUse === 'Automatic',
      automaticMarketplacePrice: marketPlacePriceInUse === 'Automatic',
      productVariant,
      safetyStock,
      maxLeadTime: Number(maxLeadTime),
      minimumOrderQuantity,
      orderCost: orderCost || 0
    };
    // if we are in the erp then consider the reorder points
    if (isErp) {
      variables = {
        ...variables,
        reorderMax,
        reorderPoint,
        reorderUnit,
        productClass
      };
    }
    createProduct({ variables })
      .then(({ data }) => {
        const { message } = data?.createProduct || {};
        toast.success(message);
        if (!updateOrder) {
          // refetch(); throwing error and causing success to show
        }
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editProduct = (logo) => {
    const {
      brandName, molecules, description, packSize,
      supplierName, manufacturer, categoryId,
      therapeuticType, barcode, unitOfMeasurement,
      id, supplierCost,
      fixedItemPrice,
      marketRrp,
      fixedSellingPrice,
      erpPriceInUse, marketPlacePriceInUse, reorderMin, reorderMax, reorderPoint, reorderUnit, productVariant, safetyStock, maxLeadTime,
      minimumOrderQuantity, buffer, reorderQuantity,
      productClass
    } = state;

    let variables = {
      productId: Number(id),
      brandName,
      molecules,
      description,
      packSize,
      supplierName,
      manufacturer,
      logo,
      categoryId,
      therapeuticType,
      barcode,
      unitOfMeasurement,
      reorderMin,
      reorderQuantity,
      // eslint-disable-next-line radix
      buffer: parseInt(buffer),
      supplierCost: supplierCost || 0,
      fixedItemPrice: fixedItemPrice || 0,
      marketRrp: marketRrp || 0,
      fixedSellingPrice: fixedSellingPrice || 0,
      automaticErpPrice: erpPriceInUse === 'Automatic',
      automaticMarketplacePrice: marketPlacePriceInUse === 'Automatic',
      productVariant,
      ...(safetyStock && { safetyStock }),
      ...(maxLeadTime && { maxLeadTime }),
      ...(minimumOrderQuantity && { minimumOrderQuantity }),
    };

    if (isErp) {
      variables = {
        ...variables,
        ...(reorderMax && { reorderMax }),
        ...(reorderPoint && { reorderPoint }),
        reorderUnit,
        productClass
      };
    }
    if (!unitOfMeasurement) {
      toast.error('Unit Of Measurement cannot be empty');
      setLoading(false);
      return;
    }
    updateProduct({ variables })
      .then(({ data }) => {
        const { message } = data?.updateProduct || {};
        toast.success(message);
        refetch();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [
    findSupplier, { loading: supLoading, data }
  ] = useLazyQuery(FIND_SUPPLIERS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (data && data.findSupplier) {
      const suppliers = data?.findSupplier.map(({ name }) => name);
      setSearchSupplier(suppliers);
    }
  }, [data, supLoading]);

  const [
    findManufacturers, { loading: manLoading, data: manData }
  ] = useLazyQuery(FIND_MANUFACTURERS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (manData && manData.manufacturers) {
      const manufacturers = manData?.manufacturers.map(({ name }) => name);
      setSearchManufacturers(manufacturers);
    }
  }, [manData, manLoading]);

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134267/Placeholders/Product_Placeholder.png';
    const alreadyUploadedImage = state.image || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;
    if (editing) return editProduct(logo);
    return addProduct(logo, addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
    setFiles(null);
    setFinalImage(null);
  };

  const handleSaveButtonClick = (event) => {
    const {
      brandName, manufacturer, molecules, supplierName, packSize, description, orderCost,
      categoryId, categoryName, productClass, therapeuticType, unitOfMeasurement, fixedSellingPrice
    } = state;

    const invalidTexts = ['None', 'Null', 'N.a', 'n/a', 'none', 'na', 'null', 'N/A', 'n.a'];

    if (brandName === '') {
      toast.error('Brand Name is required');
    } else if (molecules === '') {
      toast.error('Molecule is required');
      setMoleculeHelperText('Molecule name required');
    } else if (supplierName === '') {
      toast.error('Suppliers Name is required');
    } else if (manufacturer === null || manufacturer === '') {
      toast.error('Manufacturer field is required');
      setManuHelperText('Manufacturer name required');
    } else if (packSize === null || packSize === '') {
      toast.error('Pack Size field is required');
    } else if (description === '') {
      toast.error('Description field is required');
    } else if (orderCost === '') {
      toast.error('Order cost field is required');
    } else if (categoryName === '') {
      toast.error('Price category field is required');
    } else if (categoryId === '') {
      toast.error('Select a valid Price category');
    } else if (productClass === '') {
      toast.error('Product class field is required');
    } else if (therapeuticType === '') {
      toast.error('Therapeutic type field is required');
    } else if (unitOfMeasurement === '') {
      toast.error('Unit of measurement field is required');
    } else if (fixedSellingPrice === '') {
      toast.error("Competitor's Selling Price is required");
    } else if (invalidTexts.includes(molecules)) {
      toast.error('Enter a valid Molecule name');
      setMoleculeHelperText('Enter a valid Molecule name');
    } else if (invalidTexts.includes(manufacturer)) {
      toast.error('Enter a valid Manufacturer name');
      setManuHelperText('Enter a valid Manufacturer name');
    } else {
      if (brandName.length > 1 && !editing) {
        return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
      } if (brandName.length > 1 && editing) {
        return handleSave();
      }
    }
    return setErrorState({
      ...errorState,
      brandNameError: brandName === '',
      moleculesError: molecules === '' || invalidTexts.includes(molecules),
      packSizeError: packSize === '',
      supplierNameError: supplierName === '',
      manufacturerError: manufacturer === null || manufacturer === '' || invalidTexts.includes(manufacturer),
      descriptionError: description === '',
      orderCostError: orderCost === '',
      priceCategoryError: categoryName === '' || categoryId === '',
      productClassError: productClass === '',
      therapeuticTypeError: therapeuticType === '',
      unitOfMeasurementError: unitOfMeasurement === '',
      fixedSellingPriceError: fixedSellingPrice === ''
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'brandName':
      case 'molecules':
      case 'packSize':
      case 'supplierName':
      case 'manufacturer':
      case 'description':
      case 'orderCost':
      case 'productClass':
      case 'therapeuticType':
      case 'fixedSellingPrice':
      case 'unitOfMeasurement':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !(value.length > 0)
        });
      case 'categoryName':
        return setErrorState({
          ...errorState,
          priceCategoryError: !(value.length > 0)
        });
      default:
        return null;
    }
  };

  const handleChangeuom = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });

    if (name === 'unitOfMeasurement') {
      errorState.unitOfMeasurementError = false;
      setErrorState(errorState);
    }
  };

  const handleChangeCategory = (priceCat) => {
    const { name, id } = priceCat;
    setState({
      ...state,
      categoryId: id,
      categoryName: name
    });
    setCategoryButtonEl(null);
  };

  const handleChangeReorderUnit = (event) => {
    const { value } = event.target;
    setState({
      ...state,
      reorderUnit: value,
      reorderMax: value === 'fixed' ? 10 : 4,
      reorderPoint: value === 'fixed' ? 5 : 1
    });
  };

  const handleChangeProductVariant = (event) => {
    const { value } = event.target;
    let variantValue = '';
    if (value === 'On Request') {
      variantValue = 'onRequest';
    } else {
      variantValue = 'readilyAvailable';
    }
    setState({
      ...state,
      productVariant: variantValue
    });
  };
  const handleChangeTherapeuticType = (event) => {
    const { value } = event.target;
    const foundCategory = allTherapeuticTypeList.find((cat) => cat.therapeuticType === value);
    setState({
      ...state,
      therapeuticType: foundCategory.id,
      therapeuticTypeName: foundCategory.therapeuticType
    });

    errorState.therapeuticTypeError = false;
    setErrorState(errorState);
  };

  const validateNumberInput = (name, value) => {
    const numberFields = ['maxLeadTime', 'fixedSellingPrice'];
    return numberFields.includes(name) && !/^\d*$/.test(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (validateNumberInput(name, value)) return;

    const searchAndSetButtonEl = (findFunction, setButtonElFunction, variables) => {
      findFunction({
        fetchPolicy: 'no-cache',
        variables,
      });
      setButtonElFunction(event.currentTarget);
    };

    if (value.length > 2) {
      switch (name) {
        case 'supplierName':
          searchAndSetButtonEl(findSupplier, setSupplierButtonEl, { search: value, onlyBusinessSuppliers: true });
          break;
        case 'manufacturer':
          searchAndSetButtonEl(findManufacturers, setManufacturerButtonEl, { search: value });
          break;
        case 'categoryName':
          searchAndSetButtonEl(findCategory, setCategoryButtonEl, { search: value });
          break;
        default:
          setSupplierButtonEl(null);
          setManufacturerButtonEl(null);
          setCategoryButtonEl(null);
      }
    } else {
      setSupplierButtonEl(null);
      setManufacturerButtonEl(null);
      setCategoryButtonEl(null);
    }

    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const setSupplier = (supplierName) => {
    setState({ ...state, supplierName });
    setSupplierButtonEl(null);
  };

  const setManufacturer = (manufacturer) => {
    setState({ ...state, manufacturer });
    setManufacturerButtonEl(null);
  };

  const addSupplier = () => {
    setSupplierButtonEl(null);
    setOpenSupplierDialog(true);
  };

  const addManufacturer = () => {
    setManufacturerButtonEl(null);
    setOpenManufacturerDialog(true);
  };

  // const businessOwnedProduct = !state.businessId || (parseInt(businessId) === parseInt(state.businessId));

  const topFields = [
    {
      name: 'brandName', label: 'Product Name', required: true, placeholder: 'Enter Product Name',
      error: brandNameError, helperText: 'Product name required', secured: false, show: true
    },
    {
      name: 'molecules', label: 'Molecule', required: true, placeholder: 'Enter Molecule Name',
      error: moleculesError, helperText: moleculeHelperText, secured: false, show: true,
    },
  ];

  const bottomFields = [
    {
      name: 'packSize', label: 'Pack Size', secured: false, show: true, placeholder: 'Enter Pack Size',
      error: packSizeError, helperText: 'Pack size required', required: true
    },
    {
      name: 'supplierName', label: 'Supplier', required: true, placeholder: 'Enter Supplier', isLoading: supLoading,
      error: supplierNameError, helperText: 'supplier name required', options: suppliersNames, secured: false, show: true
    },
    {
      name: 'manufacturer', label: 'Manufacturer', required: true, placeholder: 'Enter Manufacturer', isLoading: manLoading,
      error: manufacturerError, helperText: manuHelperText, secured: false, show: true
    },
    {
      name: 'barcode', label: 'UPC Barcode', secured: false, show: true, placeholder: 'Enter UPC Barcode',
    },
    {
      name: 'supplierCost', label: 'Supplier Cost', secured: false, show: isMP, placeholder: 'Enter Supplier Cost', type: 'number'
    },
    {
      name: 'fixedItemPrice', label: 'Fixed Item Price', secured: false, show: isMP, placeholder: 'Enter Fixed Item Price', type: 'number'
    },
    {
      name: 'fixedSellingPrice', label: "Competitor's Selling Price", secured: false, show: isErp, placeholder: "Enter Competitor's Selling Price", type: 'text',
      required: true, helperText: "Competitor's Selling Price required", error: fixedSellingPriceError
    },
    {
      name: 'orderCost', label: 'Order Cost', secured: true, show: true, placeholder: 'Enter Order Cost', type: 'number',
      error: orderCostError, helperText: 'Order cost required', required: true

    },
    {
      name: 'marketRrp', label: 'Market RRP', secured: false, show: isMP, placeholder: 'Enter Market RRP',
    },
    {
      name: 'minimumOrderQuantity', label: 'Minimum Order Quantity', secured: false, show: isErp, placeholder: 'Enter Minimum Order Quantity', type: 'number'
    },
    {
      name: 'maxLeadTime', label: 'Lead Time', secured: false, show: isErp, placeholder: 'Enter Lead Time', type: 'text'
    },
    {
      name: 'safetyStock', label: 'Safety Stock', secured: false, show: isErp, placeholder: 'Enter Safety Stock', type: 'number'
    },
    {
      name: 'reorderUnit', label: 'Reorder Unit', options: ['weeks', 'fixed'], secured: false, show: isErp,
      onChange: handleChangeReorderUnit, placeholder: 'Select', type: 'number'
    },
    {
      name: 'reorderPoint', label: 'Reorder Point', secured: true, show: isErp, type: 'number', placeholder: 'Enter Reorder Point'
    },
    {
      name: 'reorderMin', label: 'Reorder Min', secured: true, show: isErp, type: 'number', placeholder: 'Enter Reorder Min'
    },
    {
      name: 'reorderMax', label: 'Reorder Max', secured: true, show: isErp, type: 'number', placeholder: 'Enter Reorder Max'
    },
    {
      name: 'reorderQuantity', label: 'Reorder Quantity', secured: true, show: isErp, type: 'number', placeholder: 'Enter Reorder Quantity'
    },
    {
      name: 'categoryName', label: 'Price Category', secured: false, show: true, required: true, isLoading: catLoading,
      placeholder: 'Search Price Category', error: priceCategoryError, helperText: 'Price category required'

    },
    {
      name: 'erpPriceInUse', label: 'Price in Use', options: ['Fixed', 'Automatic'], secured: false, show: isErp,
      onChange: handleChangeuom, placeholder: 'Select',
    },
    {
      name: 'productVariant', label: 'Product Variant', options: ['On Request', 'Readily Available'],
      secured: false, show: isErp, onChange: handleChangeProductVariant, placeholder: 'Select'
    },
    {
      name: 'productClass', label: 'Product Class', options: ['drug', 'non_drug'], secured: false, show: isErp,
      onChange: handleChangeuom, placeholder: 'Select',
      error: productClassError, helperText: 'Product class is required', required: true
    },
    {
      name: 'therapeuticType', label: 'Therapeutic Type', options: allTherapeuticTypeList?.map(({ therapeuticType }) => therapeuticType),
      secured: false, show: true, onChange: handleChangeTherapeuticType, placeholder: 'Select',
      val: state.therapeuticTypeName,
      error: therapeuticTypeError, helperText: 'Therapeutic type is required', required: true
    },
    {
      name: 'unitOfMeasurement', label: 'Unit Of Measurement', options: ['Unit', 'Pack', 'Sachet'], secured: false, show: true,
      onChange: handleChangeuom, placeholder: 'Select',
      error: unitOfMeasurementError, helperText: 'Unit of measurement is required', required: true
    },
    {
      name: 'buffer', label: 'Buffer', secured: false, show: true, placeholder: 'Enter Value', type: 'number'
    },
    {
      name: 'marketPlacePriceInUse', label: 'Price in Use', options: ['Fixed', 'Automatic'], secured: false, show: isMP,
      onChange: handleChangeuom, placeholder: 'Select'
    },
  ];

  const returnInputProps = (name) => {
    switch (name) {
      case 'itemPrice':
      case 'rpp':
        return {
          startAdornment: (
            <InputAdornment position="start">
              <CurrencyTypo>₦</CurrencyTypo>
            </InputAdornment>
          )
        };
      default:
        return null;
    }
  };

  const returnValue = (name, value) => {
    switch (name) {
      case 'itemPrice':
      case 'storeSellingPrice':
      case 'rrp':
      case 'qpc':
        return value && Number(value);
      default:
        return value;
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, isLoading, secured, placeholder, onChange, val, type, readOnly
    } = field;
    const value = state[fieldName];
    if ([
      'reorderUnit', 'productVariant', 'therapeuticType', 'unitOfMeasurement', 'erpPriceInUse', 'productClass'
    ].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={returnValue(fieldName, value)}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={isLoading}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        InputProps={returnInputProps(fieldName)}
        cSize="lg"
        readOnly={readOnly}
      />
    );
  };

  return (
    <>
      <CDialog
        data-testid="product-dialog"
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <GridWrapper container>
          <Grid container>
            <Grid item container xs={6}>
              <DialogTitleText>
                {editing ? 'Edit Individual Product' : 'Create Individual Product'}
              </DialogTitleText>
              <DialogTitleSubText>
                {editing
                  ? 'Edit the form to update product information'
                  : 'Add Product information to the fields in the form'}
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <DialogContent>
            <UploadGrid container item>
              {showInitialImage ? (
                <InitialImage
                  image={state.image}
                  handleImageChange={handleChangeInitialImage}
                  handleImageDelete={handleDeleteInitialImage}
                />
              ) : (files && files.length ? (
                <CropImageContainer
                  files={files}
                  handleFile={handleFile}
                  handleFinalImage={setFinalImage}
                />
              ) : (
                <DropZone
                  handleFile={handleFile}
                />
              ))}
            </UploadGrid>
            <Grid container spacing={2}>
              {topFields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
              <Grid item xs={12}>
                <CustomInputBase
                  multiline
                  minRows={2}
                  label="Description"
                  placeholder="Describe Product"
                  name="description"
                  required
                  value={state.description}
                  helperText="Description required"
                  onChange={handleChange}
                  error={descriptionError || false}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: '8px' }}>
              {bottomFields.filter((item) => item.show).map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <CustomButton
              type="tertiary"
              header
              style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
              onClick={handleCloseDialog}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="tertiary"
              header
              onClick={handleSaveButtonClick}
              disabled={loading}
              style={{ width: '11rem', height: '2.7rem' }}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : editing ? 'Save Changes' : 'Save & ....'}
            </CustomButton>
          </DialogActions>
        </GridWrapper>
      </CDialog>

      <IndividualSupplierDialog
        dialogOpen={openSupplierDialog}
        closeDialog={() => setOpenSupplierDialog(false)}
        refetchSuppliers={refetchSuppliers}
        fromProduct
      />

      <IndividualManufacturerDialog
        dialogOpen={openManufacturerDialog}
        closeDialog={() => setOpenManufacturerDialog(false)}
        fromProduct
      />

      <SearchPopper
        buttonEl={supplierButtonEl}
        handleClose={setSupplierButtonEl}
        searchResult={searchSupplier}
        handleChange={setSupplier}
        handleAdd={() => addSupplier()}
        loading={supLoading}
        modelType="Supplier"
      />

      <SearchPopper
        buttonEl={manufacturerButtonEl}
        handleClose={setManufacturerButtonEl}
        searchResult={searchManufacturers}
        handleChange={setManufacturer}
        handleAdd={() => addManufacturer()}
        loading={manLoading}
        modelType="Manufacturer"
      />

      <SearchPopper
        buttonEl={categoryButtonEl}
        handleClose={setCategoryButtonEl}
        searchResult={searchCategory}
        handleChange={handleChangeCategory}
        loading={catLoading}
        modelType="Price Category"
        categories
      />

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualProductDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  businessState: PropTypes.instanceOf(Object),
  updateOrder: PropTypes.bool,
};

IndividualProductDialog.defaultProps = {
  businessState: {},
  updateOrder: false
};

export default IndividualProductDialog;
