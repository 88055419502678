import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { PaystackButton } from 'react-paystack';
import { styled } from '@mui/material/styles';

export const VirtualWrapper = styled('div')`
    padding: 10px;
    display: flex;
    justify-content: space-between;
`;

export const PayIcon = styled('img')`
    width: 30px;
`;

export const HeaderText = styled('h1')`

`;

export const PayButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const PayButton = styled(({ ...otherProps }) => (
  (
    <IconButton style={{ borderRadius: '15px', padding: 0 }}>
      <PaystackButton {...otherProps} />
    </IconButton>
  )
))`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 35px;
    background-color: #FCED50;
    color: #424242;
    border: none;
    border-radius: 0;
    border-radius: 15px;

    &:hover {
      background-color: #D2C647;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      color: #848483;
      background-color: #CCCCCB;
    }

    @media(min-width: 992px) {
      height: 50px;
      border-radius: 10px;
    }
  `;
