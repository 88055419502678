import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { RenderPopper } from '../../../utils/utils';
import {
  ProductItem, SearchPaper, ImgDiv, ProdName, PackSizeGrid
} from './searchPopper.styles';

const SearchPopper = ({
  open, anchorEl, handleClose, isLoading, searchedProducts,
  handleProdSelection
}) => (
  <RenderPopper
    open={open}
    anchorEl={anchorEl}
    onClickAway={handleClose}
    transition
    disablePortal
    popperPlacement="bottom"
    modifiers={{
      flip: {
        enabled: false,
      }
    }}
  >
    <SearchPaper elevation={0} square>
      {isLoading
        ? <div>Searching...</div>
        : (
          <>
            {searchedProducts.map((prod) => (
              <ProductItem container onClick={() => handleProdSelection(prod)}>
                <Grid item container md={2} style={{ height: '3.5rem' }}>
                  <ImgDiv prodImg={prod.image} />
                </Grid>
                <Grid item md={7}>
                  <ProdName variant="caption">{prod.brandName}</ProdName>
                  <PackSizeGrid item container>
                    <span style={{ marginRight: 15 }}>
                      {`Pack Size: ${prod.packSize}`}
                    </span>
                    {!!prod.quantityInStock && (
                    <span>{`Qty in Stock: ${prod.quantityInStock}`}</span>
                    )}
                  </PackSizeGrid>
                </Grid>
                <Grid style={{ textAlign: 'right', fontSize: '20px' }} item md={3}>
                  {`₦ ${prod.itemPrice}`}
                </Grid>
              </ProductItem>
            ))}
          </>
        )}
    </SearchPaper>
  </RenderPopper>
);

SearchPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchedProducts: PropTypes.instanceOf(Array),
  handleProdSelection: PropTypes.func.isRequired
};

SearchPopper.defaultProps = {
  searchedProducts: [],
};

export default SearchPopper;
