import { styled } from '@mui/material/styles';
import {
  Typography, Popper, Paper
} from '@mui/material';
import { ServiceFeeTool } from '../../../assets/svgs';

export const PopperContainer = styled(Popper)`
  z-index: 15000000;
  min-height: 0rem;
  width: 25rem;
  margin-left: 8.6rem !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem !important;

  @media (max-width: 991px) {
    margin-left: 6.6rem !important;
    margin-bottom: 2.5rem !important;
    width: 46rem;
  }
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: 15px;
  padding: 1rem 1.5rem;
  height: 6rem;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 3rem;
    top: 5rem;
    width: 25px;
    height: 25px;
    background-color: #fff;
    box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
    -webkit-box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
    -moz-box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
    transform: rotate(136deg);
    -webkit-transform: rotate(136deg);
    -moz-transform: rotate(136deg);
  }

  @media (max-width: 991px) {
    height: 9rem;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 5rem;
      top: 8rem;
      width: 35px;
      height: 35px;
      background-color: #fff;
      box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
      -webkit-box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
      -moz-box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
      transform: rotate(136deg);
      -webkit-transform: rotate(136deg);
      -moz-transform: rotate(136deg);
    }
  }
`;

export const ServiceText = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  text-align: justify;

  @media (max-width: 991px) {
    font-size: 24px;
  }
`;

export const InfoLogo = styled(ServiceFeeTool)`
  margin: auto;
  &.MuiSvgIcon-root {
    font-size: 4rem;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      font-size: 7rem;
      margin-left: 1rem;
    }
  }
`;
