import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import {
  ArrowImg,
  BackButton,
  TitleTextGridContainer,
  PrimaryTitle,
  TitleGridContainer,
  Wrapper,
  UploadContainer,
  Title,
  CTextField,
  TextFieldGrid,
  FieldHeader,
  EditButton,
  ButtonText,
  ButtonContainer,
  EmptyState,
  EmptyHeader,
  EmptyText,
  EmptyStateContainer,
  UrlText,
} from './bannerContainer.styles';
import MainContent from '../../customComponents/mainContent';
import backImg from '../../../assets/images/marketOrder/Arrow.png';
import InitialImage from './upload/initialImage';
import { CREATE_BANNER_MUTATION, UPDATE_CATEGORY_BANNER_MUTATION } from '../../../mutations/banners';
import { EmptyBanners } from '../../../assets/svgs';
import { BANNERS_PAGE_CATEGORIES } from '../../../queries/categories';
import SuccessDialog from './successDialog';
import { JSONParse } from '../../../utils/json';
import DeleteDialog from './deleteDialog';

export default function Banner({ param, bannerPageDetail, refetch }) {
  const navigate = useNavigate();
  const initialState = {
    homePageImage1: {
      publicId: '', carouselImage: '', size: '', name: 'homePageImage1', carouselUrl: ''
    },
    homePageImage2: {
      publicId: '', carouselImage: '', size: '', name: 'homePageImage2', carouselUrl: ''
    },
    homePageImage3: {
      publicId: '', carouselImage: '', size: '', name: 'homePageImage3', carouselUrl: ''
    },
    othersImage1: {
      publicId: '', carouselImage: '', size: '', name: 'othersImage1', carouselUrl: ''
    },
    othersImage2: {
      publicId: '', carouselImage: '', size: '', name: 'othersImage2', carouselUrl: ''
    },
    cartPage: {
      carouselImage: '', size: '', name: 'cartPage', carouselUrl: '', categoryId: ''
    },
    middleSection: {
      carouselImage: '', size: '', name: 'middleSection', carouselUrl: '', categoryId: ''
    },
  };
  const [state, setState] = useState(initialState);
  const [editing, setEditing] = useState(false);
  const [files, setFiles] = useState(null);
  const [, setFinalImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState({});
  const [, setCategories] = useState([]);
  const [createBanner] = useMutation(CREATE_BANNER_MUTATION);
  const [createBannerCategory] = useMutation(UPDATE_CATEGORY_BANNER_MUTATION);
  const [successModal, setSuccessModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [errorState, setErrorState] = useState({});

  const [open,] = useState(false);

  const {
    id, page: bannerType, actionType, isImage
  } = param;
  const isCategory = bannerType === 'CATEGORIES';

  useEffect(() => {
    if (actionType) {
      setEditing(true);
    }
  }, [actionType]);

  useEffect(() => {
    if (bannerPageDetail?.length) {
      bannerPageDetail[0]?.meta?.forEach((item) => {
        const parsedItem = JSONParse(item);
        const {
          name, banner: carouselImage, url: carouselUrl, category_id: categoryId, size
        } = parsedItem;

        if (state[name]) {
          setState((prevState) => ({
            ...prevState,
            [name]: {
              ...prevState[name], carouselImage, carouselUrl, categoryId, size
            }
          }));
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerPageDetail]);

  const {
    data: categoriesList
  } = useQuery(BANNERS_PAGE_CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (actionType) {
      setCategories(categoriesList);
    }
  }, [actionType, categoriesList]);

  const handleCancle = () => {
    setFiles([]);
  };
  const homePageHeader = [
    { name: 'homePageImage1', label: 'Carousel Image 1' },
    { name: 'homePageImage2', label: 'Carousel Image 2' },
    { name: 'homePageImage3', label: 'Carousel Image 3' },
  ];

  const othersHeader = [
    { name: 'othersImage1', label: 'Image 1' },
    { name: 'othersImage2', label: 'Image 2' },
  ];

  const cartHeader = [
    { name: 'cartPage', label: 'Cart Page Banner' }
  ];

  const middleSection = [
    { name: 'middleSection', label: 'Homepage (Middle Section) Banner' }
  ];

  let headers;

  if (bannerType === 'Homepage (Hero Section)' && isImage) {
    headers = othersHeader;
  } else if (bannerType === 'Homepage (Hero Section)') {
    headers = homePageHeader;
  } else if (bannerType === 'Cart Page') {
    headers = cartHeader;
  } else {
    headers = middleSection;
  }

  const handleFile = (filesData, name) => {
    setFiles(filesData);
    setOpenImageDialog((prev) => ({ ...prev, [name]: true }));
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleCreateBanners = (name) => {
    const filteredBanners = Object.entries(state)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => value.carouselImage !== '' && name !== value.name)
      // eslint-disable-next-line no-unused-vars
      .map(([key, value]) => ({
        banner: value.carouselImage, size: value.size, name: value.name,
        url: value.carouselUrl, isCarousel: bannerType === 'Homepage (Hero Section)' && !isImage
      }));
    const banners = filteredBanners;
    createBanner({
      variables: {
        banners,
        bannerPageId: id
      }
    })
      .then(({ data }) => {
        const { message } = data?.createBanner || {};
        toast.success(message);
        if (name) {
          toggleDeleteModal();
        } else {
          toggleSuccessModal();
          setEditing(false);
        }
        setFiles(null);
        refetch();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isValidUrl = (url, name) => {
    if (url === '') return setErrorState((prev) => ({ ...prev, [name]: false }));
    const regex = /^(https?:\/\/)?(www\.)?(marketplace|warehouse|puremp)\.(uat\.|staging\.|canary\.)?ogapharmacy\.com\/.+$/;
    const isValid = regex.test(url);

    setErrorState((prev) => ({ ...prev, [name]: !isValid }));
    return isValid;
  };

  const handleCreateCategoryBanners = (name) => {
    if (Object.values(errorState).some((value) => value === true)) return toast.error('Invalid Url');
    const filteredBanners = Object.entries(state)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, value]) => value.carouselImage !== '' && name !== value.name)
      .map(([key, value]) => {
        const header = headers?.find((item) => item.name === key);
        return {
          carouselImage: value.carouselImage,
          carouselUrl: value.carouselUrl,
          publicId: value.publicId,
          size: value.size,
          name: value.name,
          categoryId: header?.categoryId || '',
        };
      });

    const banners = filteredBanners;

    createBannerCategory({
      variables: {
        banners,
      },
    })
      .then(({ data }) => {
        const { message } = data?.createCategoryBanner || {};
        toast.success(message);
        if (name) {
          toggleDeleteModal();
        } else {
          toggleSuccessModal();
          setEditing(false);
        }
        refetch();
        setFiles(null);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlesaveBanners = () => {
    if (Object.values(errorState).some((value) => value === true)) {
      toast.error('Please fix the invalid URLs before saving.');
      return;
    }

    if (Object.values(state).some((item) => item.carouselUrl && !item.carouselImage)) {
      toast.error('Some banners have URLs but no corresponding images.');
      return;
    }

    setLoading(true);
    if (isCategory) return handleCreateCategoryBanners();
    return handleCreateBanners();
  };

  const handleDeleteBanners = (name) => {
    setLoading(true);
    if (isCategory) return handleCreateCategoryBanners(name);
    return handleCreateBanners(name);
  };
  const handleCloseImageDialog = (name) => {
    setOpenImageDialog((prev) => ({ ...prev, [name]: false }));
  };

  // eslint-disable-next-line no-unused-vars
  const handleImageUpload = (file, name) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        setLoading(false);
        const publicId = response.public_id;
        return resolve({ carouselImage: response.secure_url, publicId });
      });
    });
  };

  const handleImageDeletion = (name) => {
    let deleteItem = {};
    if (isCategory) {
      deleteItem = {
        name, size: '', carouselImage: '', publicId: '', carouselUrl: '', categoryId: ''
      };
    } else {
      deleteItem = {
        name, size: '', carouselImage: '', publicId: '', carouselUrl: ''
      };
    }
    setState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name], ...deleteItem,
      }
    }));
    handleDeleteBanners(name);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async (fileData, name) => {
    setLoading(true);
    try {
      const image = await handleImageUpload(fileData, name);
      setState((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], ...image, size: files[0].file.size }
      }));
      toast.success('Image saved successfully');
      handleCloseImageDialog(name);
    } catch (error) {
      // console.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    isValidUrl(value, name);
    setState((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], carouselUrl: value }
    }));
  };

  const handleImgDelete = (name) => {
    handleImageDeletion(name);
    toast.error('Image deleted successfully');
  };

  const textField = (name) => {
    const value = state[name]?.carouselUrl;
    return (
      <TextFieldGrid>
        <CTextField
          variant="outlined"
          size="large"
          value={value}
          name={name}
          fullWidth
          required
          type="text"
          onChange={handleChange}
          helperText={errorState[name] && 'Input valid URL e.g. example@ogapharmacy.com/path'}
          error={errorState[name]}
          placeholder="Enter Carousel URL"
          InputProps={{
            style: { height: '4rem', fontSize: '1rem', borderRadius: '.6rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '.9rem', textAlign: 'center' },
          }}
        />
      </TextFieldGrid>
    );
  };

  return (
    <MainContent>
      <div>
        <BackButton
          variant="outlined"
          startIcon={<ArrowImg src={backImg} alt="back" />}
          onClick={() => navigate(-1)}
          container
          xs={12}
        >
          back
        </BackButton>
        <TitleGridContainer container item xs={12} justifyContent="space-between">
          <TitleTextGridContainer item md={6}>
            <PrimaryTitle variant="h5">
              { `${bannerType} Banners` }
            </PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGridContainer>
      </div>
      <Wrapper isCategory={isCategory}>
        {!bannerPageDetail[0]?.meta?.length && !editing ? (
          <EmptyState container>
            <EmptyStateContainer>
              <EmptyBanners style={{ fontSize: '15rem', fill: 'none' }} />
              <EmptyHeader>Nothing to Display Yet!</EmptyHeader>
              <EmptyText>Hi there, all uploaded banners will be displayed here</EmptyText>
              <ButtonContainer container xs={12} isCategory="cat">
                <EditButton
                  onClick={handleEdit}
                  className="mpAdmin-uat-banner-upload-image"
                >
                  <ButtonText>
                    Upload Carousel Image
                  </ButtonText>
                </EditButton>
              </ButtonContainer>
            </EmptyStateContainer>
          </EmptyState>
        ) : (
          <>
            {headers?.map(({ label, name }) => (
              <UploadContainer isCategory={isCategory} key={name}>
                <Title>{label}</Title>
                <InitialImage
                  handleFile={handleFile}
                  handleCancle={handleCancle}
                  openImageDialog={openImageDialog}
                  handleCloseImageDialog={handleCloseImageDialog}
                  handleSave={handleSave}
                  handleFinalImage={setFinalImage}
                  isLoading={loading}
                  state={state[name]}
                  name={name}
                  files={files}
                  editing={editing}
                  handleImgDelete={handleImgDelete}
                  open={open}
                  isCategory={isCategory}
                />
                {!editing && (
                  <UrlText>
                    <span style={{ color: '#60606099' }}>
                      {(bannerType === 'Homepage (Hero Section)' && !isImage) ? 'Carousel URL: ' : 'Image URL: '}
                    </span>
                    {state[name]?.carouselUrl}
                  </UrlText>
                )}
                {editing && (
                  <Grid>
                    <FieldHeader>
                      {(bannerType === 'Homepage (Hero Section)' && !isImage) ? 'Carousel URL' : 'Image URL'}
                    </FieldHeader>
                    {textField(name)}
                  </Grid>
                )}
              </UploadContainer>
            ))}
            <ButtonContainer isCategory={isCategory} container xs={12}>
              {editing ? (
                <EditButton
                  onClick={handlesaveBanners}
                  className="mpAdmin-uat-banner-save"
                >
                  <ButtonText>
                    Save
                  </ButtonText>
                </EditButton>
              ) : (
                <EditButton
                  onClick={handleEdit}
                  className="mpAdmin-uat-banner-edit"
                >
                  <ButtonText>
                    Edit
                  </ButtonText>
                </EditButton>
              )}
            </ButtonContainer>
          </>
        )}
      </Wrapper>
      <SuccessDialog
        openConfirmation={successModal}
        handleClose={toggleSuccessModal}
        title="Carousel Images Saved"
        discreption="Hi User,  your carousel images have been successfully saved."
      />
      <DeleteDialog
        openConfirmation={deleteModal}
        handleClose={toggleDeleteModal}
      />
    </MainContent>
  );
}

Banner.propTypes = {
  param: PropTypes.instanceOf(Object).isRequired,
  bannerPageDetail: PropTypes.instanceOf(Array).isRequired,
  refetch: PropTypes.func.isRequired,
};
