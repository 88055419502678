import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import DateSelectorButtons from './dateSelectorButtons';
import DateRangeSelector from './dateRangeSelector';

export const DashboardDateRanges = ({
  active,
  range,
  handleDateButtons,
  handleIconDateRange,
  xs
}) => (
  <Grid item container xs={xs} alignItems="center">
    <Grid item xs={6}>
      <DateSelectorButtons
        active={active}
        handleDateButtons={handleDateButtons}
      />
    </Grid>
    <Grid item container xs={6} justifyContent="flex-end">
      <DateRangeSelector
        range={range}
        handleIconDateRange={handleIconDateRange}
      />
    </Grid>
  </Grid>
);

DashboardDateRanges.propTypes = {
  xs: PropTypes.number,
  active: PropTypes.string,
  range: PropTypes.string,
  handleDateButtons: PropTypes.func.isRequired,
  handleIconDateRange: PropTypes.func.isRequired,
};

DashboardDateRanges.defaultProps = {
  active: '',
  range: '',
  xs: 5
};

export default DashboardDateRanges;
