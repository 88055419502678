import ReturnActionTypes from './returnsTypes';
import {
  toggleCartItem, toggleSelectAll,
  changeQty, saveSoldItems, changeReason, toggleResellable
} from './returnsUtils';

const returnsReducer = (returns, action) => {
  switch (action.type) {
    case ReturnActionTypes.TOGGLE_RETURN_ITEM:
      return {
        ...returns,
        ...toggleCartItem(returns, action)
      };
    case ReturnActionTypes.SAVE_SOLD_ITEMS:
      return {
        ...returns,
        ...saveSoldItems(returns, action)
      };
    case ReturnActionTypes.TOGGLE_SELECT_ALL:
      return {
        ...returns,
        ...toggleSelectAll(returns, action)
      };
    case ReturnActionTypes.CHANGE_RETURN_ITEM_QUANTITY:
      return {
        ...returns,
        ...changeQty(returns, action)
      };
    case ReturnActionTypes.CLEAR_RETURN_CART:
      return {
        ...returns,
        returnCartItems: new Map(),
        selectAll: false
      };
    case ReturnActionTypes.CHANGE_RETURN_REASON:
      return {
        ...returns,
        ...changeReason(returns, action)
      };
    case ReturnActionTypes.TOGGLE_ITEM_RESELLABLE:
      return {
        ...returns,
        ...toggleResellable(returns, action)
      };
    case ReturnActionTypes.UPDATE_CART:

      return {
        ...returns,
        ...action.payload
      };
    default:
      return returns;
  }
};

export default returnsReducer;
