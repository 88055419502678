import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  DialogActions, DialogContent, DialogTitle,
  Grid,
  InputLabel,
  Slide,
  useMediaQuery
} from '@mui/material';
import { SAVE_DELIVERY_LOCATION } from '../../../mutations/outlets';
import {
  CDialog,
  CircularProgressLoader,
  DialogActionButtonText,
  DialogCancelButton, DialogOkButton,
  DialogTitleSubText,
  DialogTitleText,
  DialogTitleWrapper,
  FormWrapper,
  GridWrapper,
  SupplierTextField,
  TopGrid
} from './individualOutletDialog.styles';
import ReturnSelectField from './returnSelectField';
import SavePopper from './savePopper';
import { reformatAddress } from '../../../utils/json';
import { ORDER_QUERY, MP_CART_ORDER_QUERY } from '../../../queries/cart';
import { validateEmailAddress } from '../../../utils/funcs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualOutletDialog = ({
  dialogOpen, closeDialog, existingOutlet, cart
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const initialState = {
    deliveryLocationId: null,
    outletName: '',
    mobile: '',
    primaryContactName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    region: '',
    city: '',
    country: 'Nigeria',
    state: 'Lagos'
  };
  const [state, setState] = useState(initialState);
  useEffect(() => {
    if (existingOutlet) {
      const { name } = existingOutlet;
      const contacts = reformatAddress(existingOutlet.contacts);
      const {
        region, city, country,
        address_line_1: addressLine1,
        primary_email_address: email, mobile_number: mobile,
        primary_contact_name: primaryContactName,
      } = contacts;
      const deliveryLocationState = {
        deliveryLocationId: existingOutlet.id,
        outletName: name,
        mobile,
        primaryContactName,
        email,
        addressLine1,
        region,
        city,
        country,
      };
      setState({ ...state, ...deliveryLocationState });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingOutlet]);

  const initialErrorState = {
    outletNameError: false,
    primaryContactNameError: false,
    emailError: false,
    mobileError: false,
    addressLine1Error: false,
    stateError: false,
    cityError: false,
  };

  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);

  const [createDeliveryLocation] = useMutation(SAVE_DELIVERY_LOCATION);

  const handleCloseDialog = () => {
    setState(initialState);
    closeDialog();
  };

  const {
    outletNameError,
    primaryContactNameError,
    emailError,
    mobileError,
    addressLine1Error,
    stateError,
    cityError
  } = errorState;

  const addAffiliate = (logo, addMore) => {
    const {
      outletName, mobile, email,
      addressLine1, primaryContactName, region, city, country, deliveryLocationId
    } = state;
    const ogaOrderingForAffiliate = localStorage.getItem('oga_ordering_for_affiliate');
    const business = ogaOrderingForAffiliate ? JSON.parse(ogaOrderingForAffiliate).id : localStorage.getItem('ogarx_business_id');
    createDeliveryLocation({
      variables: {
        business: +business,
        deliveryLocationId: +deliveryLocationId,
        name: outletName,
        primaryContactName,
        mobileNumber: mobile,
        primaryEmailAddress: email,
        addressLine1,
        region,
        city,
        country,
      },
      refetchQueries: [ORDER_QUERY, MP_CART_ORDER_QUERY]
    })
      .then(({ data }) => {
        const { message } = data?.saveDeliveryLocation ?? {};
        toast.success(message);
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setSaveButtonEl(null);
    setLoading(true);
    return addAffiliate();
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const handleSaveButtonClick = (event) => {
    const {
      outletName, primaryContactName, email, mobile, addressLine1, state: newState,
      city
    } = state;

    if (outletName === '') {
      toast.error('Branch name is required');
    } else if (primaryContactName === '') {
      toast.error('Primary Contact name is required');
    } else if (email === '') {
      toast.error('Email address is required');
    } else if (!validateEmailAddress(email)) {
      toast.error('Invalid email address');
    } else if (mobile === '') {
      toast.error('Phone number is required');
    } else if (addressLine1 === '') {
      toast.error('Address line 1 is required');
    } else if (newState === '') {
      toast.error('State is is required');
    } else if (city === '') {
      toast.error('City is required');
    } else {
      setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
      handleSave();
    }
    return setErrorState({
      ...errorState,
      outletNameError: outletName === '',
      primaryContactNameError: primaryContactName === '',
      emailError: email === '' || !validateEmailAddress(email),
      mobileError: mobile === '',
      addressLine1Error: addressLine1 === '',
      stateError: newState === '',
      cityError: city === '',
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'outletName':
      case 'primaryContactName':
      case 'email':
      case 'mobile':
      case 'addressLine1':
      case 'country':
      case 'city':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const nigeriaState = [
    'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo',
    'Ekiti', 'Enugu', 'FCT - Abuja', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos',
    'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara'
  ];

  const topFields = [
    {
      name: 'outletName', label: 'Branch Name', helperText: 'Branch Name required', placeholder: "Enter Customer's Name", error: outletNameError
    },
    {
      name: 'primaryContactName', label: 'Primary Contact Name', placeholder: "Enter Customer's Name", helperText: 'Primary Contact required', error: primaryContactNameError
    },
    {
      name: 'email', label: 'Email Address', placeholder: 'Enter Email Address', helperText: 'Email required', error: emailError
    },
    {
      name: 'mobile', label: 'Phone Number', placeholder: 'Enter Phone Number', helperText: 'Mobile required', error: mobileError
    },
    {
      name: 'addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line 1', helperText: 'Address required', error: addressLine1Error
    },
    {
      name: 'state', label: 'State', placeholder: 'Select State', options: nigeriaState, helperText: 'State required', error: stateError
    },
    {
      name: 'country', label: 'Country', options: ['Nigeria'], placeholder: 'Select Country'
    },
    {
      name: 'city', label: 'City', placeholder: 'Enter City', helperText: 'City required', error: cityError
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, placeholder, error
    } = field;
    const value = state[fieldName];
    if (['country', 'state', 'deliveryLocationType'].includes(fieldName)) {
      return (
        <FormWrapper item xs={12} lg={6}>
          <InputLabel
            htmlFor={label}
            style={{ fontSize: isSmall ? '1.8rem' : '.9rem', marginBottom: isSmall && '.7rem' }}
            sx={{ display: 'inline' }}
          >
            {label}
          </InputLabel>
          <ReturnSelectField
            field={field}
            value={value}
            handleChange={handleChange}
            cart={cart}
            showCheckBox={cart && false}
            error={error || false}
            helperText={error && helperText}
          />
        </FormWrapper>
      );
    }
    return (
      <FormWrapper item xs={12} lg={6}>
        <InputLabel
          htmlFor={label}
          style={{ fontSize: isSmall ? '1.8rem' : '.9rem', marginBottom: isSmall && '.7rem' }}
          sx={{ display: 'inline' }}
        >
          {label}
        </InputLabel>
        <SupplierTextField
          variant="filled"
          size="small"
          id={label}
          value={value}
          placeholder={placeholder}
          type={fieldName === 'mobile' ? 'number' : fieldName === 'dateLaunched' ? 'date' : 'text'}
          error={error}
          helperText={error && helperText}
          name={fieldName}
          onChange={handleChange}
        />
      </FormWrapper>
    );
  };

  return (
    <>
      <CDialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
      >
        <Grid container>
          <GridWrapper container item xs>
            <DialogTitle id="alert-dialog-slide-title">
              <Close
                fontSize="large"
                style={{
                  position: 'absolute', right: '1.5rem', cursor: 'pointer',
                  width: isSmall ? '50px' : '25px', height: isSmall && '50px'
                }}
                onClick={handleCloseDialog}
              />
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {existingOutlet ? 'Edit Delivery Location' : 'Add Delivery Location' }
                </DialogTitleText>
                <DialogTitleSubText>
                  {existingOutlet
                    ? 'Edit the form to update outlet information'
                    : 'Add your delivery information to the fields in the form'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent container sm={12}>
              <TopGrid container item>
                {topFields.map((field) => returnTextField(field))}
              </TopGrid>
              {/* <MiddleGrid item>
                {middleFields.map((field) => returnTextField(field))}
              </MiddleGrid>
              <BottomGrid item>
                {bottomFields.map((field) => returnTextField(field))}
              </BottomGrid> */}
            </DialogContent>

            <DialogActions style={{ display: isSmall ? 'block' : 'flex' }}>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    Submit
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </CDialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualOutletDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  existingOutlet: PropTypes.instanceOf(Object),
  cart: PropTypes.bool
};
IndividualOutletDialog.defaultProps = {
  existingOutlet: {},
  cart: false
};
export default IndividualOutletDialog;
