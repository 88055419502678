import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
  TableContainerWrapper, TableComponent, TableHeader,
  MainTableHead, TableGrid, MainTableBody, PrimaryTitle, TitleGridContainer, TitleTextGridContainer
} from './banners.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { BANNERS_PAGE_QUERY } from '../../../queries/banners';
import { addHyphen } from '../../shared/methods';

const headers = [
  'Pages', 'No of Banners', 'Actions',
];

const startDate = moment().startOf('day').format('YYYY-MM-DD');
const endDate = moment().endOf('day').format('YYYY-MM-DD');
const initialState = {
  searchText: '',
  dateFilter: {
    dateFrom: startDate,
    dateTo: endDate
  }
};

const Banners = () => {
  const [state,] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [pageCount,] = useState(50);
  const [pageNumber,] = useState(1);
  const { searchText } = state;

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} data-testid={`mp-uat-banners-${addHyphen(header)}`}>{header}</TableHeader>
  ));

  const variables = {
    pageCount,
    pageNumber,
    search: searchValue
  };

  const {
    loading, data
  } = useQuery(BANNERS_PAGE_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
  });

  // if (error) return <div>{error.message}</div>;
  const {
    bannerPages = [],
  } = data || {};

  const rows = bannerPages;
  return (
    <>
      <TitleGridContainer container item xs={12} justifyContent="space-between">
        <TitleTextGridContainer item md={5}>
          <PrimaryTitle variant="h5">Manage Banners</PrimaryTitle>
        </TitleTextGridContainer>
      </TitleGridContainer>
      <TableContainerWrapper elevation={0}>
        <TableGrid item container>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableComponent item container data-testid="mp-uat-banners-table">
                <MainTableHead item container>
                  {returnHeaders()}
                </MainTableHead>
                <MainTableBody item container>
                  {rows.map((_row) => (
                    <ReturnRow
                      row={_row}
                      key={_row}
                    />
                  ))}
                </MainTableBody>
              </TableComponent>
            )}
        </TableGrid>
      </TableContainerWrapper>
    </>
  );
};

export default Banners;
