import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, Button
} from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import {
  TableHeader,
  GridContainer,
  PaperWrapper,
  TableGrid,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MainTableHead,
  ButtonContainer,
  GoBack
} from './purchaseOrder.styles';
import ReturnRow from './returnRow';
import { BodyCell } from './returnRow.styles';
import { SEARCH_PO_QUERY } from '../../../queries/products';
import SearchPopper from './searchPopper';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { PLACE_PO_ORDER_PRODUCTS_MUTATION } from '../../../mutations/cart';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import { generateRandomNumber } from '../../../utils/funcs';
import { CustomCheckbox } from '../mangePurchaseOrder/returnRow.styles';

const poHeaders = [
  'S/N',
  'Product ID',
  'Product Name',
  'Supplier',
  'Pack Size',
  'Qty',
  'PO Unit Cost',
  'Price',
  'Action'
];

function PurchaseOrderContainer() {
  const returnHeaders = () => poHeaders.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  const navigate = useNavigate();
  const localBusinessId = localStorage.getItem('ogarx_business_id');

  const { data: businessData } = useQuery(GET_MY_BUSINESS, {
    variables: { id: localBusinessId }
  });

  const deliveryLocationId = businessData?.business?.deliveryLocations[0]?.id || 0;

  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [pId, setPId] = useState('');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [currentRowSearch, setCurrentRowSearch] = useState(0);

  const [reload, setReload] = useState(false);
  const reloadHandler = () => setReload(!reload);

  const [getSearchedProducts, { data: poData, loading }] = useLazyQuery(
    SEARCH_PO_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        productName: search,
        productId: pId,
        status: 'enabled'
      }
    }
  );

  const createData = (product) => {
    const prod = new Product(product, 'erp');

    return {
      productId: prod?.id,
      brandName: prod?.brandName,
      quantityInStock: prod?.quantityInStock,
      supplier: prod?.supplier,
      packSize: prod?.packSize,
      itemPrice: prod?.orderCost,
    };
  };

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  useEffect(() => {
    if (poData && poData.erpProducts) {
      const sResults = poData.erpProducts.map((product) => createData(product));
      setSearchedProducts(sResults);
      setSearchFieldEl(savedEl);
    }
  }, [poData]);

  const handleSearch = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set search field
    setPId('');
    setSearch(value);
    // Update the product name for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productName = value;
    setProducts([...products]);

    if (value && value.length > 2) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  const handleSearchByPId = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set product id search field
    setSearch('');
    setPId(value);
    // Update the product id for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productId = value;
    setProducts([...products]);

    if (value) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  const addMoreHandler = () => {
    const next = generateRandomNumber(10);
    const newData = {
      sn: next,
      productId: '',
      productName: '',
      supplier: '',
      packSize: '',
      quantity: 0,
      newUnitCost: '',
      price: 0
    };
    setProducts([...products, newData]);
  };

  const removeRowHandler = (sn) => {
    const newProducts = products.filter((p) => p.sn !== sn);
    setProducts(newProducts);
  };

  const handleProductSelection = (row) => {
    const {
      productId, brandName, supplier, itemPrice, packSize
    } = row;

    const newProduct = products.find(
      ({ productName, productId: pid }) => productName === brandName && pid !== ''
    );

    if (newProduct) return toast.error('Product already exists');

    const newData = products.find(({ sn }) => sn === currentRowSearch);
    newData.productId = productId;
    newData.productName = brandName;
    newData.supplier = supplier.name;
    newData.packSize = packSize;
    newData.quantity = 1;
    newData.newUnitCost = itemPrice;
    newData.price = itemPrice;
    setProducts([...products]);
    handlePopperClose();
  };

  useEffect(() => {
    setProducts([...products]);
  }, [reload]);

  const counterHandler = (type, row, val) => {
    const { sn, quantity, newUnitCost } = row;
    switch (type) {
      case 'increment': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) + 1;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;

        break;
      }
      case 'decrement': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) - 1;
        if (newQuantity < 0) return;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;

        break;
      }
      case 'change': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        if (val <= 0) return;
        newData.quantity = Number(val);
        newData.price = val * newUnitCost;
        break;
      }

      default:
        break;
    }
    reloadHandler();
  };

  const updateCostPriceHandler = (val, row) => {
    const { sn, quantity } = row;
    const newData = products.find(({ sn: sid }) => sn === sid);
    newData.newUnitCost = val;
    newData.price = quantity * val;

    setProducts([...products]);
    reloadHandler();
  };

  const [placePoOrder, { loading: poloading }] = useMutation(PLACE_PO_ORDER_PRODUCTS_MUTATION);

  // ================= Save Draft Handler ===================
  const saveDraftHandler = async () => {
    const filterItems = products.filter((prod) => prod.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((prod) => {
      const { productId, quantity, newUnitCost } = prod;
      return {
        productId,
        quantity,
        newUnitCost
      };
    });

    if (!cart.length) return toast.error('Purchase order is empty');

    const request = {
      cart,
      isManualOrder: true,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: true,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: [],
      idempotentKey: String(uuid())
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        toast.success('Purchase order successfully saved as draft');
        navigate('/manage-purchase-order');
      })
      .catch(() => {});
  };

  // ================= Save PO Handler ===================
  const createPOHandler = () => {
    // Remove row that does not have product id
    const filterItems = products.filter((prod) => prod.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((prod) => {
      const { productId, quantity, newUnitCost } = prod;
      return {
        productId: Number(productId),
        quantity,
        newUnitCost
      };
    });

    if (cart.length < 1) return toast.error('Purchase order is empty');

    const request = {
      cart,
      isManualOrder: true,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: false,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: [],
      idempotentKey: String(uuid())
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        toast.success('Purchase order successfully create');
        navigate('/manage-purchase-order');
      })
      .catch(() => {});
  };

  const deleteHandler = () => {
    if (selected.length < 1) return toast.error('Kindly select an item to delete');
    const result = products.filter((obj) => !selected.includes(obj.sn));
    setProducts(result);
    setSelected([]);
  };

  const handleSelect = (_, sn) => {
    if (_.target.checked) {
      const list = selected.filter((item) => item !== sn);
      setSelected([...list, sn]);
    } else {
      const list = selected.filter((item) => item !== sn);
      setSelected(list);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = products.map((product) => product.sn);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === products.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  let rowCounter = 0;
  return (
    <MainContent>
      <GridContainer container>
        <GoBack onClick={() => navigate('/inventory/orders')}>
          <KeyboardArrowLeft style={{ fontSize: '1.8rem', cursor: 'pointer' }} />
          back
        </GoBack>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Create Purchase Order</PrimaryTitle>
            <ButtonContainer>
              <Button
                variant="outlined"
                disabled={poloading}
                style={{ width: '11rem', height: '3rem', border: '2px solid' }}
                onClick={deleteHandler}
              >
                Delete Item(
                {selected.length}
                )
              </Button>
              <Button
                variant="outlined"
                disabled={poloading}
                style={{ width: '8rem', height: '3rem', border: '2px solid' }}
                onClick={saveDraftHandler}
              >
                Save Draft
              </Button>
              <Button
                onClick={createPOHandler}
                variant="contained"
                disabled={poloading}
                style={{ width: '8rem', height: '3rem' }}
              >
                Create PO
              </Button>
            </ButtonContainer>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <TableGrid item container>
            <TableContainer>
              <Table>
                <MainTableHead>
                  <TableHeader>
                    {renderCheckbox()}
                  </TableHeader>
                  {returnHeaders()}
                  <TableHeader />
                </MainTableHead>
                <TableBody>
                  {!products?.length ? (
                    <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                      Purchase order is empty
                    </BodyCell>
                  ) : (
                    products?.map((item) => {
                      const { sn } = item;
                      rowCounter += 1;
                      return (
                        <ReturnRow
                          key={sn}
                          row={item}
                          index={rowCounter}
                          loading={loading}
                          counterHandler={counterHandler}
                          handleSearch={handleSearch}
                          removeRowHandler={removeRowHandler}
                          handleSelect={handleSelect}
                          updateCostPriceHandler={updateCostPriceHandler}
                          handleSearchByPId={handleSearchByPId}
                          poLoading={poloading}
                          currentRowSearch={currentRowSearch}
                          selected={selected}
                        />
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TableGrid>
          <Button
            variant="outlined"
            onClick={addMoreHandler}
            style={{
              width: '11rem',
              height: '3rem',
              border: '2px solid',
              marginTop: '1rem'
            }}
          >
            Add New Product
          </Button>
        </PaperWrapper>
      </GridContainer>
      <SearchPopper
        searchFieldEl={searchFieldEl}
        handleClose={handlePopperClose}
        searchedProducts={searchedProducts}
        handleProductSelection={handleProductSelection}
      />
      {/* <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title={confirmText?.title}
        desc={confirmText?.desc}
        options={['Cancel', confirmText?.confirm]}
        setStatus={setConfirmStatus}
        loading={poloading}
      /> */}
    </MainContent>
  );
}

export default PurchaseOrderContainer;
