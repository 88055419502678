import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatisticPaper = styled(Paper)`
  box-shadow: 0px 3px 4px #42424240;
  border-radius: 7px;
  border: 1px solid #79797940;
  width: 100%;
`;

export const StatisticWrapper = styled(Grid)`
  align-items: center;
  padding: 18px 20px;
`;

export const StatisticGrid = styled(Grid)`
  flex-direction: column;
  margin-left: 18px;
`;

export const StatisticHeader = styled(Grid)`
  font-size: 20px;
  color: #424242;
  margin-bottom: 10px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const StatisticContent = styled(Grid)`
  color: #424242;
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const DiffGrid = styled(Grid)`
  color: ${({ diff }) => (diff < 0 ? '#FF4142' : '#3CC480')};
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const DiffImage = styled('img')`
  height: 5px;
  width: 5px;
  margin-right: 2px;

  @media(min-width: 992px) {
    height: 11px;
    width: 11px;
  }
`;

export const IconImage = styled('img')`
  height: 3rem;
`;
