import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Grid from '@mui/material/Grid';
import { RenderPopper } from '../../../../utils/utils';
import {
  GridWrapper, InfoText, ItemTypo, ItemTypoBox
} from './filterPopper.styles';

const filterPopper = ({
  filterButtonEl, handleFilterButtonClick, status, handleStatus
}) => {
  const open = Boolean(filterButtonEl);

  const columns = ['Open Orders', 'Dispatched Orders', 'Closed Orders'];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <RenderPopper
      open={open}
      anchorEl={filterButtonEl}
      onClickAway={handleFilterButtonClick}
      popperPlacement="bottom-end"
    >
      <GridWrapper container>
        <InfoText>Filter by Order Status</InfoText>
        {columns?.map((column) => (
          <ItemTypoBox item alignItems="center" onClick={(e) => handleStatus(e, column)}>
            {/* <Grid item xs={1}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ color: '#FCED50' }}
                checked={column === status}
              />
            </Grid> */}
            <ItemTypo item xs={12}>
              {column}
            </ItemTypo>
          </ItemTypoBox>
        ))}
      </GridWrapper>
    </RenderPopper>
  );
};

filterPopper.propTypes = {
  filterButtonEl: PropTypes.shape(Object),
  handleFilterButtonClick: PropTypes.func.isRequired,
};

filterPopper.defaultProps = {
  filterButtonEl: {}
};

export default filterPopper;
