import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from '../../../utils/currencyFormatter';
import CartRow from './cartRow';
import { Dots } from '../../../assets/svgs';
import HoldSaleDialog from './holdSaleDialog';
import SalesOnHoldDialog from './salesOnHoldDialog';
import SelectCustomerDialog from './selectCustomerDialog';
import CustomerDialog from './customerDialog';
import PaymentDialog from './paymentDialog';
import IndividualCustomerDialog from '../../customers/individualCustomerDialog';
import CartPopper from './cartPopper';
import NotificationDialog from '../../shared/notificationDialog';
import CustomButton from '../../customComponents/customButton';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { round } from '../../../utils/utils';
import {
  WrapperPaper, HeaderGrid, Header, OptionsWrapper, ItemsBody,
  ItemsGrid, TableComponent, MainTableHead, TableHeader, TotalsGrid,
  TextGrid, CustomInputBase, EmptyGrid, EmptyImg
} from './cart.styles';

import { useStateValue } from '../../../providers/stateProvider';
import EmptyCart from '../../../assets/images/empty_cart.gif';

const headers = ['Products', 'Qty', 'Price'];

const Cart = () => {
  const [calDiscount, setCalDiscount] = useState('');
  const [openWarning, setOpenWarning] = useState(false);
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [openIndividualCustomerModal, setOpenIndividualCustomerModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  const [{
    sale: {
      cart, saleTotal, percDiscount, discount, subTotal, salesOnHold, customerModalData
    },
    model: { addModelDialogOpen },
    user,
  }, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();
  const { allowedPermissionsMap: { userPermissions } } = user;

  useEffect(() => {
    if (percDiscount) setCalDiscount(percDiscount);
  }, []);

  useEffect(() => {
    const _discount = round(subTotal * (percDiscount / 100));
    dispatch({
      type: SaleActionTypes.UPDATE_SALE_STATE,
      payload: {
        discount: _discount,
        saleTotal: subTotal - _discount
      }
    });
  }, [percDiscount, subTotal]);

  const cartItems = [];
  cart.forEach((item) => {
    cartItems.push((item));
  });
  const makeSales = async () => {
    if (cartItems.length) setOpenPaymentDialog(true);
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const handleHoldSaleButton = () => {
    dispatch({
      type: SaleActionTypes.UPDATE_SALE_STATE,
      payload: { openHoldSale: true }
    });
  };

  const handleOpenCustomerModal = () => {
    setDotsButtonEl(null);
    setOpenCustomerModal(true);
  };

  const handleSalesOnHoldButton = () => {
    if (salesOnHold.length) {
      dispatch({
        type: SaleActionTypes.UPDATE_SALE_STATE,
        payload: { openSalesOnHoldDialog: true }
      });
    } else {
      setOpenWarning(true);
    }
  };

  const handleDiscount = (e) => {
    const { value } = e?.target ?? '';
    if (value >= 0 && value <= 100) {
      setCalDiscount(value);
      dispatch({
        type: SaleActionTypes.ADD_PERC_DISCOUNT,
        payload: Number(value)
      });
    }
  };

  const options = [
    { name: 'View/Edit Customer', action: () => handleOpenCustomerModal(), disable: !customerModalData?.id },
    { name: 'Sales OnHold', action: () => handleSalesOnHoldButton() },
    { name: 'Hold Sale', action: () => handleHoldSaleButton(), disable: !cartItems.length },
    {
      name: 'Clear All', action: () => dispatch({ type: SaleActionTypes.CLEAR_CART }), disable: !cartItems.length
    }
  ];

  const renderTotals = () => (
    <TotalsGrid item container>
      <TextGrid item container>
        <Grid item md={6}>Sub Total</Grid>
        <Grid item md={6} style={{ textAlign: 'right' }}>
          {currencyFormatter(subTotal)}
        </Grid>
      </TextGrid>
      {userPermissions?.includes('sales_discount') ? (
        <TextGrid item container>
          <Grid item container alignItems="center" md={6}>
            <CustomInputBase
              type="number"
              placeholder="Enter Discount"
              value={calDiscount}
              onChange={handleDiscount}
              inputProps={{ min: 0, max: 100 }}
            />
            <Grid item style={{ paddingLeft: '5px' }}>%</Grid>
          </Grid>
          <Grid item md={6} style={{ textAlign: 'right' }}>
            {`-${currencyFormatter(discount)}`}
          </Grid>
        </TextGrid>
      ) : ''}
      <TextGrid item container>
        <Grid item md={6}>Total</Grid>
        <Grid item md={6} style={{ textAlign: 'right' }}>
          {currencyFormatter(saleTotal)}
        </Grid>
      </TextGrid>
    </TotalsGrid>
  );

  return (
    <WrapperPaper>
      <HeaderGrid item container>
        <Grid item md={8}>
          <Header variant="h5">{`Cart Items (${cartItems.length})`}</Header>
        </Grid>
        <OptionsWrapper item md={3}>
          <Dots
            fontSize="small"
            style={{ cursor: 'pointer' }}
            onClick={(e) => setDotsButtonEl(e.currentTarget)}
          />
        </OptionsWrapper>
      </HeaderGrid>
      <ItemsGrid item container>
        <TableComponent item container>
          <MainTableHead item container>
            {returnHeaders()}
          </MainTableHead>
          <ItemsBody>
            {cartItems.length
              ? cartItems.map((item) => (
                <CartRow key={item.id} item={item} />
              )) : (
                <EmptyGrid item container direction="column">
                  <EmptyImg
                    alt="Empty shopping basket"
                    // src="https://res.cloudinary.com/health-id/image/upload/v1663739598/Placeholders/empty_cart.gif"
                    src={EmptyCart}
                  />
                  <Grid item style={{ fontWeight: 600, margin: '1rem 0 .8rem' }}>
                    No product Here yet!
                  </Grid>
                  <Grid item style={{ fontSize: '.7rem' }}>
                    When you select a product it will show here.
                  </Grid>
                </EmptyGrid>
              )}
          </ItemsBody>
        </TableComponent>
      </ItemsGrid>
      {renderTotals()}
      <Grid item container style={{ marginTop: '2rem' }}>
        <CustomButton
          type="tertiary"
          header
          onClick={makeSales}
          disabled={!cartItems.length}
          style={{ width: '48%', height: '2.6rem', marginRight: '4%' }}
        >
          Pay Now
        </CustomButton>
        <CustomButton
          data-testid="addCustomer"
          type="secondary"
          header
          onClick={() => setOpenModal(true)}
          disabled={openModal || !cartItems.length}
          style={{ width: '48%', height: '2.6rem' }}
        >
          Add Customer To Purchase
        </CustomButton>
      </Grid>

      <HoldSaleDialog />
      <SalesOnHoldDialog />
      <NotificationDialog
        openDialog={openWarning}
        setOpenDialog={setOpenWarning}
        type="warning"
        title="No sales on hold!"
        desc="Hi Pharm, you currently have no sales on hold"
      />
      <CartPopper
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
      />
      <SelectCustomerDialog
        open={openModal}
        close={() => setOpenModal(false)}
        addCustomer={setOpenIndividualCustomerModal}
      />
      <IndividualCustomerDialog
        dialogOpen={openIndividualCustomerModal}
        closeDialog={() => setOpenIndividualCustomerModal(false)}
        isFromSaleScreen
        autoAddCustomerToSale
      />
      <CustomerDialog
        open={openCustomerModal}
        close={() => setOpenCustomerModal(false)}
        editCustomer={setOpenIndividualCustomerModal}
      />
      <PaymentDialog
        openDialog={openPaymentDialog}
        setOpenDialog={setOpenPaymentDialog}
      />
    </WrapperPaper>
  );
};

export default Cart;
