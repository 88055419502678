import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@mui/material';
import CustomNewTextField from '../../shared/CustomNewTextField';
import CustomButton from '../../customComponents/customButton';
import {
  FiltersGrid,
} from './affiliateFilter.styles';
import { useStateValue } from '../../../providers/stateProvider';

const AffiliateFilter = ({
  state, handleFilterChange, handleSetFilter
}) => {
  const status = [
    { name: 'All', value: '' },
    { name: 'Verified', value: 'VERIFIED' },
    { name: 'Unverified', value: 'UNVERIFIED' },
    { name: 'Suspended', value: 'SUSPENDED' },
  ];

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  return (
    <Grid container xs={12}>
      <FiltersGrid container item xs={12} justifyContent="space-end">
        <Grid item style={{ width: '23%' }}>
          <CustomNewTextField
            field={{ name: 'affiliateName', label: 'Affiliate Name' }}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '96%' }}
            className="mp-uat-affiliateName-filter"
          />
        </Grid>
        <Grid item style={{ width: '23%' }}>
          <CustomNewTextField
            field={{ name: 'email', label: 'Email Address' }}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '96%' }}
            className="mp-uat-email-filter"
          />
        </Grid>
        <Grid item style={{ width: '23%' }}>
          <CustomNewTextField
            field={{ name: 'phoneNumber', label: 'Phone Number' }}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '96%' }}
            className="mp-uat-phoneNumber-filter"
          />
        </Grid>
        <Grid item style={{ width: '23%' }}>
          <CustomNewTextField
            field={{ name: 'status', label: 'Status' }}
            state={state}
            select
            handleChange={handleFilterChange}
            style={{ width: '96%' }}
            className="mp-uat-status-filter"
          >
            {status.map(({ name, value }) => (
              <MenuItem key={value} value={value} className={`mpAdmin-uat-customer-category-filter${name}`}>
                {name}
              </MenuItem>
            ))}
          </CustomNewTextField>
        </Grid>
        <Grid item style={{ width: '8%' }}>
          <CustomButton
            style={{
              width: '6.5rem', height: '2.95rem', fontWeight: '700', fontSize: '0.75rem'
            }}
            onClick={handleSetFilter}
            className="mp-uat-set-filter"
          >
            Set Filter
          </CustomButton>
        </Grid>
      </FiltersGrid>
    </Grid>
  );
};

AffiliateFilter.propTypes = {
  state: PropTypes.instanceOf(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
};

AffiliateFilter.defaultProps = {
  state: {},
};

export default AffiliateFilter;
