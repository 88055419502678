import { createMuiTheme, styled } from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';

export const theme = createMuiTheme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%',
        paddingTop: '20px'
      },
      root: {
        backgroundColor: '#fff'
      }
    }
  }
});

export const TitleText = styled(Typography)`
    font-size: 20px;
    font-family: Montserrat,medium,sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Dash = styled(Box)`
    height: 3px;
    width: 8%;
    margin: 2px 8px;
    background: #CECECE;
`;

export const MiddleText = styled(Typography)`
    font-family: Montserrat,medium,sans-serif;
    font-size: 17px;
`;
export const BackgroundImage = styled('img')`
    height: 70px;
    margin-bottom: 20px;
`;

export const MiddleGroup = styled(Grid)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px;
`;

export const BrowseFileButton = styled(Grid)`
    font-size: 15px;
    padding: 8px 22px;
    background: #F0F0F0;
    border: 1px solid #FCED50;
    max-width: 280px;
    text-align: center;
    border-radius: 5px;
    font-family: Montserrat,medium,sans-serif;
    margin: 0 auto;
`;

export const DropZoneContainer = styled(Grid)`

`;
