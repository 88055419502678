import HomeIcon from '@mui/icons-material/Home';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {
  IconImage,
  StatisticContent,
  StatisticGrid, StatisticHeader,
  StatisticPaper,
  StatisticWrapper
} from './smallCard.styles';

export default function StatisticCard({
  stat, admin
}) {
  const {
    icon, title, value, diff
  } = stat;

  return (
    <Grid item container xs={6}>
      <StatisticPaper elevation={2}>
        <StatisticWrapper admin={admin} diff={diff} container item>
          <IconImage src={icon} />
          <StatisticGrid item>
            <StatisticHeader item>
              {title}
            </StatisticHeader>
            <StatisticContent item>
              {value}
            </StatisticContent>
          </StatisticGrid>
        </StatisticWrapper>
      </StatisticPaper>
    </Grid>
  );
}

StatisticCard.propTypes = {
  stat: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  value: PropTypes.number,
  admin: PropTypes.bool
};
StatisticCard.defaultProps = {
  stat: {},
  icon: HomeIcon,
  value: 0,
  admin: false
};
