import {
  Grid, Typography, Box, Paper
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const CardGridContainer = styled(Paper)`
    width: 390px;
    height: 250px;
    margin-bottom: 50px;
    background: ${(props) => (props.selected ? '#a0a21b1c' : 'white')}  0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #CCCCCC;
    : 0.5px solid #CCCCCC80;
    border-radius: 7px;
    opacity: 1;
    box-sizing: border-box;
    padding:15px 0;
    position: relative;
    cursor: pointer;

    @media(min-width: 992px){
      width: 300px;
      height: 210px;
    }
`;

export const CardHeader = styled(Grid)`
    height: 30px;
    border-bottom: 0.5px solid rgba(204,204,204,.45);
    justify-content: flex-end;
`;

export const HeaderText = styled(Typography)`
  font-size: 22px;
  font-weight: 500;
  color: #A3A3A3;
  text-transform: uppercase;
  padding-right: 15px;
  padding-bottom: 10px;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const CardContentGrid = styled(Grid)`
    margin-top: 35px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 65%;
`;

export const ContentHeader = styled(Typography)`
    color: #424242;
    font-size: 22px;
    font-weight: 600;

    @media(min-width: 992px) {
      font-size: 18px;
    }
`;

export const ContentMeta = styled(Typography)`
    font-size: 22px;
    color: #A3A3A3;
    margin-bottom: 10px;

    @media(min-width: 992px) {
      font-size: 16px;
    }
`;

export const SubContentMeta = styled(Box)`
  display: flex;
  align-items: center;
`;

export const SubContentIcon = styled('img')`
    height: 18px;
    margin-right: 5px;

    @media(min-width: 992px) {
      height: 15px;
      margin-right: 10px;
    }
`;

export const SubContentText = styled(Typography)`
  color: #424242;
  font-weight: 600;
  font-size: 22px;

  @media(min-width: 992px) {
    font-size: 15px;
  }
`;

export const HeaderIcon = styled('img')`
  height: 60px;
  width: 60px;
  position: absolute;
  top: 10px;
  left: 20px;
`;

export const FooterMeta = styled(Grid)`
`;

export const AffiliateSearchIcon = styled('img')`
  height: 80px;
  width: 80px;
  padding: 5px;

  @media(min-width: 992px) {
    height: 50px;
    width: 50px;
  }
`;

export const TypoGrid = styled(Grid)`
  margin-left: 2.5rem;

  @media(min-width: 992px) {
    margin-left: .6rem;
`;

export const AffiliateSearchTypo = styled(Typography)`
  font-size: 1.3rem;

  @media(min-width: 992px) {
    font-size: .75rem
  }
`;

export const AffiliateSearchCardContainer = styled(Grid)`
  background: ${(props) => (props.selected ? '#F0F0F0' : 'white')}  0% 0% no-repeat padding-box;
  cursor: pointer;
`;

export const ContainerWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;
