import {
  Grid, Typography, IconButton, CircularProgress,
  TextField,
  TableContainer,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
display: flex;
justify-content: center;
text-align: center;
width: 100%;
  & > * {
    padding: 2rem 3rem 0rem 3rem;
  }
`;
export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 8px;
  @media(max-width: 991px) {
   background: none;
   box-shadow: none;
   & .MuiPaper-elevation1 {
    box-shadow: none;
  }
  }
`;

export const TitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media(max-width: 991px) {
    width: 100%;
  }
`;

export const TitleText = styled(Typography)`
  font-weight: 600;
  color: #24292E;
  line-height: 22px;
  font-size: 1.5rem;
  text-align: left;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const DividerLine = styled('hr')`
  border-right: 1px solid #CCCCCC;
  height: 80%;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #E0E0E0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const ButtonText = styled(Typography)`
  font-size: 13px;
  font-weight: 700;

  @media(max-width: 991px) {
    font-size: 30px;
  }
`;
export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const EditButton = styled(IconButton)`
  height: 50px;
  width: 92px;
  padding: 0px 25px;
  color: #FFFFFF;
  background-color: #235A91;
  border-radius: 6px;
  margin-bottom: 40px;
  &:hover {
    background-color: #235A91;
  }

  @media(max-width: 991px) {
    height: 5rem;
    width: 100%;
  border-radius: 12px;

  }
`;

export const CancelButton = styled(IconButton)`
  height: 45px;
  width: 85px;
  padding: 0px 25px;
  color: #235A91;
  border: 1px solid #235A91;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 40px;
  &:hover {
    background-color: #000000;
    color: #FFF;
  }

  @media(max-width: 991px) {
    height: 5rem;
    width: 100%;
    border-radius: 12px;

  }
  `;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #FFFFFF;
  margin-top: 4px;
  & .MuiCircularProgress-svg {
    width: 1.6rem;
  }
`;

export const UploadGrid = styled(Grid)`
  padding: 0 10px;
  padding-top: 4rem;
  border-right: 1px solid #D6D6D6;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const Image = styled('img')`
  max-width: 100%;
  border-radius: 50%;
`;

export const SupplierTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color:  #F5F5F7;
    border-radius: 5px;
    padding: 5px;

  }
  & .MuiFormLabel-root {
    color: #858383;
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
  & .MuiFormLabel-root.Mui-disabled {
    color: #858383;
  }
  @media(max-width: 991px) {
    .MuiFilledInput-root {
      font-size: 1.8rem;
      border-radius: 10px;
      padding: 1rem;
  }
  & .MuiInputLabel-filled {
      font-size: 1.6rem;
    }
  }
`;

export const FieldGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
    @media(max-width: 991px) {
      & > * {
        width: 100%;
      }
    }
`;

export const MiddleGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
    justify-content: center;

    @media(max-width: 991px) {
      & > * {
        width: 100%;
      }
    }
`;

export const ButtonGrid = styled(Grid)`
    display: flex;
    justify-content: right;

    @media(max-width: 991px) {
      justify-content: center;
      margin-right: 0rem;
      margin-left: 1rem;
    }
`;

export const ReasonGrid = styled(Grid)`
  justify-content: center;

    & > * {
      width: 100%;
      margin-bottom: 4rem;
    }
    @media(max-width: 991px) {
      & > * {
        width: 100%;
      }
    }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const FormLabel = styled(Typography)`
  color: #A3A3A3;
  font-size: 17px;

  @media(max-width: 991px) {
    font-size: 27px;
  }
`;

export const MessageBoxContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
`;

export const MessageBoxTextField = styled(TextField)`
  background-color: #F0F0F0;
  height: 55px;

  label + .MuiInput-formControl {
    margin-top: 21px;
  }

  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
  }

  input, textarea {
    padding-left: 20px;
    background-color: #F0F0F0;
    color: #424242;
    font-size: 16px;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    height: 80px;

    label + .MuiInput-formControl {
      margin-top: 23px;
    }

    label {
      font-size: 1.6rem;
    }

    input, textarea {
      font-size: 2rem;
    }
  }
`;

export const LightDivider = styled(Divider)`
  background-color: #D6D6D6;

  @media(min-width: 992px) {
    background-color: #F0F0F0;
  }
`;
