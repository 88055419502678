import {
  Button,
  Grid,
  TableCell,
  Typography
} from '@mui/material';
import styled from 'styled-components';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;
  font-weight: 400;

  @media(min-width: 992px) {
    font-size: 1rem;

    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 33%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4){
      width: 28%;
    }
    &:nth-child(5){
      width: 16%;
    }
  }
`;

export const ActionButton = styled(Button)`
  height: 45px;
  color: #235A91;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 0px;
`;

export const TextWrapper = styled(Typography)`
  padding-left: .5rem;
  font-weight: 700;
  font-size: 14px;
`;
