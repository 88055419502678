import {
  Popper, Grid, ListItemText, Paper, Typography, Button, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CPopper = styled(Popper)`
  z-index: 15000000;
`;

export const PaperWrapper = styled(Paper)`
  width: 8rem;
  padding: 1rem 0;
  box-shadow: -1px 2px 8px 1px #00000017;
  transition: transform .3s;
  z-index: 500;
`;

export const CListItemText = styled(ListItemText)`
  margin: 0;
`;

export const TextGrid = styled(Grid)`
`;

export const Typo = styled(Typography)`
  font-size: .85rem;
  color: #606060;
  text-transform: capitalize;
`;

export const GridContainer = styled(Button)`
  padding: 15px 25px;
  width: 20rem;
  align-items: center;
  cursor: pointer;

  @media(min-width: 992px) {
    padding: 0px;
    width: 16rem;
    height: 3.063rem;
    margin-bottom: .5rem;
  }
  &:hover {
    background-color: #F3F9FF;
  }
  &:disabled {
    background-color: #F3F9FF;
  }
`;

export const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 2rem;
`;

export const ItemWrapper = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
`;
