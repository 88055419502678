import { gql } from '@apollo/client';

export const SAVE_DELIVERY_LOCATION = gql`
mutation saveDeliveryLocation(
    $deliveryLocationId: Int,
    $business: Int!, $name: String!,
    $primaryContactName: String!, $mobileNumber: String!,
    $primaryEmailAddress: String!,
    $addressLine1: String!,
    $city: String!,
    $country: String!
  ){
    saveDeliveryLocation(
      deliveryLocationId: $deliveryLocationId,
      business: $business, name: $name, primaryContactName: $primaryContactName,
      mobileNumber: $mobileNumber, primaryEmailAddress: $primaryEmailAddress, addressLine1: $addressLine1, city: $city, country: $country
    ){
      deliveryLocation{
        name
      }
      message
    }
  }
`;
export const DELETE_DELIVERY_LOCATION = gql`
mutation deleteDeliveryLocation(
    $deliveryLocationId: Int!
  ){
    deleteDeliveryLocation(deliveryLocationId: $deliveryLocationId){
      message
    }
  }
`;

export const CREATE_OUTLET_USER = gql`
  mutation newAddBusinessUser(
    $fullName: String!, $email: String!, $roleId: String!,
  ){
    newAddBusinessUser(
      fullName: $fullName,
      email: $email,
      roleId: $roleId,
    ) {
      message
    }
  }
`;

export const UPDATE_BUSINESS_USER = gql`
  mutation updateBusinessUser(
    $businessUserId: Int!, $firstName: String!,
    $lastName: String!, $email: String!, $mobile: String!, $roleId: String!,
    $jobTitle: String!, $startingDate: String!
  ){
    updateBusinessUser(
      businessUserId: $businessUserId,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      mobile: $mobile,
      roleId: $roleId,
      jobTitle: $jobTitle,
      startingDate:$startingDate
    ) {
      message
    }
  }
`;

export const DELETE_BUSINESS_USER = gql`
  mutation deleteBusinessUser($id: Int!){
    deleteBusinessUser(id: $id) {
      message
    }
  }
`;
