import {
  Dialog, Grid, Typography, IconButton, TableCell, InputBase
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const CDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  width: 40rem;
  padding: 0 1.5rem;
`;

export const DialogHeader = styled(Grid)`
  width: 37rem;
  padding: 1.5rem 0;
  background: #fff;
  /* position: fixed;
  z-index: 1; */
`;

export const HeaderIcon = styled(IconButton)`
  border-radius: 0;
  height: 100%;
  padding: 0 8px;

  @media(min-width: 992px) {
    padding: 5px;
  }
`;

export const DialogBody = styled(Grid)`
  height: max-content;
  margin-top: 1rem;
`;

export const DialogActions = styled(Grid)`
  justify-content: flex-end;
  padding: 1rem 0 1.5rem;
  background: #fff;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

export const SubText = styled(Typography)`
  font-size: 12.25px;
  font-weight: 500;
  color: #606060;
  margin-right: 15px;
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 60%;
  border-radius: 6px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }
`;

export const HeaderGrid = styled(Grid)`
  height: max-content;
`;

export const TableHeader = styled(TableCell)`
  font-size: 14px;
  font-weight: 700;
  color: #303030;
  line-height: 3.2;
  padding: 10px;
  border-bottom: 0;
  background: #F4F5F7;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 8%;
    }
    &:nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5) {
      width: 23%;
    }
  }
`;

export const MainTableRow = styled(Grid)`
  height: max-content;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;
  padding: 10px;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 8%;
    }
    &:nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5) {
      width: 23%;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const HeaderText = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 10px;
`;

export const ProductName = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: #303030;
  width: 100%;
`;
