import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Hidden } from '@mui/material';
import currencyFormatter from '../../../shared/currencyFormatter';

import {
  ProductContainer, ProductDetail, ProductDetailBrandNameText,
  ProductDetailPackSizeText, HideMd, ShowMd, ProductDetailSavingsText,
  ProductDetailItemPriceText, ProductDetailNameMainContainer,
  ProductDetailBrandTypo, MobileCounterGrid, MainTableRow, BodyCell,
} from '../../../cart/product-item/styles';
import { PromoInfo } from '../../newOrder/promoInfo';
import {
  ProductDetailMainContainer, ImageWrapper, ProductDetailImage, ProductCounter, PromoText, MobileText
} from './styles';

const ProductItem = ({
  product: {
    supposedUnitCost,
    unitCostPaid,
    marketRrp,
    name: brandName,
    meta: {
      image,
      pack_size: packSize,
    },
    quantity,
    quantityRecieved
  }
}) => (
  <>
    <ShowMd container item>
      <Grid item container xs={4}>
        <ImageWrapper>
          <ProductDetailImage src={image} alt="" />
        </ImageWrapper>
      </Grid>
      <Grid item container xs={8} direction="column">
        <Grid item container>
          <ProductDetailBrandTypo>
            { brandName }
          </ProductDetailBrandTypo>
        </Grid>
        <ProductCounter>
          Quantity Ordered : &nbsp;
          { Number(quantity) }
        </ProductCounter>
        <ProductCounter>
          Quantity Received: &nbsp;
          { Number(quantityRecieved) }
        </ProductCounter>
        <Grid item container alignItems="center">
          <Grid item xs={5}>
            <MobileText>Unit Cost</MobileText>
            <ProductCounter style={{ fontWeight: '600', color: '#000' }}>
              ₦
              {` ${currencyFormatter(supposedUnitCost)}`}
            </ProductCounter>
          </Grid>
          <Grid item xs={5}>
            <MobileText>Price</MobileText>
            <ProductCounter style={{ fontWeight: '600', color: '#000' }}>
              ₦&nbsp;
              { currencyFormatter(Number(supposedUnitCost) * Number(quantityRecieved)) }
            </ProductCounter>
          </Grid>
          {unitCostPaid < supposedUnitCost < 1 ? '' : <PromoInfo info="First Order Promotion" />}
          {unitCostPaid < supposedUnitCost ? (
            <PromoText style={{ color: '#AF1839' }}>
              {`Promo: ${currencyFormatter(Number(unitCostPaid))}`}
            </PromoText>
          ) : ''}
        </Grid>
      </Grid>
    </ShowMd>
    <Hidden mdDown>
      <MainTableRow>
        <ProductDetail container>
          <ImageWrapper><ProductDetailImage src={image} alt="" /></ImageWrapper>

          <ProductDetailNameMainContainer>
            <ProductDetailBrandNameText>
              { brandName }
            </ProductDetailBrandNameText>
            <ProductDetailPackSizeText>
              Pack Size:&nbsp;
              { packSize }
            </ProductDetailPackSizeText>
            {unitCostPaid < supposedUnitCost < 1 ? '' : <PromoInfo info="First Order Promotion" />}
          </ProductDetailNameMainContainer>
        </ProductDetail>
        <BodyCell>
          <ProductCounter>
            <p style={{ color: '#424242', fontSize: '20px' }}>
              {Number(quantity)}
            </p>
          </ProductCounter>
        </BodyCell>
        <BodyCell>
          <ProductCounter>
            <p style={{ color: '#424242', fontSize: '20px' }}>
              {Number(quantityRecieved)}
            </p>
          </ProductCounter>
        </BodyCell>
        <BodyCell>
          <ProductDetailMainContainer>
            <ProductDetailItemPriceText>
              ₦&nbsp;
              {currencyFormatter(supposedUnitCost)}
            </ProductDetailItemPriceText>
            {unitCostPaid < supposedUnitCost ? (
              <PromoText style={{ color: '#AF1839' }}>
                Promo: ₦&nbsp;
                { currencyFormatter(Number(unitCostPaid)) }
              </PromoText>
            ) : ''}
            {/* <ProductDetailSavingsText>
              Savings: ₦&nbsp;
              {(marketRrp - supposedUnitCost) < 0
                ? currencyFormatter(0)
                : currencyFormatter((Number(marketRrp) || Number(supposedUnitCost)) - Number(supposedUnitCost))}
            </ProductDetailSavingsText> */}
          </ProductDetailMainContainer>
        </BodyCell>
        <BodyCell>
          <ProductDetailItemPriceText>
            ₦&nbsp;
            {currencyFormatter(Number(supposedUnitCost) * Number(quantityRecieved))}
          </ProductDetailItemPriceText>
        </BodyCell>
      </MainTableRow>
    </Hidden>
  </>
);

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

ProductItem.defaultProps = {
};

export default ProductItem;
