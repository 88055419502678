import { gql } from '@apollo/client';

export const GET_ALL_REPORTS = gql`
  query stockReports(
    $businesses: [String]
    $businessUserIds: [String]
    $pageCount: Int
    $pageNumber: Int
    $search: String
    $filter: String
  ) {
    stockReports(
      pageCount: $pageCount
      pageNumber: $pageNumber
      businesses: $businesses
      businessUserIds: $businessUserIds
      search: $search
      filter: $filter
    ) {
      id
      business {
        name
      }
      status
      lastUpdated
      createdBy {
        user {
          username
        }
      }
      meta
    }
    stockReportTotalNumber
  }
`;

export const GET_STOCK_REPORT_QUERY = gql`
  query stockReportInventory($id: Int!) {
    stockReportInventory(id: $id) {
      id
      meta
      status
      expectedQuantity
      quantityInStock
      batch {
        id
        batchNo
        expiryDate
        orderCost
      }
      product {
        id
        brandName
        genericName
        meta
        erpVersion {
          resolvedPriceInUseValue
        }
      }
      stockReport {
        dateCreated
        business {
          name
        }
        createdBy {
          user {
            username
          }
        }
        status
      }
    }
  }
`;

export const GET_ALL_REPORTS_INVENTORY = gql`
  query allStockReportInventory {
    allStockReportInventory {
      totalQuantityInStock
      totalInventoryValue
      totalDamagedProducts
      totalExpiredProducts
    }
  }
`;

export const GET_ALL_EXPORT_URL = gql`
  query csvDownloads(
    $pageCount: Int
    $pageNumber: Int
    $dateFrom: String
    $dateTo: String
    $search: String
  ) {
    csvDownloads(
      pageCount: $pageCount
      pageNumber: $pageNumber
      dateFrom: $dateFrom
      dateTo: $dateTo
      search: $search
    ) {
      id
      name
      status
      businessUser {
        id
        fullName
      }
      downloadType
      downloadUrl
      dateCreated
    }
    csvDownloadsTotalNumber
  }
`;

export const GET_ALL_UPLOADS = gql`
  query allCsvUploads(
    $userId: Int
    $search: String
    $id: Int
    $businessId: Int
    $status: String
    $pageCount: Int
    $pageNumber: Int
    $dateFrom: String
    $dateTo: String
  ) {
    allCsvUploads(
      userId: $userId
      search: $search
      id: $id
      businessId: $businessId
      status: $status
      pageCount: $pageCount
      pageNumber: $pageNumber
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      id
      initiatedBy {
        id
        username
        profile
      }
      business {
        id
      }
      status
      fileName
      logs
      dateCreated
    }
    csvUploadCount
  }
`;
