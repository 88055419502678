import {
  IconButton, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Notification } from '../../../assets/svgs';

export const BellImg = styled('img')`
  width: 55px;
  height: 54px;
  @media (min-width: 992px) {
    width: 55px;
    height: 54px;
  }
`;

export const Container = styled(Grid)`
  display: flex;
  cursor: pointer;
  min-width: 6rem;

`;

export const TestWrapper = styled(Grid)`

`;

export const Header = styled(Grid)`
color: #303030;
font-size: 12px;
font-weight: 700;
line-height: 22.82px;

`;

export const Info = styled(Grid)`

color: #606060;
font-size: 11px;
font-weight: 400;
line-height: 19.56px;

`;

export const Duration = styled(Grid)`
color: #606060;
font-size: 10px;
font-weight: 400;
line-height: 19.56px;

`;

export const DialogCancelButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  color: #424242;
  border-radius: 0;
  border-radius: 10px;
  margin-right: 7px;
  border: 1px solid #eee;
  font-size: 2.5rem;

  @media (min-width: 992px) {
    font-size: 14px;
  }
`;

export const DialogOkButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border-radius: 5px;
  margin-right: 7px;
  background-color: #FCED50;
  color: #424242;
  font-size: 2.5rem;

  @media (min-width: 992px) {
    font-size: 14px;
  }

  &:hover {
    background-color: #D2C647;
  }
`;

export const HeaderContainer = styled(Grid)`
  display: flex;
  flex-gap: 5px;
`;
export const NotificationImg = styled(Notification)`
&.MuiSvgIcon-root {
  width: 1rem;
  margin: 0 1rem;
}
`;
