import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  StatusesGrid,
} from './filter.styles';

const Filter = ({
  searchText, handleSearch
}) => {
  const testValue = searchText ?? '';
  return (
    <Grid item container xs={12}>
      <StatusesGrid container item>
        <CustomSearchField
          name="search"
          value={testValue}
          fullWidth
          placeholder="Search"
          handleChange={(e) => handleSearch(e?.target?.value)}
        />
      </StatusesGrid>
    </Grid>
  );
};

Filter.propTypes = {
  searchText: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  searchText: ''
};

export default Filter;
