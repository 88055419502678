import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// tabs functionality/logic
export default function TabPanel(props) {
  const {
    children, value, index, style, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div style={{ display: value === index ? 'block' : 'none' }}>
        <Box p={3} style={style}>
          <Typography>{children}</Typography>
        </Box>
      </div>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  style: PropTypes.instanceOf(Object)
};

TabPanel.defaultProps = {
  style: {}
};
