import {
  Grid, Paper, Typography, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UpdateButton } from '../../../cart/cart.styles';

export const OrderCostText = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
`;
export const SupportText = styled(Typography)`
  font-size: 15px;
  color: #A3A3A3;
`;
export const PaperWrapper = styled(Paper)`
  margin-left: 30px;
  padding: 20px;
`;
export const RadioInfoGrid = styled(Grid)`
  display: grid;
  padding: 0 25px 0 30px;
  color: #A3A3A3;
`;
export const UpdatePreferenceButton = styled(UpdateButton)`
background: #FAF33E;
&:hover{
background: #FAF33E;
}
`;
export const EditEmailButton = styled(Button)`
  padding: 2px 8px;
  margin-left: 20px;
`;
export const EditEmailButtonIcon = styled('img')`
  width: 20px;
  margin-bottom: 4px;

  @media(min-width: 992px) {
    width: 17px;
  }
`;
export const EditEmailButtonText = styled(Typography)`
    margin-left: 10px;
    font-weight: 500;
`;
