const currencyFormatter = (value) => `₦ ${new Intl.NumberFormat(
  'en-US',
  { minimumFractionDigits: 0 }
).format(value)}`;

export const valuesFormatter = (value) => new Intl.NumberFormat(
  'en-US',
  { minimumFractionDigits: 0 }
).format(value);

export default currencyFormatter;
