import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ThemeProvider
} from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  getJobStatus
} from '../../../utils/kwiks';
import {
  StepperIcon
} from '../dispatch/dispatchContainer.styles';
import {
  useStyles, theme, StepperText,
  StepperSubtext, Line, TimeLineWrapper,
  RoboRobo
} from './styles';
import CompleteIcon from '../../../assets/images/orderDispatch/Complete Step.png';
import IncompleteIcon from '../../../assets/images/orderDispatch/Incomplete Step.png';

const StepIcon = ({ completed }) => (
  <div>
    {completed
      ? <StepperIcon src={CompleteIcon} alt="complete" />
      : <StepperIcon src={IncompleteIcon} alt="process" />}
  </div>
);
StepIcon.propTypes = {
  completed: PropTypes.bool.isRequired,
};

const TimeLine = () => (
  <TimeLineWrapper>
    <Line />
    <RoboRobo />
    <Line />
  </TimeLineWrapper>
);

function getSteps() {
  return ['Ready for Dispatch', 'In Transit', 'Order Delivered'];
}

const stepTextMap = {
  0: 'Order picked up by rider',
  1: 'Rider arrived at destination'
};

const stepMap = {
  Sorted: 1,
  'Out for Delivery': 2,
  Delivered: 3
};

export default function VerticalLinearStepper({
  status, deliveryId
}) {
  const classes = useStyles();
  const mappedStatus = stepMap[status];
  const [activeStep, setActiveStep] = React.useState(mappedStatus);
  const [deliveryStatus, setDeliveryStatus] = React.useState([true, true]);
  const steps = getSteps();
  const isThereDelivery = !(deliveryId === 'False' || deliveryId === '');

  useEffect(() => {
    if (!isThereDelivery) return;
    // make an api call to get the status of the job
    // getJobStatus('5283AC457').then(({ data }) => {
    getJobStatus(deliveryId).then((data) => {
      setDeliveryStatus([false, false]);
      const [pickup, delivery] = data.split(',');
      if (delivery === 'ARRIVED') {
        setActiveStep(3);
      } else if (pickup === 'ARRIVED') {
        setActiveStep(2);
        setDeliveryStatus([true, true]);
      } else {
        setActiveStep(1);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [isThereDelivery, mappedStatus]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Stepper
          className={classes.lineVertical}
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>
                <StepperText>
                  {label}
                  {
                    ((mappedStatus - 1) > index) && (deliveryStatus[activeStep - 1])
                      ? (
                        <StepperSubtext>
                          <TimeLine />
                          {stepTextMap[index]}
                        </StepperSubtext>
                      ) : ''
                  }
                </StepperText>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

      </div>
    </ThemeProvider>
  );
}

VerticalLinearStepper.propTypes = {
  status: PropTypes.bool.isRequired,
};
