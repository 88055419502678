import { Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import deleteIcon from '../../../assets/images/Delete.png';
import {
  CardTimer,
  CashTiles,
  MoneyStack
} from '../../../assets/svgs';
import { useStateValue } from '../../../providers/stateProvider';
import currencyAmount from '../../../utils/currencyAmount';
import { JSONParse } from '../../../utils/json';
import { getUser } from '../../../utils/utils';
import {
  DeleteSaleIcon,
  TableColumnRows,
  TableColumnRowText
} from './dashboard.styles';

const ReturnAffiliateRow = ({ sale, navigate, handleDeleteSale }) => {
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());
  const {
    id,
    transactionDate: lastUpdated,
    business: { name },
    receiptNumber,
    businessUser: { user },
    customer,
    salesPayment,
    saleItems,
  } = sale;

  const soldBy = getUser(user);
  const saleMeta = JSONParse(sale?.meta);
  const excessChange = saleMeta?.change && saleMeta?.change_status === 'EXCESS' ? saleMeta?.change : '';

  const { aggregateTotal } = sale;
  // if (saleReturns.length) {
  //   totalToPay -= saleReturns?.reduce((accumulator, item) => accumulator - item.total_to_pay, 0);
  // }


  const paymentMethods = () => salesPayment.map(({ paymentMethod }) => {
    switch (paymentMethod) {
      case 'CARD': return <CashTiles style={{ fontSize: '2.3rem', marginRight: '.5rem' }} />;
      case 'CASH': return <MoneyStack style={{ fontSize: '2.3rem', marginRight: '.5rem' }} />;
      default:
        return <CardTimer style={{ fontSize: '2.3rem', marginRight: '.5rem' }} />;
    }
  });

  const completedSaleOrderCost = saleItems.reduce((accumulator, item) => {
    const meta = JSONParse(item.meta);
    return accumulator + meta.order_cost * item.quantity || 0;
  }, 0);

  // const returnedSaleOrderCost = saleReturns.reduce((accumulator, item) => {
  //   const meta = JSONParse(item.meta);
  //   return accumulator + meta.order_cost * item.quantity || 0;
  // }, 0);

  // const totalOrderCost = completedSaleOrderCost - returnedSaleOrderCost;
  const totalOrderCost = completedSaleOrderCost;

  const grossProfit = aggregateTotal - totalOrderCost;
  const grossMargin = (grossProfit / aggregateTotal) * 100;
  const deleteSale = (e) => {
    handleDeleteSale(sale);
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <TableColumnRows
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(`/sale-details/${id}`)}
    >
      {/* {saleReturns.length ? (<SaleReturnTag>R</SaleReturnTag>) : (<span style={{ width: '40px' }} />)} */}
      <TableColumnRowText style={{ width: '210px' }}>
        {lastUpdated ? moment(lastUpdated).format('D MMM YYYY h:mm a') : 'N/A'}
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '200px' }}>
        <Grid>{name}</Grid>
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '110px' }}>N/A</TableColumnRowText>

      <TableColumnRowText style={{ width: '180px' }}>
        {receiptNumber}
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '180px' }}>
        {soldBy}
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '180px' }}>
        {customer ? customer.name : 'N/A'}
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '130px' }}>
        {`₦ ${currencyAmount(totalOrderCost)}`}
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '150px' }}>
        {`₦ ${currencyAmount(aggregateTotal)}`}
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '150px' }}>
        {`₦ ${currencyAmount(grossProfit)}`}
      </TableColumnRowText>

      <TableColumnRowText style={{ width: '150px' }}>
        {`${grossMargin.toFixed(2)} %`}
      </TableColumnRowText>
      <TableColumnRowText style={{ width: '150px' }}>
        {excessChange ? `₦ ${excessChange.toFixed(2)}` : 'N/A'}
      </TableColumnRowText>
      <TableColumnRowText style={{ width: '200px' }}>
        {paymentMethods()}
      </TableColumnRowText>

      {userPermissions?.includes('CAN_DELETE_SALE') && (
        <TableColumnRowText
          onClick={(e) => deleteSale(e)}
          style={{ width: '40px' }}
        >
          <DeleteSaleIcon src={deleteIcon} alt="delete icon" />
        </TableColumnRowText>
      )}
    </TableColumnRows>
  );
};

ReturnAffiliateRow.propTypes = {
  sale: PropTypes.instanceOf(Object).isRequired,
  navigate: PropTypes.instanceOf(Object).isRequired,
  handleDeleteSale: PropTypes.instanceOf(Object).isRequired
};

export default ReturnAffiliateRow;
