import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { OUTLET_USER_QUERY } from '../../../queries/outlets';
import Navigation from '../shared/navigation/navigation';
import SideNav from '../shared/sideNav/sideNav';
import MainContent from './displayAllUsers/allUsers';
import TitleGrid from './titleGrid';

import {
  TitleGridWrapper, UserContentWrapper,
} from './userContainer.styles';

export default function UserContainer() {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 4;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    searchText: '',
  };
  const [state, setState] = useState(initialState);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: event.target.value });
  };
  const {
    loading, data, refetch
  } = useQuery(OUTLET_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      businessId: Number(localStorage.getItem('ogarx_business_id')),
      pageCount: state.currentPageCount,
      pageNumber: state.currentPage,
    },
  });

  return (
    <>
      <Navigation />

      <TitleGridWrapper>
        <TitleGrid
          state={state}
          stateChanger={setState}
          refetch={refetch}
        />
      </TitleGridWrapper>

      <UserContentWrapper>
        <SideNav />
        {
          (!loading && data)
          && (
            <MainContent
              searchText={state.searchText}
              data={data}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              currentPage={state.currentPage}
              currentPageCount={state.currentPageCount}
            />
          )
        }
      </UserContentWrapper>
    </>
  );
}
