import { styled } from '@mui/material/styles';
import { Box, Grid, Pagination } from '@mui/material';

export const CPagination = styled(Pagination)`
  & .MuiPaginationItem-outlinedPrimary.Mui-selected {
    color: #FFFFFF;
    background-color: #235A91;
    border: none;
    box-shadow: 0px 3.43137px 7.84314px rgba(0, 0, 0, 0.14)
  }

  & .MuiPaginationItem-root {
    color: #235A91;
    border: 1px solid #78AADA;
  }

  @media (max-width: 991px) {
    & .MuiPaginationItem-root {
      height: 64px;
      min-width: 64px;
      margin: 0 7px;
      font-size: 2rem;
      border-radius: 35px;
    }

    & .MuiSvgIcon-root {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const RowBox = styled('div')`
@media (max-width: 991px) {
  font-size: 2.5rem;
  padding: 30px 0px;
}
`;

export const SelectBox = styled('select')`
padding: .5rem;
border-radius: .313rem;
margin-left: 15px;
height: 2.75rem;
width: 4rem;
border: 1px solid #78AADA;
background-color: #ffffff;

@media (max-width: 991px) {
  padding: .8rem;
  height: 4.75rem;
  width: 6.625rem;
  font-size: 2rem;
}

`;

export const ContentGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    display: grid;
    text-align: center;
    justify-content: center;
  }
`;
export const ResultBox = styled(Box)`
  font-size: 1rem;

  @media (min-width: 992px) {
    margin-right: auto;
  }
  @media (max-width: 991px) {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;
