import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { TableColumnRow, TableColumnRowText } from './dataAnalysis.styles';
import ParamsDialog from './paramsDialog';

const ReturnRow = ({ item, index }) => {
  const {
    name, description, id
  } = item;
  const [openModal, setOpenModal] = useState(false);

  return (
    <TableColumnRow>
      <TableColumnRowText>
        {index + 1}
      </TableColumnRowText>
      <TableColumnRowText
        onClick={() => setOpenModal(true)}
      >
        <Grid>{name}</Grid>
      </TableColumnRowText>
      <TableColumnRowText>
        <Grid>{description}</Grid>
      </TableColumnRowText>
      <ParamsDialog
        open={openModal}
        item={item}
        id={id}
        close={setOpenModal}
      />
    </TableColumnRow>
  );
};

ReturnRow.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number
};

ReturnRow.defaultProps = {
  index: 0
};

export default ReturnRow;
