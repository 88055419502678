import {
  TableHead, TableBody, TableRow, TableCell, Button,
  Typography, Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.3rem;
  padding: 15px;

  /* &:first-child, :last-child {
    border-bottom: 0;
  }
  &:nth-child(1), :nth-last-child(2), :last-child {
    padding: 0;
  } */

  @media(min-width: 992px) {
    font-size: .9rem;
    padding: 10px;
    cursor: pointer;
  }
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 0;
  padding-bottom: 0;
  color: #B1B1B1;

  @media(min-width: 992px) {
    font-size: .8rem;
  }
`;

export const BodyCell2 = styled(TableCell)`
  font-size: 1.7rem;
  border-bottom: 0;
  padding-top: 0;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const TableRowHead = styled(TableRow)`
  & > * {
    @media(max-width: 991px) {
      &:nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7) {
        display: none;
      }
    }
  }
`;

export const TableRowBody = styled(TableRow)`
  & > * {
    @media(max-width: 991px) {
      &:nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7) {
        display: none;
      }
    }
  }
`;

export const CustomTableHead = styled(TableHead)`
  display: table-header-group;

  @media(min-width: 992px) {
    display: none;
  }
`;

export const CustomTableBody = styled(TableBody)`
  display: table-row-group;

  @media(min-width: 992px) {
    display: none;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #CCCCCC;
`;

export const ProductImageContainer = styled('div')`
  width: 7rem;
  height: 7rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;

  @media(min-width: 992px) {
    width: 5rem;
    height: 5rem
  }
`;

export const ProductImage = styled('img')`
  width: 80%;
  vertical-align: middle;

  @media(min-width: 992px) {
    ${''}
  }
`;

export const ButtonImage = styled('img')`
  width: 5rem;
  display: block;

  @media(min-width: 992px) {
    width: 2rem;
  }
`;

export const AddImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: .7rem;
  }
`;

export const EditProdButton = styled(Button)`
  margin: 10px 10px 0 0;
  float: right;
`;

export const EditProdButtonIcon = styled('img')`
  width: 30px;
  margin-bottom: 4px;

  @media(min-width: 992px) {
    width: 17px;
  }
`;

export const EditProdButtonText = styled(Typography)`
    font-weight: 500;
`;
