import { useQuery } from '@apollo/client';
import { KeyboardArrowLeft } from '@mui/icons-material';
import {
  Box,
  Grid
} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import medical from '../../../assets/images/marketOrder/medicalgroup.png';
import sort from '../../../assets/images/marketOrder/sort.png';
import { CATEGORY_DETAIL } from '../../../queries/categories';
import { JSONParse } from '../../../utils/json';
import MainContent from '../../customComponents/mainContent';
import CustomSearchField from '../../shared/CustomSearchField';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';
import Loader from '../../shared/loader';
import {
  ContentContainer,
  ContentDiv, ContentImg, ContentText, ContentTextName,
  CustomPaper,
  MenuButton,
  OrderGridContainer,
  PrimaryTitle,
  SecondaryTitle,
  SortBox, SortImg, SortText,
  TextWrapper,
  TitleGridContainer, TitleTextGridContainer,
  ViewCartButton, ViewCartButtonText
} from '../../toBeDeleted/supplierDetails/supplierDetailsContainer.styles';
import CategoryDialog from '../individualCategoryDialog';
import CategoryProduct from './categoryProducts';
import { useStateValue } from '../../../providers/stateProvider';

const CategoryDetails = () => {
  const [edit, setEdit] = useState(false);
  const [searchText, setSearchText] = useSearchParamsState('search', '');

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    loading, error, data, refetch
  } = useQuery(CATEGORY_DETAIL, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  const { category = {} } = data;
  const meta = JSONParse(category.meta);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const EditCat = () => {
    setEdit(true);
  };

  const truncateString = (string, maxLength = 20) => {
    if (string?.length > maxLength) {
      return `${string.substring(0, maxLength)}...`;
    }
    return string;
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <MenuButton onClick={() => navigate('/categories')}>
              <KeyboardArrowLeft fontSize="large" />
            </MenuButton>
          </TitleTextGridContainer>
        </TitleGridContainer>
        <Grid container style={{ marginBottom: '3rem' }}>
          <Grid item xs={8}>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                Category Detail
              </PrimaryTitle>
              <SecondaryTitle>
                View details of a specified category
              </SecondaryTitle>
            </TextWrapper>
          </Grid>
          <Grid item xs={4}>
            <ViewCartButton
              onClick={() => EditCat()}
              disabled={!userPermissions?.includes('pricing_categories_edit_pricing_categories')}
            >
              <Hidden smDown>
                <ViewCartButtonText>
                  Edit
                </ViewCartButtonText>
              </Hidden>
            </ViewCartButton>
          </Grid>
        </Grid>

        <CustomPaper elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <CustomSearchField
                name="search"
                value={searchText}
                handleChange={(e) => handleSearch(e.target.value)}
                placeholder="Search product item"
                style={{ width: '100%' }}
                categoriesList
              />
            </Grid>
            <Grid item xs={1}>
              <SortBox style={{ cursor: 'pointer' }}>
                <SortImg src={sort} alt="sort" />
                <SortText>Sort</SortText>
              </SortBox>
            </Grid>
          </Grid>
        </CustomPaper>

        <ContentContainer elevation={0}>
          <ContentDiv container spacing={2}>
            <Grid item xs={2}>
              <ContentImg src={medical} alt="group" />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: '10px' }}>
                <ContentTextName>
                  {truncateString((category.categoryName))}
                </ContentTextName>
                <ContentText>
                  Loyalty Weight:
                  {' '}
                  {category.loyaltyWeight}
                </ContentText>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box style={{ marginTop: '10px' }}>
                <ContentText>
                  VAT Status:
                  {' '}
                  {category.vatStatus}
                </ContentText>
                <ContentText>
                  Markup:
                  {' '}
                  {category.markup}
                </ContentText>
              </Box>
            </Grid>
          </ContentDiv>
        </ContentContainer>
        <CategoryProduct
          categoryId={id}
          searchText={searchText}
          handleSearch={handleSearch}
        />
        <CategoryDialog
          edit
          dialogOpen={edit}
          closeDialog={() => setEdit(false)}
          categoryRefetch={refetch}
          catData={{ ...category, pricingValue: meta.pricing_value, pricingType: meta.pricing_type }}
        />
      </OrderGridContainer>
    </MainContent>
  );
};

export default CategoryDetails;
