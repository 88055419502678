import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  HeaderWrapper, AffiliatePaper, ContactPaper, ItemImage, ContactText,
  NormalText, NameText, LowerGrids, BottomContainer, ContactGrid, IconImage,
  NameGrid, SubHeaderText, ContactHeaderText, LightDivider, LowerLighterText,
  LowerDarkerText, LowerLighter2Text, SmallHeading, ContactsGrid, NormalText2,
} from './affiliatesHeader.styles';
import OrderIcon from '../../../assets/images/order/Total Orders Icon.png';
import OrderCashIcon from '../../../assets/images/order/Gross Order Value Icon.png';
import currencyFormatter from '../../shared/currencyFormatter';
import { parseAffiliateFields } from '../utils';

const AffiliatesHeader = ({ data }) => {
  const {
    id, legalName, logo, dateCreated, grossOrderAmount, orderVolume,
    city, country, mobile, addressLine1, contactName, email,
  } = parseAffiliateFields(data);

  const datePlaced = moment(dateCreated).format('DD/MM/YYYY');
  const affiliateId = `SU${id}`;

  return (
    <HeaderWrapper container spacing={6}>
      <NameGrid item xs={12} md={6}>
        <AffiliatePaper elevation={2}>
          <SubHeaderText variant="subtitle1" align="right">
            {affiliateId}
          </SubHeaderText>
          <LightDivider light />
          <ItemImage alt="logo" src={logo} />
          <BottomContainer item>
            <NameText>{legalName || 'N/A'}</NameText>
            <NormalText>
              {`Created on ${datePlaced || 'N/Ae'}`}
            </NormalText>
            <LowerGrids container item>
              <IconImage alt="lower logo" src={OrderIcon} />
              <LowerLighterText variant="subtitle1">
                Order Volume:
              </LowerLighterText>
              <LowerDarkerText variant="subtitle1">
                {`${orderVolume || 0} Orders`}
              </LowerDarkerText>
            </LowerGrids>
            <LowerGrids container item>
              <IconImage alt="lower logo" src={OrderCashIcon} />
              <LowerLighter2Text variant="subtitle1">
                Order Value:
              </LowerLighter2Text>
              <LowerDarkerText variant="subtitle1">
                {currencyFormatter(grossOrderAmount)}
              </LowerDarkerText>
            </LowerGrids>
          </BottomContainer>
        </AffiliatePaper>
      </NameGrid>
      <ContactGrid item xs={12} md={6}>
        <ContactPaper elevation={2}>
          <ContactHeaderText variant="h6" align="left">
            Contact Information
          </ContactHeaderText>
          <LightDivider light />
          <ContactsGrid item container direction="column">
            <Grid item container spacing={4}>
              <Grid item xs={6}>
                <SmallHeading variant="caption">
                  Primary Contact Name
                </SmallHeading>
                <NormalText2>{contactName || 'None available'}</NormalText2>
              </Grid>
              <Grid item xs={6}>
                <SmallHeading variant="caption">Mobile</SmallHeading>
                <NormalText2>{mobile || 'N/A'}</NormalText2>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SmallHeading variant="caption">
                  Email
                </SmallHeading>
                <NormalText2>{email || 'No email'}</NormalText2>
              </Grid>
              <Grid item xs={12}>
                <SmallHeading variant="caption">Address</SmallHeading>
                <ContactText>{`${addressLine1 || 'N/A'}`}</ContactText>
                <ContactText>
                  {(city || country) ? `${city}, ${country}` : '' }
                </ContactText>
              </Grid>
            </Grid>
          </ContactsGrid>
        </ContactPaper>
      </ContactGrid>
    </HeaderWrapper>
  );
};

AffiliatesHeader.propTypes = {
  data: PropTypes.instanceOf(Object)
};

AffiliatesHeader.defaultProps = {
  data: {}
};

export default AffiliatesHeader;
