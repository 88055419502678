import React from 'react';
import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

const TableLoader = ({ rows }) => (
  <div style={{ marginTop: '1rem' }}>
    {Array.from(new Array(rows)).map((index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        style={{ marginBottom: '.5rem', padding: '1rem' }}
      />
    ))}
  </div>
);

export default TableLoader;

TableLoader.propTypes = {
  rows: PropTypes.number.isRequired
};
