import {
  Grid, Typography, IconButton, CircularProgress,
  TextField, TableContainer, Table, TableHead, TableCell,
  Checkbox, TableRow, Paper, Popper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  & > * {
    padding: .5rem 3rem;
    width: 100%;
  }
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 600;
  color: #424242;
  font-size: 30px;
  margin-bottom: 10px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const DividerLine = styled('hr')`
  border: 1px solid #CCCCCC;
  width:96%;
  min-width: 300px;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #E0E0E0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;
export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #FCED50;
  color: #424242;
  margin-bottom: 25px;

  &:hover {
    background-color: #D2C647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 25px;
  border: 2px solid #424242;
  `;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const UploadGrid = styled(Grid)`
  background-color: #E0E0E0;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const SupplierTextField = styled(TextField)`

`;

export const TopGrid = styled(Grid)`
  & > * {
    width: 100%;
    margin: 0 0 1.5rem;
  }

`;

export const BottomGrid = styled(Grid)`
  & > * {
    width: calc(50% - 0.75rem);
    margin: 0 1.5rem 1.5rem 0;

    :nth-child(2n) {
      margin-right: 0;
    }
  }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CurrencyTypo = styled(Typography)`
  font-size: .7rem;
  color: #909090;
`;

export const SearchSupplierResults = styled('div')`
  background: #ffffff;
  width: 27.5%;
  padding: 2%;
  position: absolute;
  left: 30.55rem;
  top: 17.5rem;
  z-index: 1200;
  border: 1px solid #00000021;
  border-radius: 0px 0px 10px 10px;
`;

export const SupplierItem = styled('div')`
  padding: 4px;
  cursor: pointer;

  &:hover {
    background: #e8e8e8;
  }
`;

export const AddSupplierButton = styled('div')`
  background: #fced4f;
  padding: 4%;
  text-align: center;
  margin-top: 5%;
  font-size: 15px;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
`;

export const OrderSubHeader = styled(Grid)`
  margin-bottom: 1rem;
  justify-content: space-between;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 10px 20px;
  align-items: center;

   @media(min-width: 992px) {
    padding: 5px 15px;
  }
`;

export const ProductItemsContainer = styled(Grid)`
  align-items: center;
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 30px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const TableContainerWrapper = styled(TableContainer)`

`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const MainTableHead = styled(TableHead)`
  display: table-header-group;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 2.2rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  &:first-child {
    width: 1%;
  }
  &:nth-child(2) {
    width: 18%;
    padding: 0;
  }
  &:nth-child(3) {
    width: 14%;
  }
  &:nth-child(4) {
    width: 2%;
  }
  &:last-child {
    width: 28%;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #CCCCCC;
`;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: .9rem;

  &:first-child, :nth-child(4) {
    border-bottom: 0;
    padding: 8px 16px;
  }
  &:nth-child(2), :nth-child(3), :last-child {
    padding: 0;
  }
  &:last-child {
    padding-left: 15px;
  }
`;

export const SearchPopper = styled(Popper)`
  z-index: 1;
`;

export const SearchPaper = styled(Paper)`
  background: #ffffff;
  width: 13.2rem;
  padding: 2%;
  z-index: 1200;
  border: 1px solid #00000021;
  max-height: 520px;
  overflow-y: scroll;
`;
