import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Paper, TableContainer, Table, TableRow, TableBody, TableHead, useMediaQuery
} from '@mui/material';
import MainContent from '../customComponents/mainContent';
import { AFFILIATE_INVOICE_QUERY } from '../../queries/affiliates';
import {
  PaymentText, ContentBox, HeaderCell, TableBox, BackgroundBox, DetailBox,
  FooterGrid, MobileContainer, FilterBox, FilterImg
} from './paymentsContainer.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import PaymentsFilter from './filter/paymentsFilter';
import filterImg from '../../assets/images/marketOrder/filter-search.png';
import MobileReturnRow from './mobileReturnRow';
import MobileFilter from './mobileFilter';
import TablePagination from '../shared/tablePagination';
import { addHyphen } from '../shared/methods';

const PaymentsContainer = () => {
  const headers = [
    'Affiliate Name', 'Order Number', 'Payment Status', 'Invoice', 'Amount Paid', 'Invoice Number', 'Credit Days'
  ];
  const isSmall = useMediaQuery('(max-width: 991px)');

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const initialFilter = {
    invoiceNo: null,
    orderNo: null,
    affiliateName: '',
    status: ''
  };

  const [state, setState] = useState({});
  const [filters, setFilters] = useState(initialFilter);

  const {
    orderNo, invoiceNo, affiliateName, status
  } = state;

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (!value && [name] in filters) {
      setFilters((_state) => {
        const copy = { ..._state };
        delete copy[name];
        return copy;
      });
      return;
    }
    setFilters((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    status,
    orderNo,
    invoiceNo,
    affiliateName
  };

  const {
    loading, error, data
  } = useQuery(AFFILIATE_INVOICE_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const handleSetfilter = () => {
    setState({ ...filters });
  };

  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);

  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  const handleClearFilter = () => {
    setFilters(initialFilter);
  };

  const affiliateInvoices = data?.affiliateInvoices || [];
  const invoicesTotalNumber = data?.invoicesTotalNumber || 0;

  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <ContentBox>
        {!isSmall && (
          <BackgroundBox>
            <DetailBox>
              <PaymentsFilter
                handleFilterChange={handleFilterChange}
                handleSetFilter={handleSetfilter}
              />
              <PaymentText> Payments </PaymentText>
              {loading ? <ProductsTableLoader /> : (
                <TableBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="payments table" data-testid="mp-uat-payments-table">
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <HeaderCell key={header} data-testid={`mp-uat-payments-${addHyphen(header)}`}>{header}</HeaderCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {affiliateInvoices.map((paymentItem) => (
                          <ReturnRow
                            key={paymentItem?.id}
                            paymentItem={paymentItem}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableBox>
              )}
            </DetailBox>
          </BackgroundBox>
        )}

        {isSmall && (
          displayMobileFilter ? (
            <MobileFilter
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleSetFilter={handleSetfilter}
              handleMobile={handleMobileFilter}
              handleClear={handleClearFilter}
            />
          )
            : (
              <MobileContainer>
                <FilterBox>
                  <FilterImg src={filterImg} alt="filter" onClick={handleMobileFilter} />
                </FilterBox>
                {loading ? <ProductsTableLoader /> : (
                  affiliateInvoices.map((paymentItem) => (
                    <MobileReturnRow
                      key={paymentItem?.id}
                      paymentItem={paymentItem}
                    />
                  ))
                )}
              </MobileContainer>
            )
        )}
        {!displayMobileFilter && (
          <FooterGrid item container>
            <TablePagination
              total={invoicesTotalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </FooterGrid>
        )}
      </ContentBox>
    </MainContent>
  );
};

export default PaymentsContainer;
