import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Hidden, Grid, Paper, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Slide
} from '@mui/material';
import toast from 'react-hot-toast';
import {
  Telegram as TelegramIcon, Clear as ClearIcon, KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Check as CheckIcon
} from '@mui/icons-material';

import moment from 'moment';
import { JSONParse } from '../../../utils/json';

import {
  CartGridContainer,
  OrderButton,
  OrderButtonText,
  UpdateButton,
  DialogActionButtonText,
  ProductColumnHeaderTitle,
  BackArrowIconContainer,
  PageTitleText,
  PageTitleSubText,
  CartItemCountContainer,
  CartItemCountText,
  ProductColumnHeaders,
  SupplierCardMainWrapper,
  PageTitleContainer,
  CartGridContainerHeader,
  DialogCancelButton,
  DialogOkButton,
  DeliveryAddressCardHeaderTitle,
  DeliveryAddressCardHeaderSubTitle,
  ChangeLocationButton,
  ChangeLocationActions,
  LocationTextField,
  ChangeLocationContainer,
  KeyboardArrowLeft
} from '../../cart/cart.styles';

import OrderTracker from '../orderTracker';

import {
  SupplierDetailCard,
  SupplierDetailCardWrapper,
  SupplierDetailCardHeader,
  OrderDeliveryNotesText,
  HeaderTitle,
  HeaderButton
} from './styles';
import { GET_SINGLE_SUPPLIER_ORDER } from '../../../queries/orders';
import { UPDATE_ORDER_META_MUTATION } from '../../../mutations/cart';
import { CANCEL_SUPPLIER_ORDERS, RECEIVE_SUPPLIER_ORDERS } from '../../../mutations/orders';
import MainContent from '../../customComponents/mainContent';
import GoToTop from '../../customComponents/scrollToTop';
import SupplierOrderDetailProductItem from './product-item';
import PaymentSummary from '../../shared/paymentSummaryCard/PaymentSummary';
import DeliveryInfoCard from '../../shared/deliveryInfoCard/deliveryInfoCard';
import { Product } from '../../../providers/reducers/product/classes/Product';
import OrderInfoCard from '../../shared/orderInfoCard/orderInfoCard';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialState = {
  business: null,
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  showCancelOrderDialog: false,
  showReceiveOrderDialog: false,
  loading: false,
  editNote: false,
  note: '',
  orderId: null,
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: 'Nigeria',
  source: '',
  showPaymentDetails: false,
  deliveryId: null,
};

const SupplierOrderDetailIndex = () => {
  const [state, setState] = useState(initialState);

  const params = useParams();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  const {
    business, businessContact, deliveryDate, dateCreated, supplier, cart, showCancelOrderDialog, activeStep,
    showReceiveOrderDialog, loading, editNote, note, source, status,
    all, prompt, error, openAlternativeDialog, notReceivedProds, onlyMarketProducts, primaryTitle, allBatches
  } = state;

  const [cancelSupplierOrders] = useMutation(CANCEL_SUPPLIER_ORDERS);
  const [receiveSupplierOrders, { loading: paymentLoading }] = useMutation(RECEIVE_SUPPLIER_ORDERS);
  const [updateOrderMeta] = useMutation(UPDATE_ORDER_META_MUTATION);

  const retrieveProducts = (orderSet) => {
    let products = [];

    products = orderSet.map((p) => {
      const {
        name,
        quantity,
        supplierOrder: { supplier: { name: supplierName, settings } },
        product: { id, meta, business: { id: productBusinessId } }
      } = p;

      const supplierSettings = JSON.parse(settings.replace(/'/g, '"').replace('None', '"N/A"'));

      return {
        id,
        name,
        quantity,
        ...new Product(p.product),
        supplier: supplierName,
        supplierPaymentTerm: supplierSettings.payment_term,
        meta: JSON.parse(meta),
        productBusinessId,
      };
    });

    setState((s) => ({ ...s, cart: products }));
  };

  const {
    loading: supplierLoading, data: supplierData, refetch
  } = useQuery(GET_SINGLE_SUPPLIER_ORDER, {
    variables: { id: params?.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (supplierData && supplierData.supplierOrder) {
      const {
        supplierOrder: {
          deliveryDate: _deliveryDate,
          deliveryId,
          orderStatus,
          dateCreated: _dateCreated,
          order: {
            id: orderId,
            meta,
            business: _business
          },
          orderproductSet,
          supplier: _supplier
        }
      } = supplierData;

      const orderMeta = JSONParse(meta.replace(/'/g, '"'));
      const businessContactMeta = JSONParse(_business.contacts.replace(/'/g, '"').replace('None', '"Lagos"'));

      const {
        note: _note, addressLine1, addressLine2, city
      } = orderMeta;
      const _source = locationState && locationState[0];

      const dateToDeliver = _deliveryDate
        ? moment(_deliveryDate).format('DD/MM/YYYY')
        : moment(_dateCreated).add(2, 'days').format('DD/MM/YYYY');

      setState((s) => ({
        ...s,
        orderId,
        note: _note,
        business: _business,
        businessContact: businessContactMeta,
        supplier: _supplier,
        deliveryDate: dateToDeliver,
        dateCreated: moment(_dateCreated).format('DD/MM/YYYY'),
        addressLine1,
        addressLine2,
        city,
        source: _source,
        orderStatus,
        deliveryId
      }));

      retrieveProducts(orderproductSet);
    }
  }, [supplierData]);

  const closeDetailsPage = () => {
    setState((s) => ({ ...s, showPaymentDetails: false }));
  };

  const openDetailsPage = () => {
    setState((s) => ({
      ...s,
      showPaymentDetails: true
    }));
  };

  const handleCancelOrder = () => {
    setState((s) => ({
      ...s,
      loading: true,
      showCancelOrderDialog: false
    }));

    cancelSupplierOrders({
      variables: {
        supplierOrderIds: [params.id]
      }
    }).then((results) => {
      const {
        data: {
          cancelSupplierOrders: {
            message
          }
        }
      } = results;
      toast.success(message);
      setState((s) => ({
        ...s, loading: false
      }), () => setTimeout(() => {
        navigate('/orders');
      }, 1500));
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, loading: false }));
    });
  };

  const handleReceiveOrder = () => {
    setState((s) => ({
      ...s,
      loading: true,
      showReceiveOrderDialog: false
    }));

    receiveSupplierOrders({
      variables: {
        supplierOrderIds: [params.id]
      }
    }).then((results) => {
      const {
        data: {
          receiveSupplierOrders: {
            message
          }
        }
      } = results;
      toast.success(message);
      setState((s) => ({
        ...s, loading: false
      }), () => setTimeout(() => {
        navigate('/orders-admin');
      }, 1500));
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, loading: false }));
    });
  };

  const handleEditNoteClicked = () => {
    setState((s) => ({ ...s, editNote: true }));
  };

  const handleEditNote = () => {
    const { orderId } = state;

    if (note) {
      const keyValues = [
        { key: 'note', value: note }
      ];

      const mutationVariables = {
        keyValues,
        orderId,
        isManualOrder: false
      };

      updateOrderMeta({
        variables: mutationVariables
      }).then(({ data }) => {
        const {
          updateOrderMeta: {
            order: { meta }
          }
        } = data;

        const orderMeta = JSONParse(meta.replace(/'/g, '"'));
        const {
          note: orderNote
        } = orderMeta;
        toast.success('Note updated.');
        setState((s) => ({ ...s, editNote: false, note: orderNote }));
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      toast.error('Note cannot be empty.');
    }
  };

  const {
    orderStatus, showPaymentDetails, deliveryId,
  } = state;

  const orderTotal = cart.reduce((sum, obj) => sum + (Number(obj.quantity) * Number(obj.resolvedPriceInUseValue)), 0);

  const groupOrderDiscount = cart.reduce((sum, obj) => sum
        + (`${obj.productBusinessId}` === '1' ? (Number(obj.quantity) * ((Number(obj.meta.marketRrp) || Number(obj.resolvedPriceInUseValue)) - Number(obj.resolvedPriceInUseValue))) : 0), 0);

  return (
    <MainContent>
      <CartGridContainer>
        <CartGridContainerHeader container sm={12} md={12}>
          <BackArrowIconContainer item sm={2} md={1} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </BackArrowIconContainer>

          <PageTitleContainer sm={10} md={5} lg={6} item>
            <PageTitleText>Order Detail</PageTitleText>

            <Hidden lgDown>
              <PageTitleSubText>View details of an order sent to a supplier</PageTitleSubText>
            </Hidden>
          </PageTitleContainer>
          <HeaderButton item container md={6} lg={5}>
            {!source && (
            <UpdateButton
              onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: true }))}
              disabled={loading}
              style={{
                marginRight: '40px'
              }}
            >
              <Hidden lgDown>
                <ClearIcon />
              </Hidden>
              <OrderButtonText>
                Cancel Order
              </OrderButtonText>
            </UpdateButton>
            )}

            {!source && (
            <OrderButton
              onClick={() => setState((s) => ({ ...s, showReceiveOrderDialog: true }))}
              disabled={loading}
            >
              <Hidden lgDown>
                <TelegramIcon />
              </Hidden>
              <OrderButtonText>
                Receive Order
              </OrderButtonText>
            </OrderButton>
            )}
          </HeaderButton>
        </CartGridContainerHeader>

        <Grid
          container
          justifyContent="space-between"
          style={{
            marginTop: '42px'
          }}
          spacing={4}
        >
          <SupplierDetailCardWrapper item container md={12} lg={6}>
            <OrderInfoCard
              id="ID0D123-SU12"
              business={business}
              dateCreated={dateCreated}
              businessDateDelivered={moment(deliveryDate).format('DD/MM/YYYY')}
              supplier={supplier}
            />
          </SupplierDetailCardWrapper>

          <SupplierDetailCardWrapper item container md={12} lg={6}>
            <SupplierDetailCard>
              <DeliveryInfoCard
                businessContact={businessContact}
                openDetailsPage={openDetailsPage}
              />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>

        <CartItemCountContainer>
          <CartItemCountText>
            {cart.length}
            &nbsp;
            {cart.length ? 'Items' : 'Item'}
          </CartItemCountText>
        </CartItemCountContainer>

        <Hidden lgDown>
          <ProductColumnHeaders>
            <HeaderTitle>Product Item</HeaderTitle>
            <HeaderTitle>Quantity Ordered</HeaderTitle>
            <HeaderTitle>Quantity Received</HeaderTitle>
            <HeaderTitle>Unit Cost</HeaderTitle>
            <HeaderTitle>Price</HeaderTitle>
          </ProductColumnHeaders>
        </Hidden>

        <Paper elevation={2} style={{ marginTop: '25px' }}>
          {cart.map((productItem) => (
            <SupplierOrderDetailProductItem
              product={productItem}
            />
          ))}
        </Paper>

        <SupplierCardMainWrapper>
          <Paper elevation={2} />
        </SupplierCardMainWrapper>

        <Grid
          container
          justifyContent="space-between"
          style={{
            marginTop: '42px',
            marginBottom: '50px'
          }}
        >
          <SupplierDetailCardWrapper item>
            <SupplierDetailCard>
              <SupplierDetailCardHeader>
                <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>

                {
                  editNote ? (
                    <ChangeLocationActions>
                      <ChangeLocationButton
                        role="button"
                        onClick={() => setState((s) => ({ ...s, editNote: false }))}
                      >
                        <ClearIcon />
                      </ChangeLocationButton>

                      <ChangeLocationButton
                        role="button"
                        onClick={() => handleEditNote()}
                      >
                        <CheckIcon />
                      </ChangeLocationButton>
                    </ChangeLocationActions>
                  ) : (
                    <DeliveryAddressCardHeaderSubTitle
                      onClick={() => handleEditNoteClicked()}
                      style={{
                        cursor: 'pointer',
                      }}
                      type="button"
                    >
                      Edit Note
                    </DeliveryAddressCardHeaderSubTitle>
                  )
                }
              </SupplierDetailCardHeader>

              {
                editNote ? (
                  <ChangeLocationContainer>
                    <LocationTextField
                      label="Note"
                      value={note}
                      multiline
                      rows={5}
                      onChange={(event) => setState((s) => ({ ...s, note: event.target.value }))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ backgroundColor: '#f0f0f0' }}
                    />
                  </ChangeLocationContainer>
                ) : (
                  <OrderDeliveryNotesText>{note}</OrderDeliveryNotesText>
                )
              }

            </SupplierDetailCard>
          </SupplierDetailCardWrapper>

          <SupplierDetailCardWrapper item>
            <SupplierDetailCard>
              <PaymentSummary
                cart={cart}
                orderTotal={orderTotal}
                groupOrderDiscount={groupOrderDiscount}
                grandTotal={orderTotal}
              />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>

        <GoToTop />
      </CartGridContainer>

      <Dialog
        open={showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton
            onClick={() => handleCancelOrder()}
          >
            <DialogActionButtonText>
              OK
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showReceiveOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showReceiveOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Receive Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to receive the order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showReceiveOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton
            onClick={() => handleReceiveOrder()}
          >
            <DialogActionButtonText>
              OK
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>
      <OrderTracker
        dialogOpen={showPaymentDetails}
        status={orderStatus}
        closeDialog={closeDetailsPage}
        deliveryId={deliveryId}
      />
    </MainContent>
  );
};

export default SupplierOrderDetailIndex;
