import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

export const ALL_PROMOTIONS_QUERY = gql`
  query allPromotions(
    $searchName: String $dateFrom: String $dateTo: String $pageCount: Int $pageNumber: Int
  ){
    allPromotions(
      searchName: $searchName dateFrom: $dateFrom dateTo: $dateTo pageCount: $pageCount pageNumber:$pageNumber
    ){
      id
      name
      value
      unit
      description
      dateCreated
      startDate
      endDate
      status
      lastUpdated
    }
    allPromotionsTotalNumber
  }
`;

export const PROMOTION_QUERY = gql`
  query promotion($id: Int!){
    promotion(id: $id){
      id
      name
      value
      unit
      description
      dateCreated
      startDate
      endDate
      status
      lastUpdated
    }
  }
`;

export const PROMOTION_PRODUCTS_QUERY = gql`
  query promotionProducts(
    $promotionId: Int $searchProductName: String $pageCount: Int $pageNumber: Int
  ){
    promotionProducts(
      promotionId: $promotionId searchProductName: $searchProductName pageCount: $pageCount pageNumber:$pageNumber
    ){
      id
      promoPrice
      promotion {
        id
        name
      }
      product {
        id
        promo
        promoStatus
        ...productVersions
      }
      qtySold
      meta
    }
    promotionProductsTotalNumber
  }
  ${PRODUCT_VERSIONS}
`;
