import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  CircularProgress, Grid,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { FIND_SUPPLIERS_QUERY, ALL_SUPPLIERS_QUERY } from '../../../queries/suppliers';
import {
  CREATE_BATCH_MUTATION, UPDATE_BATCH_MUTATION
} from '../../../mutations/products';
import {
  ButtonDarkStyled, HeaderStyled, ButtonOutlineStyled, FooterStyled,
  DialogTitleStyled, DialogContentStyled, CloseStyled, DialogContainer
} from '../../shared/uploadProduct/individual/individualProductDialog.styles';
import SearchPopper from './searchPopper';
import SuccessDialog from '../../shared/successDialog';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';
import CustomInputBase from '../../customComponents/customInputBase';

const defaultReceived = moment().format('YYYY-MM-DD');
const defaultExp = moment().add(1, 'years').format('YYYY-MM-DD');

const initialState = {
  batchNo: '',
  quantity: 0,
  dateReceived: defaultReceived,
  expiryDate: defaultExp,
  unitCost: 0,
  supplierId: ''
};

const initialErrorState = {
  batchNoError: false,
  quantityError: false,
  dateReceivedError: false,
  expiryDateError: false,
  unitCostError: false,
  supplierIdError: false,
};

const SingleBatchDialog = ({
  open, onClose, brandName, productId, batchToEdit, refetch
}) => {
  const [batch, setBatch] = useState(initialState);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedSuppliers, setSearchedSuppliers] = useState([]);
  const [openPopper, setOpenPopper] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [allSupplier, setAllSupplier] = useState([]);

  const {
    batchNoError, quantityError, dateReceivedError, expiryDateError, unitCostError, supplierIdError
  } = errorState;

  const textRef = useRef();

  const { data: allSuppliersData } = useQuery(ALL_SUPPLIERS_QUERY, {
    variables: {
      onlyBusinessSuppliers: true
    }
  });

  useEffect(() => {
    if (!allSuppliersData?.allSuppliers) return;
    setAllSupplier(allSuppliersData?.allSuppliers);
  }, [allSuppliersData]);

  useEffect(() => {
    if (batchToEdit) {
      const {
        batchNo, dateRecieved: dR, expiryDate: eD, supplier: { name },
        quantityInStock: quantity, orderCost: unitCost
      } = batchToEdit;
      const dateReceived = moment(dR).format('YYYY-MM-DD');
      const expiryDate = moment(eD).format('YYYY-MM-DD');
      const supplier = name.split(' ')[0];
      setBatch({
        ...batch, batchNo, quantity, dateReceived, expiryDate,
        unitCost, supplier
      });
    }
  }, [batchToEdit]);

  const [findSupplier, { loading, data }] = useLazyQuery(FIND_SUPPLIERS_QUERY);
  useEffect(() => {
    if (data && data.findSupplier) {
      const suppliers = data.findSupplier
        .filter((supplier) => !!supplier)
        .map(({ id, name }) => ({ id, name }));
      setSearchedSuppliers(suppliers);
    }
  }, [data, loading]);

  const validateState = (name, value) => {
    switch (name) {
      case 'batchNo':
      case 'dateReceived':
      case 'expiryDate':
      case 'supplierId':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !(value.length > 0)
        });
      case 'unitCost':
      case 'quantity':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !(value.length > 0) || value === '0'
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBatch({ ...batch, [name]: value });
    validateState(name, value);
  };

  const handleClose = () => {
    onClose();
    setBatch(initialState);
  };

  const handleSetSupplier = (sup) => {
    const { id, name } = sup;
    setBatch({ ...batch, supplierId: id, supplier: name });
    setOpenPopper(false);
  };

  const [createBatch] = useMutation(CREATE_BATCH_MUTATION);
  const [updateBatch] = useMutation(UPDATE_BATCH_MUTATION);

  const validateFields = () => {
    const {
      batchNo, quantity, expiryDate, dateReceived, unitCost, supplierId
    } = batch;

    let valid = true;

    if (batchNo === '') {
      valid = false;
    } else if (quantity === '' || quantity === 0) {
      valid = false;
    } else if (expiryDate === '') {
      valid = false;
    } else if (dateReceived === '') {
      valid = false;
    } else if (unitCost === '' || unitCost === 0) {
      valid = false;
    } else if (supplierId === '') {
      valid = false;
    }

    setErrorState({
      ...errorState,
      batchNoError: batchNo === '',
      quantityError: (quantity === '' || quantity === 0),
      expiryDateError: expiryDate === '',
      dateReceivedError: dateReceived === '',
      unitCostError: (unitCost === '' || unitCost === 0),
      supplierIdError: supplierId === '',
    });

    if (!valid) return false;
    return true;
  };

  const handleBatch = () => {
    if (!validateFields()) {
      toast.error('Provide required fields');
    } else {
      const supplier = allSupplier.find(({ name: n }) => n === batch.supplierId)?.id;
      setIsLoading(true);
      if (!batchToEdit) {
        createBatch({
          variables: { ...batch, supplierId: supplier, productId }
        }).then(({ data: { createBatch: { message } } }) => {
        // toast.success(message);
          setIsLoading(false);
          setSuccessOpenDialog(true);
          if (refetch) refetch();
          handleClose();
        }).catch((err) => {
          setIsLoading(false);
          toast.error(err?.message);
        });
      } else {
        const { id: batchId } = batchToEdit;
        const {
          batchNo, dateRecieved, expiryDate, quantity, unitCost
        } = batch;
        updateBatch({
          variables: {
            batchId, batchNo, dateRecieved, expiryDate, quantity, unitCost
          }
        }).then(({ data: { updateBatch: { message } } }) => {
          toast.success(message);
          setIsLoading(false);
          if (refetch) refetch();
          handleClose();
        }).catch((err) => {
          toast.error(err?.message);
        });
      }
    }
  };

  const topFields = [
    {
      type: 'text', name: 'batchNo', label: 'Batch No', 'data-testid': 'batchNo', required: true, value: batch?.batchNo,
      placeholder: 'Enter Batch No', onChange: handleChange,
      error: batchNoError, helperText: 'Batch number is required', secured: false, show: true
    },
    {
      type: 'number', name: 'quantity', label: 'Quantity', 'data-testid': 'quantity', required: true, value: batch?.quantity,
      placeholder: 'Enter Quantity', onChange: handleChange,
      error: quantityError, helperText: 'Quantity is required', secured: false, show: true
    },
    {
      type: 'number', name: 'unitCost', label: 'Order Cost', 'data-testid': 'unitCost', required: true, value: batch?.unitCost,
      placeholder: 'Enter Order Cost', onChange: handleChange,
      error: unitCostError, helperText: 'Unit cost is required', secured: false, show: true
    },
    {
      type: 'date', name: 'dateReceived', label: 'Date Received', 'data-testid': 'dateReceived', required: true, value: batch?.dateReceived,
      placeholder: 'Enter Date Received', onChange: handleChange,
      error: dateReceivedError, helperText: 'Date received is required', secured: false, show: true
    },
    {
      name: 'supplierId', label: 'Supplier', 'data-testid': 'supplierId', required: true, value: batch?.supplierId,
      options: allSupplier?.map(({ name }) => name), placeholder: 'Enter supplier', onChange: handleChange,
      error: supplierIdError, helperText: 'Supplier is required', secured: false, show: true
    },
    {
      type: 'date', name: 'expiryDate', label: 'Expire Date', 'data-testid': 'expiryDate', required: true, value: batch?.expiryDate,
      placeholder: 'Enter Expire Date', onChange: handleChange,
      error: expiryDateError, helperText: 'Expiry date is required', secured: false, show: true
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, secured, placeholder, onChange, type, readOnly
    } = field;
    const value = batch[fieldName];
    if ([
      'supplierId'
    ].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        // loading={isLoading}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={readOnly}
      />
    );
  };

  return (
    <>
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title="New Batch Added!"
        desc="Hi Pharm, Your new batch has been successfully added"
        option="ok"
        refetch={refetch}
      />
      <DialogContainer
        open={open}
        data-testid="singleBatchDialog"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <CloseStyled>
            <Close
              fontSize="small"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </CloseStyled>
          <HeaderStyled>
            <DialogTitleStyled>{brandName}</DialogTitleStyled>
          </HeaderStyled>
          <Grid container spacing={2}>
            {topFields.map((field) => (
              <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
          <FooterStyled>
            <ButtonOutlineStyled onClick={handleClose}>
              Cancel
            </ButtonOutlineStyled>
            <ButtonDarkStyled data-testid="batch_button" disabled={isLoading} onClick={handleBatch}>
              {
                isLoading ? (
                  <CircularProgress
                    disableShrink
                    size={18}
                    thickness={4}
                    style={{ color: '#fff' }}
                  />
                )
                  : batchToEdit ? 'Edit batch' : 'Add batch'
              }
            </ButtonDarkStyled>
          </FooterStyled>
        </DialogContentStyled>
      </DialogContainer>
      <SearchPopper
        open={openPopper}
        anchorEl={textRef.current}
        handleClose={() => setOpenPopper(false)}
        searchedSuppliers={searchedSuppliers}
        handleSetSupplier={handleSetSupplier}
      />
    </>
  );
};

SingleBatchDialog.propTypes = {
  brandName: PropTypes.string,
  productId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  refetch: PropTypes.func.isRequired,
  batchToEdit: PropTypes.instanceOf(Object).isRequired,
};

SingleBatchDialog.defaultProps = {
  brandName: '',
  productId: '',
  onClose: () => { },
};

export default SingleBatchDialog;
