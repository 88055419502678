import { useSearchParams } from 'react-router-dom';

const useSearchParamsState = (searchParamName, defaultValue) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState) => {
    const next = {
      ...[...searchParams.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      [searchParamName]: newState
    };
    setSearchParams((params) => ({ ...params, ...next }));
  };
  return [searchParamsState, setSearchParamsState];
};

export default useSearchParamsState;
