import React from 'react';
import styled, { css } from 'styled-components';
import {
  Grid, Divider, Typography, CircularProgress, Button, TextField
} from '@mui/material';

const getSelectedStyles = () => css`
  color: #FCED50;
`;

export const HeaderContainer = styled(Grid)`
  padding-bottom: 5vw;
  justify-content: space-between;
  font-size: 1.6rem;

  @media (min-width: 992px) {
    padding-bottom: 1.5vw;
    font-size: .875rem;
  }
`;
export const LoginTypoGrid = styled(Grid)`

`;
export const EmailGrid = styled(({ selected, ...extraProps }) => (
  <div {...extraProps} />
))`
  padding: 0 2vw 1vw;
  cursor: pointer;
  ${({ selected }) => selected && getSelectedStyles}
`;
export const PhoneGrid = styled(({ selected, ...extraProps }) => (
  <div {...extraProps} />
))`
  padding: 0 2vw 1vw;
  cursor: pointer;
  ${({ selected }) => selected && getSelectedStyles}
`;
export const ForgotContainer = styled(Grid)`
  padding-bottom: 2vw;
  text-align: right;
  margin-top: -20px;

  @media (min-width: 992px) {
    padding-bottom: 25px;
  }
`;

export const ForgotTypo = styled(Typography)`
  font-size: 1.7rem;
  color: #24292E;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: .75rem;
  }
`;

export const ForgotButton = styled(Button)`
paddng: 0px;

`;

export const StyledDivider = styled(Divider)`
  background-color: #636363;
  margin: 3rem 0;

  @media (min-width: 992px) {
    margin: 2rem 0;
  }
`;

export const HaveAccount = styled(Typography)`
  padding-top: 1.5rem;
  text-align: center;

  @media (max-width: 991px) {
    padding-top: 2.5rem;
  }
`;

export const HaveAccountTypo = styled(Typography)`
  padding-top: 1rem;
  font-weight: 600;
  font-size: 2rem;

  @media (min-width: 992px) {
    font-size: .875rem;
  }
`;

export const InputContainer = styled('div')`
  margin-bottom: 1vw;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #FFFFFF;
  margin: 1px;
`;

export const CustomField = styled(TextField)`
border-radius: 10px;


  & .MuiOutlinedInput-root {
    background-color: #FFFFFF;
    border-radius: 10px;
    border-color: #235A91;
    border: 0.5px solid  #235A91;

    &fieldset: {
      border-color: #235A91;
    },
    &:hover fieldset: {
      border-color: #235A91;
    },
    &.Mui-focused fieldse: {
      border-color: #235A91;
    },
  }
  & .MuiInputBase-input {
    height: 5rem;
    font-size: 1rem;
  }
  @media (min-width: 992px) {
    & .MuiInputBase-input {
      height: 2rem;
    }
  }

  @media(max-width: 991px) {
    & .MuiInputBase-input {
      font-size: 3rem;
    }

  }
`;

export const InputWrapper = styled('div')`
  padding: 0px;
`;

export const Label = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  margin-bottom: .4rem;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;
