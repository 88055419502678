import {
  Grid, Typography, Icon, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainWrapper = styled(Grid)`
  position: relative;
`;

export const HeaderWrapper = styled(Grid)`
  margin: 5px 5px;
`;

export const HeaderText = styled(Typography)`
  font-size: 10px;
`;

export const CropContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 28vh;
  background: #fff;
`;

export const ControlsContainer = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 14vh;
  right: 3px;
  background: #cacaca;
  padding: 6px;
  height: 3.5rem;
`;

export const CustomIconButton = styled(IconButton)`
  padding: 0;
`;

export const CustomIcon = styled(Icon)`
  color: #424242;
  font-size: 13px;
`;

export const CheckIcon = styled(Icon)`
  color: #424242;
  font-size: 18px;
`;

export const CheckButton = styled(IconButton)`
  cursor: pointer;
  color: #A3A3A3;
  background: #FCED50;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  top: 8vh;
  right: 18vw;
  padding: 5px;

  &:hover {
    background: #E2D444;
  }
`;

export const ChooseAnother = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ChooseAnotherImg = styled('img')`
  width: .5rem;
`;

export const ChooseAnotherText = styled(Typography)`
  text-decoration: underline;
  font-size: 0.65rem;
  color: #7B7B7B;
  margin-left: 5px;
`;
