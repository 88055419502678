import {
  Typography, MobileStepper, Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CMobileStepper = styled(MobileStepper)`
  max-width: 100vw;
  flex-grow: 1;
  padding: 20px 1px 10px;

  & .MuiMobileStepper-progress {
    width: 100%;
    height: 10px;
    border-radius: 20px;
    background-color: #C5E2FF;
  }
  & .MuiLinearProgress-barColorPrimary {
    background-color: #235A91;
  }
`;

export const StepperTypo = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
`;

export const SlimCheckbox = styled(Checkbox)`
  padding: 0;
  margin-left: 5px;
  color: #AEAEB2;
  cursor: default;
`;
