import React from 'react';
import {
  Dialog, Slide, List, ListItem, ListItemText, ListItemSecondaryAction,
  ListItemAvatar, Divider, Grid, Typography
} from '@mui/material';

import productPlaceholder from '../../../assets/images/products/productPlaceholder.png';
import {
  CDialogTitle, DialogHeaderText, CDialogContent, ProdAvatar, CloseIcon
} from './holdSaleDialog.styles';
import { RetrieveIcon } from '../../../assets/svgs';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';

import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const SalesOnHoldDialog = () => {
  const [{
    sale: { salesOnHold, openSalesOnHoldDialog }
  }, dispatch] = Object.values(useStateValue());

  const handleDialogClose = () => {
    dispatch({
      type: SaleActionTypes.UPDATE_SALE_STATE,
      payload: { openSalesOnHoldDialog: false }
    });
  };

  const handleReturnSaleToCart = (cart, note) => {
    const newHold = salesOnHold.filter((sale) => sale.note !== note);
    dispatch({
      type: SaleActionTypes.RETURN_HELD_SALE,
      payload: {
        cart,
        salesOnHold: newHold
      }
    });
    handleDialogClose();
  };

  return (
    <Dialog
      open={openSalesOnHoldDialog}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      onClose={handleDialogClose}
    >
      <CDialogTitle item container>
        <DialogHeaderText>Sales on Hold</DialogHeaderText>
        <CloseIcon onClick={handleDialogClose} />
      </CDialogTitle>
      <CDialogContent>
        <Grid container>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <List style={{ paddingTop: 0 }}>
              {salesOnHold?.map(({ note, cart }) => (
                <React.Fragment key={note}>
                  <ListItem
                    alignItems="flex-start"
                    button
                    onClick={() => handleReturnSaleToCart(cart, note)}
                  >
                    <ListItemAvatar>
                      <ProdAvatar src={productPlaceholder} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <Grid container item direction="column">
                          {Array.from(cart).slice(0, 3).map((c) => {
                            const { productId, brandName, quantity } = c[1];
                            return (
                              <Typography key={productId} variant="caption">
                                {`${quantity} x ${brandName}`}
                              </Typography>
                            );
                          })}
                          <Typography>{cart.size > 3 && '...'}</Typography>
                        </Grid>
                      )}
                      secondary={(
                        <Grid item container style={{ marginTop: '.5rem' }}>
                          <Typography variant="caption" inline>
                            {`Note: ${note}`}
                          </Typography>
                        </Grid>
                      )}
                      primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                      secondaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                    />
                    <ListItemSecondaryAction>
                      <RetrieveIcon
                        edge="end"
                        onClick={() => handleReturnSaleToCart(cart, note)}
                        style={{ color: '#878205', cursor: 'pointer' }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      </CDialogContent>
    </Dialog>
  );
};

export default SalesOnHoldDialog;
