import React, { useEffect } from 'react';
import NewOrder from './newOrder';
import {
  OrderGridContainer, LogoContainer, ProductsBox, ContentBox, Header, MpLogo, InfoWrapper, HeaderSubText
} from './marketOrderContainer.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import FooterComponents from '../../../navigation/Footer';

const NonAuthMarketOrderContainer = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, [dispatch]);

  return (
    <ContentBox data-testid="nonAuthOrderspage">
      <OrderGridContainer container>
        <LogoContainer container><MpLogo /></LogoContainer>
        <InfoWrapper>
          <Header>
            Our Top Selling Products
          </Header>
          <HeaderSubText>
            We&apos;ve curated a selection of the finest pharmaceutical products that are flying off the shelves!
            Our Top Selling Products are not just popular; they&apos;re a testament to our commitment to quality, safety, and your satisfaction.
          </HeaderSubText>
        </InfoWrapper>
        <ProductsBox>

          <NewOrder />
        </ProductsBox>
      </OrderGridContainer>
      <FooterComponents nonAuthPage="NonAuthPage" />
    </ContentBox>
  );
};

export default NonAuthMarketOrderContainer;
