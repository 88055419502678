import { IconButton, Typography, DialogContentText } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleText = styled(Typography)`
  font-size: 2.25rem;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: 1.25rem;
  }
`;

export const ContentText = styled(DialogContentText)`
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  color: #424242;
  border-radius: 0;
  border-radius: 10px;
  margin-right: 7px;
  border: 1px solid #eee;
  font-size: 2.5rem;

  @media (min-width: 992px) {
    font-size: 14px;
  }
`;

export const DialogOkButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border-radius: 5px;
  margin-right: 7px;
  background-color: #FCED50;
  color: #424242;
  font-size: 2.5rem;

  @media (min-width: 992px) {
    font-size: 14px;
  }

  &:hover {
    background-color: #D2C647;
  }
`;
