import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useStateValue } from '../../../providers/stateProvider';
import CustomSearchField from '../../shared/CustomSearchField';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import {
  DateWrapper,
  StatusesGrid,
} from './filter.styles';

const Filter = ({
  state, handleSubmit, handleSearch
}) => {
  const { searchText } = state ?? '';
  const [{ navbar: { openSideDrawer } }] = Object.values(useStateValue());
  return (
    <Grid item container xs={12}>
      <StatusesGrid container item drawer={openSideDrawer}>
        <CustomSearchField
          name="search"
          value={searchText}
          fullWidth
          placeholder="Search"
          handleChange={(e) => handleSearch(e?.target?.value)}
        />
      </StatusesGrid>
      <DateWrapper item drawer={openSideDrawer}>
        <CustomDateRanges
          handleSubmit={handleSubmit}
          xs={12}
          styles={{ height: '2.938rem' }}
        />
      </DateWrapper>
    </Grid>
  );
};

Filter.propTypes = {
  state: PropTypes.shape(Object),
  handleSubmit: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  state: {}
};

export default Filter;
