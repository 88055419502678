import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import {
  BodyCell, MainTableRow, CustomInputNumberBase, Label
} from './returnRow.styles';
import { CaretDownIcon, CaretUpIcon, DeleteIcon } from '../../../assets/svgs';
import CustomInputBase from '../../customComponents/customInputBase';

const ReturnRow = ({
  row, loading, handleSearch, counterHandler, index, removeRowHandler, isDelete
}) => {
  const {
    sn, productName, productPosition
  } = row;
  return (
    <MainTableRow container>
      <BodyCell>
        <Label>Product</Label>
        <CustomInputBase
          value={productName}
          variant="filled"
          size="small"
          step={0.01}
          onChange={(e) => handleSearch(e, sn)}
          style={{ width: '100%' }}
          loading={loading}
        />
      </BodyCell>
      <BodyCell>
        <Label>Position</Label>
        <CustomInputNumberBase
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          required
          value={productPosition}
          onChange={(e) => counterHandler('change', row, e.target.value)}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => counterHandler('increment', row, 0)}
                />
                <CaretDownIcon
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => counterHandler('decrement', row, 0)}
                />
              </Grid>
            </InputAdornment>
          )}
        />
      </BodyCell>
      {index !== 0 ? (
        <BodyCell>
          <DeleteIcon data-testid="deleteRow" style={{ cursor: 'pointer', marginTop: '27px', pointerEvents: isDelete ? 'none' : '' }} onClick={() => removeRowHandler(sn)} />
        </BodyCell>
      ) : ''}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  handleSearch: PropTypes.func,
  counterHandler: PropTypes.func,
  removeRowHandler: PropTypes.func,
  index: PropTypes.string.isRequired,
  isDelete: PropTypes.bool,
};

ReturnRow.defaultProps = {
  loading: false,
  handleSearch: () => null,
  counterHandler: () => null,
  removeRowHandler: () => null,
  isDelete: false
};

export default ReturnRow;
