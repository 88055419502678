import React from 'react';
import MapCsvDialog from './mapCsvDialog';

function MapSupplierCsvDialog() {
  const CSV_FIELDS = [
    ['Batch No', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,required'
    }],
    ['Product Brand Name', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false,
      rules: 'str,required'
    }],
    ['Pack Size', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false
    }],
    ['Supplier', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false
    }],
    ['Order Cost', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false,
      rules: 'str,required,money'
    }],
    ['Quantity', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'int,required'
    }],
    ['Date Received', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,required,date'
    }],
    ['Expiry Date', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,required,date'
    }],
    ['No Duplicate Batch No(Yes/No)', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,required'
    }]
  ];

  return (
    <MapCsvDialog
      fields={CSV_FIELDS}
      model="Batches"
      endpoint="inventory"
      backRoute="products"
    />
  );
}

export default MapSupplierCsvDialog;
