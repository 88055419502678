import NotificationsActionTypes from './notificationsTypes';

const notificationsReducer = (notification, action) => {
  switch (action.type) {
    case NotificationsActionTypes.UPDATE_NOTIFICATION_COUNT:
      return {
        ...notification,
        ...action.payload
      };
    default:
      return notification;
  }
};

export default notificationsReducer;
