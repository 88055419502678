import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Grid, TableBody, Paper, Table
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import {
  MenuButtonStyled, PageTitleText, PageTitleSubText, PageTitleContainer, BackRouteText,
  CartGridContainerHeader, TableComponent
} from '../../cart/cart.styles';
import { TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import {
  KeyboardArrowLeft, TableContainerWrapper, MainTableRow, TableCellStyled,
  FilterGrid, PaperWrapper, TCustomButton
} from './supplierOrders.styles';
import { UPDATED_UNRECONCILED_MANUAL_SUPPLIER_ORDERS } from '../../../queries/orders';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { OrderGridContainer } from '../admin/order.styles';
import CustomSearchField from '../../shared/CustomSearchField';
import ReturnRow from './returnRow';
import MainContent from '../../customComponents/mainContent';
import TablePagination from '../../shared/tablePagination';
import { useStateValue } from '../../../providers/stateProvider';

const SupplierOrdersContainer = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [search, setSearch] = useState('');
  const [{ navbar: { openSideDrawer } }] = Object.values(useStateValue());
  const navigate = useNavigate();

  const headers = ['Date', 'Suppliers', 'PO Number', 'Parent Id', 'Amount of Products', 'Total Order Value'];
  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const dateDefault = moment(moment().startOf('day')).format('YYYY-MM-DD');

  const [dateFilter, setDateFilter] = useState({
    dateFrom: dateDefault,
    dateTo: dateDefault
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD')
    });
  };

  const { loading, data } = useQuery(UPDATED_UNRECONCILED_MANUAL_SUPPLIER_ORDERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageNumber,
      pageCount,
      dateFrom: dateFilter?.dateFrom,
      dateTo: dateFilter?.dateTo
    }
  });

  const products = data?.unreconciledManualSupplierOrders;

  return (
    <MainContent>
      <OrderGridContainer container>
        <Grid container item alignItems="center">
          <MenuButtonStyled onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
          </MenuButtonStyled>
          <BackRouteText variant="caption">back</BackRouteText>
        </Grid>
        <CartGridContainerHeader item container>
          <Grid container xs={12} md={10} lg={10} alignItems="center" justify-content="space-between">
            <PageTitleContainer>
              <PageTitleText>Open Orders</PageTitleText>
              <PageTitleSubText>Order breakdown for affiliates by suppliers</PageTitleSubText>
            </PageTitleContainer>
          </Grid>
        </CartGridContainerHeader>
        <PaperWrapper elevation={0}>
          <FilterGrid item container spacing={1}>
            <Grid item container xs={openSideDrawer ? 7.5 : 8}>
              <CustomSearchField
                name="search"
                value={search}
                fullWidth
                placeholder="Search"
                handleChange={(e) => setSearch(e?.target?.value)}
              />
            </Grid>
            <Grid item container xs={openSideDrawer ? 2.5 : 2}>
              <CustomDateRanges
                handleSubmit={handleSubmit}
                xs={12}
                styles={{
                  height: '47px', width: openSideDrawer ? '13.8rem' : '13.5rem', fontSize: '10.5px', border: '1px solid #bababa',
                }}
              />
            </Grid>
            <Grid item container xs={2}>
              <TCustomButton type="tertiary" header> Download CSV </TCustomButton>
            </Grid>
          </FilterGrid>
          <TableContainerWrapper component={Paper}>
            {loading ? <ProductsTableLoader />
              : (
                <TableComponent aria-label="affiliates table">
                  <Table>
                    <TableColumnHeaders>
                      {returnHeaders()}
                    </TableColumnHeaders>
                    <TableBody>
                      {
                        products.length
                          ? products?.map((item) => <ReturnRow row={item} key={item?.id} />)
                          : (
                            <MainTableRow>
                              <TableCellStyled colSpan={6} style={{ textAlign: 'center' }}>No record found</TableCellStyled>
                            </MainTableRow>
                          )
                      }
                    </TableBody>
                  </Table>
                </TableComponent>
              )}
            {products?.length > 0 && (
              <TablePagination
                total={data?.supplierOrderTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </TableContainerWrapper>
        </PaperWrapper>
      </OrderGridContainer>
    </MainContent>
  );
};

export default SupplierOrdersContainer;
