import db from './db';

export const addSale = async (params) => {
  db.open();
  return db.table('sales').add(params);
};

export const updateSale = async (key, data) => db.sales
  .update(key, data);

export async function getSale(key) {
  return db.sales.get(key);
}

export const allSales = async () => db.table('sales').toArray();

// saleItem
export const addSaleItem = async (params) => {
  db.open();
  return db.table('saleItems').add(params);
};

export const allSaleItems = async () => db.table('saleItems').toArray();

export async function filterSaleItems(saleKey, productKey) {
  return db.saleItems.where('[saleId+productId]')
    .equals([saleKey, productKey])
    .toArray();
}

export async function saleItemsBySaleId(saleKey) {
  return db.saleItems.where('saleId')
    .equals(saleKey)
    .toArray();
}

// saleItemBatch
export const addSaleItemBatch = async (params) => {
  db.open();
  return db.table('saleItemBatch').add(params);
};

// rawSaleData
export const addRawSaleData = async (params) => {
  db.open();
  return db.table('rawSaleData').add(params);
};

export const getAllRawSaleData = async () => db.table('rawSaleData').toArray();

export const getRawSaleData = (key) => db.rawSaleData.get(key);

export async function getOrphanData(key) {
  return db.rawSaleData.where('id').above(key).toArray();
}

// Hooks
function updateSaleCallback(primKey, obj,) {
  db.transaction('rw', db.sales, () => db.sales.get(obj.saleId).then((sale) => {
    let total = 0;
    if (sale.total) {
      total = sale.total + obj.total;
    } else total = obj.total;
    return db.sales.update(obj.saleId, { total });
  }));
}

db.saleItems.hook('creating', updateSaleCallback);

export default db;
