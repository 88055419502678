import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, TableRow, Table, TableHead
} from '@mui/material';
import _ from 'lodash';
import ReturningProductItem from './returningProductItem';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import {
  SupplierProductsCardHeader, SupplierProductsCardHeaderText, SupplierCardPaper
} from '../../cart/cart.styles';
import {
  ReturnProductFooterGrid, CustomCheckbox, PaperWrapper,
  ReturnProductsCardFooterText, TableGrid, TableBox, TableContent, MainTableBody,
  HeaderCell, CustomTableCheckbox
} from './returns.styles';

const Returns = ({
  state, handleChangeReason, handleIncrement, handleDecrement, handleChange, handleBatchUpdate,
  handleChangeProductQuantity, disableField, handleBatchDialog, reviewOrder, handleSelectedId
}) => {
  const [selected, setSelected] = useState([]);

  const handleSelect = (__, id) => {
    const newSelected = [...selected];
    const idx = newSelected.indexOf(id);
    if (newSelected.includes(id)) newSelected.splice(idx, 1);
    else newSelected.push(id);
    setSelected(newSelected);
  };

  const renderCheckbox = (id) => (
    <CustomCheckbox
      size="small"
      checked={selected.includes(id)}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );
  const renderSupplierContainers = () => {
    const { cart } = state;

    const supplierCategories = _.groupBy(cart, 'supplier');

    return Object.entries(supplierCategories).map(([key, value]) => (
      <SupplierCardPaper elevation={2} key={value}>
        <SupplierProductsCardHeader>
          <SupplierProductsCardHeaderText>
            Supplier:
            &nbsp;
            {key.split(' ')[0]}
          </SupplierProductsCardHeaderText>
        </SupplierProductsCardHeader>
        {value.filter((p) => p.quantity > 0).map((productItem) => (
          <ReturningProductItem
            product={productItem}
            selectedOulet={state.selectedOutlet}
            handleReasonChange={handleChangeReason}
            incrementProductQuantity={handleIncrement}
            decrementProductQuantity={handleDecrement}
            changeInputQuantity={handleChangeProductQuantity}
            key={productItem}
          />
        ))}
        <ReturnProductFooterGrid container>
          <ReturnProductsCardFooterText>
            {`${value.length} ${value.length > 1 ? 'Items' : 'Item'}`}
          </ReturnProductsCardFooterText>
          <ReturnProductsCardFooterText>
            { renderCheckbox(key) }
            Return all items from
            &nbsp;
            {key.split(' ')[0]}
          </ReturnProductsCardFooterText>
        </ReturnProductFooterGrid>
      </SupplierCardPaper>
    ));
  };

  useEffect(() => localStorage.setItem('selectedSupplier', JSON.stringify(selected)));
  const loading = false;
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = state.cart.map((product) => product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const renderSelectAllCheckbox = () => (
    <CustomTableCheckbox
      size="small"
      checked={selected.length === state.cart.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select affiliate' }}
    />
  );
  const headers = [
    renderSelectAllCheckbox(), 'Product Item', 'Qty Delivered', 'Qty Returned', 'Reasons', !disableField && 'Action'
  ];

  return (
    <>
      <PaperWrapper elevation={2}>
        <TableGrid item container>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableBox>
                <TableContent component={Paper}>
                  <Table aria-label="users table">
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <HeaderCell>{header}</HeaderCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <MainTableBody item container>
                      {state.cart.map((business, index) => (
                        <ReturnRow
                          key={business.id}
                          index={index}
                          row={business}
                          selected={selected}
                          handleSelect={handleSelect}
                          handleReasonChange={handleChangeReason}
                          incrementProductQuantity={handleIncrement}
                          decrementProductQuantity={handleDecrement}
                          handleChangeProductQuantity={handleChangeProductQuantity}
                          changeInputQuantity={handleChangeProductQuantity}
                          disableField={disableField}
                          editReturn={state.editReturn}
                          handleBatchDialog={handleBatchDialog}
                          reviewOrder={reviewOrder}
                          handleSelectedId={handleSelectedId}
                          handleChange={handleChange}
                          orderIsReturned={state.orderIsReturned}
                          handleBatchUpdate={handleBatchUpdate}
                        />
                      ))}
                    </MainTableBody>
                  </Table>
                </TableContent>
              </TableBox>
            )}
        </TableGrid>
      </PaperWrapper>

      {/* <Hidden mdDown>
        <ReturningProductColumnHeaders>
          <ProductColumnHeaderTitle style={{ width: '490px' }}>
            Product Item
          </ProductColumnHeaderTitle>
          <ProductColumnHeaderTitle style={{ width: '304px' }}>
            Qty Delivered
          </ProductColumnHeaderTitle>
          <ProductColumnHeaderTitle style={{ width: '304px' }}>
            Qty Returned
          </ProductColumnHeaderTitle>
          <ProductColumnHeaderTitle style={{ width: '165px' }}>
            Reason
          </ProductColumnHeaderTitle>
        </ReturningProductColumnHeaders>
      </Hidden> */}
      {/* {renderSupplierContainers()} */}
    </>
  );
};

Returns.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  handleChangeReason: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleChangeProductQuantity: PropTypes.func.isRequired,
  disableField: PropTypes.bool.isRequired,
  handleBatchDialog: PropTypes.bool.isRequired,
  reviewOrder: PropTypes.bool,
  handleSelectedId: PropTypes.func.isRequired,
};

Returns.defaultProps = {
  reviewOrder: false
};

export default Returns;
