import {
  Grid,
  MenuItem,
  Select
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import UnCheckboxIcon from '../../../assets/images/supplier/Checkbox-resting.png';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import {
  CheckboxImage,
  FieldCheckbox,
  FormField,
  SelectNameTypo
} from './returnSelectField.styles';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ReturnSelectField = ({
  field, value, handleChange, cart
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected(event.target.value);
    handleChange(event);
  };

  return (
    <FormField cart={cart} variant="filled" size="small" ref={selectRef}>
      {/* <InputLabel id="select-label">{field.label}</InputLabel> */}
      <Select
        labelId="select-filled-label"
        id="select-filled"
        value={value}
        renderValue={(val) => val}
        onChange={handleInput}
        inputProps={{
          name: field.name
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {field.options.map(
          (option) => (
            <MenuItem key={option} value={option}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <SelectNameTypo>{option}</SelectNameTypo>
                <FieldCheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected.indexOf(option) > -1}
                />
              </Grid>
            </MenuItem>
          )
        )}
      </Select>
    </FormField>
  );
};

ReturnSelectField.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  cart: PropTypes.bool
};

ReturnSelectField.defaultProps = {
  field: {},
  value: '',
  cart: false
};

export default ReturnSelectField;
