import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useState } from 'react';

import { GET_PLAIN_SUB_BUSINESSES } from '../../../queries/affiliates';
import { JSONParse } from '../../../utils/json';
import CTablePagination from '../../customComponents/cTablePagination';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import GoToTop from '../../customComponents/scrollToTop';
import SubBusinessCard from './subBusinessCard';
import {
  FooterGrid,
  OutletItem,
  OutletItems,
  OutletWrapper
} from './subBusinesses.styles';

export default function SubBusinessesMainContent() {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
  };
  const [state, setState] = useState(initialState);
  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };
  const {
    loading, data
  } = useQuery(GET_PLAIN_SUB_BUSINESSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageCount: state.currentPageCount,
      pageNumber: state.currentPage,
    },
  });
  if (loading) return <NewOrdersLoader />;
  const { subBusinesses, businessTotalNumber } = data || {};
  return (
    <OutletWrapper>
      <OutletItems>
        {
          subBusinesses.map((business) => {
            const {
              id
            } = business;
            const {
              name, city, country, business_type: type, date_launched: dateLaunched
            } = business.meta ? JSONParse(business.meta) : {
              name: '', city: '', country: '', business_type: '', date_launched: ''
            };

            return (
              <OutletItem key={business.id}>
                <SubBusinessCard
                  id={`0${id}`}
                  name={name}
                  location={`${city}, ${country}`}
                  type={type}
                  launched={moment(dateLaunched).format('Do MMMM YYYY')}
                />
              </OutletItem>
            );
          })
        }
      </OutletItems>
      <FooterGrid item container>

        <CTablePagination
          rowsPerPageOptions={[20, 50, 100, 150, 200]}
          component="div"
          count={businessTotalNumber}
          page={state.currentPage - 1}
          onChangePage={handleChangePage}
          rowsPerPage={state.currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
      {
        (businessTotalNumber.length > 5) && <GoToTop />
      }
    </OutletWrapper>
  );
}
