import {
  Button,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const TableGrid = styled(Grid)`
  margin: 0.5rem 0 1.5rem;
  overflow-x: auto;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 50px 5rem;
`;

export const MainTableHead = styled(TableHead)``;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 600;
  line-height: 26.27px;
  white-space: nowrap;
  background: #f3f9ff;
  padding: 14px 10px;
  border: 0;
  /**text-transform: capitalize;**/

  @media (min-width: 992px) {
    font-size: 16px;
  }
  &:nth-child(1) {
    text-align: center;
    min-width: 70px;

  }
`;

export const ButtontGrid = styled(Grid)`
  justify-content: space-between;
  margin: 10px 10px 5px 10px;
  @media (min-width: 992px) {
    margin: 3rem 5px 5px;
  }
`;

export const ButtonWrapper = styled(Button)`
  background-color: #000000;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 0.9rem;
  &.MuiButton-text:hover {
    background-color: #424242;
  }
`;

export const ButtonText = styled(Typography)`
  color: #ffffff;
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #24292E;

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 20px 50px 100px;
  align-items: center;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: center;
  z-index: 10;
`;

export const GoBack = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  color: #000000;
  padding: 0.5rem 0;
  margin-bottom: -1rem;
  font-weight: 500;
`;

export const HeaderTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 600;
  color: #235A91;
  padding: 10px 0px 15px 0px;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;
