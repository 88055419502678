import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@mui/material';
import { useQuery } from '@apollo/client';
import CustomNewTextField from '../../shared/CustomNewTextField';
import CustomButton from '../../customComponents/customButton';
import {
  DatePicker, FiltersGrid
} from './affiliateFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import MobileFilter from './mobileFilter';
import { useStateValue } from '../../../providers/stateProvider';
import { BUSINESS_CATEGORIES } from '../../../queries/categories';

const AffiliateFilter = ({
  state, handleFilterChange, handleSetFilter, handleDateFilter, mobile, handleMobile,
  handleClear
}) => {
  const status = [
    { name: 'All', value: '' },
    { name: 'Verified', value: 'VERIFIED' },
    { name: 'Unverified', value: 'UNVERIFIED' },
    { name: 'Suspended', value: 'SUSPENDED' },
  ];

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const [searchValue,] = useState('');
  const [pageCount,] = useState(20);
  const [pageNumber,] = useState(1);

  const variables = {
    pageCount,
    pageNumber,
    search: searchValue
  };

  const { data } = useQuery(BUSINESS_CATEGORIES, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const category = data?.businessCategories?.map((item) => {
    const { id, categoryName } = item;
    return { name: categoryName, value: id };
  }) || [];

  const allCategory = [{ name: 'All Categories', value: 0 }];
  return (
    <>
      {!mobile && (
        <Grid container xs={12}>
          <FiltersGrid container item justifyContent="flex-end">
            <DatePicker>
              <CustomDateRanges
                handleSubmit={handleDateFilter}
                xs={12}
                styles={{ height: '3.2rem', width: '100%' }}
              />
            </DatePicker>
            <CustomNewTextField
              field={{ name: 'affiliateName', label: 'Affiliate Name' }}
              state={state}
              handleChange={handleFilterChange}
              className="mp-uat-affiliateName-filter"
            />
            <CustomNewTextField
              field={{ name: 'category', label: 'Customer Category' }}
              state={state}
              select
              handleChange={handleFilterChange}
              style={{ width: '11rem' }}
              maxHeight="30rem"
            >
              {allCategory.map(({ name, value }) => (
                <MenuItem key={value} value={value} style={{ padding: '1rem 0.5rem' }}>
                  {name}
                </MenuItem>
              ))}

              {category.map(({ name, value }) => (
                <MenuItem key={value} value={value} style={{ padding: '1rem 0.5rem' }}>
                  {name}
                </MenuItem>
              ))}
            </CustomNewTextField>
            <CustomNewTextField
              field={{ name: 'phoneNumber', label: 'Phone Number' }}
              state={state}
              handleChange={handleFilterChange}
              className="mp-uat-phoneNumber-filter"
            />
            <CustomNewTextField
              field={{ name: 'email', label: 'Email Address' }}
              state={state}
              handleChange={handleFilterChange}
              className="mp-uat-email-filter"
            />
            <CustomNewTextField
              field={{ name: 'status', label: 'Status' }}
              state={state}
              select
              handleChange={handleFilterChange}
              style={{ width: '10rem' }}
              className="mp-uat-status-filter"
            >
              {status.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </CustomNewTextField>
            <CustomButton
              style={{
                width: '6.5rem', height: '2.95rem', fontWeight: '700', fontSize: '0.75rem'
              }}
              onClick={handleSetFilter}
              disabled={!userPermissions?.includes('manage_affiliates_set_filter')}
              className="mp-uat-set-filter"
            >
              Set Filter
            </CustomButton>
          </FiltersGrid>
        </Grid>
      )}
      {mobile && (
        <MobileFilter
          state={state}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleDateFilter={handleDateFilter}
          handleMobile={handleMobile}
          handleClear={handleClear}
        />
      )}
    </>
  );
};

AffiliateFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  handleDateFilter: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  handleMobile: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired
};

AffiliateFilter.defaultProps = {
  state: {},
};

export default AffiliateFilter;
