import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import DropZone from '../../shared/logoDropzone';
import ReturnSelectField from './returnSelectField';
import CropImageContainer from '../../shared/logoProcess/cropImageContainer';
import InitialImage from '../../shared/logoProcess/initialImage';
import SavePopper from './savePopper';
import CreditDaysPopper from './creditDaysPopper';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader, UploadGrid,
  GridWrapper, MiddleGrid, SupplierTextField
} from './individualTherapeuticTypeDialog.styles';
import {
  CREATE_THERAPEUTIC_TYPE, UPDATE_THERAPEUTIC_TYPE
} from '../../../mutations/therapeuticType';
import { validateEmail } from '../../auth/utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualTherapeuticTypeDialog = ({
  dialogOpen, closeDialog, edit, refetch, therapeuticTypeName
}) => {
  const initialState = {
    therapeuticTypeName: therapeuticTypeName || '',
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
  };

  const { id } = useParams();
  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [creditDays, setCreditDays] = useState(14);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [creditDaysEl, setCreditDaysEl] = useState(false);
  const [editing, setEditing] = useState(edit);
  const [showInitialImage, setShowInitialImage] = useState(false);

  const [createTherapeuticType] = useMutation(CREATE_THERAPEUTIC_TYPE);
  const [updateTherapeuticType] = useMutation(UPDATE_THERAPEUTIC_TYPE);

  const { nameError } = errorState;

  const handleCloseDialog = () => {
    setEditing(true);
    setState(initialState);
    refetch();
    setFinalImage(null);
    closeDialog();
  };

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleChangeInitialImage = () => {
    setShowInitialImage(false);
  };

  const handleDeleteInitialImage = () => {
    setShowInitialImage(false);
    setState({ ...state, logo: '' });
  };

  const handleCreditDaysOpen = (el) => {
    setCreditDaysEl(creditDaysEl ? null : el);
  };

  const handleCreditDays = (days) => {
    setCreditDays(days);
    handleCreditDaysOpen(null);
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.SUPPLIER_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const addSupplier = (logo, addMore) => {
    const { therapeuticTypeName: name } = state;

    createTherapeuticType({
      variables: {
        therapeuticType: name,
      }
    })
      .then(({ data }) => {
        const { message } = data?.createTherapeuticType || {};
        toast.success(message);
        refetch();
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editSupplier = () => {
    const {
      therapeuticTypeName: name
    } = state;

    updateTherapeuticType({
      variables: {
        therapeuticTypeId: id,
        therapeuticType: name,
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateTherapeuticType || {};
        toast.success(message);
        refetch();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png';
    const alreadyUploadedImage = state.logo || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    if (editing) return editSupplier(logo);
    return addSupplier(logo, addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
    setFiles(null);
    setFinalImage(null);
  };

  const handleSaveButtonClick = (event) => {
    const { therapeuticTypeName: name } = state;
    if (name.length > 1 && !editing) {
      return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
    } if (name.length > 1 && editing) {
      return handleSave();
    }
    return setErrorState({
      ...errorState,
      nameError: true
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'therapeuticTypeName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'therapeuticTypeName',
      label: 'Product type Name',
      helperText: 'Product type name required'
    },
  ];

  const returnTextField = (field) => {
    const { name: fieldName, label, helperText } = field;
    const value = state[fieldName];
    const numberFields = ['mobile', 'markup'];
    if (['term', 'country', 'providerPromo'].includes(fieldName)) {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
          handleCreditDaysOpen={handleCreditDaysOpen}
          creditDays={creditDays}
        />
      );
    }
    return (
      <SupplierTextField
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={numberFields.includes(fieldName) ? 'number' : 'text'}
        error={fieldName === 'supplierName' ? nameError : false}
        helperText={nameError && helperText}
        name={fieldName}
        onChange={handleChange}
      />
    );
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
      >
        <Grid container>
          <GridWrapper container item xs={8}>
            <DialogTitle id="alert-dialog-slide-title">
              <DialogTitleWrapper container>
                <DialogTitleText>
                  {editing ? 'Edit Product type' : 'Add Product type' }
                </DialogTitleText>
                <DialogTitleSubText>
                  {editing
                    ? 'Edit the form to update Product type information'
                    : 'Add Product type information to the fields in the form'}
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>
            <DialogContent>
              <MiddleGrid item>
                {topFields.map((field) => returnTextField(field))}
              </MiddleGrid>
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    {editing ? 'Save Changes' : 'Save & ...'}
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
          <UploadGrid container item xs={4}>
            {showInitialImage ? (
              <InitialImage
                image={state.logo}
                handleImageChange={handleChangeInitialImage}
                handleImageDelete={handleDeleteInitialImage}
              />
            ) : (files && files.length ? (
              <CropImageContainer
                files={files}
                handleFile={handleFile}
                handleFinalImage={setFinalImage}
              />
            ) : (
              <DropZone
                handleFile={handleFile}
              />
            ))}
          </UploadGrid>

        </Grid>
      </Dialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />

      <CreditDaysPopper
        creditDays={creditDays}
        handleCreditDays={handleCreditDays}
        creditDaysEl={creditDaysEl}
        handleCreditDaysOpen={handleCreditDaysOpen}
      />
    </>
  );
};

IndividualTherapeuticTypeDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  refetch: PropTypes.func,
  therapeuticTypeName: PropTypes.string
};

IndividualTherapeuticTypeDialog.defaultProps = {
  edit: false,
  refetch: () => {},
  therapeuticTypeName: ''
};

export default IndividualTherapeuticTypeDialog;
