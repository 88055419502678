import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Hidden, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { validatePassword } from './utils';
import {
  CircularProgressLoader, OgaAdminLogo
} from './resetPassword.styles';

import { useStateValue } from '../../providers/stateProvider';
import { OgaToken } from '../../graphql/token';
import PasswordTextField from './passwordTextField';
import CustomButton from '../customComponents/customButton';
import {
  AuthGridContainer, FormWrapper, InnerGridContainer, InputsGridContainer, InputsHeaderTypo, InputsPaper, InputsPaperContainer,
  LogoGridContainer, LogoTypo, MpAuthImage, SubTypo, TypoDesc, TypoWrapper
} from './authContainer.styles';
import SuccessDialog from '../shared/newSuccessDialog';

const ResetPassword = () => {
  const initialPasswords = {
    password: '',
    confirmPassword: '',
  };

  const [passwordError, setPasswordError] = useState('');
  const [passwords, setPasswords] = useState(initialPasswords);
  const [validateState, setValidateState] = useState('');
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const [, dispatch] = Object.values(useStateValue());

  const { password, confirmPassword } = passwords;

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, [dispatch]);

  const { uidb64, token } = useParams();
  const navigate = useNavigate();

  const checkPasswords = () => (
    password !== confirmPassword
      ? 'Passwords do not match'
      : ''
  );

  useEffect(() => {
    const same = password && password === confirmPassword;
    setValidateState(!passwordError && same);
  }, [passwordError, password, confirmPassword]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'password') setPasswordError(validatePassword(value));
    setPasswords({
      ...passwords,
      [name]: value
    });
  };

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const handleSubmit = () => {
    setLoading(true);
    const link = OgaToken.APP_LINK.split('pharmiq')[0];
    const resetUrl = `${link}password_reset/${uidb64}/${token}`;

    const user = { password: confirmPassword };

    const config = { headers: { 'Content-Type': 'application/json' } };

    axios.put(resetUrl, { user }, config)
      .then((res) => {
        if (res.status === 200) {
          setPasswords(initialPasswords);
          toast.success(res.data.message);
          toggleSuccessModal();
        }
      }).catch(() => {
        toast.error('This verification link is corrupted or expired');
      }).finally(() => {
        setLoading(false);
      });
  };
  const handleClose = () => {
    toggleSuccessModal();
    setTimeout(() => navigate('/'), 3000);
  };

  const renderHelperText = (name) => {
    switch (name) {
      case 'password':
        return password && passwordError
          ? 'Should be atleast 8 characters. UPPERCASE, lowercase, letters & numbers'
          : '';
      case 'confirmPassword':
        return confirmPassword ? checkPasswords() : '';
      default:
        return '';
    }
  };

  const renderError = (name) => {
    switch (name) {
      case 'password':
        return password ? passwordError : false;
      case 'confirmPassword':
        return confirmPassword ? !!checkPasswords() : false;
      default:
        return false;
    }
  };

  const returnTextField = (label, name) => (
    <PasswordTextField
      name={name}
      label={label}
      password={passwords[name]}
      handleChange={handleChange}
      passwordError={renderError(name)}
      loading={loading}
      helperText={renderHelperText(name)}
    />
  );
  const isLarge = useMediaQuery('(min-width: 992px)');

  return (
    <AuthGridContainer container>
      <InnerGridContainer item container>
        <Hidden mdDown>
          <LogoGridContainer item container xs={12} md={6} lg={6}>
            <MpAuthImage />
            <TypoWrapper>
              <LogoTypo variant="h5">
                Quality Guaranteed
              </LogoTypo>
              <TypoDesc>
                Your reliable source for trusted pharmaceutical products
              </TypoDesc>
            </TypoWrapper>
          </LogoGridContainer>
        </Hidden>
        <InputsGridContainer item container xs={12} md={6} lg={6}>
          <InputsPaper item container>
            <InputsPaperContainer item>
              <OgaAdminLogo />
              <InputsHeaderTypo variant="h6">
                Create new password
              </InputsHeaderTypo>
              <SubTypo variant="h6">
                Please enter a new and unique password
              </SubTypo>
              <FormWrapper>
                <Grid container>
                  {returnTextField('New Password', 'password')}
                  {returnTextField('Confirm New Password', 'confirmPassword')}
                  <Grid container item justifyContent="center">
                    <CustomButton
                      data-testid="auth-button"
                      disabled={!validateState}
                      type="submit"
                      className="signin-button"
                      id="signin-button"
                      onClick={handleSubmit}
                      style={{ width: '100%', height: isLarge ? '3.5rem' : '5.7rem', fontSize: isLarge ? '' : '2.7rem' }}
                    >
                      {loading ? (
                        <CircularProgressLoader
                          disableShrink
                          size={16}
                          thickness={3}
                        />
                      ) : 'Submit'}
                    </CustomButton>
                  </Grid>
                </Grid>
              </FormWrapper>
            </InputsPaperContainer>
          </InputsPaper>
        </InputsGridContainer>
      </InnerGridContainer>
      <SuccessDialog
        openConfirmation={successModal}
        handleClose={handleClose}
        title="Password reset!"
        discreption="Hi User, your password reset was successful!"
      />
    </AuthGridContainer>

  );
};

export default ResetPassword;
