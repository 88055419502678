import React from 'react';
import PropTypes from 'prop-types';
import {
  ProductDetail, ProductDetailImage, ProductDetailBrandNameText,
  ProductDetailPackSizeText, HideMd, ShowMd, ProductDetailPriceMobileContainer,
  ProductDetailNameMainContainer, SubItemContent, ProductDetailQuantityText
} from '../dispatch/product-item/styles';
import { ReturnProductContainer } from './returns.styles';

const ReturningProductItemOverview = ({ product }) => {
  const {
    name: brandName, meta, recieved, condition,
    quantityRecieved, quantityReturned
  } = product;
  const { image, pack_size: packSize } = meta;

  return (
    <ReturnProductContainer
      style={!recieved ? { opacity: 0.4, cursor: 'not-allowed' } : {}}
    >
      <ProductDetail>
        <ProductDetailImage src={image} alt="" />
        <ProductDetailNameMainContainer>
          <ProductDetailBrandNameText>
            { brandName }
          </ProductDetailBrandNameText>
          <HideMd>
            <ProductDetailPackSizeText>
              Pack Size:&nbsp;
              { packSize }
            </ProductDetailPackSizeText>
          </HideMd>
        </ProductDetailNameMainContainer>
      </ProductDetail>
      <ShowMd>
        <ProductDetailPriceMobileContainer>
          <ProductDetailPackSizeText>
            Pack Size:&nbsp; &nbsp;
            {packSize}
            &nbsp; &nbsp;
          </ProductDetailPackSizeText>
        </ProductDetailPriceMobileContainer>
      </ShowMd>
      <HideMd>

        <SubItemContent>

          <ProductDetailQuantityText>
            {quantityRecieved}
          </ProductDetailQuantityText>

          <ProductDetailQuantityText>
            {quantityReturned}
          </ProductDetailQuantityText>

          <ProductDetailQuantityText>
            {condition}
          </ProductDetailQuantityText>
        </SubItemContent>
      </HideMd>
    </ReturnProductContainer>
  );
};

ReturningProductItemOverview.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

ReturningProductItemOverview.defaultProps = {};

export default ReturningProductItemOverview;
