import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Paper, TableContainer, TableBody, useMediaQuery, Grid, Table
} from '@mui/material';
import moment from 'moment';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { AFFILIATE_QUERY } from '../../../queries/affiliates';
import {
  TableContainerWrapper, FooterGrid, MobileContainer, FilterBox, FilterImg, TitleHeader
} from './affiliates.styles';
import ReturnRow from './returnRow';
import AffiliateFilter from './affiliateFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import affiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import MobileReturnRow from './mobileReturnRow';
import { useStateValue } from '../../../providers/stateProvider';
import filterImg from '../../../assets/images/marketOrder/filter-search.png';
import { addHyphen } from '../../shared/methods';
import TablePagination from '../../shared/tablePagination';
import { MenuButton } from './mobileFilter.styles';
import { TableComponent, TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';

const headers = [
  { name: 'Created Date', width: '200px', testId: 'mp-uat-affiliates-createdDate' },
  { name: 'Affiliate Name', width: '300px', testId: 'mp-uat-affiliates-affiliateName' },
  { name: 'Customer Category', width: '300px', testId: 'mp-uat-affiliates-customerCategory' },
  { name: 'Account Handler', width: '250px', testId: 'mp-uat-affiliates-accountHandler' },
  { name: 'Email Address', width: '150px', testId: 'mp-uat-affiliates-emailAddress' },
  { name: 'Phone Number', width: '180px', testId: 'mp-uat-affiliates-phoneNumber' },
  { name: 'Status', width: '150px', testId: 'mp-uat-affiliates-status' },
];

const Affiliates = () => {
  const [{ affiliate: { affiliateStatus } }, dispatch] = Object.values(useStateValue());
  const isSmall = useMediaQuery('(max-width: 991px)');

  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    phoneNumber: '',
    status: affiliateStatus || '',
    affiliateName: '',
    setStatus: affiliateStatus || '',
    dateFrom: '',
    dateTo: '',
    filters: {},
    email: ''
  };

  const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState([]);
  const [stateRows, setStateRows] = useState([]);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);

  const {
    phoneNumber, status,
    affiliateName, dateFrom, dateTo, filters, setStatus, email, category
  } = state;

  const updateAffiliateState = (affiliates, affiliatesCount, refetch) => {
    dispatch({
      type: affiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliates,
        affiliatesCount,
        refetch,
        affiliateStatus: ''
      }
    });
  };

  const handleSetFilter = () => {
    setState({
      ...state,
      setStatus: status,
      filters: {
        phoneNumber, name: affiliateName, dateFrom, dateTo,
        email, category
      }
    });
  };

  const handleClearFilter = () => {
    setState(initialState);
  };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const returnHeaders = () => headers.map(({ name, width, testId }) => (
    <TableHeader
      key={name}
      className={`mpAdmin-uat-manageAffiliatePage-${addHyphen(name)}`}
      style={{ minWidth: width }}
      data-testid={testId}
    >
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };
  const handleDateFilter = (dateRange) => {
    const { dateFrom: _dateFrom, dateTo: _dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(_dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(_dateTo).format('YYYY-MM-DD')
    }));
  };
  const variables = {
    pageCount,
    pageNumber,
    status: setStatus,
    ...filters
  };

  const {
    loading, error, data, refetch
  } = useQuery(AFFILIATE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.allBusinesses) {
      const { allBusinesses, businessTotalNumber } = data;
      setStateRows(allBusinesses);
      updateAffiliateState(allBusinesses, businessTotalNumber, refetch);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    allBusinesses = [], businessTotalNumber = 0,
  } = data || {};

  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  return (
    <>
      {!displayMobileFilter && (
        <div style={{ width: '100%' }}>
          {!isSmall ? (
            <TableContainerWrapper component={Paper}>
              <Grid container xs={12}>
                {/* <Grid item xs={3}></Grid> */}
                <Grid item xs={12} style={{ marginLeft: 'auto' }}>
                  <AffiliateFilter
                    state={state}
                    selected={selected}
                    handleFilterChange={handleFilterChange}
                    handleSetFilter={handleSetFilter}
                    handleDateFilter={handleDateFilter}
                  />
                </Grid>
              </Grid>
              <TableContainer>
                {loading ? <ProductsTableLoader /> : (
                  <TableComponent>
                    <Table data-testid="mp-uat-affiliates">
                      <TableColumnHeaders>
                        <TableHeader className="mpAdmin-uat-manageAffiliatePage-created-date" />
                        {returnHeaders()}
                      </TableColumnHeaders>
                      <TableBody>
                        {allBusinesses.map((business, index) => (
                          <ReturnRow
                            key={business.id}
                            row={business}
                            selected={selected}
                            handleSelect={handleSelect}
                            index={index}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableComponent>
                )}
              </TableContainer>

              <FooterGrid item container>
                <TablePagination
                  total={businessTotalNumber}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </FooterGrid>
            </TableContainerWrapper>
          )
            : (
              <MobileContainer>
                <Grid container justifyContent="space-between">
                  <MenuButton>
                    <KeyboardArrowLeft fontSize="large" />
                    <TitleHeader>Manage Affiliates</TitleHeader>
                  </MenuButton>
                  <FilterBox>
                    <FilterImg src={filterImg} alt="filter" onClick={handleMobileFilter} />
                  </FilterBox>
                </Grid>
                {loading ? <ProductsTableLoader /> : (
                  allBusinesses.map((business, index) => (
                    <MobileReturnRow
                      key={business.id}
                      row={business}
                      index={index}
                      selected={selected}
                      handleSelect={handleSelect}
                    />
                  ))
                )}
                <FooterGrid item container>
                  <TablePagination
                    total={businessTotalNumber}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                </FooterGrid>
              </MobileContainer>
            )}
        </div>
      )}
      {displayMobileFilter && (
        <AffiliateFilter
          state={state}
          selected={selected}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleDateFilter={handleDateFilter}
          mobile={displayMobileFilter}
          handleMobile={handleMobileFilter}
          handleClear={handleClearFilter}
        />
      )}
    </>
  );
};

export default Affiliates;
