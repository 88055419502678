import {
  Paper, Grid, Typography, Divider, Button, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainPaper = styled(Paper)`
  margin-right: 15px;
  background-color: #ededed;
  width: 20em;
  max-width: 20em;
`;

export const ContainerGrid = styled(Grid)`
  padding: 5px;
  width: 15rem;
`;

export const HeaderText = styled(Typography)`
`;

export const Divide = styled(Divider)`
`;

export const InnerWrapper = styled(Grid)`
  padding: .5rem .2rem .3rem;
`;

export const CreditText = styled(TextField)`
  margin: 0;
  width: 100%;
`;

export const AddButton = styled(Button)`
  padding: .5rem .7rem;
  height: 39px;
`;

export const AddText = styled(Typography)`
  font-size: 1rem;
`;
