import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import CustomSearchField from '../../shared/CustomSearchField';
import ReturnRow from '../returnRow';
import ReturnRowMobileView from '../returnRowMobileView';
import EmptyCount from '../../../assets/images/empty_count.gif';
import {
  TableHeader, EmptyGrid, EmptyImg, ShowingText, CTableBody,
} from './newReport.styles';
import {
  TableComponent, MainTableHead, TableGrid, PaperWrapper, SearchGrid
} from '../stockReport.styles';

const NewReport = ({
  handleSearch, searchText, batches, loading, updateHandler, reportLoading, setDisableButton
}) => {
  const headers = [
    'Batch No.', 'Product name', 'Pack size', 'Expiry Date', 'QTY Counted', (batches?.length > 0) && 'Action'
  ];
  const isSmall = useMediaQuery('(max-width: 991px)');

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} batches={batches}>{header}</TableHeader>
  ));

  const finishArr = [];
  useEffect(() => {
    if (finishArr.includes(0)) setDisableButton(true);
    else setDisableButton(false);
  }, [batches]);

  return (
    <>
      {!isSmall ? (
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <Grid item container xs={2}>
              <ShowingText variant="caption">
                {`Showing ${batches?.length || 0} Product(s)`}
              </ShowingText>
            </Grid>
            <Grid item container xs={10}>
              <CustomSearchField
                data-testid="search-field"
                name="search"
                value={searchText}
                loading={loading}
                placeholder={!batches?.length ? 'Search' : 'Search inventory to add product item'}
                handleChange={handleSearch}
                style={{ width: '100%' }}
              />
            </Grid>
          </SearchGrid>
          <TableGrid item container>
            <TableComponent item container>
              <MainTableHead item container>
                {returnHeaders()}
              </MainTableHead>
              <CTableBody empty={!batches?.length}>
                {batches?.map((row) => {
                  finishArr.push(row?.quantityInStock);
                  return (
                    <ReturnRow
                      key={row.batchId}
                      row={row}
                      updateHandler={updateHandler}
                      reportLoading={reportLoading}
                      setDisableButton={setDisableButton}
                    />
                  );
                })}
              </CTableBody>
            </TableComponent>
            {!batches?.length ? (
              <EmptyGrid item container direction="column">
                <EmptyImg
                  alt="Empty stock count report"
                  src={EmptyCount}
                />
                <Grid item style={{ fontWeight: 600, margin: '1rem 0 .8rem' }}>
                  Nothing to display yet!
                </Grid>
                <Grid item style={{ fontSize: '.7rem' }}>
                  Hi there, all search items will be displayed here
                </Grid>
              </EmptyGrid>
            ) : ''}
          </TableGrid>
        </PaperWrapper>
      )
        : (
          <>
            <PaperWrapper elevation={0} style={{ borderRadius: '30px' }}>
              <SearchGrid item container>
                <Grid item container xs={12}>
                  <CustomSearchField
                    name="search"
                    value={searchText}
                    loading={loading}
                    placeholder={!batches?.length ? 'Search' : 'Search inventory to add product item'}
                    handleChange={handleSearch}
                    style={{ width: '100%', marginBottom: '1rem' }}
                  />
                </Grid>
              </SearchGrid>
            </PaperWrapper>
            {batches?.map((row) => {
              finishArr.push(row?.quantityInStock);
              return (
                <ReturnRowMobileView
                  key={row.batchId}
                  row={row}
                  updateHandler={updateHandler}
                  reportLoading={reportLoading}
                  setDisableButton={setDisableButton}
                />
              );
            })}
            {!batches?.length ? (
              <PaperWrapper elevation={0} style={{ marginTop: '1rem' }}>
                <EmptyGrid item container direction="column">
                  <EmptyImg
                    alt="Empty stock count report"
                    src={EmptyCount}
                  />
                  <Grid item style={{ fontWeight: 600, margin: '1rem 0 .8rem' }}>
                    Nothing to display yet!
                  </Grid>
                  <Grid item style={{ fontSize: '.7rem' }}>
                    Hi there, all search items will be displayed here
                  </Grid>
                </EmptyGrid>
              </PaperWrapper>
            ) : ''}
          </>
        )}
      {/* <NewReportDialog
        open={openNewReportDialog}
        business={businessId || business.id}
        handleCloseModal={() => setOpenNewReportDialog(false)}
      /> */}
    </>
  );
};

NewReport.propTypes = {
  batches: PropTypes.instanceOf(Array).isRequired,
  handleSearch: PropTypes.func.isRequired,
  searchText: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateHandler: PropTypes.func.isRequired,
  reportLoading: PropTypes.bool.isRequired,
  setDisableButton: PropTypes.func.isRequired,
};

export default NewReport;
