import {
  Grid, Typography, IconButton, TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { KeyboardArrowLeft } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Drawer from '@mui/material/Drawer';

// the width in pixels for sm breakpoint.
const sm = '960px';
export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 3rem;

  @media(min-width: 992px) {
    padding: 30px;
  }
`;

export const StyledTag = styled(Grid)`
  margin-left: 20px;
  margin-bottom: 2px;
  font-size: 1.6rem;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;
`;

export const TextWrapper = styled(Grid)`
  margin-left: 0;
`;

export const KeyboardArrow = styled(KeyboardArrowLeft)`
  font-size: 4rem;

  @media(min-width: 992px) {
    font-size: 2.1875rem;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 30px;
  color: #424242;

  @media(min-width: 992px) {
    font-size: 16px;
  }
  @media(max-width 991px) {
    font-weight: 400;
    font-size: 2rem;
    color: #858383;
  }
`;

export const HeaderIcon = styled('img')`
  width: 18px;
`;

export const StatisticsGridContainer = styled(Grid)`
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 35px;
  transition: 500ms;
  flex-wrap: nowrap;
  width: calc(100vw - 500px);
  align-items: center;
  @media(max-width: ${sm}) {
    flex-wrap: wrap ;
    justify-content: space-around
  }
`;

export const StatisticsGridContainerWrapper = styled(Grid)`
  overflow: hidden;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
`;

export const OrderSubHeader = styled(Grid)`
  margin-bottom: 1rem;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 30px 0px;
  align-items: center;

  @media(max-width: 991px) {
    justify-content: space-between;
  }
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const ProductItemsContainer = styled(Grid)`
  align-items: center;

  @media(max-width: ${sm}) {

  }
`;

export const TutorialTextIcon = styled('span')`
  font-size: 1.5rem;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const FilterImg = styled('img')`
  width: 4rem;
`;

export const RightDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorRight {
    width: 40rem;
    border-top-left-radius: 1rem;
    margin-top: 8.45rem;
  }
`;

export const FilterContainer = styled(Grid)`
  justify-content: flex-end;
  align-items: center;

  @media(max-width: ${sm}) {

  }
`;

export const FilterNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const FilterCreditTypo = styled(Typography)`
  font-size: 0.7rem;
`;

export const SearchContainer = styled(Grid)`
  align-items: center;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };

  @media(min-width: 992px) {
  }
`;

export const AutoCompleteTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 8px;
  & .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 5px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root {
    border-radius: 8px;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 10px;

  & .MuiOutlinedInput-input {
    font-size: 2rem;
    padding: 30px;
  };
  & .MuiOutlinedInput-root {
    border-radius: 10px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    & .MuiOutlinedInput-input {
      font-size: 1rem;
      padding: 15px 14px;
    };
  }
`;

export const SearchButton = styled(IconButton)`
  padding: ${(props) => (props.small ? '15px 20px' : '19px 28px')};
  background: #235A91;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #78AADA;
  }

  &:disabled {
    color: #424242;
    background-color: #235A91;
  }

  @media(min-width: 992px) {
    padding: ${(props) => (props.small ? '5px 8px' : '10px 12px')};
  }
`;

export const SearchImage = styled('img')`
  width: ${(props) => (props.small ? '40px' : '60px')};

  @media(min-width: 992px) {
    width: ${(props) => (props.small ? '20px' : '30px')};
  }
`;

export const FilterTypo = styled(Typography)`
  margin-right: 1rem;
`;

export const AutoComplete = styled(Autocomplete)`
`;

export const ViewCartButton = styled(IconButton)`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 50px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 10px;
  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }
`;

export const ViewCartButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 1rem;

  @media(max-width: ${sm}) {
    margin-bottom: .4rem;
  }
`;

export const OrderCountText = styled(Typography)`
  font-size: 20px;
  color: #4D4F5C;

  @media(max-width: ${sm}) {
    font-size: 14px;
  }
`;

export const ClearIcon = styled(CloseIcon)`
  font-size: ${(props) => (props.small ? '1.25rem' : '1.85rem')};

  @media(max-width: 992px) {
    font-size: ${(props) => (props.small ? '2.5rem' : '3.8rem')};
  }
`;
