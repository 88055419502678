import React, { useState, useLayoutEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  Grid, Hidden
} from '@mui/material';
import {
  useNavigate
} from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import ReturnSelectField from './returnSelectField';
import InitialImage from './initialImage';
import {
  ButtonText, TitleText, TitleWrapper, CircularProgressLoader, UploadGrid, GridWrapper, EditButton, ContainerWrapper, ProfileGrid,
  TextWrapper, TextTitle, Text, HeaderWrapper, Title, DialogTitleWrapper, DialogTitleText, DialogCancelButton, DialogOkButton, DialogActionButtonText,
  LinkPosPaper, LinkText, LinkSubText, LinkBox, NoLinkText, NolinkSubText, LinkCodeText, CopyButton, CloseIcon, DialogTitleContainer, CDialog, ItemWrapper, InputItemWrapper,
  LinkButton, LinkedName, LinkedType, RemoveIcon, RemoveText, OgaLinkSubTitle, RedirectButton,
  RemoveButton, EclipseDesign, LogoContent, OgaLinkTitle, TextBox, LinkDialog, LinkDialogActions
} from './styles';
import {
  UPDATE_BUSINESS_MUTATION
} from '../../mutations/business';
import { validateEmail } from '../auth/utils';
import { parseAffiliateFields } from '../affiliates/utils';

import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import currencyFormatter from '../shared/currencyFormatter';
import { AFFILIATE_PROFILE_QUERY, GET_LINKED_POS } from '../../queries/affiliates';
import MainContent from '../customComponents/mainContent';
import CustomInputBase from '../customComponents/customInputBase';
import SuccessDialog from '../shared/successDialog';
import { LinkIcon, PharmacyIcon } from '../../assets/svgs';
import ConfirmDialog from '../shared/confirmDialog';
import { REMOVE_LINKED_POS } from '../../mutations/affiliates';
import MobileFooter from '../orders/marketplaceOrder/mobileFooter/mobileFooter';

const nigeriaState = [
  'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo',
  'Ekiti', 'Enugu', 'FCT - Abuja', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos',
  'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara'
];

const AffiliateInfo = () => {
  const localBusinessId = localStorage.getItem('ogarx_business_id');
  const url = window.location.origin;

  const {
    loading: loadingAffiliate, data, refetch
  } = useQuery(AFFILIATE_PROFILE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id: localBusinessId },
  });

  const { data: linkedPosData, refetch: refetchLinkedPos } = useQuery(GET_LINKED_POS, {
    fetchPolicy: 'cache-and-network',
    variables: { mpBusinessId: localBusinessId },
  });

  const { linkedPosAccounts, linkedPosAccountsTotal } = linkedPosData ?? {};

  const initialState = {
    legalName: '',
    contactName: '',
    mobile: '',
    email: '',
    secondaryEmailAddress: '',
    addressLine1: '',
    city: 'Lagos',
    country: 'Nigeria',
    id: '',
    logo: '',
    license: [],
    grossOrderAmount: 0,
    orderVolume: 0,
    salesRepresentative: '',
    landMark: '',
    affiliateState: '',
    categoryId: '',
    linkCode: ''
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    reasonError: false,
  };

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [openDialog, setOpenDialog] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [dialogDesc, setDialogDesc] = useState({});
  const [openLinkDialog, setopenLinkDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeSuccessDialog, setRemoveSuccessDialog] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [removedAccountId, setRemovedAccountId] = useState('');

  const [updateBusiness] = useMutation(UPDATE_BUSINESS_MUTATION);
  const [removeLinkedPos, { loading: removeLoading }] = useMutation(REMOVE_LINKED_POS);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (data) {
      const {
        id, legalName, logo, city, country, mobile, addressLine1,
        contactName, email, secondaryEmailAddress, license, linkingCode: linkCode,
        grossOrderAmount, orderVolume, salesRepresentative, landMark, state: affiliateState, businessCategory
      } = parseAffiliateFields(data);
      setState((_state) => ({
        ..._state, legalName, contactName, email, secondaryEmailAddress, landMark,
        mobile, addressLine1, city, country, id, logo, license, linkCode,
        grossOrderAmount, orderVolume, salesRepresentative, affiliateState, categoryId: businessCategory?.id
      }));
    }
  }, [data]);

  useLayoutEffect(() => {
    if (linkedPosData && linkedPosAccounts) setLinkedAccounts(linkedPosAccounts);
  }, [linkedPosData, linkedPosAccounts]);

  const { linkCode } = state;

  const handleEditing = () => {
    setOpenDialog(true);
  };

  const handleFile = (filesData) => {
    setFiles(filesData);
    setOpenImageDialog(true);
  };
  const handleImageUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };
  const handleCloseLinkDialog = () => {
    setopenLinkDialog(false);
  };

  const editAffiliate = (logo) => {
    const {
      id, mobile, email, secondaryEmailAddress, landMark, affiliateState,
      addressLine1, city, country, salesRepresentative, categoryId
    } = state;
    updateBusiness({
      variables: {
        id,
        mobile,
        email,
        secondaryEmailAddress,
        addressLine1,
        city,
        country,
        terms: false,
        salesRepresentative,
        landmark: landMark,
        state: affiliateState,
        reasonForUpdate: 'Update By Affiliate',
        logo,
        categoryId
      }
    })
      .then(({ data: returnData }) => {
        const { message } = returnData?.updateBusiness || {};
        toast.success(message);
        handleCloseImageDialog();
        setSuccessOpenDialog(true);
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleSave = async () => {
  //   setLoading(true);
  //   return editAffiliate();
  // };

  const handleSave = async () => {
    setLoading(true);
    setDialogDesc({ title: 'Details Saved!', desc: 'Hi Affiliate, Your details has been updated successfully!' });
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134434/Placeholders/Affliate_Placeholder.png';
    const alreadyUploadedImage = state.logo || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    return editAffiliate(logo);
  };

  const handleImageSave = async (img) => {
    setLoading(true);
    setDialogDesc({ title: 'Profile Photo Saved!', desc: 'Hi Affiliate, your profile photo has been updated successfully!' });
    const logo = await handleImageUpload(img);

    return editAffiliate(logo);
  };

  const handleDeleteImage = async () => {
    setDialogDesc({ title: 'Photo Deleted!', desc: 'Hi Affiliate, your profile photo has been deleted!' });
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134434/Placeholders/Affliate_Placeholder.png';
    return editAffiliate(logoPlaceholder);
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };
  const topFields = [
    {
      name: 'legalName',
      label: 'Pharmacy name/ Legal name',
      helperText: 'Pharmacy name/ Legal name required',
      type: 'text'
    },
    { name: 'contactName', label: 'Affiliate first & Last name', type: 'text' },
    { name: 'email', label: 'Email Address', type: 'text' },
    { name: 'mobile', label: 'Phone number ', type: 'text' },
    { name: 'secondaryEmailAddress', label: 'Secondary Email Address', type: 'text' },
    { name: 'addressLine1', label: 'Pharmacy Address', type: 'text' },
    { name: 'salesRepresentative', label: 'Sales Representatives', type: 'text' },
    { name: 'landMark', label: 'Landmark', type: 'text' },
    { name: 'city', label: 'City', type: 'text' },
    {
      name: 'affiliateState', label: 'State', type: 'text', options: nigeriaState
    },
  ];

  const handleCopyLinkCode = () => {
    navigator.clipboard.writeText(linkCode)
      .then(() => {
        toast.success('Link code copied to clipboard!');
      })
      .catch(() => {
        toast.error('Failed to copy link code!');
      });
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, type
    } = field;
    const value = fieldName === 'grossOrderAmount' ? currencyFormatter(state[fieldName]) : state[fieldName];
    if (fieldName === 'affiliateState') {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
          fullWidth
          helperText={helperText}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type}
        name={fieldName}
        onChange={handleChange}
        placeholder={label}
        cSize="lg"
        helperText={helperText}
        disabled={(fieldName === 'legalName' || fieldName === 'contactName')}
      />
    );
  };

  const redirectToPos = () => {
    if (url?.includes('uat')) window.location.href = 'https://pos.uat.ogapharmacy.com/';
    else if (url?.includes('staging')) window.location.href = 'https://pos.staging.ogapharmacy.com/';
    else if (url?.includes('canary')) window.location.href = 'https://pos.canary.ogapharmacy.com/';
    else window.location.href = 'https://pos.ogapharmacy.com/';
  };

  const handleRemoveDialog = (id) => {
    setRemovedAccountId(id);
    setConfirmDialog(true);
  };

  const handleRemoveLinkedPos = () => {
    removeLinkedPos({
      variables: {
        linkedAccountId: +removedAccountId,
      },
    })
      .then(() => {
        toast.success('POS account removed successfully!');
        refetchLinkedPos();
        setRemovedAccountId('');
        setConfirmDialog(false);
        setRemoveSuccessDialog(true);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleTabChange = (val) => {
    if (val === 'topSellingProducts') {
      navigate('/new-order/All Categories/0/0/Top Selling Products');
    } else if (val === 'clearanceSale') {
      navigate('/new-order/All Categories/0/0/Clearance Sale');
    } else if (val === 'profile') {
      navigate('/account-info');
    } else {
      navigate('/new-order');
    }
  };

  return (
    <MainContent>
      <ContainerWrapper>
        <HeaderWrapper container>
          <Title>My Account</Title>
          <EditButton
            onClick={handleEditing}
          >
            <ButtonText>
              Edit Profile
            </ButtonText>
          </EditButton>
        </HeaderWrapper>
        { loadingAffiliate ? <ProductsTableLoader /> : (
          <Grid container spacing={3}>
            <UploadGrid container item xs={12} md={4}>
              <ProfileGrid>
                <InitialImage
                  image={state.logo}
                  name={state.legalName}
                  contactName={state.contactName}
                  handleFile={handleFile}
                  files={files}
                  handleFinalImage={setFinalImage}
                  openImageDialog={openImageDialog}
                  handleCloseImageDialog={handleCloseImageDialog}
                  handleDeleteImage={handleDeleteImage}
                  handleImageSave={handleImageSave}
                  loading={loading}
                />
              </ProfileGrid>
            </UploadGrid>
            <GridWrapper container item xs={12} md={8}>
              <ProfileGrid>
                <TitleWrapper container>
                  <TitleText>
                    Profile Information
                  </TitleText>
                </TitleWrapper>
                <ItemWrapper item>
                  <TextWrapper>
                    <TextTitle>Pharmacy / LegalName</TextTitle>
                    <Text>{state.legalName}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>Affiliate First & Last Name</TextTitle>
                    <Text>{state.contactName}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>Email Address</TextTitle>
                    <Text>{state.email}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>Phone Number</TextTitle>
                    <Text>{state.mobile ? state.mobile : 'N/A'}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>Secondary Email Address</TextTitle>
                    <Text>{state.secondaryEmailAddress ? state.secondaryEmailAddress : 'N/A'}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>Pharmacy Address</TextTitle>
                    <Text>{state.addressLine1 ? state.addressLine1 : 'N/A'}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>Sales Representatives</TextTitle>
                    <Text>{state.salesRepresentative ? state.salesRepresentative : 'N/A'}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>Landmark</TextTitle>
                    <Text>{state.landMark ? state.landMark : 'N/A'}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>City</TextTitle>
                    <Text>{state.city}</Text>
                  </TextWrapper>
                  <TextWrapper>
                    <TextTitle>State</TextTitle>
                    <Text>{state.affiliateState ? state.affiliateState : 'N/A'}</Text>
                  </TextWrapper>
                </ItemWrapper>

              </ProfileGrid>

              <LinkPosPaper>
                <LinkText>Linked POS Account</LinkText>
                <LinkSubText>Users can now link their OGApharmacy POS to marketplace</LinkSubText>

                <LinkBox>
                  <Grid container>
                    <Grid item xs={1}>
                      <LinkIcon style={{ fontSize: '2.75rem' }} />
                    </Grid>
                    <Grid item xs={8}>
                      <NoLinkText>
                        {linkedPosAccountsTotal > 0 ? linkedPosAccountsTotal : 'No'}
                        {' '}
                        Linked POS Account!
                      </NoLinkText>
                      <NolinkSubText>Click the button to proceed to link a POS account</NolinkSubText>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                      <LinkButton onClick={() => setopenLinkDialog(true)}>Link POS</LinkButton>
                    </Grid>
                  </Grid>
                </LinkBox>

                {linkedAccounts.map(({ id, name }, index) => (
                  <>
                    <Grid container key={id} style={{ margin: '1.5rem 0', paddingRight: '2rem' }}>
                      <Grid item xs={1}>
                        <PharmacyIcon style={{ fontSize: '2.75rem' }} />
                      </Grid>
                      <Grid item xs={8}>
                        <LinkedName data-testid="linkedPosName">{name}</LinkedName>
                        <LinkedType>OGApharmacy POS</LinkedType>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'center' }}>
                        <RemoveButton variant="text" onClick={() => handleRemoveDialog(id)}>
                          <RemoveIcon />
                          <RemoveText>Remove POS</RemoveText>
                        </RemoveButton>
                      </Grid>
                    </Grid>

                    {index < linkedAccounts.length - 1 && (
                      <hr style={{ margin: '1.5rem 0', border: '0.5px solid #e7e8e9' }} />
                    )}
                  </>
                ))}

              </LinkPosPaper>
            </GridWrapper>

          </Grid>
        )}
        <Hidden mdUp>
          <MobileFooter active="profile" handleTabChange={handleTabChange} />
        </Hidden>
      </ContainerWrapper>

      <CDialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        filesLimit={1}
      >
        <Grid container>
          <GridWrapper container item xs={12}>
            <DialogTitleContainer id="alert-dialog-slide-title">
              <Grid container>
                <DialogTitleWrapper container xs={7}>
                  <DialogTitleText>
                    Edit Affiliate Details
                  </DialogTitleText>
                </DialogTitleWrapper>
                <Grid item container xs={5} justifyContent="flex-end" style={{ marginTop: '1rem' }}>
                  <CloseIcon onClick={handleCloseDialog} />
                </Grid>
              </Grid>
            </DialogTitleContainer>
            <DialogContent>
              <InputItemWrapper>
                {topFields.map((field) => (
                  <Grid key={field} item>{returnTextField(field)}</Grid>
                ))}
              </InputItemWrapper>
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSave}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    Save
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>

        </Grid>
      </CDialog>

      <LinkDialog
        open={openLinkDialog}
        keepMounted
        onClose={handleCloseLinkDialog}
        maxWidth="sm"
        fullWidth
        filesLimit={1}
      >
        <DialogTitleContainer style={{ textAlign: 'right' }} id="alert-dialog-slide-title">
          <CloseIcon onClick={handleCloseLinkDialog} />
        </DialogTitleContainer>

        <LogoContent>
          <EclipseDesign>
            <LinkIcon style={{ fontSize: '2.421rem' }} />
          </EclipseDesign>
        </LogoContent>
        <TextBox>
          <OgaLinkTitle>OGApharmacy POS Link Code</OgaLinkTitle>
          <OgaLinkSubTitle>
            Please copy the code and proceed to your POS
            <br />
            account to setup
          </OgaLinkSubTitle>

          <LinkCodeText>{linkCode}</LinkCodeText>
        </TextBox>

        <LinkDialogActions>
          <CopyButton onClick={handleCopyLinkCode}>Copy Code</CopyButton>

          <RedirectButton onClick={redirectToPos}>Redirect to POS</RedirectButton>
        </LinkDialogActions>
      </LinkDialog>

      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={dialogDesc.title}
        desc={dialogDesc.desc}
        option="ok"
        refetch={refetch}
      />

      <ConfirmDialog
        openDialog={confirmDialog}
        setOpenDialog={setConfirmDialog}
        title="Remove the Linked POS?"
        desc="Are you sure you want to remove the linked POS?"
        options={['Cancel', 'Yes, Remove']}
        buttonAction={handleRemoveLinkedPos}
        loading={removeLoading}
      />

      <SuccessDialog
        openDialog={removeSuccessDialog}
        setOpenDialog={setRemoveSuccessDialog}
        title="Linked POS removed!"
        desc="Link Pos has been removed successfully!"
        option="Ok"
      />
    </MainContent>
  );
};

AffiliateInfo.propTypes = {
};

AffiliateInfo.defaultProps = {
};
export default AffiliateInfo;
