import { styled } from '@mui/material/styles';
import {
  TableCell, Paper, TextField, Button,
  Typography, Grid
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import TelegramIcon from '@mui/icons-material/Telegram';

export const HeaderGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  border-top: 2px solid rgba(224, 224, 224, 1);
  padding: 1em 0;
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
  padding: 0 35px;
`;

export const TableColumnHeaderTitle = styled('p')`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 23px;
  white-space: nowrap;
  padding-bottom: 12px;
  margin-bottom: 0;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
`;

export const TableContainer = styled(Paper)`
  border: 1px solid #eee;
  padding: 20px 0;
`;

export const TableHeaderCell = styled(TableCell)`
  padding-left: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;
export const TableBodyCell = styled(TableCell)`
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-transform: capitalize;
  padding: 14px;
  padding-left: 0;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
`;

export const TableWrapper = styled('div')`
  overflow: hidden;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-bottom:2rem;
  }
`;
export const InputTextField = styled(TextField)`
  width: '25ch',
`;

export const CancelReportButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  background-color: transparent;
  color: #424242;
  border-radius: 1.5px solid #424242;;
  border-radius: 15px;

  &:disabled {
    color: #848483;
    background-color: #CCCCCB;
  }

  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 15px;
    border-radius: 10px;
    margin-right: 30px;
  }
`;

export const EmptyRow = styled('p')`
  font-size: 16px;
  text-align: center;
  margin: 2em 0;
`;

export const SaveProgressIcon = styled(CachedIcon)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const GenerateReportIcon = styled(TelegramIcon)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ProductItemsTotal = styled(Typography)`
  font-size: 30px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const SaleSearchResult = styled('div')`
  background: white;
  width: 50%;
  position: absolute;
  right: 1.5%;
  top: 58%;
  z-index: 200;
  box-shadow: 1px 2px 3px #00000029;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #eeeeee;
`;
