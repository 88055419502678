import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './actionPopper.styles';

export default function ActionPopper({
  action, row, handleAction, handleClose
}) {
  const open = Boolean(action);
  const [isSecondPopperOpen, setIsSecondPopperOpen] = useState(false);
  const [currentEventType, setCurrentEventType] = useState('');

  const { page } = row;

  const handleClickAway = () => {
    setIsSecondPopperOpen(false);
    handleClose();
  };

  const handleChangeText = (e, type) => {
    const isHomepageHero = page === 'Homepage (Hero Section)';

    if (type === 'upload') {
      if (isHomepageHero) {
        setCurrentEventType('upload');
        setIsSecondPopperOpen(true);
      } else {
        handleAction(e, 'upload', false);
      }
    } else if (type === 'view') {
      if (isHomepageHero) {
        setCurrentEventType('view');
        setIsSecondPopperOpen(true);
      } else {
        handleAction(e, 'view', false);
      }
    }
  };

  return (
    <>
      {!isSecondPopperOpen && (
        <RenderPopper
          open={open}
          anchorEl={action}
          popperPlacement="bottom"
          onClickAway={handleClickAway}
          modifiers={{
            offset: {
              enabled: true,
              offset: '0vw, 1vh',
            }
          }}
        >
          <GridWrapper container item>
            <GridContainer
              container
              item
              onClick={(e) => handleChangeText(e, 'upload')}
            >
              <ItemWrapper>
                <ItemTypo variant="subtitle1">
                  Upload Banners
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
            <GridContainer
              container
              item
              onClick={(e) => handleChangeText(e, 'view')}
            >
              <ItemWrapper>
                <ItemTypo variant="subtitle1">
                  View Banners
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
          </GridWrapper>
        </RenderPopper>
      )}

      {isSecondPopperOpen && (
        <RenderPopper
          open={open}
          anchorEl={action}
          popperPlacement="bottom"
          onClickAway={handleClickAway}
          modifiers={{
            offset: {
              enabled: true,
              offset: '0vw, 1vh',
            }
          }}
        >
          <GridWrapper container item>
            <GridContainer
              container
              item
              onClick={(e) => handleAction(e, currentEventType, true)}
            >
              <ItemWrapper>
                <ItemTypo variant="subtitle1">
                  Image
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
            <GridContainer
              container
              item
              onClick={(e) => handleAction(e, currentEventType, false)}
            >
              <ItemWrapper>
                <ItemTypo variant="subtitle1">
                  Carousel
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
          </GridWrapper>
        </RenderPopper>
      )}
    </>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
  handleClose: PropTypes.func.isRequired
};
