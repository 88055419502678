import { gql } from '@apollo/client';

export const CREATE_RETURN = gql`
  mutation createReturn(
    $cart: [ReturnProductQuantityType]!
    $returnSaleId: Int!
    $paymentsMade: [PaymentDetailsType]!
    $transactionDate: String!
    $version: Int!
  ) {
    createReturn(
      cart: $cart
      returnSaleId: $returnSaleId
      paymentsMade: $paymentsMade
      transactionDate: $transactionDate
      version: $version
    ) {
      sale {
        id
        previousSale
        nextSale
        changeDue
        receiptNumber
        salesPayment {
          id
          paymentMethod
          amount
        }
        saleItems {
          name
          quantity
        }
        saleReturns {
          name
          quantity
        }
      }
      message
    }
  }
`;
