import ReconciliationActionTypes from './reconciliationTypes';
import {
  selectForReconciliation, closeBatchModal,
  toggleSelectProductBatch, updateBatchToSellQuantity
} from './reconciliationUtils';

// eslint-disable-next-line default-param-last
const reconciliationReducer = (reconciliation = {}, action) => {
  switch (action.type) {
    case ReconciliationActionTypes.CLOSE_BATCH_MODAL:
      return {
        ...reconciliation,
        ...closeBatchModal(reconciliation, action)
      };
    case ReconciliationActionTypes.SELECT_FOR_RECONCILIATION:
      return {
        ...reconciliation,
        ...selectForReconciliation(reconciliation, action)
      };
    case ReconciliationActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY:
      return {
        ...reconciliation,
        ...updateBatchToSellQuantity(reconciliation, action)
      };

    case ReconciliationActionTypes.TOGGLE_SELECT_PRODUCT_BATCH:
      return {
        ...reconciliation,
        ...toggleSelectProductBatch(reconciliation, action)
      };

    default:
      return reconciliation;
  }
};

export default reconciliationReducer;
