import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Grid, TableBody } from '@mui/material';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
// import { STOCK_TRANSFER_PRODUCT_QUERY } from '../../queries/products';
import { SALES_PRODUCTS_QUERY } from '../../queries/products';
import { CREATE_STOCK_TRANSFER_MUTATION } from '../../mutations/stockTransfer';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import CustomButton from '../customComponents/customButton';
import ReturnRow from './returnRow';
import TransferDialog from './transferDialog';
import TransferLocationDialog from './transferLocationDialog';
import TransferReceipt from './transferReceipt';
import SimpleBadge from '../shared/simpleBadge';
import TablePagination from '../shared/tablePagination';
import CustomSearchField from '../shared/CustomSearchField';
import ToggleBusiness from '../shared/busnesss';
import { Product } from '../../providers/reducers/product/classes/Product';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle, TCustomButton,
  PaperWrapper, TableGrid, TableHeader, TableComponent, MainTableHead,
  FooterWrapper, SearchGrid, TransferIcon, TSCustomButton, CustomCheckbox, ButtonWrapper
} from './stockTransfer.styles';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import StockTransferTypes from '../../providers/reducers/stockTransfer/stockTransferTypes';
import { useStateValue } from '../../providers/stateProvider';

const headers = [
  'S/N', 'Product Name', 'Qty in Stock', 'Cost Price', 'Selling Price', '% Profit/Loss',
  'Pack Size',
];

const StockTransfer = () => {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [stateRows, setStateRows] = useState([]);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [openTransferLocationDialog, setOpenTransferLocationDialog] = useState(false);
  const [openTransferReceipt, setOpenTransferReceipt] = useState(false);

  const navigate = useNavigate();

  const [{
    user: { platform, allowedPermissionsMap: { userPermissions } },
    stockTransfer: { transferItems, transferLocation },
    business: {
      businessId,
    },
    product: { refetch: prodRefetch }
  }, dispatch] = Object.values(useStateValue());

  const [createStockTransfer] = useMutation(CREATE_STOCK_TRANSFER_MUTATION);
  const handleTransferStock = () => {
    setOpenTransferLocationDialog(false);
    const transfers = [];
    transferItems.forEach((item) => {
      const batches = [];
      item.batches.forEach((batch) => {
        if (batch.qtyToTransfer) batches.push({ batchId: batch.id, qtyToTransfer: batch.qtyToTransfer });
      });
      if (batches.length) transfers.push({ productId: item.id, batches });
    });
    if (transfers.length) {
      createStockTransfer({
        variables: {
          transferLocation: transferLocation?.id,
          transfers
        }
      }).then(({ data }) => {
        // const noOfProd = data?.createStockTransfer?.noOfProductsCreated || 0;
        // const storeName = data?.createStockTransfer?.transferLocation?.name;
        // toast.success(`${noOfProd} product(s) was created for ${storeName} during product transfer`);
        dispatch({
          type: StockTransferTypes.UPDATE_TRANSFER,
          payload: {
            transferredData: data?.createStockTransfer,
            transferItems: [],
            transferLocation: '',
          }
        });
        prodRefetch();
        setOpenTransferReceipt(true);
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      toast.error('You did not select any quantities to be transferred!');
    }
  };

  const updateProductState = (products, totalCount, refetch) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { products, totalCount, refetch }
    });
  };

  const handleSelectAll = (event) => {
    let newSelections = [];
    if (event.target.checked) {
      const newRows = stateRows.filter((x) => x.quantityInStock);
      newSelections = newRows;
    }

    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER,
      payload: { transferItems: newSelections }
    });
  };

  const handleSelect = (_, row) => {
    const selectedIndex = transferItems.findIndex((x) => x?.id === row?.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(transferItems, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(transferItems.slice(1));
    } else if (selectedIndex === transferItems.length - 1) {
      newSelected = newSelected.concat(transferItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        transferItems.slice(0, selectedIndex),
        transferItems.slice(selectedIndex + 1)
      );
    }

    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER,
      payload: { transferItems: newSelected }
    });
  };

  const renderCheckbox = () => {
    const newRows = stateRows.filter((x) => x.quantityInStock);
    return (
      <CustomCheckbox
        size="small"
        checked={transferItems.length === newRows.length}
        onChange={handleSelectAll}
        inputProps={{ 'aria-label': 'select product' }}
      />
    );
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const variables = {
    ...(searchText.length >= 3 ? { search: searchText } : { search: '' }),
    suppliers: [''],
    business: businessId,
    productType: 'only_business_products',
    status: 'ACTIVE',
    pageCount,
    pageNumber,
  };

  const {
    loading, error, data, refetch
  } = useQuery(SALES_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.salesPageProducts) {
      const { salesPageProducts: products, salesPageTotalNumber } = data;
      updateProductState(products, salesPageTotalNumber, refetch);
      setStateRows(products);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    salesPageProducts = [], salesPageTotalNumber = 0
  } = data || {};

  const rows = salesPageProducts?.map((prod) => new Product(prod, platform));

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={4}>
            <PrimaryTitle variant="h5">Stock Transfer</PrimaryTitle>
          </TitleTextGridContainer>
          <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={7}>
            <TCustomButton
              type="secondary"
              header
              style={{ width: '12.2rem', marginRight: '.8rem' }}
              onClick={() => navigate('/received-transfers')}
              disabled={!userPermissions?.includes('stock_transfer_received_transfer')}
            >
              Received Transfers
            </TCustomButton>
            <CustomButton
              type="tertiary"
              header
              style={{ width: '14.5rem', height: '3rem' }}
              onClick={() => navigate('/stock-transfer-logs')}
              disabled={!userPermissions?.includes('stock_transfer_view_stock_transfer_logs')}
            >
              View stock transfer Logs
            </CustomButton>
          </Grid>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search"
              handleChange={(e) => setSearchText(e?.target?.value)}
              style={{ width: '87%' }}
            />
            <ButtonWrapper item container>
              <SimpleBadge value={transferItems && transferItems.length}>
                <TSCustomButton
                  type="secondary"
                  header
                  disabled={!transferItems.length || !userPermissions?.includes('stock_transfer_ multi_transfers')}
                  onClick={() => setOpenTransferDialog(true)}
                >
                  <TransferIcon />
                  Multi Transfer
                </TSCustomButton>
              </SimpleBadge>
            </ButtonWrapper>
          </SearchGrid>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <MainTableHead item container>
                    <TableHeader>{renderCheckbox()}</TableHeader>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody style={{ width: '100%' }}>
                    {rows.map((_row, indx) => (
                      <ReturnRow
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                        selected={transferItems}
                        handleSelect={handleSelect}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>
          <ToggleBusiness />
          <FooterWrapper item container>
            {salesPageTotalNumber > 0 && (
            <TablePagination
              total={salesPageTotalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            )}
          </FooterWrapper>

          <TransferDialog
            openDialog={openTransferDialog}
            closeDialog={() => setOpenTransferDialog(false)}
            openLocationDialog={() => setOpenTransferLocationDialog(true)}
            handleSelect={handleSelect}
          />

          <TransferLocationDialog
            openDialog={openTransferLocationDialog}
            closeDialog={() => setOpenTransferLocationDialog(false)}
            handleTransferStock={handleTransferStock}
          />

          <TransferReceipt
            openDialog={openTransferReceipt}
            closeDialog={() => setOpenTransferReceipt(false)}
            handleTransferStock={handleTransferStock}
          />
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default StockTransfer;
