import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDarkStyled, GridWrapper, GridContainer, ItemTypo, ItemWrapper
} from './buttonsPopper.style';
import { RenderPopper } from '../../utils/utils';

export default function ProductButtonsPopper({ actionHandler }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonDarkStyled
        className="erp-uat-add-product"
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
        addProduct
        data-testid="erp-uat-inventory-add-product"
      >
        Add Product
      </ButtonDarkStyled>
      <RenderPopper
        open={open}
        anchorEl={anchorRef.current}
        popperPlacement="bottom"
        onClickAway={handleClose}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
      >
        <GridWrapper container item>
          <GridContainer
            container
            item
            className="erp-uat-add-individually"
            onClick={() => { actionHandler('add_individually'); setOpen(false); }}
            data-testid="erp-uat-inventory-add-individually"
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Add Individually
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
          <GridContainer
            container
            item
            className="erp-uat-upload-order-csv"
            onClick={() => { actionHandler('upload_order_csv'); setOpen(false); }}
            data-testid="erp-uat-inventory-upload-product-csv"
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Upload CSV File
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </RenderPopper>
    </>
  );
}

ProductButtonsPopper.propTypes = {
  actionHandler: PropTypes.func.isRequired,
};
