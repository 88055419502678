import {
  Paper, Typography, Grid, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MobileContainer = styled(Paper)`
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 100%;
`;

export const SerialText = styled(Typography)`
  font-weight: 600;
  font-size: 2.5rem;
  padding: 2rem;
`;

export const HeaderText = styled(Grid)`
  font-weight: 600;
  font-size: 2rem;
`;

export const DetailText = styled(Grid)`
  text-align: right;
  font-size: 2rem;
`;

export const TextBox = styled(Grid)`
  padding: 0 2rem 2rem 2rem;
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;
