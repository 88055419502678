import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import Hidden from '@mui/material/Hidden';
import {
  NavWrapper, LogoWrapper, PharmiqLogo, MajorText,
  DateTimeTypo, NavItemWrapper, AffiliatesLogo, LogoIcon,
  AffiliateImage
} from './navigation.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import AffiliatesPopper from '../../../navigation/affiliatesPopper';
import PharmIQLogoImage from '../../../../assets/images/BoxLogo.png';
import AffiliateLogoPlaceholder from '../../../../assets/images/navigation/Affiliate_Placeholder.png';

const today = moment().format('dddd DD MMMM  HH:m');

function Navigation() {
  const [affiliatesLogoEl, setAffiliatesLogoEl] = useState(null);
  const [logo, setLogo] = useState(AffiliateLogoPlaceholder);
  const [{ affiliate: { loggedInAffiliate } }] = Object.values(useStateValue());
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedInAffiliate && loggedInAffiliate.business) {
      const {
        business: {
          logo: initialLogo
        }
      } = loggedInAffiliate || {};
      setLogo(initialLogo);
    }
  }, [loggedInAffiliate]);
  const handleAffiliateLogoClick = (event) => {
    setAffiliatesLogoEl(affiliatesLogoEl ? null : event.currentTarget);
  };
  return (
    <>
      <NavWrapper>
        <LogoWrapper>
          <PharmiqLogo
            src={PharmIQLogoImage}
            alt="pharmIQ logo"
            onClick={() => navigate('/module')}
          />
          <MajorText>
            Setup
          </MajorText>
        </LogoWrapper>

        <NavItemWrapper>
          <Hidden mdDown>
            <DateTimeTypo>
              {today}
            </DateTimeTypo>
          </Hidden>
          <AffiliatesLogo>
            <LogoIcon color="primary" onClick={handleAffiliateLogoClick}>
              <AffiliateImage alt="affiliates logo" src={logo} />
            </LogoIcon>
          </AffiliatesLogo>
        </NavItemWrapper>
      </NavWrapper>

      <AffiliatesPopper
        affiliatesLogoEl={affiliatesLogoEl}
        handleAffiliateLogoClick={handleAffiliateLogoClick}
      />
    </>
  );
}

export default Navigation;
