import {
  Grid, Typography, IconButton, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PercentageWapper = styled(Box)`
  // background: rgba(252,237,80,0.25);
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transition: 500ms;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: flex-start;
  text-align: left;
  position: relative;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 42px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 22px;
`;
export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;
