import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../../assets/images/Close.png';
import { AddImage, ButtonIcon } from './receiptPrint.styles';
import { OgaToken } from '../../../graphql/token';

const ReceiptPrint = ({ receiptNumber, closePrint, type }) => (
  <div style={{ position: 'relative', height: '80%' }}>
    <iframe
      title="receipt"
      src={`${OgaToken.SERVER_LINK}download_receipt/${receiptNumber}.pdf/${type}`}
      width="100%"
      height="100%"
      style={{ border: 0 }}
    />
    {/* <ButtonIcon size="small" onClick={closePrint}>
      <AddImage alt="Close Receipt" src={CloseIcon} />
    </ButtonIcon> */}
  </div>
);

ReceiptPrint.propTypes = {
  receiptNumber: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closePrint: PropTypes.func.isRequired
};

export default ReceiptPrint;
