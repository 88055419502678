import {
  Grid, Typography, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderPaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 2rem;
  margin-bottom: 1.5rem;
`;

export const SmallGrid = styled(Grid)`
  flex-direction: column;
`;

export const SmallTitle = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  color: #303030;
`;

export const SmallerTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  color: #303030;
  text-transform: capitalize;
`;
