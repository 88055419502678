import { useMutation, useQuery } from '@apollo/client';
import { FilterList, KeyboardArrowLeft } from '@mui/icons-material';
import { Grid, TableBody } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { STOCK_TRANSFER_STATUS_MUTATION } from '../../../mutations/stockTransfer';
import StockTransferTypes from '../../../providers/reducers/stockTransfer/stockTransferTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { GET_STOCK_TRANSFERS } from '../../../queries/stockTransfer';
import CustomButton from '../../customComponents/customButton';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import MainContent from '../../customComponents/mainContent';
import CustomSearchField from '../../shared/CustomSearchField';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import NotificationDialog from '../../shared/notificationDialog';
import TablePagination from '../../shared/tablePagination';
import FilterPopper from '../filterPopper';
import {
  ButtonWrapper,
  CustomCheckbox,
  FilterText,
  FooterWrapper,
  MainTableHead,
  MenuButton,
  PaperWrapper,
  PrimaryTitle,
  SearchGrid,
  TCustomButton,
  TableComponent,
  TableGrid, TableHeader,
  TitleGrid, TitleTextGridContainer
} from './receivedTransfers.styles';
import RejectReasonDialog from './rejectReasonDialog';
import ReturnRow from './returnRowReceived';

const headers = [
  'S/N', 'Date', 'Stock Transfer Receipt', 'Transfer Location', 'Handler', 'Status', 'Action',
];

const ReceivedStockTransfer = () => {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [stateRows, setStateRows] = useState([]);
  const [status, setStatus] = useState('All Transfers');
  const [filter, setFilter] = useState('');
  const [openRejectWarning, setOpenRejectWarning] = useState(false);
  const [openConfirmWarning, setOpenConfirmWarning] = useState(false);
  const [openRejectSuccess, setOpenRejectSuccess] = useState(false);
  const [openConfirmSuccess, setOpenConfirmSuccess] = useState(false);
  const [openRejectReasonDialog, setOpenRejectReasonDialog] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [dates, setDates] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });

  const navigate = useNavigate();

  const [{
    stockTransfer: { transferItems },
  }, dispatch] = Object.values(useStateValue());

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const handleStatus = (event, column) => {
    setStatus(column);
    handleFilterButtonClick(event);
    switch (column) {
      case 'All':
        return setFilter('');
      case 'Pending':
        return setFilter('PENDING');
      case 'Rejected':
        return setFilter('REJECTED');
      case 'Confirmed':
        return setFilter('CONFIRMED');
      default:
        break;
    }
  };

  const handleSelectAll = (event) => {
    let newSelections = [];
    if (event.target.checked) newSelections = stateRows;

    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER,
      payload: { transferItems: newSelections }
    });
  };

  const handleSelect = (_, row) => {
    const selectedIndex = transferItems.findIndex((x) => x?.id === row?.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(transferItems, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(transferItems.slice(1));
    } else if (selectedIndex === transferItems.length - 1) {
      newSelected = newSelected.concat(transferItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        transferItems.slice(0, selectedIndex),
        transferItems.slice(selectedIndex + 1)
      );
    }

    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER,
      payload: { transferItems: newSelected }
    });
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={transferItems.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const handleSubmit = (dateRange) => {
    setDates(dateRange);
  };

  const variables = {
    ...(searchText.length >= 3 ? { search: searchText } : { search: '' }),
    status: 'RECEIVED',
    dateFrom: dates?.dateFrom,
    dateTo: dates?.dateTo,
    filter,
    pageCount,
    pageNumber,
  };

  const {
    loading, error, data, refetch
  } = useQuery(GET_STOCK_TRANSFERS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.stockTransfers) setStateRows(data.stockTransfers);
  }, [data]);

  const [changeStockTransferStatus] = useMutation(STOCK_TRANSFER_STATUS_MUTATION);

  if (error) return <div>{error.message}</div>;
  const {
    stockTransfers = [], stockTransferTotalNumber = 0
  } = data || {};

  const rejectTransfers = (reason) => {
    const transferIds = transferItems.filter((x) => x.status === 'PENDING').map((m) => m.id);
    if (transferIds.length) {
      changeStockTransferStatus({
        variables: {
          status: 'REJECTED',
          transferIds,
          reason
        }
      })
        .then(({ data: { changeStockTransferStatus: changeStatus } }) => {
          if (changeStatus && changeStatus.message) {
            setOpenRejectSuccess(true);
            refetch();
            dispatch({
              type: StockTransferTypes.UPDATE_TRANSFER,
              payload: { transferItems: [] }
            });
          }
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } else toast.error('There are no Transfers with Pending status');
  };

  const confirmTransfers = () => {
    const transferIds = transferItems.filter((x) => x.status === 'PENDING').map((m) => m.id);
    if (transferIds.length) {
      changeStockTransferStatus({
        variables: {
          status: 'CONFIRMED',
          transferIds
        }
      })
        .then(({ data: { changeStockTransferStatus: changeStatus } }) => {
          if (changeStatus && changeStatus.message) {
            const noOfProd = changeStatus?.noOfProductsCreated;
            setOpenConfirmSuccess(true);
            refetch();
            toast.success(`${noOfProd} new product(s) created`);
            dispatch({
              type: StockTransferTypes.UPDATE_TRANSFER,
              payload: { transferItems: [] }
            });
          }
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } else toast.error('There are no Transfers with Pending status');
  };

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={4}>
            <MenuButton onClick={() => navigate(-1)}>
              <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
            </MenuButton>
            <PrimaryTitle variant="h5">Received Transfers</PrimaryTitle>
          </TitleTextGridContainer>
          <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={7}>
            <TCustomButton
              type="secondary"
              header
              style={{ width: '12.2rem', marginRight: '.8rem' }}
              disabled={!transferItems.length}
              onClick={() => setOpenRejectWarning(true)}
            >
              Reject Transfer
            </TCustomButton>
            <CustomButton
              type="tertiary"
              header
              style={{ width: '14.5rem', height: '3rem' }}
              disabled={!transferItems.length}
              onClick={() => setOpenConfirmWarning(true)}
            >
              Confirm transfer
            </CustomButton>
          </Grid>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchGrid item container>
            <CustomSearchField
              name="search"
              value={searchText}
              placeholder="Search"
              handleChange={(e) => setSearchText(e?.target?.value)}
              style={{ width: '73%' }}
            />
            <ButtonWrapper item container>
              <Grid item container xs={8}>
                <CustomDateRanges
                  handleSubmit={handleSubmit}
                  xs={12}
                  styles={{ height: '47px', border: '1px solid #bababa' }}
                />
              </Grid>
              <Grid item container xs={4} justifyContent="flex-end">
                <CustomButton
                  type="secondary"
                  style={{ width: '6.5rem', height: '2.95rem', marginLeft: '5px' }}
                  onClick={handleFilterButtonClick}
                >
                  <FilterList style={{ marginRight: '.4rem' }} />
                  <FilterText>Filter</FilterText>
                </CustomButton>
              </Grid>
            </ButtonWrapper>
          </SearchGrid>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <MainTableHead item container>
                    <TableHeader>{renderCheckbox()}</TableHeader>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody style={{ width: '100%' }}>
                    {stockTransfers.map((_row, indx) => (
                      <ReturnRow
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                        selected={transferItems}
                        handleSelect={handleSelect}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>
          <FooterWrapper item container>
            {stockTransferTotalNumber > 0 && (
              <TablePagination
                total={stockTransferTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>

          <NotificationDialog
            openDialog={openRejectWarning}
            setOpenDialog={setOpenRejectWarning}
            type="warning"
            title="Reject Transfers?"
            desc="Hi User, are you sure you want to reject the transfer"
            action="cancel"
            action2="Yes, Reject"
            action2Func={() => setOpenRejectReasonDialog(true)}
          />

          <NotificationDialog
            openDialog={openConfirmWarning}
            setOpenDialog={setOpenConfirmWarning}
            type="warning"
            title="Confirm Transfers?"
            desc="Hi User, are you sure you want to confirm the transfer"
            action="cancel"
            action2="Yes, Confirm"
            action2Func={confirmTransfers}
          />

          <NotificationDialog
            openDialog={openRejectSuccess}
            setOpenDialog={setOpenRejectSuccess}
            title="Transfers Rejected!"
            desc="Hi User, your received transfer has been rejected."
          />

          <NotificationDialog
            openDialog={openConfirmSuccess}
            setOpenDialog={setOpenConfirmSuccess}
            title="Transfers Confirmed!"
            desc="Hi User, your received transfer has been confirmed successfully."
          />

          <RejectReasonDialog
            openDialog={openRejectReasonDialog}
            closeDialog={() => setOpenRejectReasonDialog(false)}
            rejectTransfers={rejectTransfers}
          />

          <FilterPopper
            filterButtonEl={filterButtonEl}
            status={status}
            handleStatus={handleStatus}
            handleFilterButtonClick={handleFilterButtonClick}
          />
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default ReceivedStockTransfer;
