import {
  Button,
  DialogActions, DialogContent, Grid
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { blockInvalidChar } from '../../../utils/mobileCheck';
import CustomInputBase from '../../customComponents/customInputBase';
import SavePopper from './savePopper';
import {
  CDialog,
  CloseIcon,
  DialogTitleText,
  GridWrapper
} from './singleBatchDialog.styles';

const defaultReceived = moment().format('YYYY-MM-DD');
const defaultExp = moment().add(1, 'years').format('YYYY-MM-DD');

const SingleBatchDialog = ({
  modalStatus, modalHandler, product, submitBatchHandler, singleBatchDetails, updateBatchHandler, singleBatchItem
}) => {
  const {
    opSetId, name: brandName, quantity, supplier, unitCost
  } = product || {};

  const [quan, setQuan] = useState(quantity - singleBatchItem.map(({ quantityReceived }) => Number(quantityReceived)).reduce((a, b) => a + b, 0));

  const initialState = {
    orderProductId: opSetId,
    batchNo: '',
    quantityReceived: quan,
    dateReceived: defaultReceived,
    expiryDate: defaultExp,
    reconciledUnitCost: unitCost,
    supplierName: supplier?.name
  };

  const initialErrorState = {
    batchNoError: false,
    quantityError: false,
    orderCostError: false
  };

  const [batch, setBatch] = useState(initialState);
  const [addButtonEl, setAddButtonEl] = useState(null);
  const [errorState, setErrorState] = useState(initialErrorState);

  useEffect(() => {
    if (singleBatchDetails) {
      const { batchNumber, batches } = singleBatchDetails;
      const editBatch = batches?.find((item) => item?.batchNo === batchNumber);
      setBatch(editBatch);
    } else { setBatch(initialState); }
  }, [singleBatchDetails]);

  useEffect(() => {
    const q = quantity - singleBatchItem.map(({ quantityReceived }) => Number(quantityReceived)).reduce((a, b) => a + b, 0);
    if (singleBatchDetails) {
      const { batchNumber, batches } = singleBatchDetails;
      const editBatch = batches?.find((item) => item?.batchNo === batchNumber);
      setBatch(editBatch);
    } else { setBatch({ ...initialState, quantityReceived: q }); setQuan(q); }
  }, [singleBatchItem]);

  const validateState = (name, value) => {
    switch (name) {
      case 'batchNo':
        return setErrorState({ ...errorState, batchNoError: !value });
      case 'quantityReceived':
        return setErrorState({ ...errorState, quantityError: !value });
      case 'reconciledUnitCost':
        return setErrorState({ ...errorState, orderCostError: !value });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'quantityReceived' && (Number(value) > quantity || Number(value) < 0)) return false;
    setBatch({ ...batch, [name]: value });
  };

  const handleKeyUp = (e, field) => {
    if (field === 'quantityReceived' || field === 'quantityReceived') { blockInvalidChar(e); }
  };

  const addBatchHandler = (e) => setAddButtonEl(addButtonEl ? null : e.currentTarget);

  const validateSave = () => {
    const { batchNo, quantityReceived, reconciledUnitCost } = batch;
    if (batchNo === '') {
      validateState('batchNo', batchNo);
      return false;
    } if (quantityReceived === '' || Number(quantityReceived) < 1) {
      validateState('quantityReceived', quantityReceived);
      return false;
    }
    if (reconciledUnitCost === '' || Number(reconciledUnitCost) < 1) {
      validateState('reconciledUnitCost', reconciledUnitCost);
      return false;
    } return true;
  };

  const savePopperHandler = (addMore = false) => {
    if (quan < Number(batch?.quantityReceived)) return toast.error('Oops! Can not received more than the quantity ordered.');
    if (!validateSave()) return;
    submitBatchHandler(batch, addMore);
    setAddButtonEl(null);
    if (!addMore) modalHandler();
    setBatch(initialState);
  };

  const updateBatchMethod = () => {
    if (!validateSave()) return;
    updateBatchHandler(batch);
    modalHandler();
  };

  const { batchNoError, quantityError, orderCostError } = errorState;

  const modalFields = [
    {
      name: 'batchNo', label: 'Batch No.', required: true, placeholder: 'Enter Batch No.', error: batchNoError,
      helperText: 'Batch number field is required'
    },
    {
      name: 'quantityReceived', label: 'Quantity', type: 'number', required: true, placeholder: 'Enter Quantity',
      error: quantityError, helperText: 'Quantity  field is required'
    },
    {
      name: 'reconciledUnitCost', label: 'Order Cost', type: 'number', required: true, placeholder: 'Enter Order Cost',
      error: orderCostError, helperText: 'Order cost field is required'
    },
    {
      name: 'dateReceived', label: 'Date Received', required: true, placeholder: 'Select Date', type: 'date'
    },
    {
      name: 'supplierName', label: 'Supplier', required: true, placeholder: 'Add Supplier'
    },
    {
      name: 'expiryDate', label: 'Expiry Date', required: true, placeholder: 'Select Date', type: 'date'
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, placeholder, type
    } = field;
    const value = batch[fieldName];
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type || 'text'}
        error={error || false}
        helperText={error && helperText}
        required={required}
        disabled={fieldName === 'supplierName' || (fieldName === 'quantityReceived' && quan < 1 && !singleBatchDetails)}
        name={fieldName}
        onChange={handleChange}
        onKeyUp={(e) => handleKeyUp(e, fieldName)}
        placeholder={placeholder}
        cSize="lg"
      />
    );
  };

  return (
    <>
      <CDialog
        open={modalStatus}
        onClose={modalHandler}
        aria-labelledby="alert-dialog-slide-title"
        fullWidth
      >
        <GridWrapper container>
          <Grid container>
            <Grid item container xs={6}>
              <DialogTitleText>{brandName}</DialogTitleText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={modalHandler} />
            </Grid>
          </Grid>
          <DialogContent>
            <Grid item container spacing={2}>
              <Grid container spacing={2}>
                {modalFields.map((field) => (
                  <Grid item key={field?.name} xs={6}>
                    { returnTextField(field) }
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }} onClick={modalHandler}>
              Cancel
            </Button>
            {
              singleBatchDetails ? (
                <Button variant="contained" onClick={updateBatchMethod} style={{ width: '11rem', height: '2.7rem' }}>
                  Update batch
                </Button>
              )
                : (
                  <Button variant="contained" onClick={addBatchHandler} disabled={batch?.quantityReceived < 1} style={{ width: '11rem', height: '2.7rem' }}>
                    Add batch
                  </Button>
                )
            }
          </DialogActions>
        </GridWrapper>
      </CDialog>

      <SavePopper
        addButtonEl={addButtonEl}
        setAddButtonEl={setAddButtonEl}
        savePopperHandler={savePopperHandler}
      />
    </>
  );
};

SingleBatchDialog.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  modalStatus: PropTypes.bool.isRequired,
  modalHandler: PropTypes.func,
  submitBatchHandler: PropTypes.func,
  singleBatchDetails: PropTypes.instanceOf(Object),
  updateBatchHandler: PropTypes.func.isRequired,
  singleBatchItem: PropTypes.instanceOf(Array).isRequired
};

SingleBatchDialog.defaultProps = {
  modalHandler: () => {},
  submitBatchHandler: () => {},
  singleBatchDetails: {}
};

export default SingleBatchDialog;
