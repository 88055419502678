import {
  TableRow, TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px 2px;
  color: #606060;

  &:first-child {
    padding: 15px 7px;
  }

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;
