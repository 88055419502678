import { gql } from '@apollo/client';

export const GET_AFFILIATES_INFO = gql`
  query allAffiliates(
    $pageCount: Int
    $pageNumber: Int
  ){
    affiliates(
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
      id,
      dateCreated,
      name,
      orderAmount,
      paidAmount,
      accountNumber
    }
    deliveryLocationTotalNumber
  }
`;

export const GET_USER_INFO = gql`
query {
  affiliatePromos
  availablePromos
  businessCanUseMastersList
  isBusinessVirtualStore
  definedUser {
    platform
    onlyBusinessProducts
    deliveryLocations{
      id
    }
    businessUser{
      id
      userPermissions
      permissions
      role {
        id
        name
      }
      business {
        id
        name
        logo
        supplierSet {
          id
          name
        }
        parentId
        policy{
          id
          priceFactor
          policyName
          supplierPolicy
        }
        isSupplyChain
        settings
      }
    }
  }
  permissions{
    id
    name
  }
  userInfo {
    id
    username
    profile
    status
    businessuserSet {
      id
      permissions
      role {
        id
        name
      }
      business {
        id
        name
        logo
        supplierSet {
          id
          name
        }
        policy{
          id
          priceFactor
          policyName
          supplierPolicy
        }
      }
    }
  }
}
`;

export const GET_ALL_USERS = gql`
  query getAllUsers {
    allElUsers {
      id
      username
      meta
      contacts
      profile
      status
      lastLogin
      dateCreated
      businessuserSet {
        id
      }
    }
  }
`;

export const GET_BUSINESS_USER = gql`
  query businessUser($id: Int!) {
    businessUser(id: $id) {
      id
      permissions
      meta
      business {
        id
        name
      }
      businessRole {
        id
        role {
          id
          name
        }
        permissions
      }
      saleSet {
        id
        total
      }
      user {
        id
        username
        profile
        contacts
        dateCreated
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions($searchValue: String) {
    permissions(search: $searchValue) {
      id
      name
    }
  }
`;

export const GET_ACCESS_CONTROL_BUSINESS_USERS = gql`
  query allBusinessUsers {
    allBusinessUsers {
      id
      permissions
      business{
        name
      }
      user {
        username
        profile
      }
      role{
        id
        name
      }
    }
    permissions{
        id
        name
    }
    allRoles{
        id
        name
        permissions
    }
  }
`;
