import React from 'react';
import styled from 'styled-components';
import {
  Paper, Grid, IconButton, Typography, Table, TableCell, TableRow, TableHead
} from '@mui/material';

const sm = '960px';

export const SupplierDetailCard = styled(Paper)`
  background: #FFFFFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  width: 95vw;
  height: 450px;
  border-radius: 7px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  @media (min-width: 992px) {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const MainTableHead = styled(TableHead)`
  display: table-header-group;
  background: #F3F9FF;

  @media (min-width: 992px) {
  }
`;

export const Header = styled(TableCell)`
  /**text-transform: capitalize;**/
  padding: 20px 10px;
  background: #F4F7FC;
  font-weight: 700;
  font-size: 16px;
`;

export const PaperContainer = styled(Paper)`
  padding: 3rem 2rem;
  margin-top: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 7px;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  padding: 10px;
  color: rgba(36, 41, 46, 0.87);
  text-align: left;
`;

export const BackText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const GridButton = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
`;

export const SupplierDetailCardWrapper = styled(Grid)`
  @media (max-width: ${sm}) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const ProductDetail = styled('div')`
  display: flex;
  align-items: center;
  @media (min-width: 992px) {
    width: 400px;
  }
`;

export const ProductDetailImage = styled('img')`
  width: 11rem;
  max-height: 11rem;
  margin-right: 20px;
  @media (min-width: 992px) {
  width: 100px;
  max-height: 100px;
  margin-right: 10px;
  }
`;

export const ProductDetailBrandNameText = styled('p')`
  font-weight: 700;
  font-size: 14px;
  color: #24292E;
  margin: 0;
  text-transform: title-case;
  margin-bottom: 15px;
  @media (min-width: 992px) {
    line-height: 23px;
    font-size: 18px;
  }
`;

export const ProductDetailPackSizeText = styled('p')`
  margin: 0;
  color: #606060;
  font-weight: 500;
  font-size: 12px;

  @media (max-width: 991px) {
    color: #868686;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 300;
  }
`;

export const HideMd = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const SubtotalGrid = styled(Grid)`
  border-top: 1px solid #E7E8E9;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 16px;
`;

export const SubtotalText = styled('div')`
  width: 14rem;
  margin-top: 2rem;
  border-top: 1px solid #E7E8E9;
  padding-top: 1rem;
  text-align: center;
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 991px) {
    height: 15rem;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin: 25px 0px;

  @media (min-width: 992px) {
    justify-content: space-between;
  }
`;
