import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableColumnRows = styled('div')`
  display: flex;

  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;
