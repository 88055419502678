import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from '../shared/currencyFormatter';
import {
  MobileContainer, DetailText, HeaderText, TextBox, DateText,
  ContentBox
} from './mobileReturnRow.styles';

export default function MobileReturnRow({ paymentItem }) {
  const navigate = useNavigate();
  const {
    id, business, creditDays, order, status, total, amountPaid
  } = paymentItem;
  const invoiceValue = currencyFormatter(total);
  const amount = currencyFormatter(amountPaid);

  const handleClick = (type) => {
    if (type === 'affiliateName') return navigate(`/manage-affiliates/${business.id}/details`);
    return navigate(`/orders/${order.id}/detail`);
  };

  return (
    <MobileContainer>
      <DateText>
        {order.id}
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container data-testid="affiliateName" onClick={() => handleClick('affiliateName')}>
          <HeaderText xs={4}>Affiliate name</HeaderText>
          <DetailText xs={8}>
            {business.name}
          </DetailText>
        </TextBox>
        <TextBox container data-testid="order" onClick={() => handleClick('order')}>
          <HeaderText xs={6}>Order number</HeaderText>
          <DetailText xs={6}>
            {order.id}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Payment Status</HeaderText>
          <DetailText xs={6}>
            {status}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Invoice Value</HeaderText>
          <DetailText xs={6}>
            {`₦ ${invoiceValue}`}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Amount paid</HeaderText>
          <DetailText xs={6}>
            {`₦ ${amount}`}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Invoice number</HeaderText>
          <DetailText xs={6}>
            {id}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Credit days</HeaderText>
          <DetailText xs={6}>
            {creditDays}
          </DetailText>
        </TextBox>
      </ContentBox>
    </MobileContainer>
  );
}

MobileReturnRow.propTypes = {
  paymentItem: PropTypes.instanceOf(Object).isRequired
};
