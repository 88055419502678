import React from 'react';
import PropTypes from 'prop-types';
import {
  ProductContainer, ProductDetail, ProductDetailBrandNameText,
  ProductDetailPackSizeText, ProductDetailQty
} from './ProductItemStyles';
import CurrencyFormatter from '../../../../utils/currencyFormatter';

const ProductItem = ({ product }) => {
  const {
    name: brandName, meta, quantity, recieved,
    sellingPrice: itemPrice
  } = product;
  const { pack_size: packSize } = meta;
  const totalAmount = itemPrice * (recieved ? quantity : 0);
  return (
    <ProductContainer item container xs={12}>
      <ProductDetail item xs={5} direction="column">
        <ProductDetailBrandNameText>
          { brandName }
        </ProductDetailBrandNameText>
        <ProductDetailPackSizeText>
          Pack Size:&nbsp;
          { packSize }
        </ProductDetailPackSizeText>
      </ProductDetail>
      <ProductDetail item xs={2} direction="column">
        <ProductDetailQty received={recieved}>
          { recieved ? quantity : 0 }
        </ProductDetailQty>
      </ProductDetail>
      <ProductDetail item xs={2} direction="column">
        <ProductDetailQty received={recieved}>
          {CurrencyFormatter(itemPrice)}
        </ProductDetailQty>
      </ProductDetail>
      <ProductDetail item xs={2} direction="column">
        <ProductDetailQty received={recieved}>
          {CurrencyFormatter(totalAmount)}
        </ProductDetailQty>
      </ProductDetail>
    </ProductContainer>
  );
};

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

ProductItem.defaultProps = {
};

export default ProductItem;
