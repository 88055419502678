import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Box,
  DialogActions, DialogContent, DialogTitle,
  Grid,
  Slide
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { CREATE_CATEGORY_MUTATION, UPDATE_CATEGORY_MUTATION } from '../../mutations/categories';
import ModelActionTypes from '../../providers/reducers/model/modelTypes';
import SaleActionTypes from '../../providers/reducers/sales/salesTypes';
import { useStateValue } from '../../providers/stateProvider';
import { JSONParse } from '../../utils/json';
import { getCustomerObject } from '../../utils/utils';
import CustomInputBase from '../customComponents/customInputBase';
import CustomSelectInputBase from '../customComponents/customSelectInputBase';
import SavePopper from '../shared/crudModel/uploadModel/individual/savePopper';
import { parseCategoryFields } from '../shared/utils';
import {
  CircularProgressLoader,
  DialogActionButtonText,
  DialogCancelButton,
  DialogContainer,
  DialogOkButton,
  DialogTitleText,
  DialogTitleWrapper,
  GridWrapper, TopGrid
} from '../suppliers/individual/individualSupplierDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualCategoryDialog = ({
  dialogOpen, closeDialog, autoAddCustomerToSale,
  edit, categoryRefetch, catData
}) => {
  const {
    reorder_point: initialReorderPoint = 0,
    reorder_unit: initialReorderUnit = 'weeks',
    reorder_max: initialReorderMax = 0
  } = JSONParse(catData.meta || JSON.stringify({}));
  const initialState = {
    categoryName: catData.categoryName || '',
    vatStatus: catData.vatStatus || 'NO',
    markup: catData.markup || '',
    loyaltyWeight: catData.loyaltyWeight || '',
    reorderUnit: initialReorderUnit,
    reorderPoint: initialReorderPoint,
    reorderMax: initialReorderMax
  };

  const initialErrorState = {
    categoryNameError: false,
    vatStatusError: false,
    markupError: false,
    loyaltyWeightError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [editing, setEditing] = useState(edit);
  const { id } = useParams();

  const [{
    model: { model }, user: { allowedPermissionsMap: { userPermissions } }
  }, dispatch] = Object.values(useStateValue());

  const [createCategory] = useMutation(CREATE_CATEGORY_MUTATION);
  const [updateCustomer] = useMutation(UPDATE_CATEGORY_MUTATION);

  const {
    categoryNameError, vatStatusError, markupError, loyaltyWeightError
  } = errorState;
  useEffect(() => {
    if (model) {
      const {
        categoryId, categoryName, markup, loyaltyWeight, vatStatus,
        reorderPoint, reorderMax, reorderUnit
      } = parseCategoryFields(model);

      setEditing(true);

      setState((_state) => ({
        ..._state, categoryId, categoryName, markup, loyaltyWeight, vatStatus,
        reorderPoint, reorderMax, reorderUnit
      }));
    }
  }, [model]);

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { category: null, reFetch: true }
    });
    setTimeout(() => dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { reFetch: false }
    }), 0);
    closeDialog();
  };

  const addCategory = (addMore) => {
    const {
      categoryName, vatStatus, markup, loyaltyWeight,
      reorderUnit, reorderPoint, reorderMax
    } = state;
    createCategory({
      variables: {
        categoryName,
        vatStatus,
        markup: Number(markup),
        loyaltyWeight: Number(loyaltyWeight),
        reorderUnit,
        reorderPoint,
        reorderMax
      }
    })
      .then(({ data }) => {
        const { message, category: newCategory } = data?.createCategory || {};
        toast.success(message);
        if (!addMore) {
          handleCloseDialog();
        }
        if (autoAddCustomerToSale) {
          dispatch({
            type: SaleActionTypes.UPDATE_CUSTOMER,
            payload: {
              openedCustomerModal: false,
              ...getCustomerObject(newCategory)
            }
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCategory = () => {
    const {
      categoryName, vatStatus, markup, loyaltyWeight,
      reorderUnit, reorderPoint, reorderMax
    } = state;
    updateCustomer({
      variables: {
        categoryId: id,
        categoryName,
        vatStatus,
        markup,
        loyaltyWeight,
        reorderUnit,
        reorderPoint,
        reorderMax
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateCategory || {};
        toast.success(message);
        categoryRefetch();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    if (editing) return editCategory();
    return addCategory(addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const validateFields = () => {
    const {
      categoryName, vatStatus, markup, loyaltyWeight
    } = state;

    let valid = true;

    if (categoryName === '') {
      valid = false;
    } else if (vatStatus === '') {
      valid = false;
    } else if (markup === '') {
      valid = false;
    } else if (loyaltyWeight === '') {
      valid = false;
    }

    setErrorState({
      ...errorState,
      categoryNameError: categoryName === '',
      vatStatusError: vatStatus === '',
      markupError: markup === '',
      loyaltyWeightError: loyaltyWeight === '',
    });

    if (!valid) return false;
    return true;
  };

  const handleSaveButtonClick = (event) => {
    if (!validateFields()) return toast.error('Provide required fields');
    if (!editing) return setSaveButtonEl(saveButtonEl ? null : event.currentTarget);
    if (editing) return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'categoryName':
      case 'vatStatus':
      case 'markup':
      case 'loyaltyWeight':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !(value.length)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    validateState(name, value);
  };
  const topFields = [
    {
      name: 'categoryName', label: 'Category Name', required: true, helperText: 'Category name required', error: categoryNameError, placeholder: 'Enter category name'
    },
    {
      name: 'vatStatus', label: 'VAT Status', options: ['YES', 'NO'], required: true, helperText: 'Please set the vat status', error: vatStatusError
    },
  ];

  const secondLineField = [
    {
      name: 'markup', label: 'Markup (%)', required: true, helperText: 'Markup is required', error: markupError, placeholder: 'Enter markup',
      disabled: editing && !userPermissions?.includes('pricing_categories_edit_mark-up')
    },
    {
      name: 'loyaltyWeight', label: 'Loyalty Weight (%)', required: true, helperText: 'Loyalty weight is required', error: loyaltyWeightError, placeholder: 'Enter loyalty weight'
    },
  ];
  // const thirdLineField = [
  //   { name: 'reorderPoint', label: 'Reorder Point' },
  //   { name: 'reorderMax', label: 'Reorder Max' },
  // ];
  // const lastLineField = [
  //   { name: 'reorderUnit', label: 'Reorder Unit', options: ['weeks', 'fixed'] },
  // ];

  const returnTextField = (field, index) => {
    const {
      name: fieldName, label, helperText, error, val, placeholder, required,
      disabled
    } = field;
    const value = state[fieldName];

    const selectFieldNames = ['term', 'country', 'vatStatus', 'reorderUnit'];

    if (selectFieldNames.includes(fieldName)) {
      return (
        <Grid item xs={6}>
          <CustomSelectInputBase
            key={index}
            field={field}
            value={val || value}
            handleChange={handleChange}
            handleCreditDaysOpen={() => ({})}
            creditDays={() => ({})}
            showCheckBox={false}
            style={{ width: '336px', height: '51px' }}
            placeholder={placeholder}
            required={required}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={6}>
        <CustomInputBase
          variant="filled"
          label={label}
          value={value}
          size="small"
          step={0.01}
          type={fieldName === 'categoryName' ? 'text' : 'number'}
          error={error || false}
          helperText={error && helperText}
          name={fieldName}
          onChange={handleChange}
          style={{ width: '336px', height: '51px' }}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />
      </Grid>
    );
  };

  return (
    <>
      <DialogContainer
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
      >
        <Grid container>
          <GridWrapper container item xs={12}>
            <DialogTitle id="alert-dialog-slide-title">
              <Box style={{ textAlign: 'right' }}>
                <Close
                  fontSize="small"
                  style={{
                    position: 'absolute', right: '2rem', cursor: 'pointer',
                    marginTop: '14px', color: '#235A91'
                  }}
                  onClick={handleCloseDialog}
                />
              </Box>
              <Grid container style={{ marginTop: '1rem' }}>
                <Grid item xs={8}>
                  <DialogTitleWrapper container>
                    <DialogTitleText>
                      {editing ? 'Edit Category' : 'Add Category' }
                    </DialogTitleText>
                    {/* <DialogTitleSubText>
                      {editing
                        ? 'Edit the form to update category information'
                        : 'Add category information to the fields in the form'}
                    </DialogTitleSubText> */}
                  </DialogTitleWrapper>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <TopGrid container spacing={4}>
                {topFields.map((field, index) => returnTextField(field, index))}
              </TopGrid>
              <TopGrid container spacing={4}>
                {secondLineField.map((field, index) => returnTextField(field, index))}
              </TopGrid>
              {/* <TopGrid container spacing={4}>
                {thirdLineField.map((field, index) => returnTextField(field, index))}
              </TopGrid>
              <TopGrid container spacing={4}>
                {lastLineField.map((field, index) => returnTextField(field, index))}
              </TopGrid> */}
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleSaveButtonClick}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    {editing ? 'Save' : 'Save & ...'}
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </DialogContainer>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />
    </>
  );
};

IndividualCategoryDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  autoAddCustomerToSale: PropTypes.bool,
  edit: PropTypes.bool,
  categoryRefetch: PropTypes.func.isRequired,
  catData: PropTypes.instanceOf(Object)
};

IndividualCategoryDialog.defaultProps = {
  autoAddCustomerToSale: false,
  edit: false,
  catData: {}
};

export default IndividualCategoryDialog;
