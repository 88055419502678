import { Close } from '@mui/icons-material';
import {
  Grid, Typography, Button, LinearProgress, Link, Dialog, DialogTitle, IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseFillIcon, DeleteWhite } from '../../../../assets/svgs';

export const GridWrapper = styled(Grid)`
  flex-direction: column;
  padding: 1rem 0px;
`;

export const Image = styled('img')`
  max-width: 30%;
  max-height: 3.5rem;
  &:hover {
    cursor: pointer;
  }
`;

export const PreviewImage = styled('img')`
  width: 100%;
  max-height: 25rem;
`;

export const UrlLink = styled(Typography)`
  margin-top: 10px;
  font-weight: 500;
  font-size: .9rem;
  color: rgba(96, 96, 96, 0.60);
`;

export const LicenceImage = styled('img')`
  max-width: 100%;
  margin-top: 15px;
`;

export const LogoWrapper = styled(Grid)`
  position: relative;
`;

export const LicenceWrapper = styled(Grid)`
  width: 90%;
  padding-top: 1rem;
  margin-left: 2rem;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 20px;

  @media(max-width: 991px) {
    padding-top: 4rem;
    background: #FFFFFF;
    box-shadow: 0px 3.89397px 5.19196px rgba(0, 0, 0, 0.05);
    border-radius: 12.9799px;
  }
`;

export const LicenceHeader = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 600;
  color: #0F0F0F;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const ImageWrapper = styled(Grid)`
  align-items: center;
`;

export const SizeWrapper = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
`;

export const EditButton = styled(Button)`
  height: 50px;
  width: 150px;
  padding: 0px 15px;
  color: #FFFFFF;
  background-color: #235A91;
  border-radius: 5px;
  margin: 20px 2px;
  font-size: 13px;
  font-weight: 700;
  &:hover {
    background-color: #235A91;
    opacity: .7;
  }
  @media(max-width: 991px) {
    font-size: 27px;
    height: 5rem;
    width: 14rem;
  }
`;

export const DocumentsButton = styled(Button)`
  height: 50px;
  width: 80%;
  padding: 15px;
  color: #FFFFFF;
  background-color: #235A91;
  border-radius: 10px;
  margin: 20px 2px;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background-color: #235A91;
  }

  @media(max-width: 991px) {
    font-size: 27px;
    width: 80%;
    height: 68px;
  }
  &.MuiButton-root.Mui-disabled {
    color: #FFFFFF;
  }
`;

export const SizeText = styled(Typography)`
  font-size: 16px;
  text-align: right:
`;

export const FileText = styled(Typography)`
  font-size: .8rem;
  font-weight: 500;
  color: #606060;
  padding: 0px 10px;
`;

export const Preview = styled(Link)`
  color: #235A91;
  text-decoration: none;
  font-size: .8rem;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export const UploadWrapper = styled(Grid)`
  position: relative;
`;

export const FileContainer = styled(Grid)`
  justify-content: space-between;
  background: #F3F9FF;
  padding: 10px 20px;
  position: relative;

`;

export const FileWrapper = styled(Grid)`
  justify-content: space-between;
  border: 1px solid #11AF22;
  border-radius: 4px;
  padding: 12px 10px;
  margin-top: 1rem;
`;

export const FileUploading = styled(Grid)`
  justify-content: space-between;
  border: 0.5px solid #E3E3E3;
  border-radius: 4px;
  padding: 12px 10px;
  margin-top: 1rem;
`;

export const Delete = styled('img')`
  font-size: 3.5rem;
  color: #A3A3A3;
  &:hover {
      cursor: pointer;
    }

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const ProgressWrapper = styled(LinearProgress)`
  color: #000000;
  position: relative;
`;

export const LinearProgressLoader = styled(LinearProgress)`
  margin-top: 4px;
  position: initial;
  & .MuiCircularProgress-svg {
    width: 1.6rem;
  }
  .MuiLinearProgress-bar1Indeterminate {
    background-color: #FFF;
    & .MuiLinearProgress-barColorPrimary {
      background-color: #FFF;
  }
  }
`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
  }
`;

export const DialogTitleContainer = styled(DialogTitle)`
  width: 100%;
  margin-top: 15px;
`;

export const DialogTitleWrapper = styled(Grid)`
  margin-top: 1px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #000000;
  font-size: 22px;
`;

export const DialogsubText = styled(Typography)`
  font-weight: 400;
  color: #606060;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;
export const DialogOkButton = styled(IconButton)`
  height: 45px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 40px;
  margin-top: 40px;

  &:hover {
    background-color: #235A91;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 45px;
  width: 170px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  color: #235A91;
  border-radius: 5px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  border: 1px solid #235A91;
  `;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  display: flex;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DeleteIcon = styled(DeleteWhite)`
  width: 15px;
  margin-right: 5px;
`;

export const DeleteImage = styled(CloseFillIcon)`
  position: absolute;
  right: 0;
  top: -5px;
  cursor: pointer;
`;
