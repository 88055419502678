import { Grid, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`

`;

export const CustomListItem = styled(ListItem)`
  padding: 0 16px;
`;
