import { createTheme, styled } from '@mui/material/styles';
import {
  Typography, Grid, Divider
} from '@mui/material';

export const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%'
      },
      root: {
        backgroundColor: '#F8F8FF',
        borderColor: '#384EB74D',
      }
    }
  }
});

export const TitleText = styled(Typography)`
  font-size: 20px;
  font-family: Montserrat,medium,sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Dash = styled(Divider)`
  width: 32%;
  margin: 2px 8px;
`;

export const MiddleText = styled(Typography)`
  font-family: Montserrat,medium,sans-serif;
  font-size: 12px;
`;
export const BackgroundImage = styled('img')`
  height: 70px;
  margin-bottom: 20px;
`;

export const MiddleGroup = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 30px 0 40px;
`;

export const BrowseFileButton = styled(Typography)`
  font-size: 20px;
  font-family: Montserrat,medium,sans-serif;
  padding-left: 5px;
`;

export const DropZoneContainer = styled(Grid)`
  padding: 0 4rem;
`;
