import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Paper, Table, TableBody } from '@mui/material';
import { useParams } from 'react-router-dom';
import TablePagination from '../../shared/tablePagination';
import { GET_ORDERS_BY_STATUS } from '../../../queries/orders';
import { TableContainerWrapper, DivFullWidth } from './orders.styles';
import { TableComponent, TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import ReturnRow from './returnRow';
import OrdersFilter from './ordersFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const headers = [
  { name: 'Order Date', width: '200px' }, { name: 'Order Number', width: '200px' }, { name: 'Value', width: '200px' },
  { name: 'Affiliate', width: '320px' }, { name: 'Estimated Delivery', width: '220px' }, { name: 'Order Status', width: '200px' }
];

const Orders = () => {
  const params = useParams();
  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    orderNo: '',
    orderStatus: params?.status || 'all',
    affiliate: '',
    dateFrom: '',
    dateTo: '',
    filters: {}
  };

  const [state, setState] = useState(initialState);
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [filters, setFilters] = useSearchParamsState('filters', '');

  const vals = filters.split(',');

  useEffect(() => {
    setState((s) => ({
      ...s, orderStatus: vals[0] || 'all', orderNo: Number(vals[1]) || '', affiliate: vals[2] || '', dateFrom: vals[3] || '', dateTo: vals[4] || '',
    }));
  }, [filters]);

  const {
    orderNo, orderStatus, affiliate, dateFrom, dateTo
  } = state;

  const handleSetFilter = () => {
    const values = `${orderStatus},${orderNo},${affiliate},${dateFrom},${dateTo}`;
    setFilters(values);
    setState({
      ...state,
      filters: {
        orderNo: Number(orderNo),
        status: orderStatus,
        affiliateName: affiliate,
        dateFrom,
        dateTo
      }
    });
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader style={{ minWidth: width }} className={`erp-uat-${name.replace(/ /g, '-')}`} key={name}>
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    pageCount,
    pageNumber,
    status: params?.status || vals[0],
    orderNo: Number(vals[1]),
    affiliateName: vals[2],
    dateFrom: vals[3],
    dateTo: vals[4],
  };

  const {
    loading, error, data
  } = useQuery(GET_ORDERS_BY_STATUS, {
    fetchPolicy: 'no-cache',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    ordersByStatus = [], ordersTotalNumber = 0,
  } = data || {};

  return (
    <>
      <OrdersFilter
        state={state}
        handleFilterChange={handleFilterChange}
        handleSetFilter={handleSetFilter}
        setState={setState}
      />
      <TableContainerWrapper component={Paper}>
        {loading ? <ProductsTableLoader /> : (
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {ordersByStatus.map((order) => (
                  <ReturnRow
                    state={state}
                    key={order.id}
                    row={order}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
        <DivFullWidth>
          {ordersTotalNumber > 0 && (
          <TablePagination
            total={ordersTotalNumber}
            pageCount={+pageCount}
            setPageCount={setPageCount}
            pageNumber={+pageNumber}
            setPageNumber={setPageNumber}
          />
          )}
        </DivFullWidth>
      </TableContainerWrapper>
    </>
  );
};

export default Orders;
