import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Slide, Grid } from '@mui/material';
import { toast } from 'react-hot-toast';
import {
  CDialog, GridWrapper, DialogTitleText, CloseIcon, DialogContentStyled,
  DialogActions, DialogActionGrid, IndividualGrid, SupplierLogo, SupplierName,
} from './supplierDialog.styles';
import CustomNewTextField from '../../shared/CustomSearchField';
import { FIND_SUPPLIERS_QUERY } from '../../../queries/suppliers';
import CTablePagination from '../../customComponents/cTablePagination';
import TableLoader from '../../customComponents/loaders/productsTableLoader';
import { UPDATE_PRODUCT_SUPPLIER } from '../../../mutations/suppliers';
import SuccessDialog from '../../shared/successDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const SupplierDialog = ({
  open, close, productId, refetchCart
}) => {
  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    searchValue: '',
  };

  const [state, setState] = useState(initialState);
  const [updateSupplier] = useMutation(UPDATE_PRODUCT_SUPPLIER);
  const [successDialog, setSuccessDialog] = useState(false);
  const {
    currentPage, currentPageCount, searchValue
  } = state;

  const variables = {
    pageCount: currentPageCount,
    pageNumber: currentPage,
    search: searchValue
  };

  const {
    loading, data
  } = useQuery(FIND_SUPPLIERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
    onlyBusinessSuppliers: true,
  });

  const rows = data?.findSupplier || [];
  const totalNumber = data?.supplierTotalNumber || 0;

  const handleChange = (event) => {
    const { value } = event.target;
    setState({ ...state, searchValue: value });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleClose = () => setSuccessDialog(false);
  const handleSelectSupplier = (id) => {
    const supplierAndProduct = [{ productId, supplierId: id }];
    updateSupplier({ variables: { supplierAndProduct } })
      .then(() => {
        close();
        setSuccessDialog(true);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <CDialog
      data-testid="product-dialog"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container>
          <Grid item container xs={6}>
            <DialogTitleText>Suppliers</DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={close} />
          </Grid>
        </Grid>
        <DialogContentStyled>
          <CustomNewTextField
            value={searchValue}
            fullWidth
            placeholder="Search For Suppliers"
            handleChange={handleChange}
          />
        </DialogContentStyled>

        {loading ? (
          <TableLoader />
        )
          : (
            <DialogActions>
              {rows?.map(({ id, logo, name }) => (
                <DialogActionGrid container key={id} onClick={() => handleSelectSupplier(id)}>
                  <IndividualGrid item xs={12} container>
                    <IndividualGrid xs={2}>
                      <SupplierLogo src={logo} alt={name} />
                    </IndividualGrid>
                    <IndividualGrid xs={10}>
                      <SupplierName>{name}</SupplierName>
                    </IndividualGrid>
                  </IndividualGrid>
                </DialogActionGrid>
              ))}
              <CTablePagination
                rowsPerPageOptions={[100]}
                component="div"
                count={totalNumber}
                page={currentPage - 1}
                onPageChange={handleChangePage}
                rowsPerPage={currentPageCount}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </DialogActions>
          )}

        <SuccessDialog
          openDialog={successDialog}
          setOpenDialog={handleClose}
          title="Supplier Updated"
          desc="Hi Affiliate, supplier has been updated successfully!"
          option="Ok"
          refetch={refetchCart}
        />
      </GridWrapper>
    </CDialog>
  );
};

SupplierDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  refetchCart: PropTypes.func.isRequired
};

export default SupplierDialog;
