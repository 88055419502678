import React from 'react';
import { Grid } from '@mui/material';

export default function FilterTag(props) {
  const {
    tag, filterType, toggleFilter, filterKey
  } = props;
  return (
    <Grid
      style={{
        padding: '6px', paddingRight: '10px', paddingLeft: '10px',
        background: '#CCCCCC', borderRadius: '5px', marginRight: '20px'
      }}
      justifyContent="space-between"
      container
    >
      <Grid item>
        { tag }
      </Grid>
      <Grid onClick={() => toggleFilter(filterKey, filterType)} style={{ cursor: 'pointer' }} item>x</Grid>
    </Grid>
  );
}
FilterTag.defaultProps = {
  suppliers: []
};
