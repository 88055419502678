import { TableRow, TableCell, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const CustomInputNumberBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 40%;
  border-radius: 10px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 16px 12px;
    padding-right: 5px;
    line-height: normal;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;
