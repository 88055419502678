import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo, Delete
} from './actionPopper.styles';
import DeleteIcon from '../../../assets/images/DeleteIcon.png';
import EditIcon from '../../../assets/images/EditIcon.png';

export default function ActionPopper({
  open, deleteAction, close, supplierAction
}) {
  return (
    <RenderPopper
      open={open}
      anchorEl={open}
      popperPlacement="bottom"
      onClickAway={close}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer
          className="erp-uat-add-to-cart"
          container
          item
          onClick={deleteAction}
        >
          <ItemWrapper>
            <Delete alt="Delete" src={DeleteIcon} />
            <ItemTypo variant="subtitle1">
              Remove Product
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          className="erp-uat-inventory-add-batches"
          container
          item
          onClick={supplierAction}
        >
          <ItemWrapper>
            <Delete alt="Change Supplier" src={EditIcon} />
            <ItemTypo variant="subtitle1">
              Change Supplier
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  deleteAction: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  supplierAction: PropTypes.func.isRequired
};
