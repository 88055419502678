import {
  Grid, Typography, Paper
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const CardPaper = styled(Paper)`
    height: 7rem;
    width: 100%;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #CCCCCC;
    border: 0.5px solid #CCCCCC80;
    border-radius: 7px;
    opacity: 1;
    padding: 5px 0;
    position: relative;
    cursor: pointer;
`;

export const CardHeader = styled(Grid)`
    height: 25px;
    border-bottom: 0.5px solid rgba(204,204,204,.45);
    justify-content: flex-end;
`;

export const HeaderText = styled(Typography)`
    font-size: 12px;
    font-weight: 500;
    color: #A3A3A3;
    text-transform: uppercase;
    padding-right: 15px;
    padding-bottom: 10px;
`;

export const CardContentGrid = styled(Grid)`
    margin-top: 25px;
    padding: 0 15px;
    flex-direction: column;
`;

export const ContentHeader = styled(Typography)`
    color: #424242;
    font-size: 16px;
    font-weight: 600;
`;

export const ContentMeta = styled(Typography)`
    font-size: 12px;
    color: #A3A3A3;
`;

export const ContentSubMeta = styled(ContentMeta)`
    margin-top: -30px;
`;

export const HeaderIcon = styled('img')`
width: 2.5rem;
position: absolute;
top: 7px;
left: 20px;
`;

export const FooterMeta = styled(Grid)`
`;
