import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import MainContent from '../customComponents/mainContent';
import { ARCHIVED_ERP_NOTIFICATIONS, ERP_NOTIFICATIONS } from '../../queries/notifications';
import NotificationListItem from './notificationListItem';
import TablePagination from '../shared/tablePagination';
import Filters from './filter';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import {
  GridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle, SecondaryTitle,
  PaperWrapper, CList, NNotificationBody, NotificationIcon, DescTypo, FooterWrapper
} from './notificationsArchive.styles';

import { useStateValue } from '../../providers/stateProvider';

const NotificationsArchive = () => {
  const [data, setData] = useState({
    notifications: [],
    totalLength: 0,
    loading: false
  });
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [, dispatch] = Object.values(useStateValue());
  const [tabVal, setTabVal] = useState('all');

  const navigate = useNavigate();
  const { tab } = useParams();

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  useEffect(() => {
    setTabVal(tab);
  }, [tab]);

  const handleTabChange = (value) => {
    navigate(`/notifications/${value}`);
  };

  const { data: allData, loading: allLoading } = useQuery(ERP_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageCount,
      pageNumber
    }
  });

  const { data: archivedData, loading: archiveLoading } = useQuery(ARCHIVED_ERP_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageCount,
      pageNumber
    }
  });

  useEffect(() => {
    switch (tabVal) {
      case 'all':
        setData({
          notifications: allData?.erpNotifications,
          totalLength: allData?.erpNotificationsTotalNumber,
          loading: allLoading
        });
        break;
      case 'archive':
        setData({
          notifications: archivedData?.archivedErpNotifications,
          totalLength: archivedData?.archivedErpNotificationsTotalNumber,
          loading: archiveLoading
        });
        break;
      default:
        setData({
          notifications: allData?.erpNotifications,
          totalLength: allData?.erpNotificationsTotalNumber,
          loading: allLoading
        });
    }
  }, [allData, archivedData, tabVal]);

  const { notifications, totalLength, loading } = data ?? [];

  return (
    <MainContent>
      <GridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <PrimaryTitle data-testid="notificationHeader" variant="h5">All Notifications</PrimaryTitle>
            <SecondaryTitle variant="caption">See All Message Notifications, Archived and Cleared Push Notifications</SecondaryTitle>
          </TitleTextGridContainer>
        </TitleGridContainer>
        <PaperWrapper elevation={0}>
          <Filters
            tabVal={tabVal}
            handleTabChange={handleTabChange}
            messagesTotal={allData?.erpNotificationsTotalNumber}
            archivesTotal={archivedData?.archivedErpNotificationsTotalNumber}
          />
          {loading ? <ProductsTableLoader /> : (
            !notifications?.length ? (
              <NNotificationBody>
                <NotificationIcon />
                <DescTypo variant="caption">No Notifications Yet!</DescTypo>
                All notifications will be displayed here.
              </NNotificationBody>
            ) : (
              <CList>
                {notifications?.map((item) => <NotificationListItem key={item?.id} item={item} />)}
              </CList>
            )
          )}

          <FooterWrapper item container>
            {totalLength > 0 && (
              <TablePagination
                total={totalLength}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default NotificationsArchive;
