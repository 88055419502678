import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@mui/material';
import { AffiliateBodyCell, MainTableRow } from '../dashboard/dashboard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import ReconciliationActionTypes from '../../../providers/reducers/reconciliation/reconciliationTypes';
import { useStateValue } from '../../../providers/stateProvider';

const ReturnOutOfStockRow = ({
  outOfStockSale,
}) => {
  const [, dispatch] = Object.values(useStateValue());
  const {
    id,
    quantity,
    total,
    saleItem: {
      product,
      sale: {
        dateCreated,
        receiptNumber,
        businessUser: {
          user: {
            username
          }
        }
      },
      name,
      packSize,
    }
  } = outOfStockSale;
  const selectForReconciliation = () => {
    dispatch({ type: ReconciliationActionTypes.SELECT_FOR_RECONCILIATION, payload: { ...product, targetQuantity: quantity, saleItemBatchId: id } });
  };
  return (
    <MainTableRow onClick={selectForReconciliation}>
      <AffiliateBodyCell />
      <AffiliateBodyCell>
        {dateCreated ? moment(dateCreated).format('lll') : 'N/A'}
      </AffiliateBodyCell>

      <AffiliateBodyCell>
        <Grid>{name}</Grid>
      </AffiliateBodyCell>

      <AffiliateBodyCell>
        <Grid>{packSize}</Grid>
      </AffiliateBodyCell>
      <AffiliateBodyCell>
        <Grid>{quantity}</Grid>
      </AffiliateBodyCell>
      <AffiliateBodyCell>
        <Grid>{receiptNumber}</Grid>
      </AffiliateBodyCell>
      <AffiliateBodyCell>
        <Grid>{username.split('@')[0]}</Grid>
      </AffiliateBodyCell>
      <AffiliateBodyCell>
        {currencyFormatter(total)}
      </AffiliateBodyCell>

    </MainTableRow>
  );
};

ReturnOutOfStockRow.propTypes = {
  outOfStockSale: PropTypes.instanceOf(Object).isRequired,
};

export default ReturnOutOfStockRow;
