import UserActionTypes from './userTypes';
import { updateUserSession } from './userUtils';

const userReducer = (user, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_USER_SESSION:
      return {
        ...user,
        ...updateUserSession(action.payload)
      };
    case UserActionTypes.UPDATE_USER:
      return {
        ...user,
        ...action.payload
      };
    default:
      return user;
  }
};

export default userReducer;
