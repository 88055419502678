import {
  Grid, IconButton, TextField, Typography, Dialog, TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/CloseRounded';

export const SalesGridContainer = styled(Grid)`
  padding: 30px;
`;

export const SaleFooterGrid = styled(Grid)`
  gap: 30px 40px;
  align-items: center;
  padding: 19px 11px 10px;
  display: flex;
  margin-bottom: 10px;
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };

  @media(min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 10px;

  & .MuiOutlinedInput-input {
    font-size: 2rem;
    padding: 30px;
  };
  & .MuiOutlinedInput-root {
    border-radius: 10px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    & .MuiOutlinedInput-input {
      font-size: 1rem;
      padding: 15px 14px;
    };
  }
`;

export const SearchButton = styled(IconButton)`
  padding: 19px 28px;
  background-color: #FCED50;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #D2C647;
  }

  &:disabled {
    color: #424242;
    background-color: #FCED50;
  }

  @media(min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 60px;

  @media(min-width: 992px) {
    width: 30px;
  }
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3rem;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const TextWrapper = styled(Grid)`
  margin-left: 1rem;
`;

export const SearchBoxWrapper = styled(Grid)`
  padding-bottom: 10px;
  align-items: center;
  width:  97.5%;

  @media(max-width: 991px) {
    justify-content: space-between;
  }
`;

export const SaleFooter = styled('div')`
  width: 98%;
  background: white;
  border-radius: 7px;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  margin-top: 1.5%;
`;

export const SaleFooterIcon = styled('img')`
  width: 10%;
  cursor: pointer;
`;

export const SaleAffiliateList = styled('div')`
  position: absolute;
  left: 516px;
  background: #ffffff;
  width: 270px;
  bottom: 20px;
  box-shadow: 1px 2px 3px #00000029;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #eeeeee;
  padding: 20px;
`;

export const SaleSearchResult = styled('div')`
  background: white;
  width: 70%;
  position: absolute;
  z-index: 200;
  box-shadow: 1px 2px 3px #00000029;
  border-radius: 0px 0px 7px 7px;
  border: 1px solid #eeeeee;
`;

export const SaleSearchResultItem = styled('div')`
  padding: 5px 15px;
  cursor: pointer;
  &:hover {
    background: #f4f4f4
  }
`;

export const ReceiptDialog = styled(Dialog)`

  &.MuiDialog-paperWidthSm {
    max-width: 60% !important;
    width: 100%;
    background: red;
  }

  &.MuiDialog-paperWidthXl {
    max-width: 1920px;
  }
`;

export const IconWrap = styled('div')`
  width: 150px;
  text-align: center;
  height: 150px;
  background: white;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 50px;
  cursor: pointer;

  &:hover {
    border: 1px solid #fced50;
  }

  &.disabled {
    border: 0;
    cursor: auto;
  }
`;

export const ReceiptWrap = styled('div')`
  padding: 2%;
  background: rgb(255, 255, 255);
  width: 65%;
  margin: 0 auto;
`;

export const ItemWrap = styled('div')`
  border-bottom: 1px solid #ddd;
  margin-bottom: 3%;
  padding: 3% 0;
`;

export const ReceiptHeader = styled('div')`
  text-align: center;
`;

export const ReceiptP = styled('p')``;

export const ReceiptH3 = styled('h3')`
  margin: 0;
`;

export const ReceiptNumDate = styled('div')`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

export const ReceiptGridLeft = styled(Grid)`
  text-align: left;
`;

export const ReceiptGridRight = styled(Grid)`
  text-align: right;
`;

export const ReceiptTableCell = styled(TableCell)`
  border: 0;
  padding: '5px';
`;

export const ClearIcon = styled(CloseIcon)`
  font-size: ${(props) => (props.small ? '1.25rem' : '1.85rem')};

  @media(max-width: 992px) {
    font-size: ${(props) => (props.small ? '2.5rem' : '3.8rem')};
  }
`;

export const ButtonIcon = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 10rem;
`;

export const AddImage = styled('img')`
  width: 2rem;

  @media(min-width: 992px) {
    width: 1rem;
  }
`;
