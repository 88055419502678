import { Grid, Popper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CustomButton from '../../../customComponents/customButton';

export const CPopper = styled(Popper)`
  z-index: 15000000
`;

export const WrapperGrid = styled(Grid)`
  width: 24.3vw;
  padding: 10px 0;
`;

export const SupplierItemGrid = styled(Grid)`
  padding: 5%;
  cursor: pointer;

  &:hover {
    background: #e8e8e8;
  }
`;

export const SimpleTypo = styled(Typography)`

`;

export const TCustomButton = styled(CustomButton)`
  border: 1px solid #000000;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  height: 2.5rem;
  margin-top: 10px;
`;
