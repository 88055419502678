import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, DialogContentText, Grid, Slide
} from '@mui/material';
import {
  CardTitle, DialogContentStyled, TitleDesc, CDialog, CloseIcon
} from './mapOrderCSV.style';
import MapCsvDialog from '../../sales/mapCsvDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export default function MapOrderCsvDialog({ openProdDialog, toggleHandler }) {
  const fields = [
    ['Product ID', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Product Name', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Pack Size', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Order Qty', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false
    }],
  ];
  const experimentalFields = [];

  return (
    <div>
      <CDialog
        data-testid="product-dialog"
        open={openProdDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleHandler}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <DialogContentText>
            <Grid>
              <Box display="flex" justifyContent="space-between">
                <CardTitle>Create Bulk Upload</CardTitle>
                <CloseIcon
                  fontSize="small"
                  onClick={toggleHandler}
                />
              </Box>
              <TitleDesc>Upload CSV file here</TitleDesc>
              <MapCsvDialog
                experimentalFields={experimentalFields}
                fields={fields}
                model="order"
                endpoint="orders"
                backRoute=""
              />
            </Grid>
          </DialogContentText>
        </DialogContentStyled>
      </CDialog>
    </div>
  );
}

MapOrderCsvDialog.propTypes = {
  openProdDialog: PropTypes.bool,
  toggleHandler: PropTypes.func
};

MapOrderCsvDialog.defaultProps = {
  openProdDialog: false,
  toggleHandler: () => null,
};
