import PropTypes from 'prop-types';
import React from 'react';
import { JSONParse } from '../../../utils/json';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  BodyCell,
  HideMd,
  MainTableRow, ProductDetail,
  ProductDetailBrandNameText,
  ProductDetailImage,
  ProductDetailNameMainContainer,
  ProductDetailPackSizeText
} from './styles';

const ReturnRow = ({ product, index }) => {
  const {
    unitCost, price, name: brandName, product: { meta }, quantity, quantityRecieved
  } = product;
  const prodMeta = JSONParse(meta.replace(/'/g, '"'));

  return (
    <MainTableRow style={index % 2 ? { backgroundColor: '#F4F7FC' } : { backgroundColor: '#FFF' }}>
      <BodyCell>
        <ProductDetail>
          <ProductDetailImage src={prodMeta?.image} alt="" />
          <ProductDetailNameMainContainer>
            <ProductDetailBrandNameText>
              { brandName }
            </ProductDetailBrandNameText>
            <HideMd>
              <ProductDetailPackSizeText>
                {`Pack Size: ${prodMeta?.pack_size ?? 'N/A'}`}
              </ProductDetailPackSizeText>
            </HideMd>
          </ProductDetailNameMainContainer>
        </ProductDetail>
      </BodyCell>
      <BodyCell>{Number(quantity)}</BodyCell>
      <BodyCell>{quantityRecieved}</BodyCell>
      <BodyCell>{`₦ ${currencyFormatter(unitCost)}`}</BodyCell>
      <BodyCell>{`₦ ${currencyFormatter(price)}`}</BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
};

export default ReturnRow;
