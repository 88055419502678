import { styled } from '@mui/material/styles';
import {
  Grid, Paper, TableCell, Typography, Box,
  TableContainer, Button
} from '@mui/material';

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 30px;
`;

export const DivFullWidth = styled('div')`
  width: 100%;
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HeaderGridItem = styled(Grid)``;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const HeaderCell = styled(TableCell)`
  background-color: #F3F9FF;
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.5rem;
  padding: 10px;
  color: #303030;

  @media (min-width: 992px) {
    &:nth-child(1), :nth-child(2), :nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 10%;
    }
  }

  @media(max-width: 991px) {
    font-size: 1.4rem;
  }
`;

export const TableBox = styled(Box)`
  margin-top: 20px;
`;

export const TableContent = styled(TableContainer)`
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 2rem;
`;

export const BackButton = styled(Button)`
  border: none;
  padding: 30px 30px 0 40px;
  font-size: .9rem;
  color: #235A91;
  &:hover {
    border: none;
  }
`;

export const ArrowImg = styled('img')`
  width: 7px;
`;

export const TitleGridContainer = styled(Grid)`
  padding: 1rem 3rem 1rem 2.5rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const CreateUser = styled(Button)`
  min-width: 13rem;
  height: 45px;
  background: #235A91;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;
