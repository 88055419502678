import {
  Grid, Typography, TextareaAutosize, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FeedbackContainer = styled(Grid)`
    min-height: 100vh;
    background: white;
    padding: 0 100px;
`;

export const TextContainer = styled(Grid)`
    text-align: center;
    margin-bottom: 10px;
`;

export const BigText = styled(Typography)`
    font-size: 30px;
    color: #424242;
    font-weight: 600;
    margin-bottom: 10px;
`;

export const EmojiText = styled(Typography)`
    font-size: 16px;
    color: #A3A3A3;
    margin-bottom: 10px;
`;
export const SmallText = styled(Typography)`
    color: #424242;
    font-size: 18px;
`;
export const NavContainer = styled(Grid)`
    height: 100px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;


    @media(max-width: 1300px) {
        margin-bottom: 30px;

    }
`;

export const NavLogo = styled('img')`
    width: 166px;
    height: 40px;
`;

export const EmojiContainer = styled('div')`
    cursor: pointer;
    margin: 40px;
    text-align: center;
`;

export const TextArea = styled(TextareaAutosize)`
    border: 0.5px solid #424242;
    border-radius: 7px;
    font-family: 'Montserrat';
    font-size: 16px;
    padding: 20px 30px;
    resize: none;
    margin: 20px auto 30px;
    width: 60%;

    &:focus {
        outline: none;
    }
`;

export const Emoji = styled('img')`
    width: 60px;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin: 1px;
`;
