import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import CartDropdown from './cartDropdown';
import currencyFormatter from '../../../utils/currencyFormatter';
import { useStateValue } from '../../../providers/stateProvider';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { round } from '../../../utils/utils';
import {
  CaretUpIcon, CaretDownIcon, DotsV
} from '../../../assets/svgs';
import {
  CartItemPack, CartItemTitle, MissedSaleIcon, ButtonsWrapper, GridCell,
  CustomInputBase, PriceGrid, DiscountGrid, CurrencyTypo
} from './cartRow.styles';

const CartRow = ({ item }) => {
  const [calDiscount, setCalDiscount] = useState('');
  const [showDiscount, setShowDiscount] = useState(false);
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [{ sale: { cart }, user }, dispatch] = Object.values(useStateValue());
  const { allowedPermissionsMap: { userPermissions } } = user;

  const changeQty = (quantity) => {
    setDotsButtonEl(null);
    if (quantity > 0 && item.batches.size) {
      const cartItem = cart.get(item.productId);
      dispatch({ type: SaleActionTypes.UPDATE_BATCH_TO_CART, payload: cartItem.product });
      return;
    }
    dispatch({
      type: SaleActionTypes.UPDATE_CART_QUANTITY,
      payload: { product: item, quantity }
    });
  };

  const handleShowDiscount = () => {
    setDotsButtonEl(null);
    setShowDiscount(!showDiscount);
  };

  const missedSale = () => {
    setDotsButtonEl(null);
    dispatch({
      type: SaleActionTypes.TOGGLE_MISSED_SALE,
      payload: { product: item }
    });
  };

  const getItemTotal = () => item.quantity * item.resolvedPriceInUseValue;

  const getDiscountPercentage = () => {
    const disc = item?.discount || 0;
    const value = getItemTotal();
    return round((disc * 100) / value);
  };

  const handleDiscount = (e) => {
    const { value } = e?.target ?? '';
    if (value && (+value < 0 || +value > getItemTotal())) return;
    setCalDiscount(value);
    dispatch({
      type: SaleActionTypes.ADD_PRODUCT_DISCOUNT,
      payload: {
        prodToDiscount: item,
        discount: Number(value),
        isPercentage: false
      }
    });
  };

  const discountName = showDiscount ? 'Remove Discount' : 'Add Discount';

  const options = [
    { name: 'Missed Sale', action: () => missedSale() },
    { name: discountName, action: () => handleShowDiscount() },
    { name: 'Remove', action: () => changeQty(0) }
  ];

  const optionsNoAccess = [
    { name: 'Missed Sale', action: () => missedSale() },
    { name: 'Remove', action: () => changeQty(0) }
  ];

  return (
    <Grid item container style={{ borderBottom: '.5px solid #e1e1e1' }}>
      <GridCell item container>
        <CartItemTitle>{item.brandName}</CartItemTitle>
        <CartItemPack>
          {`${item.packSize} at `}
          <b>{currencyFormatter(item.resolvedPriceInUseValue)}</b>
        </CartItemPack>
      </GridCell>
      <GridCell item container>
        <CustomInputBase
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          value={item.quantity}
          onClick={({ target: { value } }) => value > 0 && changeQty(value)}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  style={{ width: '.8rem', height: '.8rem', cursor: 'pointer' }}
                  onClick={() => changeQty(+item.quantity + 1)}
                />
                <CaretDownIcon
                  style={{ width: '.8rem', height: '.8rem', cursor: 'pointer' }}
                  onClick={() => +item.quantity > 1 && changeQty(+item.quantity - 1)}
                />
              </Grid>
            </InputAdornment>
          )}
        />
      </GridCell>
      <GridCell item container>
        <Grid container alignItems="flex-start" style={{ textAlign: 'center' }}>
          <PriceGrid item md={8} style={{ textDecoration: item.missedSale ? 'line-through' : 'none' }}>
            {currencyFormatter(getItemTotal())}
          </PriceGrid>
          <ButtonsWrapper item md={4}>
            <MissedSaleIcon
              size="small"
              key={item.brandName}
              onClick={(e) => setDotsButtonEl(e.currentTarget)}
            >
              <DotsV style={{ fontSize: '.8rem' }} />
            </MissedSaleIcon>
          </ButtonsWrapper>
        </Grid>
      </GridCell>

      {showDiscount ? (
        <DiscountGrid item container>
          <Grid item container alignItems="center" md={6}>
            <CustomInputBase
              type="number"
              placeholder="0.00"
              value={calDiscount}
              onChange={handleDiscount}
              style={{ width: '65%' }}
              discount
              startAdornment={(
                <InputAdornment position="start">
                  <CurrencyTypo>₦</CurrencyTypo>
                </InputAdornment>
              )}
            />
          </Grid>
          <Grid item md={6} style={{ textAlign: 'right' }}>
            {`${getDiscountPercentage()} %`}
          </Grid>
        </DiscountGrid>
      ) : ''}
      {userPermissions?.includes('sales_discount') ? (
        <CartDropdown
          dotsButtonEl={dotsButtonEl}
          setDotsButtonEl={setDotsButtonEl}
          options={options}
        />
     ) : (
       <CartDropdown
         dotsButtonEl={dotsButtonEl}
         setDotsButtonEl={setDotsButtonEl}
         options={optionsNoAccess}
       />
     )}
    </Grid>
  );
};

CartRow.propTypes = {
  item: PropTypes.instanceOf(Object),
};

CartRow.defaultProps = {
  item: [],
};

export default CartRow;
