import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  MobileContainer, DetailText, HeaderText, TextBox, DateText,
  ContentBox, ReasonText,
} from './returnRow.styles';

export default function MobileReturnRow({
  row
}) {
  const {
    dateCreated, actionDone, editedBy, reasonForUpdate
  } = row;

  const createdDate = moment(dateCreated).format('DD/MM/YY (h:mm)');
  return (
    <MobileContainer>
      <DateText>
        {createdDate}
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container>
          <HeaderText xs={4}>Action done</HeaderText>
          <DetailText xs={8}>
            {actionDone}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>Edited by</HeaderText>
          <DetailText xs={8}>
            {editedBy}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={4}>Reason</HeaderText>
          <ReasonText xs={8}>
            {reasonForUpdate}
          </ReasonText>
        </TextBox>
      </ContentBox>
    </MobileContainer>
  );
}

MobileReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

MobileReturnRow.defaultProps = {
  row: {},
};
