import { Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import RangePicker from 'react-range-picker';
import { BlueCalendar } from '../../../assets/svgs';
import { PlaceholderInput } from './customDateRanges.styles';

export const CustomDateRanges = ({
  handleSubmit, xs, styles, state
}) => {
  const [dateRange, setDateRange] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });

  function dateChangeHandler(start, end) {
    setDateRange({
      dateFrom: start,
      dateTo: end
    });
  }

  const handleRange = () => {
    const { dateFrom, dateTo } = dateRange;
    handleSubmit({ dateFrom, dateTo });
    // if (dateFrom && dateTo) {
    //   const _dateTo = moment(dateTo).add({ hours: 23, minutes: 59, seconds: 59 }).toDate();
    //   handleSubmit({ dateFrom, dateTo: _dateTo });
    // }
  };

  useEffect(() => {
    const { dateFrom, dateTo } = state;
    if (dateFrom && dateTo) {
      setDateRange({
        dateFrom, dateTo
      });
    }
  }, [state]);

  const placeholder = () => {
    const { dateFrom, dateTo } = dateRange;

    return (
      <PlaceholderInput item container style={styles}>
        <BlueCalendar style={{ fontSize: '.9rem', margin: '0 0.5rem' }} />
        {`${dateFrom && moment(dateFrom).format('ll')} `}
        &nbsp;-&nbsp;
        {` ${dateTo && moment(dateTo).format('ll')}`}
      </PlaceholderInput>
    );
  };

  return (
    <Grid item container className="uat-date-filter" xs={xs} alignItems="center" w="100">
      <RangePicker
        placeholder={placeholder}
        onDateSelected={(start, end) => dateChangeHandler(start, end)}
        onClose={handleRange}
        rangeTillEndOfDay
        styles={{ width: '100%' }}
      />
    </Grid>
  );
};

CustomDateRanges.propTypes = {
  xs: PropTypes.number,
  styles: PropTypes.shape(Object),
  handleSubmit: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object)
};

CustomDateRanges.defaultProps = {
  xs: 5,
  styles: {},
  state: {}
};

export default CustomDateRanges;
