import React, { useState } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
// import CustomButton from '../customComponents/customButton';
import Products from './products/products';
import Cart from './cart/cart';
import IndividualProductDialog from '../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import SelectBatchDialog from './products/selectBatchDialog';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
} from './salesContainer.styles';
import CreateBatches from './createBatches';
import CreateProduct from './createProduct';
import ActionButtonsPopper from './actionButtonsPopper';

import { useStateValue } from '../../providers/stateProvider';

const SalesContainer = () => {
  const [{
    product: { addProductDialogOpen }
  }, dispatch] = Object.values(useStateValue());

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const toggleIndividualDialog = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const [openDialog, setOpenDialog] = useState(false);
  const toggleCreateBatchHandler = () => {
    setOpenDialog(!openDialog);
  };

  const [openProdDialog, setOpenProdDialog] = useState(false);
  const toggleCreateProductHandler = () => {
    setOpenProdDialog(!openProdDialog);
  };

  const handleAction = (type, setOpen) => {
    if (type === 'individual_prod') {
      toggleIndividualDialog();
    } else if (type === 'bulk_prod') {
      toggleCreateProductHandler();
    }
    setOpen(false);
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item justifyContent="space-between">
          <TitleTextGridContainer item>
            <PrimaryTitle variant="h5">Sales</PrimaryTitle>
          </TitleTextGridContainer>
          <Grid item>
            <ActionButtonsPopper
              data-testid="action-popper"
              handleAction={handleAction}
              createBatchHandler={toggleCreateBatchHandler}
            />
          </Grid>
        </TitleGridContainer>
        <Grid item container spacing={2}>
          <Grid item container xs={8}>
            <Products />
          </Grid>
          <Grid item container xs={4}>
            <Cart />
          </Grid>
        </Grid>
      </OrderGridContainer>

      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
      />
      <SelectBatchDialog />
      <CreateBatches
        openDialog={openDialog}
        toggleHandler={toggleCreateBatchHandler}
      />
      <CreateProduct
        openProdDialog={openProdDialog}
        toggleHandler={toggleCreateProductHandler}
      />
    </MainContent>
  );
};

export default SalesContainer;
export { SalesContainer as PureSalesContainer };
