import {
  Grid, TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SummaryGridContainer = styled(Grid)`
    padding: 30px;
  `;

export const SummaryTopSection = styled(Grid)``;

export const SummaryH1 = styled('h1')`
      font-weight: 500;
      font-size: 35px;
      margin-bottom: 2%;
  `;

export const SummaryConfirmButton = styled('div')`
      color: #424242;
      background: #fced50;
      width: 85%;
      padding: 6%;
      border-radius: 10px;
      cursor: pointer;
  `;

export const SaleSummarySection = styled('div')`
      background: white;
      height: 624px;
      border-radius: 7px 0px 0px 7px;
      padding: 34px;
      box-shadow: 1px 2px 3px #00000029;
      border: 0.1px solid #e1dede;
      overflow: scroll;
  `;

export const SalePayment = styled('div')`
      box-shadow: 1px 2px 3px #00000029;
      border: 0.1px solid #e1dede;
      border-radius: 0px 7px 7px 0px;
      height: 624px;
      padding: 50px 10px;
  `;

export const SummaryTableCellHead = styled(TableCell)`
     &.MuiTableCell-head {
         font-weight: 600;
     }
  `;

export const SummaryTableCellNoBorder = styled(TableCell)`
      border: 0;
  `;

export const SummaryTableCellBorderTop = styled(TableCell)`
      border-top: 1px solid #e0e0e0;
      border-bottom: 0;
  `;

export const PaymentP = styled('p')`
      margin: 0;
      border-bottom: 1px solid #ccc8c8;
      padding-bottom: 20px;
  `;

export const Qty = styled('div')`
      width: 55%;
      background: rgb(240, 240, 240);
      text-align: center;
      border-radius: 5px;
  `;
