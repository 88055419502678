import React from 'react';
import PropTypes, { array } from 'prop-types';
import {
  ResultBox, ContentGrid, CPagination, RowBox, SelectBox
} from './tablePagination.styles';

const TablePagination = ({
  pageCount, setPageCount, pageNumber, setPageNumber, total,
  hideRowsPerPage, displayShowResult, options, handleUpdateProducts
}) => {
  const number = pageNumber === 1 ? pageNumber : ((pageNumber - 1) * pageCount) + 1;
  const count = pageNumber === 1 ? pageCount : pageCount * pageNumber;

  const handleChange = (event, page) => {
    setPageNumber(page);
    if (handleUpdateProducts) {
      handleUpdateProducts();
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setPageCount(parseInt(event.target.value));
  };

  return (
    <ContentGrid item container>
      {!hideRowsPerPage
        ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RowBox>Rows Per Page </RowBox>
            <SelectBox
              value={pageCount}
              onChange={handleChangeRowsPerPage}
            >
              {options?.length ? (
                options.map((option) => (
                  <option key={option}>{option}</option>
                ))
              ) : (
                <>
                  <option>5</option>
                  <option>10</option>
                  <option>20</option>
                </>
              )}
            </SelectBox>
          </div>
        ) : <div />}
      {displayShowResult && (
        <ResultBox>
          {`Showing Results for ${number}-${count} of ${total.toLocaleString()}`}
        </ResultBox>
      )}
      <CPagination
        onChange={handleChange}
        count={Math.ceil(total / pageCount)}
        variant="outlined"
        color="primary"
        shape="round"
        page={pageNumber}
      />
    </ContentGrid>
  );
};

TablePagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  hideRowsPerPage: PropTypes.bool,
  displayShowResult: PropTypes.bool,
  options: PropTypes.string,
  handleUpdateProducts: PropTypes.func
};

TablePagination.defaultProps = {
  hideRowsPerPage: false,
  displayShowResult: false,
  options: '',
  handleUpdateProducts: () => null
};

export default TablePagination;
