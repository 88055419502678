import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_USER_ROLE = gql`
  mutation updateBusinessUserStatus($id: Int!, $action: String!) {
    updateBusinessUserStatus(id: $id, action: $action) {
      message
      businessUser {
        id
        status
        user {
          username
          profile
        }
        permissions
        permissionIds
        permissionNames
        meta
        business {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_BUSINESS_USER_PERMISSION = gql`
  mutation updateBusinessUserPermissions(
    $businessUserId: Int!
    $permissions: [Int]!
  ) {
    updateBusinessUserPermission(
      businessUserId: $businessUserId
      permissions: $permissions
    ) {
      message
      businessUser {
        id
        status
        user {
          username
          profile
        }
        permissions
        permissionIds
        permissionNames
        meta
        business {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation assignUserRole(
    $roleId: Int!, $businessUserId: Int!
  ) {
    assignUserRole(
      roleId: $roleId, businessUserId: $businessUserId
    ){
      message
    }
  }
`;

export const DISABLE_BUSINESS_USER = gql`
mutation disableBusinessUser(
  $businessUserId: Int!
) {
  disableBusinessUser(
    businessUserId: $businessUserId
  ){
    message
  }
}
`;
