export const renderStockReportStatus = (status) => {
  let _status = 'Started';
  if (status === 'RECONCILE_QUANTITY') _status = 'Reconcile Quantity';
  if (status === 'AUDIT_REPORT') _status = 'Audit Report';
  if (status === 'COMPLETE') _status = 'Complete';
  return _status;
};

export const handleStockReportStatusColor = (status) => {
  let color = '#07DE10';
  if (status === 'RECONCILE_QUANTITY' || status === 'AUDIT_REPORT') color = '#FFB966';
  if (status === 'COMPLETE') color = '#07DE10';
  return color;
};
