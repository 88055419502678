import { Grid } from '@mui/material';
import React from 'react';
import AffiliatesIcon from '../../assets/images/dashboard/affiliates-icon.png';
import SuppliersIcon from '../../assets/images/dashboard/suppliers-icon.png';
import ProductsIcon from '../../assets/images/dashboard/products-icon.png';
import TotalOrderIcon from '../../assets/images/order/Total Orders Icon.png';
import currencyFormatter, { valuesFormatter } from '../../utils/currencyFormatter';
import GrossOrderIcon from '../../assets/images/order/Gross Order Value Icon.png';
import { StatisticsGridContainer, StatisticsGridWrapper } from '../orders/admin/order.styles';
import StatisticCard from '../orders/shared/statisticCard';

export function SummaryPlaceHolder() {
  const STATISTICS = [{
    id: 1,
    icon: AffiliatesIcon,
    title: 'XXXX XXXX XXXX',
    value: 'X',
  }, {
    id: 2,
    icon: SuppliersIcon,
    title: 'XXXX XXXX XXXX',
    value: 'X',
  }, {
    id: 3,
    icon: ProductsIcon,
    title: 'XXXX XXXX XXXX',
    value: 'X',
  }, {
    id: 4,
    icon: TotalOrderIcon,
    title: 'XXXX XXXX XXXX',
    value: ` ${valuesFormatter(0)}`,
  }, {
    id: 5,
    icon: GrossOrderIcon,
    title: 'XXXX XXXX XXXX',
    value: currencyFormatter(0),
  }];

  return (
    <Grid item container xs={12} wrap="nowrap" alignItems="center">
      <StatisticsGridWrapper item container>
        <StatisticsGridContainer
          container
          item
          wrap="nowrap"
          justifyContent="space-between"
        >
          {STATISTICS.map((statistic) => (
            <StatisticCard
              key={statistic.id}
              statistic={statistic}
              admin
            />
          ))}
        </StatisticsGridContainer>
      </StatisticsGridWrapper>
    </Grid>
  );
}
