import React, { useState, useEffect } from 'react';
import { Query } from '@apollo/client/react/components';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GET_ALL_SUPPLIER_ORDERS } from '../../../queries/orders';

import Order from './order';
import Loader from '../../shared/loader';

const OrdersSupplierContainer = ({
  session
}) => {
  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    supplierBusinessName: ''
  };

  const [state, setState] = useState(initialState);
  const {
    currentPage, currentPageCount, supplierBusinessName
  } = state;

  const navigate = useNavigate();

  useEffect(() => {
    if (session && session.businessuserSet?.length) {
      const { business } = session?.businessuserSet[0] ?? '';
      const { name } = business?.supplierSet[0] ?? '';
      setState({ ...state, supplierBusinessName: name });
    }
  }, [session]);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleOrderItemCardClicked = (id) => {
    navigate(`/orders/${id}/detail`, ['supplier']);
  };

  return (
    <Query
      query={GET_ALL_SUPPLIER_ORDERS}
      fetchPolicy="cache-and-network"
      variables={{
        pageCount: currentPageCount,
        pageNumber: currentPage,
        search: supplierBusinessName
      }}
    >
      {({ data, loading, error }) => {
        if (loading) return <Loader />;
        if (error) return `Error! ${error.message}`;
        const {
          allSupplierOrders = [], supplierOrderTotalNumber = 0
        } = data;

        const supplierOrderItems = allSupplierOrders
          .filter((o) => o.status === 'SUPPLIER_ORDER_SENT')
          .map(
            (supplierOrderItem) => {
              const {
                dateCreated, id, order: { business }, orderproductSet, total, savings, supplier
              } = supplierOrderItem || {};
              const { name: supplierName, logo: supplierLogo } = supplier || {};
              const { name, logo } = business || {};
              const orderVolume = orderproductSet.reduce(
                (previous, current) => previous + current.quantity, 0
              );
              return {
                dateCreated, id, name, logo, total, savings, orderVolume,
                supplierName, supplierLogo
              };
            }
          );

        return (
          <Order
            supplierOrders={supplierOrderItems}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={currentPage}
            currentPageCount={currentPageCount}
            totalCount={supplierOrderTotalNumber}
            onOrderItemCardClicked={handleOrderItemCardClicked}
          />
        );
      }}
    </Query>
  );
};

OrdersSupplierContainer.propTypes = {
  session: PropTypes.instanceOf(Object)
};

OrdersSupplierContainer.defaultProps = {
  session: {}
};

export default OrdersSupplierContainer;
