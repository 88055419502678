import { Paper, Typography, Grid } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const ArrowLeftIcon = styled(ArrowLeft)`
  color: #919191;
  cursor: pointer;
  align-self: center;
`;
export const ArrowRightIcon = styled(ArrowRight)`
  color: #919191;
  cursor: pointer;
  align-self: center;
`;
export const DateRangePaper = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E2E2E2;
  width: 11.3rem;
  height: 1.85rem;
  box-shadow: 0px 2px 3px #00000029;
`;
export const DateRangeTypo = styled(Typography)`
  color: #9E9E9E;
  line-height: 1px;
`;

export const DateWrapper = styled(Grid)`
  position: absolute;
  left: 15%;

  @media (min-width: 992px) {
    left: 45%;
  }
`;
