import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Paper, TableRow, TableBody,
} from '@mui/material';
import GoToTop from '../../customComponents/scrollToTop';
import CTablePagination from '../../customComponents/cTablePagination';
import { GET_CUSTMER_SALE_QUERY } from '../../../queries/sales';
import {
  TableContainerWrapper, TableComponent, HeaderCell, FooterGrid, OrderCountText,
  MainTableHead
} from './customerSales.styles';
import paginationShowing from '../../../utils/pagination';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';

const CustomerSales = ({
  state,
  customerSaleId,
  handleChangePage,
  page,
  handleChangeRowsPerPage,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const {
    searchText, currentPage, currentPageCount,
  } = state;

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const { loading, error, data } = useQuery(GET_CUSTMER_SALE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      customerId: customerSaleId,
      pageCount: currentPageCount,
      pageNumber: currentPage,
      productName: searchValue
    },
  });

  if (loading) return <ProductsTableLoader />;
  if (error) return <div>{error.message}</div>;
  const {
    customerSale = [],
  } = data;

  const TotalNumber = customerSale.length;

  const showing = paginationShowing(TotalNumber, currentPageCount);

  return (
    <>
      <TableContainerWrapper component={Paper}>
        <TableComponent aria-label="sales transactions table">
          <MainTableHead>
            <TableRow>
              <HeaderCell>Date Created</HeaderCell>
              <HeaderCell>Outlet</HeaderCell>
              <HeaderCell>Receipt</HeaderCell>
              <HeaderCell>Sold By</HeaderCell>
              <HeaderCell>Sale Amount</HeaderCell>
              <HeaderCell>Loyalty Earned</HeaderCell>
              <HeaderCell>Store Credit Added</HeaderCell>
            </TableRow>
          </MainTableHead>
          <TableBody>
            {customerSale.map((transaction) => (
              <ReturnRow
                row={transaction}
              />
            ))}
          </TableBody>
        </TableComponent>

      </TableContainerWrapper>
      <FooterGrid item container>
        <OrderCountText>
          Showing
          <b>
            {` ${showing()} - ${showing('end')} `}
          </b>
          of
          <b>
            {` ${TotalNumber} `}
          </b>
          transactions
        </OrderCountText>
        <CTablePagination
          rowsPerPageOptions={[20, 25]}
          component="div"
          count={TotalNumber}
          page={page - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
      {(customerSale.length)
        ? <GoToTop /> : ''}
    </>
  );
};

CustomerSales.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  state: PropTypes.instanceOf(Object),
  // eslint-disable-next-line react/require-default-props
  customerSaleId: PropTypes.number,
};

CustomerSales.defaultProps = {
  state: {},
  customerSaleId: 0
};

export default CustomerSales;
