import {
  TableRow, TableCell, Checkbox, Grid, Button, TextField, Box,
  InputBase, TextareaAutosize, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// export const MainTableRow = styled(TableRow)`
//   & > * {
//     border-bottom: unset;
//     tr:nth-child(odd): {
//       background-color: red;
//     }
//   }
//   tr:nth-child(odd): {
//     background-color: red;
//   }
//   &:nth-of-type(odd): {
//     background-color: red;
//   }
// `;

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
    padding: 10px;
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 17%;
    }
    &:nth-child(4) {
      width: 17%;
    }
    &:nth-child(5) {
      width: 30%;
    }
    // &:nth-child(6) {
    //   width: 15%;
    // }
  }
`;

// export const BodyCell = styled(TableCell)`
//   text-transform: capitalize;
//   font-size: 1.2rem;
//   padding: 10px;

//   @media(min-width: 992px) {
//     font-size: .9rem;
//   }
// `;

export const ViewCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked{
    color: #235A91;
  }
  &.MuiIconButton-colorSecondary{
    color: #235A91;
  }
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.6rem;
  }
`;

export const ProductCounter = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 60px;

  @media (max-width: 991px) {
    margin-left: 7.5rem;
    margin-right: 8rem;
  }

  @media (max-width: 991px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ProductCounterRemoveButton = styled(Button)`
  min-width: 48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #CECECE;
  }
`;

export const ProductCounterAddButton = styled(Button)`
  min-width: 48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #CECECE;
  }
`;

export const Remove = styled(RemoveIcon)`
  font-size: 2.2rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const Add = styled(AddIcon)`
  font-size: 2.2rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const ProductCounterQuantityInput = styled('input')`
  width: 96px;
  height: 60px;
  font-size: 20px;
  font-family: Montserrat,medium,sans-serif;
  line-height: 17px;
  color: #424242;
  background-color: #f0f0f0;
  outline: none;
  border: none;
  padding-left: 15px;

  @media (max-width: 991px) {
    width: 10rem;
    height: 5.5rem;
    font-size: 2.5rem;
    line-height: 13px;
    background-color: #E6E6E6;
    text-align: center;
    padding: 0;
  }
`;

export const TextFieldBox = styled(Box)`
  margin-bottom: .3rem;
  width: 100%;
  display: block;
`;

export const CTextField = styled(TextareaAutosize)`
  height: 3.5rem !important;
  width: 100%;
  border-radius: .6rem;
  padding: .6rem;
  border: 1px solid #E7E8E9;
  line-height: 1.5;
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 45%;
  border-radius: 6px;
  font-size: 16px;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0;
  }
  & .MuiInputAdornment-positionStart {
    margin-right: 0;
    margin-left: 16px;
  }
`;

export const ActionButton = styled(Box)`
  border: none;
  color: #606060;
  width: 100%;
  padding-bottom: 1rem;
  background-color: inherit !important;
  display: flex;
  cursor: pointer;
  margin-right: 1rem;
`;

export const ActionText = styled(Typography)`
  margin-left: 10px;
`;
