import { TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }

  &:nth-of-type(even) {
    background-color: #F4F7FC;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.3rem;
  padding: 15px;

  @media(min-width: 992px) {
    font-size: .9rem;
    padding: 10px;
    cursor: pointer;
  }
`;
