import {
  Grid, TableContainer, Table, TableHead, TableCell,
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
  padding: 15px 2px;
  background: #F3F9FF;
  color: #303030;
  font-weight: 700;
  font-size: 1rem;
  & > * {
    border-bottom: unset;
  }

  &:first-child {
    width: 20%;
    padding: 15px 7px;
  }
  &:nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5) {
    width: 20%;
  }

  @media(min-width: 992px) {

  }
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;
`;
