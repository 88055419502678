import OfflineActionTypes from './offlineTypes';
import { loadingData } from './offlineUtils';

const productReducer = (offline, action) => {
  switch (action.type) {
    case OfflineActionTypes.PRODUCTS_LOADING:
      return {
        ...offline,
        ...loadingData(action.payload)
      };
    default:
      return offline;
  }
};

export default productReducer;
