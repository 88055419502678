import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  TableContainer, Table, TableRow, TableBody, TableHead,
  Hidden, IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  TableBox, MainTableRow, BodyCell, HeaderCell, ProductTypo, Content, InfoRow, Header, Label, Value
} from './productInfo.styles';

export default function ProductInfo({
  product, clearanceSale
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const productToUse = clearanceSale ? product[0]?.product : product;
  const { batches, marketplaceVersion, qpc } = productToUse;
  const { expiryDate } = batches[0] || {};
  const {
    manufacturer, category, packSize, unitOfMeasurement, barcode,
    supplier
  } = marketplaceVersion;
  const { categoryName } = category || {};
  const { id } = supplier;
  const headers = [
    'SKU', 'Qty/Ctn', 'Pack Size', 'Category', 'UOM', 'UPC', 'UPC2', 'UPC2', 'Manufacturer', 'Expiry Date'
  ];

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <Content>
      <Hidden mdDown>
        <ProductTypo>Product Information</ProductTypo>
        <TableBox>
          <TableContainer>
            <Table aria-label="payments table">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <HeaderCell key={header}>{header}</HeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <MainTableRow>
                  <BodyCell>
                    {id}
                  </BodyCell>
                  <BodyCell>
                    {qpc || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {packSize || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {categoryName || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {unitOfMeasurement || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {barcode || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {barcode || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {barcode || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {manufacturer || 'N/A'}
                  </BodyCell>
                  <BodyCell>
                    {moment(product[0]?.expiryDate || expiryDate).format('MMM DD, YYYY') || 'N/A'}
                  </BodyCell>
                </MainTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TableBox>
      </Hidden>
      <Hidden mdUp>
        <Header>
          <ProductTypo>
            Product Information
          </ProductTypo>
          <IconButton onClick={handleToggle}>
            {isExpanded ? <ExpandLessIcon style={{ fontSize: '3rem' }} /> : <ExpandMoreIcon style={{ fontSize: '3rem' }} />}
          </IconButton>
        </Header>

        {isExpanded && (
          <>
            <InfoRow>
              <Label>SKU</Label>
              <Value>
                { id }
              </Value>
            </InfoRow>

            <InfoRow>
              <Label>Qty</Label>
              <Value>
                {qpc || 'N/A'}
              </Value>
            </InfoRow>

            <InfoRow>
              <Label>Pack Size</Label>
              <Value>100ml</Value>
            </InfoRow>

            <InfoRow>
              <Label>UOM</Label>
              <Value>
                {unitOfMeasurement || 'N/A'}
              </Value>
            </InfoRow>

            <InfoRow>
              <Label>Manufacturer</Label>
              <Value>
                {manufacturer || 'N/A'}
              </Value>
            </InfoRow>

            <InfoRow>
              <Label>Expiry Date</Label>
              <Value>
                {moment(product[0]?.expiryDate || expiryDate).format('MMM DD, YYYY') || 'N/A'}
              </Value>
            </InfoRow>
          </>
        )}
      </Hidden>
    </Content>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.instanceOf(Object),
  clearanceSale: PropTypes.string
};

ProductInfo.defaultProps = {
  product: {},
  clearanceSale: ''
};
