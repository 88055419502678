import moment from 'moment';

const resetDashboardState = () => {
  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');
  const range = moment().format('ll');
  return {
    active: 'day',
    startDate,
    endDate,
    range,
    expiryActive: '12 months',
    expiryDays: 365
  };
};

const business = {
  subBusinessesIds: [],
  businessId: 0,
  businessUser: 0,
  dashboardState: {
    ...resetDashboardState()
  },
  roleId: 0,
  allBusinessRole: []
};

export default business;
