import React from 'react';
import {
  DialogContent,
  Slide,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import warning from '../../../assets/images/Warning.gif';
import {
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  ButtonDarkStyled,
  ButtonWhiteStyled,
  FooterStyled, DialogContainer
} from './confirmDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openDialog,
  setOpenDialog,
  title,
  desc,
  options,
  loading,
  confirmHanlder
}) => {
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <DialogContainer
      open={openDialog}
      data-testid="confirmDialog"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      style={{ '&.MuiDialog-paper': { borderRadius: '.75rem' } }}
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <img src={warning} alt="warning" width={155} />
            </Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled>{desc}</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <ButtonWhiteStyled onClick={handleClose}>
            {options[0]}
          </ButtonWhiteStyled>
          <ButtonDarkStyled disabled={loading} onClick={confirmHanlder}>
            {loading ? 'Loading...' : options[1]}
          </ButtonDarkStyled>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

ConfirmDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  confirmHanlder: PropTypes.func
};

ConfirmDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  title: '',
  desc: '',
  options: [],
  loading: false,
  confirmHanlder: () => {},
};

export default ConfirmDialog;
