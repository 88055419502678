import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, Button,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import toast from 'react-hot-toast';
import MainContent from '../../../../customComponents/mainContent';
import {
  TableHeader,
  GridContainer,
  PaperWrapper,
  TableGrid,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MainTableHead,
  ButtonContainer,
  GoBack,
  HeaderTitle
} from './generateInvoice.styles';
import ReturnRow from './returnRow';
import { BodyCell } from './returnRow.styles';
import TablePagination from '../../../../shared/tablePagination';
import ProductsTableLoader from '../../../../customComponents/loaders/productsTableLoader';
import { INVOICE_PRODUCTS_QUERY } from '../../../../../queries/dispatch';
import { SAVE_ORDER_INVOICE_MUTATION } from '../../../../../mutations/orders';
import SuccessDialog from '../../../../shared/successDialog';
import { OgaToken } from '../../../../../graphql/token';
import ConfirmDialog from '../../../../shared/confirmDialog';

const headers = [
  'S/N',
  'Manufacturer',
  'Product Name',
  'Qty',
  'Unit Price',
  'Amount',
];

function GenerateInvoiceContainer() {
  const returnHeaders = () => headers.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const invoiceId = location?.state?.invoiceId;

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState([]);
  const [productChanges, setProductChanges] = useState([]);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [saveRealtimeInvoiceChanages, { loading: invoiceLoading }] = useMutation(SAVE_ORDER_INVOICE_MUTATION);

  const { data, loading, refetch } = useQuery(INVOICE_PRODUCTS_QUERY, {
    variables: { orderId: id, pageCount, pageNumber },
    fetchPolicy: 'network-only'
  });

  const prod = data?.orderProducts || [];
  const count = data?.orderProductsTotalNumber;

  useEffect(() => {
    const result = prod.map((item) => {
      const {
        id: orderProductId, quantity, unitCost, name, product, realTimeInvoicePrice, realTimeInvoiceQty, excludeFromRealTimeInvoice
      } = item;
      return {
        productId: product?.id,
        productName: name,
        manufacturer: product?.manufacturer,
        realTimeInvoicePrice,
        quantity,
        unitCost,
        realTimeInvoiceQty,
        orderProductId,
        excludeFromRealTimeInvoice
      };
    });
    setProducts(result);
  }, [data, trigger]);

  const quantityHandler = (val, row) => {
    const { orderProductId, unitCost, quantity } = row;
    if (val > quantity) return toast.error('Quantity Cannot be more than Quantity Ordered');
    const productIndex = productChanges.findIndex((p) => p.orderProductId === orderProductId);

    if (productIndex > -1) {
      const updatedProducts = [...productChanges];
      updatedProducts[productIndex].quantity = val || 0;
      setProductChanges(updatedProducts);
    } else {
      setProductChanges([
        ...productChanges,
        {
          orderProductId,
          quantity: val || 0,
        }
      ]);
    }
    const newData = products.find(({ orderProductId: pId }) => orderProductId === pId);
    newData.realTimeInvoicePrice = unitCost * val;
    newData.realTimeInvoiceQty = val;

    setProducts([...products]);
  };

  const deleteProductHandler = (row) => {
    const { orderProductId } = row;
    const productIndex = productChanges.findIndex((p) => p.orderProductId === orderProductId);
    if (productIndex > -1) {
      const updatedProducts = [...productChanges];
      updatedProducts[productIndex].excluded = true;
      setProductChanges(updatedProducts);
    } else {
      setProductChanges([
        ...productChanges,
        {
          orderProductId,
          excluded: true,
        }
      ]);
    }
  };

  const addProductHandler = (row) => {
    const { orderProductId } = row;
    const productIndex = productChanges.findIndex((p) => p.orderProductId === orderProductId);
    if (productIndex > -1) {
      const updatedProducts = [...productChanges];
      updatedProducts[productIndex].excluded = false;
      setProductChanges(updatedProducts);
    } else {
      setProductChanges([
        ...productChanges,
        {
          orderProductId,
          excluded: false,
        }
      ]);
    }
  };

  const handleSaveChanges = () => {
    if (!productChanges?.length) return;
    setOpenConfirmDialog(false);
    saveRealtimeInvoiceChanages({
      variables: {
        orderId: id,
        invoiceId,
        productChanges
      }
    }).then(() => {
      setProductChanges([]);
      setSuccessOpenDialog(true);
      refetch();
      setTrigger(!trigger);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };
  const handleDownload = () => {
    if (productChanges?.length) {
      setOpenConfirmDialog(true);
    } else {
      window.open(
        `${OgaToken.SERVER_LINK}generate-real-time-invoice/${id}/?invoice_id=${invoiceId}`,
        '_blank'
      );
    }
  };

  const handleUnsaveChanges = () => {
    setProductChanges([]);
    setOpenConfirmDialog(false);
  };

  return (
    <MainContent>
      <GridContainer container>
        <GoBack onClick={() => navigate(-1)}>
          <KeyboardArrowLeft
            style={{ fontSize: '1.8rem', cursor: 'pointer' }}
          />
          back
        </GoBack>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Generate Invoice</PrimaryTitle>
            <ButtonContainer>
              <Button
                variant="outlined"
                style={{
                  width: '7rem', height: '3.4rem', border: '1px solid', fontWeight: '700', borderRadius: '6px'
                }}
                onClick={handleSaveChanges}
                disabled={invoiceLoading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{
                  width: '10rem', height: '3.4rem', fontWeight: '700', borderRadius: '6px'
                }}
                onClick={handleDownload}
              >
                View Invoice
              </Button>
            </ButtonContainer>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <HeaderTitle>Edit List of Products</HeaderTitle>
          <TableGrid item container>
            <TableContainer>
              <Table>
                <MainTableHead>
                  {returnHeaders()}
                  <TableHeader />
                </MainTableHead>
                <TableBody>
                  {loading ? (
                    <ProductsTableLoader />
                  ) : !products?.length ? (
                    <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                      Order Product is empty
                    </BodyCell>
                  ) : (
                    products?.map((item, index) => (
                      <ReturnRow
                        key={item?.orderProductId}
                        row={item}
                        index={index}
                        quantityHandler={quantityHandler}
                        productChanges={productChanges}
                        deleteProductHandler={deleteProductHandler}
                        addProductHandler={addProductHandler}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TableGrid>
          <TablePagination
            total={count}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </PaperWrapper>
      </GridContainer>
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title="Your changes  have been saved"
        option="ok"
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="You Have Unsaved Changes"
        desc="Any unsaved changes will be lost!"
        options={["Don't Save", 'Save Changes']}
        buttonAction={handleSaveChanges}
        handleDelete={handleUnsaveChanges}
      />
    </MainContent>
  );
}

export default GenerateInvoiceContainer;
