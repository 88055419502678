import React from 'react';
import PropTypes from 'prop-types';
import {
  TabsGrid, TCustomButton
} from './tabsButtons.styles';

const TabsButtons = ({
  tabVal, handleTabChange, tab
}) => (
  <TabsGrid container item>
    {tab.map(({
      label, value
    }) => (
      <TCustomButton
        key={value}
        className="mp-uat-new-orders-page"
        onClick={() => handleTabChange(value)}
        type="secondary"
        header
        active={value === tabVal}
      >
        {label}
      </TCustomButton>
    ))}
  </TabsGrid>
);

TabsButtons.propTypes = {
  tabVal: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tab: PropTypes.instanceOf(Array).isRequired
};

export default TabsButtons;
