import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowLeftIcon, ArrowRightIcon, DateRangePaper, DateRangeTypo
} from './dateRangeSelector.styles';

const DateRangeSelector = ({
  range, handleIconDateRange
}) => (
  <>
    <ArrowLeftIcon
      onClick={() => handleIconDateRange('backward')}
    />
    <DateRangePaper elevation={2}>
      <DateRangeTypo variant="caption">
        {range}
      </DateRangeTypo>
    </DateRangePaper>
    <ArrowRightIcon
      onClick={() => handleIconDateRange('forward')}
    />
  </>
);
DateRangeSelector.propTypes = {
  handleIconDateRange: PropTypes.func.isRequired,
  range: PropTypes.string,
};

DateRangeSelector.defaultProps = {
  range: ''
};

export default DateRangeSelector;
