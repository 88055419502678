import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Close } from '@mui/icons-material';
import { Grid } from '@mui/material';
import currencyAmount from '../../../utils/currencyAmount';

import { EyeOutline } from '../../../assets/svgs';
import {
  CloseStyled,
  DialogContentStyled,
  NoteCardHeader,
  NoteDialog,
  NoteHeader,
  NoteInfo,
  NoteSubTitle,
  NoteTitle,
  TableColumnRows, TableColumnRowText, TCustomButton
} from './eodReport.styles';

const ReturnReportRow = ({
  report, selectedColumns
}) => {
  const [open, setOpen] = useState(false);
  const dateCreated = report?.dateCreated;
  const timeClosed = report?.timeClosed;
  const cardTotal = report?.cardTotal;
  const cashTotal = report?.cashTotal;
  const bankTotal = report?.bankTotal;
  const saleAmount = report?.saleAmount;
  const expectedCardTotal = report?.expectedCardTotal;
  const expectedCashTotal = report?.expectedCashTotal;
  const expectedBankTotal = report?.expectedBankTotal;
  const expectedSalesAmount = report?.expectedSaleAmount;
  const footfall = report?.footfall;
  const note = report?.note;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { fullName } = report?.businessUser;
  const storeCredit = report?.storeCredit;

  const dateColumn = (
    <TableColumnRowText style={{ width: '250px' }}>
      {dateCreated ? moment(dateCreated).format('D MMM YYYY h:mm a') : 'N/A'}
    </TableColumnRowText>
  );

  const closeTimeColumn = (
    <TableColumnRowText style={{ width: '150px' }}>
      {timeClosed ? moment(timeClosed, 'h:mm:ss a').format('h:mm:ss a') : 'N/A'}
    </TableColumnRowText>
  );

  const userColumn = (
    <TableColumnRowText
      style={{ width: '260px', overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      { fullName }
    </TableColumnRowText>
  );

  const expectedCashColumn = (
    <TableColumnRowText style={{ width: '200px', }}>
      {`₦ ${currencyAmount(expectedCashTotal)}`}
    </TableColumnRowText>
  );

  const actualCashColumn = (
    <TableColumnRowText style={{ width: '200px', }}>
      {`₦ ${currencyAmount(cashTotal)}`}
    </TableColumnRowText>
  );

  const expectedCardColumn = (
    <TableColumnRowText style={{ width: '200px' }}>
      {`₦ ${currencyAmount(expectedCardTotal)}` }
    </TableColumnRowText>
  );

  const actualCardColumn = (
    <TableColumnRowText style={{ width: '200px' }}>
      {`₦ ${currencyAmount(cardTotal)}`}
    </TableColumnRowText>
  );

  const expectedBankColumn = (
    <TableColumnRowText style={{ width: '250px' }}>
      {`₦ ${currencyAmount(expectedBankTotal)}`}
    </TableColumnRowText>
  );

  const actualBankColumn = (
    <TableColumnRowText style={{ width: '250px' }}>
      {`₦ ${currencyAmount(bankTotal)}`}
    </TableColumnRowText>
  );

  const saleAmountColumn = (
    <TableColumnRowText style={{ width: '220px' }}>
      {`₦ ${currencyAmount(expectedSalesAmount)}`}
    </TableColumnRowText>
  );

  const actualAmountColumn = (
    <TableColumnRowText style={{ width: '220px' }}>
      {`₦ ${currencyAmount(saleAmount)}`}
    </TableColumnRowText>
  );

  const storeCreditInColumn = (
    <TableColumnRowText style={{ width: '200px' }}>
      { `₦ ${currencyAmount(storeCredit)}` }
    </TableColumnRowText>
  );

  const footfallColumn = (
    <TableColumnRowText style={{ width: '150px' }}>
      { footfall }
    </TableColumnRowText>
  );

  const handleToggleNotes = () => {
    setOpen(!open);
  };

  const noteColumn = (
    <TableColumnRowText style={{ width: '150px' }}>
      <TCustomButton
        type="secondary"
        header
        onClick={handleToggleNotes}
      >
        <EyeOutline style={{ marginRight: '.5rem', width: '1.3rem' }} />
        View Note
      </TCustomButton>
    </TableColumnRowText>
  );


  return (
    <TableColumnRows>
      {dateColumn}
      {closeTimeColumn}
      {userColumn}
      {selectedColumns.includes('Cash') && expectedCashColumn}
      {selectedColumns.includes('Cash') && actualCashColumn}
      {selectedColumns.includes('Card') && expectedCardColumn}
      {selectedColumns.includes('Card') && actualCardColumn}
      {selectedColumns.includes('Bank Transfer') && expectedBankColumn}
      {selectedColumns.includes('Bank Transfer') && actualBankColumn}

      {selectedColumns.includes('Store Credit') && storeCreditInColumn}
      {selectedColumns.includes('Sale Amount') && saleAmountColumn}
      {selectedColumns.includes('Sale Amount') && actualAmountColumn}
      {selectedColumns.includes('Footfall') && footfallColumn}
      {selectedColumns.includes('Note') ? noteColumn : <TableColumnRowText style={{ width: '200px' }} />}

      <NoteDialog
        open={open}
        onClose={handleToggleNotes}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogContentStyled>

          <NoteHeader>
            <Grid>
              <NoteTitle>EOD Report Note</NoteTitle>
              <NoteSubTitle>Note helps for more clarity on the report</NoteSubTitle>
            </Grid>
            <CloseStyled>
              <Close
                fontSize="small"
                onClick={handleToggleNotes}
                style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }}
              />
            </CloseStyled>
          </NoteHeader>
          <NoteCardHeader>Note</NoteCardHeader>
          <NoteInfo>
            { note }
          </NoteInfo>

        </DialogContentStyled>
      </NoteDialog>
    </TableColumnRows>
  );
};

ReturnReportRow.propTypes = {
  report: PropTypes.instanceOf(Object).isRequired,
  selectedColumns: PropTypes.instanceOf(Object).isRequired
};

export default ReturnReportRow;
