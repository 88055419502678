import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {
  TableColumnRows, TableColumnRowText, ButtonContainer, TabIcon, TabImage
} from './returnRow.styles';

import DeleteIcon from '../../assets/images/Delete.png';

const ReturnRow = ({
  row, updatingInvoice, handleRemoveBatch
}) => (
  <TableColumnRows>
    <TableColumnRowText style={{ width: '450px' }}>
      {row?.product?.brandName}
    </TableColumnRowText>
    <TableColumnRowText style={{ width: '180px' }}>
      {row?.product?.packSize || 'N/A'}
    </TableColumnRowText>
    <TableColumnRowText style={{ width: '200px' }}>
      {row?.product?.manufacturer || 'N/A'}
    </TableColumnRowText>
    <TableColumnRowText style={{ width: '150px' }}>
      {row.batchNo || 'N/A'}
    </TableColumnRowText>
    <TableColumnRowText style={{ width: '200px' }}>
      {Moment(row.expiryDate).format('DD/MM/YYYY') || 'N/A'}
    </TableColumnRowText>
    <TableColumnRowText style={{ width: '180px' }}>
      {row.quantityReceived}
    </TableColumnRowText>
    {updatingInvoice ? (
      <ButtonContainer item container>
        <TabIcon onClick={() => handleRemoveBatch(row)}>
          <TabImage
            src={DeleteIcon}
            alt="delete"
            style={{ width: '15.2px', height: '19px' }}
          />
        </TabIcon>
      </ButtonContainer>
    ) : ''}
  </TableColumnRows>
);

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  updatingInvoice: PropTypes.func.isRequired,
  handleRemoveBatch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
