import {
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
// the width in pixels for sm breakpoint.
export const BoldTitle = styled(Grid)`
 font-weight: 600;
 font-size: 2rem;
`;
export const MainContainer = styled(Grid)`
 padding: 40px;
 font-size: 1rem;
 min-height: 1100px;
`;
export const InlineGreyText = styled('span')`
 color: #A3A3A3;
 font-size: 1rem;
`;
export const InfoHeader = styled(Grid)`
 color: #A3A3A3;
 font-size: 1rem;
`;
export const SmallHeader = styled(InfoHeader)`
 font-size: 0.8rem;
`;
export const PharmacyNameText = styled(Grid)`
  color: #424242;
  font-size: 16px;
  font-weight: 600;
`;
export const OrderTagInfo = styled(Grid)`
   padding: 20px;
`;
export const TableHeader = styled(Grid)`
  background: #00000029;
  padding: 20px;
`;
export const TableRow = styled(Grid)`
  padding: 20px;
`;
