import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import { AUTOMATIC_ORDERING_MUTATION, BULK_BUY_MUTATION, CREATE_ORDER_MUTATION } from '../../mutations/orders';
import Products from './products/products';
import Orders from './orders/orders';
import MainContent from '../customComponents/mainContent';
import { EXPORT_PRODUCT_MUTATION } from '../../mutations/reports';
import Filter from './filter';
import OrderButtonsPopper from './orderButtonsPopper';
import {
  OrderGridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid,
  TCustomButton, PaperWrapper
} from './inventoryContainer.styles';
import IndividualProductDialog from '../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import { useStateValue } from '../../providers/stateProvider';
import SuppliersModel from '../shared/orderDropdown/suppliersModel/suppliersModel';
import ProductButtonsPopper from './productButtonsPopper';
import CreateProduct from '../sales/createProduct';
import CreateBatches from '../sales/createBatches';
import BatchDetails from '../batchDetails/batchDetails';
import TherapeuticTypes from '../therapeuticTypes';
import { OrderButton, OrderButtonText } from '../toBeDeleted/titleGrid.styles';
import CustomerDropdown from '../shared/crudModel/uploadModel/modelCreatePopper';
import ModelActionTypes from '../../providers/reducers/model/modelTypes';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import ConfirmDialog from '../shared/dialog/confirmDialog';

const InventoryContainer = () => {
  const [{
    cart: { cartCounter, isManualOrder }, user: { platform, allowedPermissionsMap: { userPermissions } },
    product: { addProductDialogOpen }, model: { addModelDialogOpen }
  }, dispatch] = Object.values(useStateValue());

  const navigate = useNavigate();
  const { tab } = useParams() || '';
  const [tabVal, setTabVal] = useState('products');
  const [openSuppliersModal, setOpenSuppliersModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadCsvDialog, setUploadCsvDialog] = useState(false);
  const [uploadBatchesCsvDialog, setUploadBatchesDialog] = useState(false);
  const [productState, setProductState] = useState([]);
  const [select, setSelect] = useState([]);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const inventoryTab = [
    {
      label: 'Orders', value: 'orders', width: '8%'
    },
    {
      label: 'Products', value: 'products', width: '8%'
    },
    {
      label: 'Batch Details', value: 'batch-details', width: '12%'
    },
    {
      label: 'Product Type', value: 'therapeutic-types', width: '12%'
    },
    {
      label: '', value: '', width: '60%', marginTop: '1.3rem'
    },
  ];

  const handleTabChange = (val) => {
    if (!val) return;
    navigate(`/inventory/${val}`);
    setTabVal(val);
  };

  useEffect(() => {
    if (tab) setTabVal(tab);
  }, [tab]);

  // BEGIN ORDER SEC

  const handleSuppliersModal = () => {
    // setOrderButtonEl(false);
    setOpenSuppliersModal(true);
  };

  if (confirmStatus) {
    navigate('/purchase-order');
    setConfirmStatus(false);
  }

  const actionHandler = (action) => {
    switch (action) {
      case 'create-individually': {
        if (cartCounter) return setOpenConfirmDialog(true);
        return navigate('/purchase-order');
      }
      case 'upload_order_csv':
        return navigate('/map-order-csv');
      case 'automatic_order':
        return handleSuppliersModal();
      default:
        break;
    }
  };

  const hybrid = () => {
    if (selected.length === 1 && selected.includes('YES')) return 'YES';
    if (selected.length === 1 && selected.includes('NO')) return 'NO';
    if ((selected.length < 1) || (selected.length > 1 && selected.includes('YES') && selected.includes('NO'))) return 'ALL';
  };

  useEffect(() => { hybrid(); }, [selected]);

  const handleSelected = (e) => {
    const { value, checked } = e.target;
    const exist = selected.find((x) => x === value);
    if (checked && !exist) {
      setSelected([...selected, value]);
    }
    if (!checked && exist) {
      const newVal = selected.filter((x) => x !== value);
      setSelected(newVal);
    }
  };

  const [automaticOrdering] = useMutation(AUTOMATIC_ORDERING_MUTATION);
  const handleAutomaticOrdering = (supplierIds) => {
    setLoading(true);
    let msg;
    let type;
    automaticOrdering({
      variables: {
        isManualOrder,
        supplierIds,
        hybrid: hybrid()
      }
    })
      .then(({ data }) => {
        const { message } = data?.automaticOrdering || {};
        msg = message;
        type = 'success';
      })
      .catch((error) => {
        msg = error;
        type = 'error';
      })
      .finally(() => {
        setLoading(false);
        navigate({
          pathname: '/manual-cart',
          alertMessage: msg,
          alertSeverity: type
        });
      });
  };

  // END ORDER SEC

  // BEGIN PRODUCT SEC

  const {
    categoryId, dateFrom, dateTo, sku, productName, supplier, status
  } = productState;

  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_PRODUCT_MUTATION);
  const randomNum = Math.floor(Math.random() * 10000);
  const newFileName = `inventory products ${randomNum}`;

  const variables = {
    type: 'inventory',
    name: newFileName,
    category: categoryId,
    dateFrom,
    dateTo,
    sku,
    productName,
    supplier,
    status
  };

  const handleExport = async () => {
    exportMutation({
      variables
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleCsvDialog = () => setUploadCsvDialog(!uploadCsvDialog);
  const handleBatchesCsvDialog = () => setUploadBatchesDialog(!uploadBatchesCsvDialog);

  const updateCartTotal = (currentOrder) => {
    const { totalProductCount } = currentOrder || {};

    dispatch({
      type: CartActionTypes.CART_COUNT,
      payload: totalProductCount
    });
  };

  const [createOrder] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted(data) {
      updateCartTotal(data?.createOrder);
    }
  });

  const [bulkBuy] = useMutation(BULK_BUY_MUTATION);
  const handleBulkBuy = () => {
    if (!select.length) return toast.error('Select at least one product');
    const productIds = select.map((prodId) => ({
      productId: prodId,
      quantity: 1
    }));
    bulkBuy({
      variables: { productIds }
    })
      .then(({ data }) => {
        const { message } = data?.bulkBuy || {};
        toast.success(message);
        createOrder({ variables: { isManualOrder: true } });
      });
  };

  const productButtons = [
    {
      name: 'Bulk Buy', label: 'bulk_buy', loading: false, permission: !userPermissions?.includes('inventory_bulk_buy')
    },
    {
      name: 'Export', label: 'export', loading: exportLoading, permission: !userPermissions?.includes('inventory_export')
    },
    {
      name: 'Add Batches', label: 'add_batches', loading: false, permission: !userPermissions?.includes('inventory_add_batches')
    }
  ];

  const actionButtonHandler = (pref) => {
    switch (pref) {
      case 'bulk_buy':
        return handleBulkBuy();
      case 'add_batches':
        return handleBatchesCsvDialog();
      case 'export':
        return handleExport();
      default:
        break;
    }
  };

  const toggleIndividualDialog = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const productActionHandler = (action) => {
    switch (action) {
      case 'add_individually':
        return toggleIndividualDialog();
      case 'upload_order_csv':
        return handleCsvDialog();
      default:
        break;
    }
  };

  const displayTab = () => {
    switch (tabVal) {
      case 'products':
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} />;
      case 'orders':
        return <Orders />;
      case 'batch-details':
        return <BatchDetails />;
      case 'therapeutic-types':
        return <TherapeuticTypes />;
      default:
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} />;
    }
  };
  const [customerButton, setCustomerLogoEl] = useState(null);

  const handleCustomerButtonClick = (event) => {
    setCustomerLogoEl(customerButton ? null : event.currentTarget);
  };

  const toggleCustomerDialog = () => {
    dispatch({ type: ModelActionTypes.TOGGLE_DIALOG_OPEN });
  };

  // END PRODUCT SEC

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={3}>
            <PrimaryTitle variant="h5">Inventory</PrimaryTitle>
          </TitleTextGridContainer>
          {tabVal === 'orders' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              <TCustomButton
                className="erp-uat-open-orders"
                onClick={() => navigate('/manage-purchase-order')}
                type="secondary"
                header
                style={{ width: '10rem', marginRight: '.8rem' }}
                data-testid="erp-uat-open-orders"
              >
                Open PO
              </TCustomButton>
              <OrderButtonsPopper actionHandler={actionHandler} autoLoading={loading} />
            </Grid>
          )}
          {tabVal === 'products' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              {productButtons?.map((item) => {
                const {
                  name, label, loading: loadStatus, permission
                } = item;
                return (
                  <TCustomButton
                    key={name}
                    className={`erp-uat-${label}`}
                    type="secondary"
                    header
                    disabled={permission || loadStatus}
                    style={{ width: '10rem', marginRight: '.8rem' }}
                    onClick={() => actionButtonHandler(label)}
                    data-testid={`erp-uat-inventory-${label}`}
                  >
                    {loadStatus ? 'Loading...' : name }
                  </TCustomButton>
                );
              })}
              <ProductButtonsPopper actionHandler={productActionHandler} autoLoading={loading} />
            </Grid>
          )}
          {tabVal === 'therapeutic-types' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              <OrderButton
                onClick={handleCustomerButtonClick}
                disabled
              >
                <OrderButtonText>
                  Add Product Type
                </OrderButtonText>
              </OrderButton>
              <CustomerDropdown
                customerButton={customerButton}
                handleCustomerButtonClick={handleCustomerButtonClick}
                individualDialogHandler={toggleCustomerDialog}
                modelName="Product type"
              />
            </Grid>
          )}
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <Filter
            tabVal={tabVal}
            handleTabChange={handleTabChange}
            tab={inventoryTab}
          />
          {displayTab()}
        </PaperWrapper>
      </OrderGridContainer>

      <CreateProduct
        openProdDialog={uploadCsvDialog}
        toggleHandler={handleCsvDialog}
      />
      <CreateBatches
        openDialog={uploadBatchesCsvDialog}
        toggleHandler={handleBatchesCsvDialog}
      />

      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
      />

      <SuppliersModel
        open={openSuppliersModal}
        handleClose={() => setOpenSuppliersModal(false)}
        handleAutomaticOrdering={handleAutomaticOrdering}
        handleSelected={handleSelected}
        selected={selected}
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="You have items in your cart from automatic ordering !"
        desc="To continue purchase order creation manually, note that your cart items will be added to your manual order list"
        options={['Cancel', 'Yes, continue']}
        setStatus={setConfirmStatus}
        loading={false}
      />
    </MainContent>
  );
};

export default InventoryContainer;
