import { styled } from '@mui/material/styles';
import {
  Paper,
  IconButton,
  Button,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { RenderPopper } from '../../../utils/utils';

export const RenderPopperStyled = styled(RenderPopper)`
  width: 138px;
  height: 107px;
  border-radius: 8px;
  background-color: #fff;
`;

export const ChartTitle = styled(Typography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  color: #303030;
`;

export const FlexApart = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const ContentBox = styled(Paper)`
  margin-top: 1.5rem;
  height: 600px;
  padding: 1.5rem;
  border-radius: 12px;
`;

export const ChangeButton = styled(IconButton)`
  width: 8.938rem;
  height: 2.75rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid rgba(231, 232, 233, 1);
  text-transform: capitalize;

  &:hover {
    background-color: #f3f9ff;
  }
`;

export const Dropdown = styled('img')`
  width: 24px;
  height: 24px;
  margin-left: 0.7rem;
`;

export const GridContainer = styled(Button)`
  padding: 15px 25px;
  width: 100%;
  align-items: center;
  cursor: pointer;

  @media (min-width: 992px) {
    padding: 0px;
    width: 100%;
    height: 3.063rem;
    margin-bottom: 0.5rem;
  }
  &:hover {
    background-color: #f3f9ff;
  }
`;

export const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 2rem;
`;

export const ItemWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;
