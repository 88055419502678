import { styled } from '@mui/material/styles';
import {
  Grid, Typography, IconButton,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { OrderButton } from '../../cart/cart.styles';

export const MapButton = styled(IconButton)`
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 35px;
background-color: #FCED50;
color: #424242;
border-radius: 0;
border-radius: 15px;

&:hover {
  background-color: #D2C647;
}

&:disabled {
  color: #848483;
  background-color: #CCCCCB;
}

@media(min-width: 992px) {
  height: 50px;
  padding: 0px 25px;
  border-radius: 10px;
}
  `;
export const CSVUploadButton = styled(MapButton)`
  background-color: #FCED50;
  `;

export const CSVCancelButton = styled(OrderButton)`
  background-color: transparent;
  border: 1px solid grey;
  margin-right: 20px;
  &:hover {
    background-color: lightgrey;
  }
  `;
export const CSVNoIconCancelButton = styled(CSVCancelButton)`
  padding: 15px 50px !important;
  @media(min-width: 992px) {
    height: 44px;
    border-radius: 10px;
  }
`;
export const CSVNoIconUploadButton = styled(CSVUploadButton)`
  padding: 15px 50px !important;
  @media(min-width: 992px) {
    height: 44px;
    border-radius: 10px;
  }
`;
export const MapInfoGrid = styled(Grid)`
  background-color: #E0E0E0;
  padding: 30px;
  `;
export const MainMapGrid = styled(Grid)`
  background-color: transparent;
  padding: 30px 30px 30px 0;
  `;
export const TextWrapper = styled(Grid)`
  padding-left: 30px;
  `;
export const PrimaryTitle = styled(Typography)`
font-size: 3rem;
font-weight: 600;
margin-bottom: 8px;

@media(min-width: 992px) {
  font-size: 1.8rem;
}
`;
export const SelectGrid = styled(Grid)`
margin-bottom: 5px;
padding: 6px;
padding-left: 30px;
`;
export const SelectDividerLine = styled(Grid)`
  height: 1px;
  background-color: #CCCCC;
  margin-left: 30px;
  margin-right: 10px;
`;
export const Ul = styled('ul')`
padding: 15px;
margin: 0
`;
export const Li = styled('li')`
font-weight: ${(props) => (props.strikeThrough ? '400' : '600')};
color: ${(props) => (props.strikeThrough ? '#A3A3A3' : '')};
margin-bottom: 5px;
`;
export const CSVNoIconButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;
export const DownloadIconImage = styled('img')`
  height: 130px;
`;
export const DownloadIconWrapper = styled(Grid)`
  min-height: 250px;
  background-color: #fff;
`;
export const ModifyGrid = styled(Grid)`
  text-align: right;
`;
export const ModifyCSVLabel = styled(Grid)`
  cursor: pointer;
  font-weight: 600;
`;
export const CancelMapIcon = styled(Close)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.9rem;
    height: 1.5rem;
  }
`;
