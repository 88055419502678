import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardLoader from '../../shared/cardLoader';
import { numberFormater } from '../../shared/utils';
import {
  TypographyStyled,
  TypographyCountStyled,
  Title, ArrowImg
} from '../dashboardContainer.styles';
import { PassMark, FileIcon } from '../../../assets/svgs';
import arrowRight from '../../../assets/images/marketOrder/arrowRight.png';

const OrderActivityCards = ({ response }) => {
  const { loading, error, data } = response;

  const activity = [
    {
      id: 0,
      title: 'Total Order Value',
      section: '(In House)',
      total: data?.supplyChainDashboard?.totalOrderValueInHouse || 0,
      link: '/inventory/orders/own_orders',
      dataTest: 'totalOrderValueIH',
      img: <PassMark style={{ fontSize: '31px' }} />,
      className: 'total-order-value-in-house'
    },
    {
      id: 1,
      title: 'Total Order Count',
      section: '(In House)',
      total: data?.supplyChainDashboard?.totalOrderCountInHouse || 0,
      link: '/inventory/orders/own_orders',
      dataTest: 'totalOrderCountIH',
      img: <FileIcon style={{ fontSize: '31px' }} />,
      className: 'total-order-count-in-house'
    },
    {
      id: 2,
      title: 'Total Order Value',
      section: '(Affiliate)',
      total: data?.supplyChainDashboard?.totalOrderValueAffiliate || 0,
      link: '/inventory/orders/authorised',
      dataTest: 'totalOrderValueA',
      img: <PassMark style={{ fontSize: '31px' }} />,
      className: 'total-order-value-affiliate'
    },
    {
      id: 3,
      title: 'Total Order Count',
      section: '(Affiliate)',
      total: data?.supplyChainDashboard?.totalOrderCountAffiliate || 0,
      link: '/inventory/orders/authorised',
      dataTest: 'totalOrderCountA',
      img: <FileIcon style={{ fontSize: '31px' }} />,
      className: 'total-order-count-affiliate'
    }
  ];

  const navigate = useNavigate();
  const onRouteHanlder = (path) => {
    navigate(path);
  };

  if (loading || error) return <CardLoader cols={4} />;

  return (
    <Box style={{ marginTop: '2rem' }}>
      <Title className="erp-uat-order-overview">Order Overview</Title>
      <Grid container spacing={4} style={{ marginTop: '.5rem' }}>
        {activity?.map((card) => (
          <Grid item xs={3} key={card?.id} data-testid={card?.dataTest} onClick={() => onRouteHanlder(card?.link)}>
            <Box
              p={4}
              bgcolor="background.paper"
              borderRadius={20}
              // className="text-center"
              style={{ cursor: 'pointer', padding: '25px 0px 25px 20px', borderRadius: '12px' }}
              className={`erp-uat-${card?.className}`}
            >
              <Grid container spacing={4}>
                <Grid item xs={2}>
                  {card.img}
                </Grid>
                <Grid item xs={10}>
                  <TypographyStyled>
                    {card?.title}
                    {' '}
                    {card?.section}
                  </TypographyStyled>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={8} style={{ padding: '32px 0 0 32px' }}>
                  <TypographyCountStyled>
                    {numberFormater(card?.total)}
                  </TypographyCountStyled>
                </Grid>
                <Grid item xs={4}>
                  <ArrowImg src={arrowRight} alt="arrow right" />
                </Grid>
              </Grid>
              {/* <TypographyStyled>{card?.section}</TypographyStyled> */}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

OrderActivityCards.propTypes = {
  response: PropTypes.instanceOf(Object).isRequired
};

export default OrderActivityCards;
