import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../providers/stateProvider';
import { SC_DASHBOARD } from '../../queries/sc-dashboard';
import MainContent from '../customComponents/mainContent';
import OrderActivityCards from './components/orderActivityCards';
import ProductActivityCards from './components/productActivityCards';
import ScChart from './components/scChart';
import { ContainerBody, HeaderText } from './dashboardContainer.styles';

const ScDashboard = () => {
  const [getDate,] = useState({
    from: '',
    to: ''
  });
  const [, dispatch] = Object.values(useStateValue());
  const [filter, setFilter] = useState('month');

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const handleFilter = (filterName) => setFilter(filterName);

  const response = useQuery(SC_DASHBOARD, {
    variables: {
      dateFrom: getDate.from,
      dateTo: getDate.to,
      filterBy: filter
    },
    fetchPolicy: 'cache-and-network'
  });

  return (
    <MainContent>
      <ContainerBody>
        <HeaderText>Supplier Chain Dashboard</HeaderText>
        {/* <Header setGetDate={setGetDate} getDate={getDate} /> */}
        <OrderActivityCards response={response} />
        <ProductActivityCards response={response} getDate={getDate} />
        <ScChart response={response} handleFilter={handleFilter} filter={filter} />
      </ContainerBody>
    </MainContent>
  );
};
export default ScDashboard;
