import * as React from 'react';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList
} from '@mui/material';
import PropTypes from 'prop-types';
import { FilterList } from '@mui/icons-material';
import { ButtonWrapper, MenuItemStyled } from './dashboard.styles';

const ActionButton = ({ sortHandler }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) { return; }
    setOpen(false);
  };

  return (
    <>
      <ButtonWrapper
        variant="outlined"
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
        data-testid="filter"
      >
        <FilterList style={{ marginRight: '.4rem' }} />
        <span style={{ opacity: '0.5' }}>Filter</span>
      </ButtonWrapper>
      <Popper
        data-testid="popper"
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled onClick={() => sortHandler('value', setOpen)}>
                    By Value
                  </MenuItemStyled>
                  <MenuItemStyled onClick={() => sortHandler('count', setOpen)}>
                    By Count
                  </MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

ActionButton.propTypes = {
  sortHandler: PropTypes.func.isRequired
};

export default ActionButton;
