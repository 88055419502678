import {
  Grid,
  InputAdornment
} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../assets/images/Search Icon.png';
import ActiveIcon from '../../../assets/images/marketOrder/activeOrder.png';
import FilterIcon from '../../../assets/images/marketOrder/filter.png';
import GrossOrderIcon from '../../../assets/images/marketOrder/grossOrder.png';
import GrossSavingsIcon from '../../../assets/images/marketOrder/savingsValue.png';
import TotalOrderIcon from '../../../assets/images/marketOrder/totalOrder.png';
import { useStateValue } from '../../../providers/stateProvider';
import currencyFormatter from '../../../utils/currencyFormatter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import MainContent from '../../customComponents/mainContent';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import TablePagination from '../../shared/tablePagination';
import StatisticCard from '../shared/statisticCard';
import TitleGrid from '../shared/titleGrid';
import FilterPopper from './filters/filterPopper';
import {
  FilterDropButton, FilterDropGrid, FilterImg,
  FooterGrid,
  ItemContainer,
  ItemsBox,
  MainContainer,
  OrderGridContainer,
  OrderText,
  SearchBox,
  SearchButton,
  SearchContainer,
  SearchImage,
  SearchTextField,
  StatisticsGrid,
  StatisticsGridContainer
} from './order.styles';
import ReturnRow from './returnRow';

const Order = ({
  affiliateOrders,
  totalCount,
  loading,
  handleSearch,
  pageNumber,
  pageCount,
  setPageCount,
  setPageNumber,
  handleSubmit,
  setFilter
}) => {
  const totalsContainer = useRef(null);
  const [status, setStatus] = useState('');
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalOrderAmount = affiliateOrders.reduce((previous, current) => +previous + +current.total, 0);
  const totalSavingsAmount = affiliateOrders.reduce((previous, current) => +previous + +current.savings || 0, 0);

  const activeOrders = affiliateOrders.filter((order) => order.status === 'ORDER_IN_CART');

  const STATISTICS = [
    {
      id: 1, icon: TotalOrderIcon, title: 'Total Orders', value: affiliateOrders.length
    },
    {
      id: 2, icon: ActiveIcon, title: 'Active Orders', value: activeOrders.length
    },
    {
      id: 3, icon: GrossOrderIcon, title: 'Gross Order Value', value: currencyFormatter(totalOrderAmount)
    },
    {
      id: 4, icon: GrossSavingsIcon, title: 'Gross Savings Value', value: currencyFormatter(totalSavingsAmount)
    },
  ];

  const isLarge = useMediaQuery('(min-width:1200px)');

  const role = localStorage.getItem('oga_user_role');
  if (role === 'oga-pharmacy-admin') return navigate('/orders-admin');

  const spacing = () => (isLarge ? 8 : 6);

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const handleStatus = (event, column) => {
    setStatus(column);
    handleFilterButtonClick(event);
    switch (column) {
      case 'Open Orders':
        return setFilter('ORDER_SENT');
      case 'Confirmed Orders':
        return setFilter('ORDER_RECEIVED');
      case 'Dispatched Orders':
        return setFilter('ORDER_DISPATCHED');
      case 'Closed Orders':
        return setFilter('ORDER_CLOSED');
      default:
        break;
    }
    return null;
  };
  // const showSupplierOrders = () => {
  //   if (status !== '') {
  //     return affiliateOrders.filter((o) => o.orderStatus === `${filter}`);
  //   }
  //   return affiliateOrders;
  // };

  // const headers = [
  //   'Date Placed', 'Order No', 'Order Status', 'Amount', 'Savings',
  // ];

  // const FilterFields = [
  //   { name: 'orderNo', label: 'Order No' },
  //   { name: 'dateFrom', label: 'Date From' },
  //   { name: 'dateTo', label: 'Date To' },
  //   { name: 'orderTotalFrom', label: 'Order Total From' },
  //   { name: 'orderTotalTo', label: 'Order Total To' },
  //   { name: 'productName', label: 'Product' },
  //   { name: 'orderStatus', label: 'Order Status', options: ['OPEN', 'DISPATCHED', 'RECEIVED', 'CLOSED'] }
  // ];
  // const returnType = (name) => {
  //   switch (name) {
  //     case 'dateFrom':
  //     case 'dateTo':
  //       return 'date';
  //     case 'orderTotalFrom':
  //     case 'orderTotalTo':
  //       return 'number';
  //     default:
  //       return 'text';
  //   }
  // };
  // const returnTextField = (field) => {
  //   const {
  //     name: fieldName, label
  //   } = field;
  //   const _value = state[fieldName];

  //   return (
  //     (fieldName === 'orderStatus'
  //       ? (
  //         <ReturnSelectField
  //           name={fieldName}
  //           label="Order Status"
  //           field={field}
  //           renderValue={_value}
  //           value={_value}
  //           disabled={false}
  //           width="100%"
  //           handleChange={handleFilterChange}
  //           fullWidth
  //         />
  //       )
  //       : (
  //         <CTextField
  //           variant="filled"
  //           label={label}
  //           value={_value}
  //           size={isLarge ? 'small' : 'medium'}
  //           type={returnType(fieldName)}
  //           name={fieldName}
  //           onChange={handleFilterChange}
  //           InputLabelProps={{ shrink: true }}
  //           fullWidth
  //         />
  //       )
  //     )
  //   );
  // };

  const renderSearchField = () => (
    <SearchContainer item xs={8} lg={9}>
      <SearchTextField
        variant="outlined"
        label=""
        // defaultValue={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search Order"
        fullWidth
        className="mpFe-uat-orders-search-input"
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
    style={{ cursor: 'pointer' }}
  >
    <SearchButton
      aria-label="Search Order"
      edge="start"
      disabled
    >
      <SearchImage
        alt="search icon"
        src={SearchIcon}
      />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const renderFilter = () => (
    <FilterDropGrid item xs={2} lg={1}>
      <FilterDropButton
        variant="outlined"
        onClick={handleFilterButtonClick}
        className="mpFe-uat-orders-create-filter-button"
      >
        <FilterImg src={FilterIcon} alt="filter" />
        <Hidden mdDown>Filter</Hidden>
      </FilterDropButton>
    </FilterDropGrid>
  );

  return (
    <MainContent>
      <MainContainer item container xs>
        <OrderGridContainer container>
          <TitleGrid />

          <StatisticsGridContainer ref={totalsContainer} container spacing={spacing()}>
            {STATISTICS.map((statistic) => (
              <StatisticsGrid key={statistic.id} item xs={12} md={3}>
                <StatisticCard
                  statistic={statistic}
                />
              </StatisticsGrid>
            ))}
          </StatisticsGridContainer>

          <OrderText>All Orders</OrderText>

          <ItemContainer container item>
            <Hidden mdDown>
              <SearchBox container>
                {renderSearchField()}
                <Grid item xs={3} lg={2}>
                  <CustomDateRanges
                    handleSubmit={handleSubmit}
                    xs={12}
                    styles={{
                      width: '100%', height: '3.2rem', border: '1px solid #E7E8E9', borderRadius: '7px', marginLeft: '10px'
                    }}
                  />
                </Grid>
                {renderFilter()}
              </SearchBox>
            </Hidden>

            <ItemsBox container>
              <Hidden mdUp>
                <SearchBox container>
                  {renderSearchField()}
                  {renderFilter()}
                </SearchBox>
              </Hidden>

              {loading ? <Grid container><ProductsTableLoader /></Grid>
                : (affiliateOrders?.map((orderItem, index) => (
                  <ReturnRow
                    key={orderItem?.id}
                    orderItem={orderItem}
                    index={index}
                  />
                )))}
              <FooterGrid>
                <TablePagination
                  total={totalCount}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </FooterGrid>
            </ItemsBox>
          </ItemContainer>

        </OrderGridContainer>
      </MainContainer>
      <FilterPopper
        filterButtonEl={filterButtonEl}
        status={status}
        handleStatus={handleStatus}
        handleFilterButtonClick={handleFilterButtonClick}
      />
    </MainContent>
  );
};

Order.propTypes = {
  affiliateOrders: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

Order.defaultProps = {
  loading: false,
};

export default Order;
