import React from 'react';
import MapCsvDialog from './mapCsvDialog';

function MapSupplierCsvDialog() {
  const fields = [
    ['Supplier Code', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Supplier Name', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false
    }],
    ['Payment Term', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Email', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Address Line 1', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Address Line 2', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Region', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['City', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Provider Promo', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Country', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }],
    ['Markup', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false
    }]
  ];
  return (
    <MapCsvDialog
      fields={fields}
      model="supplier"
      endpoint="suppliers"
      backRoute="suppliers"
    />
  );
}

MapSupplierCsvDialog.propTypes = {
};
export default MapSupplierCsvDialog;
