import React from 'react';
import MarketOrderContainer from './marketOrderContainer';
import NonAuthMarketOrderContainer from './marketPlaceNonAuthComponent/marketOrderContainer';

const MpOrderContainer = () => {
  const token = localStorage.getItem('oga_rest_token');

  return (
    <div>
      {token ? <MarketOrderContainer /> : <NonAuthMarketOrderContainer />}
    </div>
  );
};

export default MpOrderContainer;
