import {
  Grid, Typography, IconButton, CircularProgress,
  TextField, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  & > * {
    padding: .5rem 3rem;
    width: 100%;
  }
  @media (max-width: 991px) {
    display: block;
  }
`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
    min-width: 55rem;
  }
  @media (max-width: 991px) {
    & .MuiDialog-paperScrollPaper {
      height: 100;
      border-radius: 25px;
    }
  }
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 1.5rem;
  margin-bottom: 4px;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: .9rem;
  color: #858383;
  margin-bottom: 15px;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const DividerLine = styled('hr')`
  border: 1px solid #CCCCCC;
  width:96%;
  min-width: 300px;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #E0E0E0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 991px) {
    font-size: 30px;
    font-weight: 700;
  }
`;
export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  color: #FFFFFF;
  background-color: #303030;
  margin-bottom: 40px;

  &:hover {
    background-color: #C0C0C1;
  }
  @media (max-width: 991px) {
    display: block;
    height: 100px;
    width: 100%;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #FFFFFF;
  background: #C0C0C1;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 40px;
  // border: 2px solid #424242;
  @media (max-width: 991px) {
    display: block;
    width: 100%;
    height: 100px;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const UploadGrid = styled(Grid)`
  background-color: #E0E0E0;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const FormWrapper = styled(Grid)`
  @media (min-width: 992px) {
    max-width: 48%;
  }
`;

export const SupplierTextField = styled(TextField)`
  width: 100%;
  padding-top: 10px;

  @media (min-width: 992px) {
    & .MuiFilledInput-root {
      height: 60px;
    }
    & .MuiFilledInput-input {
      font-size: .9rem;
    }
  }
  & .MuiFilledInput-root {
    background-color:  #FFF;
    border-radius: 10px;
    border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #d8d8d8')};

  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
  & .MuiFormLabel-root.Mui-disabled {
    color: #000000;
  }
  & .MuiFilledInput-inputMarginDense {
    padding: 15px 10px;
  }
  @media (max-width: 991px) {
    & .MuiFilledInput-input {
      font-size: 2rem;
    }
    & .MuiFilledInput-root {
      height: 100px;
      margin-bottom: 1.5rem;
      border-radius: 25px;
    }
  }
`;

export const TopGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 1.5rem 1.5rem 0;

      @media (max-width: 991px) {
        margin: 0 0 1.5rem 0;
      }

      :nth-child(2n) {
        margin-right: 0;
      }
    }
`;

export const MiddleGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
`;

export const BottomGrid = styled(Grid)`
    & > * {
      width: calc(33.33% - 1rem);
      margin: 0 1.5rem 1.5rem 0;

      :last-child {
        margin-right: 0;
      }
    }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;
