import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, TableRow, TableBody, Paper, Checkbox
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMutation, useQuery } from '@apollo/client';
import GoToTop from '../../customComponents/scrollToTop';
import CTablePagination from '../../customComponents/cTablePagination';
import {
  OrderItemsGrid, OrderCountText,
} from '../../orders/admin/order.styles';
import paginationShowing from '../../../utils/pagination';
import {
  TableComponent, MainTableHead
} from '../../toBeDeleted/supplierDetails/supplierProducts.styles';
import {
  HeaderCell,
  AffiliateTableContainerWrapper,
  FooterGrid,
  SortCart, SortImage, PaddingBottom
} from '../dashboard/dashboard.styles';
import { useStateValue } from '../../../providers/stateProvider';
import ascImg from '../../../assets/images/Sort Ascending Icon.png';
import descImg from '../../../assets/images/Sort Descending Icon.png';
import Loader from '../../shared/loader';
import { GET_OUT_OF_STOCK_SALES } from '../../../queries/sales';
import ReturnOutOfStockRow from './returnOutOfStockRow';
import SelectReconciliationProductBatchDialog from './selectReconciliationProductBatchDialog';
import { RECONCILE_OUT_OF_STOCK_BATCH } from '../../../mutations/sales';
import ReconciliationActionTypes from '../../../providers/reducers/reconciliation/reconciliationTypes';

const OutOfStockTab = ({
  history, handleReFetch, reFetchCount
}) => {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    data: {},
  };
  const [{ business: { businessId, businessUser: businessUserId } }, dispatch] = Object.values(useStateValue());

  const [state, setState] = useState(initialState);
  const [allData, setAllData] = useState({});
  const [sortCart, setSortCart] = useState(false);
  const [asc, setAsc] = useState(false);
  const [desc, setDesc] = useState(false);
  const [reconcileOutOfStockBatch] = useMutation(RECONCILE_OUT_OF_STOCK_BATCH);

  const {
    currentPage, currentPageCount
  } = state;

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const closeModal = () => {
    dispatch({
      type: ReconciliationActionTypes.CLOSE_BATCH_MODAL
    });
  };
  const handleReconcileOutOfStockBatch = (variables) => {
    reconcileOutOfStockBatch({
      variables
    })
      .then(({ data }) => {
        const { message } = data?.reconcileOutOfStockBatch || {};
        closeModal();
        handleReFetch(message, data);
      })
      .catch((err) => {
        closeModal();
        handleReFetch(err?.message);
      });
  };
  const sortByCart = (direction) => {
    if (direction === 'asc') {
      setDesc(false);
      setAsc(!asc);
      // eslint-disable-next-line no-use-before-define
      data.outOfStockSales.sort((a, b) => ((a.total > b.total) ? 1 : -1));
    } else if (direction === 'desc') {
      setDesc(!desc);
      setAsc(false);
      // eslint-disable-next-line no-use-before-define
      data.outOfStockSales.sort((a, b) => ((a.total < b.total) ? 1 : -1));
    }
    // eslint-disable-next-line no-use-before-define
    setAllData(data);
  };
  const variables = {
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  const {
    data, loading, error
  } = useQuery(GET_OUT_OF_STOCK_SALES, {
    fetchPolicy: 'no-cache',
    variables
  });

  useEffect(() => {
    if (data && data.outOfStockSales) {
      setAllData(data);
    }
  }, [data, reFetchCount]);

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;
  const {
    outOfStockSales = [], outOfStockSalesTotalNumber = 0
  } = allData;
  const showing = paginationShowing(outOfStockSales, currentPageCount);

  return (
    <>
      <OrderItemsGrid>
        <OrderCountText>
          Showing
          <b>
            {` ${showing()} - ${showing('end')} `}
          </b>
          of
          <b>
            {` ${outOfStockSalesTotalNumber} `}
          </b>
          sales(s)
        </OrderCountText>
      </OrderItemsGrid>

      <AffiliateTableContainerWrapper component={Paper}>
        <TableComponent aria-label="supplier products table">
          <MainTableHead>
            <TableRow>
              <HeaderCell />
              <HeaderCell>Date Sold</HeaderCell>
              <HeaderCell>Product</HeaderCell>
              <HeaderCell>Pack Size</HeaderCell>
              <HeaderCell>Qty Sold</HeaderCell>
              <HeaderCell>Receipt #</HeaderCell>
              <HeaderCell>Sold By</HeaderCell>
              <HeaderCell style={{ cursor: 'pointer' }} onClick={() => setSortCart(!sortCart)}>
                Sale Amount
                <ArrowDropDownIcon />
              </HeaderCell>
            </TableRow>

          </MainTableHead>
          {sortCart
            ? (
              <SortCart>
                <PaddingBottom>Sort</PaddingBottom>
                <SortImage src={ascImg} />
                Ascending
                <Checkbox
                  checked={asc}
                  onChange={() => sortByCart('asc')}
                  disabled={loading}
                  style={{ position: 'absolute', left: '265px' }}
                  name="asc"
                  color="primary"
                />
                <div style={{ padding: '3%' }} />
                <SortImage src={descImg} />
                Decending
                <Checkbox
                  checked={desc}
                  onChange={() => sortByCart('desc')}
                  disabled={loading}
                  style={{ position: 'absolute', left: '265px' }}
                  name="desc"
                  color="primary"
                />
              </SortCart>
            )
            : ''}
          <TableBody>
            {outOfStockSales.map((outOfStockSale) => (
              <ReturnOutOfStockRow
                key={outOfStockSale.saleItem.id}
                outOfStockSale={outOfStockSale}
                history={history}
              />
            ))}
          </TableBody>
        </TableComponent>
        {!outOfStockSales && <Grid style={{ textAlign: 'center' }}>No Data</Grid>}
      </AffiliateTableContainerWrapper>

      <FooterGrid item container xs={12} justifyContent="flex-end">
        <Grid item>
          <CTablePagination
            rowsPerPageOptions={[20, 50, 100, 150, 200]}
            component="div"
            count={outOfStockSalesTotalNumber}
            page={currentPage - 1}
            onChangePage={handleChangePage}
            rowsPerPage={currentPageCount}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </FooterGrid>
      {
        (outOfStockSales.length)
          ? <GoToTop /> : ''

      }
      <SelectReconciliationProductBatchDialog handleReconcileOutOfStockBatch={handleReconcileOutOfStockBatch} />
    </>
  );
};

OutOfStockTab.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  handleReFetch: PropTypes.func.isRequired,
  reFetchCount: PropTypes.number.isRequired
};

export default OutOfStockTab;
