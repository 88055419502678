/* eslint-disable no-unused-vars */
import db, { addSale, getOrphanData } from '../saleObject';

const saleUpdate = async (saleId, cart) => cart.map(
  async ({ productId, quantity, batches }) => db.transaction(
    'rw',
    db.products,
    db.saleItems,
    db.sales,
    db.saleItemBatch,
    async () => db.products.get(productId).then((product) => {
      const unitCost = product?.resolvedPriceInUseValue;
      const { quantityInStock: prodQIS, batches: origBatches } = product;
      const newProdQuantity = prodQIS - quantity;
      const amountPaid = unitCost * quantity;
      const saleItemObj = {
        name: product.brandName,
        productId: product.id,
        saleId,
        quantity,
        unitCost,
        total: amountPaid
      };
      return db.saleItems.add(saleItemObj).then(
        () => db.sales.get(saleId).then((sale) => {
          const params = {
            outlet: sale.outlet,
            saleItem: sale,
            quantity
          };
          if (batches.length) {
            return batches.map(async (batch) => {
              const origBatch = origBatches.find(({ id }) => +id === batch.batchId);
              const { quantityInStock: batchQIS } = origBatch;
              origBatch.quantityInStock = batchQIS - Number(batch.qtyToSell);
              const batchIndx = origBatches.findIndex(({ id }) => id === origBatch.id);
              if (batchIndx !== -1) origBatches[batchIndx] = origBatch;
              return db.products.update(productId, {
                quantityInStock: newProdQuantity, batches: origBatches
              }).then(() => {
                params.quantity = Number(batch.qtyToSell);
                params.status = 'SIB_RECONCILED';
                params.orderProducts = { [origBatch.id]: Number(batch.qtyToSell) };
                return db.saleItemBatch.add(params);
              });
            });
          }
          return db.saleItemBatch.add(params);
        })
      );
    })
  )
);

const returnSaleUpdate = async (saleId, cart) => cart.map(
  async ({
    productId, quantity, reason, resellable, itemBatches
  }) => db.transaction(
    'rw',
    db.products,
    db.saleItems,
    db.sales,
    db.saleItemBatch,
    db.orderProducts,
    async () => db.products.get(productId).then((product) => {
      const { quantityInStock: prodQIS, batches: origBatches } = product;
      const newProdQuantity = prodQIS + quantity;
      return db.sales.get(saleId).then(() => {
        if (resellable && itemBatches.length) {
          return itemBatches.map(async (batch) => {
            const origBatch = origBatches.find(({ id }) => id === batch.orderProductId);
            const meta = JSON.parse(origBatch?.meta.replace(/'/g, '"').replace('None', '"N/A"'));
            const { quantityInStock: batchQIS } = origBatch;
            const quantitySold = meta.quantity_sold || 0;
            const totalSold = quantitySold - Number(batch.itemBatchQuantity);
            meta.quantity_sold = totalSold;
            origBatch.meta = JSON.stringify(meta);
            origBatch.quantityInStock = batchQIS + Number(batch.itemBatchQuantity);
            const batchIndx = origBatches.findIndex(({ id }) => id === origBatch.id);
            if (batchIndx !== -1) origBatches[batchIndx] = origBatch;

            return db.products.update(productId, {
              quantityInStock: newProdQuantity, batches: origBatches
            });
          });
        }
        return db.sales.get(saleId);
      });
    })
  )
);

export const createSales = async (variables, session, dateOfSale) => {
  const {
    cart, paymentsMade, transactionDate, receiptNumber, customerId
  } = variables;
  const { businessuserSet: businessUserSet } = session;

  const addSalePayments = async (saleId) => paymentsMade.forEach(
    async ({ paymentMethod, amount }) => {
      await db.salePayments.add({
        saleId,
        paymentMethod,
        amount,
        dateCreated: dateOfSale
      });
    }
  );

  const paymentMethod = paymentsMade.length
    ? paymentsMade[0].paymentMethod : 'Split';

  const params = {
    businessUser: businessUserSet[0]?.id,
    business: businessUserSet[0]?.business?.id,
    transactionDate,
    receiptNumber,
    customerId,
    paymentMethod,
    status: 'SALE_COMPLETED'
  };

  const saleId = await addSale(params).then(async (resp) => {
    await addSalePayments(resp);
    await saleUpdate(resp, cart);
    return resp;
  });

  return saleId;
};

export const returnLocalSale = async (variables, session, dateOfSale) => {
  const {
    cart, paymentsMade, transactionDate, returnSaleId
  } = variables;
  const { businessuserSet: businessUserSet } = session;

  const addSalePayments = async (saleId) => paymentsMade.forEach(
    async ({ paymentMethod, amount }) => {
      await db.salePayments.add({
        saleId,
        paymentMethod,
        amount,
        dateCreated: dateOfSale
      });
    }
  );

  const paymentMethod = paymentsMade.length
    ? paymentsMade[0].paymentMethod : 'Split';

  const params = {
    businessUser: businessUserSet[0]?.id,
    business: businessUserSet[0]?.business?.id,
    transactionDate,
    paymentMethod,
    returnSaleId,
    status: 'SALE_RETURNED'
  };

  const saleId = await addSale(params).then(async (resp) => {
    await addSalePayments(resp);
    await returnSaleUpdate(resp, cart);
    return resp;
  });

  return saleId;
};

export const getReturnData = async (saleId) => {
  const data = {
    receiptNumber: '',
    businessUser: {},
    message: 'Sale has been successfully created'
  };
  return db.transaction(
    'r',
    db.sales,
    db.businessUsers,
    db.businesses,
    db.outlets,
    db.users,
    async () => db.sales.get(saleId).then((sale) => {
      data.receiptNumber = sale.receiptNumber;
      return db.businessUsers.get(sale.businessUser).then((businessUser) => db.users.get(businessUser.user.id).then((user) => {
        data.businessUser.user = user;
        return db.businesses.get(businessUser.business.id).then((business) => {
          data.businessUser.business = business;
          return data;
        });
      }));
    })
  );
};

export const syncSaleData = async (sale, makeSales) => {
  const data = await getOrphanData(sale.version);
  if (data.length) {
    const sales = data.map((item) => {
      const _item = item;
      _item.version = _item.id;
      delete _item.id;
      return _item;
    });
    const variables = { sales };
    const resp = await makeSales({ variables });
  }
};
