import { useQuery } from '@apollo/client';
import { TableBody } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Product } from '../../../providers/reducers/product/classes/Product';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { SALES_PRODUCTS_QUERY } from '../../../queries/products';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import CustomExportButton from '../../downloads/exportModule/exportComponent';
import TablePagination from '../../shared/tablePagination';
import Filters from './filter';
import {
  DivFullWidth,
  Header,
  HeaderGrid,
  HeaderGridItem,
  MainTableHead,
  TableComponent,
  TableContainerWrapper,
  TableGrid,
  TableHeader
} from './products.styles';
import ReturnRow from './returnRow';

const headers = [
  'Product Name', 'Qty in Stock', 'Cost Price', 'Selling Price', '% Profit/Loss',
  'Pack Size', 'Status', 'Action'
];

const initialState = {
  searchText: '',
  filters: {
    productType: 'only_business_products',
    status: 'ACTIVE'
  }
};

const Products = () => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const [{
    user,
  }, dispatch] = Object.values(useStateValue());
  const { platform, allowedPermissionsMap: { userPermissions } } = user;

  const { searchText, filters } = state;

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSetFilter = (name, value) => {
    if (!name) return setState({ ...state, filters: {} });
    if (name === 'productType') {
      return setState({
        ...state,
        filters: { [name]: value, status: 'ACTIVE' }
      });
    }
    return setState({
      ...state, filters: { [name]: value }
    });
  };

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const editProduct = (row) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: row }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} className={`erp-uat-${header.replace(/ /g, '-')}`}>{header}</TableHeader>
  ));

  const updateProductState = (products, totalCount, refetch) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { products, totalCount, refetch }
    });
  };

  const variables = {
    search: searchValue,
    pageCount,
    pageNumber,
    ...filters,
  };

  const {
    loading, error, data, refetch
  } = useQuery(SALES_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.salesPageProducts) {
      const { salesPageProducts, salesPageTotalNumber } = data;
      updateProductState(salesPageProducts, salesPageTotalNumber, refetch);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    salesPageProducts = [], salesPageTotalNumber = 0
  } = data || {};

  const rows = salesPageProducts?.map((product) => {
    const newProduct = new Product(product, platform);
    const manufacturer = product?.manufacturer;
    return { ...newProduct, manufacturer };
  });

  return (
    <TableContainerWrapper elevation={0}>
      <HeaderGrid container>
        <HeaderGridItem><Header>Product Items</Header></HeaderGridItem>
        <HeaderGridItem>
          <Header>
            <CustomExportButton
              title="Export All Products"
              color="secondary"
              fileName="business_product"
              large="large"
              icon="withIcon"
              name="Products"
              disabled={!userPermissions?.includes('sales_export_all_products')}
            />
            {/* <ExportButton onClick={exportProductHandler}>
              <ExportIconStyled />
              Export All Products
            </ExportButton> */}
          </Header>
        </HeaderGridItem>
      </HeaderGrid>
      <Filters
        state={state}
        handleSetFilter={handleSetFilter}
        handleSearch={handleSearch}
      />
      <TableGrid item container>
        {loading
          ? <ProductsTableLoader />
          : (
            <TableComponent item container>
              <MainTableHead item container>
                {returnHeaders()}
              </MainTableHead>
              <TableBody>
                {rows.map((_row) => (
                  <ReturnRow
                    refetch={refetch}
                    row={_row}
                    user={user}
                    editProduct={editProduct}
                  />
                ))}
              </TableBody>
            </TableComponent>
          )}
      </TableGrid>
      <DivFullWidth>
        {salesPageTotalNumber > 0 && (
        <TablePagination
          total={salesPageTotalNumber}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
        )}
      </DivFullWidth>
    </TableContainerWrapper>
  );
};

export default Products;
