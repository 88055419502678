import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import { JSONParse } from '../../../../../utils/json';
import { TableCellBodyStyled } from '../return-details.style';
import currencyFormatter from '../../../../../utils/currencyFormatter';

const SaleItemRow = ({ saleItem }) => {
  const {
    name, quantity, packSize, productDiscount, meta, unitCost, total
  } = saleItem;
  const { quantity_in_stock: quantityInStock = 'N/A', discount_on_returns: discountOnReturns = 0 } = JSONParse(meta);

  const getDiscount = () => {
    const result = productDiscount / (total / 100);
    return result || 0;
  };
  let aggregatedDiscount = unitCost * quantity - productDiscount;
  if (discountOnReturns) {
    aggregatedDiscount -= discountOnReturns;
  }

  return (
    <TableRow>
      <TableCellBodyStyled>{name}</TableCellBodyStyled>
      <TableCellBodyStyled>{packSize}</TableCellBodyStyled>
      <TableCellBodyStyled>
        {String(quantityInStock).padStart(2, '0')}
      </TableCellBodyStyled>
      <TableCellBodyStyled>
        {String(quantity).padStart(2, '0')}
      </TableCellBodyStyled>
      <TableCellBodyStyled>{currencyFormatter(unitCost)}</TableCellBodyStyled>
      <TableCellBodyStyled>
        {`${getDiscount()}%`}
      </TableCellBodyStyled>
      <TableCellBodyStyled>
        {currencyFormatter(aggregatedDiscount)}
      </TableCellBodyStyled>
    </TableRow>
  );
};

SaleItemRow.propTypes = {
  saleItem: PropTypes.instanceOf(Object)
};

SaleItemRow.defaultProps = {
  saleItem: {}
};

export default SaleItemRow;
