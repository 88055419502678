import {
  Grid
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const TitleGridWrapper = styled(Grid)`
    padding: 30px;
`;

export const OutletItems = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

export const UserContentWrapper = styled(Grid)`
    display: flex;
    justify-content: space-between;
    flex-wrap: no-wrap;
    padding: 20px 30px 10px;
`;
