import {
  TableRow, TableCell, InputBase, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddPlus } from '../../../../../assets/svgs';

export const MainTableRow = styled(TableRow)`
  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;
  &:nth-child(1) {
    text-align: center;
  }
  &:nth-child(3), :nth-child(5), :nth-child(6) {
    opacity: 0.5;
  }

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const CustomInputNumberBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 40%;
  border-radius: 10px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 16px 12px;
    padding-right: 5px;
    line-height: normal;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }

  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;

export const AddProductButton = styled(Button)`
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border: 1px solid #235A91;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  &.MuiButton-root {
    min-width: 0px;
  }
  @media (max-width: 991px) {
    width: 65px;
    height: 65px;
  }
`;

export const AddImg = styled(AddPlus)`
  &.MuiSvgIcon-root {
    width: 19px;
    height: 19px;
    color: #235A91;
  }

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
      width: 55px;
      height: 55px;
    }
  }
`;
