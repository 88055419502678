import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import qs from 'qs';
import moment from 'moment';
import { Grid, TableBody } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import MainContent from '../customComponents/mainContent';
import { CHAIN_MANAGER_DASHBOARD } from '../../queries/cmDashboard';
import { GET_BUSINESS_SIBLINGS } from '../../queries/affiliates';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import SwitchPopper from './switchPopper';
import CustomSearchField from '../shared/CustomSearchField';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import TablePagination from '../shared/tablePagination';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle,
  PaperWrapper, TableGrid, TableHeader, TableComponent, TableColumnHeaders,
  FooterWrapper, FilterGrid, StyledCustomButton, SwitchGrid
} from './chainManagerDashboard.styles';
import { getAxios } from '../../axios';
import { OgaToken } from '../../graphql/token';
import CustomExportButton from '../downloads/exportModule/exportComponent';
import { useStateValue } from '../../providers/stateProvider';
import withAuth from '../../utils/withAuth';

const headers = [
  { name: 'S/N', width: '70px' }, { name: 'Store Names', width: '280px' }, { name: 'Total Sale Amount', width: '200px' },
  { name: 'Number of Sales', width: '150px' }, { name: 'Gross Margin', width: '200px' }, { name: 'Cash Sales', width: '200px' },
  { name: 'Bank Transfer Sales', width: '200px' }, { name: 'Credit Sales', width: '200px' }, { name: 'Card Sales', width: '200px' }
];

const ChainManagerDashboard = () => {
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [switchGridEl, setSwitchGridEl] = useState(null);
  const [dates, setDates] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }, dispatch] = Object.values(useStateValue());

  const axios = getAxios();

  const handleSubmit = (dateRange) => {
    setDates(dateRange);
  };

  const handleDownloadCSV = async () => {
    const dateFrom = moment(dates?.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(dates?.dateTo).format('YYYY-MM-DD');
    const serverUrl = `${OgaToken.SERVER_LINK}`;
    const token = localStorage.getItem('oga_rest_token');
    axios.get(`${serverUrl}download_chain_manager_dashboard_csv/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      params: {
        date_from: dateFrom,
        date_to: dateTo,
        business_name: search
      },
      paramsSerializer: (_params) => qs.stringify(_params, { arrayFormat: 'repeat' })
    })
      .then((res) => {
        const filename = 'download_chain_manager_dashboard_csv';
        const blob = new Blob([res.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log('err :>> ', err);
      });
  };

  const variable = {
    dateFrom: moment(dates?.dateFrom).format('YYYY-M-DD'),
    dateTo: moment(dates?.dateTo).format('YYYY-M-DD')
  };
  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader style={{ width }}>
      {name}
    </TableHeader>
  ));

  const { data: siblingsData, refetch: siblingRefetch } = useQuery(GET_BUSINESS_SIBLINGS, {
    fetchPolicy: 'cache-and-network',
  });
  const { businessSiblings } = siblingsData ?? {};

  const variables = {
    pageCount,
    pageNumber,
    dateFrom: dates?.dateFrom,
    dateTo: dates?.dateTo,
    ...(search.length >= 3 ? { search } : { search: '' }),
  };

  const {
    loading, error, data
  } = useQuery(CHAIN_MANAGER_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    chainManagerDashboard = [], chainManagerDashboardTotalNumber = 0
  } = data || {};

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={4}>
            <PrimaryTitle variant="h5">Chain Manager&apos;s Dashboard</PrimaryTitle>
          </TitleTextGridContainer>
          <SwitchGrid
            item
            container
            xs={3}
            onClick={(e) => setSwitchGridEl(e.currentTarget)}
            style={{ pointerEvents: !userPermissions?.includes('cm_dashboard_switch_stores') ? 'none' : '' }}
          >
            Switch Stores
            <KeyboardArrowDownRounded style={{ fontSize: '1.5rem', color: '#606060' }} />
          </SwitchGrid>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <FilterGrid item container spacing={2}>
            <Grid item container xs={8}>
              <CustomSearchField
                name="search"
                value={search}
                fullWidth
                placeholder="Search"
                handleChange={(e) => setSearch(e?.target?.value)}
                disabled={!userPermissions?.includes('cm_dashboard_search')}
              />
            </Grid>
            <Grid
              item
              container
              xs={3}
              style={{
                maxWidth: '20%', flexBasis: '20%',
                pointerEvents: !userPermissions?.includes('cm_dashboard_date_filter') ? 'none' : ''
              }}
            >
              <CustomDateRanges
                handleSubmit={handleSubmit}
                xs={12}
                styles={{
                  height: '47px', width: '100%', border: '1px solid #bababa',
                }}
              />
            </Grid>
            <Grid item container xs={1} style={{ maxWidth: '13.3%', flexBasis: '13.3%' }}>
              {/* <StyledCustomButton type="tertiary" onClick={handleDownloadCSV}>
                Download CSV
              </StyledCustomButton> */}
              <CustomExportButton
                title="Download CSV"
                color="tertiary"
                fileName="chain_manager_dashboard"
                name="Chain Manager Dashboard"
                large="large"
                variable={variable}
              />
            </Grid>
          </FilterGrid>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <TableColumnHeaders item container>
                    {returnHeaders()}
                  </TableColumnHeaders>
                  <TableBody>
                    {chainManagerDashboard.map((_row, indx) => (
                      <ReturnRow
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>
          <FooterWrapper item container>
            {chainManagerDashboardTotalNumber > 0 && (
              <TablePagination
                total={chainManagerDashboardTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>

        <SwitchPopper
          switchGridEl={switchGridEl}
          setSwitchGridEl={setSwitchGridEl}
          businessSiblings={businessSiblings}
          siblingRefetch={siblingRefetch}
        />
      </Grid>
    </MainContent>
  );
};

export default ChainManagerDashboard;
