import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Paper, TableRow, TableBody
} from '@mui/material';
import GoToTop from '../../../customComponents/scrollToTop';
import CTablePagination from '../../../customComponents/cTablePagination';
import { THERAPEUTIC_TYPE_PRODUCTS_QUERY } from '../../../../queries/therapeuticType';
import {
  TableContainerWrapper, TableComponent, HeaderCell, FooterGrid, OrderCountText,
  MainTableHead
} from './therapeuticTypeProducts.styles';
import paginationShowing from '../../../../utils/pagination';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import { Product } from '../../../../providers/reducers/product/classes/Product';
import { useStateValue } from '../../../../providers/stateProvider';

const TherapeuticTypeProducts = ({
  state,
  therapeuticTypeId,
  handleChangePage,
  page,
  handleChangeRowsPerPage,
}) => {
  const [{ user: { platform } }, dispatch] = Object.values(useStateValue());

  const [searchValue, setSearchValue] = useState('');
  const {
    searchText, currentPage, currentPageCount,
  } = state;

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const { loading, error, data } = useQuery(THERAPEUTIC_TYPE_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      therapeuticTypeId,
      pageCount: currentPageCount,
      pageNumber: currentPage,
      productName: searchValue
    },
  });

  if (loading) return <ProductsTableLoader />;
  if (error) return <div>{error.message}</div>;
  const {
    therapeuticTypeProducts = [], productsTotalNumber = 0
  } = data || {};

  const rows = therapeuticTypeProducts.map((product) => new Product(product, platform));

  const showing = paginationShowing(therapeuticTypeProducts, currentPageCount);

  return (
    <>
      <TableContainerWrapper component={Paper}>
        <TableComponent aria-label="supplier products table">
          <MainTableHead>
            <TableRow>
              <HeaderCell />
              <HeaderCell />
              <HeaderCell>Product Name</HeaderCell>
              <HeaderCell>Pack Size</HeaderCell>
              <HeaderCell>Item Price</HeaderCell>
              <HeaderCell>Mkt. RRP*</HeaderCell>
              <HeaderCell />
              <HeaderCell />
            </TableRow>
          </MainTableHead>
          <TableBody>
            {rows.map((row) => (
              <ReturnRow key={row.id} row={row} />
            ))}
          </TableBody>
        </TableComponent>
      </TableContainerWrapper>
      <FooterGrid item container>
        <OrderCountText>
          Showing
          <b>
            {` ${showing()} - ${showing('end')} `}
          </b>
          of
          <b>
            {` ${productsTotalNumber} `}
          </b>
          products
        </OrderCountText>
        <CTablePagination
          rowsPerPageOptions={[20, 25]}
          component="div"
          count={productsTotalNumber}
          page={page - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
      {(therapeuticTypeProducts.length)
        ? <GoToTop /> : ''}
    </>
  );
};

TherapeuticTypeProducts.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  state: PropTypes.instanceOf(Object),
  therapeuticTypeId: PropTypes.number,
};

TherapeuticTypeProducts.defaultProps = {
  state: {},
  therapeuticTypeId: 0
};

export default TherapeuticTypeProducts;
