import React, { useState, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
  TableContainerWrapper, TableComponent, TableHeader,
  MainTableHead, TableGrid, DivFullWidth, MainTableBody, PrimaryTitle, TitleGridContainer, TitleTextGridContainer
} from './downloads.styles';
import TablePagination from '../../shared/tablePagination';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import Filter from './filter';
import { GET_ALL_EXPORT_URL } from '../../../queries/reports';
import { ActionButton, TextWrapper } from './returnRow.styles';
import RefreshIcon from '../../../assets/images/RefreshIcon.png';
import { addHyphen } from '../../shared/methods';

const headers = [
  'ID', 'Date & Time Generated', 'Name of Reports', 'Owners', 'Status', 'Action'
];

const startDate = moment().startOf('day').format('YYYY-MM-DD');
const endDate = moment().endOf('day').format('YYYY-MM-DD');
const initialState = {
  searchText: '',
  dateFilter: {
    dateFrom: startDate,
    dateTo: endDate
  }
};

const Downloads = () => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const { searchText, dateFilter } = state;

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setState({
      ...state,
      dateFilter: {
        dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
        dateTo: moment(dateTo).format('YYYY-MM-DD'),
      }
    });
  };

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} data-testid={`mp-uat-downloads-${addHyphen(header)}`}>{header}</TableHeader>
  ));

  const variables = {
    pageCount,
    pageNumber,
    search: searchValue,
    ...dateFilter
  };

  const {
    loading, error, data, refetch
  } = useQuery(GET_ALL_EXPORT_URL, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const handleRefresh = () => {
    refetch();
  };
  if (error) return <div>{error.message}</div>;
  const {
    csvDownloads = [], csvDownloadsTotalNumber = 0
  } = data || {};

  const rows = csvDownloads;

  return (
    <>
      <TitleGridContainer container item justifyContent="space-between">
        <TitleTextGridContainer item>
          <PrimaryTitle variant="h5">All Downloads</PrimaryTitle>
        </TitleTextGridContainer>
        <ActionButton
          data-testid="export"
          onClick={handleRefresh}
        >
          <img src={RefreshIcon} alt="refresh" />
          <TextWrapper>
            Refresh
          </TextWrapper>
        </ActionButton>
      </TitleGridContainer>
      <TableContainerWrapper elevation={0}>
        <Filter
          state={state}
          handleSubmit={handleSubmit}
          handleSearch={handleSearch}
        />
        <TableGrid item container>
          {loading
            ? <ProductsTableLoader />
            : (
              <TableComponent item container data-testid="mp-uat-downloads-table">
                <MainTableHead item container>
                  {returnHeaders()}
                  <TableHeader />
                </MainTableHead>
                <MainTableBody item container>
                  {rows.map((_row) => (
                    <ReturnRow
                      row={_row}
                    />
                  ))}
                </MainTableBody>
              </TableComponent>
            )}
        </TableGrid>
        <DivFullWidth>
          {csvDownloadsTotalNumber > 0 && (
          <TablePagination
            total={csvDownloadsTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          )}
        </DivFullWidth>
      </TableContainerWrapper>
    </>
  );
};

export default Downloads;
