import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_ORDER_INVOICE } from '../../../queries/orders';
import {
  GridContainer, ItemTypo, ItemWrapper, GridWrapper,
} from './invoiceDownloadDropdown.styles';
import { RenderPopper } from '../../../utils/utils';
import { OgaToken } from '../../../graphql/token';

const InvoiceDropdown = ({
  openInvoiceDialog, handleCloseModal, anchorEl, orderId
}) => {
  const open = Boolean(openInvoiceDialog);
  const {
    data
  } = useQuery(GET_ORDER_INVOICE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      orderId
    }
  });
  const handleDownload = (invoiceId) => {
    window.open(
      `${OgaToken.SERVER_LINK}download_partial_invoice/${invoiceId}`,
      '_blank'
    );
  };
  const invoices = data?.orderInvoices;
  return (
    <RenderPopper
      open={open}
      anchorEl={anchorEl}
      onClickAway={() => handleCloseModal()}
      popperPlacement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        {invoices?.map(({ invoice }, index) => (
          <GridContainer container item onClick={() => handleDownload(invoice)}>
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                {`Invoice ${index + 1}`}
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        ))}
      </GridWrapper>
    </RenderPopper>
  );
};

InvoiceDropdown.propTypes = {
  openInvoiceDialog: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  anchorEl: PropTypes.instanceOf(Object).isRequired,
  // invoices: PropTypes.instanceOf(Array).isRequired
};

export default InvoiceDropdown;
