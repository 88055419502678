import PropTypes from 'prop-types';
import React from 'react';
import {
  CardContentGrid,
  CardGridContainer, CardHeader,
  ContentHeader,
  ContentMeta, FooterMeta,
  HeaderText
} from './subBusiness.styles';

export default function SubBusinessCard({
  id, name, location, type, launched
}) {
  return (
    <CardGridContainer
      elevation={2}
    >
      <CardHeader container item>
        <HeaderText>
          {`SUB${id}`}
        </HeaderText>
      </CardHeader>

      <CardContentGrid>
        <ContentHeader>
          {name}
        </ContentHeader>

        <FooterMeta>
          <ContentMeta>
            {location}
          </ContentMeta>
          <ContentMeta>
            {`Launched: ${launched}`}
          </ContentMeta>
          <ContentMeta>
            {type}
          </ContentMeta>
        </FooterMeta>

      </CardContentGrid>
    </CardGridContainer>
  );
}

SubBusinessCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string,
  type: PropTypes.string,
  launched: PropTypes.string,
};

SubBusinessCard.defaultProps = {
  id: '',
  name: '',
  location: '',
  type: '',
  launched: '',
};
