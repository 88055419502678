const isLocalStorageKeyCreated = () => {
  if (!localStorage.getItem('receive_manual_order')) {
    localStorage.setItem(
      'receive_manual_order',
      JSON.stringify({ orderList: [] })
    );
  }
};

isLocalStorageKeyCreated();

const receiveManualOrder = {
  orderList: JSON.parse(localStorage.getItem('receive_manual_order')).orderList
};

export default receiveManualOrder;
