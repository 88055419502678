import React from 'react';
import PropTypes from 'prop-types';
import { BodyCell, MainTableRow } from './returnRow.styles';

const ReturnRow = ({ dispErr }) => {
  const {
    productName, quantityInStock, quantityOrdered
  } = dispErr;

  const cells = [productName, quantityOrdered, quantityInStock];

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell key={cell}>
      {cell}
    </BodyCell>
  ));

  return (
    <MainTableRow>{renderBodyCells()}</MainTableRow>
  );
};

ReturnRow.propTypes = {
  dispErr: PropTypes.shape(Object),
};

ReturnRow.defaultProps = {
  dispErr: {},
};

export default ReturnRow;
