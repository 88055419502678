import {
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem 0px;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0rem 0px 1.8rem 0px;
    justify-content: space-between;
  }
`;

export const SearchGrid = styled(Grid)`
  width: 72%;
  @media(min-width: 1550px) {
    width: 74%;
  }

`;

export const DateGrid = styled(Grid)`
`;

export const ExportGrid = styled(Grid)`
  width: 10%;
`;

export const ExportText = styled('span')`
  font-size: 13px;
  font-weight: 400;
  color: #FFF;
  @media(max-width: 991px) {
     font-size: 2.5rem;
  }
`;
