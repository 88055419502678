import { styled } from '@mui/material/styles';
import {
  TableContainer, TableCell, Checkbox, Typography, Grid, IconButton, Paper
} from '@mui/material';
import { StTransfer } from '../../../assets/svgs';
import CustomButton from '../../customComponents/customButton';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const SearchGrid = styled(Grid)`
  padding: 2rem 1.5rem .5rem;
  align-items: center;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
  height: 2rem;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`

`;

export const MainTableHead = styled(Grid)`

`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F4F5F7;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 4%;
    }
    &:nth-child(2), :nth-child(3), :nth-child(6), :nth-child(7) {
      width: 18%;
    }
    &:nth-child(4), :nth-child(5) {
      width: 12%;
    }
  }
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 25px;
  font-weight: 600;
  color: #606060;
  margin-top: 0.5rem;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;
