const affiliate = {
  availablePromos: [],
  affiliatePromos: [],
  usablePromos: [],
  pricePolicyFactor: 1,
  affiliates: [],
  affiliatesCount: 0,
  refetch: null,
  affiliateDetailRefetch: null,
  affiliate: null,
  addAffiliateDialogOpen: false,
  loggedInAffiliate: {},
  loggedInAffiliateRefetch: null,
  isBusinessVirtualStore: false,
};

export default affiliate;
