import { useMutation } from '@apollo/client';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import DeleteIcon from '../../assets/images/marketOrder/DeleteIcon.png';
import beautyIcon from '../../assets/images/marketOrder/beautyIcon.png';
import consumableIcon from '../../assets/images/marketOrder/consumablesIcon.png';
import counterIcon from '../../assets/images/marketOrder/counterIcon.png';
import healthIcon from '../../assets/images/marketOrder/healthIcon.png';
import medicalIcon from '../../assets/images/marketOrder/medicalIcon.png';
import prescriptionIcon from '../../assets/images/marketOrder/prescriptionIcon.png';
import supplementsIcon from '../../assets/images/marketOrder/supplementsIcon.png';
import { DELETE_CATEGORIES } from '../../mutations/categories';
import ConfirmDialog from '../shared/confirmDialog';
import SuccessDialog from '../shared/successDialog';
import {
  CardBox,
  CardGrid,
  CategoryName,
  Delete,
  Logo,
  ProductNumber,
  SubCategoryBox,
  SubCategoryText
} from './therapeuticTypeCard.styles';

export default function SupplierCard({
  subType,
  name, handleDropdown,
  productCount, index, dropdown, refetch
}) {
  const [deleteCategories] = useMutation(DELETE_CATEGORIES);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [typeId, setTypeId] = useState('');

  const handleConfirmation = (categoryId) => {
    setTypeId(categoryId);
    setOpenConfirm(true);
  };

  const handleDelete = () => {
    setOpenConfirm(false);
    deleteCategories({
      variables: {
        categoryId: typeId
      }
    }).then(() => {
      setOpenSuccess(!openSuccess);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const categoryImg = [
    consumableIcon, medicalIcon, counterIcon, beautyIcon, prescriptionIcon, healthIcon, supplementsIcon
  ];

  return (
    <>
      <CardBox container xs={12} dropdown={dropdown} name={name}>
        <CardGrid item xs={1} style={{ padding: '.9rem 2rem' }}>
          <Logo src={categoryImg[index]} alt="logo" />
        </CardGrid>

        <CardGrid item xs={7} style={{ padding: '1.5rem' }}>
          <CategoryName>{name}</CategoryName>
          <ProductNumber>
            {`${productCount} Product(s)`}
          </ProductNumber>
        </CardGrid>

        <CardGrid
          container
          item
          xs={4}
          style={{
            padding: '1.5rem', justifyContent: 'flex-end', marginTop: '.6rem'
          }}
        >
          <ProductNumber onClick={() => handleDropdown(name)} style={{ color: '#235a91', marginRight: '1rem', cursor: 'pointer' }}>
            View Sub Category
          </ProductNumber>
          <KeyboardArrowDown onClick={() => handleDropdown(name)} style={{ fill: '#235a91', cursor: 'pointer' }} />
        </CardGrid>

        {(dropdown === name && subType.length > 0) && (
          subType.map(({ id: catId, therapeuticType }) => (
            <SubCategoryBox container key={catId}>
              <Grid item xs={8} style={{ marginTop: '1.5rem' }}>
                <SubCategoryText style={{ color: '#606060' }}>{therapeuticType}</SubCategoryText>
              </Grid>
              <Grid item container xs={4} style={{ marginTop: '1.5rem', justifyContent: 'flex-end', color: '#606060' }}>
                <Delete onClick={() => handleConfirmation(catId)} src={DeleteIcon} alt="Delete" style={{ cursor: 'pointer' }} />
                <ProductNumber onClick={() => handleConfirmation(catId)} style={{ cursor: 'pointer' }}>Remove</ProductNumber>
              </Grid>
            </SubCategoryBox>
          ))
        )}
      </CardBox>
      <ConfirmDialog
        openDialog={openConfirm}
        setOpenDialog={() => setOpenConfirm(!openConfirm)}
        buttonAction={handleDelete}
        title="Remove Sub Category?"
        desc="Are you sure you want to remove this sub category?"
        options={['Cancel', 'Yes, Remove']}
        therapeuticType
      />

      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={() => setOpenSuccess(!openSuccess)}
        title="Sub Category Removed"
        desc="Your selected sub category has been removed successfully"
        option="Ok"
        therapeuticType
      />

      {/* <CardGridContainer
        elevation={2}
        item
        container
        onClick={() => navigate(`/suppliers/${id}/details`)}
      >
        <CardHeader container item>
          {/* <HeaderIcon src={logo} alt="supplier logo" /> */}
      {/* <HeaderText>
        {`su${id}`}
      </HeaderText> */}
      {/* </CardHeader> */}

      {/* <CardContentGrid>
        <ContentHeader>
          {name}
        </ContentHeader>

        <FooterMeta>
          <ContentMeta>
            {creditDays}
          </ContentMeta>
          <ContentMeta>
            {`${productCount} Product(s)`}
          </ContentMeta>
        </FooterMeta>

        </CardContentGrid> */}
      {/* </CardGridContainer> */}
    </>
  );
}

SupplierCard.propTypes = {
  name: PropTypes.string.isRequired,
  productCount: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  subType: PropTypes.instanceOf(Array).isRequired,
  dropdown: PropTypes.string.isRequired,
  handleDropdown: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};
