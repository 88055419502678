import { gql } from '@apollo/client';

export const CREATE_REPORT_MUTATION = gql`
  mutation createReport(
    $business: Int, $status: String, $products: [StockReportProductType]!
  ){
    createReport(
      business: $business,
      status: $status,
      products: $products
    ){
      message
    }
  }
`;

export const CREATE_NEW_REPORT_MUTATION = gql`
  mutation createReport($business: Int!, $status: String!) {
    createReport(
      business: $business,
      status: $status
    ) {
      message
      stockReport {
        id
      }
    }
  }
`;

export const UPDATE_REPORT_MUTATION = gql`
  mutation updateReport(
    $stockReportId: Int, $business: Int, $status: String, $products: [StockReportProductUpdateType]!
  ){
    updateReport(
      stockReportId: $stockReportId,
      business: $business,
      status: $status,
      products: $products
    ){
      message
    }
  }
`;

export const REMOVE_REPORT_MUTATION = gql`
  mutation removeStockInventory($stockReportId: Int!, $inventoryIds: [Int]!) {
    removeStockInventory(
      stockReportId: $stockReportId
      inventoryIds: $inventoryIds
    ) {
      message
      __typename
    }
  }
`;

export const RECONCILE_STOCK_COUNT = gql`
  mutation reconcileStockCount(
    $productId: Int, $reason: String, $note: String
  ){
    reconcileStockCount(
      productId: $productId,
      reason: $reason,
      note: $note
    ){
      message
    }
  }
`;

export const CLOSE_REPORT_MUTATION = gql`
  mutation closeReport(
    $stockReportId: Int
  ){
    closeReport(
      stockReportId: $stockReportId,
    ){
      message
    }
  }
`;

export const EXPORT_MUTATION = gql`
  mutation exportCsv($type: String!, $name: String!, $dateFrom: String, $dateTo: String, $status: String!, $dateFilterBy: String, $productName: String, $batchNumber: String, $productId: String, $orderId: String, $returned: Boolean){
    exportCsv(type: $type, name: $name, dateFrom: $dateFrom, dateTo: $dateTo, status: $status, dateFilterBy: $dateFilterBy, productName: $productName, batchNumber: $batchNumber, productId: $productId, orderId: $orderId, returned: $returned){
      message
    }
  }
`;

export const EXPORT_BACK_ORDERS_MUTATION = gql`
  mutation exportCsv($type: String!, $name: String!, $search: String, $dateFrom: String, $dateTo: String, $status: String!){
    exportCsv(type: $type, name: $name, search: $search, dateFrom: $dateFrom, dateTo: $dateTo, status: $status){
      message
    }
  }
`;

export const EXPORT_PRODUCT_MUTATION = gql`
  mutation exportCsv($type: String!, $name: String!, $category: String, $productId: String, $productName: String, $supplier: String, $dateFrom: String, $dateTo: String, $status: String!, $sku: String){
    exportCsv(type: $type, name: $name, category: $category, productId: $productId, productName: $productName, supplier: $supplier, dateFrom: $dateFrom, dateTo: $dateTo, status: $status, sku: $sku){
      message
    }
  }
`;
