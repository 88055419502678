import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import {
  KeyboardArrowLeft, KeyboardArrowRight,
} from '@mui/icons-material';

import styled, { css } from 'styled-components';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const MainContainer = styled(Grid)`
  background: #F0F0F0;
`;

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 32px;
`;

export const StatisticsGridWrapper = styled(Grid)`
  overflow: hidden;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-top: 3rem;
    margin-bottom:2rem;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  transform: translateX(0px);
  transition: 500ms;
`;

export const OrdersTab = styled(Grid)`
  justify-content: space-between;
  border-top: 2px solid #CCCCCC;
  border-bottom: 2px solid #CCCCCC;

  @media(min-width: 992px) {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
`;

export const ActiveOrderNumber = styled(Typography)`
  font-size: 30px;
  color: #424242;
  font-weight: 600;
  padding: 35px 0px;

  @media(min-width: 992px) {
    font-size: 20px;
    padding: 30px 0px;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const getStyles = ({ show }) => {
  if (show) {
    return css`
      display: inline-flex;
    `;
  }
  return css`
    display: none;
  `;
};

export const ArrowButton = styled(({ ...otherProps }) => (
  <IconButton {...otherProps} />
))`
  padding: 0;
  margin-top: 14px;

  @media(min-width: 992px) {
    ${getStyles}
  }
`;

export const ArrowLeftIcon = styled(KeyboardArrowLeft)`
  cursor: pointer;
  font-size: 3rem;

  @media(min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  cursor: pointer;
  font-size: 3rem;

  @media(min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 30px;

  @media(max-width: ${sm}) {
    margin-bottom: 10px;
  }
`;

export const OrderCountText = styled(Typography)`
  font-size: 22px;
  color: #4D4F5C;
  margin: 14px 0;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;
