import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableBody } from '@mui/material';
import { useQuery } from '@apollo/client';
import { FilterList } from '@mui/icons-material';
import moment from 'moment';
import MainContent from '../../customComponents/mainContent';
import {
  TableWrapper, FooterGrid, BackArrowIconContainer, KeyboardArrowLeft, OrderGridContainer, MainContainer,
  PrimaryTitle, SecondaryTitle, TableContainer, TableColumnHeaders, WrapperPaper, FilterContainer, FilterText, SearchGrid, TableHeader, BackButtonText
} from './eodReport.styles';
import { useStateValue } from '../../../providers/stateProvider';
import { EOD_REPORT_QUERY } from '../../../queries/sales';
import ReturnReportRow from './returnReportRow';
import FilterPopper from './filterPopper';
import ToggleBusiness from '../../shared/busnesss';
import CustomSearchField from '../../shared/CustomSearchField';
import CustomExportButton from '../../downloads/exportModule/exportComponent';
import CustomButton from '../../customComponents/customButton';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../shared/tablePagination';
import CustomDatePicker from '../../shared/customDatePicker/customDatePicker';

const EODReport = ({
  history,
}) => {
  const tableColumns = [
    'Cash', 'Card', 'Bank Transfer', 'Sale Amount', 'Store Credit', 'Footfall', 'Note'
  ];

  const initialState = {
    currentPage: 1,
    currentPageCount: 10,
    data: {},
    selectedOptionFilter: new Map(),
    selectedColumns: tableColumns,
  };
  const [{
    business: {
      businessId, businessUser: businessUserId,
    }
  }] = Object.values(useStateValue());
  const [state, setState] = useState(initialState);
  const [allData, setAllData] = useState({});
  const [filterButton, setFilterButton] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(moment().startOf('day'));

  const handleSubmit = (event) => {
    setStartDate(event);
  };
  const handleFilterButtonClick = (event) => {
    setFilterButton(filterButton ? null : event.currentTarget);
  };

  const { selectedOptionFilter, selectedColumns } = state;

  const handleOptionToggle = (option) => {
    const filterSelectedOption = tableColumns;
    if (selectedOptionFilter.get(option)) {
      selectedOptionFilter.delete(option);
    } else {
      selectedOptionFilter.set(option, option);
    }
    selectedOptionFilter.forEach((prov) => {
      filterSelectedOption.splice(filterSelectedOption.indexOf(prov), 1);
    });
    setState({
      ...state, selectedOptionFilter, selectedColumns: filterSelectedOption
    });
  };

  const variables = {
    search: searchText.length > 3 ? searchText : '',
    pageCount,
    pageNumber,
    dateFrom: startDate,
    dateTo: moment(startDate).endOf('day'),
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  const {
    data, loading, error
  } = useQuery(EOD_REPORT_QUERY, {
    variables,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data && data.eodReport) {
      setAllData(data);
    }
  }, [data]);

  if (error) return `Error! ${error.message}`;
  const {
    eodReport = [], eodReportTotalNumber = 0
  } = allData;

  const exportVariable = {
    dateFrom: moment(startDate).format('YYYY-M-DD'),
    dateTo: moment(startDate).format('YYYY-M-DD'),
  };
  return (
    <MainContent style={{ backgroundColor: '#000' }}>
      <MainContainer item container xs>
        <OrderGridContainer container>
          <Grid container style={{ marginBottom: '1rem' }}>
            <Grid item xs={12}>
              <BackArrowIconContainer onClick={() => history.goBack()}>
                <KeyboardArrowLeft />
                <BackButtonText>
                  back
                </BackButtonText>
              </BackArrowIconContainer>
            </Grid>
            <Grid xs={12} container style={{ marginLeft: 15, justifyContent: 'space-between' }}>
              <Grid style={{ width: '90%' }}>
                <PrimaryTitle>End Of Day Report</PrimaryTitle>
                <SecondaryTitle>View sales record</SecondaryTitle>
              </Grid>
              <Grid style={{ width: '10%' }}>
                <CustomDatePicker
                  xs={12}
                  handleSubmit={handleSubmit}
                  startDate={startDate}
                />
              </Grid>
            </Grid>
          </Grid>

          <WrapperPaper container style={{ overflowX: 'auto' }}>
            <FilterContainer container alignItems="center">
              <ToggleBusiness />
              <SearchGrid item container justifyContent="space-between">
                <Grid item container style={{ width: '76vw' }}>
                  <CustomSearchField
                    name="search"
                    value={searchText}
                    fullWidth
                    placeholder="Search"
                    handleChange={(e) => setSearchText(e?.target?.value)}
                  />
                </Grid>
                <CustomButton
                  type="secondary"
                  style={{
                    width: '7rem', height: '2.95rem', position: 'relative',
                  }}
                  onClick={(e) => handleFilterButtonClick(e)}
                >
                  <FilterList style={{ marginRight: '.2rem' }} />
                  <FilterText>Filter by</FilterText>
                </CustomButton>
                <CustomExportButton
                  title="Export"
                  color="secondary"
                  fileName="eod_report"
                  name="EOD Report"
                  icon="icon"
                  variable={exportVariable}
                />
              </SearchGrid>
            </FilterContainer>
            <TableWrapper item container>
              {loading
                ? <ProductsTableLoader />
                : (
                  <TableContainer item container>
                    <TableColumnHeaders item container>
                      <TableHeader style={{ width: '250px' }}>
                        Date Created
                      </TableHeader>
                      <TableHeader style={{ width: '150px' }}>
                        Time Closed
                      </TableHeader>
                      <TableHeader style={{ width: '260px' }}>
                        Users
                      </TableHeader>
                      { selectedColumns.includes('Cash') && (
                        <>
                          <TableHeader style={{ width: '200px' }}>
                            Expected Cash
                          </TableHeader>
                          <TableHeader style={{ width: '200px' }}>
                            Actual Cash
                          </TableHeader>
                        </>
                      )}
                      { selectedColumns.includes('Card') && (
                        <>
                          <TableHeader style={{ width: '200px' }}>
                            Expected Card
                          </TableHeader>
                          <TableHeader style={{ width: '200px' }}>
                            Actual Card
                          </TableHeader>
                        </>
                      )}
                      { selectedColumns.includes('Bank Transfer') && (
                        <>
                          <TableHeader style={{ width: '250px' }}>
                            Expected Bank Transfer
                          </TableHeader>
                          <TableHeader style={{ width: '250px' }}>
                            Actual Bank Transfer
                          </TableHeader>
                        </>
                      )}
                      { selectedColumns.includes('Store Credit') && (
                        <TableHeader style={{ width: '200px' }}>
                          Store Credit
                        </TableHeader>
                      )}
                      { selectedColumns.includes('Sale Amount') && (
                        <>
                          <TableHeader style={{ width: '220px' }}>
                            Expected Sales Amount
                          </TableHeader>
                          <TableHeader style={{ width: '220px' }}>
                            Actual Sales Amount
                          </TableHeader>
                        </>
                      )}
                      { selectedColumns.includes('Footfall') && (
                        <TableHeader style={{ width: '150px' }}>
                          Footfall
                        </TableHeader>
                      )}
                      { selectedColumns.includes('Note') ? (
                        <TableHeader style={{ width: '150px' }}>
                          Action
                        </TableHeader>
                      ) : (
                        <TableHeader style={{ width: '200px' }} />
                      )}
                    </TableColumnHeaders>
                    <TableBody container>
                      {!eodReport.length ? (
                        <Grid>
                          <p>There are no reports to show.</p>
                        </Grid>
                      )
                        : (eodReport.map((report) => (
                          <ReturnReportRow
                            key={report.id}
                            report={report}
                            selectedColumns={selectedColumns}
                          />
                        )))}
                    </TableBody>
                  </TableContainer>
                )}
            </TableWrapper>
            <FooterGrid item container xs={12} justifyContent="flex-end">
              {eodReport?.length > 0 && (
                <TablePagination
                  total={eodReportTotalNumber}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            </FooterGrid>
          </WrapperPaper>

          <FilterPopper
            columns={tableColumns}
            filterButton={filterButton}
            handleOptionToggle={handleOptionToggle}
            handleTriggerButtonClick={handleFilterButtonClick}
            selectedOptionFilter={selectedOptionFilter}
          />
        </OrderGridContainer>
      </MainContainer>
    </MainContent>
  );
};

EODReport.propTypes = {
  history: PropTypes.instanceOf(Object),
};
EODReport.defaultProps = {
  history: {}
};
export default EODReport;
