import { styled } from '@mui/material/styles';
import {
  Grid, Paper, TextField, Typography
} from '@mui/material';

export const DetailCard = styled(Paper)`
  border-radius: 0;
  padding: 25px;
  margin-bottom: 20px;

  @media(min-width: 992px) {
    padding: 15px;

  }
`;
export const DetailCardHeaderText = styled('p')`
  font-weight: 600;
  font-size: 16px;
`;
export const DetailCardText = styled('p')`
  font-size: 16px;
  margin: 0;
`;
export const PaperCardGrid = styled(Grid)`
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
`;

export const MessageBoxContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
`;

export const MessageBoxTextField = styled(TextField)`
  background-color: #F0F0F0;
  height: 55px;

  label + .MuiInput-formControl {
    margin-top: 21px;
  }

  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
  }

  input, textarea {
    padding-left: 20px;
    background-color: #F0F0F0;
    color: #424242;
    font-size: 16px;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    height: 80px;

    label + .MuiInput-formControl {
      margin-top: 23px;
    }

    label {
      font-size: 1.6rem;
    }

    input, textarea {
      font-size: 2rem;
    }
  }
`;

export const FormLabel = styled(Typography)`
  color: #A3A3A3;
  font-size: 12px;
`;
