import { gql } from '@apollo/client';

// export const SC_DASHBOARD = gql`
//   query supplyChainDashboard {
//     supplyChainDashboard {
//       totalProductsInInventory
//       totalEnabledProducts
//       totalDisabledProducts
//       totalSoldOutProducts
//       totalOrderValueInHouse
//       totalOrderCountInHouse
//       totalOrderValueAffiliate
//       totalOrderCountAffiliate
//     }
//   }
// `;

export const SC_DASHBOARD = gql`
  query supplyChainDashboard(
    $dateFrom: String
    $dateTo: String
    $filterBy: String
  ) {
    supplyChainDashboard(
      dateFrom: $dateFrom
      dateTo: $dateTo
      filterBy: $filterBy
    ) {
      totalProductsInInventory
      totalEnabledProducts
      totalDisabledProducts
      totalSoldOutProducts
      totalOrderValueInHouse
      totalOrderCountInHouse
      totalOrderValueAffiliate
      totalOrderCountAffiliate
      totalCostOfGoodsGraph
      totalOrderValueAffiliateGraph
      totalOrderCountAffiliateGraph
    }
  }
`;
// query supplyChainDashboard($dateFrom: String, $dateTo: String, $filterBy: String) {
//   supplyChainDashboard(dateFrom: $dateFrom, dateTo: $dateTo, filterBy: $filterBy) {
//     totalProductsInInventory
//     totalEnabledProducts
//     totalDisabledProducts
//     totalSoldOutProducts
//     totalOrderValueInHouse
//     totalOrderCountInHouse
//     totalOrderValueAffiliate
//     totalOrderCountAffiliate
//     totalCostOfGoodsGraph {
//       month
//       year
//       total
//     }
//     totalOrderValueAffiliateGraph {
//       month
//       year
//       total
//     }
//     totalOrderCountAffiliateGraph {
//       month
//       year
//       total
//     }
//   }
// }
