import {
  Grid, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const RoleGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 10px 50px 30px;
  background-color:  #F3F9FF;
  min-height: 100vh;
`;
