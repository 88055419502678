import {
  Dialog, Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const CustomerDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  width: 40rem;
  padding: 0 1.5rem;
`;

export const DialogHeader = styled(Grid)`
  width: 42rem;
  padding: 1.5rem 0 .5rem;
  background: #fff;
`;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const DialogBody = styled(Grid)`
  margin: 1rem 0;
`;

export const TextGrid = styled(Grid)`
  margin-top: 1rem;
`;

export const Subheader = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
  color: #606060;
  line-height: 2rem;
  width: 100%;
`;

export const SmallText = styled(Typography)`
  font-size: 15px;
  color: #606060;
  width: 100%;
`;

export const DialogActions = styled(Grid)`
  justify-content: flex-end;
  padding: 1.5rem 0 2rem;
  background: #fff;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;
