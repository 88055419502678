import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import currencyFormatter from '../../../utils/currencyFormatter';

const ReturnRow = ({ row }) => {
  const navigate = useNavigate();

  const orderAmount = row?.orderproductSet.reduce(
    (previous, current) => previous + (current.quantity * current.unitCost), 0
  );

  const orderVolume = row?.orderproductSet.reduce(
    (previous, current) => previous + current.quantity, 0
  );

  const handleCardClick = (order) => {
    const detail = `/receive-manual-order/${order.id}`;
    navigate(detail);
  };

  const returnDate = ({ lastUpdated }) => moment(lastUpdated).format('DD/MM/YYYY HH:mm');

  return (
    <TableColumnRows style={{ cursor: 'pointer' }} onClick={() => handleCardClick(row)}>
      <TableColumnRowText>{returnDate(row)}</TableColumnRowText>
      <TableColumnRowText>{row?.supplier?.name}</TableColumnRowText>
      <TableColumnRowText>{row?.id}</TableColumnRowText>
      <TableColumnRowText>{`#${row?.order?.id}`}</TableColumnRowText>
      <TableColumnRowText>{orderVolume}</TableColumnRowText>
      <TableColumnRowText>{currencyFormatter(orderAmount)}</TableColumnRowText>
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

export default ReturnRow;
