export const checkDuplicateEntries = (batch, action) => {
  const { payload } = action;
  const newBatch = batch;
  if (batch.length < 1) {
    newBatch.push(payload);
  } else {
    for (let i = 0; i < batch.length; i++) {
      if (batch[i].id === payload.id) {
        newBatch[i] = payload;
      } else {
        newBatch.push(payload);
      }
    }
  }
  return newBatch;
};
