import {
  Grid, Typography, IconButton, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
    padding: 15px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 231px
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 2rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1.2rem;
    padding: 6px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #C5C5C5;
  margin: 1px;
`;
