import { TableCell, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDropDown } from '@mui/icons-material';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F4F5F6;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 4%;
    }
    &:nth-child(2), :nth-child(3), :nth-child(6), :nth-child(7) {
      width: 18%;
    }
    &:nth-child(4), :nth-child(5) {
      width: 12%;
    }
  }
`;

export const DownIcon = styled(ArrowDropDown)`
  height: 1.3rem;
  cursor: pointer;
`;
