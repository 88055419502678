import React, { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import OverviewCard from './overviewCard';
import {
  GridContainer, GridWrapper, InnerContainer, TableTitle
} from './dashboard.styles';
import TopSellingProduct from './topSellingProduct/topSellingProduct';
import { useStateValue } from '../../providers/stateProvider';

const AdminDashboard = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  },);

  const viewPort = useMediaQuery('(min-width:992px)');

  return (
    <MainContent style={{ backgroundColor: '#f7fafe' }}>
      <GridWrapper>
        <InnerContainer>
          <GridContainer container>
            {viewPort ? (
              <TableTitle>Overview</TableTitle>
            ) : ''}
            <OverviewCard />

          </GridContainer>
          <TopSellingProduct />
        </InnerContainer>
      </GridWrapper>
    </MainContent>
  );
};

export default AdminDashboard;
