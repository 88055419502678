import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import toast from 'react-hot-toast';
import { Checkbox, CircularProgress, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
  FilledCustomButton,
  OutlinedCustomButton
} from '../../customComponents/customButton';
import CustomInputBase from '../../customComponents/customInputBase';
import {
  CDialog,
  ButtonContainer,
  BatchDialogHeader,
  PackSize,
  ProductName,
  BatchDialogBody,
  ColumnHeader,
  BatchItem,
  AddIcon,
  TCustomButton,
  ColumnValue, CircularLoaderContainer
} from './batchDialog.styles';
import { CREATE_BATCH_MUTATION } from '../../../mutations/products';
import { JSONParse } from '../../../utils/json';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const BatchDialog = ({
  product,
  openDialog,
  handleCloseDialog,
  refetch,
  handleAddProductToReport,
  loading
}) => {
  const [state, setState] = useState({ batchNo: '', orderCost: '' });
  const [errors, setErrors] = useState({
    batchNoError: false,
    orderCostError: false
  });
  const [addingNewBatch, setAddingNewBatch] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const isSmall = useMediaQuery('(max-width: 991px)');

  const data = product?.erpProducts[0] || {};
  const { erpVersion, batches } = data;
  const { brandName, packSize } = erpVersion || {};
  const { batchNo, orderCost } = state;
  const endDate = moment().add(1, 'year');

  const handleAddBatches = () => {
    handleAddProductToReport(selectedBatches, data);
    setSelectedBatches([]);
    handleCloseDialog();
  };

  const handleBatchSelections = (batch) => {
    const newBatches = selectedBatches;
    const batchIndx = newBatches.findIndex((_b) => _b.id === batch.id);
    if (batchIndx !== -1) newBatches.splice(batchIndx, 1);
    else newBatches.push(batch);
    setSelectedBatches([...newBatches]);
  };

  const [createBatch, { loading: createLoading }] = useMutation(
    CREATE_BATCH_MUTATION
  );

  const createBatchHandler = (payload) => {
    createBatch({
      variables: { ...payload }
    })
      .then(
        ({
          data: {
            createBatch: { message }
          }
        }) => {
          toast.success(message);
          refetch();
        }
      )
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleCreateBatch = () => {
    if (batchNo === '') {
      setErrors({ ...errors, batchNoError: true });
      toast.error('Batch No. is required');
    } else if (orderCost === '' || Number(orderCost) <= 0) {
      setErrors({ ...errors, orderCostError: true });
      toast.error('Order Cost is required');
    } else {
      setAddingNewBatch(false);
      const { id: productId } = data;
      const payload = {
        batchNo,
        productId,
        unitCost: orderCost,
        supplierId: '',
        quantity: '1',
        expiryDate: endDate.format('YYYY-MM-DD'),
        allowDuplicate: true
      };
      createBatchHandler(payload);
      setState({ batchNo: '', orderCost: '' });
      setErrors({ batchNoError: false, orderCostError: false });
    }
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'batchNo':
        return setErrors({
          ...errors,
          [`${name}Error`]: !value.length
        });
      case 'orderCost':
        return setErrors({
          ...errors,
          [`${name}Error`]: !(value.length && Number(value) > 0)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const fields = [
    {
      type: 'text',
      name: 'batchNo',
      label: 'Batch #',
      value: batchNo,
      placeholder: 'Enter Batch No',
      onChange: handleChange,
      disabled: true,
      error: errors.batchNoError,
      elperText: 'Please add a value'
    },
    {
      type: 'number',
      name: 'orderCost',
      label: 'Order Cost',
      value: orderCost,
      placeholder: 'Enter Order Cost',
      onChange: handleChange,
      error: errors.orderCostError,
      helperText: 'Please add a value'
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName,
      label,
      type,
      placeholder,
      error,
      helperText
    } = field;
    const value = state[fieldName];
    return (
      <CustomInputBase
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={type}
        error={error || false}
        helperText={error && helperText}
        name={fieldName}
        placeholder={placeholder}
        onChange={handleChange}
        required
        cSize="lg"
        style={{ fontSize: '16px' }}
      />
    );
  };

  return (
    <CDialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={() => ({})}
      fullWidth
    >
      {loading || createLoading ? (
        <CircularLoaderContainer>
          <CircularProgress
            disableShrink
            size={18}
            thickness={4}
          />
        </CircularLoaderContainer>
      ) : (
        <>
          <BatchDialogHeader container item>
            <Grid container item xs={6} direction="column">
              <ProductName
                style={{
                  fontSize: isSmall ? '35px' : '14px',
                  marginBottom: isSmall ? '1.5rem' : '',
                  marginTop: isSmall ? '1rem' : ''
                }}
              >
                {brandName}
              </ProductName>
              <PackSize
                item
                style={{
                  fontSize: isSmall ? '25px' : '12px',
                  marginBottom: isSmall ? '1rem' : ''
                }}
              >
                {`Pack Size: ${packSize}`}
              </PackSize>
            </Grid>
            {!isSmall ? (
              <ButtonContainer item container xs={6} justifyContent="flex-end">
                <TCustomButton
                  type="secondary"
                  header
                  onClick={() => setAddingNewBatch(true)}
                >
                  <AddIcon />
                  Add batch
                </TCustomButton>
                <FilledCustomButton
                  type="tertiary"
                  header
                  style={{ width: '6rem', height: '3rem' }}
                  onClick={handleAddBatches}
                >
                  Continue
                </FilledCustomButton>
              </ButtonContainer>
            ) : (
              <ButtonContainer
                item
                container
                xs={12}
                justifyContent="space-between"
              >
                <TCustomButton
                  type="secondary"
                  header
                  onClick={() => setAddingNewBatch(true)}
                  style={{
                    width: '25rem',
                    marginTop: '1.3rem',
                    borderRadius: '10px',
                    fontSize: '30px',
                    fontWeight: '700',
                    height: '5rem'
                  }}
                >
                  <AddIcon />
                  Add batch
                </TCustomButton>
                <FilledCustomButton
                  type="tertiary"
                  header
                  style={{
                    width: '25rem',
                    height: '5rem',
                    marginTop: '1.3rem',
                    fontSize: '30px',
                    borderRadius: '10px'
                  }}
                  onClick={handleAddBatches}
                >
                  Continue
                </FilledCustomButton>
              </ButtonContainer>
            )}
          </BatchDialogHeader>
          <BatchDialogBody container item>
            {addingNewBatch ? (
              <BatchItem container spacing={3} style={{ padding: 0 }}>
                {fields.map((field) => (
                  <Grid item key={field?.name} xs={12}>
                    {returnTextField(field)}
                  </Grid>
                ))}
                <Grid item container justifyContent="flex-end">
                  <OutlinedCustomButton
                    type="secondary"
                    header
                    onClick={handleCreateBatch}
                    style={{
                      width: isSmall ? '60rem' : '7rem',
                      margin: isSmall ? '0 0 1rem 0' : '0',
                      height: isSmall ? '5rem' : '48px',
                      borderRadius: '10px',
                      fontSize: isSmall ? '35px' : '14px'
                    }}
                    disabled={createLoading}
                  >
                    Save Batch
                  </OutlinedCustomButton>
                </Grid>
              </BatchItem>
            ) : (
              ''
            )}
            {batches && batches.length ? (
              batches.map((batch) => (
                <BatchItem key={batch.id} container>
                  <Grid item xs={2} alignItems="center" justifyContent="center">
                    <Checkbox
                      onClick={() => handleBatchSelections(batch)}
                      name="terms"
                      color="primary"
                      checked={selectedBatches.some((_b) => _b.id === batch.id)}
                      style={{
                        color: '#235A91',
                        marginLeft: isSmall ? '1rem' : '',
                        marginTop: isSmall ? '1rem' : '',
                        transform: isSmall ? 'scale(2)' : ''
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ColumnHeader item>Batch #</ColumnHeader>
                    <ColumnValue item>{batch.batchNo}</ColumnValue>
                  </Grid>
                  <Grid item xs={4}>
                    <ColumnHeader item>Expiry date</ColumnHeader>
                    <ColumnValue item>
                      {batch.expiryDate
                        ? moment(batch.expiryDate).format('MM/DD/YYYY')
                        : '-- -- --'}
                    </ColumnValue>
                  </Grid>
                  <Grid item xs={3}>
                    <ColumnHeader item>Order cost</ColumnHeader>
                    <ColumnValue item>{batch.orderCost}</ColumnValue>
                  </Grid>
                </BatchItem>
              ))
            ) : (
              <Grid item container justifyContent="center">
                <ProductName>There are no batches for this product</ProductName>
              </Grid>
            )}
          </BatchDialogBody>
        </>
      )}
    </CDialog>
  );
};

BatchDialog.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleAddProductToReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired
};

// BatchDialog.defaultProps = {
//   setPid: () => '',
// };

export default BatchDialog;
