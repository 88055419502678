import {
  Grid, Typography, IconButton, Button, List, ListItem, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { KeyboardArrowRight } from '@mui/icons-material';
import {
  NoNotification, CogIcon, BellNotifyIcon, ArchiveIcon, Notification
} from '../../assets/svgs';

export const MainContainer = styled(Grid)`
  width: 33rem;

  @media(min-width: 992px) {
    width: 33rem;
  }
`;

export const IconWrapper = styled(Grid)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;

  &:hover {
    &> span {
      color: #000000;
    }
  }
`;

export const Tooltip = styled(Typography)`
  font-weight: 600;
  font-size: 8px;
  color: #ffffff;
`;

export const CogStyle = styled(CogIcon)`
  font-size: 18px;
`;

export const ArchiveStyled = styled(ArchiveIcon)`
  font-size: 18px;
  fill: none;
`;

export const BellNotifyIconStyled = styled(BellNotifyIcon)``;

export const ListN = styled('li')`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  list-style-type: none;
  width: 100%;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const BellBg = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(245, 245, 245, 1);
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  line-height: 2rem;
  cursor: pointer;
  border-radius: 50%;
  position: relative
`;

export const NotRead = styled('div')`
  position: absolute;
  top: 3px;
  right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FF0909;
`;

export const NotifyContainer = styled(Grid)`
  max-height: 25rem;
  overflow-y: scroll;
  padding: 0 1.5rem;
  margin-top: 1rem;
`;

export const NotifyTextContainer = styled('div')`
  width: inherit;
`;

export const NotifyTextHeader = styled('div')`
  font-weight: 700;
  font-size: 13px;
  color: #303030;
`;

export const NotifyTextBody = styled(Grid)`
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
  color: #606060;
`;

export const HeaderRight = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
  gap: .5rem;
`;

export const NotificationIcon = styled(NoNotification)`
  width: 110px;
  height: 109px;
  padding-bottom: 1rem;
`;

export const NNotificationBody = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0 4rem 0;
`;

export const Track = styled('div')`
  font-weight: 600;
  font-size: 10px;
  color: #303030;
  white-space: nowrap;
`;

export const HeaderTypo = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 500;
  color: #235A91;

  @media(min-width: 992px) {
    font-weight: 700;
    font-size: 18px;
  }
`;

export const DescTypo = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: .5rem
`;

export const TitleTypo = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: .75rem
  }
`;

export const BodyTypo = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.2;


  @media(min-width: 992px) {
    font-size: .75rem;
    background: red;
  }
`;

export const HeaderButton = styled(Button)`
  font-size: 1.6rem;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: .8rem;
  }
`;

export const HeaderWrapper = styled(Grid)`
  padding: .7rem 1.5rem;
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  align-items: center;
`;

export const FooterWrapper = styled(Grid)`
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
`;

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  font-size: 2rem;

  @media (min-width: 992px) {
    font-size: 1rem;
  }
`;

export const CList = styled(List)`
  width: 100%;
  padding: 0;
  padding-bottom: 8px;
`;

export const CListItem = styled(ListItem)`
  position: relative;

  &:hover {
    &> button {
      display: inline-flex;
    }
  }
`;

export const CListItemText = styled(ListItemText)`
  margin: 0;
`;

export const ButtonIcon = styled(IconButton)`
  display: none;
  position: absolute;
  top: 4px;
  right: 8px;
`;

export const CloseImage = styled('img')`
  width: 1.2rem;

  @media(min-width: 992px) {
    width: .7rem;
  }
`;

export const HeaderContainer = styled(Grid)`
  display: flex;
  flex-gap: 5px;
  justify-content: space-between;x

`;

export const TitleContainer = styled(Grid)`
  display: flex;
  flex-gap: 5px;
  justify-content: space-between;x

`;

export const NotificationImg = styled(Notification)`
&.MuiSvgIcon-root {
  width: 1rem;
  margin: 0 .4rem;
}
`;
