import {
  Grid, Typography, Button, TextField,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdminLogo } from '../../assets/svgs';

export const MainGridContainer = styled(Grid)`
`;
export const BusinessSetupGridContainer = styled(Grid)`
  height: 110vh;
  @media (min-width: 992px) {
  }
`;
export const InputsGridContainer = styled(BusinessSetupGridContainer)`
  flex-direction: column;
`;

export const Logo = styled('img')`
  width: 35%;
  margin: 0;

  @media (min-width: 992px) {
    width: 25%;
  }
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  // padding: .5rem 0 4rem;

  @media(min-width: 992px) {
    font-size: 2rem;
    // padding: .5rem 0;
    font-weight: 700;
    margin-bottom: 10px;
  }
`;

export const TopGrid = styled(Grid)`
    & > * {
      width: calc(50% - 0.75rem);
      margin: 0 1.5rem 1.5rem 0;

      :nth-child(2n) {
        margin-right: 0;
      }
    }
`;

export const MiddleGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
`;

export const BottomGrid = styled(Grid)`
    & > * {
      width: calc(33.33% - 1rem);
      margin: 0 1.5rem 1.5rem 0;

      :last-child {
        margin-right: 0;
      }
    }
`;

export const SupplierTextField = styled(TextField)`
`;

export const ContinueButton = styled(Button)`
  height: 80px;
  width: 20rem;
  border-radius: 10px;
  background-color: #FCED50;
  float: right;

  &:hover {
    background-color: #D2C647;
  }

  @media (min-width: 992px) {
    height: 50px;
    width: 180px;
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 26px;
  font-weight: 500;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #FFFFFF;
  margin-top: 4px;
`;

export const CheckboxContainer2 = styled(Grid)`
  padding: 0 0 2rem;
`;

export const AcknowledgeTypo = styled(Typography)`
  font-size: 1.28rem;

  @media (min-width: 992px) {
    font-size: 0.75rem;
  }
`;

export const ButtonGrid = styled(Grid)`
  justify-content: center;

  @media (min-width: 992px) {
    justify-content: flex-start;
  }
`;

export const UploadGrid = styled(Grid)`
  background-color: #E0E0E0;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const UploadMobileGrid = styled(Grid)`
  margin: 2rem 0 3rem;
`;

export const CreateButton = styled(Button)`
  background-color: #235A91;
  border-radius: 10px;
  margin-top: 30px;
  font-size: .9rem;
  color: #fff;
  height: 55px;

  &:hover {
    background-color: #235A91;
  }
`;

export const ContentBox = styled(Grid)`
  padding: 2rem 10rem;
  background-color: #FFFFFF;
  height: 100%;
}
`;

export const CustomField = styled(TextField)`
border-radius: 10px;


  & .MuiOutlinedInput-root {
    background-color: #FFFFFF;
    border-radius: 10px;
    border-color: #235A91;
    border: 0.5px solid  #235A91;

    &fieldset: {
      border-color: #235A91;
    },
    &:hover fieldset: {
      border-color: #235A91;
    },
    &.Mui-focused fieldse: {
      border-color: #235A91;
    },
  }
  & .MuiInputBase-input {
    height: 2rem;
  }
`;

export const LogoGridContainer = styled(Grid)`
  flex-direction: column;
  background-color: #ECF6FF;
  padding: 3rem;
  justify-content: center;
   align-items: center;


  @media (min-width: 992px) {
  }
`;

export const Img = styled('img')`
  width: 68%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 75%;
  }

  @media (min-width: 992px) {
    width: 80%;
    margin: 0;
  }
`;

export const TypoWrapper = styled(Grid)`
  text-align: center;
  margin: 3rem 2rem;

`;

export const LogoTypo = styled(Typography)`
  color: #235A91;
  font-size: 32px;
  font-weight: 700;
  line-height: 30px;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 992px) {
    font-size: 32px;
  }
`;
export const TypoDesc = styled(Typography)`
  color: #385F84;
  font-size: 24px;
  font-weight: 500;
  margin-top:  2rem;
  line-height: 20px;

  @media (min-width: 768px) {
    font-size: 22px;
  }

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const SubTypo = styled(Typography)`
  color: #385F84;
  font-weight: 500;
  font-size: 17px;
  display: flex;
  margin-bottom:  1.5rem;

  @media (min-width: 992px) {
    font-size: 18px;
  }
`;

export const OgaAdminLogo = styled(AdminLogo)`
  &.MuiSvgIcon-root {
    width: 65%;
    height: 15%;
  }

  @media (min-width: 1450px) {
    &.MuiSvgIcon-root {
      width: 51%;
      height: 15%;
    }
  }
`;

export const FieldWrapper = styled(Grid)`
  overflow-y: scroll;
  height: 62vh;

  ::-webkit-scrollbar {
    width: 5px;
    height: 90%;
  }

  ::-webkit-scrollbar-track {
    background-color: #ECF6FF;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    min-height: 10px;
    background-color: transparent;
    border-top: 60px solid #235A91;
  }
`;

export const InputWrapper = styled('div')`
width: 95%;
`;

export const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  margin-bottom: .2rem;
  @media(max-width: 991px) {
    font-size: 1.5rem;
  }
`;
