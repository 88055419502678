import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TabsGrid = styled(Grid)`
  padding: .5rem 0;
  align-items: center;
`;

export const TCustomButton = styled(Button)`
  font-weight: 700;
  font-size: 0.875rem;
  background-color: ${({ active }) => (active ? '#235A91' : 'none')};
  width: 10rem;
  height: 45px;
  margin-right: .5rem;
  border: ${({ active }) => (active ? 'none' : '1px solid #235A91')};
  border-radius: 8px;
  color: ${({ active }) => (active ? '#FFF' : '#235A91')};
  &:hover{
    background-color: ${({ active }) => (active ? '#235A91' : 'none')};
  }

  @media (max-width: 991px) {
    font-size: 2.2rem;
    width: 25rem;
    height: 90px;
    margin-top: 15px;
  }

`;
