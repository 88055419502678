import React from 'react';
import ContentLoader from 'react-content-loader';

const TableRow = (props) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader
      viewBox="0 0 1060 40"
      height={50}
      width={1418}
      speed={2}
      backgroundColor="#cccccc"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="13" rx="6" ry="6" width="20" height="18" />
      <rect x="34" y="13" rx="6" ry="6" width={400 * random} height="18" />
      <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="18" />
      <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="18" />
      <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="18" />

      <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>
  );
};

const Table = (props) => (
  <>
    {Array(10)
      .fill('')
      .map((e, i) => (
        <TableRow
          key={i}
          style={{ opacity: 1 }}
          {...props}
        />
      ))}
  </>
);

export default Table;
