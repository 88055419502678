import React, { useEffect, useState } from 'react';
import SideNav from '../shared/sideNav/sideNav';

import { useStateValue } from '../../../providers/stateProvider';
import Navigation from '../shared/navigation/navigation';
import MainContent from './subBusinesses';
import TitleGrid from './titleGrid';

import {
  OutletContentWrapper,
  TitleGridWrapper
} from './subBusinesses.styles';

export default function SubBusinesses() {
  const [, dispatch] = Object.values(useStateValue());
  const [key, setKey] = useState(0);
  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, []);

  return (
    <>
      <Navigation />

      <TitleGridWrapper>
        <TitleGrid setContentKey={setKey} />
      </TitleGridWrapper>

      <OutletContentWrapper>
        <SideNav />
        <MainContent key={key} />
      </OutletContentWrapper>
    </>
  );
}
