import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useMediaQuery, Grid } from '@mui/material';
import toast from 'react-hot-toast';
import StatusDropdown from './statusDropdown';
import currencyFormatter from '../../utils/currencyFormatter';
import { UPDATE_PRODUCT_BATCH_STATUS, DELETE_BATCH_MUTATION, UPDATE_BATCH_SHORTDATED_STATUS } from '../../mutations/products';
import ActionPopper from './actionPopper';
import ConfirmDialog from '../shared/confirmDialog';
import {
  TableColumnRowText, TableColumnRows, ColorGrid, DropDownArrow, StatusColor
} from './returnRow.styles';
import { Product } from '../../providers/reducers/product/classes/Product';
import { useStateValue } from '../../providers/stateProvider';
import dropdown from '../../assets/images/marketOrder/dropdown.png';

const ReturnRow = ({
  row, rowIndx, handleBatchEdit, refetch
}) => {
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const isLarge = useMediaQuery('(min-width:992px)');

  const navigate = useNavigate();

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const {
    id, dateCreated, batchNo, product, quantityInStock, status, supplier, orderCost,
    expiryDate, lastUpdatedField, lastUpdatedBy, lastUpdatedDate, previousInfo, currentInfo, expiryStatus, shortDated, canBeShortdated
  } = row;

  const datePlaced = moment(dateCreated).format('D MMM YYYY h:mm a');
  const lastUpdated = moment(lastUpdatedDate).format('DD MMM YYYY h:mm a');

  const [updateProductBatchStatus] = useMutation(UPDATE_PRODUCT_BATCH_STATUS);
  const [markProductBatch] = useMutation(UPDATE_BATCH_SHORTDATED_STATUS);
  const handleStatusChange = (_status) => {
    setDotsButtonEl(null);
    updateProductBatchStatus({
      variables: {
        batchId: id,
        status: _status,
      }
    }).then(({ data }) => {
      const { updateProductBatchStatus: { message } } = data ?? {};
      toast.success(message);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const [deleteBatchMutate, { loading }] = useMutation(DELETE_BATCH_MUTATION);

  if (confirmStatus) {
    deleteBatchMutate({
      variables: {
        batchId: id,
      }
    }).then(({ data }) => {
      const { deleteBatch: { message } } = data ?? {};
      toast.success(message);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
    setDotsButtonEl(null);
    setConfirmStatus(false);
  }

  const renderStatus = (_status) => (
    <Grid
      item
      container
      style={{
        cursor: 'pointer', pointerEvents: !userPermissions?.includes('batch_details_update_batch') ? 'none' : ''
      }}
      onClick={(e) => setDotsButtonEl(e.currentTarget)}
    >
      <StatusColor status={_status} />
      <ColorGrid>{_status}</ColorGrid>
      <DropDownArrow src={dropdown} alt="dropdown" />
    </Grid>
  );

  const getStatus = () => {
    switch (status) {
      case 'EXPIRED':
        return renderStatus('expired');
      case 'DAMAGED':
        return renderStatus('damaged');
      default:
        return renderStatus('sellable');
    }
  };
  const getExpiryStatus = (stat) => {
    switch (stat) {
      case '0_3_months':
        return '0 - 3 Months';
      case '3_7_months':
        return '3 - 7 Months';
      case 'expired':
        return 'Expired';
      default:
        return 'Normal';
    }
  };

  const cells = [
    { name: batchNo, width: '150px' }, { name: getExpiryStatus(expiryStatus), width: '150px' }, { name: currencyFormatter(orderCost), width: '130px' }, { name: moment(expiryDate).format('DD MMM YYYY'), width: '150px' }, { name: getStatus(), width: '170px' },
    { name: quantityInStock, width: '100px' }, { name: lastUpdatedField, width: '175px' }, { name: lastUpdatedBy, width: '150px' },
    { name: lastUpdated, width: '250px' }, { name: previousInfo, width: '230px' }, { name: currentInfo, width: '210px' },
  ];

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => cells.map(({ name, width }) => (
    <TableColumnRowText data-testid={`erp-uat-row-${name}`} style={{ width }} key={name}>{isLarge ? returnCell(name) : ''}</TableColumnRowText>
  ));

  const handleClick = () => {
    handleBatchEdit(row);
  };

  const options = [
    { name: 'sellable', disabled: status === 'SELLABLE' },
    { name: 'damaged', disabled: status === 'DAMAGED' },
    { name: 'expired', disabled: status === 'EXPIRED' }
  ];

  const handleShortDated = (setOpen) => {
    markProductBatch({
      variables: {
        batchId: id,
        shortDated: !shortDated,
      }
    }).then(({ data }) => {
      const { markBatchAsShortDated: { message } } = data ?? {};
      toast.success(message);
      setOpen(false);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };
  const handleAction = (type, setOpen) => {
    switch (type) {
      case 'view_batch_info':
        return navigate(`/batch-details-info/${id}`);
      case 'delete_batch':
        return setOpenConfirmDialog(true);
      case 'shortDated':
        return handleShortDated(setOpen);
      default:
        break;
    }
    setOpen(false);
  };

  return (
    <TableColumnRows>
      <TableColumnRowText style={{ width: '70px' }}>{rowIndx + 1}</TableColumnRowText>
      <TableColumnRowText style={{ width: '250px' }}>{datePlaced}</TableColumnRowText>
      <TableColumnRowText style={{ width: '250px' }}>
        {supplier?.name}
      </TableColumnRowText>
      <TableColumnRowText
        style={{
          cursor: 'pointer', width: '250px',
          pointerEvents: !userPermissions?.includes('batch_details_edit_batch') ? 'none' : ''
        }}
        onClick={handleClick}
      >
        {product?.brandName}
      </TableColumnRowText>
      {renderBodyCells()}
      <TableColumnRowText style={{ width: '220px', padding: '16px 20px' }}>
        <ActionPopper data-testid="action_popper" handleAction={handleAction} shortDated={shortDated} canBeShortdated={canBeShortdated} />
      </TableColumnRowText>

      <StatusDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
        action={handleStatusChange}
        rowStatus
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Delete Batch"
        desc="Are you sure you want to delete batch?"
        options={['Cancel', 'Yes, Delete']}
        setStatus={setConfirmStatus}
        loading={loading}
      />
    </TableColumnRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
  handleBatchEdit: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
