import {
  Typography, Box, TableCell, Grid, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentBox = styled(Box)`
  padding: 20px;

  @media (max-width: 991px) {
    padding: 30px;
  }
`;

export const PaymentText = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const HeaderCell = styled(TableCell)`
  background-color: #F4F7FC;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.5rem;
  padding: 10px;

  @media(max-width: 991px) {
    font-size: 1.4rem;
  }
`;

export const TableBox = styled(Box)`
  margin-top: 20px;
`;

export const BackgroundBox = styled(Box)`
  background: #fff;
`;

export const DetailBox = styled(Box)`
  padding: 20px;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;

  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const MobileContainer = styled(Box)`
  width: 100%;
`;

export const FilterBox = styled(Box)`
  margin-bottom: 1.5rem;
  text-align: right;
`;

export const FilterImg = styled('img')`
  height: 5rem;
  width: 5rem;
`;
