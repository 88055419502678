const returns = {
  allSoldItems: new Map(),
  returnCartItems: new Map(),
  selectAll: false,
  total: 0,
  returnSaleId: null,
  cart: new Map(),
  saleTotal: 0.00,
  subTotal: 0.00,
  totalCount: 0,
};

export default returns;
