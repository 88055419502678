import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputBase, Typography } from '@mui/material';

export const VisIcon = styled(Visibility)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const VisOffIcon = styled(VisibilityOff)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const Label = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  margin-bottom: .4rem;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const CInputBase = styled(InputBase)`
  border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #d8d8d8')};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : '')};
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #606060;
  line-height: 3.2;
  @media(max-width: 991px) {
    font-size: 1.5rem;
  }

  & .MuiInputBase-input {
    padding: ${({ cSize }) => (cSize === 'lg' ? '18px' : '12px')};
    padding-right: 5px;
    width: 80%;
  }

  & .MuiInputAdornment-root {
    margin-right: 12px;
    margin-left: 8px;
  }

  & .MuiInputAdornment-positionEnd {
    margin-right: 0;
    margin-left: 12px;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
        transform: translate(12px, 7px) scale(0.6);
      }
  & .MuiFormHelperText-marginDense {
    margin-top: 0;
    margin-bottom: 4px;
  }

  &:active, &:focus, &:focus-within {
    border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #303030')};
  }
`;
