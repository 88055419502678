import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell, MainTableRow,
} from './returnRow.styles';
import { JSONParse } from '../../../../utils/json';

const ReturnRow = ({
  row, index
}) => {
  const {
    batchNo, meta, dateCreated, order
  } = row;
  const { id } = order;

  const {
    sold_quantity: soldQuantity, previous_quantity: previousQuantity, quantity_recieved: qtyReceived, quantity_returned: qtyReturned
  } = JSONParse(meta);

  const date = moment(dateCreated).format('D MMM YYYY h:mm a');
  const orderId = `#${id}`;
  const batchId = `#${batchNo}`;
  const prevQty = previousQuantity || qtyReceived;
  const currentQty = soldQuantity || qtyReturned;
  const erpCells = [
    date, orderId, batchId, prevQty, currentQty
  ];

  const returnCell = (val) => val || 'N/A';

  const renderBodyCells = () => erpCells.map((cell) => (
    <BodyCell key={cell} className={`uat-order-logs-table-row-${index}`} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <MainTableRow>
      {renderBodyCells()}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
