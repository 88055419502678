import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

// therapeutic Type
export const THERAPEUTIC_TYPE_DETAIL = gql`
    query therapeuticType($id: Int!){
        therapeuticType(id: $id) {
            id
            therapeuticType
            therapeuticTypeProductCount
        }
    }
`;

export const THERAPEUTIC_TYPE_PRODUCTS_QUERY = gql`
    ${PRODUCT_VERSIONS}

  query therapeuticTypeProducts(
    $therapeuticTypeId: Int!
    $pageCount: Int
    $pageNumber: Int
    $productName: String
  ){
    therapeuticTypeProducts(
      therapeuticTypeId: $therapeuticTypeId
      pageCount: $pageCount
      pageNumber: $pageNumber
      productName: $productName
    ) {
        id
        brandName
        genericName
        meta
        ...productVersions
      }
      productsTotalNumber
  }
`;

export const FIND_THERAPEUTIC_TYPE_QUERY = gql`
  query allTherapeuticTypes(
    $pageCount: Int, $pageNumber: Int, $search: String
  ){
    allTherapeuticTypes(
      pageCount: $pageCount
      pageNumber: $pageNumber
      therapeuticType: $search
    ){
        id
        therapeuticType
        therapeuticTypeProductCount
    }
    productsTotalNumber
  }
`;

export const ALL_THERAPEUTIC_TYPES_QUERY = gql`
  query allTherapeuticTypes {
    allTherapeuticTypes {
      id
      therapeuticType
    }
  }
`;
