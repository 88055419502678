import React from 'react';
import MainContent from '../customComponents/mainContent';
import ReturnTable from './components/returnTable';

const Return = () => (
  <MainContent>
    <div style={{ marginTop: '10px', padding: '20px 40px' }}>
      <ReturnTable />
    </div>
  </MainContent>
);

export default Return;
