import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Proptypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import {
  BrowseFileButton,
  DropZoneContainer,
  TitleDesc,
  TitleText,
  theme, UploadIcon
} from './supplierCvsDropzone.styles';

const DropzoneContent = () => {
  const isSmall = useMediaQuery('(max-width:991px)');
  return (
    <DropZoneContainer>
      {/* <BackgroundImage src={UploadImage} /> */}
      <UploadIcon />
      <TitleText>
        Select file or drag and drop here
      </TitleText>
      <TitleDesc>
        .CSV file no more than 2MB
      </TitleDesc>
      <BrowseFileButton>
        {isSmall ? 'Select File' : 'SELECT FILE'}
      </BrowseFileButton>
    </DropZoneContainer>
  );
};

export default function SupplierCsvDropzone({
  handleFile, model
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DropzoneArea
          onChange={handleFile}
          acceptedFiles={['.csv']}
          dropzoneText={<DropzoneContent />}
          fileslimit={1}
          inputProps={{ 'data-testid': `${model}-upload-input` }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

SupplierCsvDropzone.propTypes = {
  handleFile: Proptypes.func.isRequired,
  model: Proptypes.string
};

SupplierCsvDropzone.defaultProps = {
  model: ''
};
