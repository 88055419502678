import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './filter.styles';

const Filter = ({
  state, handleSetFilter, handleSearch
}) => {
  const [status, setStatus] = useState('only_business_products');
  const { searchText } = state ?? '';

  const productStatuses = [
    {
      label: 'Enabled', name: 'status', value: 'ACTIVE', width: '10%'
    },
    {
      label: 'Disabled', name: 'status', value: 'IN_ACTIVE', width: '10%'
    },
    {
      label: 'My Business', name: 'productType', value: 'only_business_products', width: '15%'
    },
    // {
    //   label: 'Oga Pharmacy Products', name: 'productType', value: 'oga_pharmacy_market_product', width: '25%'
    // },
  ];

  const handleStatusClick = (name, value) => {
    setStatus(value);
    handleSetFilter(name, value);
  };

  return (
    <Grid item container xs={12}>
      <StatusesGrid container item>
        {productStatuses.map(({ label, name, value }) => (
          <UnderlineGrid
            className={`erp-uat-${name}`}
            key={value}
            item
            onClick={() => handleStatusClick(name, value)}
            active={value === status}
          >
            <Typo active={value === status}>{label}</Typo>
          </UnderlineGrid>
        ))}
        <UnderlineGrid width="40%" style={{ padding: '10.5px 0', cursor: 'auto' }}>
          <Typo />
        </UnderlineGrid>
      </StatusesGrid>
      <StatusesGrid container item style={{ padding: '0 1.5rem' }}>
        <CustomSearchField
          name="search"
          value={searchText}
          fullWidth
          placeholder="Search"
          handleChange={(e) => handleSearch(e?.target?.value)}
        />
      </StatusesGrid>
    </Grid>
  );
};

Filter.propTypes = {
  state: PropTypes.shape(Object),
  handleSetFilter: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  state: {}
};

export default Filter;
