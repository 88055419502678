import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getUser } from '../../../utils/utils';
import {
  BodyCell, MainTableRow, ColorGrid, CustomCheckbox, TCustomButton
} from './returnRowReceived.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';

const ReturnRow = ({
  row, rowIndx, selected, handleSelect
}) => {
  const navigate = useNavigate();
  const isSelected = () => selected.findIndex((x) => x?.id === row?.id) !== -1;
  const {
    id, dateCreated, receiptNumber, businessFrom, businessUser, status,
  } = row;
  const date = moment(dateCreated).format('DD MMM YYYY h:mm a');
  const { name: FromName } = businessFrom ?? {};
  const handler = getUser(businessUser?.user);

  const getStatus = () => {
    switch (status) {
      case 'CONFIRMED':
        return <ColorGrid item container status="confirmed">confirmed</ColorGrid>;
      case 'REJECTED':
        return <ColorGrid item container status="rejected">rejected</ColorGrid>;
      default:
        return <ColorGrid item container status="pending">pending</ColorGrid>;
    }
  };

  const cells = [FromName, handler];

  const returnCell = (value) => value || '-';

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const renderBodyCells = () => {
    const isLarge = useMediaQuery('(min-width:992px)');
    return cells.map((cell) => (
      <BodyCell>{isLarge ? returnCell(cell) : ''}</BodyCell>
    ));
  };

  return (
    <MainTableRow item container>
      <BodyCell>{renderCheckbox()}</BodyCell>
      <BodyCell>{rowIndx + 1}</BodyCell>
      <BodyCell>{date}</BodyCell>
      <BodyCell
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/received-transfers/${id}/details`)}
      >
        {`#${receiptNumber}`}
      </BodyCell>
      {renderBodyCells()}
      <BodyCell>{getStatus()}</BodyCell>
      <BodyCell>
        <TCustomButton
          onClick={() => navigate(`/received-transfers/${id}/details`)}
        >
          View Transfers
        </TCustomButton>
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  rowIndx: PropTypes.number.isRequired,
  selected: PropTypes.shape(Array),
  handleSelect: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  selected: []
};

export default ReturnRow;
