import React from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  NoResultsContainer,
  NoResultsHeader,
} from './newOrder.styles';
import ProductCard from './productCard';
import { TOP_SELLING_PRODUCTS } from '../../../../queries/products';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';

const NewOrder = () => {
  const variables = {
    pageCount: 20,
    pageNumber: 1,
  };

  const { loading, data } = useQuery(TOP_SELLING_PRODUCTS, {
    fetchPolicy: 'no-cache',
    variables
  });

  const { topSellingProducts: products = {} } = data || {};

  return loading
    ? <NewOrdersLoader />
    : (
      <Box>
        <ProductCardsContainer container spacing={3}>
          {products.length
            ? products.map((product, index) => (
              <ProductCardGrid key={product.id} item>
                <ProductCard
                  product={product}
                  index={index}
                />
              </ProductCardGrid>
            )) : (
              <NoResultsContainer>
                <NoResultsHeader>
                  Product Not Found!
                </NoResultsHeader>
                <NoResultsText>
                  Please we currently don’t have the product you searched for,
                  check out our recommended products below
                </NoResultsText>
              </NoResultsContainer>
            )}
        </ProductCardsContainer>
      </Box>
    );
};

export default NewOrder;
