import { useMutation } from '@apollo/client';
import {
  FormControl, FormControlLabel, Grid, Radio, RadioGroup
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import EditIcon from '../../../../assets/images/Edit Icon.png';
import { DELETE_SUB_BUSINESS_PRODUCT_VERSIONS } from '../../../../mutations/business';
import { useStateValue } from '../../../../providers/stateProvider';
import { OrderButtonText } from '../titleGrid.styles';
import {
  EditEmailButton, EditEmailButtonIcon, EditEmailButtonText,
  OrderCostText,
  PaperWrapper,
  RadioInfoGrid, SupportText, UpdatePreferenceButton
} from './businessSettingCard.styles';
import EmailPopper from './emailPopper';

const BusinessSettingCard = ({ preferences, setPreferences }) => {
  const [{ user: { role } },] = Object.values(useStateValue());
  const isChainManager = role === 'chain-manager';
  const [deleteSubBusinessProductVersions] = useMutation(DELETE_SUB_BUSINESS_PRODUCT_VERSIONS);
  const {
    orderCostFormat, orderEmailingPolicy, centralProcurementEmail, productPriceFormat,
  } = preferences;
  const [popperEl, setPopperEl] = useState(null);
  const [editEmail, setEditEmail] = useState(false);

  const handlePopper = (event) => {
    setPopperEl(popperEl ? null : event.currentTarget);
    if (popperEl && centralProcurementEmail) {
      setPreferences({ ...preferences, centralProcurementEmail });
    } else {
      setPreferences({ ...preferences, centralProcurementEmail: '' });
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value === 'central_procurement') {
      handlePopper(event);
    } else {
      setPreferences({ ...preferences, [name]: value });
    }
  };
  const EditEmail = (event) => {
    setPopperEl(popperEl ? null : event.currentTarget);
    setPreferences({
      ...preferences, orderEmailingPolicy: ''
    });
  };
  const handleAddedEmail = () => {
    if (preferences && preferences.centralProcurementEmail) {
      setPreferences({
        ...preferences, orderEmailingPolicy: 'central_procurement'
      });
      setPopperEl(null);
    }
    setEditEmail(true);
  };
  const handleRevertSubBusinessProductVersions = () => {
    deleteSubBusinessProductVersions().then(() => {
      toast.success('Sub Business product versions cleared successfully');
    });
  };

  return (
    <Grid
      justifyContent="flex-end"
      container
    >
      <PaperWrapper>
        <Grid>
          <OrderCostText>Order Cost</OrderCostText>
          <SupportText>
            Select how you you want the system to calculate your order cost, this value is
            used in the pricing formula alongside markup to calculate the selling price of a product
          </SupportText>
          <FormControl
            variant="standard"
            component="fieldset"
            style={{ margin: '.5rem 0 0 1.5rem' }}
          >
            <RadioGroup
              row
              aria-label="everything"
              defaultValue={orderCostFormat}
              name="orderCostFormat"
              value={orderCostFormat}
              onChange={handleChange}
            >
              <Grid container>
                <Grid sm={4}>
                  <FormControlLabel value="average_order_cost" control={<Radio />} label="Average order cost" />
                  <RadioInfoGrid>The average order cost of all batches added for a product</RadioInfoGrid>
                </Grid>
                <Grid sm={4}>
                  <FormControlLabel value="most_recent_order_cost" control={<Radio />} label="Most recent order cost" />
                  <RadioInfoGrid>The most recent order cost value for batches added or received for a product</RadioInfoGrid>
                </Grid>
                <Grid sm={4}>
                  <FormControlLabel value="highest_order_cost" control={<Radio />} label="Highest order cost" />
                  <RadioInfoGrid>The highest order cost value for all batches in stock for a product</RadioInfoGrid>
                </Grid>
                <Grid sm={4}>
                  <FormControlLabel value="use_current_order_cost" control={<Radio />} label="Order cost from CSV" />
                  <RadioInfoGrid>The last order cost from product csv upload or previous order cost settings</RadioInfoGrid>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid>
          <OrderCostText>Product Prices</OrderCostText>
          <SupportText>
            Select how you want PharmIQ to round up the selling price of products in your business
          </SupportText>
          <FormControl
            variant="standard"
            component="fieldset"
            style={{ margin: '.5rem 0 0 1.5rem' }}
          >
            <RadioGroup
              row
              aria-label="everything"
              defaultValue={productPriceFormat}
              name="productPriceFormat"
              value={productPriceFormat}
              onChange={handleChange}
            >
              <Grid container>
                <Grid sm={4}>
                  <FormControlLabel value="nearest_10" control={<Radio />} label="Nearest 10" />
                  <RadioInfoGrid>Round up the selling price of my products to the nearest 10</RadioInfoGrid>
                </Grid>
                <Grid sm={4}>
                  <FormControlLabel value="default" control={<Radio />} label="Default" />
                  <RadioInfoGrid>Don`&apos;`t round up, use exact auto-calculated or entered product price</RadioInfoGrid>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid style={{ marginTop: '1.5rem' }}>
          <OrderCostText>Supplier orders</OrderCostText>
          <SupportText>
            Specify here if emails are to be sent directly to suppliers or to central procurement.
          </SupportText>
          <FormControl
            variant="standard"
            component="fieldset"
            style={{ margin: '.5rem 0 0 1.5rem', width: '100%' }}
          >
            <RadioGroup
              row
              aria-label="order emailing policy"
              defaultValue={orderEmailingPolicy}
              name="orderEmailingPolicy"
              value={orderEmailingPolicy}
              onChange={handleChange}
            >
              <Grid container>
                <Grid item sm={4}>
                  <FormControlLabel value="suppliers" control={<Radio />} label="Suppliers" />
                  <RadioInfoGrid>If a given supplier has no email, you will be prompted to add one</RadioInfoGrid>
                </Grid>
                <Grid item sm={4}>
                  <FormControlLabel value="central_procurement" control={<Radio />} label="Central procurement" />
                  {!editEmail
                    ? <RadioInfoGrid>An email for central procurement must be added here if none</RadioInfoGrid>
                    : (
                      <Grid>
                        {centralProcurementEmail}
                        <EditEmailButton value="central_procurement" onClick={EditEmail}>
                          <EditEmailButtonIcon alt="cart icon" src={EditIcon} />
                          <EditEmailButtonText variant="subtitle1">
                            Edit
                          </EditEmailButtonText>
                        </EditEmailButton>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        <br />
        <br />
        { isChainManager && (
        <Grid
          style={{
            margin: '0.4rem', padding: 20, borderRadius: 5, border: '1px solid lightgrey'
          }}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid><h3>Sync Inventory Update</h3></Grid>
            <SupportText><h4>Click the button to apply properties of your products to your outlets</h4></SupportText>
          </Grid>
          <Grid item>
            <UpdatePreferenceButton onClick={() => handleRevertSubBusinessProductVersions()}>
              <OrderButtonText>
                Sync Inventory
              </OrderButtonText>
            </UpdatePreferenceButton>
          </Grid>
        </Grid>
        ) }
      </PaperWrapper>
      <EmailPopper
        popperEl={popperEl}
        email={centralProcurementEmail}
        handleChange={handleChange}
        handlePopper={handlePopper}
        handleAddedEmail={handleAddedEmail}
      />
    </Grid>
  );
};

BusinessSettingCard.propTypes = {
  preferences: PropTypes.instanceOf(Object).isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default BusinessSettingCard;
