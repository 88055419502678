export const JSONParse = (jsonString) => {
  const processedString = jsonString?.replace(/'|None/gi, (matched) => (matched === 'None' ? '""' : '"'));
  try {
    return JSON.parse(processedString);
  } catch (err) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return {};
    }
  }
};

export function reformatAddress(str) {
  const reform = str?.replace(/'(')+/g, (m,) => `'${m.substring(1, m.length - 1).replace(/'/g, '`')}'`)
    .replace(/'(')+\w/g, (m,) => `'${m.substring(1, m.length).replace(/'/g, '`')}`)
    .replace(/\w(')+/g, (m,) => `${m.substring(0, m.length - 1).replace(/'/g, '`')}'`)
    .replace(/\w(')+\w/g, (m,) => m.replace(/'/g, '`'));

  return JSONParse(reform);
}
