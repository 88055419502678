import React from 'react';
import { styled } from '@mui/material/styles';
import { Switch, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

const SwitchMethod = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 60,
  height: 30,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    marginRight: 1,
    transitionDuration: '300ms',
    color: '#C5E2FF',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#235A91',
      '& + .MuiSwitch-track': {
        backgroundColor: '#F3F9FF'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 25,
    height: 25,

  },
  '& .MuiSwitch-track': {
    border: '1px solid #78AADA',
    borderRadius: 32 / 2,
    backgroundColor: '#C5E2FF',
  }
}));

const SwitchToggle = ({ checkedState, setCheckedState }) => {
  const switchHandler = (event) => {
    setCheckedState(event.target.checked);
  };
  return (
    <FormControlLabel
      onChange={switchHandler}
      control={<SwitchMethod sx={{ m: 1 }} />}
      checked={checkedState}
      data-testid="switch-toggle"
    />
  );
};

SwitchToggle.propTypes = {
  checkedState: PropTypes.bool,
  setCheckedState: PropTypes.func.isRequired,
};

SwitchToggle.defaultProps = {
  checkedState: false,
};

export default SwitchToggle;
