import { useMutation } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import toast from 'react-hot-toast';
import sort from '../../../assets/images/marketOrder/sort.png';
import { ExportIcon } from '../../../assets/svgs';
import { EXPORT_MUTATION } from '../../../mutations/reports';
import { useStateValue } from '../../../providers/stateProvider';
import ReportExportModel from '../../../utils/reportExportModel';
import MainContent from '../../customComponents/mainContent';
import CustomSearchField from '../CustomSearchField';
import useSearchParamsState from '../helpers/ulrSearchParams';
import Model from './models';
import {
  ExportBox,
  ModelGridContainer,
  ModelNumberBox,
  ModelNumberText,
  ModelsNumber,
  ModelsTab,
  SortBox, SortImg, SortText, TypeTab
} from './models.styles';
import TitleGrid from './titleGrid';

function Models({
  findModelsQuery, modelName, modelTotalNumber, modelQueryName, extraModelQueryParam,
  renderModelCard, modelCardOnclickAction, renderIndividualModel, modelNamePlural,
  loop, renderTable, categoriesList, therapeuticTypes
}) {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    searchText: '',
    ascending: true,
    model: []
  };

  let endpoint;

  const [state, setState] = useState(initialState);

  const [openExportModel, setOpenExportModel] = useState(false);
  const [search, setSearch] = useSearchParamsState('search', '');

  const [
    {
      model: { modelsCount },
    }
  ] = Object.values(useStateValue());

  const {
    currentPage, searchText
  } = state;
  const [dotsButtonEl, setDotsButtonEl] = useState(false);

  const handleOpenExport = () => {
    setOpenExportModel(!openExportModel);
  };

  if (modelName === 'Supplier') {
    endpoint = 'suppliers';
  } else if (modelName === 'Customer') {
    endpoint = 'customers';
  } else if (modelName === 'Category') {
    endpoint = 'categories';
  } else if (modelName === 'Product type') {
    endpoint = 'therapeutic-types';
  }
  let fileName;
  if (modelName === 'Supplier') {
    fileName = 'supplier';
  } else if (modelName === 'Customer') {
    fileName = 'customer';
  } else if (modelName === 'Category') {
    fileName = 'category';
  } else if (modelName === 'Product type') {
    fileName = 'therapeutic-type';
  }

  useEffect(() => {
    if (search) {
      setState({ ...state, searchText: search });
    }
  }, [search]);

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, searchValue: searchText });
      setSearch(searchText);
    } else if (!searchText) {
      setState({ ...state, searchValue: '' });
      setSearch('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };
  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };
  const handleSort = () => {
    setState({ ...state, ascending: !state.ascending });
  };
  const [exportMutation] = useMutation(EXPORT_MUTATION);
  const randomNum = Math.floor(Math.random() * 10000);
  const NewfileName = `${endpoint} ${randomNum}`;

  const handleClick = async () => {
    exportMutation({
      variables: {
        type: endpoint,
        name: NewfileName,
        status: ''
        // ...variable
      }
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const [sortCategory, setSortCategory] = useState(false);
  const handleSorting = () => setSortCategory(true);

  const [reload, setReload] = useState(false);
  const reloadFunc = () => setReload(!reload);

  return (
    <>
      {therapeuticTypes ? (
        <Box>
          <TypeTab item container>
            <Grid container>
              <Grid item xs={8}>
                <ModelNumberText>
                  {`${modelsCount} ${modelsCount <= 1 ? modelName : `${modelNamePlural}`}`}
                </ModelNumberText>
              </Grid>
              <Grid item xs={4}>
                <ExportBox onClick={(e) => setDotsButtonEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
                  <ExportIcon />
                  <SortText>Export</SortText>
                </ExportBox>
              </Grid>
            </Grid>
          </TypeTab>

          <Grid container spacing={2} style={{ marginBottom: '30px' }}>
            <Grid item xs={11}>
              <CustomSearchField
                name="search"
                value={searchText}
                placeholder={`Search ${modelName.toLowerCase()}`}
                handleChange={(e) => handleSearch(e.target.value)}
                style={{ width: '100%' }}
                categoriesList
              />
            </Grid>
            <Grid item xs={1}>
              <SortBox onClick={handleSorting} style={{ cursor: 'pointer' }}>
                <SortImg src={sort} alt="sort" />
                <SortText>Sort</SortText>
              </SortBox>
            </Grid>
          </Grid>
          <ReportExportModel
            open={openExportModel}
            handleClose={handleOpenExport}
            endpoint={endpoint}
            params={extraModelQueryParam}
            dotsButtonEl={dotsButtonEl}
            setDotsButtonEl={setDotsButtonEl}
          />
          <Model
            loop={loop}
            state={state}
            handleChangePage={handleChangePage}
            page={currentPage}
            findModelsQuery={findModelsQuery}
            extraModelQueryParam={extraModelQueryParam}
            modelName={modelName}
            modelNamePlural={modelNamePlural}
            modelTotalNumber={modelTotalNumber}
            modelQueryName={modelQueryName}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSearch={handleSearch}
            renderModelCard={renderModelCard}
            renderTable={renderTable}
            modelCardOnclickAction={modelCardOnclickAction}
            categoriesList={categoriesList}
            sort={sortCategory}
          />
        </Box>
      )
        : (
          <MainContent>
            <ModelGridContainer>
              <TitleGrid
                modelNamePlural={modelNamePlural}
                modelName={modelName}
                renderIndividualModel={renderIndividualModel}
                endpoint={endpoint}
                fileName={fileName}
                reloadFunc={reloadFunc}
              />

              <ModelsTab>
                <ModelNumberBox>
                  <ModelsNumber>
                    {`${modelsCount} ${modelsCount <= 1 ? modelName : `${modelNamePlural}`}`}
                  </ModelsNumber>
                </ModelNumberBox>
                <CustomSearchField
                  name="search"
                  value={searchText}
                  placeholder={`Search ${modelName}`}
                  handleChange={(e) => handleSearch(e.target.value)}
                  style={{ width: '100%' }}
                  categoriesList={categoriesList}
                />
                <SortBox onClick={handleSort} style={{ cursor: 'pointer' }}>
                  <SortImg src={sort} alt="sort" />
                  <SortText>Sort</SortText>
                </SortBox>
                <ExportBox
                  onClick={handleClick}
                  style={{ cursor: 'pointer' }}
                >
                  <ExportIcon />
                  <SortText>Export</SortText>
                </ExportBox>
              </ModelsTab>
              <Model
                loop={loop}
                state={state}
                handleChangePage={handleChangePage}
                page={currentPage}
                findModelsQuery={findModelsQuery}
                extraModelQueryParam={extraModelQueryParam}
                modelName={modelName}
                modelNamePlural={modelNamePlural}
                modelTotalNumber={modelTotalNumber}
                modelQueryName={modelQueryName}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleSearch={handleSearch}
                renderModelCard={renderModelCard}
                renderTable={renderTable}
                modelCardOnclickAction={modelCardOnclickAction}
                categoriesList={categoriesList}
                reload={reload}
              />
            </ModelGridContainer>
          </MainContent>
        )}
    </>
  );
}
Models.propTypes = {
  findModelsQuery: PropTypes.instanceOf(Object).isRequired,
  extraModelQueryParam: PropTypes.instanceOf(Object),
  modelName: PropTypes.string.isRequired,
  modelTotalNumber: PropTypes.string.isRequired,
  modelQueryName: PropTypes.string.isRequired,
  renderModelCard: PropTypes.func.isRequired,
  renderTable: PropTypes.func.isRequired,
  modelCardOnclickAction: PropTypes.func.isRequired,
  renderIndividualModel: PropTypes.func.isRequired,
  modelNamePlural: PropTypes.string.isRequired,
  loop: PropTypes.bool.isRequired,
  categoriesList: PropTypes.bool,
  therapeuticTypes: PropTypes.bool
};
Models.defaultProps = {
  extraModelQueryParam: {},
  categoriesList: false,
  therapeuticTypes: false
};
export default Models;
