import {
  Grid, Typography, Button, LinearProgress, Link, Dialog, IconButton, DialogTitle,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { CameraIcon, CameraWhite, DeleteWhite } from '../../assets/svgs';

export const GridWrapper = styled(Grid)`
  flex-direction: column;
  margin: 0;
`;

export const Image = styled('img')`
  max-width: 100%;
  border-radius: 50%;
  height: 12rem;
  &:hover {
    cursor: pointer;
  }

  @media(max-width: 991px) {
    max-width: 100%;
    height: 25rem;
  }
`;

export const AffiliateName = styled(Typography)`
  margin-top: 10px;
  font-weight: 700;
  color: #303030;
  font-size: 22px;

  @media(max-width: 991px) {
    margin-top: 20px;
    font-size: 2.5rem;
  }
`;

export const PharmacyName = styled(Typography)`
  margin-top: 10px;
  font-weight: 400;
  font-size: 1rem;
  color: #303030;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const LicenceImage = styled('img')`
  max-width: 100%;
  margin-top: 15px;
`;

export const LogoWrapper = styled(Grid)`
  position: relative;
`;

export const LicenceWrapper = styled(Grid)`
  width: 90%;
  padding-top: 1rem;
  margin-left: 2rem;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;

  @media(max-width: 991px) {
    padding-top: 4rem;
  }
`;

export const LicenceHeader = styled(Typography)`
  font-size: 23px;
  font-weight: 500;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const ImageWrapper = styled(Grid)`
  padding: 0px 0rem 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EditButton = styled(Button)`
  height: 50px;
  width: 150px;
  padding: 0px 15px;
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 10px;
  margin: 20px 2px;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background-color: #000000;
  }
  @media(max-width: 991px) {
    font-size: 27px;
    height: 5rem;
    width: 14rem;
  }
`;

export const DocumentsButton = styled(Button)`
  height: 50px;
  width: 80%;
  padding: 15px;
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 10px;
  margin: 20px 2px;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background-color: #000000;
  }

  @media(max-width: 991px) {
    font-size: 27px;
    width: 80%;
    height: 68px;
  }
  &.MuiButton-root.Mui-disabled {
    color: #FFFFFF;
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const FileText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #676767;
  margin-top: 1rem;
`;

export const FileName = styled(Link)`
  color: #676767;
  &:hover {
  color: #676767;
    cursor: pointer;
  }
`;

export const UploadWrapper = styled(Grid)`
  position: relative;
  width: 80%;
  margin-top: 2rem;
`;

export const FileContainer = styled(Grid)`
  width: 80%;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
`;

export const FileWrapper = styled(Grid)`
  justify-content: space-between;
  border: 1px solid #11AF22;
  border-radius: 4px;
  padding: 12px 10px;
  margin-top: 1rem;
`;

export const FileUploading = styled(Grid)`
  justify-content: space-between;
  border: 0.5px solid #E3E3E3;
  border-radius: 4px;
  padding: 12px 10px;
  margin-top: 1rem;
`;
export const ImageContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Delete = styled('img')`
  font-size: 3.5rem;
  color: #A3A3A3;
  &:hover {
      cursor: pointer;
    }

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const ProgressWrapper = styled(LinearProgress)`
  color: #000000;
  position: relative;
`;

export const LinearProgressLoader = styled(LinearProgress)`
  margin-top: 4px;
  position: initial;
  & .MuiCircularProgress-svg {
    width: 1.6rem;
  }
  .MuiLinearProgress-bar1Indeterminate {
    background-color: #FFF;
    & .MuiLinearProgress-barColorPrimary {
      background-color: #FFF;
  }
  }
`;

export const Camera = styled(CameraIcon)`
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0;
  left: 44px;
  cursor: pointer;

  @media(max-width: 991px) {
    left: 100px;
  }


`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
  }
`;

export const DialogTitleContainer = styled(DialogTitle)`
  width: 100%;
  margin-top: 15px;
`;

export const DialogTitleWrapper = styled(Grid)`
  margin-top: 1px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #000000;
  font-size: 22px;
  @media(max-width: 991px) {
    font-size: 2.8rem;
  }
`;

export const DialogsubText = styled(Typography)`
  font-weight: 400;
  color: #606060;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
  @media(max-width: 991px) {
    font-size: 1.8rem;
  }
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;
export const DialogOkButton = styled(IconButton)`
  height: 45px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 40px;
  margin-top: 40px;
  margin-right: 30px;

  &:hover {
    background-color: #235A91;
  }

  @media(max-width: 991px) {
    height: 70px;
    width: 300px;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 45px;
  width: 170px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  color: #235A91;
  border-radius: 5px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  border: 1px solid #235A91;

  @media(max-width: 991px) {
    height: 70px;
    width: 300px;
  }
  `;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  display: flex;

  @media(max-width: 991px) {
    font-size: 27px;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
  @media(max-width: 991px) {
    width: 50px;
  }
`;

export const DeleteIcon = styled(DeleteWhite)`
  width: 15px;
  margin-right: 5px;

  @media(max-width: 991px) {
    width: 50px;
  }
`;

export const Camera2 = styled(CameraWhite)`
  width: 15px;
  margin-right: 5px;

  @media(max-width: 991px) {
    width: 50px;
  }
`;
