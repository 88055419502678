import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Grid, TableBody, TableContainer, Table, Badge
} from '@mui/material';
import toast from 'react-hot-toast';
import moment from 'moment';
import MainContent from '../customComponents/mainContent';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import { FilledCustomButton, OutlinedCustomButton } from '../customComponents/customButton';
import ReturnRow from './returnRow';
import TablePagination from '../shared/tablePagination';
import CustomSearchField from '../shared/CustomSearchField';
import ToggleBusiness from '../shared/busnesss';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import { BACK_ORDER_QUERY } from '../../queries/backOrders';
import { BACK_ORDER_CART_MUTATION } from '../../mutations/backOrder';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import { EXPORT_BACK_ORDERS_MUTATION } from '../../mutations/reports';
import {
  CartIconStyled,
  CartText,
  ExportText,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  PaperWrapper,
  TableGrid,
  TableHeader,
  MainTableHead,
  FooterWrapper,
  SearchGrid,
  CustomCheckbox,
  ExportIconStyled
} from './backOrder.styles';
import BackOrderCartActionTypes from '../../providers/reducers/backOrder/backOrderTypes';
import { useStateValue } from '../../providers/stateProvider';

const headers = [
  'Order ID',
  'Affiliate Name',
  'Product Name',
  'Qty Ordered',
  'Cost Price',
  'Selling Price',
  'Hybrid',
];

const BackOrder = () => {
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [stateRows, setStateRows] = useState([]);
  const startDate = moment().startOf('day');
  const endDate = moment().startOf('day');

  const [dateFilter, setDateFilter] = useState({
    dateFrom: startDate,
    dateTo: endDate
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom,
      dateTo
    });
  };

  const [
    { backOrder: { cartItems }, navbar: { openSideDrawer } },
    dispatch
  ] = Object.values(useStateValue());

  const { loading, error, data } = useQuery(BACK_ORDER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: searchText,
      pageCount,
      pageNumber,
      dateFrom: dateFilter?.dateFrom,
      dateTo: dateFilter?.dateTo
    }
  });
  const orders = data?.backOrders || [];
  const backOrdersTotalNumber = data?.backOrdersTotalNumber || 0;

  useEffect(() => {
    setStateRows(orders);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [backOrderCartMutation] = useMutation(BACK_ORDER_CART_MUTATION);

  const handleAddToCart = () => {
    if (cartItems.length < 1) return toast.error('Select at least an item');
    const selProducts = cartItems?.map((item) => ({
      orderId: item?.order?.id,
      productId: item?.product?.id,
      quantity: item?.quantityOrdered,
    }));
    backOrderCartMutation({
      variables: {
        products: selProducts
      }
    }).then(({ data: { addBackOrderProductToCart: { message } } }) => {
      dispatch({
        type: BackOrderCartActionTypes.UPDATE_CART,
        payload: { cartItems: [] }
      });
      dispatch({
        type: CartActionTypes.CART_BACK_ORDER_COUNTER,
        payload: selProducts?.length
      });
      return toast.success(message);
    }).catch((err) => toast.error(err?.message));
  };

  const handleSelectAll = (event) => {
    let newSelections = [];
    if (event.target.checked) {
      const newRows = stateRows.filter((x) => x?.quantityOrdered);
      newSelections = newRows;
    }
    dispatch({
      type: BackOrderCartActionTypes.UPDATE_CART,
      payload: { cartItems: newSelections }
    });
  };

  const handleSelect = (_, row) => {
    const selectedIndex = cartItems.findIndex((x) => x?.id === row?.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(cartItems, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(cartItems.slice(1));
    } else if (selectedIndex === cartItems.length - 1) {
      newSelected = newSelected.concat(cartItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        cartItems.slice(0, selectedIndex),
        cartItems.slice(selectedIndex + 1)
      );
    }
    dispatch({
      type: BackOrderCartActionTypes.UPDATE_CART,
      payload: { cartItems: newSelected }
    });
  };

  const renderCheckbox = () => {
    const newRows = stateRows.filter((x) => x?.quantityOrdered);
    return (
      <CustomCheckbox
        size="small"
        checked={cartItems.length === newRows.length}
        onChange={handleSelectAll}
        inputProps={{ 'aria-label': 'select product' }}
      />
    );
  };

  const [exportMutation] = useMutation(EXPORT_BACK_ORDERS_MUTATION);

  const randomNum = Math.floor(Math.random() * 10000);
  const newFileName = `back-order ${randomNum}`;

  const variables = {
    type: 'back_orders',
    name: newFileName,
    dateFrom: moment(dateFilter?.dateFrom),
    dateTo: moment(dateFilter?.dateTo),
    search: searchText,
    status: ''
  };

  const handleExport = async () => {
    exportMutation({
      variables
    })
      .then((res) => {
        const { message } = res?.data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  if (error) return error.message;

  const returnHeaders = () => headers.map((header) => <TableHeader key={header}>{header}</TableHeader>);

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Back Order</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <SearchGrid item container spacing={1}>
            <Grid item container xs={openSideDrawer ? 6 : 7}>
              <CustomSearchField
                name="search"
                value={searchText}
                placeholder="Search For Affiliates"
                handleChange={(e) => setSearchText(e?.target?.value)}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid container item xs={openSideDrawer ? 6 : 5} spacing={1}>
              <Grid item xs={5}>
                <CustomDateRanges
                  handleSubmit={handleSubmit}
                  xs={12}
                  styles={{
                    height: '3rem', width: '14.3rem', fontSize: '11px', border: '1px solid #bababa'
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Badge
                  badgeContent={cartItems.length}
                  sx={{
                    width: '100%',
                    '& .MuiBadge-badge': {
                      color: 'white',
                      backgroundColor: 'red'
                    }
                  }}
                >
                  <FilledCustomButton style={{ width: '100%' }} onClick={handleAddToCart}>
                    <CartIconStyled />
                    <CartText>Add To Cart</CartText>
                  </FilledCustomButton>
                </Badge>
              </Grid>
              <Grid item xs={3}>
                <OutlinedCustomButton style={{ width: '100%' }} onClick={handleExport}>
                  <ExportIconStyled />
                  <ExportText>Export</ExportText>
                </OutlinedCustomButton>
              </Grid>
            </Grid>
          </SearchGrid>
          <TableGrid item container>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableContainer>
                <Table>
                  <MainTableHead>
                    <TableHeader>{renderCheckbox()}</TableHeader>
                    {returnHeaders()}
                  </MainTableHead>
                  <TableBody>
                    {orders.map((_row) => (
                      <ReturnRow
                        key={_row?.id}
                        row={_row}
                        selected={cartItems}
                        handleSelect={handleSelect}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TableGrid>
          <ToggleBusiness />
          <FooterWrapper item container>
            {backOrdersTotalNumber > 0 && (
              <TablePagination
                total={backOrdersTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default BackOrder;
