import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';
import toast from 'react-hot-toast';
import { useQuery, useMutation } from '@apollo/client';
import { DELETE_USER, GENERATE_TOKEN_MUTATION } from '../../mutations/auth';
import CustomButton from '../customComponents/customButton';
import PasswordTextField from './passwordTextField';
import {
  HaveAccountTypo, HaveAccount, CustomField, InputWrapper, Label
} from './register.styles';
import {
  validateEmail, validateMobile, validatePassword
} from './utils';
import TermsAndConditionsPopUp from './alerts/TermsAndConditionsPopUp';
import { USERINFOSTATUS } from '../../queries/businesses';
import ConfirmDialog from '../shared/confirmDialog';
import { OgaToken } from '../../graphql/token';

const Register = () => {
  const initialState = {
    email: '',
    mobile: '',
    password: '',
    validated: false,
    loading: false,
    showPassword: false,
    accountStatus: ''
  };
  const initialErrorState = {
    emailError: false,
    mobileError: false,
    passwordError: false,
  };

  const [state, setState] = useState(initialState);
  const [terms, setTerms] = useState(true);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deleteUser] = useMutation(DELETE_USER);
  const [generateUserToken] = useMutation(GENERATE_TOKEN_MUTATION);

  const navigate = useNavigate();

  const {
    email, mobile, password, validated, loading, accountStatus
  } = state;
  const { emailError, passwordError, mobileError } = errorState;

  const validateState = (name, value) => {
    switch (name) {
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: validateMobile(value)
        });
      case 'password':
        return setErrorState({
          ...errorState,
          passwordError: validatePassword(value)
        });
      default:
        return true;
    }
  };

  React.useEffect(() => {
    setState({
      ...state,
      validated: !emailError && !passwordError && !mobileError
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, mobile]);

  const {
    data, refetch
  } = useQuery(USERINFOSTATUS, {
    variables: { email, password },
    fetchPolicy: 'no-cache',
  });

  const handleDelete = () => {
    deleteUser({
      variables: {
        email,
      }
    }).then((resp) => {
      const {
        message
      } = resp.data?.deleteUser ?? '';
      refetch();
      toast.success(message);
      return setOpenModal(false);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleDeleteOldAffiliate = () => {
    generateUserToken({
      variables: {
        userEmail: email,
      }
    }).then((resp) => {
      const {
        token, restToken
      } = resp.data?.generateUserToken ?? '';
      localStorage.clear()
      localStorage.setItem('oga_auth_token', token);
      OgaToken.authToken = token;
      localStorage.setItem('oga_rest_token', restToken);
      handleDelete();
    }).catch((err) => {
      toast.error(err?.message);
    })
      .finally(() => {
        localStorage.removeItem('oga_auth_token');
        localStorage.removeItem('oga_rest_token');
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const handleMobileChange = (event) => {
    const { value } = event.target;
    setState({ ...state, mobile: value });
  };

  useEffect(() => {
    if (data && data.userInfoAndStatus) {
      const { message } = data.userInfoAndStatus;
      setState({ ...state, accountStatus: message });
    }
  }, [data, state]);

  const handleProceed = () => navigate('/business-setup', {
    state: {
      email, mobile, password, accountStatus
    }
  });

  const handleSignup = () => {
    if (accountStatus === 'USER REGISTRATION NOT COMPLETE') {
      return setOpenModal(true);
    }
    if (accountStatus === 'USER DOES NOT EXIST') {
      return navigate('/business-setup', {
        state: {
          email, mobile, password, accountStatus
        }
      });
    }
    if (accountStatus === 'USER REGISTRATION COMPLETE') {
      return toast.error('Account already exist');
    }
    return toast.error(accountStatus);
  };
  const isLarge = useMediaQuery('(min-width: 992px)');

  return (
    <>
      <InputWrapper item container direction="column">
        <Grid item container justifyContent="space-between">
          <Label error={email ? emailError : false}>
            Email
            {' '}
            <span style={{ color: 'red' }}>*</span>
          </Label>
        </Grid>
        <CustomField
          variant="outlined"
          name="email"
          value={email}
          size={isLarge ? 'small' : 'medium'}
          type="email"
          required
          fullWidth
          error={email ? emailError : false}
          helperText={emailError ? 'Invalid email' : ' '}
          onChange={handleChange}
          InputProps={{ disableUnderline: true }}
          className="uat-register-email"
        />
      </InputWrapper>
      <InputWrapper item container direction="column">
        <Grid item container justifyContent="space-between">
          <Label error={email ? emailError : false}>
            Mobile
            {' '}
            <span style={{ color: 'red' }}>*</span>
          </Label>
        </Grid>
        <CustomField
          variant="outlined"
          name="mobie"
          value={mobile}
          size={isLarge ? 'small' : 'medium'}
          type="text"
          required
          fullWidth
          error={mobile ? mobileError : false}
          helperText={mobileError ? 'Invalid mobile number' : ' '}
          onChange={handleMobileChange}
          InputProps={{ disableUnderline: true }}
          className="uat-register-mobile"
        />
      </InputWrapper>
      <PasswordTextField
        password={password}
        handleChange={handleChange}
        passwordError={passwordError}
        loading={loading}
      />
      <CustomButton
        disabled={!validated}
        onClick={() => setOpen(true)}
        style={{ width: '100%', height: isLarge ? '3.5rem' : '5.5rem', fontSize: isLarge ? '' : '2.5rem' }}
      >
        Signup
      </CustomButton>
      <HaveAccount item xs={12}>
        <HaveAccountTypo variant="subtitle2" display="inline">
          Already have an account?
          &nbsp;
          &nbsp;
          <Link to="/" style={{ color: '#235A91', textDecoration: 'underline' }}>Sign In</Link>
        </HaveAccountTypo>
      </HaveAccount>

      <TermsAndConditionsPopUp
        handleContinue={() => handleSignup()}
        open={open}
        setOpen={setOpen}
        terms={terms}
        setTerms={setTerms}
      />

      <ConfirmDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        title="Registration Not Completed!"
        desc="Would you like to continue?"
        options={['Cancel', 'Yes, Continue']}
        buttonAction={handleProceed}
        handleDelete={handleDeleteOldAffiliate}
      />
    </>
  );
};

export default Register;
