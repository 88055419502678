import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, Paper, TableBody } from '@mui/material';
// import {
//   CardGridContainer, CardHeader, HeaderText,
//   HeaderIcon, CardContentGrid, ContentHeader,
//   ContentMeta, FooterMeta
// } from './customerCard.styles';
import {
  BodyCell, TableContainerWrapper, TableComponent,
  HeaderCell, MainTableHead, MainTableRow
} from '../categories/categoryCard.styles';
// import { JSONParse } from '../../utils/json';
import { renderPoints } from '../../utils/utils';

export default function CustomerCard({
  modelData // id, customer
}) {
  // const { name, loyaltyPoint } = customer;
  // const meta = JSONParse(customer.meta || {});
  const padLetter = (value, minLength) => (`${value}`.length < minLength ? `${new Array(minLength - (`${value}`).length).fill(0).join('')}${value}` : value);
  return (
    <TableContainerWrapper component={Paper}>
      <TableComponent aria-label="supplier products table">
        <MainTableHead>
          <TableRow>
            <HeaderCell>Name</HeaderCell>
            <HeaderCell>Loyalty Point</HeaderCell>
            <HeaderCell>PAD Letter</HeaderCell>
          </TableRow>
        </MainTableHead>

        <TableBody>
          { modelData.map((model) => (
            <MainTableRow>
              <BodyCell>
                {model.name}
              </BodyCell>
              <BodyCell>
                {renderPoints(model.loyaltyPoint)}
              </BodyCell>
              <BodyCell>
                {`${padLetter(model.id, 6)}`}
              </BodyCell>
            </MainTableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainerWrapper>
  // <CardGridContainer
  //   elevation={2}
  //   item
  //   container
  // >
  //   <CardHeader container item>
  //     <HeaderIcon src={meta.photo} alt="supplier logo" />
  //     <HeaderText>
  //       {`${padLetter(id, 6)}`}
  //     </HeaderText>
  //   </CardHeader>
  //   <CardContentGrid>
  //     <ContentHeader>
  //       {name}
  //     </ContentHeader>
  //     <FooterMeta>
  //       <ContentMeta>
  //         {renderPoints(loyaltyPoint)}
  //         {' '}
  //         Loyalty point
  //         {`${loyaltyPoint === 1 ? '' : 's'}`}
  //       </ContentMeta>
  //     </FooterMeta>
  //   </CardContentGrid>
  // </CardGridContainer>
  );
}

CustomerCard.propTypes = {
  modelData: PropTypes.instanceOf(Array).isRequired
  // id: PropTypes.string.isRequired,
  // customer: PropTypes.instanceOf(Object).isRequired,
};
