import React, {
  useContext, useReducer, useLayoutEffect, createContext
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import mainReducer from './mainReducer';
import initialState from './initialState';
import { GET_USER_INFO } from '../queries/user';
import { updateUserSession } from './reducers/user/userUtils';

export const StateContext = createContext();

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const token = localStorage.getItem('oga_auth_token');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // This is the only query functionality that should live in this file please!
  const [getUserInfo, { loading, data }] = useLazyQuery(GET_USER_INFO);
  useLayoutEffect(() => {
    getUserInfo({
      fetchPolicy: 'network-only',
      onError: () => {
        switch (true) {
          case pathname === '/register':
          case pathname === '/forgot-password':
          case /^\/reset_password/.test(pathname):
          case pathname === '/business-setup':
            break;
          default: navigate('/');
            break;
        }
      }
    });
  }, [token, getUserInfo, pathname, navigate]);

  const userData = loading || !data?.userInfo ? null : data;
  const sessionData = updateUserSession(userData);
  const stateWithSession = {
    ...state,
    user: { ...state.user, ...sessionData }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = { state: stateWithSession, dispatch };
  return (
    <StateContext.Provider value={values}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);

StateProvider.propTypes = {
  children: PropTypes.instanceOf(Object)
};

StateProvider.defaultProps = {
  children: {}
};

export default StateProvider;
