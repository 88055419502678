import {
  List, ListItem, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CList = styled(List)`
  width: 100%;
  padding-bottom: 15px;
`;

export const CListItem = styled(ListItem)`
  padding-left: 1.5rem;

  &:hover {
    background-color: #F3F9FF;
  }
`;

export const CListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-size: 16px;
    font-weight: 400;
    color: #606060;
    text-transform: capitalize;
  }
`;
