import React from 'react';
import {
  Grid, Typography, IconButton, Checkbox, Paper,
  Table, TableHead, TableCell
} from '@mui/material';
import {
  KeyboardArrowLeft, KeyboardArrowRight,
} from '@mui/icons-material';

import styled, { css } from 'styled-components';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const MainContainer = styled(Grid)`
  background: #F0F0F0;
`;

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 32px;
`;

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
  }
`;

export const StatisticsGridWrapper = styled(Grid)`
  overflow: hidden;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-top: 3rem;
    margin-bottom:2rem;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  transform: translateX(0px);
  transition: 500ms;
`;

export const OrdersTab = styled(Grid)`
  justify-content: space-between;
  border-top: 2px solid #CCCCCC;
  border-bottom: 2px solid #CCCCCC;

  @media(min-width: 992px) {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
`;

export const ActiveOrderNumber = styled(Typography)`
  font-size: 13x;
  color: #424242;
  font-weight: 600;
  padding: 20px 0px;

  @media(min-width: 992px) {
    font-size: 11px;
    padding: 10px 0px;
  }
`;

const getStyles = ({ show }) => {
  if (show) {
    return css`
      display: inline-flex;
    `;
  }
  return css`
    display: none;
  `;
};

export const ArrowButton = styled(({ ...otherProps }) => (
  <IconButton {...otherProps} />
))`
  padding: 0;
  margin-top: 14px;

  @media(min-width: 992px) {
    ${getStyles}
  }
`;

export const ArrowLeftIcon = styled(KeyboardArrowLeft)`
  cursor: pointer;
  font-size: 3rem;

  @media(min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  cursor: pointer;
  font-size: 3rem;

  @media(min-width: 992px) {
    font-size: 1.9rem;
  }
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 30px;

  @media(max-width: ${sm}) {
    margin-bottom: 10px;
  }
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const HeaderCell = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.5rem;
  padding-left: 0px;
  border-bottom: 2px solid rgba(224, 224, 224, 1);

  &:nth-child(1) {
    width: 14%;
    padding-left: 32px;
  }
  &:nth-child(2) {
    width: 14%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 22%;
  }
  &:nth-child(5) {
    width: 13%;
    text-align: center;
  }
  &:nth-child(6) {
    width: 13%;
    text-align: center;
  }
  &:nth-child(7) {
    width: 14%;
    text-align: center;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin: 10px 30px 0;
`;

export const RRPText = styled(Typography)`
  font-size: 18px;
  color: #4D4F5C;
  margin-top: 5px;

  @media(min-width: 992px) {
    font-size: 15px;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #CCCCCC;
`;

export const TableWrapper = styled('div')`
  padding-bottom: 5px;
  align-items: center;

  @media(min-width: 992px) {
    margin-left: 1.2rem;
  }
`;

export const TableContainer = styled(Paper)`
  border: 1px solid #eee;
`;

export const TableHeaderGrid = styled(Grid)`
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  margin-bottom: 1rem;
`;

export const TableHeaderTitle = styled('p')`
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 23px;
  white-space: pre-wrap;
  margin: 0;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;
