const navbarReducer = (navbar, action) => {
  switch (action.type) {
    case 'hideNavbar':
      return {
        ...navbar,
        isNavbarHidden: action.payload
      };
    case 'openSideDrawer':
      return {
        ...navbar,
        openSideDrawer: !navbar.openSideDrawer
      };
    default:
      return navbar;
  }
};

export default navbarReducer;
