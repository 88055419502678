import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import {
  CardPaper, ProductImg, ImgBox, DetailsBox, HeaderContainer, DetailsContainer, PromoPriceText,
  HeaderText, TypoContainer, PackSizeTypo, PricingAndSaving, PricingWrapper, Pricing,
  PromoContainer, PromoText, ChangeWrapper, CardWrapper
} from './productCard.styles';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { Product } from '../../../../providers/reducers/product/classes/Product';

const ProductCard = ({
  product, index,
}) => {
  const {
    brandName,
    resolvedPriceInUseValue: actualPrice, packSize, image,
    mktPrice, promoValue, promoCondition
  } = new Product(product, 'marketplace');

  const itemPrice = actualPrice;
  const promoStatus = promoCondition === 'ONGOING';

  return (
    <CardPaper
      elevation={2}
      spacing={2}
      className={`mpFe-uat-new-order-card-${index}`}
    >
      <CardWrapper>
        {(promoStatus) ? (
          <PromoContainer>
            <PromoText>
              {`${promoValue}% OFF`}
            </PromoText>
          </PromoContainer>
        ) : ''}
        <ImgBox>
          <ProductImg component="img" src={image} alt={brandName} />
        </ImgBox>
        <DetailsBox>
          <DetailsContainer>
            <Box>
              <HeaderContainer>
                <HeaderText variant="subtitle2">
                  {brandName}
                </HeaderText>
              </HeaderContainer>
              <TypoContainer item container xs={12}>
                <ChangeWrapper container item xs={12}>
                  <PackSizeTypo item xs={6} variant="body1">
                    Pack Size:
                    {' '}
                    {packSize}
                  </PackSizeTypo>
                </ChangeWrapper>
                <PricingAndSaving item container justify-content="space-between">
                  <PricingWrapper item xs={6} md={6}>
                    <Pricing>
                      {currencyFormatter(mktPrice)}
                    </Pricing>
                  </PricingWrapper>
                  <Grid item container xs={6} md={6}>
                    {(promoStatus) ? (
                      <PromoPriceText>
                        {currencyFormatter(itemPrice)}
                      </PromoPriceText>
                    ) : ''}
                  </Grid>
                </PricingAndSaving>
              </TypoContainer>
            </Box>
          </DetailsContainer>
        </DetailsBox>

      </CardWrapper>
    </CardPaper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
};

ProductCard.defaultProps = {
  product: {},
};

export default ProductCard;
