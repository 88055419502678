import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import {
  InputAdornment, Slide, Dialog, DialogActions, DialogTitle
} from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader, GridWrapper
} from '../../suppliers/individual/individualSupplierDialog.styles';
import { KeyboardArrowLeft } from '../../cart/cart.styles';

import TabPanel from '../../shared/tabPanel';

import { CUSTOMER_QUERY } from '../../../queries/customers';
import ModelActionTypes from '../../../providers/reducers/model/modelTypes';
import CustomerSales from './customerSales';
import CustomerHeader from './customerHeader';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import EditIcon from '../../../assets/images/Edit Icon.png';
import SearchIcon from '../../../assets/images/Search Icon.png';
import DeleteIcon from '../../../assets/images/Delete.png';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderSubHeader, TransactinHistoryContainer, ViewCartButton, ViewCartButtonText,
  TextWrapper, HeaderIcon, MenuButton, SearchImage, SearchContainer, SearchTextField,
  SearchButton, CustomerContainer, DeleteCartButton
} from './customerDetailsContainer.styles';

import IndividualCustomerDialog from '../individualCustomerDialog';

import { DELETE_CUSTOMER_MUTATION } from '../../../mutations/customers';

import { parseCustomerFields } from '../../shared/utils';

import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CustomerDetailsContainer = () => {
  const initialState = {
    searchText: '',
    currentPage: 1,
    currentPageCount: 20,
    addCustomerDialogOpen: false
  };

  const initialAlertState = {
    message: '',
    showAlert: false,
    type: ''
  };

  const [alertState, setAlertState] = useState(initialAlertState);

  const [state, setState] = useState(initialState);

  const [value, setValue] = useState(0);

  const [openDialogue, setOpenDialogue] = useState(false);

  const {
    searchText, currentPage
  } = state;
  const { id } = useParams();
  const navigate = useNavigate();

  const [, dispatch] = Object.values(useStateValue());
  const localBusinessId = localStorage.getItem('ogarx_business_id');
  const role = localStorage.getItem('oga_user_role');

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER_MUTATION);

  const openCustomerEditDialog = (customer) => {
    setState({ ...state, addCustomerDialogOpen: true });
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: {
        model: customer,
        addModelDialogOpen: state.addCustomerDialogOpen
      }
    });
  };

  const updateCustomerState = (refetch) => {
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      customerDetailRefetch: refetch,
      addModelDialogOpen: false
    });
  };

  const toggleCustomerEditDialog = () => {
    setState({ ...state, addCustomerDialogOpen: false });
    dispatch({ type: ModelActionTypes.UPDATE_MODEL, payload: { addModelDialogOpen: state.addCustomerDialogOpen } });
  };

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const renderSearchField = () => (
    <SearchContainer container item xs={12} md={4}>
      <SearchTextField
        variant="outlined"
        label=""
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search transactin history"
        fullWidth
        InputProps={{
          endAdornment:
  <InputAdornment position="end">
    <SearchButton
      aria-label="Search transactin history"
      edge="end"
      disabled
    >
      <SearchImage alt="search icon" src={SearchIcon} />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const {
    loading, error, data, refetch
  } = useQuery(CUSTOMER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  useEffect(() => {
    if (data && data.customer) {
      updateCustomerState(refetch);
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  const {
    customerId, name, dateCreated, address,
    mobile, email, businessId, loyaltyPoint, storeCredit, username
  } = parseCustomerFields(data?.customer || {});

  const removeCustomer = () => {
    deleteCustomer({
      variables: {
        customerId
      }
    })
      .then(({ resp }) => {
        const { message } = resp?.deleteCustomer || {};
        setAlertState({
          message,
          showAlert: true,
          type: 'success'
        });
      })
      .catch(() => {
        setAlertState({
          message: 'Something went wrong!',
          showAlert: true,
          type: 'error'
        });
      })
      .finally(() => {
        navigate('/customers');
      });
  };

  const isOgaAdmin = role === 'oga-pharmacy-admin';

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item xs={6}>
            <MenuButton onClick={() => {
              setOpenDialogue(false);
              navigate('/customers');
              setState({ ...state, addCustomerDialogOpen: false });
            }}
            >
              <KeyboardArrowLeft />
            </MenuButton>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                Customer Detail
              </PrimaryTitle>
              <SecondaryTitle>
                View details of a specified customer
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
          <Grid item xs={6} container justifyContent="flex-end">


            { parseInt(businessId) === parseInt(localBusinessId) && (
            <ViewCartButton
              item
              onClick={() => openCustomerEditDialog(data?.customer || {})}
              style={{ marginRight: '3rem' }}
            >
              <Hidden smDown>
                <HeaderIcon alt="cart icon" src={EditIcon} />
              </Hidden>
              <ViewCartButtonText>
                Edit
              </ViewCartButtonText>
            </ViewCartButton>
            )}

            { parseInt(businessId) === parseInt(localBusinessId) && (
            <DeleteCartButton
              item
              onClick={() => setOpenDialogue(true)}
            >
              <Hidden smDown>
                <HeaderIcon alt="cart icon" src={DeleteIcon} />
              </Hidden>
              <ViewCartButtonText>
                Delete
              </ViewCartButtonText>
            </DeleteCartButton>

            )}
          </Grid>
        </TitleGridContainer>
        <CustomerHeader
          id={customerId}
          name={name}
          dateCreated={dateCreated}
          createdBy={username}
          address={address}
          totalSpend={storeCredit}
          creditBalance={storeCredit}
          loyaltyEarned={loyaltyPoint}
          loyaltyBalance={storeCredit}
          mobile={mobile}
          email={email}
        />

        <TabPanel value={value} index={isOgaAdmin ? 1 : 0}>
          <Hidden mdUp>
            {renderSearchField()}
          </Hidden>
          <OrderSubHeader container item>
            <CustomerContainer container item xs={12} md={2} xl={2}>
              <TransactinHistoryContainer>
                Transaction History
              </TransactinHistoryContainer>
            </CustomerContainer>
            <Hidden smDown>
              {renderSearchField()}
            </Hidden>
          </OrderSubHeader>
          <CustomerSales
            state={state}
            customerSaleId={customerId}
            handleChangePage={handleChangePage}
            page={currentPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSearch={handleSearch}
          />
        </TabPanel>
      </OrderGridContainer>

      <IndividualCustomerDialog
        dialogOpen={state.addCustomerDialogOpen}
        closeDialog={toggleCustomerEditDialog}
      />

      <Dialog
        open={(openDialogue)}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialogue(false)}
        maxWidth="md"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
      >
        <Grid container>
          <GridWrapper container item xs={12}>
            <DialogTitle id="alert-dialog-slide-title">
              <DialogTitleWrapper container>
                <DialogTitleText>
                  Confirm Delete
                </DialogTitleText>
                <DialogTitleSubText>
                  Are you sure you want to delete this customer? You will not be able to restore your actions.
                </DialogTitleSubText>
              </DialogTitleWrapper>
            </DialogTitle>

            <DialogActions>
              <DialogCancelButton
                onClick={() => setOpenDialogue(false)}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={removeCustomer}
              >
                <DialogActionButtonText>
                  {loading ? (
                    <CircularProgressLoader
                      disableShrink
                      size={22}
                      thickness={5}
                    />
                  ) : (
                    <DialogActionButtonText>
                      Delete
                    </DialogActionButtonText>
                  )}
                </DialogActionButtonText>
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </Dialog>

    </MainContent>
  );
};

export default CustomerDetailsContainer;
