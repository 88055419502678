import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FilterBox = styled(Grid)`
  padding-left: 2px;
  padding-bottom: 20px;
  max-height: 124.5rem;

  @media (max-width: 991px) {
    padding-left: 35px;
  }
`;

export const ListItems = styled(Grid)`
  padding: .5rem 0 0;

  @media (min-width: 992px) {
    padding-left: .5rem
  }
`;

export const ItemContentGrid = styled(Grid)`
  display: ${(props) => (props.drop ? 'flex' : 'none')}
`;

export const ButtonImage = styled('img')`
  width: 5rem;
  display: block;

  @media(min-width: 992px) {
    width: 2rem;
  }
`;

export const FilterSubHeader = styled('h4')`
  font-weight: bold;
  margin: 20px 0;
  font-size: 1.8rem;

  &:first-of-type{
    margin-top: 0;
  }
  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const HeaderGrid = styled(Grid)`
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  ${'' /* padding: 6px;
  padding-left: 20px; */}

  @media (max-width: 991px) {
    padding: 25px 35px;
  }
`;

export const FilterHandle = styled(Grid)`
  cursor: pointer;
  margin-bottom: 8px;
`;

export const FilterGrid = styled(Grid)`
  padding-left: 20px;
`;

export const SearchGrid = styled(Grid)`
  padding: 6px 10px;
`;

export const FilterContainer = styled(Paper)`
  background: #fff;
  border-radius: .4rem;
  height: 100.6rem;
  max-height: 100.5rem;
  overflow: hidden;
  overflow-y: scroll;
`;

export const FilterNameTypo = styled(Typography)`
  font-size: 1rem;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const FilterCreditTypo = styled(Typography)`
  font-size: 0.7rem;

  @media (max-width: 991px) {
    font-size: 1.2rem;
  }
`;

export const FilterImage = styled('img')`
  width: 26px;

  @media (max-width: 991px) {
    width: 50px
  }
`;
export const ContainerHeader = styled(Typography)`
  font-weight: bold;
  margin: 20px 20px;
  font-size: 1.2rem;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const ProductFilterHeader = styled(Typography)`
  margin: 40px 10px;
  font-size: 1rem;
  font-weight: bold;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const FilterHeader = styled(Typography)`
  font-size: 1.17rem;
  border-top: 1px solid #CCCCCC80;
  padding: 20px;
  paddingBottom: 0px;

  @media (max-width: 991px) {
    font-size: 2rem;
    font-weight: 600;
    padding: 25px 35px;
  }
`;

export const FilterTypo = styled(Typography)`
   font-size: 1.2rem;
   font-weight: bold;

  @media (max-width: 991px) {
    font-size: 2rem;
    font-weight: 500;
  }
`;
