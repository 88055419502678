import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation, refetchQueries, useApolloClient } from '@apollo/client';
import { Dots } from '../../assets/svgs';
import ActionPopper from './actionPopper';
import {
  BodyCell, MainTableRow,
} from './categoryCard.styles';
import { DELETE_PRICE_CATEGORY_MUTATION } from '../../mutations/categories';
import { FIND_CATEGORY_QUERY } from '../../queries/categories';

const ReturnRow = ({ row }) => {
  const {
    categoryName, vatStatus, loyaltyWeight, markup, id, categoryTotalProducts
  } = row;
  const [action, setAction] = useState(null);
  const navigate = useNavigate();
  const client = useApolloClient();

  const [deleteCategory] = useMutation(DELETE_PRICE_CATEGORY_MUTATION);

  const deletePriceCategoryhandel = (categoryId) => {
    deleteCategory({
      variables: {
        categoryId
      },
      refetchQueries: [{ query: FIND_CATEGORY_QUERY }]
    })
      .then(({ data }) => {
        const { message } = data?.deleteCategory || {};
        toast.success(message);
        client.refetchQueries({ include: [FIND_CATEGORY_QUERY] });
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const handleAction = (event, type, catId) => {
    if (type === 'delete') {
      deletePriceCategoryhandel(String(catId.id));
    }
    handleOptions(event);
  };

  return (
    <MainTableRow>
      <BodyCell onClick={() => navigate(`/category/${id}/details`)} style={{ cursor: 'pointer' }}>
        {categoryName}
      </BodyCell>
      <BodyCell>
        {`#${id}`}
      </BodyCell>
      <BodyCell>
        {categoryTotalProducts}
      </BodyCell>
      <BodyCell>
        {markup}
      </BodyCell>
      <BodyCell>
        {loyaltyWeight}
      </BodyCell>
      <BodyCell>
        {vatStatus}
      </BodyCell>
      <BodyCell>
        <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
        <ActionPopper
          action={action}
          handleAction={handleAction}
          row={row}
          handleClose={handleCloseModal}
        />
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
