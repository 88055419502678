import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { AFFILIATE_QUERY } from '../../queries/affiliates';
import NewOrdersLoader from '../customComponents/loaders/newOrdersLoader';
import CTablePagination from '../customComponents/cTablePagination';
import affiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import paginationShowing from '../../utils/pagination';
import { useStateValue } from '../../providers/stateProvider';
import GoToTop from '../customComponents/scrollToTop';
import AffiliateCard from './affiliateCard';
import {
  AffiliateCardsContainer, AffiliateCardGrid,
  FooterGrid, OrderCountText
} from './affiliates.styles';
import { JSONParse } from '../../utils/json';

export default function Affiliates({
  state,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const [, dispatch] = Object.values(useStateValue());

  const {
    searchValue, currentPage, currentPageCount,
  } = state;

  const updateAffiliateState = (affiliates, affiliatesCount, refetch) => {
    dispatch({
      type: affiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliates,
        affiliatesCount,
        refetch
      }
    });
  };

  const {
    loading, error, data, refetch
  } = useQuery(AFFILIATE_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      name: searchValue,
      pageCount: currentPageCount,
      pageNumber: currentPage
    },
  });
  const filtereData = data?.allBusinesses
    .map((business) => {
      const parsedContacts = JSONParse(
        business.contacts.replace(/'/g, '"').replace('None', '"Lagos"')
      );
      const { city } = parsedContacts;
      return { ...business, city };
    });
  const {
    businessTotalNumber = 0,
  } = data || {};

  useEffect(() => {
    if (filtereData) {
      updateAffiliateState(filtereData, businessTotalNumber, refetch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <NewOrdersLoader />;
  if (error) return <div>{error.message}</div>;

  const showing = paginationShowing(filtereData, currentPageCount);
  return (
    <>
      <OrderCountText>
        Showing
        <b>
          {` ${showing()} - ${showing('end')} `}
        </b>
        of
        <b>
          {` ${businessTotalNumber} `}
        </b>
        Affiliates
      </OrderCountText>
      <AffiliateCardsContainer container spacing={2}>
        {
          filtereData.map((affiliate) => (
            <AffiliateCardGrid key={affiliate.id} item xs>
              <AffiliateCard
                id={affiliate.id}
                logo={affiliate.logo}
                name={affiliate.name}
                location={affiliate.city || 'N/A'}
                grossOrder={affiliate.grossOrderAmount}
                tax={affiliate.tax}
              />
            </AffiliateCardGrid>
          ))
        }

      </AffiliateCardsContainer>

      <FooterGrid item container>

        <CTablePagination
          rowsPerPageOptions={[20, 50, 100, 150, 200]}
          component="div"
          count={businessTotalNumber}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
      {
        (filtereData.length > 8)
          ? <GoToTop /> : ''

      }
    </>
  );
}

Affiliates.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  currentPageCount: PropTypes.number.isRequired,
};
