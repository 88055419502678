import {
  TableCell, Checkbox, Grid, Button
} from '@mui/material';
import styled, { css } from 'styled-components';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F4F5F6;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1), &:nth-child(2) {
      width: 5%;
    }
    &:nth-child(3), :nth-child(4), &:nth-child(5) {
      width: 18%;
    }
    &:nth-child(6), :nth-child(7), :nth-child(8) {
      width: 12%;
    }
  }
`;

const pendingStyles = css`
  background-color: #FBF777;
  color: #606060;
`;

const confirmedStyles = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;

const rejectedStyles = css`
  background-color: #FBDADA;
  color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'confirmed':
      return confirmedStyles;
    case 'rejected':
      return rejectedStyles;
    default:
      return pendingStyles;
  }
};

export const ColorGrid = styled(Grid)`
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  width: 128px;
  height: 46px;
  justify-content: center;
  padding: 1px 0;

  ${getStyles}
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const TCustomButton = styled(Button)`
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  width: 8rem;
  height: 46px;
  color: #606060;
  font-size: 12px;
  background: #fff;
`;
