import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  CMobileStepper, SlimCheckbox, StepperTypo
} from './stepper.styles';

const Stepper = ({ activeStep }) => (
  <Grid item container>
    <CMobileStepper
      variant="progress"
      steps={3}
      position="static"
      activeStep={activeStep}
    />
    <Grid item container justifyContent="space-between">
      <Grid item container xs={4}>
        <StepperTypo>Received</StepperTypo>
        <SlimCheckbox checked={activeStep === 1} />
      </Grid>
      <Grid item container xs={4} justifyContent="flex-end">
        <StepperTypo>Reconciled</StepperTypo>
        <SlimCheckbox checked={activeStep === 2} />
      </Grid>
    </Grid>
  </Grid>
);

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired
};

export default Stepper;
