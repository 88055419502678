import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import { JSONParse } from '../../../utils/json';
import {
  ProductDetailImage, PackSizeText, MainTableRow, BodyCell, BrandNameText
} from './returnRow.styles';

const ReturnRow = ({ prod }) => {
  const {
    unitCost, name: brandName, meta: orderMeta, product: { meta }, quantity, quantityRecieved
  } = prod;
  const prodMeta = JSONParse(meta.replace(/'/g, '"'));
  const getPrice = () => currencyFormatter(Number(unitCost) * (Number(quantityRecieved)));
  const { clearance_sale: clearanceSale } = JSONParse(orderMeta.replace(/'/g, '"'));

  return (
    <MainTableRow container>
      <BodyCell>
        <Grid container item>
          <Grid container item xs={4} alignItems="center">
            <ProductDetailImage src={prodMeta?.image} alt="" />
          </Grid>
          <Grid container item xs={8} direction="column" justifyContent="center">
            <BrandNameText>{brandName}</BrandNameText>
            <PackSizeText>{`Pack Size: ${prodMeta?.pack_size ?? 'N/A'}`}</PackSizeText>
            {clearanceSale === true || clearanceSale === 'true'
              ? (
                <span style={{
                  color: '#FFA621', fontWeight: 'bold', background: '#FFF7F3', padding: '5px 15px', borderRadius: 15, width: 'fit-content', fontSize: 10, marginTop: 5
                }}
                >
                  Short dated
                </span>
              ) : null}
          </Grid>
        </Grid>
      </BodyCell>
      <BodyCell>{Number(quantity)}</BodyCell>
      <BodyCell>{quantityRecieved || 0}</BodyCell>
      <BodyCell>
        {`₦ ${currencyFormatter(unitCost)}`}
      </BodyCell>
      <BodyCell>{`₦ ${getPrice()}`}</BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  prod: PropTypes.instanceOf(Object).isRequired,
};

export default ReturnRow;
