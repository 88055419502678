import { createTheme, styled } from '@mui/material/styles';
import {
  Typography, Grid
} from '@mui/material';

export const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%'
      },
      root: {
        backgroundColor: '#E0E0E0'
      }
    }
  }
});

export const TitleText = styled(Typography)`
  font-size: 10.31px;
  font-family: Montserrat,medium,sans-serif;
  color: #000;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

export const SubTitleText = styled(Typography)`
  font-size: 9.52px;
  font-family: Montserrat,medium,sans-serif;
  color: #00000066;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BrowseFileButton = styled(Grid)`
  font-size: 10px;
  font-weight: 500;
  font-family: Montserrat,medium,sans-serif;
  color: #666666;
  text-align: center;
  padding: 10px 0;
  border: 1px solid #666666;
  border-radius: 6px;
  width: 85px;
  margin-top: 1rem;

  @media(min-width: 992px) {
    font-size: 9px;
    padding: 10px 0;
  }
`;

export const DropZoneContainer = styled(Grid)`
  padding: 0 4rem;
  align-items: center;
`;
