import React from 'react';
import PropTypes from 'prop-types';
import {
  GridContainer, ItemTypo, ItemWrapper, GridWrapper
} from './supplierCreatePopper.styles';
import { RenderPopper } from '../../../utils/utils';

const SupplierPopper = ({
  supplierButton,
  handleSupplierButtonClick,
  individualDialogHandler,
  navigate
}) => {
  const open = Boolean(supplierButton);

  const handleManualSupplierAdd = () => {
    handleSupplierButtonClick();
    individualDialogHandler();
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={supplierButton}
      onClickAway={() => handleSupplierButtonClick()}
      popperPlacement="bottom"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer container item onClick={handleManualSupplierAdd}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Add individually
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>

        <GridContainer container item onClick={() => navigate('/map-supplier-csv')}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Upload CSV file
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
};

SupplierPopper.propTypes = {
  supplierButton: PropTypes.instanceOf(Object),
  handleSupplierButtonClick: PropTypes.func.isRequired,
  individualDialogHandler: PropTypes.func.isRequired,
  navigate: PropTypes.instanceOf(Object).isRequired
};

SupplierPopper.defaultProps = {
  supplierButton: {},
};

export default SupplierPopper;
