/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import { CREATE_FEEDBACK_MUTATION } from '../../mutations/feedback';
import { useStateValue } from '../../providers/stateProvider';
import CustomButton from '../customComponents/customButton';
import {
  FeedbackContainer, TextContainer, NavContainer, NavLogo, EmojiContainer, BigText,
  SmallText, EmojiText, TextArea, Emoji, CircularProgressLoader
} from './feedback.styles';
import PharmiqLogo from '../../assets/images/PharmIQ Logo (dark).png';
import GreatActive from '../../assets/images/feedback/Great - Active.png';
import GoodActive from '../../assets/images/feedback/Good - Active.png';
import OkayActive from '../../assets/images/feedback/Okay - Active.png';
import BadActive from '../../assets/images/feedback/Bad - Active.png';
import TerribleActive from '../../assets/images/feedback/Terrible - Active.png';
import GreatInactive from '../../assets/images/feedback/Great - Inactive.png';
import GoodInactive from '../../assets/images/feedback/Good - Inactive.png';
import OkayInactive from '../../assets/images/feedback/Okay - Inactive.png';
import BadInactive from '../../assets/images/feedback/Bad - Inactive.png';
import TerribleInactive from '../../assets/images/feedback/Terrible - Inactive.png';

export default function Feedback() {
  const initialState = {
    rating: 0,
    message: '',
    orderId: 0,
    affiliateId: 0,
    loading: false,
  };

  const initialEmojiState = {
    great: false,
    good: false,
    okay: false,
    bad: false,
    terrible: false
  };

  const [state, setState] = useState(initialState);
  const [, dispatch] = Object.values(useStateValue());
  const [emojiState, setEmojiState] = useState(initialEmojiState);

  const navigate = useNavigate();

  const handleTextChange = (event) => {
    const { value } = event.target;
    setState({ ...state, message: value });
  };

  const handleSubmitFeedback = (createFeedback) => {
    const {
      rating, message, orderId, affiliateId
    } = state;
    if (!rating) {
      toast.error('Please select your satisfaction level.');
    } else {
      setState({ ...state, loading: true });
      createFeedback({
        variables: {
          rating,
          message,
          orderId,
          affiliateId
        }
      }).then((response) => {
        const msg = response?.data?.createFeedback.message;
        toast.success(msg);
      }).then(() => {
        const timer = setTimeout(() => {
          localStorage.removeItem('feedback');
          navigate('/module');
        }, 2000);
        return () => clearTimeout(timer);
      }).catch((err) => {
        toast.error(err?.message);
      })
        .finally(() => {
          setState({ ...state, loading: false });
        });
    }
  };

  const handleSelectedEmoji = (status) => {
    const ratings = {
      terrible: 1, bad: 2, okay: 3, good: 4, great: 5
    };
    setState({ ...state, rating: ratings[status] });
    if (!emojiState[status]) {
      setEmojiState({ ...initialEmojiState, [status]: !emojiState[status] });
    }
  };

  useEffect(() => {
    const feedback = JSON.parse(localStorage.getItem('feedback'));
    if (feedback?.orderId) {
      const { orderId, affiliateId } = feedback;
      setState({ ...state, orderId, affiliateId });
    } else navigate('/module');
    dispatch({ type: 'hideNavbar', payload: true });
  }, []);

  const { loading } = state;
  const {
    great, good, okay, bad, terrible
  } = emojiState;

  return (
    <Mutation mutation={CREATE_FEEDBACK_MUTATION}>
      {(createFeedback) => (
        <FeedbackContainer>
          <NavContainer>
            <NavLogo src={PharmiqLogo} />
          </NavContainer>

          <TextContainer>
            <BigText> Welcome back!</BigText>
            <SmallText>
              How was your experience with your last order?
            </SmallText>
          </TextContainer>

          <Grid container justifyContent="center">
            <EmojiContainer>
              <Emoji src={great ? GreatActive : GreatInactive} onClick={() => handleSelectedEmoji('great')} />
              <EmojiText style={{ fontWeight: `${great ? 600 : 400}` }}>Great</EmojiText>
            </EmojiContainer>
            <EmojiContainer>
              <Emoji src={good ? GoodActive : GoodInactive} onClick={() => handleSelectedEmoji('good')} />
              <EmojiText style={{ fontWeight: `${good ? 600 : 400}` }}>Good</EmojiText>
            </EmojiContainer>
            <EmojiContainer>
              <Emoji src={okay ? OkayActive : OkayInactive} onClick={() => handleSelectedEmoji('okay')} />
              <EmojiText style={{ fontWeight: `${okay ? 600 : 400}` }}>Okay</EmojiText>
            </EmojiContainer>
            <EmojiContainer>
              <Emoji src={bad ? BadActive : BadInactive} onClick={() => handleSelectedEmoji('bad')} />
              <EmojiText style={{ fontWeight: `${bad ? 600 : 400}` }}>Bad</EmojiText>
            </EmojiContainer>
            <EmojiContainer>
              <Emoji src={terrible ? TerribleActive : TerribleInactive} onClick={() => handleSelectedEmoji('terrible')} />
              <EmojiText style={{ fontWeight: `${terrible ? 600 : 400}` }}>Terrible</EmojiText>
            </EmojiContainer>
          </Grid>
          <TextContainer>
            <SmallText>What do you want us to improve? We want to serve you better.</SmallText>
          </TextContainer>
          <Grid container>
            <TextArea
              rowsMin={6}
              placeholder="Write something..."
              onChange={handleTextChange}
            />
          </Grid>
          <Grid container justifyContent="center">
            <CustomButton
              onClick={() => handleSubmitFeedback(createFeedback)}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={16}
                  thickness={3}
                />
              ) : 'Submit Feedback'}
            </CustomButton>
          </Grid>
        </FeedbackContainer>
      )}
    </Mutation>
  );
}
