import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import { useStateValue } from '../../providers/stateProvider';
import CustomerCategories from './customerCategories/customerCategories';
import { GridContainer } from './customerCategories.styles';

const CategoryContainer = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, [dispatch]);

  return (
    <MainContent>
      <GridContainer container>
        <Grid item container spacing={2}>
          <Grid item container xs={12} className="mpAdmin-uat-customerCategory-page">
            <CustomerCategories />
          </Grid>
        </Grid>
      </GridContainer>
    </MainContent>
  );
};

export default CategoryContainer;
