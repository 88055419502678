import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { StyledTab } from '../../../orders/admin/order.styles';

import { THERAPEUTIC_TYPE_DETAIL } from '../../../../queries/therapeuticType';
import TherapeuticTypeProducts from './therapeuticTypeProducts';
import TherapeuticTypeHeader from './therapeuticTypeHeader';
import Loader from '../../../shared/loader';
import MainContent from '../../../customComponents/mainContent';
import EditIcon from '../../../../assets/images/Edit Icon.png';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, ViewCartButton, ViewCartButtonText,
  TextWrapper, HeaderIcon, MenuButton, KeyboardArrowLeft
} from './therapeuticTypeDetailsContainer.styles';

import IndividualTherapeuticTypeDialog from '../individualTherapeuticTypeDialog';

const TherapeuticTypeDetailsContainer = () => {
  const initialState = {
    searchText: '',
    currentPage: 1,
    currentPageCount: 20,
  };

  const [state, setState] = useState(initialState);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [openPaymentModal, setOpenPaymentModal] = useState(false);
  // const [value, setValue] = useState(0);

  const { currentPage } = state;
  const { id } = useParams();
  const navigate = useNavigate();

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const {
    loading, error, data, refetch
  } = useQuery(THERAPEUTIC_TYPE_DETAIL, {
    fetchPolicy: 'cache-and-network',
    variables: { id: Number(id) },
  });

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  const {
    id: therapeuticTypeId, therapeuticType, therapeuticTypeProductCount
  } = data?.therapeuticType || {};

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <MenuButton onClick={() => navigate('/therapeutic-types')}>
              <KeyboardArrowLeft fontSize="large" />
            </MenuButton>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                Product Type Detail
              </PrimaryTitle>
              <SecondaryTitle>
                View details of a specified product type
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
          <Grid item xs={4} container justifyContent="flex-end">
            {/* { parseInt(businessId) === parseInt(localBusinessId) && ( */}
            <ViewCartButton
              item
              onClick={() => setDialogOpen(true)}
            >
              <Hidden smDown>
                <HeaderIcon alt="cart icon" src={EditIcon} />
              </Hidden>
                <ViewCartButtonText>
                  Edit
                </ViewCartButtonText>
            </ViewCartButton>
            {/* )} */}

          </Grid>
        </TitleGridContainer>
        <TherapeuticTypeHeader
          id={therapeuticTypeId}
          name={therapeuticType}
          productCount={therapeuticTypeProductCount}
        />
        <AppBar style={{ background: 'none' }} elevation={0} position="static">
          <StyledTab label={`Products (${therapeuticTypeProductCount})`} />
        </AppBar>
        <TherapeuticTypeProducts
          state={state}
          therapeuticTypeId={therapeuticTypeId}
          handleChangePage={handleChangePage}
          page={currentPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          // handleSearch={handleSearch}
        />
        {/* </TabPanel> */}
      </OrderGridContainer>

      <IndividualTherapeuticTypeDialog
        dialogOpen={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        edit
        refetch={refetch}
        therapeuticTypeName={therapeuticType}
      />
    </MainContent>
  );
};

export default TherapeuticTypeDetailsContainer;
