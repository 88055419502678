import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import { MainTableRow, BodyCell } from './returnRow.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';

const ReturnRow = ({ row }) => {
  const {
    brandName, packSize, orderCost, resolvedPriceInUseValue, promoPrice, qtySold
  } = row;
  const isLarge = useMediaQuery('(min-width:992px)');

  const price = `₦ ${currencyFormatter(resolvedPriceInUseValue)}`;
  const cost = `₦ ${currencyFormatter(orderCost)}`;
  const promo = `₦ ${currencyFormatter(promoPrice)}`;

  const cells = [brandName, packSize, cost, price, promo, qtySold];

  const returnCell = (value) => value || '-';

  const renderBodyCells = () => {
    return cells.map((cell) => (
      <BodyCell>{isLarge ? returnCell(cell) : ''}</BodyCell>
    ));
  };

  return (
    <MainTableRow item container>
      {renderBodyCells()}
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
