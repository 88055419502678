/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  HeaderWrapper, CustomerPaper, ContactPaper,
  NormalText, NameText, LowerGrids, BottomContainer, BottomCustomerContainer, ContactGrid, IconImage,
  NameGrid, SubHeaderText, ContactHeaderText, LightDivider, LowerLighterText,
  LowerDarkerText, LowerLighter2Text, SmallHeading, ContactsGrid, NormalText2
} from './customerHeader.styles';
// import OrderIcon from '../../../assets/images/order/Total Orders Icon.png';
import OrderCashIcon from '../../../assets/images/order/Gross Order Value Icon.png';
import currencyFormatter from '../../shared/currencyFormatter';

const CustomerHeader = ({
  id, name, dateCreated, createdBy, totalSpend, creditBalance,
  loyaltyEarned, loyaltyBalance,
  address, mobile, email
}) => {
  const datePlaced = moment(dateCreated).format('DD/MM/YYYY');
  const customerId = `CU${id}`;

  return (
    <HeaderWrapper container spacing={6}>
      <NameGrid item xs={12} md={6}>
        <CustomerPaper elevation={2}>
          <SubHeaderText variant="subtitle1" align="right">
            {customerId}
          </SubHeaderText>
          <LightDivider light />
          <BottomCustomerContainer item direction="column">
            <NameText>{name}</NameText>
            <NormalText>
              {`Added by ${name} on ${datePlaced}`}
            </NormalText>
            <LowerGrids container item style={{ marginBottom: '1.5rem' }}>
              <IconImage alt="lower logo" src={OrderCashIcon} />
              <LowerLighter2Text variant="subtitle1">
                Total Spend:
              </LowerLighter2Text>
              <LowerDarkerText variant="subtitle1">
                {currencyFormatter(totalSpend)}
              </LowerDarkerText>
            </LowerGrids>

            <LowerGrids container item>
              <LowerLighter2Text variant="subtitle1">
                Store Credit Balance:
              </LowerLighter2Text>
              <LowerDarkerText variant="subtitle1">
                {currencyFormatter(creditBalance)}
              </LowerDarkerText>
            </LowerGrids>

            <LowerGrids container item>
              <LowerLighter2Text variant="subtitle1">
                Total Loyalty Earned:
              </LowerLighter2Text>
              <LowerDarkerText variant="subtitle1">
                {loyaltyEarned}
              </LowerDarkerText>
            </LowerGrids>

            <LowerGrids container item>
              <LowerLighter2Text variant="subtitle1">
                Loyalty Balance:
              </LowerLighter2Text>
              <LowerDarkerText variant="subtitle1">
                {loyaltyBalance}
              </LowerDarkerText>
            </LowerGrids>

          </BottomCustomerContainer>
        </CustomerPaper>
      </NameGrid>
      <ContactGrid item xs={12} md={6}>
        <ContactPaper elevation={2}>
          <ContactHeaderText variant="h6" align="left">
            Contact Information
          </ContactHeaderText>
          <LightDivider light />
          <ContactsGrid item direction="column">
            <SmallHeading variant="caption">Mobile</SmallHeading>
            <NormalText2>{mobile || 'N/A'}</NormalText2>
            <SmallHeading variant="caption">Email</SmallHeading>
            <NormalText2>{email || 'N/A'}</NormalText2>
            <SmallHeading variant="caption">Address</SmallHeading>
            <NormalText>{`${address[0]},`}</NormalText>
            {address[1] && <NormalText>{`${address[1]},`}</NormalText>}
            {address[2] && <NormalText>{`${address[2]},`}</NormalText>}
            <NormalText>{`${address[3]}, ${address[4]}`}</NormalText>
          </ContactsGrid>
        </ContactPaper>
      </ContactGrid>
    </HeaderWrapper>
  );
};

CustomerHeader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  dateCreated: PropTypes.string,
  address: PropTypes.arrayOf(String),
  createdBy: PropTypes.string,
  totalSpend: PropTypes.number,
  creditBalance: PropTypes.number,
  loyaltyEarned: PropTypes.number,
  loyaltyBalance: PropTypes.number,
  mobile: PropTypes.number,
  email: PropTypes.string
};

CustomerHeader.defaultProps = {
  id: '',
  name: '',
  dateCreated: '',
  address: [],
  createdBy: '',
  totalSpend: 0,
  creditBalance: 0,
  loyaltyEarned: 0,
  loyaltyBalance: 0,
  mobile: 0,
  email: ''
};

export default CustomerHeader;
