import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import currencyFormatter from '../../../utils/currencyFormatter';

import {
  BodyCell, MainTableRow
} from './returnRow.styles';

const ReturnRow = ({ row }) => {
  const {
    dateCreated,
    loyaltyAwarded,
    receiptNumber,
    totalToPay,
    changeDue,
    business: { name },
    businessUser
  } = row;

  const { user: { username } } = businessUser;

  return (
    <MainTableRow>
      <BodyCell>
        {dateCreated ? moment(dateCreated).format('lll') : 'N/A'}
      </BodyCell>

      <BodyCell>
        { name }
      </BodyCell>

      <BodyCell>
        {receiptNumber}
      </BodyCell>

      <BodyCell>
        {username}
      </BodyCell>

      <BodyCell>
        {currencyFormatter(totalToPay)}
      </BodyCell>

      <BodyCell>
        {loyaltyAwarded || 'N/A'}
      </BodyCell>

      <BodyCell>
        {changeDue || 'N/A'}
      </BodyCell>

    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
