import {
  Grid, TableContainer, Table, TableHead, TableCell
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableHead)`
  display: none;

  @media(min-width: 992px) {
    display: table-header-group;
    background: #F3F9FF;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
  padding: 0px 15px;
`;

export const TableComponent = styled(Table)`
  min-width: 650;
`;

export const ErpHeader = styled(TableCell)`
ont-weight: 700;
font-size: 16px;
line-height: 164.2%;
color: #303030;
  :first-child{
    width: 17%;
  }
  :nth-child(3), :nth-child(4), {
    width: 20%;
  }
  :last-child {
    width: 15%;
  }
  :nth-child(2) {
    width: 28%;
  }
  /**text-transform: uppercase;**/
  padding: 10px;
  width: 23%;

`;

export const FooterGrid = styled(Grid)`
align-items: center;
margin-top: 10px;
padding: 1.5rem 15px;
justify-content: center;
display: grid;



@media (min-width: 992px) {
  display: flex;
  justify-content: space-between;
}
`;

export const MobileWrapper = styled(Grid)`
  margin: 2rem 1rem 1rem 1rem;
  width: 100%;
`;
