import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  ButtonsGrid,
  OrderButton, OrderButtonText,
  PrimaryTitle,
  SecondaryTitle,
  TitleGridContainer, TitleTextGridContainer
} from '../../toBeDeleted/titleGrid.styles';
import CustomerDropdown from './uploadModel/modelCreatePopper';

import ModelActionTypes from '../../../providers/reducers/model/modelTypes';
import { useStateValue } from '../../../providers/stateProvider';
import CategoriesCSVUpload from '../../categories/categoriesCSVUpload';

export default function TitleGrid({
  renderIndividualModel, modelName, modelNamePlural, endpoint, fileName, reloadFunc
}) {
  const [customerButton, setCustomerLogoEl] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const openDialogHandler = () => {
    reloadFunc();
    setOpenDialog(!openDialog);
  }

  const [{
    model: { addModelDialogOpen }, user: { allowedPermissionsMap: { userPermissions } },
  }, dispatch] = Object.values(useStateValue());

  const handleCustomerButtonClick = (event) => {
    setCustomerLogoEl(customerButton ? null : event.currentTarget);
  };
  const toggleIndividualDialog = () => {
    dispatch({ type: ModelActionTypes.TOGGLE_DIALOG_OPEN });
  };
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          {modelNamePlural}
        </PrimaryTitle>
        <SecondaryTitle>
          Business
          {' '}
          {modelNamePlural}
          {' '}
          on Portal
        </SecondaryTitle>
      </TitleTextGridContainer>
      <ButtonsGrid item>
        <OrderButton
          onClick={handleCustomerButtonClick}
          disabled={
            (modelName === 'Product type') ? !userPermissions?.includes('product_type_add_product_type')
              : (modelName === 'Category') ? !userPermissions.includes('pricing_categories_add_category')
                : !userPermissions?.includes(`${endpoint}_add_${fileName}`)
              }
        >
          {/* <Hidden smDown>
            <PlusIcon />
          </Hidden> */}
          <OrderButtonText>
            Add
            {' '}
            {modelName}
          </OrderButtonText>
        </OrderButton>
      </ButtonsGrid>
      <CategoriesCSVUpload
        openDialog={openDialog}
        toggleHandler={openDialogHandler}
      />
      <CustomerDropdown
        customerButton={customerButton}
        handleCustomerButtonClick={handleCustomerButtonClick}
        individualDialogHandler={toggleIndividualDialog}
        modelName={modelName}
        openDialogHandler={openDialogHandler}
      />
      {renderIndividualModel(addModelDialogOpen, toggleIndividualDialog)}
    </TitleGridContainer>
  );
}
TitleGrid.propTypes = {
  renderIndividualModel: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired,
  modelNamePlural: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  reloadFunc: PropTypes.func
};

TitleGrid.defaultProps = {
  reloadFunc: () => null
};
