import {
  Grid, Typography, Paper, Divider, Checkbox, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled(Grid)`
    margin-bottom: .1rem;

    @media(min-width: 992px) {
    }
  `;

export const NameGrid = styled(Grid)`

  `;

export const ContactGrid = styled(Grid)`
  `;

export const SupplierPaper = styled(Paper)`
    position: relative;
    border-radius: 8px;
    padding-bottom: 15px;
    min-height: 22.3rem;

    @media(min-width: 992px) {
      border-radius: 6px;
    }
  `;

export const MainPaper = styled(Paper)`

`;

export const SubHeaderText = styled(Typography)`
    color: #A3A3A3;
    padding: 18px 20px;

    @media(max-width: 991px) {
      font-size: 1.4rem
    }
  `;

export const HeaderText = styled(Typography)`
  font-size: .8rem;
  font-weight: 300;
  padding: 15px 20px 5px;
  color: #606060;
`;

export const LightDivider = styled(Divider)`
  color: #F0F0F0;
`;

export const SearchGrid = styled(Grid)`
  padding: .5rem 0;
  align-items: center;
  justify-content: flex-end;
`;

export const BottomContainer = styled(Grid)`
  margin: 8rem 3rem 0;

  @media(min-width: 992px) {
    margin: 4.5rem 3rem 0;
  }
`;

export const ItemImage = styled('img')`
  width: 12rem;
  position: absolute;
  top: 5px;
  left: 2rem;

  @media(min-width: 992px) {
    width: 8rem;
  }
`;

export const NormalText = styled(Typography)`
  font-size: 20px;
  margin-bottom: 10px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const NormalText2 = styled(Typography)`
    font-size: 20px;
    margin-bottom: 25px;

    @media(min-width: 992px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  `;

export const NameText = styled(Typography)`
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: 600;

    @media(min-width: 992px) {
      font-size: 25px;
      margin-bottom: 20px;
    }
  `;

export const LowerGrids = styled(Grid)`
    align-items: center;
    margin-bottom: 10px;
  `;

export const LowerLighterText = styled(Typography)`
    margin: 0 10px;
    color: #A3A3A3;

    @media(max-width: 991px) {
      font-size: 1.7rem;
    }
  `;

export const LowerLighter2Text = styled(Typography)`
    margin-left: 10px;
    margin-right: 26px;
    color: #A3A3A3;

    @media(max-width: 991px) {
      font-size: 1.7rem;
    }
  `;

export const LowerDarkerText = styled(Typography)`
    margin-left: 5px;
    font-weight: 500;

    @media(max-width: 991px) {
      font-size: 1.7rem;
    }
  `;

export const IconImage = styled('img')`
    height: 55px;

    @media(min-width: 992px) {
      height: 30px;
    }
  `;

export const ContactsGrid = styled(Grid)`
    margin: 15px 3rem;
  `;

export const SmallHeading = styled(Typography)`
    color: #A3A3A3;
    font-size: 1rem;

    @media(min-width: 992px) {
      font-size: .75rem;
    }
  `;

export const UserIcon = styled('img')`
  width: 12rem;
  height: 12rem;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #CCCCCC;
`;

export const UpdateButton = styled(IconButton)`
    align-items: center;
    padding: 15px 20px;
    background-color: #FCED50;
    color: #424242;
    border-radius: 5px;
    height: 1rem;
    margin: 0 20px;
  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }
`;

export const UpdateButtonText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
