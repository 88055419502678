import { styled } from '@mui/material/styles';
import { Grid, TableCell, Typography } from '@mui/material';

export const BrandNameText = styled(Typography)`
  color: #24292E;
  font-size: 20px;
  font-weight: 700;
  text-transform: title-case;

  @media (min-width: 992px) {
    line-height: 20px;
    font-size: 14px;
  }
`;

export const ProductDetailImage = styled('img')`
  width: 8rem;
  max-height: 8rem;

  @media (min-width: 992px) {
  width: 95px;
  max-height: 70px;
  }
`;

export const PackSizeText = styled(Typography)`
  color: #606060;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;

  @media (max-width: 991px) {
    font-size: 20px;
  }
`;

export const MainTableRow = styled(Grid)`
  align-items: center;
  border-bottom: 1px solid #E7E8E9;

  :nth-child(odd) {
    background: #F3F9FF;
  }
  
  & > * {
    border-bottom: unset;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 2.8;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 33%;
    }
    &:nth-child(2), &:nth-child(3), :nth-child(4) {
      width: 17%;
    }
    :nth-child(5) {
      width: 16%;
    }
  }
`;
