import { styled } from '@mui/material/styles';
import {
  Typography, Paper, Grid
} from '@mui/material';

export const PopperContainer = styled('div')`
  z-index: 15000000;
  min-height: 0rem;
  width: calc(100% + 1.5rem);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem !important;
  border-radius: 15px;

  @media (min-width: 992px) {
    position: relative;
    margin-left: -.7rem !important;
  }

  @media (max-width: 991px) {
    position: relative;
    z-index: 1;
    margin-bottom: 2.5rem !important;
    border-radius: 25px;
    width: 53rem;
    height: 17rem;
    margin-left: -1.5rem !important;
    margin-top: -16rem !important;
  }
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: 15px;
  padding: 1rem 1.5rem;

  @media (min-width: 992px) {
    min-height: 6.875rem;
    max-height: 7.5rem;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 50%;
      top: -10px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
      -webkit-box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
      -moz-box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
      transform: rotate(136deg);
      -webkit-transform: rotate(136deg);
      -moz-transform: rotate(136deg);
    }
  }


  @media (max-width: 991px) {
    height: 17rem;
    border-radius: 25px;
    padding: 2rem 1rem 1rem 2rem;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: .75rem;
  font-weight: 700;
  color: #303030;
  margin-bottom: .5rem;

  @media (max-width: 991px) {
    font-size: 2.188rem;
    font-weight: 700;
  }
`;

export const TitleText = styled(Typography)`
  font-size: .625rem;
  font-weight: 600;
  color: #616161;

  @media (max-width: 991px) {
    font-size: 1.875rem;
    font-weight: 600;
  }
`;

export const SubtitleText = styled(Typography)`
  font-size: .625rem;
  font-weight: 500;
  color: #616161;

  @media (max-width: 991px) {
    font-size: 1.875rem;
    font-weight: 500;
  }
`;

export const PopperGrid = styled(Grid)`
  margin-bottom: .3rem;
`;
