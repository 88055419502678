import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList
} from '@mui/material';
// import { ReceiptRounded } from '@mui/icons-material';
import { MenuItemStyled, ButtonDarkStyled } from '../return-details.style';
import SalesReceipt from './salesReceipt';
import ReturnReceipt from './returnReceipt';
import { Receipt } from '../../../../../assets/svgs';
import { useStateValue } from '../../../../../providers/stateProvider';

const ReceiptPopper = ({ row }) => {
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openReturnDialog, setOpenReturnDialog] = React.useState(false);
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const openSalesReceiptHandler = () => {
    setOpenDialog(!openDialog);
  };

  const openReturnReceiptHandler = () => {
    setOpenReturnDialog(!openReturnDialog);
  };

  const {
    sale: {
      receiptNumber,
      customer,
      businessUser,
      saleItems,
      saleReturns
    }
  } = row;

  const getCartInfo = () => {
    let subTotal = 0;
    const cart = saleItems.map((item) => {
      const {
        id,
        name: brandName,
        quantity,
        meta,
        unitCost: itemPrice,
        packSize,
        product: { id: productId },
        saleitembatchSet: itemBatches
      } = item;
      subTotal += itemPrice * quantity;
      return {
        id,
        brandName,
        image: '',
        itemPrice,
        packSize,
        productId,
        quantity,
        meta,
        itemBatches
      };
    });
    return { cart, subTotal };
  };
  const { cart } = getCartInfo();

  return (
    <>
      <SalesReceipt
        cart={cart}
        sale={row}
        customer={customer}
        saleReturnData={{ businessUser, receiptNumber, message: '' }}
        openDialog={openDialog}
        openSalesReceiptHandler={openSalesReceiptHandler}
      />
      <ReturnReceipt
        sale={row}
        returns={saleReturns}
        customer={customer}
        saleReturnData={{ businessUser, receiptNumber, message: '' }}
        openReturnDialog={openReturnDialog}
        openReturnReceiptHandler={openReturnReceiptHandler}
      />
      <ButtonDarkStyled
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
        disabled={!userPermissions?.includes('affiliate_dashboard_receipt')}
      >
        <Receipt />
        <span style={{ marginLeft: '.5rem' }}>Receipt</span>
      </ButtonDarkStyled>
      <Popper
        style={{ zIndex: '100' }}
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled onClick={openSalesReceiptHandler}>
                    Sales
                  </MenuItemStyled>
                  {
                    saleReturns.length > 0 && (
                    <MenuItemStyled onClick={openReturnReceiptHandler}>
                      Returns
                    </MenuItemStyled>
                    )
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

ReceiptPopper.propTypes = {
  row: PropTypes.isRequired
};

export default ReceiptPopper;
