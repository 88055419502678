import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  CardGridContainer, CardHeader, HeaderText,
  HeaderIcon, CardContentGrid, ContentHeader,
  ContentMeta, FooterMeta, SubContentMeta,
  SubContentIcon, SubContentText, ContainerWrapper
} from './affiliateCard.styles';
import currencyFormatter from '../../utils/currencyFormatter';
import GrossOrderIcon from '../../assets/images/order/Gross Order Value Icon.png';

export default function SupplierCard({
  id, logo,
  name, location,
  grossOrder,
  handleOnClickCard,
  cardSelected,
  tax
}) {
  const navigate = useNavigate();
  const role = localStorage.getItem('oga_user_role');
  return (
    <CardGridContainer
      elevation={2}
      selected={cardSelected}
      onClick={() => handleOnClickCard(navigate, id)}
    >
      <CardHeader container item>
        <HeaderIcon src={logo} alt="supplier logo" />
        <HeaderText>
          {`af${id}`}
        </HeaderText>
      </CardHeader>

      <CardContentGrid>
        <ContentHeader>
          {name}
        </ContentHeader>

        <FooterMeta>
          <ContentMeta>
            {location}
          </ContentMeta>
          <ContainerWrapper>
            <SubContentMeta>
              <SubContentIcon src={GrossOrderIcon} />
              <SubContentText>
                {currencyFormatter(grossOrder)}
              </SubContentText>
            </SubContentMeta>
            { role === 'oga-pharmacy-admin'
              ? <SubContentText>{`Tax: ${tax}%`}</SubContentText>
              : ''}
          </ContainerWrapper>
        </FooterMeta>

      </CardContentGrid>
    </CardGridContainer>
  );
}

SupplierCard.propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  grossOrder: PropTypes.number.isRequired,
  handleOnClickCard: PropTypes.func,
  cardSelected: PropTypes.bool,
  tax: PropTypes.string.isRequired
};
SupplierCard.defaultProps = {
  handleOnClickCard: (navigate, id) => navigate(`/affiliates/${id}/details`),
  cardSelected: false
};
