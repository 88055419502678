import React, { useState } from 'react';
import { Grid, Hidden } from '@mui/material';
import Affiliates from './affiliate/affiliate';
import MainContent from '../customComponents/mainContent';
import {
  OrderGridContainer, TitleHeader
} from './affiliatesContainer.styles';

const ManageAffiliates = () => (
  <MainContent>
    <OrderGridContainer container>
      <Hidden mdDown><TitleHeader>Manage Affiliates</TitleHeader></Hidden>

      <Grid item container xs={12}>
        <Affiliates />
      </Grid>
    </OrderGridContainer>
  </MainContent>
);

export default ManageAffiliates;
