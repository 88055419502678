import React from 'react';
import PropTypes from 'prop-types';
import {
  TabsGrid, UnderlineGrid, Typo, TotalsGrid
} from './filter.styles';

const Filter = ({
  tabVal, handleTabChange, messagesTotal, archivesTotal
}) => {
  const notificationTabs = [
    {
      label: 'Messages', value: 'all', width: '10%', totals: messagesTotal
    },
    {
      label: 'Archives', value: 'archive', width: '10%', totals: archivesTotal
    },
  ];

  return (
    <TabsGrid container item>
      {notificationTabs.map(({
        label, value, width, totals
      }) => (
        <UnderlineGrid
          key={value}
          item
          container
          onClick={() => handleTabChange(value)}
          active={value === tabVal}
          width={width}
        >
          <Typo active={value === tabVal}>
            {label}
            {totals ? <TotalsGrid>{totals}</TotalsGrid> : ''}
          </Typo>
        </UnderlineGrid>
      ))}
      <UnderlineGrid width="80%" style={{ padding: '10.5px 0', cursor: 'auto' }}>
        <Typo />
      </UnderlineGrid>
    </TabsGrid>
  );
};

Filter.propTypes = {
  tabVal: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  messagesTotal: PropTypes.string,
  archivesTotal: PropTypes.string
};

Filter.defaultProps = {
  messagesTotal: 0,
  archivesTotal: 0
};

export default Filter;
