import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  ClickAwayListener, Fade, List, ListItem, ListItemText, Grid, Typography
} from '@mui/material';
import toast from 'react-hot-toast';
import { SWITCH_STORE_MUTATION } from '../../mutations/auth';
import { CPopper, PaperWrapper } from './switchPopper.styles';

const SwitchPopper = ({
  switchGridEl, setSwitchGridEl, businessSiblings, siblingRefetch
}) => {
  const open = Boolean(switchGridEl);
  const [switchStore] = useMutation(SWITCH_STORE_MUTATION);

  const handleSwitch = (id) => {
    switchStore({
      variables: { businessId: id }
    }).then(({ data }) => {
      const {
        switchStore: { message, business }
      } = data ?? {};
      localStorage.setItem('ogarx_business_id', business?.id);
      toast.success(message);
      setSwitchGridEl(null);
      siblingRefetch();
    })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <CPopper
      open={open}
      anchorEl={switchGridEl}
      transition
      disablePortal
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <PaperWrapper>
            <ClickAwayListener onClickAway={() => setSwitchGridEl(null)}>
              <List style={{ width: '100%', padding: 0 }}>
                {businessSiblings.map(({ id, name }) => (
                  <ListItem
                    disableGutters
                    dense
                    button
                    style={{ padding: '.5rem 1.5rem' }}
                    key={id}
                    onClick={() => handleSwitch(id)}
                  >
                    <ListItemText
                      primary={(
                        <Grid container item>
                          <Typography
                            variant="caption"
                            noWrap
                            style={{ fontSize: '1rem', color: '#606060' }}
                          >
                            {name}
                          </Typography>
                        </Grid>
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </ClickAwayListener>
          </PaperWrapper>
        </Fade>
      )}
    </CPopper>
  );
};

SwitchPopper.propTypes = {
  switchGridEl: PropTypes.shape(Object),
  setSwitchGridEl: PropTypes.func.isRequired,
  siblingRefetch: PropTypes.func.isRequired,
  businessSiblings: PropTypes.shape(Array),
};

SwitchPopper.defaultProps = {
  switchGridEl: {},
  businessSiblings: [],
};

export default SwitchPopper;
