import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import moment from 'moment';
import DashboardInfo from './dashboardInfo';
import MainContent from '../../customComponents/mainContent';
import { IconsGridContainer } from '../../affiliates/affiliates.styles';
import { useStateValue } from '../../../providers/stateProvider';
import CustomSearchField from '../../shared/CustomSearchField';
import CustomButton from '../../customComponents/customButton';
import {
  BarChart, MoneyStack, HandMoney, CashTiles, BankExchange, CardTimer, CreditCard
} from '../../../assets/svgs';
import StatisticsComponent from './statisticsComponent';
import { GET_SALES_DASHBOARD_SUMMARY } from '../../../queries/sales';
import ToggleBusiness from '../../shared/busnesss';
import SalesFilterPopper from './salesFilterPopper';
import {
  GridContainer, PerformanceTab, WrapperPaper, HeaderText,
  SearchGrid, FilterText
} from './dashboard.styles';
import CustomExportButton from '../../downloads/exportModule/exportComponent';

const DashboardContainer = () => {
  const initialState = {
    filterOption: '',
    selectedOptionFilter: new Map(),
  };

  const [state, setState] = useState(initialState);
  const [searchText, setSearchText] = useState('');
  const [refreshCount, setRefreshCount] = useState(0);
  const [filterButton, setFilterButton] = useState(null);
  const [filterToggle, setFilterToggle] = useState(false);

  const [{
    business: {
      dashboardState: { startDate, endDate }
    }
  }, dispatch] = Object.values(useStateValue());

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const variable = {
    dateFrom: moment(startDate).format('YYYY-M-DD'),
    dateTo: moment(endDate).format('YYYY-M-DD')
  };

  const handleFilterButtonClick = (event) => {
    setFilterButton(filterButton ? null : event.currentTarget);
  };

  const { filterOption, selectedOptionFilter } = state;

  const handleOptionToggle = (option) => {
    const filterSelectedOption = [];
    setFilterToggle(true);
    if (option === 'With return') {
      selectedOptionFilter.delete('All');
    } else {
      selectedOptionFilter.clear();
    }
    if (selectedOptionFilter.get(option)) {
      selectedOptionFilter.delete(option);
    } else {
      selectedOptionFilter.set(option, option);
    }
    selectedOptionFilter.forEach((prov) => filterSelectedOption.push(prov));
    setState({ ...state, selectedOptionFilter, filterOption: filterSelectedOption[0] });
  };

  const statistics = [{
    id: 1,
    icon: BarChart,
    title: 'No of Sales',
    size: 4,
    value: 'totalSales'
  }, {
    id: 2,
    icon: MoneyStack,
    title: 'Cash Sales',
    size: 4,
    value: 'salesInCash',
    type: 'amount'
  }, {
    id: 3,
    icon: HandMoney,
    title: 'Total Excess Change',
    size: 4,
    value: 'totalExcessChange',
    type: 'amount'
  }, {
    id: 4,
    icon: CashTiles,
    title: 'Card Sales',
    size: 3,
    value: 'salesInCard',
    type: 'amount'
  }, {
    id: 5,
    icon: BankExchange,
    title: 'Credit Sales',
    size: 3,
    value: 'salesInCredit',
    type: 'amount'
  }, {
    id: 6,
    icon: CardTimer,
    title: 'Bank Transfer Sales',
    size: 3,
    value: 'salesInBankTransfer',
    type: 'amount'
  }, {
    id: 7,
    icon: CreditCard,
    title: 'Total Sale Amount',
    size: 3,
    value: 'totalSaleAmount',
    type: 'amount'
  }];
  return (
    <MainContent>
      <Grid item container xs>
        <GridContainer container>
          <StatisticsComponent
            allowChainManager
            statistics={statistics}
            querySchema={GET_SALES_DASHBOARD_SUMMARY}
            filterOption={filterOption}
            queryName="salesDashboardSummary"
            primaryTitle="Sales History"
            secondaryTitle="View sales record"
            backRoute="affiliate-dashboard"
          />
          <WrapperPaper>
            <PerformanceTab container alignItems="center">
              <Grid item>
                <HeaderText>Sales Performance</HeaderText>
              </Grid>
              <IconsGridContainer item container md={6}>
                <CustomExportButton
                  title="Download CSV"
                  color="tertiary"
                  fileName="sales_history"
                  name="Sales Report"
                  variable={variable}
                />
                {/* <StyledCustomButton type="tertiary" onClick={handleDownloadCSV}>
                  Download CSV
                </StyledCustomButton> */}
              </IconsGridContainer>
              <ToggleBusiness />
              <SearchGrid item container justifyContent="space-between">
                <Grid item container style={{ width: '85vw' }}>
                  <CustomSearchField
                    name="search"
                    value={searchText}
                    fullWidth
                    placeholder="Search"
                    handleChange={(e) => setSearchText(e?.target?.value)}
                  />
                </Grid>
                <CustomButton
                  type="secondary"
                  style={{ width: '6.5rem', height: '2.95rem' }}
                  onClick={handleFilterButtonClick}
                >
                  <FilterList style={{ marginRight: '.4rem' }} />
                  <FilterText>Filter</FilterText>
                </CustomButton>
              </SearchGrid>
            </PerformanceTab>
            <DashboardInfo
              searchText={searchText}
              filterOption={filterOption}
              refreshCount={refreshCount}
              handleRefreshCount={() => setRefreshCount(refreshCount + 1)}
            />

            <SalesFilterPopper
              filterButton={filterButton}
              handleOptionToggle={handleOptionToggle}
              handleTriggerButtonClick={handleFilterButtonClick}
              selectedOptionFilter={selectedOptionFilter}
              filterToggle={filterToggle}
            />
          </WrapperPaper>
        </GridContainer>
      </Grid>
    </MainContent>
  );
};

export default DashboardContainer;
