import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Hidden, Grid, Paper, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Slide
} from '@mui/material';
import toast from 'react-hot-toast';
import {
  Clear as ClearIcon, Check as CheckIcon
} from '@mui/icons-material';
import moment from 'moment';

import {
  CartGridContainer,
  DialogActionButtonText,
  ProductColumnHeaderTitle,
  ProductColumnHeaderTitleW,
  BackArrowIconContainer,
  PageTitleText,
  PageTitleSubText,
  CartItemCountContainer,
  CartItemCountText,
  ProductColumnHeaders,
  SupplierCardMainWrapper,
  PageTitleContainer,
  CartGridContainerHeader,
  DialogCancelButton,
  DialogOkButton,
  DeliveryAddressCardHeaderTitle,
  DeliveryAddressCardHeaderSubTitle,
  ChangeLocationButton,
  ChangeLocationActions,
  LocationTextField,
  ChangeLocationContainer,
  KeyboardArrowLeft,
  OrderButton,
  OrderButtonText,
} from '../../../cart/cart.styles';

import {
  SupplierDetailCard,
  SupplierDetailCardWrapper,
  SupplierDetailCardHeader,
  OrderDeliveryNotesText
} from './styles';

import {
  GET_SINGLE_SUPPLIER_ORDER
} from '../../../../queries/orders';

import {
  UPDATE_ORDER_META_MUTATION
} from '../../../../mutations/cart';

import InvoiceDropdown from './invoiceDownloadDropdown';

import MainContent from '../../../customComponents/mainContent';
import GoToTop from '../../../customComponents/scrollToTop';

import SupplierOrderDetailProductItem from './product-item';

import PaymentSummary from '../../../shared/paymentSummaryCard/PaymentSummary';

import { JSONParse } from '../../../../utils/json';
import { Product } from '../../../../providers/reducers/product/classes/Product';
import DeliveryInfoCard from '../../../shared/deliveryInfoCard/deliveryInfoCard';
import OrderInfoCard from '../../../shared/orderInfoCard/orderInfoCard';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialState = {
  isErp: false,
  business: null,
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  totalPromo: 0,
  showCancelOrderDialog: false,
  showPlaceOrderDialog: false,
  editNote: false,
  note: '',
  orderId: null,
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: 'Nigeria',
  openInvoiceModal: false,
  anchorEl: null,
};

const SupplierOrderDetailIndex = () => {
  const [state, setState] = useState(initialState);

  const params = useParams();
  const navigate = useNavigate();

  const {
    business, businessContact, deliveryDate, dateCreated, supplier, cart, totalPromo, showCancelOrderDialog,
    showPlaceOrderDialog, editNote, note, isErp, openInvoiceModal,
    anchorEl, orderId
  } = state;

  const [updateOrderMeta] = useMutation(UPDATE_ORDER_META_MUTATION);

  const retrieveProducts = (orderProdSet) => {
    let products = [];
    let _totalPromo = 0;
    products = orderProdSet.map((p) => {
      const {
        name,
        quantity,
        unitCost,
        quantityRecieved,
        meta: orderProductMeta,
        supplierOrder: {
          supplier: {
            name: supplierName,
            settings
          }
        },
        product: {
          id,
          meta,
          business: {
            id: productBusinessId
          }
        }
      } = p;
      const supplierSettings = JSON.parse(settings.replace(/'/g, '"').replace('None', '"N/A"'));
      const {
        supposed_amount: supposedAmount,
        amount_paid: amountPaid,
        supposed_unit_cost: supposedUnitCost,
        unit_cost_paid: unitCostPaid,
        market_rpp: marketRpp,
      } = JSONParse(orderProductMeta);
      _totalPromo += (supposedUnitCost - unitCostPaid) * quantity;
      return {
        // make the platform discovery route path independent as string can change. This works for now but you should use the platform property in the user context
        ...new Product(p.product, isErp ? 'erp' : 'marketplace'),
        id,
        name,
        quantity,
        quantityRecieved,
        unitCost,
        supplier: supplierName,
        supplierPaymentTerm: supplierSettings.payment_term,
        meta: JSON.parse(meta),
        supposedAmount,
        amountPaid,
        supposedUnitCost,
        unitCostPaid,
        marketRpp,
        productBusinessId
      };
    });

    setState((s) => ({ ...s, cart: products, totalPromo: _totalPromo }));
  };

  const { data: supplierData } = useQuery(GET_SINGLE_SUPPLIER_ORDER, {
    variables: {
      id: params.id
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const _isErp = params.redirectTo === 'manual-order-dashboard' ? 'erp' : 'marketplace';
    if (supplierData && supplierData.supplierOrder) {
      const {
        supplierOrder: {
          deliveryDate: _deliveryDate, dateCreated: _dateCreated,
          order: { id, meta, business: _business },
          orderproductSet, supplier: _supplier
        }
      } = supplierData;

      const orderMeta = JSONParse(meta.replace(/'/g, '"'));
      const businessContactMeta = JSONParse(_business.contacts.replace(/'/g, '"').replace('None', '"Lagos"'));
      const {
        addressLine1, addressLine2, city, note: _note
      } = orderMeta;

      setState((s) => ({
        ...s,
        orderId: id,
        note: _note,
        business: _business,
        businessContact: businessContactMeta,
        supplier: _supplier,
        deliveryDate: _deliveryDate ? moment(_deliveryDate) : null,
        dateCreated: moment(_dateCreated).format('DD/MM/YYYY'),
        addressLine1,
        addressLine2,
        city,
        isErp: _isErp
      }));

      retrieveProducts(orderproductSet);
    }
  }, [supplierData, params]);

  const handleEditNoteClicked = () => {
    setState((s) => ({ ...s, editNote: true }));
  };

  const handleOptionClick = () => {
    setState((s) => ({ ...s, openInvoiceModal: false }));
  };

  const handleEditNote = () => {
    if (note) {
      const keyValues = [{ key: 'note', value: note }];
      const mutationVariables = {
        keyValues, orderId, isManualOrder: false
      };

      updateOrderMeta({
        variables: mutationVariables
      }).then(({ data }) => {
        const {
          updateOrderMeta: { order: { meta } }
        } = data;
        const orderMeta = JSONParse(meta.replace(/'/g, '"'));
        const { note: orderNote } = orderMeta;

        toast.success('Note updated.');
        setState((s) => ({ ...s, editNote: false, note: orderNote }));
      }).catch((err) => {
        toast.error(err?.message);
      });
    } else {
      toast.error('Note cannot be empty.');
    }
  };

  const orderTotal = cart.reduce((sum, obj) => sum + (Number(obj.quantity) * Number(isErp ? obj.orderCost : obj.supposedUnitCost)), 0);

  const groupOrderDiscount = cart.reduce((sum, obj) => sum + (`${obj.productBusinessId}` === '1' ? (Number(obj.quantity) * ((Number(obj.marketRpp) || Number(isErp ? obj.orderCost : obj.supposedUnitCost)) - Number(isErp ? obj.orderCost : obj.supposedUnitCost))) : 0), 0);

  return (
    <MainContent>
      <CartGridContainer>
        <CartGridContainerHeader container>
          <BackArrowIconContainer onClick={() => navigate(`/${params.redirectTo}`)}>
            <KeyboardArrowLeft />
          </BackArrowIconContainer>

          <PageTitleContainer>
            <PageTitleText>Order Detail</PageTitleText>

            <Hidden lgDown>
              <PageTitleSubText>View details of an order sent to a supplier</PageTitleSubText>
            </Hidden>
          </PageTitleContainer>
          <OrderButton onClick={(event) => setState((s) => ({ ...s, openInvoiceModal: true, anchorEl: event.currentTarget }))}>
            <OrderButtonText> Proforma Invoice</OrderButtonText>
          </OrderButton>
        </CartGridContainerHeader>

        <Grid
          container
          spacing={4}
          style={{ marginTop: '42px' }}
        >
          <SupplierDetailCardWrapper item container md={12} lg={6}>
            <OrderInfoCard
              id="ID0D123-SU12"
              business={business}
              dateCreated={dateCreated}
              businessDateDelivered={moment(deliveryDate).format('DD/MM/YYYY')}
              supplier={supplier}
            />
          </SupplierDetailCardWrapper>

          <SupplierDetailCardWrapper item container md={12} lg={6}>
            <SupplierDetailCard>
              <DeliveryInfoCard businessContact={businessContact} />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>

        <CartItemCountContainer>
          <CartItemCountText>
            {cart.length}
            &nbsp;
            {cart.length ? 'Items' : 'Item'}
          </CartItemCountText>
        </CartItemCountContainer>

        <ProductColumnHeaders>
          <ProductColumnHeaderTitleW>
            Product Item
          </ProductColumnHeaderTitleW>
          <ProductColumnHeaderTitle style={{ width: '260px' }}>
            Quantity Ordered
          </ProductColumnHeaderTitle>
          <ProductColumnHeaderTitle style={{ width: '225px' }}>
            Quantity Received
          </ProductColumnHeaderTitle>
          <ProductColumnHeaderTitle style={{ width: '255px' }}>
            Unit Cost
          </ProductColumnHeaderTitle>
          <ProductColumnHeaderTitle>
            Price
          </ProductColumnHeaderTitle>
        </ProductColumnHeaders>

        <Paper
          elevation={2}
          style={{
            marginTop: '25px'
          }}
        >
          {
            cart.map((productItem) => (
              <SupplierOrderDetailProductItem
                product={productItem}
              />
            ))
          }
        </Paper>

        <SupplierCardMainWrapper>
          <Paper elevation={2} />
        </SupplierCardMainWrapper>

        <Grid
          container
          justifyContent="space-between"
          style={{
            marginTop: '42px',
            marginBottom: '50px'
          }}
        >
          <SupplierDetailCardWrapper item container md={12} lg={5}>
            <SupplierDetailCard>
              <SupplierDetailCardHeader>
                <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>

                {
                  editNote ? (
                    <ChangeLocationActions>
                      <ChangeLocationButton
                        role="button"
                        onClick={() => setState((s) => ({ ...s, editNote: false }))}
                      >
                        <ClearIcon />
                      </ChangeLocationButton>

                      <ChangeLocationButton
                        role="button"
                        onClick={() => handleEditNote()}
                      >
                        <CheckIcon />
                      </ChangeLocationButton>
                    </ChangeLocationActions>
                  ) : (
                    <DeliveryAddressCardHeaderSubTitle
                      onClick={() => handleEditNoteClicked()}
                      style={{
                        cursor: 'pointer'
                      }}
                      type="button"
                    >
                      Edit Note
                    </DeliveryAddressCardHeaderSubTitle>
                  )
                }
              </SupplierDetailCardHeader>

              {
                editNote ? (
                  <ChangeLocationContainer>
                    <LocationTextField
                      label="Note"
                      value={note}
                      multiline
                      rows={5}
                      onChange={(event) => setState((s) => ({ ...s, note: event.target.value }))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ backgroundColor: '#f0f0f0' }}
                    />
                  </ChangeLocationContainer>
                ) : (
                  <OrderDeliveryNotesText>{note}</OrderDeliveryNotesText>
                )
              }

            </SupplierDetailCard>
          </SupplierDetailCardWrapper>

          <SupplierDetailCardWrapper item container md={12} lg={5}>
            <SupplierDetailCard>
              <PaymentSummary
                cart={cart}
                orderTotal={orderTotal}
                groupOrderDiscount={groupOrderDiscount}
                grandTotal={orderTotal}
                promo={totalPromo}
              />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>

        <GoToTop />
      </CartGridContainer>

      <Dialog
        open={showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton>
            <DialogActionButtonText>
              OK
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showPlaceOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showPlaceOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Place Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to place the order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showPlaceOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton>
            <DialogActionButtonText>
              OK
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>
      <InvoiceDropdown
        openInvoiceDialog={openInvoiceModal}
        handleOptionClick={handleOptionClick}
        anchorEl={anchorEl}
        orderId={orderId}
      />
    </MainContent>
  );
};

export default SupplierOrderDetailIndex;
