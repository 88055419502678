import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import searchIcon from '../../../assets/images/supplier/Search Icon.png';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderButton, OrderButtonText, PlusIcon, ButtonsGrid,
  SearchbarWrapper, StyledInput, InputButton, SearchImage
} from './titleGrid.styles';
import AddUserDialog from './addUserDialog/addUserDialog';
import { useStateValue } from '../../../providers/stateProvider';

export function TitleGrid({
  state, stateChanger, refetch
}) {
  const [{ user: { parentId, businessId } }] = Object.values(useStateValue());
  const [openModal, setOpenModal] = useState(false);
  const closeDialogHandler = async () => {
    setOpenModal(false);
    await refetch();
  };
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Business
        </PrimaryTitle>
        <SecondaryTitle>
          View and edit your business information
        </SecondaryTitle>
      </TitleTextGridContainer>

      <ButtonsGrid item>
        <SearchbarWrapper>
          <StyledInput
            onChange={(e) => {
              stateChanger({ ...state, searchText: e.target.value });
            }}
            placeholder="Find User By Name"
          />
          <InputButton>
            <SearchImage src={searchIcon} />
          </InputButton>
        </SearchbarWrapper>
        {!parentId && (
        <OrderButton onClick={() => setOpenModal(true)}>
          <Hidden mdDown>
            <PlusIcon />
          </Hidden>
          <OrderButtonText>
            Add User
            {openModal}
          </OrderButtonText>
        </OrderButton>
        )}
        <AddUserDialog
          dialogOpen={openModal}
          closeDialog={closeDialogHandler}
          businessId={businessId}
        />
      </ButtonsGrid>

    </TitleGridContainer>
  );
}

TitleGrid.propTypes = {
  stateChanger: PropTypes.instanceOf(Function),
  refetch: PropTypes.instanceOf(Function).isRequired,
  state: PropTypes.string,
};

TitleGrid.defaultProps = {
  stateChanger: () => 'clicked',
  state: {}
};

export default TitleGrid;
