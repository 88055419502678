import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import MainContent from '../customComponents/mainContent';
import {
  VirtualWrapper, PayButton, PayIcon,
  PayButtonText, HeaderText
} from './virtual.styles';
import Loader from '../shared/loader';
import { JSONParse } from '../../utils/json';
import { SINGLE_ORDER_QUERY } from '../../queries/dispatch';
import payIcon from '../../assets/images/Pay Icon.png';

export default function Virtual({ match }) {
  const { orderId } = match.params;
  const navigate = useNavigate();

  const {
    loading, error, data
  } = useQuery(SINGLE_ORDER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { orderId },
  });
  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const {
    businessOrder: {
      paymentSummary,
      deliveryOutlet: {
        contacts,
        name: outletName
      }
    }
  } = data;
  const {
    primary_email_address: mailAddress,
    address_line_1: addressLine
  } = JSONParse(contacts);

  const {
    total
  } = JSONParse(paymentSummary);

  // run a query to get the order information
  const handlePayment = (response) => {
    const { status } = response;
    if (status === 'success') {
      toast.success('Your payment has been successfully made');
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } else {
      toast.error('Something went wrong!');
    }
  };

  const componentProps = () => (
    {
      email: mailAddress,
      amount: +total * 100,
      metadata: {
        name: outletName,
        address: addressLine
      },
      // publicKey: process.env.PAYSTACK_PUBLIC_KEY,
      publicKey: 'pk_test_2b92e2358864a71efcf5a0c66383b772a51589ce', // for demo purpose
      onSuccess: handlePayment
    }
  );
  return (
    <MainContent>
      <VirtualWrapper>
        <HeaderText>
          Kindly make a payment for your order #
          {orderId}
        </HeaderText>
        <PayButton
          {...componentProps()}
        >
          <PayIcon src={payIcon} />
          <PayButtonText>
            Pay
          </PayButtonText>
        </PayButton>
      </VirtualWrapper>
    </MainContent>
  );
}

Virtual.propTypes = {
  match: PropTypes.instanceOf(Object)
};

Virtual.defaultProps = {
  match: {},
};
