import React from 'react';
import { Grid } from '@mui/material';
import spacemanLogo from '../../assets/images/spacemanLogo.png';
import {
  GridContainer, TopGrid, OgaAdminLogo, ImageContainer, TextContainer, Img,
  HeaderText, InnerText
} from './notfoundContainer.styles';

const NotfoundContainer = () => (
  <GridContainer container item>
    <TopGrid item container>
      <OgaAdminLogo fontSize="3rem" />
    </TopGrid>
    <Grid alignItems="center" item container>
      <ImageContainer xs={12} md={6} lg={6} item container>
        <Img alt="PharmIQ" src={spacemanLogo} />
      </ImageContainer>
      <TextContainer xs={12} md={6} lg={6} item container>
        <HeaderText variant="caption">
          Oops! Site Under Maintenance 🚧
        </HeaderText>
        <InnerText variant="caption">
          We are currently deploying an upgrade to our marketplace. Our team is actively working to deploy this upgrade as swiftly and efficiently as possible. Rest assured, we are doing everything in our power to bring the marketplace back to operating optimally, and we should be back up by next week. We understand the critical role OGApharmacy plays in your operations, and we sincerely apologize for any inconvenience this may cause.
        </InnerText>
        <br />
        <br />
        <InnerText variant="caption">
          We are still standing by to rapidly assist with your procurement needs. While the website is being upgraded, please reach out to your sales representative or our customer service department on tel:+2349055793553 who will be happy to assist you to place orders. We value your partnership and are committed to ensuring a high level of service and reliability. Thank you for your understanding and patience as we work through this challenge.
        </InnerText>
      </TextContainer>
    </Grid>
  </GridContainer>
);

export default NotfoundContainer;
