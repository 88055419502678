import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import {
  RoleGridContainer,
} from './bannersContainer.styles';
import { useStateValue } from '../../providers/stateProvider';
import Banners from './banners/banners';

const BannersContainer = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContent>
      <RoleGridContainer container>
        <Grid item container spacing={2}>
          <Grid item container xs={12}>
            <Banners />
          </Grid>
        </Grid>
      </RoleGridContainer>
    </MainContent>
  );
};

export default BannersContainer;
