import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  Paper,
  TableRow,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainContainer = styled(Grid)`
  background: #F0F0F0;
`;

export const PageTitleText = styled('p')`
  color: #424242;
  font-size: 4rem;
  font-weight: 600;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
  @media (min-width: 992px) {
    font-size: 1.6rem;
  }
`;

export const HeaderWrapper = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  margin-bottom: 20px;
`;


export const TableWrapper = styled(Paper)`
  margin: 2rem;
  margin-bottom: 1.5rem;
  padding-bottom: 5px;
  align-items: center;
  width: 98vw;
  overflow-x: scroll;

  @media(min-width: 992px) {
    margin-bottom: 1rem;
  }
`;

export const TableColumnHeaderTitle = styled('p')`
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 23px;
  padding-bottom: 12px;
  width: 10rem;
  margin: 0px 5px;
  white-space: normal;
  text-align: center;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
  padding: 0 25px;
  border-bottom: 2px solid rgba(224, 224, 224, 1);
`;

export const TableContainer = styled(Paper)`
  padding: 28px;
  margin-top: 1em;
  width: fit-content;
  box-shadow: none;
`;

export const TableColumnRow = styled(TableRow)`
  display: flex;
  margin: 0;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

export const TableColumnRowText = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 23px;
  white-space: normal;
  width: 10rem;
  margin: 0px 5px;
  padding-bottom: 12px;
  text-align: center;
  word-break: break-word;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;
export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #FCED50;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;


export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
  `;

export const MenuButton = styled(IconButton)`
  color: #424242;
  `;

export const FooterGrid = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const DataCountText = styled(Grid)`
  font-size: 2rem;
  color: #4D4F5C;
  margin: 5px 20px;

  @media(min-width: 992px) {
    font-size: 20px;
    margin: .5rem 3rem;
  }
`;

export const DropDownArrow = styled(ArrowDropDown)`
  font-size: 1.2rem;
`;

export const DropUpArrow = styled(ArrowDropUp)`
  font-size: 1.2rem;
`;


