import {
  Grid, Typography, Paper, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContactGrid = styled(Grid)`
  margin-top: 2.5rem;
`;

export const InnerGrid = styled(Grid)`
  border-left: 1px solid #CCCCCC80;
  padding: 18px 0;
  margin-left: 15px;
`;

export const ContactPaper = styled(Paper)`
  border-radius: 8px;

  @media(min-width: 992px) {
    border-radius: 6px;
  }
`;

export const CardWrapper = styled(Grid)`
  position: relative;
  padding-left: 30px;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

export const CardGrid = styled(Grid)`
  padding: 18px 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #CCCCCC;
  border: 0.5px solid #CCCCCC80;
  border-radius: 7px;
  z-index: 5;
`;

export const LightDivider = styled(Divider)`
  color: #CCCCCC80;
`;

export const ShortDivider = styled(Divider)`
  position: absolute;
  top: 50%;
  left: 0;
  width: 33px;
  background-color: #bbbbbb78;
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
`;

export const SubHeaderText = styled(Typography)`
    color: #A3A3A3;
    padding: 18px 20px;

    @media(max-width: 991px) {
      font-size: 1.4rem
    }
  `;

export const BottomContainer = styled(Grid)`
    margin: 8rem 3rem 0;

    @media(min-width: 992px) {
      margin: 4.5rem 3rem 0;
    }
  `;

export const ItemImage = styled('img')`
    width: 12rem;
    position: absolute;
    top: 5px;
    left: 2rem;

    @media(min-width: 992px) {
      width: 8rem;
    }
  `;

export const NormalText = styled(Typography)`
    font-size: 20px;
    margin-bottom: 10px;

    @media(min-width: 992px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  `;

export const NormalText2 = styled(Typography)`
    font-size: 20px;
    margin-bottom: 10px 0;

    @media(min-width: 992px) {
      font-size: 16px;
      margin: 8px 0;
    }
  `;

export const NameText = styled(Typography)`
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: 600;

    @media(min-width: 992px) {
      font-size: 25px;
      margin-bottom: 20px;
    }
  `;

export const LowerGrids = styled(Grid)`
    align-items: center;
    margin-bottom: 10px;
  `;

export const LowerLighterText = styled(Typography)`
    margin: 0 10px;
    color: #A3A3A3;

    @media(max-width: 991px) {
      font-size: 1.7rem;
    }
  `;

export const LowerLighter2Text = styled(Typography)`
    margin-left: 10px;
    margin-right: 26px;
    color: #A3A3A3;

    @media(max-width: 991px) {
      font-size: 1.7rem;
    }
  `;

export const LowerDarkerText = styled(Typography)`
    margin-left: 5px;
    font-weight: 500;

    @media(max-width: 991px) {
      font-size: 1.7rem;
    }
  `;

export const IconImage = styled('img')`
    height: 55px;

    @media(min-width: 992px) {
      height: 30px;
    }
  `;

export const SmallHeading = styled(Typography)`
    color: #A3A3A3;
    font-size: 1rem;

    @media(min-width: 992px) {
      font-size: .75rem;
    }
  `;
