const order = {
  orders: [],
  totalCount: 0,
  expiryCount: 0,
  ordersCount: 0,
  refetch: null,
  addOrderDialogOpen: false,
  orderRow: null,
  ordersStatus: ''
};

export default order;
