import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Paper, useMediaQuery, Hidden } from '@mui/material';
import {
  DetailGrid, DetailContentGrid, ImgLogo, DetailHeader, DetailTypo, QuantityGrid,
  ActionButton, ActionTextField, Content, AddButton, AddCartTypo, CircularProgressLoader,
  ShoppingCartImg, Typo, QuantityContentGrid, InputButtonGroup, ContentGrid, ProductVariantText, DivBullet, ButtonWrapper,
  MinusIcon, PlusIcon
} from './productDetail.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import { Product } from '../../../../providers/reducers/product/classes/Product';
import { UPDATE_ORDER_MUTATION } from '../../../../mutations/orders';
import CartActionTypes from '../../../../providers/reducers/cart/cartTypes';
import cart from '../../../../assets/images/marketOrder/white-cart.png';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { JSONParse } from '../../../../utils/json';

export default function ProductDetail({
  product, clearanceSale
}) {
  const isSmall = useMediaQuery('(max-width:991px)');
  const productToUse = clearanceSale ? product[0]?.product : product;
  const {
    id: productId, brandName, meta, marketplaceVersion,
    mktPrice: actualPrice, packSize, quantityInStock: productQtyInStock, savedPromoValue
  } = new Product(productToUse);
  const quantityInStock = clearanceSale ? product[0]?.quantityInStock : productQtyInStock;
  const { description, image, } = marketplaceVersion;
  const [{
    cart: { isManualOrder },
  }, dispatch] = Object.values(useStateValue());
  const [counter, setCounter] = useState(0);
  const promoVal = Math.round(product[0]?.price - product[0]?.discountedPrice) || savedPromoValue;

  const handleActionButtons = (value) => {
    const sum = counter + value;
    if (sum > quantityInStock) return toast.error('Requested quantity not available');
    setCounter(sum);
  };
  const handleChange = (value) => {
    if (value > quantityInStock) return toast.error('Requested quantity not available');
    setCounter(value);
  };

  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER_MUTATION);
  const handleAlertOpen = (msg) => toast(msg);

  const ogaOrderingForAffiliateKey = 'oga_ordering_for_affiliate';
  const selectedAffiliate = localStorage.getItem(ogaOrderingForAffiliateKey);
  const _selectedAffiliate = selectedAffiliate ? JSON.parse(selectedAffiliate) : null;

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        productId: Number(productId),
        quantity: counter,
        isManualOrder,
        affiliateId: _selectedAffiliate?.id,
        overwriteQuantity: true,
        ...(clearanceSale && { batchId: product[0]?.id })
      }
    })
      .then(({ data: response }) => {
        const { message, totalProductCount } = response?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: totalProductCount
        });
        handleAlertOpen(message);
      });
  };
  const productMolecule = Object.values(JSONParse(productToUse?.molecule?.replace(/'/g, '"'))).toString().replaceAll(',', ', ');
  const splitMolecules = productMolecule.split(', ');
  const capMolecule = (string) => (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase());
  const splitMoc = splitMolecules.map((itm) => capMolecule(itm));
  let moleculeStr = '';
  splitMoc?.forEach((itm, idx) => {
    if (idx === splitMoc.length - 1) {
      moleculeStr += itm;
      return;
    }
    moleculeStr += `${itm}, `;
  });

  return (
    <DetailGrid component={Paper} container>
      <DetailContentGrid item lg={6} md={12}>
        <ImgLogo src={image} alt={brandName} />
        {!clearanceSale ? (
          quantityInStock ? (
            <ProductVariantText status={24}>
              <DivBullet type="24" />
                &nbsp;Available
            </ProductVariantText>
          ) : (
            <ProductVariantText status={72}>
              <DivBullet type="72" />
              &nbsp;
              Out of Stock
            </ProductVariantText>
          )
        ) : (
          <ProductVariantText status="clearanceSale">
            <DivBullet type="clearanceSale" />
                &nbsp;Clearance Sale
          </ProductVariantText>
        )}
      </DetailContentGrid>
      <ContentGrid item lg={6} md={12}>
        <Content>
          <DetailHeader> Molecules </DetailHeader>
          <DetailTypo>
            {moleculeStr || 'N/A'}
          </DetailTypo>
          <DetailHeader> Description </DetailHeader>
          <DetailTypo>
            {description}
          </DetailTypo>
          <DetailHeader> Pack Size </DetailHeader>
          <DetailTypo>
            {packSize}
          </DetailTypo>

          <QuantityGrid xs={12} container justifyContent="space-between">
            <QuantityContentGrid xs={9} lg={4}>
              <DetailHeader>Quantity</DetailHeader>
              <InputButtonGroup size="small" aria-label="small outlined button group">
                <ActionButton
                  onClick={() => handleActionButtons(-1)}
                  disabled={counter === 0}
                >
                  <MinusIcon />
                </ActionButton>
                <ActionTextField
                  InputProps={{ disableUnderline: true, style: { fontSize: isSmall && '2rem' } }}
                  variant="standard"
                  placeholder={0}
                  value={counter || ''}
                  onChange={(e) => handleChange(Number(e.target.value))}
                />
                <ActionButton
                  onClick={() => handleActionButtons(1)}
                >
                  <PlusIcon />
                </ActionButton>
              </InputButtonGroup>
            </QuantityContentGrid>
            <QuantityContentGrid xs={4}>
              <DetailHeader>Price</DetailHeader>
              <Typo>
                {`${currencyFormatter(actualPrice || Math.round(product[0]?.discountedPrice))}`}
              </Typo>
            </QuantityContentGrid>
            <QuantityContentGrid xs={4}>
              <DetailHeader>Savings</DetailHeader>
              <Typo>
                {`${currencyFormatter(promoVal || 0)}`}
              </Typo>
            </QuantityContentGrid>
          </QuantityGrid>
          <Hidden mdDown>
            <AddButton
              fullWidth
              startIcon={<ShoppingCartImg src={cart} alt="cart" />}
              className="joy-add-to-cart"
              variant="outlined"
              disableElevation
              onClick={handleAddToCart}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={24}
                  thickness={4}
                />
              ) : <AddCartTypo> Add to cart </AddCartTypo>}
            </AddButton>
          </Hidden>
          <Hidden mdUp>
            <ButtonWrapper>
              <AddButton
                fullWidth
                startIcon={<ShoppingCartImg alt="cart" />}
                className="joy-add-to-cart"
                variant="outlined"
                disableElevation
                onClick={handleAddToCart}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={24}
                    thickness={4}
                  />
                ) : <AddCartTypo> Add to cart </AddCartTypo>}
              </AddButton>
            </ButtonWrapper>
          </Hidden>
        </Content>
      </ContentGrid>
    </DetailGrid>
  );
}

ProductDetail.propTypes = {
  product: PropTypes.instanceOf(Object),
  clearanceSale: PropTypes.string
};

ProductDetail.defaultProps = {
  product: {},
  clearanceSale: ''
};
