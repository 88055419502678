import {
  Grid, Typography, Paper, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderWrapper = styled(Grid)`
  margin-bottom: 3rem;

  @media(min-width: 992px) {
  }
`;

export const NameGrid = styled(Grid)`

`;

export const ContactGrid = styled(Grid)`
`;

export const CustomerPaper = styled(Paper)`
  position: relative;
  border-radius: 8px;
  padding-bottom: 15px;
  min-height: 22.3rem;

  @media(min-width: 992px) {
    border-radius: 6px;
  }
`;

export const ContactPaper = styled(Paper)`
  border-radius: 8px;
  padding: 18px 30px;
  height: 29rem;

  @media(min-width: 992px) {
    border-radius: 6px;
    height: 22.3rem;
  }
`;

export const SubHeaderText = styled(Typography)`
  color: #A3A3A3;
  padding: 18px 20px;

  @media(max-width: 991px) {
    font-size: 1.4rem
  }
`;

export const ContactHeaderText = styled(Typography)`
  font-weight: 600;
  padding-bottom: 14px;

  @media(max-width: 991px) {
    font-size: 1.6rem
  }
`;

export const LightDivider = styled(Divider)`
  color: #F0F0F0;
`;

export const BottomContainer = styled(Grid)`
  margin: 8rem 3rem 0;

  @media(min-width: 992px) {
    margin: 4.5rem 3rem 0;
  }
`;

export const BottomCustomerContainer = styled(Grid)`
  margin: 3rem 3rem 0;

  @media(min-width: 992px) {
    margin: 1rem 3rem 0;
  }
`;

export const ItemImage = styled('img')`
  width: 12rem;
  position: absolute;
  top: 5px;
  left: 2rem;

  @media(min-width: 992px) {
    width: 8rem;
  }
`;

export const NormalText = styled(Typography)`
  font-size: 20px;
  margin-bottom: 10px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const NormalText2 = styled(Typography)`
  font-size: 20px;
  margin-bottom: 25px;

  @media(min-width: 992px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

export const NameText = styled(Typography)`
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

export const LowerGrids = styled(Grid)`
  align-items: center;
`;

export const LowerLighterText = styled(Typography)`
  margin: 0 10px;
  color: #A3A3A3;

  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const LowerLighter2Text = styled(Typography)`
  margin-left: 10px;
  margin-right: 26px;
  color: #A3A3A3;

  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const LowerDarkerText = styled(Typography)`
  margin-left: 5px;
  font-weight: 500;

  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const IconImage = styled('img')`
  height: 55px;

  @media(min-width: 992px) {
    height: 30px;
  }
`;

export const ContactsGrid = styled(Grid)`
  margin-top: 15px;
`;

export const SmallHeading = styled(Typography)`
  color: #A3A3A3;
  font-size: 1rem;

  @media(min-width: 992px) {
    font-size: .75rem;
  }
`;
