import gql from 'graphql-tag';

export const MAKE_SALE_MUTATION = gql`
  mutation CreateSales(
    $sales: [SalesType]!
  ) {
      createSales(
        sales: $sales
      ) {
        message
        sale{
          id
          status
          total
          discount
          totalToPay
          flag
          receiptNumber
          businessUser {
            business{
            name,
            contacts
            }
            user{
            username,
            profile
            }
          }
          saleItems{
            id
            name
            quantity
            total
          }
          customer{
            id
            name
            loyaltyPoint
          }
        }
        failedSale{
          customerId
          receiptNumber
        }
      }
    }
`;

export const MAKE_RETURN_MUTATION = gql`
  mutation CreateReturn(
    $cart: [ReturnProductQuantityType]!,
    $transactionDate: String!,
    $returnSaleId: Int!,
    $paymentsMade: [PaymentDetailsType]!,
    $version: Int!
  ) {
      createReturn(
        cart: $cart,
        transactionDate: $transactionDate,
        returnSaleId: $returnSaleId,
        paymentsMade: $paymentsMade
        version: $version
      ) {
        message
        sale{
          id
          status
          total
          receiptNumber
          businessUser {
            business{
              name,
              contacts
            }
            user{
              username,
              profile
            }
          }
          saleItems{
            id
            name
            quantity
            total
          }
        }
      }
    }
`;

export const RECONCILE_OUT_OF_STOCK_BATCH = gql`
    mutation reconcileOutOfStockBatch($saleItemBatchId: Int!, $batches: [ReconcileBatchProductQuantityType]!) {
        reconcileOutOfStockBatch(saleItemBatchId: $saleItemBatchId, batches: $batches) {
        message
        }
    }
`;

export const MAKE_SALES_MUTATION = gql`
    mutation CreateSales(
        $sales: [SalesType]!,
    ) {
        createSales(
            sales: $sales,
        ) {
            message
        }
    }
`;

export const SEND_RECEIPT_VIA_MAIL = gql`
    mutation sendReceiptViaMail(
        $receiptNumber: String!,
    ) {
        sendReceiptViaMail(
            receiptNumber: $receiptNumber,
        ) {
            message
        }
    }
`;

export const DELETE_SALE_MUTATION = gql`
    mutation deleteSale(
        $receiptNumber: String!,
    ) {
        deleteSale(
            receiptNumber: $receiptNumber,
        ) {
            message
        }
    }
`;

export const UPDATE_SALES_TARGET_MUTATION = gql`
  mutation updateSaleTarget(
    $daily: Float, $weekly: Float, $monthly: Float,
  ) {
    updateSaleTarget(
      daily: $daily, weekly: $weekly, monthly: $monthly
    ) {
      saleRevenueTarget {
        id
      }
    }
  }
`;
