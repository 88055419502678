import { styled } from '@mui/material/styles';
import { Grid, Typography, IconButton } from '@mui/material';

export const HeaderGrid = styled(Grid)`
  padding: 15px 20px;
  border-bottom: 1px solid #e9e9e9;
`;

export const ContentGrid = styled(Grid)`
  padding: 15px 20px;
`;

export const InputGrid = styled(Grid)`
  padding: 15px 0 0;
`;

export const ActionsGrid = styled(Grid)`
  padding: 15px 20px;
`;
export const DoneButton = styled(IconButton)`
  padding: 6px 25px;
  border-radius: 5px;
  background-color: #FCED50!important;
  font-size: .95rem;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }
`;

export const CancelButton = styled(DoneButton)`
  background-color: #fff!important;
  border: 1px solid black;
  margin-right: 25px;

  &:hover {
    background-color: #fff;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: 22px;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const SmallText = styled(Typography)`
  font-size: 0.85rem
`;
