import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
  Grid, Paper, Hidden, TableRow, TableBody, TableCell,
} from '@mui/material';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
// import CTablePagination from '../customComponents/cTablePagination';
import TablePagination from '../shared/tablePagination';
// import { GET_ORDER_INVOICES } from '../../queries/orders';
import GoToTop from '../customComponents/scrollToTop';
import {
  TableContainerWrapper, TableComponent, HeaderCell, MainTableHead, MainTableRow
} from './invoices.styles';
import { useStateValue } from '../../providers/stateProvider';
import {
  OrderGridContainer,
  FooterGrid, OrderCountText, DivFullWidth
} from '../orders/admin/order.styles';
import paginationShowing from '../../utils/pagination';
import ToggleBusiness from '../shared/busnesss';

const Invoices = ({
  setPageNumber,
  setPageCount,
  handleRowClick,
  currentPageCount,
  page,
  text
}) => {
  const [{
    business: {
      businessId,
      businessUser: businessUserId,
      dashboardState: { startDate, endDate }
    },
  }] = Object.values(useStateValue());

  const variables = {
    dateFrom: startDate,
    dateTo: endDate,
    pageCount: currentPageCount,
    pageNumber: page,
    searchInvoiceId: text
  };
  if (businessId) {
    variables.businesses = [businessId];
  }
  if (businessUserId) {
    variables.businessUserIds = [businessUserId];
  }
  const data = [];
  // const {
  //   data, loading, error
  // } = useQuery(GET_ORDER_INVOICES, {
  //   fetchPolicy: 'no-cache',
  //   variables
  // });

  // if (loading) return <ProductsTableLoader />;
  // if (error) return <div>{error.message}</div>;

  const { orderInvoices, orderInvoicesTotalNumber = 0 } = data;

  const showing = paginationShowing(orderInvoices, orderInvoicesTotalNumber);

  return (
    <OrderGridContainer container style={{ padding: '0 30px' }}>
      {/* <Grid item container justifyContent="space-between">
        <OrderCountText style={{ position: 'relative' }}>
          Showing
          <b>{` ${showing()} - ${showing('end')} `}</b>
          of
          <b>{` ${orderInvoicesTotalNumber} `}</b>
          invoice(s)
        </OrderCountText>
      </Grid> */}
      <ToggleBusiness />
      <TableContainerWrapper>
        <TableComponent aria-label="supplier products table">
          <MainTableHead>
            <TableRow>
              <HeaderCell>Date Received</HeaderCell>
              <HeaderCell>Invoice Number</HeaderCell>
              <HeaderCell>Business</HeaderCell>
              <HeaderCell>Supplier</HeaderCell>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>Invoice Value</HeaderCell>
            </TableRow>
          </MainTableHead>
          <TableBody>
            {orderInvoices?.map((_row) => (
              <MainTableRow onClick={() => handleRowClick(_row.id)} style={{ cursor: 'pointer' }}>
                <TableCell>{moment(_row.dateReceived).format('lll')}</TableCell>
                <TableCell>{_row?.invoiceNumber}</TableCell>
                <TableCell>{_row?.business?.name}</TableCell>
                <TableCell>{_row?.supplier?.name}</TableCell>
                <TableCell>{_row?.status}</TableCell>
                <TableCell>{_row?.total}</TableCell>
              </MainTableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainerWrapper>
      {/* <FooterGrid item container>
        <Hidden smDown>
          <OrderCountText>
            Showing
            <b>{` ${showing()} - ${showing('end')} `}</b>
            of
            <b>{` ${orderInvoicesTotalNumber} `}</b>
            invoice(s)
          </OrderCountText>
        </Hidden>
        <CTablePagination
          rowsPerPageOptions={[20, 50, 100, 150, 200]}
          component="div"
          count={orderInvoicesTotalNumber}
          page={page - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid> */}
      <DivFullWidth>
        {orderInvoicesTotalNumber > 0 && (
          <TablePagination
            total={orderInvoicesTotalNumber}
            pageCount={currentPageCount}
            setPageCount={setPageCount}
            pageNumber={page}
            setPageNumber={setPageNumber}
          />
        )}
      </DivFullWidth>
      {orderInvoices?.length ? <GoToTop /> : ''}
    </OrderGridContainer>
  );
};

Invoices.propTypes = {
  handleRowClick: PropTypes.func.isRequired,
  // handleChangePage: PropTypes.func.isRequired,
  // handleChangeRowsPerPage: PropTypes.func.isRequired,
  setPageCount: PropTypes.isRequired,
  setPageNumber: PropTypes.isRequired,
  page: PropTypes.number.isRequired,
  currentPageCount: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default Invoices;
