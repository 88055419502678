import PromotionsTypes from './promotionsTypes';

const promotionsReducer = (promotions, action) => {
  switch (action.type) {
    case PromotionsTypes.UPDATE_PROMOTIONS:
      return {
        ...promotions,
        ...action.payload
      };
    default:
      return promotions;
  }
};

export default promotionsReducer;
