import React from 'react';
import { Grid } from '@mui/material';
import { ToTopButton, ArrowIcon } from './scrollToTop.styles';

const scrollToTop = () => {
  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid justifyContent="center" item container>
      <ToTopButton onClick={goToTop}>
        <ArrowIcon />
      </ToTopButton>
    </Grid>
  );
};

export default scrollToTop;
