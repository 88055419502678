import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMediaQuery, Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import currencyFormatter from '../../shared/currencyFormatter';
import { UPDATE_PRODUCTS_STATUS_MUTATION } from '../../../mutations/products';
import { UPDATE_ORDER_MUTATION } from '../../../mutations/orders';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { Dots } from '../../../assets/svgs';
import {
  CustomCheckbox, StatusColor, StatusText
} from './returnRow.styles';
import { TableColumnRows, TableColumnRowText } from '../../shared/tableGlobalStyle.styles';
import { Product } from '../../../providers/reducers/product/classes/Product';
import ActionPopper from './actionPopper';
import { useStateValue } from '../../../providers/stateProvider';
import SingleBatchDialog from '../../products/productDetails/singleBatchDialog';

const ReturnRow = ({
  row, selected, handleSelect, refetchData
}) => {
  const [action, setAction] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const isLarge = useMediaQuery('(min-width:992px)');

  const navigate = useNavigate();
  const isSelected = () => selected.indexOf(row.id) !== -1;
  const {
    dateCreated, brandName, quantityInStock, quantityInStockHeld, resolvedPriceInUseValue,
    orderCost, status, supplier
  } = row;

  const price = currencyFormatter(resolvedPriceInUseValue);
  const cost = currencyFormatter(orderCost);
  const [updateOrder] = useMutation(UPDATE_ORDER_MUTATION);
  const [updateProductStatus] = useMutation(UPDATE_PRODUCTS_STATUS_MUTATION);

  const [{
    user: { platform },
    cart: { isManualOrder }
  }, dispatch] = Object.values(useStateValue());
  const isErp = platform === 'erp';

  const getStatus = () => {
    if (status === 'ACTIVE') {
      return (
        <Box style={{ display: 'flex' }}>
          <StatusColor active />
          <StatusText>Enabled</StatusText>
        </Box>
      );
    }
    return (
      <Box style={{ display: 'flex' }}>
        <StatusColor />
        <StatusText>Disabled</StatusText>
      </Box>
    );
  };

  const erpCells = [cost, price, quantityInStock];

  const returnCell = (value) => value || 'N/A';

  const renderBodyCells = () => {
    return erpCells.map((cell) => (
      <TableColumnRowText key={cell}>{isLarge ? returnCell(cell) : ''}</TableColumnRowText>
    ));
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row.id);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const handleAddToCart = (productId) => {
    updateOrder({
      variables: {
        productId,
        quantity: 1,
        isManualOrder: isErp || isManualOrder
      }
    })
      .then(({ data }) => {
        const { message, order } = data?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNTER,
          payload: order?.supplierorderSet
        });
        toast.success(message);
      });
  };

  const updateProductStatusHandler = (type, productId) => {
    updateProductStatus({
      variables: {
        productId,
        status: type ? 'IN_ACTIVE' : 'ACTIVE'
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateProductStatus || {};
        toast.success(message);
        refetchData();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleAction = (event, type, productRow) => {
    if (type === 'add cart') {
      handleAddToCart(+productRow.id);
    } else if (type === 'add batch') {
      setOpenModal(true);
    } else {
      updateProductStatusHandler(type, productRow.id);
    }
    handleOptions(event);
  };

  const renderDate = moment(dateCreated).format('DD/MM/YY (h:mm)');

  return (
    <>
      <TableColumnRows data-testid="erp-uat-product-tb-row" className="erp-uat-product-tb-row">
        <TableColumnRowText data-testid="erp-uat-product-tb-checkbox">{renderCheckbox()}</TableColumnRowText>
        <TableColumnRowText data-testid="erp-uat-product-tb-name">{renderDate}</TableColumnRowText>
        <TableColumnRowText data-testid="erp-uat-product-tb-sku" style={{ cursor: 'pointer' }} onClick={() => navigate(`/products/${row.id}/details`)}>
          {row?.sku}
        </TableColumnRowText>
        <TableColumnRowText data-testid="erp-uat-product-tb-brandName" className="erp-uat-product-tb-brandName" style={{ cursor: 'pointer' }} onClick={() => navigate(`/products/${row.id}/details`)}>
          {brandName}
        </TableColumnRowText>
        <TableColumnRowText data-testid="erp-uat-product-tb-supplier">{ supplier?.name }</TableColumnRowText>
        {renderBodyCells()}
        <TableColumnRowText>{quantityInStockHeld}</TableColumnRowText>
        <TableColumnRowText>{getStatus()}</TableColumnRowText>
        <TableColumnRowText data-testid="erp-uat-action-popper-td" className="erp-uat-action-popper-td">
          <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
          <ActionPopper
            action={action}
            handleAction={handleAction}
            row={row}
            handleClose={handleCloseModal}
          />
        </TableColumnRowText>
      </TableColumnRows>

      <SingleBatchDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        brandName={brandName}
        productId={row?.id}
        refetch={refetchData}
      />
    </>

  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Product),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  selected: []
};

export default ReturnRow;
