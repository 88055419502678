import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { BUSINESS_ROLES_QUERY } from '../../../queries/businesses';
import Navigation from '../shared/navigation/navigation';
import SideNav from '../shared/sideNav/sideNav';
import MainContent from './mainContent/mainContent';
import TitleGrid from './titleGrid';

import {
  TitleGridWrapper, UserContentWrapper,
} from './permissionsContainer.styles';

const PermissionsContainer = () => {
  const pageCount = sessionStorage.getItem('rowsPerPage') || 6;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    searchText: '',
  };
  const [state, setState] = useState(initialState);

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: event.target.value });
  };
  const {
    loading, data, refetch
  } = useQuery(BUSINESS_ROLES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      businessId: Number(localStorage.getItem('ogarx_business_id')),
      pageCount: state.currentPageCount,
      pageNumber: state.currentPage,
    },
  });

  // const roles = [
  //   { id: 10, name: 'admin', permissions: ['CAN_DELETE_SALE', 'CAN_MAKE_SALE', 'CAN_DEFINE_PERMISSIONS'] },
  //   { id: 11, name: 'chain-manager', permissions: ['CAN_DELETE_SALE', 'CAN_MAKE_SALE', 'CAN_DEFINE_PERMISSIONS'] },
  //   { id: 12, name: 'affiliate-admin', permissions: ['CAN_DELETE_SALE', 'CAN_MAKE_SALE'] },
  //   { id: 13, name: 'oga-pharmacy-admin', permissions: ['CAN_DELETE_SALE', 'CAN_MAKE_SALE'] },
  //   { id: 14, name: 'store-manager', permissions: ['CAN_DELETE_SALE', 'CAN_MAKE_SALE'] }
  // ];

  return (
    <>
      <Navigation />
      <TitleGridWrapper>
        <TitleGrid
          state={state}
          stateChanger={setState}
          refetch={refetch}
        />
      </TitleGridWrapper>
      <UserContentWrapper>
        <SideNav />
        {!loading && data && data.businessRoles
          ? (
            <MainContent
              businessRoles={data.businessRoles}
              totalNumber={data.businessRolesTotalNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              currentPage={state.currentPage}
              currentPageCount={state.currentPageCount}
            />
          ) : ''}
      </UserContentWrapper>
    </>
  );
};

export default PermissionsContainer;
