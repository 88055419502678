import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import dayjs from 'dayjs';
import FormTemplate from '../shared/fieldTemplate/template';
import {
  CaretUpIcon, CaretDownIcon, DeleteIcon, UpdateIcon
} from '../../assets/svgs';
import {
  CustomInputBase, RemoveButton,
  MobileContainer, DetailText, HeaderText, TextBox, DateText, ContentBox
} from './returnRow.styles';
import { JSONParse } from '../../utils/json';

const ReturnRowMobileView = ({
  row, updateHandler, reportLoading, setDisableButton
}) => {
  const {
    batch: { batchNo, expiryDate }, product: { brandName, meta }, id, quantityInStock
  } = row;

  const [expdate, setExpdate] = useState(expiryDate);
  const [counter, setCounter] = useState(quantityInStock);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setCounter(quantityInStock);
  }, [quantityInStock]);

  const {
    pack_size: packSize
  } = JSONParse(meta) || {};

  const handleDateChange = (e) => {
    const newExpDate = e?.target?.value;
    setExpdate(newExpDate);
  };

  const expireDateField = {
    type: 'date', name: 'expiryDate', value: dayjs(expdate).format('YYYY-MM-DD'), onChange: handleDateChange,
    style: { width: '75%', fontSize: '2rem', height: '5rem' }
  };

  const handleValChange = (value) => {
    setTrigger(true);
    setDisableButton(false);
    if (value < 0) setCounter(0);
    else setCounter(value);
  };

  const handleActionButtons = (value) => {
    setTrigger(true);
    setDisableButton(true);
    if (value === -1 && counter <= 0) setCounter(0);
    else setCounter(counter + value);
  };

  const triggerUpdateHandler = (type) => {
    if (type === 'UPDATE') {
      setDisableButton(false);
    }
    setTrigger(false);
    updateHandler(row, counter, type);
  };

  const maxLength = 80;

  return (
    <MobileContainer data-testid="routeBtn" key={row.id} style={{ marginTop: '2rem' }}>
      <DateText container>
        <DetailText>
          {batchNo || 'N/A'}
        </DetailText>
      </DateText>
      <hr />
      <ContentBox>
        <TextBox container>
          <HeaderText xs={6}>Product Name</HeaderText>
          <DetailText xs={6}>
            {
                brandName.length > maxLength
                  ? `${brandName.substring(0, maxLength)}...`
                  : brandName
              }
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Pack Size</HeaderText>
          <DetailText xs={6}>
            {packSize}
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>Expiry Date</HeaderText>
          <DetailText xs={6}>
            <FormTemplate fields={expireDateField} />
          </DetailText>
        </TextBox>
        <TextBox container>
          <HeaderText xs={6}>QTY Counted</HeaderText>
          <DetailText xs={6}>
            <CustomInputBase
              type="number"
              placeholder="0"
              min={1}
              size="medium"
              error={'quantity Counter' || false}
              helperText={'quantity Counter' && 'Please add a value'}
              required
              style={{
                height: '5rem', fontSize: '2rem', width: '70%', border: '1px solid #78AADA'
              }}
              value={counter || ''}
              onChange={(e) => handleValChange(Number(e.target.value))}
              endAdornment={(
                <InputAdornment position="end">
                  <Grid item container direction="column">
                    <CaretUpIcon
                      style={{ width: '1.5rem', height: '1.5rem', cursor: 'pointer' }}
                      onClick={() => handleActionButtons(1)}
                    />
                    <CaretDownIcon
                      style={{ width: '1.5rem', height: '1.5rem', cursor: 'pointer' }}
                      onClick={() => handleActionButtons(-1)}
                    />
                  </Grid>
                </InputAdornment>
            )}
            />
          </DetailText>
        </TextBox>
        <RemoveButton
          style={{
            marginLeft: '1rem', fontSize: '2rem', paddingTop: '1rem', marginBottom: '2.5rem'
          }}
          onClick={() => triggerUpdateHandler('UPDATE')}
          disabled={reportLoading || counter === 0 || !trigger}
        >
          <UpdateIcon style={{ fontSize: '4rem', marginRight: '4px' }} />
          Update
        </RemoveButton>
        <RemoveButton
          style={{
            marginLeft: '26rem', fontSize: '2rem', paddingTop: '1rem', marginBottom: '2.5rem'
          }}
          onClick={() => triggerUpdateHandler('REMOVE')}
        >
          <DeleteIcon style={{ fontSize: '4rem', marginRight: '4px' }} />
          Remove
        </RemoveButton>
      </ContentBox>
    </MobileContainer>
  );
};

ReturnRowMobileView.propTypes = {
  row: PropTypes.instanceOf(Object),
  updateHandler: PropTypes.func.isRequired,
  reportLoading: PropTypes.func.isRequired,
  setDisableButton: PropTypes.func.isRequired,
};

ReturnRowMobileView.defaultProps = {
  row: {},
};

export default ReturnRowMobileView;
