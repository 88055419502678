import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemTypo, ItemWrapper
} from './downloadPopper.styles';

export default function DownloadPopper({
  open, handleClose, data, buttonAction
}) {
  const customValue = [];

  if (data) {
    data?.forEach((val) => {
      const splitVal = val.split(/[-_]+/);
      const stringVal = `${splitVal[1]}_${splitVal[2]}`;
      customValue.push(stringVal);
    });
  }

  return (
    <RenderPopper
      open={open}
      anchorEl={open}
      popperPlacement="bottom"
      onClickAway={handleClose}
      styles={{ width: '200px', height: '107px', borderRadius: '8px' }}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      {data?.length > 0 ? (
        <GridWrapper container item>
          {data?.map((item, index) => (
            <GridContainer container item key={item} onClick={() => { buttonAction(item); handleClose(); }}>
              <ItemWrapper>
                <ItemTypo variant="subtitle1">
                  {`# ${customValue[index]}`}
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
          ))}
        </GridWrapper>
      )
        : (
          <GridWrapper container item>
            <GridContainer container item>
              <ItemWrapper>
                <ItemTypo>
                  List is empty
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
          </GridWrapper>
        )}
    </RenderPopper>
  );
}

DownloadPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
  buttonAction: PropTypes.func.isRequired
};

DownloadPopper.defaultProps = {
  data: []
};
