import React from 'react';
import { Dialog, Slide, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';

import {
  GridWrapper, GridContainer, ItemWrapper, ItemTypo,
} from './reportExportModel.styles';

import { getAxios } from '../axios';
import { OgaToken } from '../graphql/token';
import { RenderPopper } from './utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ReportExportModel = ({
  handleClose, endpoint, params, dotsButtonEl, setDotsButtonEl
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const open = Boolean(dotsButtonEl);
  const downloadData = async (url, fileType, end) => {
    const serverUrl = `${OgaToken.SERVER_LINK}`;
    const token = localStorage.getItem('oga_rest_token');
    getAxios().get(`${serverUrl}${url}/${endpoint}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      params: {
        ...params,
      },

      paramsSerializer: (_params) => qs.stringify(_params, { arrayFormat: 'repeat' }),
      responseType: 'blob',
    })
      .then((res) => {
        const name = `${endpoint}-${moment().format('DD/MMM/YYYY')}-${moment().format('h:mm')}`;
        const filename = `${name}.${end}`;
        const blob = new Blob([res.data], { type: fileType });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log('err :>> ', err);
      });
  };

  const handleCsvData = () => {
    downloadData('download_data_export_csv', 'text/csv', 'csv');
  };

  const handlePdfData = () => {
    downloadData('download_data_export_pdf', 'application/pdf', 'pdf');
  };

  const options = [
    { name: 'Export to CSV', action: handleCsvData },
    { name: 'Download to PDF', action: handlePdfData }
  ];

  return (
    <>
      <RenderPopper
        open={open}
        anchorEl={dotsButtonEl}
        popperPlacement="bottom"
        onClickAway={() => setDotsButtonEl(null)}
        reportModel
        styles={{ width: isSmall ? '15rem' : '12rem' }}
        marginLeft="-1.5rem !important"
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
      >
        <GridWrapper container item>
          {options.map(({ name, action }) => (
            <GridContainer
              key={name}
              container
              item
              onClick={action}
            >
              <ItemWrapper>
                <ItemTypo variant="subtitle1" data-testid={name.replace(/ /g, '_')} style={{ textTransform: 'capitalize' }}>
                  {name}
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
          ))}
        </GridWrapper>
      </RenderPopper>
      {/* <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="sm"
      >
        <div>
          <GridWrapper container item onClick={handleCsvData}>
            <GridContainer container item>
              <ItemWrapper>
                <ItemTypo variant="subtitle1">
                  Export to CSV
                </ItemTypo>
              </ItemWrapper>
            </GridContainer>
          </GridWrapper>
        </div>

        <div>
          <GridWrapper container item onClick={handlePdfData}>
            <GridContainer container item>
              <ItemWrapper>
                <ItemTypo variant="subtitle1">Download as PDF</ItemTypo>
              </ItemWrapper>
            </GridContainer>
          </GridWrapper>
        </div>
      </Dialog> */}
    </>
  );
};

ReportExportModel.propTypes = {
  handleClose: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
  params: PropTypes.instanceOf(Object),
  dotsButtonEl: PropTypes.shape(Object),
  setDotsButtonEl: PropTypes.func.isRequired,
};

ReportExportModel.defaultProps = {
  params: {},
  dotsButtonEl: {},
};

export default ReportExportModel;
