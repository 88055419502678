import {
  Grid, Typography, Icon, IconButton, Slider
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import Cropper from 'react-easy-crop';
import { styled } from '@mui/material/styles';
import { ZoomIn, ZoomOut } from '../../../assets/svgs';

export const MainWrapper = styled(Grid)`
  position: relative;
`;

export const HeaderWrapper = styled(Grid)`
  margin: 5px 5px;
`;

export const HeaderText = styled(Typography)`
  font-size: 12px;
  color: #235A91;
  @media(max-width: 991px) {
    font-size: 30px;
  }
`;

export const CropContainer = styled('div')`
  position: relative;
  width: 50%;
  height: 30vh;
  background: #fff;

  @media(max-width: 991px) {
    width: 100%;
    height: 30vh;
  }
`;

export const ControlsContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;

  @media(max-width: 991px) {
   margin-bottom: 30px;
  }
`;

export const CustomIconButton = styled(IconButton)`
  padding: 0;
`;

export const CustomIcon = styled(Icon)`
  color: #424242;
  font-size: 13px;
`;

export const CheckIcon = styled(Icon)`
  color: #424242;
  font-size: 18px;
`;

export const CheckButton = styled(IconButton)`
  cursor: pointer;
  color: #A3A3A3;
  background: #FCED50;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  top: 8vh;
  right: 18vw;
  padding: 5px;

  &:hover {
    background: #E2D444;
  }
`;

export const ChooseAnother = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ChooseAnotherImg = styled('img')`
  width: .5rem;
`;

export const ChooseAnotherText = styled(Typography)`
  text-decoration: underline;
  font-size: 0.65rem;
  color: #7B7B7B;
  margin-left: 5px;
`;

export const ZoomScrollbar = styled('div')`
  flex: 1;
  height: 6px;
  background: none;
  border-radius: 3px;
  margin: 0 8px;
  margin-top: -22px
`;

export const ZoomSlider = styled(Slider)`
  && {
    color: #EDF6FF;
    height: 6px;
    padding: 0;
    margin: 0;
  }

  && .MuiSlider-thumb {
    color: #235A91;
    width: 14px;
    height: 14px;
    margin-top: 0px;
    margin-left: -7px;
    @media(max-width: 991px) {
      width: 30px;
      height: 30px;
    }
  }

  && .MuiSlider-track {
    height: 6px;
    border-radius: 3px;

    @media(max-width: 991px) {
      width: 30px;
      height: 30px;
      border-radius: 10px;

    }
  }

  && .MuiSlider-rail {
    height: 6px;
    border-radius: 3px;

    @media(max-width: 991px) {
      width: 30px;
      height: 30px;
      border-radius: 10px;

    }
  }
`;

export const PlusButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  @media(max-width: 991px) {
    width: 50px;
    height: 50px;
  }
`;

export const MinusButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: 5px;

  @media(max-width: 991px) {
    width: 50px;
    height: 50px;
  }
`;

export const PlusIcon = styled(Add)`
  color: #3f51b5;
  width: 20px;
  height: 20px;
`;

export const MinusIcon = styled(Remove)`
  color: #3f51b5;
  width: 20px;
  height: 20px;
`;

export const ActionContainer = styled(Grid)`
  margin: 30px 0;
  justify-content: center;


`;

export const CustomCropper = styled(Cropper)`
  && .reactEasyCrop_CropArea__before,
  && .reactEasyCrop_CropArea__after {
    background: red;
  }
`;

export const ImageWrapper = styled(Grid)`
  justify-content: center;
  background: #EDF6FF;
  width: 100%;
`;

export const ZooomInIcon = styled(ZoomIn)`
&.MuiSvgIcon-root {
  width: 3.5rem;
  height: 3.5rem;
}

@media (min-width: 992px) {
  &.MuiSvgIcon-root {
    width: 1.5rem;
    height: 1.5rem;
  }
}
`;

export const ZooomOutIcon = styled(ZoomOut)`
&.MuiSvgIcon-root {
  width: 3.5rem;
  height: 3.5rem;
}

@media (min-width: 992px) {
  &.MuiSvgIcon-root {
    width: 1.5rem;
    height: 1.5rem;
  }
}
`;
