import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDarkStyled, GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './buttonsPopper.style';
import { RenderPopper } from '../../utils/utils';

export default function OrderButtonsPopper({ actionHandler, autoLoading }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen(!open);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonDarkStyled
        className="erp-uat-create-new-order"
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
        data-testid="erp-uat-create-new-order"
      >
        Create Purchase Order
      </ButtonDarkStyled>
      <RenderPopper
        open={open}
        anchorEl={anchorRef.current}
        popperPlacement="bottom"
        onClickAway={handleClose}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
      >
        <GridWrapper container item>
          <GridContainer
            container
            item
            className="erp-uat-create-individually"
            onClick={() => { actionHandler('create-individually'); setOpen(false); }}
            data-testid="erp-uat-create-individually"
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Create Individually
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
          <GridContainer
            container
            item
            className="erp-uat-create-automatic-order"
            disabled={autoLoading}
            onClick={() => { actionHandler('automatic_order'); setOpen(false); }}
            data-testid="erp-uat-create-automatic-order"
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                { autoLoading ? 'loading...' : 'Create Automatically' }
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </RenderPopper>
    </>
  );
}

OrderButtonsPopper.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  autoLoading: PropTypes.bool
};

OrderButtonsPopper.defaultProps = {
  autoLoading: false
};
