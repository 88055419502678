import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Hidden
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PaymentSummaryGrandTotalRow, PaymentSummaryRow, PaymentSummaryRowBoldTitle, PayNowButton,
  PaymentSummaryRowTitle, PaymentSummaryCardHeader, PaymentSummaryCardHeaderTitle, ContinueButton,
  TipTool, ButtonWrapper
} from './paymentSummary.styles';
import currencyFormatter from '../../shared/currencyFormatter';
import ServiceFeePopper from './serviceFeePopper';

const PaymentSummary = ({
  cart, orderTotal, serviceFee, deliveryFee, updateLoading, grandTotal, promo,
  placeLoading, platform, handleDirectPlaceOrder
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [action, setAction] = useState(null);

  const handleOpenTip = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const totalPromo = promo;
  const isPrevRoute = state?.prevLocation?.startsWith('/new-order');
  const otherRoute = platform === 'erp' ? '/inventory' : '/new-order';
  const route = isPrevRoute ? -1 : otherRoute;

  return (
    <>
      <PaymentSummaryCardHeader>
        <PaymentSummaryCardHeaderTitle>Payment Summary</PaymentSummaryCardHeaderTitle>
      </PaymentSummaryCardHeader>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Order Total
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(orderTotal)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Delivery Fee
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(deliveryFee)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Service Fee
          <TipTool tabIndex={0} style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={(event) => handleOpenTip(event)}>ⓘ</TipTool>
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(serviceFee)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryGrandTotalRow>
        <PaymentSummaryRowBoldTitle
          style={{ color: '#000' }}
        >
          Grand Total
        </PaymentSummaryRowBoldTitle>
        <PaymentSummaryRowBoldTitle style={{ color: '#000' }}>
          {`₦ ${currencyFormatter(grandTotal - totalPromo)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryGrandTotalRow>
      <Hidden mdDown>
        <PayNowButton
          variant="contained"
          fullWidth
          onClick={() => handleDirectPlaceOrder()}
          disabled={placeLoading || updateLoading || !cart.length}
          className="mpAdmin-uat-cartPage-placeOrder"
        >
          Place Order
        </PayNowButton>
        <ContinueButton
          fullWidth
          onClick={() => navigate(route)}
          className="mpAdmin-uat-cartPage-continueShopping"
        >
          Continue Shopping
        </ContinueButton>
      </Hidden>

      <Hidden mdUp>
        <ButtonWrapper>
          <PayNowButton
            variant="contained"
            fullWidth
            onClick={() => handleDirectPlaceOrder()}
            disabled={placeLoading || updateLoading || !cart.length}
            className="mpAdmin-uat-cartPage-placeOrder"
          >
            Place Order
            &nbsp;
            {`(₦ ${currencyFormatter(grandTotal - totalPromo)})`}
          </PayNowButton>
          <ContinueButton
            fullWidth
            onClick={() => navigate(route)}
            className="mpAdmin-uat-cartPage-continueShopping"
          >
            Continue Shopping
          </ContinueButton>
        </ButtonWrapper>
      </Hidden>

      <ServiceFeePopper action={action} handleOpenTip={handleOpenTip} />
    </>
  );
};

PaymentSummary.propTypes = {
  cart: PropTypes.instanceOf(Array),
  updateLoading: PropTypes.bool.isRequired,
  handleDirectPlaceOrder: PropTypes.func.isRequired,
  orderTotal: PropTypes.number,
  serviceFee: PropTypes.number,
  deliveryFee: PropTypes.number,
  grandTotal: PropTypes.number,
  promo: PropTypes.number,
  placeLoading: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired,
};

PaymentSummary.defaultProps = {
  cart: [],
  orderTotal: 0,
  serviceFee: 0,
  deliveryFee: 0,
  grandTotal: 0,
  promo: 0
};

export default PaymentSummary;
