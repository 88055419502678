import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import { InputAdornment } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import {
  AntTabs, StyledTab
} from '../../orders/admin/order.styles';
import TabPanel from '../../shared/tabPanel';

import { SUPPLIER_QUERY } from '../../../queries/suppliers';
import SupplierProducts from './supplierProducts';
import SupplierHeader from './supplierHeader';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import EditIcon from '../../../assets/images/Edit Icon.png';
import SearchIcon from '../../../assets/images/Search Icon.png';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderSubHeader, ProductItemsTotal, ViewCartButton, ViewCartButtonText,
  TextWrapper, HeaderIcon, MenuButton, SearchImage, SearchContainer, SearchTextField,
  SearchButton, ProductItemsContainer, PayButton, PayButtonText, PayIcon, KeyboardArrowLeft
} from './supplierDetailsContainer.styles';

import IndividualSupplierDialog from '../../suppliers/individual/individualSupplierDialog';
import SupplierActionTypes from '../../../providers/reducers/supplier/supplierTypes';
import { parseSupplierFields, cherryPickOrder } from '../../shared/utils';

import AffiliatePaymentDialog from '../../affiliates/affiliatesDetails/affiliatePaymentDialog';
import payIcon from '../../../assets/images/Pay Icon.png';

import { useStateValue } from '../../../providers/stateProvider';
import SupplierPayment from './supplierPayment';

const SupplierDetailsContainer = () => {
  const initialState = {
    searchText: '',
    currentPage: 1,
    currentPageCount: 20,
  };

  const [state, setState] = useState(initialState);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [value, setValue] = useState(0);

  const {
    searchText, currentPage
  } = state;
  const { id } = useParams();
  const navigate = useNavigate();

  const [{
    supplier: { supplierProductsCount, addSupplierDialogOpen }
  }, dispatch] = Object.values(useStateValue());
  const localBusinessId = localStorage.getItem('ogarx_business_id');
  const role = localStorage.getItem('oga_user_role');

  const openSupplierEditDialog = (supplier) => {
    dispatch({
      type: SupplierActionTypes.UPDATE_SUPPLIER,
      payload: {
        supplier,
        addSupplierDialogOpen: true
      }
    });
  };

  const updateSupplierState = (refetch) => {
    dispatch({
      type: SupplierActionTypes.UPDATE_SUPPLIER,
      payload: {
        supplierDetailRefetch: refetch
      }
    });
  };

  const toggleSupplierEditDialog = () => {
    dispatch({ type: SupplierActionTypes.UPDATE_SUPPLIER, payload: { addSupplierDialogOpen: false } });
  };

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderSearchField = () => (
    <SearchContainer container item xs={12} md={4}>
      <SearchTextField
        variant="outlined"
        label=""
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search product item"
        fullWidth
        InputProps={{
          endAdornment:
  <InputAdornment position="end">
    <SearchButton
      aria-label="search for product"
      edge="end"
      disabled
    >
      <SearchImage alt="search icon" src={SearchIcon} />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const {
    loading, error, data, refetch
  } = useQuery(SUPPLIER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const handleSelectedOrder = () => {
    const orders = cherryPickOrder(data);
    setSelectedOrder(orders);
    setOpenPaymentModal(true);
  };

  useEffect(() => {
    if (data && data.supplier) {
      updateSupplierState(refetch);
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  const {
    supplierId, name, logo, dateCreated, address, terms, orderVolume, orderValue,
    mobile, email, businessId
  } = parseSupplierFields(data?.supplier || {});
  const isOgaAdmin = role === 'oga-pharmacy-admin';
  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <MenuButton onClick={() => navigate('/suppliers')}>
              <KeyboardArrowLeft />
            </MenuButton>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                Supplier Detail
              </PrimaryTitle>
              <SecondaryTitle>
                View details of a specified supplier
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
          <Grid item xs={6} container justifyContent="flex-end">
            { isOgaAdmin && (
            <PayButton item style={{ marginRight: 30 }} onClick={() => handleSelectedOrder()}>
              <Hidden smDown>
                <PayIcon src={payIcon} />
              </Hidden>
              <PayButtonText>
                Add Payment
              </PayButtonText>
            </PayButton>
            )}

            { parseInt(businessId) === parseInt(localBusinessId) && (
            <ViewCartButton
              item
              onClick={() => openSupplierEditDialog(data?.supplier || {})}
            >
                <Hidden smDown>
                  <HeaderIcon alt="cart icon" src={EditIcon} />
                </Hidden>
                <ViewCartButtonText>
                  Edit
                </ViewCartButtonText>
            </ViewCartButton>
            )}

          </Grid>
        </TitleGridContainer>
        <SupplierHeader
          id={supplierId}
          name={name}
          dateCreated={dateCreated}
          logo={logo}
          address={address}
          terms={terms}
          orderVolume={orderVolume}
          orderValue={orderValue}
          mobile={mobile}
          email={email}
        />
        <AppBar elevation={0} position="static">
          <AntTabs value={value} onChange={handleChange} aria-label="Ogarx tabs">
            { isOgaAdmin && <StyledTab label="Payments" /> }
            <StyledTab label={`Products (${supplierProductsCount})`} />
          </AntTabs>
        </AppBar>
        { isOgaAdmin && (
        <TabPanel value={value} index={0} style={{ padding: 0 }}>
          <SupplierPayment />
        </TabPanel>
        ) }

        <TabPanel value={value} index={isOgaAdmin ? 1 : 0}>
          <Hidden mdUp>
            {renderSearchField()}
          </Hidden>
          <OrderSubHeader container item>
            <ProductItemsContainer container item xs={12} md={2} xl={2}>
              <ProductItemsTotal>
                {`${supplierProductsCount} Product Item(s)`}
              </ProductItemsTotal>
            </ProductItemsContainer>
            <Hidden smDown>
              {renderSearchField()}
            </Hidden>
          </OrderSubHeader>
          <SupplierProducts
            state={state}
            supplierId={supplierId}
            handleChangePage={handleChangePage}
            page={currentPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSearch={handleSearch}
          />
        </TabPanel>
      </OrderGridContainer>

      <IndividualSupplierDialog
        dialogOpen={addSupplierDialogOpen}
        closeDialog={toggleSupplierEditDialog}
      />
      <AffiliatePaymentDialog
        open={openPaymentModal}
        orders={selectedOrder}
        supplierId={id}
        handleCloseModal={() => setOpenPaymentModal(false)}
      />
    </MainContent>
  );
};

export default SupplierDetailsContainer;
