import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Grid, DialogContent, Slide, InputAdornment
} from '@mui/material';
import { Mutation } from '@apollo/client/react/components';
import toast from 'react-hot-toast';

import { InputSelectField, DateSelectField } from './inputFields';
import {
  DialogWrapperContainer, DialogHeaderContainer, DialogHeaderText, DialogFooterContainer,
  DialogCancelButton, DialogActionButtonText, DialogDoneButton, FormGrid, InputTextField,
  CircularProgressLoader, DialogHeaderSubText
} from './affiliatePaymentDialog.styles';
import { MANUAL_PAYMENT } from '../../../mutations/orders';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AffiliatePaymentDialog = ({
  open, orders, affiliateId, supplierId, handleCloseModal
}) => {
  const date = new Date();
  const defaultPaymentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const initialState = {
    amount: '',
    via: '',
    description: '',
    paymentType: '',
    paymentDate: defaultPaymentDate
  };
  const [state, setState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    const { amount, via } = state;
    if (!amount || !via) setFormCompleted(false);
    else setFormCompleted(true);
  }, [state]);

  const handleSubmitPayment = (manualPayment) => {
    setFormCompleted(false);
    const makePayment = (variables, amount, order) => {
      const params = { ...variables };
      if (order) {
        params.amount = amount;
        params.orders = order;
      }
      manualPayment({
        variables: order ? params : { ...variables, amount }
      }).then((response) => {
        const { message } = response?.data?.manualPayment || {};
        toast.success(message);
      }).then(() => handleCloseModal())
        .then(() => window.location.reload())
        .catch((err) => {
          toast.error(err?.message);
        })
        .finally(() => setLoading(false));
    };

    const {
      amount, via, description, paymentDate, paymentType
    } = state;
    const variables = {
      via,
      paymentType,
      paymentDate,
      description,
      affiliateId,
      supplierId,
    };
    if (!formCompleted) toast.error('Please check input fields.');
    else {
      setLoading(true);
      const { ordersWithIncompletePayment, ordersWithoutPayment } = orders;
      const sanitizedAmount = amount.replace(/[ ,]/g, '');
      const balance = +sanitizedAmount;

      // make credit payment if there are no existing orders
      const totalOrders = [...ordersWithIncompletePayment, ...ordersWithoutPayment];
      if (!totalOrders.length) {
        return makePayment({
          ...variables, paymentType: 'credit'
        }, balance);
      }
      makePayment(variables, balance, totalOrders.map((order) => ({ supplierOrderId: Number(order.id) })));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const returnTextField = (field) => {
    const { name: fieldName, label, type } = field;
    const value = state[fieldName];
    if (type === 'date') {
      return (
        <DateSelectField
          field={field}
          value={value}
          handleChange={handleChange}
        />
      );
    }
    if (type === 'select') {
      return (
        <InputSelectField
          field={field}
          value={value}
          handleChange={handleChange}
        />
      );
    }
    return (
      <InputTextField
        fullWidth
        variant="filled"
        size="small"
        label={label}
        value={value}
        type={type}
        name={fieldName}
        onChange={handleChange}
        InputProps={{
          startAdornment: fieldName === 'amount' && <InputAdornment position="start">₦</InputAdornment>
        }}
      />
    );
  };

  const topFormFields = [
    { name: 'paymentDate', label: 'Payment Date', type: 'date' },
    { name: 'amount', label: 'Amount Received', type: 'text' }
  ];

  const middleFormFields = [
    {
      name: 'via', label: 'Sent via', type: 'select',
      options: ['Bank transfer', 'Cash', 'Card', 'Cheque']
    },
    {
      name: 'paymentType', label: 'Payment Type', type: 'select',
      options: ['Invoice']
    }
  ];

  const bottomFormFields = [
    { name: 'description', label: 'Description', type: 'text' }
  ];

  return (
    <Mutation mutation={MANUAL_PAYMENT}>
      {(manualPayment) => (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => ('')}
          maxWidth="md"
          fullWidth
        >
          <DialogWrapperContainer>
            <DialogHeaderContainer>
              <Grid item container xs={12} wrap="nowrap" alignItems="center">
                <DialogHeaderText>Add Payment</DialogHeaderText>
              </Grid>
              <DialogHeaderSubText>Confirm payment received from an affiliate</DialogHeaderSubText>
            </DialogHeaderContainer>

            <DialogContent>
              <FormGrid>
                {topFormFields.map((field) => returnTextField(field))}
              </FormGrid>
              <FormGrid>
                {middleFormFields.map((field) => returnTextField(field))}
              </FormGrid>
              <Grid container item>
                {bottomFormFields.map((field) => returnTextField(field))}
              </Grid>
            </DialogContent>

            <DialogFooterContainer container justifyContent="flex-end">
              <DialogCancelButton onClick={handleCloseModal}>
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>
              <DialogDoneButton
                disabled={!formCompleted}
                onClick={() => handleSubmitPayment(manualPayment)}
              >
                {isLoading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    Save
                  </DialogActionButtonText>
                )}
              </DialogDoneButton>
            </DialogFooterContainer>
          </DialogWrapperContainer>
        </Dialog>
      )}
    </Mutation>
  );
};

AffiliatePaymentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  orders: PropTypes.instanceOf(Object).isRequired,
  affiliateId: PropTypes.instanceOf(String).isRequired,
  supplierId: PropTypes.instanceOf(String).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default AffiliatePaymentDialog;
