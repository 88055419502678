import { styled } from '@mui/material/styles';
import { Popper, Paper, Box } from '@mui/material';

export const PopperContainer = styled(Popper)`
  z-index: 15000000;
  min-height: 0rem;
  min-width: 10rem;
  margin-left: ${({ marginLeft }) => (marginLeft)};
  margin-top: ${({ marginTop }) => (marginTop)};
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: ${({ productInfoPopper }) => (productInfoPopper && '6rem')};

  @media(max-width: 991px) {
    border-radius: 40px;
  }
`;

export const Arrow = styled(Box)`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  border-bottom: 15px solid white;
  top: -12px;
  right: 18px;
`;
