import {
  Typography, IconButton, Paper, StepLabel,
  Stepper, StepConnector, LinearProgress, Step, Grid, TableCell, Checkbox, Button
} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

export const LabelButton = styled(IconButton)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 35px;
    background: #424242;
    color: #CCCCCC;
    border-radius: 15px;
    margin-right: 50px;

    &:hover {
      background-color: #423B3D;
    }

    &:disabled {
    border: 3px solid #b2b2b2;
    }

    @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 10px;
    border: 1.5px solid #424242;

    &:disabled {
        border: 1.5px solid #b2b2b2;
    }
    }
`;

export const UpdateButton = styled(IconButton)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 35px;
    // background-color: #FCED50;
    color: #424242;
    border-radius: 15px;
    margin-right: 50px;
    border: 3px solid #424242;

    // &:hover {
    //   background-color: #D2C647;
    // }

    &:disabled {
      border: 3px solid #b2b2b2;
    }

    @media(min-width: 992px) {
      height: 50px;
      padding: 0px 25px;
      border-radius: 10px;
      border: 1.5px solid #424242;

      &:disabled {
        border: 1.5px solid #b2b2b2;
      }
    }
  `;

export const OrderButton = styled(IconButton)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 35px;
    background-color: #FCED50;
    color: #424242;
    border-radius: 0;
    border-radius: 15px;

    &:hover {
      background-color: #D2C647;
    }

    &:disabled {
      color: #848483;
      background-color: #CCCCCB;
    }

    @media(min-width: 992px) {
      height: 50px;
      padding: 0px 25px;
      border-radius: 10px;
    }
  `;

export const SaveChangesText = styled(Typography)`
  font-size: 28px;
  font-weight: 700;

  @media (min-width: 992px) {
    font-size: 14px;
  }
`;

export const PlaceOrderIcon = styled(TelegramIcon)`
    width: 4rem;
    height: 4rem;

    @media(min-width: 992px) {
      width: 1.5rem;
      height: 1.5rem;
    }
  `;

export const UpdateCartIcon = styled(ClearIcon)`
    width: 4rem;
    height: 4rem;

    @media(min-width: 992px) {
      width: 1.5rem;
      height: 1.5rem;
    }
  `;

export const SupplierDetailCard = styled(Paper)`
  width: 100%;
  padding-bottom: 15px;
  height: unset;
  border-radius: 7px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;

  `;

export const DeliveryAddressCardSection = styled(Paper)`
  width: 100%;
  padding: 5px 35px;
  height: 450px;
  border-radius: 15px;

  @media (max-width: 991px) {
    padding: 0 2rem 3rem;
    width: 100%;
    border-radius: 15px;
    height: 560px;
  }
`;

export const DispatchStepper = styled(Stepper)`
  padding: 0rem 0 1rem;
  width: 100%;
  background: none;
  justify-content: space-between;
  &.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    display: none;
}
`;

export const DispatchTypo = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 600;
  color: #303030;

  @media (min-width: 991px) {
    font-size: 1.2rem;
  }
`;

export const DispatchSubTypo = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 300;

  @media (min-width: 991px) {
    font-size: 1rem;
  }
`;

export const StepperIcon = styled('img')`
  width: 35px;
`;

export const StepperConnector = styled(StepConnector)`
  top: 17px;
  left: calc(-50% + 17px);
  right: calc(50% + 17px);

  & > * {
    border-top-style: solid;
    border-top-width: 0.15rem;
    border-color: ${({ completed }) => (completed ? '#FCED50' : '#C7C7C7')};
  }
`;

export const ProgressBar = styled(LinearProgress)`
  width: 100%;
  &.MuiLinearProgress-root {
    height: 12px;
    border-radius: 10px;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: #D9D9D9;
  }
  & .MuiLinearProgress-barColorPrimary {
    background-color: #235A91 !important;
  }
`;

export const StepContainer = styled(Step)`

  &.MuiStep-alternativeLabel {
    flex: 0;
    position: initial;
  }
`;

export const StepWrapper = styled(StepLabel)`
  display: flex;

  & .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    display: none;
  }
`;

export const Label = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const Header = styled(TableCell)`
  padding: 25px 5px;
  font-weight: 700;
  font-size: 1.1rem;
  color: #303030;
  white-space: nowrap;
  :first-child {
    width: 30%;
  }
  @media(min-width: 992px) {
    &:nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6) :nth-child(7), :nth-child(8) {
      width: 10%;
    }
  }
`;

export const CancelHeader = styled(TableCell)`
  padding: 25px 5px;
  font-weight: 600;
  font-size: 1.2rem;
  color: #303030;
  white-space: nowrap;
  :first-child {
    width: 35%;
  }
  @media(min-width: 992px) {
    &:nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6) {
      width: 13%;
    }
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked{
    color: #235A91;
  }
  &.MuiIconButton-colorSecondary{
    color: #AEAEB2;
  }
`;

export const SupplierCardPaper = styled(Paper)`
  margin-top: 45px;
  margin-bottom: 52px;
  padding: 40px 0px;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
  @media (min-width: 992px) {
    margin-top: 40px;
    border-radius: 12px;
    padding: 40px 15px;
  }
`;

export const OverviewFooterText = styled(Typography)`
  font-size: 29px;
  color: #235A91;
  font-weight: 700;
  margin-right: 15px;

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;

export const CancelButton = styled(Button)`
  border: 1px solid #235A91;
  padding: 17px 30px;
  &.MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
}
`;

export const FooterGrid = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
  margin: 10px 30px 35px;

  @media (min-width: 992px) {
    justify-content: space-between;
  }
`;
