import React from 'react';
import { useNavigate } from 'react-router-dom';
import ModelContainer from '../shared/crudModel/modelsContainer';
import { FIND_CATEGORY_QUERY } from '../../queries/categories';
import IndividualCategoryDialog from './individualCategoryDialog';
import CategoryList from './categoryLIst';

function Categories() {
  const navigate = useNavigate();
  return (
    <ModelContainer
      findModelsQuery={FIND_CATEGORY_QUERY}
      modelName="Category"
      modelNamePlural="Category(s)"
      modelTotalNumber="productsTotalNumber"
      modelQueryName="allCategories"
      renderIndividualModel={(addModelDialogOpen, toggleIndividualDialog) => (
        <IndividualCategoryDialog
          dialogOpen={addModelDialogOpen}
          closeDialog={toggleIndividualDialog}
        />
      )}
      loop={false}
      categoriesList
      renderModelCard={(model) => (
        <CategoryList
          id={model.id}
          categories={model}
        />
      )}
      modelCardOnclickAction={(model) => navigate(`/category/${model.id}/details`)}
    />
  );
}

export default Categories;
