import {
  DialogContent,
  Button, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: .75rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 450px;
  height: 450px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  padding-top: 2rem !important;
`;

export const TitleStyled = styled('span')`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #303030;
`;

export const DescStyled = styled('p')`
  font-weight: 400;
  font-size: 12px;
  line-height: 164.2%;
  color: #606060;
  padding: 1.3rem 0 .8rem 0;
`;

export const ButtonDarkStyled = styled(Button)`
  width: 178px;
  height: 48px;
  background: #235A91 !important;
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 12px;
  background: #235A91 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 178px;
  height: 48px;
  border: 1px solid #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #235A91 !important;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #235A91;
  filter: drop-shadow(0px 4px 14px rgba(151, 151, 151, 0.19));
`;

export const FooterStyled = styled('div')`
  display: flex;
  justify-content: space-around;
  gap: 2;
`;
