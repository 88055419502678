import { styled } from '@mui/material/styles';

import {
  Paper,
  Grid
} from '@mui/material';

const sm = '960px';

export const SupplierDetailCard = styled(Paper)`
  width: 90vw;
  border-radius: 7px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 40px;
  min-height: 450px;

  @media(min-width: 991px) {
    width: 95vw;;
    height: 395px;
  }
`;

export const SupplierDetailCardHeader = styled('div')`
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;

  @media(max-width: ${sm}) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const HeaderButton = styled(Grid)`
  display: flex;
  justify-content: space-between;

`;

export const AffiliateDetailWrapper = styled('div')`
  position: absolute;
  top: 53px;
  left: 22px;

  @media(min-width: 1024px) {
    top: 17px;
  }
`;

export const AffiliateDetailLogo = styled('img')`
  width: 85px;
  height: 85px;
  margin-bottom: 10px;

  @media(max-width: ${sm}) {
    width: 50px;
    height: 50px;
  }
`;

export const AffiliateDetailName = styled('p')`
  margin: 0;
  margin-bottom: 20px;
  color: #424242;
  font-size: 2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 25px;
  }
`;

export const SupplierDetailCardWrapper = styled(Grid)`
  @media(max-width: ${sm}) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const OrderDetailIdText = styled('p')`
  color: #A3A3A3;
  font-size: 1.8rem;
  margin: 0;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const OrderDetailInfoWrapper = styled('div')`
  margin-top: 150px;
  padding-left: 20px;

  @media(max-width: ${sm}) {
    margin-top: 80px;
  }
`;

export const OrderDetailInfoText = styled('p')`
  margin: 0;
  margin-bottom: 10px;
  font-size: 2rem;

  @media(min-width: 991px) {
    font-size: 17px;
  }
`;

export const OrderDetailSupplierInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const OrderDetailSupplierInfoLogo = styled('img')`
  width: 35px;
  height: 35px;
  margin-right: 10px;


  @media(max-width: ${sm}) {
    width: 20px;
    height: 20px;
  }
`;

export const OrderDetailSupplierInfoName = styled('p')`
  color: #424242;
  font-size: 1.8rem;
  font-weight: 600;
  height: 18px;

  @media(min-width: 992px) {
    font-size: 17px;
  }
`;

export const SupplierDetailCardActionButton = styled('button')`
  color: #A3A3A3;
  font-size: 18px;
  margin: 0;
  background-color: white;
  border: none;
  text-decoration: underline;

  @media(max-width: ${sm}) {
    font-size: 10px;
  }
`;

export const OrderDeliveryNotesText = styled('p')`
  color: #424242;
  font-size: 20px;

  @media(max-width: ${sm}) {
    font-size: 12px;
  }
`;

export const HeaderTitle = styled('p')`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  white-space: nowrap;

  &:first-child {
    width: 40%;
  }
  &:nth-child(2) {
    width: 16%;
  }
  &:nth-child(3) {
    width: 16%;
  }
  &:nth-child(4) {
    width: 15%;
  }
  &:nth-child(5) {
    width: 13%;
  }
`;
