import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import moment from 'moment';
import {
  DialogActions, DialogContent, Slide, Grid
} from '@mui/material';
import { FilledCustomButton } from '../../customComponents/customButton';
import {
  DialogTitleText, GridWrapper,
  CDialog, CloseIcon, LocationTextField, ProfileTypo, SaveText
} from './addNoteDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const NoteDialog = ({
  dialogOpen, closeDialog, info, editChange, saveChange, note, profileName
}) => {
  const handleSave = () => {
    if (!note) {
      toast.error('Note cannot be empty');
      return;
    }
    saveChange();
    closeDialog();
  };

  const today = new Date();
  const time = moment(today?.created).format('hh:mm A');

  return (
    <CDialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth
      fileslimit={1}
      disableEnforceFocus
      data-testid="supplier-dialog"
    >
      <GridWrapper container item>
        <Grid container style={{ paddingTop: '1.5rem' }}>
          <Grid item xs={8}>
            <DialogTitleText>
              Add Note
            </DialogTitleText>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <CloseIcon onClick={closeDialog} />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2} style={{ padding: '1rem 0rem 1rem 1rem' }}>
            <Grid container xs={12} style={{ marginBottom: '1rem' }}>
              <Grid item xs={8}>
                <ProfileTypo>
                  {profileName ? profileName.first_name : `${info?.profile?.firstName} ${info?.profile?.lastName}`}
                </ProfileTypo>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <ProfileTypo style={{ color: 'rgba(130, 132, 159, 1)' }}>{time}</ProfileTypo>
              </Grid>
            </Grid>
            <LocationTextField
              fullWidth
              variant="standard"
              InputProps={{ disableUnderline: true }}
              multiline
              rows={12}
              onChange={editChange}
              value={note}
            />
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: '0rem 1.5rem 3rem 1.5rem' }}>
          <FilledCustomButton
            onClick={handleSave}
            style={{ width: '100%' }}
          >
            <SaveText>Save Note</SaveText>
          </FilledCustomButton>
        </DialogActions>
      </GridWrapper>
    </CDialog>
  );
};

NoteDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  info: PropTypes.instanceOf(Object),
  editChange: PropTypes.func.isRequired,
  saveChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  profileName: PropTypes.instanceOf(Object)
};

NoteDialog.defaultProps = {
  info: {},
  note: '',
  profileName: {}
};

export default NoteDialog;
