import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';

import {
  CInputBase, Label, HelperText, CircularProgressLoader
} from './customInputBase.styles';

const CustomInputBase = ({
  label, sideLabel, required, error, loading, helperText, createPromotion, ...props
}) => (
  <Grid item container direction="column">
    <Grid item container justifyContent="space-between">
      {label
        ? (
          <Label error={error} createPromotion={createPromotion}>
            {label}
            {' '}
            {createPromotion ? (
              <span style={{ color: '#EE4223', fontSize: '16px' }}>{required && '*'}</span>
            )
              : required && '*' }
          </Label>
        )
        : ''}
      {sideLabel ? <Label style={{ fontSize: '10px' }}>{sideLabel}</Label> : ''}
    </Grid>
    <CInputBase
      className={`mp-uat-${label.replace(/ /g, '-')}`}
      error={error}
      {...props}
      endAdornment={loading ? (
        <InputAdornment position="end">
          <CircularProgressLoader
            disableShrink
            size={18}
            thickness={4}
          />
        </InputAdornment>
      ) : ''}
    />
    {helperText && error ? <HelperText>{helperText}</HelperText> : ''}
  </Grid>
);

CustomInputBase.propTypes = {
  label: PropTypes.string,
  sideLabel: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  helperText: PropTypes.string,
  props: PropTypes.shape(Object),
  createPromotion: PropTypes.bool
};

CustomInputBase.defaultProps = {
  label: '',
  sideLabel: '',
  required: false,
  error: false,
  loading: false,
  helperText: '',
  createPromotion: false,
  props: {}
};

export default CustomInputBase;
