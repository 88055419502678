import { styled } from '@mui/material/styles';
import {
  Typography, Grid, Paper, Button
} from '@mui/material';

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0px 7px;
`;

export const OptionWrapper = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px 4px 7px;
`;

export const ButtonWrapper = styled(Grid)`
  justify-content: flex-end;
  padding: 0px 7px;
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const SubTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  color: #303030;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const Desc = styled(Typography)`
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #606060;
  padding: 3px 0px;
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  padding: 2rem;
`;

export const SaveChanges = styled(Button)`
  width: 170px;
  height: 50px;
  background: #235A91;
  box-shadow: 0px 4px 14px 0px #97979730;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;

  &:hover {
    background: #78AADA;
    color: #235A91;

  }
`;

export const FooterSec = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 3rem 0;
  padding-right: 20px;
`;

export const ProductWrapper = styled('div')`
  width: 100%;
  padding-right: 12px;
  padding-top: 7px;
`;

export const FieldGrid = styled(Grid)`
  padding: 0px 7px;
  padding-right: 20px;
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
    @media(max-width: 991px) {
      & > * {
        width: 100%;
      }
    }
`;

export const ButtonText = styled(Typography)`
    padding-left: 5px;
    font-size: 14px;
  font-weight: 400;
`;
