import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import {
  OrderGridContainer,
} from './downloadsContainer.styles';
import { useStateValue } from '../../providers/stateProvider';
import Downloads from './downloads/downloads';

const DownloadContainer = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  return (
    <MainContent>
      <OrderGridContainer container>
        <Grid item container spacing={2}>
          <Grid item container xs={12}>
            <Downloads />
          </Grid>
        </Grid>
      </OrderGridContainer>
    </MainContent>
  );
};

export default DownloadContainer;
