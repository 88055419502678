import { gql } from '@apollo/client';

export const TICKETS_QUERY = gql`
  query tickets(
    $status: String, $pageCount: Int, $pageNumber: Int, 
  ){
    tickets(
      status: $status
      pageCount: $pageCount
      pageNumber: $pageNumber
    ){
      id
      description
      status
      comment
      dateCreated
      ticketNumber
      lastUpdated
      lastStatus
      createdBy{
        username
      }
    }
    ticketsTotalNumber
  }
`;
