import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import { Grid } from '@mui/material';
import GoToTop from '../../customComponents/scrollToTop';
import CTablePagination from '../../customComponents/cTablePagination';
import {
  OrderGridContainer, StatisticsGridContainer, OrdersTab, ActiveOrderNumber,
  OrderItemsGrid, FooterGrid, OrderCountText, MainContainer, ArrowLeftIcon,
  StatisticsGridWrapper, ArrowButton, ArrowRightIcon
} from './order.styles';
import StatisticCard from '../shared/statisticCard';
import OrderItemCard from '../shared/orderItemCard';
import TitleGrid from '../shared/titleGrid';
import MainContent from '../../customComponents/mainContent';

import currencyFormatter, { valuesFormatter } from '../../../utils/currencyFormatter';
import paginationShowing from '../../../utils/pagination';
import TotalOrderIcon from '../../../assets/images/order/Total Orders Icon.png';
import GrossOrderIcon from '../../../assets/images/order/Gross Order Value Icon.png';
import ActiveIcon from '../../../assets/images/order/Active Orders Icon.png';

import { useStateValue } from '../../../providers/stateProvider';

const Order = ({
  supplierOrders,
  handleChangeRowsPerPage,
  handleChangePage,
  currentPageCount,
  page,
  totalCount,
  onOrderItemCardClicked
}) => {
  const totalsContainer = useRef(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const element = totalsContainer.current;
  const [{ navbar: { openSideDrawer } }, dispatch] = Object.values(useStateValue());

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const totalOrderAmount = supplierOrders.reduce(
    (previous, current) => previous + current.total, 0
  );

  const STATISTICS = [
    {
      id: 1, icon: ActiveIcon, title: 'Active Orders', value: 'N/A'
    },
    {
      id: 2, icon: ActiveIcon, title: 'Overdue Orders', value: 'N/A'
    },
    {
      id: 3, icon: ActiveIcon, title: 'Overdue Payments', value: 'N/A'
    },
    {
      id: 4, icon: TotalOrderIcon, title: 'Total Order Volume', value: ` ${valuesFormatter(totalCount)}`
    },
    {
      id: 5, icon: GrossOrderIcon, title: 'Total Order Value', value: currencyFormatter(totalOrderAmount)
    },
  ];

  const scrollRight = () => {
    const containerWidth = STATISTICS.length * 269;
    const translateX = containerWidth - element.clientWidth;
    element.style.transform = `translateX(-${translateX}px)`;
    setDisabledButton(!disabledButton);
  };

  const scrollLeft = () => {
    element.style.transform = 'translateX(0px)';
    setDisabledButton(!disabledButton);
  };

  const showing = paginationShowing(supplierOrders, currentPageCount);

  return (
    <MainContent>
      <MainContainer item container xs>
        <OrderGridContainer container>
          <TitleGrid />
          <Grid item container xs={12} wrap="nowrap" alignItems="center">
            <ArrowButton
              disabled={!disabledButton}
              onClick={scrollLeft}
              component="span"
              show={openSideDrawer}
            >
              <ArrowLeftIcon />
            </ArrowButton>
            <StatisticsGridWrapper item container>
              <StatisticsGridContainer
                ref={totalsContainer}
                container
                item
                wrap="nowrap"
                justifyContent="space-between"
              >
                {STATISTICS.map((statistic) => (
                  <StatisticCard
                    key={statistic.id}
                    statistic={statistic}
                    admin
                  />
                ))}
              </StatisticsGridContainer>
            </StatisticsGridWrapper>
            <ArrowButton
              disabled={disabledButton}
              onClick={scrollRight}
              component="span"
              show={openSideDrawer}
            >
              <ArrowRightIcon />
            </ArrowButton>
          </Grid>

          <OrdersTab>
            <ActiveOrderNumber>
              {`${totalCount} Orders`}
            </ActiveOrderNumber>
          </OrdersTab>

          <OrderItemsGrid>
            <OrderCountText>
              Showing
              <b>
                {` ${showing()} - ${showing('end')} `}
              </b>
              of
              <b>
                {` ${totalCount} `}
              </b>
              orders
            </OrderCountText>
            {supplierOrders.map((supplierOrder) => {
              const {
                id, dateCreated, name, total, savings, logo,
              } = supplierOrder;
              return (
                <OrderItemCard
                  key={supplierOrder.id}
                  id={id}
                  name={name}
                  dateCreated={dateCreated}
                  total={total}
                  savings={savings}
                  logo={logo}
                  handleOrderItemCardClicked={onOrderItemCardClicked}
                />
              );
            })}
          </OrderItemsGrid>

          <FooterGrid item container>
            <Hidden smDown>
              <OrderCountText>
                Showing
                <b>
                  {` ${showing()} - ${showing('end')} `}
                </b>
                of
                <b>
                  {` ${totalCount} `}
                </b>
                orders
              </OrderCountText>
            </Hidden>
            <CTablePagination
              rowsPerPageOptions={[20, 25]}
              component="div"
              count={totalCount}
              page={page - 1}
              onChangePage={handleChangePage}
              rowsPerPage={currentPageCount}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </FooterGrid>
          {
            (supplierOrders.length)
              ? <GoToTop /> : ''

          }
        </OrderGridContainer>
      </MainContainer>
    </MainContent>
  );
};

Order.propTypes = {
  supplierOrders: PropTypes.instanceOf(Array).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  currentPageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onOrderItemCardClicked: PropTypes.func.isRequired,
};

export default Order;
