import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
} from '@mui/material';
import { ButtonWhiteStyled } from './salesContainer.styles';
import { ButtonDarkStyled, MenuItemStyled } from './actionButtonsPopper.style';
import { useStateValue } from '../../providers/stateProvider';

export default function ActionButtonsPopper({ handleAction, createBatchHandler }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [{
    user: { allowedPermissionsMap: { userPermissions } },
  }] = Object.values(useStateValue());

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonWhiteStyled className="erp-uat-create-batches" data-testid="create-batches" onClick={createBatchHandler} disabled={!userPermissions?.includes('sales_create_batches')}>Create Batches</ButtonWhiteStyled>
      <ButtonDarkStyled
        className="erp-uat-create-product"
        data-testid="create-product"
        onClick={handleToggle}
        ref={anchorRef}
        style={{ cursor: 'pointer' }}
        disabled={!userPermissions?.includes('sales_create_product')}
      >
        Create Product
      </ButtonDarkStyled>
      <Popper
        className="erp-uat-create-product-popper"
        data-testid="popper"
        style={{ zIndex: '100' }}
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItemStyled
                    className="erp-uat-individual-product"
                    data-testid="individual_prod"
                    onClick={() => handleAction('individual_prod', setOpen)}
                  >
                    Create Individual Product
                  </MenuItemStyled>
                  <MenuItemStyled
                    className="erp-uat-bulk-product"
                    data-testid="bulk_prod"
                    onClick={() => handleAction('bulk_prod', setOpen)}
                  >
                    Create Bulk Product
                  </MenuItemStyled>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

ActionButtonsPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  createBatchHandler: PropTypes.func.isRequired,
};
