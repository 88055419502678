import React from 'react';
import PropTypes from 'prop-types';
import TextField from './fields/textField';
import NumberField from './fields/numberField';
import SelectField from './fields/selectField';
import DateField from './fields/dateField';

const FormTemplate = ({ fields }) => {
  const { type } = fields;

  switch (type) {
    case 'text':
      return <TextField fields={fields} />;
    case 'number':
      return <NumberField fields={fields} />;
    case 'select':
      return <SelectField fields={fields} />;
    case 'date':
      return <DateField fields={fields} />;
    default:
      return null;
  }
};
FormTemplate.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired
};
export default FormTemplate;
