import React from 'react';
import {
  Button, DialogActions, DialogContent, DialogContentText,
  Slide, Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import List from '../../../assets/images/List.gif';
import {
  DialogContainer, Desc, Header, ImageWrapper
} from './lowDataDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const LowDataDialog = ({
  open,
  handleClose,
  handleConfirm
}) => (
  <DialogContainer
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="large"
        style={{
          position: 'absolute', right: '1.5rem', cursor: 'pointer', fontSize: '4rem'
        }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '3rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <ImageWrapper marginBottom={4}>
            <img src={List} alt="success" width={330} />
          </ImageWrapper>
          <Header>
            Low Data Mode!
          </Header>
          <Desc>
            Running on a  low speed internet? , we now how a list view on our marketplace, that helps you load products faster.
          </Desc>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <Button
          fullWidth
          onClick={handleConfirm}
          variant="contained"
          style={{
            background: '#235A91',
            color: '#FFF',
            padding: '2rem',
            fontSize: '2.5rem',
            borderRadius: '15px',
            fontWeight: '700',
          }}
          size="large"
        >
          Try it now
        </Button>
      </DialogActions>
    </DialogContent>
  </DialogContainer>
);

LowDataDialog.propTypes = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

LowDataDialog.defaultProps = {
};

export default LowDataDialog;
