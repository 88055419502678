import {
  Dialog, Grid, Typography, InputBase
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const CDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 12px;
    min-height: 30vh;
    max-height: 80vh;
  }
`;

export const DialogWrapper = styled(Grid)`
  width: 50rem;
  padding: 0 1.5rem;
`;

export const DialogHeader = styled(Grid)`
  padding: 1.5rem 0;
  background: #fff;
`;

export const DialogContent = styled(Grid)`
  margin-top: 1rem;
`;

export const DialogActions = styled(Grid)`
  justify-content: flex-end;
  padding: 3rem 0 1.5rem;
  background: #fff;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

export const SubText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  margin-right: 15px;
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  border-radius: 6px;
  font-size: .8rem;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const HeaderText = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: #303030;
  width: 100%;
`;
