import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  NoteDescription, NoteHeader, NoteInfo, NoteInfoContainer, OutletContactLine
} from './dispatch.styles';

const AllNoteRow = ({ note }) => {
  const {
    content, profile, created
  } = note;
  const { lastName, firstName } = profile;
  const returnDate = moment(created).format('HH:mm a');

  return (
    <NoteInfoContainer>
      <NoteHeader container>
        <OutletContactLine>
          {firstName}
          &nbsp;
          {lastName}
        </OutletContactLine>
        <OutletContactLine>{ returnDate }</OutletContactLine>
      </NoteHeader>
      <NoteInfo>
        <NoteDescription>
          { content }
        </NoteDescription>
      </NoteInfo>
    </NoteInfoContainer>
  );
};

AllNoteRow.propTypes = {
  note: PropTypes.instanceOf(Object),
};

AllNoteRow.defaultProps = {
  note: {},
};

export default AllNoteRow;
