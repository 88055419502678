import {
  Grid, Typography, IconButton, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { Download, DownloadIcon } from '../../assets/svgs';

export const PercentageWapper = styled(Box)`
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transition: 500ms;
  left: 0;
  top: 0;
`;

export const SampleCsvWrapper = styled(Grid)`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: flex-start;
  text-align: left;
  position: relative;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5rem 1rem;

  @media (max-width: 991px) {
    margin-bottom: 5rem;
  }
`;
// box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const CsvFileImage = styled('img')`
  height: 42px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 14px;

  @media (max-width: 991px) {
    font-weight: 500;
    font-size: 1.9rem;
  }
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 11px;

  @media (max-width: 991px) {
    font-size: 1.6rem;
  }
`;
export const DownloadIconWrapper = styled(IconButton)`
  background: none;
  text-align: center;
`;
export const DownloadIconImage = styled('img')`
  height: 22px;
`;
export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;

export const DownloadLogo = styled(Download)`
  font-size: 2.5rem;

  @media(max-width: 991px) {
    font-size: 6rem;
  }
`;

export const DownloadIcn = styled(DownloadIcon)`
  font-size: 2rem;

  @media(max-width: 991px) {
    font-size: 4rem;
  }
`;

export const DeleteIcn = styled(DeleteIcon)`
  @media(max-width: 991px) {
    font-size: 4rem;
  }
`;
