import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  TableContainerWrapper, TableComponent, TableHeader,
  MainTableHead, TableGrid, DivFullWidth, MainTableBody, PrimaryTitle
} from './invoiceLogs.styles';
import TablePagination from '../../../shared/tablePagination';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import { addHyphen } from '../../../shared/methods';
import { INVOICE_LOGS_QUERY } from '../../../../queries/orders';

const headers = [
  'S/N', 'Date & Time Downloaded', 'ID', 'Initiated by', 'Action'
];

const InvoiceLogs = ({ orderId }) => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const returnHeaders = () => headers.map((header) => (
    <TableHeader key={header} data-testid={`mp-uat-downloads-${addHyphen(header)}`}>{header}</TableHeader>
  ));

  const variables = {
    orderId,
    pageCount,
    pageNumber,
  };

  const {
    loading, error, data
  } = useQuery(INVOICE_LOGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    realtimeInvoices = [], realtimeInvoicesTotalNumber = 0
  } = data || {};

  const rows = realtimeInvoices;

  return (
    <TableContainerWrapper elevation={0}>
      <PrimaryTitle>Downloaded Invoice</PrimaryTitle>
      <TableGrid item container>
        {loading
          ? <ProductsTableLoader />
          : (
            <TableComponent item container data-testid="mp-uat-downloads-table">
              <MainTableHead item container>
                {returnHeaders()}
              </MainTableHead>
              <MainTableBody item container>
                {rows.map((_row, index) => (
                  <ReturnRow
                    key={_row?.id}
                    row={_row}
                    index={index}
                  />
                ))}
              </MainTableBody>
            </TableComponent>
          )}
      </TableGrid>
      <DivFullWidth>
        {realtimeInvoicesTotalNumber > 0 && (
        <TablePagination
          total={realtimeInvoicesTotalNumber}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
        )}
      </DivFullWidth>
    </TableContainerWrapper>
  );
};

InvoiceLogs.propTypes = {
  orderId: PropTypes.number
};

InvoiceLogs.defaultProps = {
  orderId: 0
};

export default InvoiceLogs;
