import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import GoToTop from '../../customComponents/scrollToTop';
import {
  FooterGrid, ProductCardsContainer, ProductCardGrid, NoResultsText
} from './newOrder.styles';
import ProductCard from './productCard';
import { PRODUCTS_QUERY } from '../../../queries/products';
import CatalogueActionTypes from '../../../providers/reducers/catalogue/catalogueTypes';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import CTablePagination from '../../customComponents/cTablePagination';

import { useStateValue } from '../../../providers/stateProvider';

const NewOrder = ({
  state,
  handleChangePage,
  handleChangeRowsPerPage,
  onlyMarketProducts
}) => {
  const [, dispatch] = Object.values(useStateValue());

  const {
    searchValue, filteredSupplier, productType, therapyIds,
    filteredPromo, currentPage, currentPageCount, priceMin, priceMax
  } = state;

  const handleAlertOpen = (msg) => toast(msg);

  const updateCatalogueState = (products, productsTotalNumber) => {
    dispatch({
      type: CatalogueActionTypes.UPDATE_CATALOGUE,
      payload: {
        products,
        totalCount: productsTotalNumber
      }
    });
  };
  const supplierNames = [];
  filteredSupplier.forEach((value) => {
    supplierNames.push(value);
  });
  const promos = [];
  filteredPromo.forEach((value) => {
    promos.push(value);
  });

  const therapeuticTypeIds = therapyIds.map((item) => item.id);
  const { loading, error, data } = useQuery(PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: searchValue,
      suppliers: supplierNames.length ? supplierNames : [''],
      therapeuticTypeIds,
      productType,
      promos,
      priceMin: priceMin ? parseFloat(priceMin) : false,
      priceMax: priceMax ? parseFloat(priceMax) : false,
      pageCount: currentPageCount,
      onlyMarketProducts,
      pageNumber: currentPage
    }
  });

  const renderSupplierNames = () => supplierNames.map((supplier) => ` ${supplier}`);

  useEffect(() => {
    if (data && data.products) {
      const { products, productsTotalNumber } = data;
      updateCatalogueState(products, productsTotalNumber);
    }
  }, [data]);

  if (loading) return <NewOrdersLoader />;
  if (error) return <div>{error.message}</div>;
  const {
    products = {}, productsTotalNumber = 0
  } = data || {};

  return (
    <>
      <ProductCardsContainer container spacing={2}>
        {products.length
          ? products.map((product) => (
            <ProductCardGrid key={product.id} item xs>
              <ProductCard
                product={product}
                openAlert={handleAlertOpen}
              />
            </ProductCardGrid>
          )) : (
            <NoResultsText>
              No results found matching :
              {searchValue}
              {supplierNames.length ? ` and :${renderSupplierNames()}` : ''}
            </NoResultsText>
          )}
      </ProductCardsContainer>

      <FooterGrid item container className="joy-new-order-pagination">
        <CTablePagination
          rowsPerPageOptions={[20, 50, 100, 150, 200]}
          component="div"
          count={productsTotalNumber}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPage={currentPageCount}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </FooterGrid>
      <GoToTop />
    </>
  );
};

NewOrder.propTypes = {
  state: PropTypes.instanceOf(Object),
  filteredSupplier: PropTypes.instanceOf(Object),
  handleChangePage: PropTypes.func.isRequired,
  currentPageCount: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onlyMarketProducts: PropTypes.bool.isRequired
};

NewOrder.defaultProps = {
  state: {},
  filteredSupplier: {},
  currentPageCount: 0,
};

export default NewOrder;
