import {
  Box,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const NavWrapper = styled(Grid)`
  height: 80px;
  background: #424242;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index:100;
`;

export const LogoWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const PharmiqLogo = styled('img')`
  width: 50px;
  height: 50px;
  margin: 0 35px;
  cursor: pointer;
`;

export const MajorText = styled(Typography)`
  font-size: 25px;
  color: #CCCCCC;
`;

export const NavItemWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const DateTimeTypo = styled(Typography)`
  font-size: 14px;
  color: #A3A3A3;
  letter-spacing: 0px;
  text-align: right;
  padding: 0px 20px;
`;

export const AffiliatesLogo = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 119px;
  height: 135px;
  border-left: 0px;
  border-radius: 0px;

  @media(min-width: 992px) {
    border-left: 1px solid rgba(240, 240, 240, .1);
    height: 80px;
  }
`;

export const LogoIcon = styled(IconButton)`
  padding: 3px;

  &:hover {
    background-color: #FCED50;
  }
`;

export const AffiliateImage = styled('img')`
  width: 30px;
  border-radius: 50%;

  @media(min-width: 992px) {
    width: 45px;
  }
`;
