import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Fade } from '@mui/material';
import CustomButton from '../../customComponents/customButton';
import NotificationDialog from '../../shared/notificationDialog';
import {
  CustomerDialog, DialogWrapper, DialogHeader, DialogBody, DialogActions,
  CloseIcon, TextGrid, Subheader, SmallText, Header
} from './customerDialog.styles';
import { RemoveCircle } from '../../../assets/svgs';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';

import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const SelectCustomerDialog = ({ open, close, editCustomer }) => {
  const [customerAddSuccess, setCustomerAddSuccess] = useState(false);

  const [{
    sale: {
      customerModalData: {
        id, name, email, phoneNo, loyaltyPoint,
      },
    },
  }, dispatch] = Object.values(useStateValue());

  const handleEditCustomer = () => {
    close();
    editCustomer(true);
    // dispatch({ type: ModelActionTypes.CLEAR_MODEL });
    // dispatch({ type: ModelActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const handleRemoveCustomer = () => {
    close();
    dispatch({ type: SaleActionTypes.RESET_CUSTOMER });
  };

  return (
    <CustomerDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      maxWidth="sm"
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <Grid item container xs={6}>
            <Header variant="h5">Customer to Purchase</Header>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={close} />
          </Grid>
        </DialogHeader>
        <DialogBody item container>
          <TextGrid item container xs={7}>
            <Subheader>Customer Name:</Subheader>
            <SmallText>{name}</SmallText>
          </TextGrid>
          <TextGrid item container xs={5}>
            <Subheader>Email Address:</Subheader>
            <SmallText>{email || 'N/A'}</SmallText>
          </TextGrid>
          <TextGrid item container xs={7}>
            <Subheader>Phone Number:</Subheader>
            <SmallText>{phoneNo || 'N/A'}</SmallText>
          </TextGrid>
          <TextGrid item container xs={5}>
            <Subheader>Loyalty Points:</Subheader>
            <SmallText>{loyaltyPoint || 'N/A'}</SmallText>
          </TextGrid>
        </DialogBody>
        <DialogActions item container>
          <CustomButton
            data-testid="removeCustomer"
            type="secondary"
            header
            style={{ width: '11rem', height: '2.7rem', marginRight: '2rem' }}
            onClick={handleRemoveCustomer}
          >
            <RemoveCircle style={{ marginRight: '0.4rem' }} />
            Remove Customer
          </CustomButton>
          <CustomButton
            type="tertiary"
            header
            onClick={handleEditCustomer}
            style={{ width: '11rem', height: '2.7rem' }}
          >
            Edit Customer Details
          </CustomButton>
        </DialogActions>
      </DialogWrapper>
      <NotificationDialog
        openDialog={customerAddSuccess}
        setOpenDialog={setCustomerAddSuccess}
        title="Customer Added Successfully!"
        desc="Hi Pharm, Customer has been successfully added"
      />
    </CustomerDialog>
  );
};

SelectCustomerDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  editCustomer: PropTypes.func.isRequired,
};

SelectCustomerDialog.defaultProps = {
  open: false
};

export default SelectCustomerDialog;
