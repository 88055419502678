import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { BodyCell, MainTableRow } from './returnRow.styles';
import currencyFormatter from '../shared/currencyFormatter';

const ReturnRow = ({ paymentItem }) => {
  const navigate = useNavigate();
  const {
    id, business, creditDays, order, status, total, amountPaid
  } = paymentItem;
  const invoiceValue = currencyFormatter(total);
  const amount = currencyFormatter(amountPaid);

  const handleClick = (type) => {
    if (type === 'affiliateName') return navigate(`/manage-affiliates/${business.id}/details`);
    return navigate(`/orders/${order.id}/detail`);
  };

  return (
    <MainTableRow>
      <BodyCell onClick={() => handleClick('affiliateName')}>
        {business.name}
      </BodyCell>
      <BodyCell onClick={() => handleClick('order')}>
        {order.id}
      </BodyCell>
      <BodyCell>
        {status}
      </BodyCell>
      <BodyCell>
        {invoiceValue}
      </BodyCell>
      <BodyCell>
        {amount}
      </BodyCell>
      <BodyCell>
        {id}
      </BodyCell>
      <BodyCell>
        {creditDays}
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  paymentItem: PropTypes.instanceOf(Object).isRequired
};

export default ReturnRow;
