import {
  Grid, TableRow, Paper,
  IconButton, Typography, TableCell, Dialog, DialogContent
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/material/styles';
import customButton from '../../customComponents/customButton';

export const MainContainer = styled(Grid)`

`;

export const OrderGridContainer = styled(Grid)`
  padding: 30px;
`;
export const WrapperPaper = styled(Paper)`
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 4px rgb(194 194 194 / 9%);
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const FilterContainer = styled(Grid)`
  justify-content: space-between;
`;

export const FilterText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #24292E;
`;

export const SearchGrid = styled(Grid)`
  padding: 1rem 0;
`;
export const BackButtonText = styled(Typography)`
  font-size: 14px;
`;

export const TableWrapper = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;

`;

export const TableColumnHeaderTitle = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #F4F5F7;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 20px;
  }
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #F4F5F7;
  padding: 16px 10px;
  border: 0;
  text-transform: capitalize;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const MainTableRow = styled(TableRow)`
  cursor: pointer;
  & > * {
    border-bottom: unset;
  }
`;

export const TableColumnRows = styled('div')`
  display: flex;
  margin: 0;
  :nth-child(even) {
    background: #F4F5F7;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26px;
  white-space: nowrap;
  color: #606060;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;

  &.MuiIconButton-root {
    padding-left: 0px;
  }
  @media(min-width: 992px) {
    font-size: 2rem;
  }
`;

export const BackArrowIconContainer = styled(IconButton)`
  color: #424242;
`;

export const PrimaryTitle = styled('p')`
  color: #424242;
  font-size: 3rem;
  font-weight: 600;
  margin: 0 0 10px 0;

  @media (min-width: 992px) {
    font-size: 1.6rem;
  }
`;

export const SecondaryTitle = styled('p')`
  margin: 0;
  font-size: 25px;

  @media (min-width: 992px) {
    font-size: 17px;
  }
`;

export const TableSubTitle = styled(Typography)`
  font-size: 16x;
  color: #424242;
  font-weight: 600;
  padding: 20px 0px;

  @media(min-width: 992px) {
    font-size: 11px;
    padding: 10px 0px;
  }
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  padding-top: 7px;
`;

export const TabImage = styled('img')`
  width: 26px;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-bottom: 30px;
`;

export const TableContainer = styled(Grid)`
`;

export const TableColumnHeaders = styled('div')`
  display: flex;
`;

export const TCustomButton = styled(customButton)`
  color: #606060;
  border: 1px solid #dadada;
  box-shadow: 0px 4px 14px rgb(219 219 219 / 19%);
  border-radius: 4px;
  height: 2.5rem;
  width: 100%;

  & .label {
    font-weight: 500;
    font-size: 12px;
  }
`;

export const NoteDialog = styled(Dialog)`
  & .MuiPaper-rounded {
    border-radius: 15px;
    padding-bottom: 20px;
  }
  & .MuiDialog-paperWidthSm {
    width: 600px;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-height: 15rem;
`;

export const CloseStyled = styled('div')`
  text-align: right;
`;

export const NoteHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 992px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const NoteCardHeader = styled(Typography)`
  font-size: 1rem;
  padding-bottom: 10px;
`;

export const NoteSubTitle = styled(Typography)`
  font-size: .9rem;
  font-weight: 400;
  padding-bottom: 10px;
`;

export const NoteTitle = styled('p')`
  margin: 0;
  color: #424242;
  font-weight: 600;
  font-size: 2rem;
  @media (min-width: 992px) {
    font-size: 20px
  }
`;

export const NoteInfo = styled(Grid)`
  padding: 10px;
  background: #F5F5F7;
`;
