import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import * as XLSX from 'xlsx';
import toast from 'react-hot-toast';
import {
  Button, Grid, Hidden, useMediaQuery
} from '@mui/material';
import Moment from 'moment';
import Loader from '../shared/loader';
import { GET_STOCK_REPORT_QUERY } from '../../queries/reports';
import {
  RECONCILE_STOCK_COUNT, CLOSE_REPORT_MUTATION
} from '../../mutations/reports';
import currencyFormatter from '../shared/currencyFormatter';
import CustomButton from '../customComponents/customButton';
import CardLoader from '../shared/cardLoader';
import SmallStatisticCard from '../orders/shared/smallStatisticCard';
import CustomSearchField from '../shared/CustomSearchField';
import StockReportDetailsRow from './stockReportDetailsRow';
import StockReportDetailsRowMobileView from './StockReportDetailsRowMobileView';
import {
  ShowingText, ExportIconStyled, CTableBody, TableHeader
} from './stockReportDetails.styles';
import {
  TitleGridContainer, PrimaryTitle, SecondaryTitle, DetailCard, TCustomButton, PaperWrapper,
  DetailCardText, StatusBullet, CIconButton, KeyboardArrowLeft, StatisticsGrid, SearchGrid,
  TableComponent, MainTableHead, TableGrid, NCustomButton
} from './stockReport.styles';
import EditProductDialog from './editProductDialog';
import { renderStockReportStatus, handleStockReportStatusColor } from './utils';
import MainContent from '../customComponents/mainContent';
import {
  BarChart, VanIcon, StockPurpleIcon, StockRedIcon
} from '../../assets/svgs';
import { JSONParse } from '../../utils/json';
import ReconcileProductBatchDialog from './reconcileProductBatchDialog';
import { useStateValue } from '../../providers/stateProvider';

const headers = [
  'Batch No.', 'Product name', 'Pack size', 'QTY in Stock', 'QTY Counted', 'Difference', 'Reason'
];

const StockReportDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const initialState = {
    prodBatches: [],
    prodTotals: [],
    totCountedProds: 0,
    aggBatches: 0,
    stockValOrderCost: 0,
    stockValSellingPrice: 0
  };
  const [state, setState] = useState(initialState);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openEditProductDialog, setOpenEditProductDialog] = useState(false);
  const [openProductBatchDialog, setOpenProductBatchDialog] = useState(false);
  const [csvPayLoad, setCsvPayLoad] = useState(false);
  const isSmall = useMediaQuery('(max-width: 991px)');

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const {
    totCountedProds,
  } = state;

  const [closeReport] = useMutation(CLOSE_REPORT_MUTATION);

  const { loading, error, data } = useQuery(GET_STOCK_REPORT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: Number(id) }
  });

  useEffect(() => {
    if (data) {
      const { stockReportInventory } = data;
      setCsvPayLoad(stockReportInventory);
      const aggBatches = stockReportInventory.reduce((total, repInv) => total + repInv.quantityInStock, 0);
      const stockValOrderCost = stockReportInventory.reduce((total, repInv) => total + (repInv.quantityInStock * repInv.batch.orderCost), 0);
      const stockValSellingPrice = stockReportInventory.reduce((total, repInv) => total + (
        repInv.quantityInStock * repInv.product.erpVersion.resolvedPriceInUseValue
      ), 0);
      const grouped = _.groupBy(stockReportInventory, (repInv) => repInv.product.id);
      setState((_s) => ({ ..._s, prodBatches: grouped }));
      const values = Object.entries(grouped).map(
        ([key, value]) => value.reduce((totals, val) => {
          const { expectedQuantity, quantityInStock } = val;
          const diff = quantityInStock - expectedQuantity;
          if (!totals[key]) {
            totals[key] = { totExpQty: 0, totQtyInStock: 0, totDiff: 0 };
          }
          totals[key].totExpQty += expectedQuantity;
          totals[key].totQtyInStock += quantityInStock;
          totals[key].totDiff += diff;
          return totals;
        }, {})
      );
      setState((_s) => ({
        ..._s,
        prodTotals: values,
        totCountedProds: values.length,
        aggBatches,
        stockValOrderCost,
        stockValSellingPrice
      }));
    }
  }, [data]);

  const statistics = [
    {
      id: 1, icon: BarChart, title: 'Total Counted Products', value: totCountedProds
    },
    {
      id: 2, icon: VanIcon, title: 'Total Batches Quantity', value: state.aggBatches
    },
    {
      id: 3, icon: StockPurpleIcon, title: 'Stock Valuation (Order Cost)', value: `₦ ${currencyFormatter(state.stockValOrderCost)}`
    },
    {
      id: 4, icon: StockRedIcon, title: 'Stock Valuation (Selling Price)', value: `₦ ${currencyFormatter(state.stockValSellingPrice)}`
    }
  ];

  const handleReconcileDifference = (product, stockReportStatus, diff) => {
    if (stockReportStatus !== 'COMPLETE' && diff !== 0) {
      setSelectedProduct(product);
      setOpenEditProductDialog(true);
    }
  };

  const handleUpdateQuantityInStock = (product, stockReportStatus) => {
    if (stockReportStatus === 'COMPLETE') {
      setSelectedProduct(product);
      setOpenProductBatchDialog(true);
    }
  };

  const handleEditReport = (report) => {
    const inventory = report?.map((row) => {
      const {
        id: inventoryId, product, batch, quantityInStock
      } = row;
      const {
        id: batchId, batchNo, expiryDate
      } = batch;
      const { id: prodId, brandName, meta: productMeta } = product;
      const { pack_size: packSize, manufacturer } = JSONParse(productMeta);
      return {
        id: prodId, inventoryId, brandName, quantityInStock, packSize, manufacturer,
        batchId, batchNo, expiryDate
      };
    });
    localStorage.setItem('report', JSON.stringify(inventory));
    navigate(`/manual-stock-report/${id}`);
  };

  const handleChange = (event, prodBatch) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [prodBatch.id]: {
        ...state[prodBatch.id],
        [name]: value
      }
    });
  };

  const handleCloseReport = (_id) => {
    closeReport({
      variables: { stockReportId: _id }
    })
      .then(({ data: _data }) => {
        const { message } = _data?.closeReport || {};
        if (message === 'Report closed successfully') {
          toast.success(message);
        } else {
          toast.error(message);
        }
      });
  };

  const [reconcileStockCount] = useMutation(RECONCILE_STOCK_COUNT);
  const handleSave = () => {
    const { reason, note } = state[selectedProduct.id];
    reconcileStockCount({
      variables: {
        productId: Number(selectedProduct.id),
        ...(reason && { reason: reason.toLowerCase() }),
        ...(note && { note: note.toLowerCase() })
      }
    })
      .then(({ data: newData }) => {
        const { message } = newData?.reconcileStockCount || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => setOpenEditProductDialog(false));
  };

  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const { stockReportInventory } = data;
  const stockReportStatus = stockReportInventory[0]?.stockReport.status;

  const handleCsv = (payload) => {
    if (payload !== undefined) {
      const collectKeys = [
        'Batch No',
        'Product Id',
        'Product Name',
        'Pack Size',
        'Manufacturer',
        'Supplier',
        'Unit of Measurement',
        'Qty in Stock',
        'Qty Counted',
        'Difference',
        'Expiry Date',
        'Reason',
      ];
      const fileName = 'export_stock_report.csv';
      const collectData = payload?.map((payloads) => {
        const item = new Map();
        const meta = payloads?.product?.meta;
        const parseMeta = JSONParse(meta);
        const diff = payloads.quantityInStock - payloads.expectedQuantity;
        item.set('Batch No', payloads?.batch?.batchNo);
        item.set('Product Id', payloads?.product?.id);
        item.set('Product Name', payloads?.product?.brandName);
        item.set('Pack Size', parseMeta.pack_size);
        item.set('Manufacturer', parseMeta.manufacturer);
        item.set('Supplier', payloads?.product?.supplier?.name);
        item.set('Unit of Measurement', parseMeta?.unit_of_measurement);
        item.set('Qty in Stock', payloads.quantityInStock - diff);
        item.set('Qty Counted', payloads.expectedQuantity + diff);
        item.set('Difference', diff);
        item.set('Expiry Date', Moment(payloads?.batch?.expiryDate).format('YYYY-MM-DD'));
        const csvObject = Object.fromEntries(item);
        return csvObject;
      });
      const worksheet = XLSX.utils.json_to_sheet(collectData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet);
      XLSX.utils.sheet_add_aoa(worksheet, [collectKeys], {
        origin: 'A1',
      });
      XLSX.writeFile(workbook, fileName);
    }
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  return (
    <>
      <MainContent>
        <Grid container style={{ padding: '30px' }}>
          <TitleGridContainer container item>
            <Grid container item md={7}>
              <CIconButton onClick={() => navigate(-1)} style={{ marginTop: '1rem' }}>
                <KeyboardArrowLeft />
              </CIconButton>
              <Grid item>
                <PrimaryTitle variant="h5">
                  Stock Report
                </PrimaryTitle>
                <SecondaryTitle variant="caption">Inventory details</SecondaryTitle>
              </Grid>
            </Grid>
            {stockReportStatus === 'COMPLETE' ? '' : (
              <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={5}>
                {!isSmall ? (
                  <>
                    <CustomButton
                      type="tertiary"
                      header
                      style={{ width: '11rem', height: '3rem' }}
                      disabled={!userPermissions?.includes('stock_count_report_edit_report')}
                      onClick={() => handleEditReport(stockReportInventory)}
                    >
                      Edit Report
                    </CustomButton>
                    <TCustomButton
                      type="secondary"
                      header
                      onClick={() => handleCloseReport(id)}
                      style={{ width: '10.5rem', color: '#303030' }}
                    >
                      Close Report
                    </TCustomButton>
                  </>
                )
                  : (
                    <>
                      <CustomButton
                        type="tertiary"
                        header
                        style={{
                          marginTop: '3rem', width: '60rem', fontSize: '3rem', backgroundColor: '#235A91', color: 'white',
                          borderRadius: '20px'
                        }}
                        disabled={!userPermissions?.includes('stock_count_report_edit_report')}
                        onClick={() => handleEditReport(stockReportInventory)}
                      >
                        Edit Report
                      </CustomButton>
                      <TCustomButton
                        type="secondary"
                        header
                        onClick={() => handleCloseReport(id)}
                        style={{
                          width: '60rem', fontSize: '2.6rem', color: '#235A91', height: '5.5rem', borderRadius: '20px'
                        }}
                      >
                        Close Report
                      </TCustomButton>
                    </>
                  )}
              </Grid>
            )}
          </TitleGridContainer>

          <DetailCard elevation={0} style={{ borderRadius: isSmall ? '30px' : '12px', height: isSmall ? '10rem' : '' }}>
            <DetailCardText
              variant="caption"
              style={{
                marginTop: isSmall ? '1.5rem' : '0', fontSize: isSmall ? '30px' : '16px',
                marginLeft: isSmall ? (stockReportStatus === 'STARTED' ? '14rem' : stockReportStatus === 'RECONCILE_QUANTITY' ? '9rem' : '14rem') : undefined
              }}
            >
              {stockReportInventory[0]?.stockReport?.business?.name}

            </DetailCardText>
            <DetailCardText variant="caption" style={{ marginLeft: '20px', marginTop: isSmall ? '1.5rem' : '0', fontSize: isSmall ? '30px' : '16px' }}>
              {`- ${renderStockReportStatus(stockReportStatus)} `}
              <StatusBullet color={handleStockReportStatusColor(stockReportStatus)} />
            </DetailCardText>
          </DetailCard>
          {isSmall ? (
            <PaperWrapper elevation={0} style={{ borderRadius: '30px' }}>
              <SearchGrid item container justifyContent="space-between" style={{ marginBottom: '1.5rem' }}>
                <TCustomButton
                  type="secondary"
                  header
                  onClick={() => handleCsv(csvPayLoad)}
                >
                  <Hidden smDown>
                    <ExportIconStyled />
                  </Hidden>
                  Export
                </TCustomButton>
              </SearchGrid>
            </PaperWrapper>
          ) : ('')}
          <StatisticsGrid item container spacing={2} style={{ margin: '0 0 2rem' }}>
            {loading
              ? <CardLoader cols={4} />
              : statistics.map((statistic) => (
                <SmallStatisticCard
                  key={statistic.id}
                  statistic={statistic}
                />
              ))}
          </StatisticsGrid>
          {!isSmall ? (
            <PaperWrapper elevation={0}>
              <SearchGrid item container justifyContent="space-between">
                <Grid item container xs={2}>
                  <ShowingText variant="caption">
                    {`Showing ${stockReportInventory.length} Product(s)`}
                  </ShowingText>
                </Grid>
                <TCustomButton
                  type="secondary"
                  header
                  onClick={() => handleCsv(csvPayLoad)}
                >
                  <Hidden smDown>
                    <ExportIconStyled />
                  </Hidden>
                  Export
                </TCustomButton>
              </SearchGrid>
              <TableGrid item container>
                <TableComponent item container>
                  <MainTableHead item container>
                    {returnHeaders()}
                  </MainTableHead>
                  <CTableBody>
                    {stockReportInventory.map((row) => (
                      <StockReportDetailsRow
                        row={row}
                      />
                    ))}
                  </CTableBody>
                </TableComponent>
              </TableGrid>
            </PaperWrapper>
          ) : (
            <>
              {stockReportInventory.map((row) => (
                <StockReportDetailsRowMobileView
                  row={row}
                  key={row}
                />
              ))}
            </>
          )}
        </Grid>
      </MainContent>

      <EditProductDialog
        open={openEditProductDialog}
        state={state}
        selectedProdBatch={selectedProduct}
        handleSave={handleSave}
        handleChange={handleChange}
        handleCloseModal={() => setOpenEditProductDialog(false)}
      />

      <ReconcileProductBatchDialog
        openProductBatchDialog={openProductBatchDialog}
        selectedProdBatches={selectedProduct}
        handleCloseModal={() => setOpenProductBatchDialog(false)}
      />
    </>
  );
};

export default StockReportDetails;
