import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
} from '@mui/material';
import {
  TitleText, ContentText, DialogCancelButton, DialogOkButton
} from './styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const NotificationDialog = ({
  dialogOpen, closeDialog, message
}) => {
  const navigate = useNavigate();
  const { data } = message ?? {};

  const handleView = () => {
    closeDialog();
    navigate(data?.pathname || '#');
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      onClose={closeDialog}
      maxWidth="sm"
    >
      <DialogTitle>
        <TitleText>{data?.title}</TitleText>
      </DialogTitle>
      <DialogContent>
        <ContentText>{data?.body}</ContentText>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={closeDialog}>
          Dismiss
        </DialogCancelButton>
        <DialogOkButton onClick={handleView}>
          View
        </DialogOkButton>
      </DialogActions>
    </Dialog>
  );
};

NotificationDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  message: PropTypes.shape(Object),
};

NotificationDialog.defaultProps = {
  message: {}
};

export default NotificationDialog;
