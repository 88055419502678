import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// import { useMutation } from '@apollo/client';
// import toast from 'react-hot-toast';
import {
  Grid,
  // Paper,
  TableRow,
  TableBody,
  TableContainer,
  Box,
  Table
  // Hidden,
  // RootRef
} from '@mui/material';
// import { DELETE_BATCH_MUTATION } from '../../../mutations/products';
// import { BodyCell } from '../../toBeDeleted/supplierDetails/returnRow.styles';
import {
  // OrderButton,
  // PlusIcon,
  // ButtonText,
  // TableContainerWrapper,
  // TableComponent,
  // HeaderCell,
  // MainTableHead
  CardTitle,
  TableCellStyled,
  TableCellBodyStyled,
  TableRowStyled,
  TableHeadStyled,
  Stat,
  TableCellBorderStyled
  // StatContainer
} from './productBatchList.styles';
import currencyFormatter from '../../shared/currencyFormatter';
import ReturnRow from './returnRow';
// import currencyFormatter from '../../shared/currencyFormatter';
// import AddBatchDropdown from './addBatchDropdown';
// import SingleBatchDialog from './singleBatchDialog';
// import WarningDialog from './warningDialog';

const ProductBatchList = ({
  refetch,
  info: {
    batches: rows, supplier, brandName, id: productId
  }
}) => {
  // const [openDropdown, setOpenDropdown] = useState(false);
  // const [openModal, setOpenModal] = useState(false);
  // const [openWarning, setOpenWarning] = useState(false);
  // const [batchToEdit, setBatchToEdit] = useState(null);
  // const [batchToDelete, setBatchToDelete] = useState(null);
  // const buttonEl = useRef();
  // const role = localStorage.getItem('oga_user_role');

  // const editBatch = (batch) => {
  //   setBatchToEdit(batch);
  //   setOpenModal(true);
  // };

  // const handleCloseModal = () => {
  //   setBatchToEdit(null);
  //   setOpenModal(false);
  // };

  // const deleteBatch = (batch) => {
  //   setBatchToDelete(batch);
  //   setOpenWarning(true);
  // };

  // const [deleteABatch] = useMutation(DELETE_BATCH_MUTATION);

  // const isChainManager = role === 'chain-manager';

  // const handleBatchDelete = (batch) => {
  //   deleteABatch({
  //     variables: { batchId: batch.id }
  //   })
  //     .then(({ data }) => {
  //       const { message } = data?.deleteBatch || {};
  //       toast.success(message);
  //       setBatchToDelete(null);
  //       setOpenWarning(false);
  //       refetch();
  //     })
  //     .catch((err) => {
  //       toast.error(err?.message);
  //     });
  // };

  // const handleCloseDropdown = (event) => {
  //   !buttonEl.current.contains(event.target) && setOpenDropdown(false);
  // };

  const grandTotal = rows?.reduce((acc, num) => acc + num.orderPrice, 0.0);
  const qtyInStock = rows?.reduce((acc, num) => acc + num.quantityInStock, 0);
  return (
    <Grid>
      <Box p={4} bgcolor="background.paper" borderRadius={8}>
        <CardTitle className="erp-uat-batch-details">Batch Details</CardTitle>
        <TableContainer style={{ marginTop: '1rem' }}>
          <Table style={{ width: '100%' }}>
            <TableHeadStyled>
              <TableRowStyled>
                <TableCellStyled>Date Received</TableCellStyled>
                <TableCellStyled>Supplier</TableCellStyled>
                <TableCellStyled>Batch No.</TableCellStyled>
                <TableCellStyled>Expiry Date</TableCellStyled>
                <TableCellStyled>Batch Qty</TableCellStyled>
                <TableCellStyled>Item Cost</TableCellStyled>
                <TableCellStyled>Item Price</TableCellStyled>
              </TableRowStyled>
            </TableHeadStyled>

            {rows?.length > 0 ? (
              <TableBody>
                {rows?.map((row) => (
                  <ReturnRow key={row.id} supplier={supplier} row={row} />
                ))}
                <TableCellBodyStyled colSpan={3} />
                <TableCellBorderStyled>
                  <Stat>Qty in stock</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>{qtyInStock}</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>Grand Total</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>{`₦ ${currencyFormatter(grandTotal)}`}</Stat>
                </TableCellBorderStyled>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCellBodyStyled
                    colSpan={7}
                    style={{ textAlign: 'center' }}
                  >
                    No Batches for this product yet
                  </TableCellBodyStyled>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Grid>
    // <Grid item container>
    //   <Grid Item container justifyContent="flex-end">
    //     <RootRef rootRef={buttonEl}>
    //       {isChainManager && (
    //       <OrderButton onClick={() => setOpenDropdown(true)}>
    //         <PlusIcon />
    //         <Hidden smDown>
    //           <ButtonText>
    //             Add Batch
    //           </ButtonText>
    //         </Hidden>
    //       </OrderButton>
    //       )}
    //     </RootRef>
    //   </Grid>
    //   <TableContainerWrapper component={Paper}>
    //     <TableComponent aria-label="supplier products table">
    //       <MainTableHead>
    //         <TableRow>
    //           <HeaderCell>Date Received</HeaderCell>
    //           <HeaderCell>Supplier</HeaderCell>
    //           <HeaderCell>Batch No.</HeaderCell>
    //           <HeaderCell>Expiry Date</HeaderCell>
    //           <HeaderCell>Batch Qty</HeaderCell>
    //           <HeaderCell>Item Cost</HeaderCell>
    //           <HeaderCell>Item Price</HeaderCell>
    //           {/* <HeaderCell /> */}
    //         </TableRow>
    //       </MainTableHead>
    //       { rows.length > 0
    //         ? (
    //           <TableBody>
    //             {rows.map((row) => {
    //               grandTotal += row.orderPrice;
    //               qtyInStock += row.quantityInStock;
    //               return (
    //                 <ReturnRow
    //                   key={row.id}
    //                   supplier={suppliers}
    //                   row={row}
    //                   editBatch={() => editBatch(row)}
    //                   deleteBatch={() => deleteBatch(row)}
    //                 />
    //               );
    //             })}
    //             <BodyCell />
    //             <BodyCell />
    //             <BodyCell />
    //             <BodyCell style={{ fontWeight: '600' }}>Qty in stock</BodyCell>
    //             <BodyCell style={{ fontWeight: '600' }}>{qtyInStock}</BodyCell>
    //             <BodyCell style={{ fontWeight: '600' }}>Grand Total</BodyCell>
    //             <BodyCell style={{ fontWeight: '600' }}>
    //               ₦
    //               {currencyFormatter(grandTotal)}
    //             </BodyCell>
    //           </TableBody>
    //         )
    //         : (
    //           <TableBody>
    //             <BodyCell />
    //             <BodyCell />
    //             <BodyCell />
    //             <BodyCell>No Batches for this product yet</BodyCell>
    //             <BodyCell />
    //             <BodyCell />
    //             {/* <BodyCell /> */}
    //           </TableBody>
    //         )}
    //     </TableComponent>
    //   </TableContainerWrapper>

  //   <AddBatchDropdown
  //     open={openDropdown}
  //     anchorEl={buttonEl.current}
  //     handleCloseDropdown={handleCloseDropdown}
  //     openBatchModal={() => setOpenModal(true)}
  //   />
  //   <SingleBatchDialog
  //     open={openModal}
  //     onClose={() => handleCloseModal()}
  //     brandName={brandName}
  //     productId={productId}
  //     refetch={refetch}
  //     batchToEdit={batchToEdit}
  //   />
  //   <WarningDialog
  //     open={openWarning}
  //     onClose={() => setOpenWarning(false)}
  //     brandName={brandName}
  //     handleBatchDelete={() => handleBatchDelete(batchToDelete)}
  //   />
  // </Grid>
  );
};

ProductBatchList.propTypes = {
  info: PropTypes.instanceOf(Object),
  refetch: PropTypes.func.isRequired
};

ProductBatchList.defaultProps = {
  info: {}
};

export default ProductBatchList;
