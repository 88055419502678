import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, Slide, Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../customComponents/customButton';
import CustomInputBase from '../customComponents/customInputBase';
import CustomSelectInputBase from '../customComponents/customSelectInputBase';
import {
  CDialog, CloseIcon, DialogTitleText, DialogTitleSubText, CircularProgressLoader, GridWrapper
} from './individualCustomerDialog.styles';
import { validateEmail } from '../auth/utils';

import { useStateValue } from '../../providers/stateProvider';
import { blockInvalidChar } from '../../utils/mobileCheck';
import { parseCustomerFields } from '../shared/utils';
import { CREATE_CUSTOMER_MUTATION, UPDATE_CUSTOMER_MUTATION } from '../../mutations/customers';
import SaleActionTypes from '../../providers/reducers/sales/salesTypes';
import { getCustomerObject } from '../../utils/utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualCustomerDialog = ({
  dialogOpen, closeDialog, autoAddCustomerToSale, isFromSaleScreen
}) => {
  const initialState = {
    customerName: '',
    mobile: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    region: '',
    city: 'Lagos',
    country: 'Nigeria',
    customerId: '',
    photo: '',
    notes: '',
    loyaltyPoint: 0,
    storeCredit: 0,
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    mobileError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [editing, setEditing] = useState(false);
  const [{
    sale: { customerModalData },
  }, dispatch] = Object.values(useStateValue());

  const [createCustomer] = useMutation(CREATE_CUSTOMER_MUTATION);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER_MUTATION);

  const { nameError, emailError, mobileError } = errorState;
  const { id } = customerModalData || {};

  useEffect(() => {
    if (id) {
      const {
        customerId, name: customerName, photo, address,
        email, mobile, notes, loyaltyPoint, storeCredit
      } = parseCustomerFields(customerModalData);
      const [
        addressLine1, addressLine2, region, city, country
      ] = address;
      setEditing(true);
      setState((_state) => ({
        ..._state, customerId, customerName, photo,
        addressLine1, addressLine2, region, city, country,
        email, mobile, notes, loyaltyPoint, storeCredit
      }));
    } else setState((_state) => ({ ..._state, ...initialState }));
  }, [id]);

  const handleCloseDialog = () => {
    closeDialog();
  };

  const addCustomer = (photo) => {
    const {
      customerName, mobile, email, addressLine1,
      addressLine2, region, city, country, notes, loyaltyPoint, storeCredit
    } = state;
    createCustomer({
      variables: {
        customerName,
        photo,
        mobile,
        email,
        addressLine1,
        addressLine2,
        region,
        city,
        country,
        notes,
        loyaltyPoint: loyaltyPoint || 0,
        storeCredit: storeCredit || 0,
      }
    })
      .then(({ data }) => {
        const { message, customer: newCustomer } = data?.createCustomer || {};
        toast.success(message);
        handleCloseDialog();
        if (autoAddCustomerToSale) {
          dispatch({
            type: SaleActionTypes.UPDATE_CUSTOMER,
            payload: {
              openedCustomerModal: false,
              ...getCustomerObject(newCustomer)
            }
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCustomer = (photo) => {
    const {
      customerId, customerName, mobile, email,
      addressLine1, addressLine2, region, city, country,
      notes, loyaltyPoint, storeCredit
    } = state;
    updateCustomer({
      variables: {
        customerId,
        customerName,
        photo,
        mobile,
        email,
        addressLine1,
        addressLine2,
        region,
        city,
        country,
        notes,
        loyaltyPoint,
        storeCredit
      }
    })
      .then(({ data }) => {
        const { message, customer: updatedCustomer } = data?.updateCustomer || {};
        toast.success(message);
        handleCloseDialog();
        if (isFromSaleScreen) {
          dispatch({
            type: SaleActionTypes.UPDATE_CUSTOMER,
            payload: {
              openedCustomerModal: false,
              ...getCustomerObject(updatedCustomer)
            }
          });
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png';
    if (editing) return editCustomer(logoPlaceholder);
    return addCustomer(logoPlaceholder);
  };

  const handleSaveButtonClick = () => {
    const { customerName, mobile } = state;
    if (customerName.length <= 1) {
      return setErrorState({
        ...errorState, nameError: true
      });
    } if (mobile && (mobile?.length <= 10 || mobile?.length > 11)) {
      return setErrorState({
        ...errorState, mobileError: true
      });
    }
    // if (validateEmail(email)) {
    //   return setErrorState({
    //     ...errorState, emailError: true
    //   });
    // }++
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'customerName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: (value.length <= 10)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'mobile' && value.length > 11) return;
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'customerName', label: 'Customer Name', placeholder: "Enter Customer's name",
      error: nameError, helperText: 'customer name required.'
    },
    { name: 'addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line' },
    {
      name: 'mobile', label: 'Phone Number', placeholder: 'Enter Phone Number', error: mobileError,
      helperText: 'Invalid phone number'
    },
    { name: 'addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line' },
    {
      name: 'email', label: 'Email Address', placeholder: 'Enter Email Address', error: emailError
    },
    { name: 'region', label: 'Region', placeholder: 'Enter Region' },
    { name: 'storeCredit', label: 'Store Credit' },
    { name: 'city', label: 'City', placeholder: 'Select City' },
    { name: 'loyaltyPoint', label: 'Loyalty Point' },
    {
      name: 'country', label: 'Country', placeholder: 'Write a Note', options: ['Nigeria', 'Uganda', 'Kenya']
    }
  ];

  const returnTextField = (field, isProtected = false) => {
    const {
      name: fieldName, label, placeholder, error, helperText
    } = field;
    const value = state[fieldName];
    if (fieldName === 'term' || fieldName === 'country') {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        disabled={isProtected}
        size="small"
        type={fieldName === 'mobile' ? 'number' : 'text'}
        onKeyDown={fieldName === 'mobile' && blockInvalidChar}
        error={error}
        helperText={error && helperText}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
      />
    );
  };

  return (
    <CDialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container>
          <Grid item container xs={6}>
            <DialogTitleText>
              {editing ? 'Edit Customer' : 'Add New Customer' }
            </DialogTitleText>
            <DialogTitleSubText>
              {editing
                ? 'Edit the form to update customer information'
                : 'Add customer information to the fields in the form'}
            </DialogTitleSubText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2}>
            {topFields.map((field) => (
              <Grid item xs={6}>{returnTextField(field)}</Grid>
            ))}
            {/* <MiddleGrid item>
              {storeCreditField.map((field) => returnTextField(field, !allowedPermissionsMap.get('CAN_UPDATE_CUSTOMER_STORE_CREDIT')))}
            </MiddleGrid>
            <MiddleGrid item>
              {loyaltyPointField.map((field) => returnTextField(field, !allowedPermissionsMap.get('CAN_UPDATE_CUSTOMER_LOYALTY_BALANCE')))}
            </MiddleGrid> */}
            <Grid item xs={12}>
              <CustomInputBase
                multiline
                minRows={2}
                label="Note"
                placeholder="Write a Note"
                name="notes"
                value={state.notes}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <CustomButton
            type="tertiary"
            header
            onClick={handleSaveButtonClick}
            style={{ width: '11rem', height: '2.7rem' }}
          >
            {loading
              ? <CircularProgressLoader disableShrink size={22} thickness={5} />
              : editing ? 'Save Changes' : 'Submit'}
          </CustomButton>
        </DialogActions>
      </GridWrapper>
    </CDialog>
  );
};

IndividualCustomerDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  autoAddCustomerToSale: PropTypes.bool,
  isFromSaleScreen: PropTypes.bool.isRequired
};

IndividualCustomerDialog.defaultProps = {
  autoAddCustomerToSale: false
};

export default IndividualCustomerDialog;
