import React from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from '../../utils/currencyFormatter';
import {
  BodyCell, MainTableRow, CustomCheckbox, ColorGrid
} from './returnRow.styles';
import { Product } from '../../providers/reducers/product/classes/Product';

const ReturnRow = ({
  row, selected, handleSelect
}) => {
  const isSelected = () => selected.findIndex((x) => x?.id === row?.id) !== -1;
  const {
    order, product, quantityOrdered, costPrice, sellingPrice, hybrid
  } = row;

  const cells = [order?.business?.name, product?.brandName, quantityOrdered, currencyFormatter(costPrice), currencyFormatter(sellingPrice)];

  const returnCell = (value) => value || '-';

  const getStatus = () => <ColorGrid item container status={hybrid}>{hybrid === 'YES' ? 'Yes' : 'No'}</ColorGrid>;

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={isSelected()}
      disabled={!quantityOrdered}
      onChange={(e) => {
        e.stopPropagation();
        handleSelect(e, row);
      }}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell key={cell}>{ returnCell(cell) }</BodyCell>
  ));

  return (
    <MainTableRow item container>
      <BodyCell>{renderCheckbox()}</BodyCell>
      <BodyCell>{order?.id}</BodyCell>
      {renderBodyCells()}
      <BodyCell>{getStatus()}</BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Product),
  selected: PropTypes.shape(Array),
  handleSelect: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  selected: [],
};

export default ReturnRow;
