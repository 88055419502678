import { styled } from '@mui/material/styles';
import {
  Dialog, Grid, TableCell, TableRow, Typography, Table, TableHead
} from '@mui/material';
import { Close } from '@mui/icons-material';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 1rem 2.5rem 0.5rem 2.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 2.5rem 1.5rem;
    }
  }
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;
  margin-bottom: 4px;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const ProductContainer = styled('div')`
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 2px solid #ECECEC;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    padding: 35px 0;
    margin: 0 35px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const BatchItem = styled(Table)`
    background: #fff;
    padding: 10px 10px 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const BatchHeaders = styled(TableHead)`
`;

export const HeaderTitle = styled(TableCell)`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  white-space: nowrap;
`;

export const TableContent = styled(TableCell)`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  white-space: nowrap;
  text-align: center;
`;

export const TableRows = styled(TableRow)`

`;

export const EditImg = styled('img')`
  width: 7%;
  cursor: pointer;
  margin-top: 0.6rem;
  margin-right: 3rem;
`;

export const DeleteImg = styled('img')`
  width: 7%;
  cursor: pointer;
  margin-top: 0.6rem
`;

export const IconWrapper = styled('div')`
display: flex;
justify-content: flex-end;
`;
