import React from 'react';
import {
  Grid, Typography, IconButton, AppBar, Drawer
} from '@mui/material';
import { KeyboardArrowLeft, ArrowDropDown } from '@mui/icons-material';
import styled, { css } from 'styled-components';
import {
  MenuIcon, BellIcon, MPWhiteLogo, AdminLogo, WarehouseLogo, WarehouseW
} from '../../assets/svgs';

const drawerWidth = 400;

const navBarShift = ({ open }) => open && css`
  width: calc(100% - ${drawerWidth}px);
  margin-left: drawerWidth;
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  });
  @media(max-width: 991px) {
    width: 100%;
  }
`;

const hide = ({ open }) => open && css`
  display: none;
`;

export const NavbarContainer = styled(({
  color, theme, open, ...otherProps
}) => (
  <AppBar {...otherProps} />
))`
  transition: ${(props) => props.theme.transitions.create(
    ['margin', 'width'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.leavingScreen
    }
  )};
  background: #F3F9FF;
  box-shadow: 0px 4px 8px rgba(214, 219, 230, 0.25);
  z-index: 10000000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* ${navBarShift} */
`;

export const SeparatorGrid = styled(Grid)`
  flex-wrap: nowrap;
  flex-direction: row;
`;

export const NavLeftMenu = styled(Grid)`
  justify-content: flex-start;
  display: contents;
  align-items: center;
  width: 100px;

  @media(min-width: 992px) {
    width: 250px;
  }
`;

// export const NavLeftMenu = styled(({ open, ...otherProps }) => (
//   <Grid {...otherProps} />
// ))`
//   justify-content: flex-start;
//   align-items: center;
//   width: 100px;

//   @media(min-width: 992px) {
//     width: 250px;
//   }
//   ${hide}
// `;

export const NavRightMenu = styled(Grid)`
  justify-content: flex-end;
  align-items: center;
`;

export const MenuButton = styled(IconButton)`
  color: white;
`;

export const MenuBarIcon = styled(MenuIcon)`
  width: 2rem;
  height: 2rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const HeaderMenuButton = styled(IconButton)`
  padding: 0;
  margin-left: 2.7rem;
`;

export const CloseMenuButton = styled(IconButton)`
  width: 100%;
  padding: 1rem 3rem;
`;

export const NavItem = styled(Grid)`
  justify-content: center;
  align-items: center;
  text-align: right;
  border-left: 1px solid rgba(240, 240, 240, .1);
  height: 72px;
  justify-content: center;
  align-items: center;
  border-radius:0px;
`;

export const DateTimeTypo = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0px;
  text-align: right;
  padding-left: 20px;
  color: #24292E;
`;

export const ViewCartTypo = styled(Typography)`
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0px;
  opacity: .5;
  padding-right: 5px;
  color: #606060;
  margin-left: 8px;

  @media(min-width: 992px) {
    font-size: 14px;
  }
`;

export const AffiliatesLogo = styled(Grid)`
  justify-content: center;
  align-items: center;
  width: 36%;
  height: 135px;
  border-left: 0px;
  border-radius: 0px;
  margin-right: 1rem;

  @media(min-width: 992px) {
    border-left: 1px solid rgba(240, 240, 240, .1);
    width: 37%;
    height: 72px;
    margin-right: 0;
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.platform === 'erp' ? '30%' : '50%')};
  }
`;

export const LogoIcon = styled(IconButton)`
  padding: 3px;
  margin-left: 1.5rem;
  border-radius: 0;

  @media(min-width: 992px) {
    margin-left: 0.5rem;
  }
`;

export const SideLogoIcon = styled(IconButton)`
  padding: 3px;
  margin-left: 5rem;
  border-radius: 0;
  margin-top: 25px;
`;

export const PharmIQImage = styled('img')`
  justify-self: flex-start;
  margin-left: 25px;
  cursor: pointer;
  height: 85px;

  @media(min-width: 992px) {
    height: 50px;
  }
`;

export const HeaderImage = styled('img')`
  height: 62px;

  @media(min-width: 992px) {
    height: 30px;
  }
`;

export const HeaderIcon = styled(IconButton)`
  border-radius: 0;
  height: 100%;
  color: #CCCCCC;
  padding: 0 8px;

  &:hover{
    color: #A3A3A3;
  }

  @media(min-width: 992px) {
    padding: 5px;
  }
`;

export const AffiliateImage = styled('img')`
  width: 100px;
  border-radius: 50%;

  @media(min-width: 992px) {
    width: 35px;
  }
`;

export const SideAffiliateImage = styled('img')`
  width: 200px;
  border-radius: 50%;
`;

export const DrawerContainer = styled(Drawer)`
  /* width: ${drawerWidth}px; */
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: 251px;
    background: #235A91;;
    z-index: 10000001;
    border: 0;
    @media(max-width: 991px) {
      width: 100%;
    }
  }
`;

export const ArrowLeft = styled(KeyboardArrowLeft)`
  width: 3rem;
  height: 3rem;

  @media(min-width: 992px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const SideNavHeader = styled(Grid)`
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #C0C0C033;

  @media(min-width: 992px) {
    padding: 19px 0;
    border-bottom: none;
  }
`;

export const SideNavItems = styled(Grid)`
  margin-top: 10px;
  width: 250px;

  @media(max-width: 991px) {
    width: 100%;
    padding-top: 5rem;
  }
`;

export const BellButton = styled(IconButton)`
  padding: 1px;

  @media(min-width: 992px) {
    padding: 7px;
  }
`;

export const BellBarIcon = styled(BellIcon)`
  font-size: 3rem;
  color: #cccccc;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const FullPharmIQLogo = styled('img')`
  width: 17rem;
  margin-left: 2rem;
  @media(min-width: 992px) {
    width: 7rem;
  }
`;

export const SideNavBackground = styled('img')`
  position: absolute;
  height: 80%;
  width: 400px;
  z-index: 12;
  margin-top: 80px;
`;

export const SideNavImage = styled('img')`
 position: absolute;
  z-index: 15;
  margin-top: 80px;
  margin-left: 100px;
`;
export const SideNavItemImage = styled('img')`
  height: 32px;

  @media(min-width: 992px) {
    height: 30px;
  }
`;
export const ProfileIcon = styled('img')`
  width: 10%;
`;

export const ProfileTypo = styled(Typography)`
  color: #24292E;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: 12px;
    color: #606060;
    margin-left: 5px;
    width: 8rem;
    text-align: start;
  }
`;

export const SideProfileTypo = styled(Typography)`
  color: rgb(88, 92, 96);
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  margin-left: 3rem;
  color: #ffffff;
`;

export const NavMenuText = styled(Typography)`
  margin-left: 10px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #24292E;

  @media(min-width: 992px) {
    font-size: 1.2rem;
  }
`;

export const DropDownArrow = styled(ArrowDropDown)`
  font-size: 1.8rem;
`;

export const MpLogo = styled(MPWhiteLogo)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 90%;
  }

  @media (min-width: 992px) {
    &.MuiSvgIcon-root {
      width: 80%;
      height: 85%;
    }
  }
`;

export const WHLogo = styled(WarehouseW)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 90%;
  }

  @media (min-width: 992px) {
    &.MuiSvgIcon-root {
      width: 80%;
      height: 85%;
    }
  }
`;

export const OgaAdminLogo = styled(AdminLogo)`
  &.MuiSvgIcon-root {
    width: 70%;
    height: 85%;
  }

  @media (min-width: 991px) {
    &.MuiSvgIcon-root {
      width: 35%;
      height: 75%;
    }
  }
`;

export const WarehouseLg = styled(WarehouseLogo)`
&.MuiSvgIcon-root {
  width: 32%;
  height: 72%;
}

@media (min-width: 1450px) {
  &.MuiSvgIcon-root {
    width: 35%;
    height: 75%;
  }
}

  @media (max-width: 991px) {
    &.MuiSvgIcon-root {
     display: none;
    }
  }
}
`;
