import {
  Grid, Paper, TableCell, TableBody, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DivFullWidth = styled('div')`
  width: 100%;
  padding: 0 1.5rem 1.5rem;
`;

export const TableContainerWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const MainTableHead = styled(Grid)`
  width: 100%;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 6%;
    }
    &:nth-child(2), &:nth-child(3) {
      width: 26%;
    }
    &:nth-child(5) {
      width: 10%;
    }
    &:nth-child(4), :nth-child(6) {
      width: 15%;
    }
    &:nth-child(7) {
      width: 2%;
    }
  }
`;

export const TitleGridContainer = styled(Grid)`
  margin: 1rem 0 1.5rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;
