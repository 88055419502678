import {
  Grid, Typography, CircularProgress, Dialog
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: .5rem 2rem;
    width: 100%;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
  color: #235A91;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 600;
  color: #303030;
  font-size: 20px;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 12px;
  color: #858383;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;
