import React from 'react';
import PropTypes from 'prop-types';
import { BodyCell, MainTableRow } from '../categories/categoryCard.styles';
import { JSONParse } from '../../utils/json';
import { LabelText, SelectionArea } from './accessControl.styles';

const ReturnRow = ({
  row, index, defaultPermissions, handleClickRow
}) => {
  const {
    id, permissions, user: { username }, business: { name: businessName }, role: { name: roleName }
  } = row;
  const permissionsParsed = JSONParse(permissions);
  return (
    <MainTableRow onClick={() => handleClickRow(id)}>
      <BodyCell>
        {index + 1}
      </BodyCell>
      <BodyCell>
        {username}
      </BodyCell>
      <BodyCell>
        {businessName}
      </BodyCell>
      <BodyCell>
        {roleName}
      </BodyCell>
      <BodyCell>
        <SelectionArea>
          {Array.isArray(permissionsParsed) && permissionsParsed.map((permissionId) => <LabelText key={permissionId}>{ defaultPermissions.get(`${permissionId}`) || '' }</LabelText>)}
        </SelectionArea>
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  defaultPermissions: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  handleClickRow: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  defaultPermissions: new Map()
};

export default ReturnRow;
