import { gql } from '@apollo/client';

export const CREATE_PROMOTION_MUTATION = gql`
  mutation createPromotion(
    $description: String $endDate: DateTime $name: String! $productIds: [Int]
    $startDate: DateTime $unit: String! $value: String!
  ) {
    createPromotion(
      description: $description endDate: $endDate name: $name productIds: $productIds
      startDate: $startDate unit: $unit value: $value
    ) {
      message
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_PROMOTION_MUTATION = gql`
  mutation updatePromotion(
    $promotionId: Int! $description: String $endDate: DateTime $name: String
    $productIds: [Int] $startDate: DateTime $unit: String $value: String
  ) {
    updatePromotion(
      promotionId: $promotionId description: $description endDate: $endDate name: $name
      productIds: $productIds startDate: $startDate unit: $unit value: $value
    ) {
      message
      promotion {
        id
      }
    }
  }
`;

export const DELETE_PROMOTION_MUTATION = gql`
  mutation deletePromotion($promotionId: Int!) {
    deletePromotion(promotionId: $promotionId) {
      message
    }
  }
`;

export const REMOVE_PROMOTION_PRODUCTS_MUTATION = gql`
  mutation removePromotionProducts(
    $promotionId: Int! $productIds: [Int]
  ) {
    removePromotionProducts(
      promotionId: $promotionId productIds: $productIds
    ) {
      message
    }
  }
`;

export const COMPLETE_PROMOTION_MUTATION = gql`
  mutation completePromotion(
    $promotionId: Int! $description: String $endDate: DateTime $name: String
    $productIds: [Int] $startDate: DateTime $unit: String $value: String
  ) {
    completePromotion(
      promotionId: $promotionId description: $description endDate: $endDate name: $name
      productIds: $productIds startDate: $startDate unit: $unit value: $value
    ) {
      message
      promotion {
        id
      }
    }
  }
`;
