import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Paper, Table, TableBody } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import moment from 'moment';
import toast from 'react-hot-toast';
import MainContent from '../../customComponents/mainContent';
import TablePagination from '../../shared/tablePagination';
import { UPDATED_UNRECONCILED_MANUAL_SUPPLIER_ORDERS } from '../../../queries/orders';
import { RECONCILE_BY_SUPPLIER } from '../../../mutations/orders';
import {
  TableContainerWrapper,
  DivFullWidth,
  GridContainer,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  PaperWrapper,
  BackButton,
  CustomCheckbox
} from './managePurchaseOrder.styles';
import {
  TableComponent,
  TableColumnHeaders,
  TableHeader
} from '../../shared/tableGlobalStyle.styles';
import ReturnRow from './returnRow';
import ManagePoFilter from './managePOFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { rmDuplicateItemsFromArr } from '../../../utils/funcs';

const headers = [
  { name: 'Date', width: '80px' },
  { name: 'Supplier', width: '200px' },
  { name: 'PO Number', width: '200px' },
  { name: 'Parent ID', width: '200px' },
  { name: 'Amount of Products', width: '220px' },
  { name: 'Total Order Value', width: '220px' },
  { name: 'Order Status', width: '200px' },
  { name: 'Action', width: '150px' }
];

const ManagePurchaseOrderContainer = () => {
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selected, setSelected] = useState([]);
  const [stateRows, setStateRows] = useState([]);

  const dateDefault = moment(moment().startOf('day')).format('YYYY-MM-DD');

  const initialState = {
    currentPage: 1,
    currentPageCount: 20,
    poNumber: '',
    parentId: '',
    status: '',
    supplier: '',
    dateFrom: dateDefault,
    dateTo: dateDefault,
    filters: {}
  };

  const [state, setState] = useState(initialState);

  const {
    poNumber, status, parentId, supplier, dateFrom, dateTo, filters
  } = state;

  const handleSetFilter = () => {
    setState({
      ...state,
      filters: {
        poNumber,
        parentId,
        status,
        dateFrom,
        dateTo,
        supplier
      }
    });
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader
      style={{ minWidth: width }}
      className={`erp-uat-${name.replace(/ /g, '-')}`}
      key={name}
    >
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const openOrders = stateRows.filter((product) => product?.order?.metaStatus === 'ORDER_SENT');
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = openOrders.map((product) => {
        if (product.order.id && product?.order?.metaStatus === 'ORDER_SENT') {
          return product.id;
        }
      });
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const handleSelect = (_, orderId) => {
    const selectItem = selected.filter((item) => item === orderId);
    if (selectItem.length < 1) return setSelected([...selected, orderId]);
    if (selectItem.length) {
      const orderExists = selected.filter((item) => item !== orderId);
      setSelected(orderExists);
    }
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === openOrders.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const variables = {
    pageCount,
    pageNumber,
    dateFrom,
    dateTo,
    ...filters,
  };

  const {
    loading, error, data, refetch
  } = useQuery(UPDATED_UNRECONCILED_MANUAL_SUPPLIER_ORDERS, {
    fetchPolicy: 'no-cache',
    variables
  });

  useEffect(() => {
    if (data && data?.unreconciledManualSupplierOrders) {
      const { unreconciledManualSupplierOrders } = data;
      setStateRows(unreconciledManualSupplierOrders);
    }
  }, [data]);

  const [mutateReconcile, { loading: reconcileLoading }] = useMutation(RECONCILE_BY_SUPPLIER);

  const deletePurchaseOrder = () => {
    const reconcilePoList = rmDuplicateItemsFromArr(selected);
    if (reconcilePoList.length < 1) {
      return toast.error('Kindly select at least an order');
    }

    mutateReconcile({
      variables: { poNumbers: reconcilePoList }
    }).then(({ data: reconcileData }) => {
      const { reconcileSingleOrMultiplePo } = reconcileData;
      toast.success(reconcileSingleOrMultiplePo.message);
      refetch();
    }).catch((err) => toast.error(err.message));
  };

  if (error) return <div>{error.message}</div>;
  const products = data?.unreconciledManualSupplierOrders;

  return (
    <MainContent>
      <GridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={3}>
            <BackButton
              variant="outlined"
              onClick={() => navigate('/inventory/orders')}
              startIcon={<KeyboardArrowLeft fontSize="large" />}
            >
              back
            </BackButton>
            <PrimaryTitle variant="h5" className="mt20">Purchase Orders</PrimaryTitle>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <ManagePoFilter
            state={state}
            handleFilterChange={handleFilterChange}
            handleSetFilter={handleSetFilter}
            setState={setState}
            deletePurchaseOrder={deletePurchaseOrder}
            loading={reconcileLoading}
          />

          <TableContainerWrapper component={Paper}>
            {loading ? (
              <ProductsTableLoader />
            ) : (
              <TableComponent>
                <Table>
                  <TableColumnHeaders>
                    <TableHeader style={{ minWidth: '50px' }}>
                      {renderCheckbox()}
                    </TableHeader>
                    {returnHeaders()}
                  </TableColumnHeaders>
                  <TableBody>
                    {products.map((order) => (
                      <ReturnRow key={order.id} row={order} selected={selected} handleSelect={handleSelect} refetch={refetch} />
                    ))}
                  </TableBody>
                </Table>
              </TableComponent>
            )}
            <DivFullWidth>
              {data?.supplierOrderTotalNumber > 0 && (
              <TablePagination
                total={data?.supplierOrderTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
              )}
            </DivFullWidth>
          </TableContainerWrapper>
        </PaperWrapper>
      </GridContainer>
    </MainContent>
  );
};

export default ManagePurchaseOrderContainer;
