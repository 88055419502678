import SupplierActionTypes from './supplierTypes';
import { checkDuplicateEntries } from './supplierUtils';

const supplierReducer = (supplier, action) => {
  switch (action.type) {
    case SupplierActionTypes.UPDATE_SUPPLIER:
      return {
        ...supplier,
        ...action.payload
      };
    case SupplierActionTypes.TOGGLE_DIALOG_OPEN:
      return {
        ...supplier,
        addSupplierDialogOpen: !supplier.addSupplierDialogOpen
      };
    case SupplierActionTypes.ADD_RECEIVED_ORDER_BATCH:
      return {
        ...supplier,
        receivedOrderBatch: checkDuplicateEntries(supplier.receivedOrderBatch, action)
      };
    default:
      return supplier;
  }
};

export default supplierReducer;
