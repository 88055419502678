import {
  Grid, IconButton, InputBase, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridCell = styled(Grid)`
  padding: 15px 10px;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 56%;
    }
    &:nth-child(2) {
      width: 22%;
    }
    &:nth-child(3) {
      padding-right: 0;
      width: 22%;
    }
  }
`;

export const CartItemTitle = styled('p')`
    font-size: 12px;
    color: #606060;
`;

export const CurrencyTypo = styled(Typography)`
  font-size: 12px;
  color: #606060;
  line-height: 3.2;
`;

export const CartItemPack = styled('p')`
    font-size: 12px;
    color: #606060;
    margin: 0;
`;

export const ButtonsWrapper = styled(Grid)`

`;

export const MissedSaleIcon = styled(IconButton)`
  color: #000;
  margin: 0px;
  cursor: pointer;
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 85%;
  border-radius: 6px;
  font-size: 12px;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: ${({ discount }) => (discount ? '12px 5px' : '12px')};
    padding-right: 5px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0;
  }
  & .MuiInputAdornment-positionStart {
    margin-right: 0;
    margin-left: 16px;
  }
`;

export const PriceGrid = styled(Grid)`
  align-items: flex-start;
  font-size: 12px;
  font-weight: 600;
`;

export const DiscountGrid = styled(Grid)`
  font-size: 15px;
  font-weight: 600;
  color: #606060;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 10px;
`;
