import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Grid, DialogContent, DialogActions, Slide,
} from '@mui/material';

import { InputSelectField } from './inputSelectField';
import {
  DialogWrapperContainer, DialogHeaderContainer, DialogHeaderText,
  DialogHeaderSubText, DialogActionButtonText, DialogDoneButton, DialogCancelButton
} from './newReport/newReportDialog.styles';
import {
  DetailCard, PaperCardGrid, DetailCardText, MessageBoxContainer, MessageBoxTextField,
  FormLabel
} from './editProductDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const EditProductDialog = ({
  open, state, selectedProdBatch, handleSave, handleChange,
  handleCloseModal
}) => {
  const { batchNo } = selectedProdBatch?.batch || {};
  const expectedQuantity = selectedProdBatch?.expectedQuantity;
  const quantityInStock = selectedProdBatch?.quantityInStock;
  let difference = quantityInStock - expectedQuantity;
  if (difference < 0) difference = `${difference}`;
  else difference = `+${difference}`;
  const formFields = [{
    name: 'reason', label: 'reason', type: 'select',
    options: ['in Stock', 'Damaged', 'Expired']
  }];

  const { note, reason } = state[selectedProdBatch.id] || {};

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => ('')}
      maxWidth="sm"
      fullWidth
    >
      <DialogWrapperContainer>
        <DialogHeaderContainer>
          <Grid item container xs={12} wrap="nowrap" alignItems="center">
            <DialogHeaderText>Reconcile Difference</DialogHeaderText>
          </Grid>
          <DialogHeaderSubText>Update stock item information</DialogHeaderSubText>
        </DialogHeaderContainer>

        <DialogContent style={{ overflowY: 'unset' }}>
          <DetailCard elevation={2}>
            <PaperCardGrid>
              <DetailCardText>
                <span>
                  Batch No.
                  {' '}
                  <b>{batchNo}</b>
                  &nbsp;
                  has a count difference of
                </span>
                &nbsp;
                <span style={{ color: `${difference !== 0 && '#EE0101'}` }}>
                  <b>{difference}</b>
                </span>
              </DetailCardText>
            </PaperCardGrid>
          </DetailCard>
          <Grid container style={{ marginBottom: '2em' }}>
            <FormLabel>Reason</FormLabel>
            {formFields.map((field) => (
              <InputSelectField
                field={field}
                value={reason}
                handleChange={(e) => handleChange(e, selectedProdBatch)}
              />
            ))}
          </Grid>
          <Grid>
            <FormLabel>Note</FormLabel>
            <MessageBoxContainer>
              <MessageBoxTextField
                label="Enter message"
                name="note"
                value={note || ''}
                multiline
                rows={5}
                onChange={(e) => handleChange(e, selectedProdBatch)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ backgroundColor: '#f0f0f0', marginBottom: '4em' }}
              />
            </MessageBoxContainer>
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: '8px 17px', marginTop: '1.5rem' }}>
          <DialogCancelButton onClick={handleCloseModal}>
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>
          <DialogDoneButton
            onClick={handleSave}
            disabled={!reason}
          >
            <DialogActionButtonText>
              Save
            </DialogActionButtonText>
          </DialogDoneButton>
        </DialogActions>
      </DialogWrapperContainer>
    </Dialog>
  );
};

EditProductDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.bool.isRequired,
  selectedProdBatch: PropTypes.instanceOf(Object).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default EditProductDialog;
