import React from 'react';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import { Grid, Select, MenuItem } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_BUSINESS_SIBLINGS } from '../../queries/affiliates';
import CustomButton from '../customComponents/customButton';
import {
  CDialog, DialogWrapper, SubText, HeaderText, DialogHeader, DialogContent, DialogActions,
  CloseIcon, CFormControl
} from './transferLocationDialog.styles';
import StockTransferTypes from '../../providers/reducers/stockTransfer/stockTransferTypes';

import { useStateValue } from '../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const TransferLocationDialog = ({ openDialog, closeDialog, handleTransferStock }) => {
  const [{
    stockTransfer: { transferLocation },
  }, dispatch] = Object.values(useStateValue());

  const { data } = useQuery(GET_BUSINESS_SIBLINGS, {
    fetchPolicy: 'cache-and-network',
  });
  const { businessSiblings } = data ?? {};

  const handleChange = (event) => {
    const { name, value } = event.target;
    const location = businessSiblings.find((biz) => biz.id === value);
    dispatch({
      type: StockTransferTypes.UPDATE_TRANSFER,
      payload: { transferLocation: location }
    });
  };

  return (
    <CDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <Grid item container xs={6}>
            <HeaderText>Transfer Location</HeaderText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={closeDialog} />
          </Grid>
          <SubText variant="caption">
            You are about to transfer products, you are required to enter the location of transfer
          </SubText>
        </DialogHeader>
        <DialogContent item container>
          <SubText variant="caption" style={{ fontWeight: 500 }}>Location</SubText>
          <CFormControl
            fullWidth
            variant="filled"
            size="small"
          >
            <Select
              id="select-transfer-business"
              value=""
              displayEmpty
              renderValue={(val) => (
                transferLocation
                  ? transferLocation?.name
                  : <span style={{ color: '#bdbdbd' }}>Select Address</span>
              )}
              onChange={handleChange}
              name="transferAddress"
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                getContentAnchorEl: null
              }}
            >
              {businessSiblings?.map((business) => (
                <MenuItem key={business.id} value={business.id}>
                  {business.name}
                </MenuItem>
              ))}
            </Select>
          </CFormControl>
        </DialogContent>
        <DialogActions item container>
          <CustomButton
            type="secondary"
            header
            onClick={closeDialog}
            style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            type="tertiary"
            header
            disabled={!transferLocation}
            onClick={handleTransferStock}
            style={{ width: '11rem', height: '2.7rem' }}
          >
            Transfer
          </CustomButton>
        </DialogActions>
      </DialogWrapper>
    </CDialog>
  );
};

TransferLocationDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleTransferStock: PropTypes.func.isRequired,
};

export default TransferLocationDialog;
