import {
  TableCell, Grid
} from '@mui/material';
import styled, { css } from 'styled-components';
import { ArrowDropDown } from '@mui/icons-material';

export const MainTableRow = styled(Grid)`
  width: 100%;
  align-items: center;

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F4F5F6;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: .9rem;

    &:nth-child(1) {
      width: 4%;
    }
    &:nth-child(2) {
      width: 16%;
    }
    &:nth-child(3) {
      width: 17%;
    }
    &:nth-child(4) {
      width: 12%;
    }
    &:nth-child(5) {
      width: 10%;
    }
    &:nth-child(6) {
      width: 14%;
    }
    &:nth-child(7) {
      width: 11%;
    }
    &:nth-child(8) {
      width: 9%;
    }
    &:nth-child(9) {
      width: 7%;
      padding-right: 0;
    }
  }
`;

const SoldOutStyles = css`
  background-color: #FBF777;
  color: #606060;
`;

const sentStyles = css`
  background-color: #DCFEDD;
  color: #07DE10;
`;

const rejectedStyles = css`
  background-color: #FBDADA;
  color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'sent':
      return sentStyles;
    case 'rejected':
      return rejectedStyles;
    default:
      return SoldOutStyles;
  }
};

export const ColorGrid = styled(Grid)`
  border-radius: 7px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 2.8;
  font-weight: 500;
  justify-content: center;

  ${getStyles}
`;

export const DownIcon = styled(ArrowDropDown)`
  height: 1.3rem;
  cursor: pointer;
`;
