import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Button } from '@mui/material';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { toast } from 'react-hot-toast';

import {
  TitleGrid, TitleTextContainer, PrimaryTitle, Desc, PaperWrapper, OptionWrapper, Title, SaveChanges,
  FooterSec, FieldGrid, SubTitle, ButtonWrapper, ButtonText, ProductWrapper
} from './productMerchandise.styles';
import SwitchToggle from '../../shared/switchToggle';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { PRODUCT_MERCHANDISE_QUERY, PRODUCT_SETTING_DISPLAY_QUERY } from '../../../queries/products';
import SearchPopper from './searchPopper';
import ReturnRow from './returnRow';
import { PlusIcon } from '../../../assets/svgs';
import { PRODUCT_SETTING_DISPLAY_MUTATION } from '../../../mutations/products';
import SuccessDialog from '../../shared/newSuccessDialog';
import MainContent from '../../customComponents/mainContent';

const initialState = {
  displayOption: '',
};

const ProductMerchandise = () => {
  const [toggle, setToggle] = useState(false);
  const [state, setState] = useState(initialState);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [currentRowSearch, setCurrentRowSearch] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [reload, setReload] = useState(false);
  const reloadHandler = () => setReload(!reload);
  const { displayOption } = state;

  const optionFields = [
    {
      name: 'displayOption',
      label: 'Display options',
      options: ['Available First', 'First In First Out', 'Latest To Oldest', 'Paid Advert']
    },
  ];

  const [getSearchedProducts, { data: settingData, loading }] = useLazyQuery(
    PRODUCT_MERCHANDISE_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        productName: search,
        status: 'enabled'
      }
    }
  );

  const {
    error, data, refetch
  } = useQuery(PRODUCT_SETTING_DISPLAY_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const { productDisplaySettings = {} } = data || {};

  useEffect(() => {
    if (productDisplaySettings && productDisplaySettings?.displayOption) {
      const { displayOption: option, enabled } = productDisplaySettings;
      let merchandiseType;
      if (option === 'first_in_first_out') {
        merchandiseType = 'First In First Out';
      } else if (option === 'oldest') {
        merchandiseType = 'Latest To Oldest';
      } else if (option === 'available_first') {
        merchandiseType = 'Available First';
      } else {
        merchandiseType = 'Paid Advert';
      }
      setToggle(enabled);
      setState({ displayOption: merchandiseType });
    }
  }, [productDisplaySettings]);

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  const handleSearch = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    setSearch(value);
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productName = value;
    setProducts([...products]);

    if (value && value.length > 2) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  useEffect(() => {
    setProducts([...products]);
  }, [products, reload]);

  useEffect(() => {
    if (productDisplaySettings && productDisplaySettings?.products) {
      const { products: dataProducts } = productDisplaySettings;
      const productsWithSN = dataProducts.map((product, index) => ({
        ...product,
        sn: index + 1,
      }));
      setProducts(productsWithSN);
    }
  }, [productDisplaySettings]);

  const createData = (product) => {
    const prod = new Product(product, 'erp');

    return {
      productId: prod?.id,
      brandName: prod?.brandName,
    };
  };

  useEffect(() => {
    if (settingData && settingData.erpProducts) {
      const sResults = settingData.erpProducts.map((product) => createData(product));
      setSearchedProducts(sResults);
      setSearchFieldEl(savedEl);
    }
    if (search === '') {
      handlePopperClose();
    }
  }, [settingData]);

  const addMoreHandler = () => {
    const next = products.length + 1;
    const newData = {
      sn: next,
      productId: '',
      productName: '',
      productPosition: 1,
    };
    setProducts([...products, newData]);
  };

  const handleProductSelection = (row) => {
    const {
      productId, brandName
    } = row;

    const exists = products.find(
      ({ productName }) => productName === brandName
    );
    if (exists) return toast.error('Product already exists');
    const newData = products.find(({ sn }) => sn === currentRowSearch);
    newData.productId = productId;
    newData.productName = brandName;
    newData.productPosition = 1;
    setProducts([...products]);
    handlePopperClose();
  };

  const counterHandler = (type, row, val) => {
    const { sn, productPosition } = row;
    switch (type) {
      case 'increment': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newPosition = productPosition + 1;
        newData.productPosition = newPosition;
        break;
      }
      case 'decrement': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newPosition = productPosition - 1;
        if (newPosition < 0) return;
        newData.productPosition = newPosition;
        break;
      }
      case 'change': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        if (val <= 0) return;
        newData.productPosition = Number(val);
        break;
      }

      default:
        break;
    }
    reloadHandler();
  };

  const handleChange = (event) => {
    const { value } = event.target;
    return setState({ ...state, displayOption: value });
  };
  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const [saveProduct, { loading: settingLoading }] = useMutation(PRODUCT_SETTING_DISPLAY_MUTATION);

  const saveProductMechandise = (sn, del = false) => {
    const hasDuplicatesPosition = products.some((product, index, array) => array.some((otherProduct, otherIndex) => index !== otherIndex && product.productPosition === otherProduct.productPosition));
    let filterData = products.filter((item) => item.productName !== '');
    if (del) {
      const newData = filterData.filter(({ sn: sid }) => sn !== sid);
      filterData = newData;
    }

    const product = filterData.map((prod) => {
      const { productId, productPosition, productName } = prod;
      return {
        productId,
        productPosition,
        productName
      };
    });
    let merchandiseType = '';
    if (displayOption === 'First In First Out') {
      merchandiseType = 'first_in_first_out';
    } else if (displayOption === 'Latest To Oldest') {
      merchandiseType = 'oldest';
    } else if (displayOption === 'Available First') {
      merchandiseType = 'available_first';
    } else {
      merchandiseType = 'paid_advert';
    }

    if (hasDuplicatesPosition && !del) return toast.error("Product can't have the same position");

    const variables = {
      products: product,
      enabled: toggle,
      displayOption: merchandiseType
    };

    saveProduct({
      variables: { ...variables }
    })
      .then(() => {
        refetch();
        if (!del) {
          toggleSuccessModal();
        }
        setIsDelete(false);
      })
      .catch(() => {});
  };

  const removeRowHandler = (sn) => {
    setIsDelete(true);
    const newData = products.filter(({ sn: sid }) => sn !== sid);
    setProducts(newData);
    saveProductMechandise(sn, true);
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, placeholder
    } = field;
    const value = state[fieldName];
    return (
      <CustomSelectInputBase
        field={field}
        value={value}
        placeholder={placeholder}
        handleChange={handleChange}
        handleCreditDaysOpen={() => ({})}
        creditDays={() => ({})}
        showCheckBox={false}
      />
    );
  };
  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '0px 30px' }}>
        <TitleGrid container item>
          <TitleTextContainer item>
            <PrimaryTitle variant="h5">Product Merchandising Tool</PrimaryTitle>
            <Desc variant="caption">Choose how products will be displayed</Desc>
          </TitleTextContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <Grid sm={5}>
            <TitleTextContainer item>
              <SubTitle variant="h5">Product display General Settings</SubTitle>
              <Desc variant="caption">
                Select how products will be displayed
              </Desc>
            </TitleTextContainer>
            <OptionWrapper container>
              <Title>Sort Products by</Title>
              <SwitchToggle
                checkedState={toggle}
                setCheckedState={setToggle}
              />
            </OptionWrapper>
            <FieldGrid container>
              {optionFields.map((field) => returnTextField(field))}
            </FieldGrid>

            { displayOption === 'Paid Advert' && toggle ? (
              <ProductWrapper>
                <Title style={{ paddingLeft: '7px', paddingBottom: '3px' }}>Select Products to Pin</Title>
                {products?.map((item, index) => {
                  const { sn } = item;
                  return (
                    <ReturnRow
                      key={sn}
                      row={item}
                      index={index}
                      loading={loading}
                      counterHandler={counterHandler}
                      handleSearch={handleSearch}
                      removeRowHandler={removeRowHandler}
                      isDelete={isDelete}
                    />
                  );
                })}
                <ButtonWrapper container>
                  <Button
                    onClick={addMoreHandler}
                    style={{
                      width: '11rem',
                      height: '3rem',
                      border: 'none',
                      justifyContent: 'end'
                    }}
                  >
                    <PlusIcon
                      style={{
                        width: '.9rem', height: '.9rem',
                      }}
                    />
                    <ButtonText>Add Product</ButtonText>
                  </Button>
                </ButtonWrapper>

              </ProductWrapper>
            ) : ''}
            <FooterSec>
              <SaveChanges onClick={saveProductMechandise} disabled={settingLoading} data-testId="saveSettings">
                { (settingLoading && !isDelete) ? <CircularProgress style={{ color: 'white' }} size={30} /> : 'Save' }
              </SaveChanges>

            </FooterSec>
          </Grid>
        </PaperWrapper>
      </Grid>
      <SuccessDialog
        openConfirmation={successModal}
        handleClose={toggleSuccessModal}
        title="Settings Saved"
        discreption="Your product merchandise settings has been saved successfully!"
      />
      <SearchPopper
        searchFieldEl={searchFieldEl}
        handleClose={handlePopperClose}
        searchedProducts={searchedProducts}
        handleProductSelection={handleProductSelection}
      />
    </MainContent>
  );
};

export default ProductMerchandise;
