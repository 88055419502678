import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { RenderPopper } from '../../../../utils/utils';
import {
  ProductItem,
  SearchPaper,
  ProdName,
} from './searchPopper.styles';

const SearchPopper = ({
  searchFieldEl,
  handleClose,
  searchedCategory,
  handleCategorySelection
}) => {
  const open = Boolean(searchFieldEl);

  return (
    <RenderPopper
      open={open}
      anchorEl={searchFieldEl}
      onClickAway={handleClose}
      popperPlacement="bottom-start"
      className="mpAdmin-uat-ordersPageFilter-customerPopper"
      modifiers={{
        offset: {
          enabled: true,
          offset: '-3vw, 1vh'
        }
      }}
    >
      <SearchPaper elevation={0} data-testid="searchPopper">
        {searchedCategory?.map((category) => (
          <ProductItem
            key={category}
            container
            onClick={() => handleCategorySelection(category.categoryName)}
          >
            <Grid item md={8} xs={4}>
              <ProdName variant="caption">{category.categoryName}</ProdName>
            </Grid>
          </ProductItem>
        ))}
      </SearchPaper>
    </RenderPopper>
  );
};

SearchPopper.propTypes = {
  searchFieldEl: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  searchedCategory: PropTypes.instanceOf(Array).isRequired,
  handleCategorySelection: PropTypes.func.isRequired
};

export default SearchPopper;
