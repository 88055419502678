import {
  TableCell, TableHead, TableRow, MenuItem, OutlinedInput
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableCellStyled = styled(TableCell)`
  font-size: .9rem;
  font-weight: 600;
  border-bottom: none;
`;

export const TableCellBStyled = styled(TableCell)`
  border-bottom: none;
`;

export const TableHeadStyled = styled(TableHead)`
  background: #f4f5f7;
  font-weight: bold;
`;

export const TableRowStyled = styled(TableRow)`
    cursor: pointer;
    border: none;
    ${({ cond }) => cond && `
        background: #f4f5f7;
    `}
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 5rem 0.9rem 1rem;
  font-size: 15px;
`;

export const OutlinedInputStyled = styled(OutlinedInput)`
  width: 100%;
  margin: 1rem 0;
  border-radius: 1rem !important;
`;
