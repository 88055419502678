import {
  Grid, Typography, TextField, Dialog
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
    max-height: 675px;
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 1rem 1.5rem;
    width: 100%;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
  color: #235A91;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 20px;
  margin-bottom: 4px;
`;

export const LocationTextField = styled(TextField)`
  background-color: rgba(243, 249, 255, 1) !important;
  height: 305px;
  border-radius: 8px;

  & .MuiInputBase-root {
    height: 305px;
    border-radius: 8px;
    padding: 0 1rem;
  }
`;

export const ProfileTypo = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const SaveText = styled(Typography)`
  font-size: 12px;
  font-weight: 700;
`;
