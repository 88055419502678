import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Close } from '@mui/icons-material';
import {
  CloseStyled,
  DialogContentStyled,
  NoteButton,
  NoteDescription, NoteDialog, NoteHeader, NoteInfo, NoteInfoContainer, OutletContactLine
} from './dispatch.styles';
import { DeliveryAddressCardHeader, DeliveryAddressCardHeaderTitle } from '../../cart/cart.styles';

const NoteRow = ({ note, handleAddNote }) => {
  const {
    content, profile, created
  } = note;
  const [open, setOpen] = useState(false);
  const { lastName, firstName } = profile;
  const returnDate = moment(created).format('HH:mm a');
  const truncateString = (data, maxLength = 75) => {
    if (data?.length > maxLength) return `${data.substring(0, maxLength)}`;
    return data;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleNote = () => {
    handleClose();
    handleAddNote();
  };
  return (
    <>
      <NoteInfoContainer>
        <NoteHeader container>
          <OutletContactLine>
            {firstName}
            &nbsp;
            {lastName}
          </OutletContactLine>
          <OutletContactLine>{ returnDate }</OutletContactLine>
        </NoteHeader>
        <NoteInfo>
          <NoteDescription onClick={handleOpen}>
            { truncateString(content) }
            <span style={{ cursor: 'pointer' }}><b> . . .</b></span>
          </NoteDescription>
        </NoteInfo>
      </NoteInfoContainer>
      <NoteDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogContentStyled>
          <CloseStyled>
            <Close
              fontSize="small"
              onClick={handleClose}
              style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }}
            />
          </CloseStyled>
          <DeliveryAddressCardHeader>
            <DeliveryAddressCardHeaderTitle>Note</DeliveryAddressCardHeaderTitle>
            <NoteButton
              onClick={handleNote}
              type="button"
            >
              Add Note
            </NoteButton>
          </DeliveryAddressCardHeader>
          <NoteInfoContainer>
            <NoteHeader container>
              <OutletContactLine>
                {firstName}
                &nbsp;
                {lastName}
              </OutletContactLine>
              <OutletContactLine>{ returnDate }</OutletContactLine>
            </NoteHeader>
            <NoteInfo>
              <NoteDescription>
                { content }
              </NoteDescription>
            </NoteInfo>
          </NoteInfoContainer>
        </DialogContentStyled>
      </NoteDialog>
    </>
  );
};

NoteRow.propTypes = {
  note: PropTypes.shape(Object),
  handleAddNote: PropTypes.func.isRequired,
};

NoteRow.defaultProps = {
  note: {},
};

export default NoteRow;
