import { styled } from '@mui/material/styles';
import {
  Typography, Grid, IconButton, Paper, CircularProgress
} from '@mui/material';

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const PromoGrid = styled(Grid)`
  padding: 2rem 1.5rem;
`;

export const ActionGrid = styled(Grid)`
  padding-top: 2rem;
  justify-content: flex-end;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const TitleGrid = styled(Grid)`
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 700;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 24px;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;
