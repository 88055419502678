import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { PromoGrid, PromoOffer, PromoImg } from './productCard.styles';
import offerIcon from '../../../assets/images/offer-Icon.png';

export const PromoInfo = ({ info }) => (
  <PromoGrid container item>
    <Grid item>
      <PromoImg alt="promo image" src={offerIcon} />
    </Grid>
    <Grid item>
      <PromoOffer>
        {' '}
        {info}
      </PromoOffer>
    </Grid>
  </PromoGrid>
);
PromoInfo.propTypes = {
  info: PropTypes.string.isRequired,
};
