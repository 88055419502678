import React, { useState } from 'react';
import { Grid, Hidden, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainContent from '../customComponents/mainContent';
import DashboardDateRanges from '../shared/dashboardDateRanges/dashboardDateRanges';
import Invoices from './invoices';
import AddInvoiceDialog from './addInvoiceDialog';
import { useStateValue } from '../../providers/stateProvider';
import { dateButtons, iconDateRange } from '../shared/dateRangeConverter';
import CustomSearchField from '../shared/CustomSearchField';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';

import {
  TitleGridContainer, TitleTextGridContainer, TextWrapper, PrimaryTitle,
  SecondaryTitle, ButtonsWrapper, SubContentHandler, SearchGrid, PaperWrapper
} from './invoices.styles';
import {
  SearchButton,
  SearchContainer,
  SearchImage,
  SearchTextField
} from '../orders/newOrder/newOrderContainer.styles';
import SearchIcon from '../../assets/images/Search Icon.png';
import { OrderButton, OrderButtonText } from '../orders/shared/titleGrid.styles';
import BusinessActionTypes from '../../providers/reducers/business/businessTypes';

const InvoicesContainer = () => {
  const [{
    business: {
      businessId,
      dashboardState: {
        active,
        startDate,
        endDate,
        range
      }
    },
    navbar: { openSideDrawer },
  }, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();
  const handleDateButtons = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;

    // const { start, end, dateRange } = dateButtons(event);
    dispatch({
      type: BusinessActionTypes.UPDATE_DASHBOARD_STATE,
      payload: {
        active: dateRange,
        startDate: dateFrom,
        endDate: dateTo,
        range: dateRange
      }
    });
  };

  const handleIconDateRange = (direction) => {
    const { start, end, dateRange } = iconDateRange(direction, active, startDate, endDate);
    dispatch({
      type: BusinessActionTypes.UPDATE_DASHBOARD_STATE,
      payload: {
        startDate: start,
        endDate: end,
        range: dateRange
      }
    });
  };

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  // const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    text: '',
  };

  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const { currentPage, currentPageCount, text } = state;
  const [searchable, setSearchable] = useState('');

  // const handleChangePage = (_, newPage) => {
  //   setState({ ...state, currentPage: newPage + 1 });
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   sessionStorage.setItem('rowsPerPage', +event.target.value);
  //   setState({ ...state, currentPageCount: +event.target.value });
  // };

  const handleSearchTextUpdate = ({ target: { value } }) => {
    setSearchable(value);
  };

  const handleRowClick = (id) => {
    navigate(`/invoices/${id}/details`);
  };

  return (
    <MainContent>
      <Grid container style={{ padding: '30px' }}>
        <TitleGridContainer container item>
          <Grid item xs={8}>
            <TextWrapper>
              <PrimaryTitle variant="h5">Invoice</PrimaryTitle>
              {/* <SecondaryTitle>
                Order invoices added to the inventory
              </SecondaryTitle> */}
            </TextWrapper>
          </Grid>
          {/* <Hidden smDown>
            <Grid container lg={5} md={7} alignItems="center">
              <DashboardDateRanges
                active={active}
                xs={12}
                range={range}
                handleDateButtons={handleDateButtons}
                handleIconDateRange={handleIconDateRange}
              />
            </Grid>
          </Hidden> */}
          <Grid item xs={4} justifyContent="flex-end">
            <ButtonsWrapper>
              <OrderButton onClick={() => setInvoiceDialogOpen(true)}>
                <OrderButtonText>Create New Invoice</OrderButtonText>
              </OrderButton>
            </ButtonsWrapper>
          </Grid>
          {/* <Hidden mdUp>
            <Grid item container justifyContent="center" style={{ marginTop: '2rem' }}>
              <Grid container sm={7} alignItems="center">
                <DashboardDateRanges
                  active={active}
                  xs={12}
                  range={range}
                  handleDateButtons={handleDateButtons}
                  handleIconDateRange={handleIconDateRange}
                />
              </Grid>
            </Grid>
          </Hidden> */}
        </TitleGridContainer>
      </Grid>
      <PaperWrapper elevation={0}>
        <SearchGrid item container spacing={2}>
          <Grid item container xs={openSideDrawer ? 9.5 : 10}>
            <CustomSearchField
              name="search"
              handleChange={(e) => handleSearchTextUpdate(e)}
              style={{ width: '100%' }}
              autoFocus
              value={searchable}
              placeholder="Search By Order Number, Invoice Number or Supplier"
            />
          </Grid>
          <Grid container item xs={openSideDrawer ? 2.5 : 2}>
            <CustomDateRanges
              handleSubmit={handleDateButtons}
              xs={12}
              styles={{
                height: '3rem', width: '13rem', fontSize: '11px', border: '1px solid #bababa'
              }}
            />
          </Grid>
        </SearchGrid>
        {/* <SubContentHandler container xs={12} alignItems="center">
          <Grid item sm={10}>
            <SearchContainer container item xs={12}>
              <SearchTextField
                variant="outlined"
                label=""
                autoFocus
                value={searchable}
                onChange={(e) => handleSearchTextUpdate(e)}
                placeholder="Search invoices"
                fullWidth
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <SearchButton
                        aria-label="search products"
                        edge="end"
                        onClick={() => setState({ ...state, text: searchable })}
                      >
                        <SearchImage alt="search icon" src={SearchIcon} />
                      </SearchButton>
                    </InputAdornment>
                }}
              />
            </SearchContainer>
          </Grid>
        </SubContentHandler> */}
        <Invoices
          // handleChangePage={handleChangePage}
          // handleChangeRowsPerPage={handleChangeRowsPerPage}
          setPageCount={setPageCount}
          setPageNumber={setPageNumber}
          handleRowClick={handleRowClick}
          page={pageNumber}
          text={text}
          currentPageCount={pageCount}
        />

        <AddInvoiceDialog
          bizId={businessId || localStorage.getItem('ogarx_business_id')}
          dialogOpen={invoiceDialogOpen}
          closeDialog={() => setInvoiceDialogOpen(false)}
        />
      </PaperWrapper>
    </MainContent>
  );
};

export default InvoicesContainer;
