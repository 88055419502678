const ReturnActionTypes = {
  UPDATE_SALE: 'UPDATE_SALE',
  TOGGLE_RETURN_ITEM: 'TOGGLE_RETURN_ITEM',
  SAVE_SOLD_ITEMS: 'SAVE_SOLD_ITEMS',
  TOGGLE_SELECT_ALL: 'TOGGLE_SELECT_ALL',
  CHANGE_RETURN_ITEM_QUANTITY: 'CHANGE_RETURN_ITEM_QUANTITY',
  CLEAR_RETURN_CART: 'CLEAR_RETURN_CART',
  CHANGE_RETURN_REASON: 'CHANGE_RETURN_REASON',
  TOGGLE_ITEM_RESELLABLE: 'TOGGLE_ITEM_RESELLABLE',
  TOGGLE_DIALOG_OPEN: 'TOGGLE_DIALOG_OPEN',
  UPDATE_CART: 'UPDATE_CART',
};

export default ReturnActionTypes;
