import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatusesGrid = styled(Grid)`
  padding: 0.5rem 1.5rem;
  align-items: center;
  margin-bottom: .8rem;
  width: ${({ drawer }) => (drawer ? '75%' : '82%')};
  padding: 0 1rem;

  /* @media(min-width: 992px) {
    padding: 1.8rem;
  } */
`;

export const DateWrapper = styled(Grid)`
  width: ${({ drawer }) => (drawer ? '22%' : '18%')};
`;
