import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RenderPopper } from '../../utils/utils';
import {
  CList, CListItem, CListItemText
} from './actionPopper.styles';

export default function ActionPopper({
  action, row, handleDelete, handleClose
}) {
  const navigate = useNavigate();
  const { id } = row;
  const open = Boolean(action);

  return (
    <RenderPopper
      open={open}
      anchorEl={action}
      popperPlacement="bottom"
      onClickAway={handleClose}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <Grid container item style={{ padding: '0 .5rem' }}>
        <CList>
          <CListItem disableGutters button onClick={() => navigate(`/promotions/details/${id}`)}>
            <CListItemText primary="View Promo Details" />
          </CListItem>
          <CListItem disableGutters button onClick={handleDelete}>
            <CListItemText primary="Delete Promo" />
          </CListItem>
        </CList>
      </Grid>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
  handleClose: PropTypes.func.isRequired
};
