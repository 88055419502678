import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo, CDialog
} from './actionPopper.styles';
import { useStateValue } from '../../../providers/stateProvider';

export default function ActionPopper({
  action, row, handleAction, handleClose
}) {
  const { status } = row;
  const open = Boolean(action);
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  return (
    <RenderPopper
      open={open}
      anchorEl={action}
      popperPlacement="bottom"
      onClickAway={handleClose}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, 'view users', row)}
          style={{ pointerEvents: !userPermissions?.includes('role_permissions_view_user') ? 'none' : '' }}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              View User
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, 'edit role', row)}
          style={{ pointerEvents: !userPermissions?.includes('role_permissions_edit_permissions') ? 'none' : '' }}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Edit Role
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, 'delete role', row)}
          style={{ pointerEvents: !userPermissions?.includes('role_permissions_delete_role') ? 'none' : '' }}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Delete Role
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
  handleClose: PropTypes.func.isRequired
};
