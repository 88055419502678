import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableBody
} from '@mui/material';
import {
  TableCellStyled,
  TableCellBodyStyled,
  TableRowStyled,
  TableHeadStyled,
  CardTitle
} from '../return-details.style';
import currencyFormatter from '../../../../../utils/currencyFormatter';

const PaymentDetails = ({ row }) => {
  let cash = 0.0;
  let card = 0.0;
  let bankTransfer = 0.0;
  let storeCredit = 0.0;
  let customerLoyalty = 0.0;

  const {
    sale: {
      total,
      totalToPay,
      salesPayment = [],
    }
  } = row;

  salesPayment.forEach((method) => {
    if (method.paymentMethod === 'CASH') {
      cash = method.rawAmount;
    }
    if (method.paymentMethod === 'CARD') {
      card = method.rawAmount;
    }
    if (method.paymentMethod === 'BANK_TRANSFER') {
      bankTransfer = method.rawAmount;
    }
    if (method.paymentMethod === 'CREDIT') {
      storeCredit = method.rawAmount;
    }
    if (method.paymentMethod === 'LOYALTY') {
      customerLoyalty = method.rawAmount;
    }
    return null;
  });

  return (
    <Box p={4} bgcolor="background.paper" borderRadius={8} mt={4}>
      <CardTitle>Payment Details</CardTitle>
      <TableContainer style={{ marginTop: '1rem' }}>
        <Table style={{ width: '100%' }}>
          <TableHeadStyled>
            <TableRowStyled>
              <TableCellStyled>SubTotal</TableCellStyled>
              <TableCellStyled>Purchase Total</TableCellStyled>
              <TableCellStyled>Cash</TableCellStyled>
              <TableCellStyled>Card</TableCellStyled>
              <TableCellStyled>Bank Transfer</TableCellStyled>
              <TableCellStyled>Store Credit</TableCellStyled>
              <TableCellStyled>Customer Loyalty</TableCellStyled>
            </TableRowStyled>
          </TableHeadStyled>
          <TableBody>
            <TableRow>
              <TableCellBodyStyled>
                {currencyFormatter(total)}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {currencyFormatter(totalToPay)}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {currencyFormatter(cash)}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {currencyFormatter(card)}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {currencyFormatter(bankTransfer)}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {currencyFormatter(storeCredit)}
              </TableCellBodyStyled>
              <TableCellBodyStyled>
                {currencyFormatter(customerLoyalty)}
              </TableCellBodyStyled>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

PaymentDetails.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired
};

export default PaymentDetails;
