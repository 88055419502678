import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import PromoHeaderLoader from '../shared/promoHeaderLoader';
import {
  HeaderPaperWrapper, SmallTitle, SmallerTitle, SmallGrid
} from './promoHeader.styles';

const promoHeaders = ['Promo Name', 'Promo Description', 'Promo Value', 'Date Created', 'Start Date', 'End Date'];

const PromoHeader = ({ promotion, loading }) => {
  const isEven = (n) => n % 2 === 0;

  const getVal = (value) => {
    if (promotion?.unit === 'PERCENTAGE') return `${value}%`;
    return `₦ ${value}`;
  };

  const returnValue = (header) => {
    switch (header) {
      case 'Promo Name':
        return promotion?.name || 'N/A';
      case 'Promo Description':
        return promotion?.description || 'N/A';
      case 'Promo Value':
        return getVal(promotion?.value) || 'N/A';
      case 'Date Created':
        return moment(promotion?.dateCreated).format('DD MMM YYYY') || 'N/A';
      case 'Start Date':
        return moment(promotion?.startDate).format('DD MMM YYYY') || 'N/A';
      case 'End Date':
        return moment(promotion?.endDate).format('DD MMM YYYY') || 'N/A';
      default:
        return null;
    }
  };

  return (
    <HeaderPaperWrapper elevation={0}>
      {loading ? <PromoHeaderLoader /> : (
        <Grid container item spacing={3}>
          {promoHeaders.map((header, indx) => (
            <SmallGrid item container xs={isEven(indx) ? 4 : 8}>
              <SmallTitle variant="caption">{header}</SmallTitle>
              <SmallerTitle variant="caption">{returnValue(header)}</SmallerTitle>
            </SmallGrid>
          ))}
        </Grid>
      )}
    </HeaderPaperWrapper>
  );
};

PromoHeader.propTypes = {
  promotion: PropTypes.shape(Object),
  loading: PropTypes.bool
};

PromoHeader.defaultProps = {
  promotion: {},
  loading: false
};

export default PromoHeader;
