const paginationShowing = (itemList, currentPageCount) => {
  const showing = (position = 'start') => {
    const num = itemList && itemList.length;
    switch (position) {
      case 'start':
        return 1;
      case 'end':
        return num < currentPageCount ? num : currentPageCount;
      default:
        return null;
    }
  };

  return showing;
};

export const getPagination = (presentPage, presentPageCount, totalItemsCount) => {
  const start = ((presentPage - 1) * presentPageCount) + 1;
  const nextPageCount = start + presentPageCount;
  const end = nextPageCount < totalItemsCount ? nextPageCount : totalItemsCount;
  return {
    start,
    end
  };
};
export default paginationShowing;
