const promotions = {
  id: '',
  promoName: '',
  description: '',
  promoInput: 'By Amount',
  promoValue: '',
  startDate: '',
  endDate: '',
  status: ''
};

export default promotions;
