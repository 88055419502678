import { gql } from '@apollo/client';

export const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation updateNotification($notificationId: ID, $status: String!) {
    updateNotification(notificationId: $notificationId, status: $status) {
      notification {
        id
        title
        message
        notificationType
        status
        dateCreated
        lastUpdated
        action
        pathname
        meta
        businessUser {
          id
          user {
            id
            username
          }
        }
      }
    }
  }
`;

export const UPDATE_ALL_NOTIFICATIONS_MUTATION = gql`
  mutation updateAllNotifications($businessUser: ID, $status: String!) {
    updateAllNotifications(businessUser: $businessUser, status: $status) {
      notification {
        id
        title
        message
        notificationType
        status
        dateCreated
        lastUpdated
        action
        pathname
        meta
        businessUser {
          id
          user {
            id
            username
          }
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateBusinessNotificationSettings(
    $expiryActive: Boolean!
    $expiryTimelines: [Int]!
    $lowOnStockActive: Boolean!
    $lowOnStockTimelines: [Int]!
    $lowOnStockAuto: Boolean!
  ) {
    updateBusinessNotificationSettings(
      expiryActive: $expiryActive
      expiryTimelines: $expiryTimelines
      lowOnStockActive: $lowOnStockActive
      lowOnStockTimelines: $lowOnStockTimelines
      lowOnStockAuto: $lowOnStockAuto
    ) {
      message
    }
  }
`;

export const CHANGE_NOTIFICATION_STATUS = gql`
  mutation updateErpNotification($notificationId: Int!, $status: String!) {
    updateErpNotification(notificationId: $notificationId, status: $status) {
      message
    }
  }
`;

export const UPDATE_ALL_NOTIFICATION_STATUS = gql`
  mutation updateAllErpNotification($status: String!) {
    updateAllErpNotification(status: $status) {
      message
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
mutation subscribeToProductNotification($productId: String!){
  subscribeToProductNotification(productId: $productId){
       message
   }
}
`;
