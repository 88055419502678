import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import paginationShowing from '../../../../utils/pagination';
import CTablePagination from '../../../customComponents/cTablePagination';
import RenderPagination from '../../../shared/pagination';
import RoleCard from './roleCard';

import {
  CardsWrapper,
  FilterBar,
  FooterGrid,
  MainWrapper
} from './mainContent.styles';
import RolePermissions from './rolePermissions';

const MainContent = ({
  businessRoles, totalNumber, handleChangePage, handleChangeRowsPerPage,
  currentPage, currentPageCount
}) => {
  // const [totalNumber, setTotalNumber] = useState(0);
  // const [allUsers, setAllUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});

  useEffect(() => {
    if (businessRoles) {
      setSelectedRole(businessRoles[1]);
    }
  }, [businessRoles]);

  const showing = paginationShowing(businessRoles, totalNumber);
  return (
    <MainWrapper item container direction="column">
      <FilterBar item container>
        <RenderPagination
          from={showing()}
          to={showing('end')}
          total={totalNumber}
          noun="Roles"
        />
      </FilterBar>
      <CardsWrapper item container spacing={4}>
        {businessRoles.map((businessRole) => (
          <RoleCard
            key={businessRole.id}
            businessRole={businessRole}
            handleRoleSelect={() => setSelectedRole(businessRole)}
          />
        ))}
        {(businessRoles.length === 0) && 'No Roles yet'}
      </CardsWrapper>
      <FooterGrid item container>
        <CTablePagination
          rowsPerPageOptions={[6, 8, 12]}
          component="div"
          count={totalNumber}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
      <RolePermissions selectedRole={selectedRole} />
    </MainWrapper>
  );
};

MainContent.propTypes = {
  businessRoles: PropTypes.shape(Array),
  totalNumber: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  currentPageCount: PropTypes.number
};

MainContent.defaultProps = {
  businessRoles: [],
  totalNumber: 0,
  currentPage: 1,
  currentPageCount: 1
};

export default MainContent;
