import { styled } from '@mui/material/styles';
import {
  Button, Grid, Typography, Box
} from '@mui/material';

export const GridContainer = styled(Button)`
    padding: 15px 25px;
    width: 100%;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 10px;
      width: 100%;
      height: 3.063rem;
      margin-bottom: .5rem;
    }
    &:hover {
      background-color: #F3F9FF;
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
`;

export const ItemWrapper = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 1rem;
    padding-bottom: 1rem;
`;
