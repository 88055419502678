import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox } from '@mui/material';
import { Close } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  BodyCell, MainTableRow, EditText, DialogContentStyled, CloseStyled,
  RoleHandle, RoleNameTypo, SaveButton, CancelButton, ButtonGrid, ContentDialog, RoleWrapper
} from './returnRow.styles';
import { Dots } from '../../../assets/svgs';
import ActionPopper from './actionPopper';
import { useStateValue } from '../../../providers/stateProvider';
import { DISABLE_BUSINESS_USER, UPDATE_USER_ROLE } from '../../../mutations/user';
import ConfirmDialog from '../roles/confirmDialog';
import SuccessDialog from './successDialog';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

const ReturnRow = ({
  id, index, fullName, user, systemRole, roleName, refetch
}) => {
  const [open, setOpen] = useState(false);
  const [selectRole, setSelectRole] = useState('');
  const [roleNames, setRoleName] = useState('');
  const [action, setAction] = useState(null);
  const [assignUserRole] = useMutation(UPDATE_USER_ROLE);
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectRoleId, setSelectRoleId] = useState('');
  const [disable, setDisable] = useState(false);
  const { username } = user;

  const [disableBusinessUser] = useMutation(DISABLE_BUSINESS_USER);

  const [{
    business: {
      roleId,
    }
  }] = Object.values(useStateValue());
  const roles = [
    { roleName: 'Chain Manager', type: 'chainManager' },
    { roleName: 'Super Admin', type: 'superAdmin' },
    { roleName: 'Store Manager', type: 'storeManager' },
    { roleName: 'Sales Rep', type: 'salesRep' },
  ];

  useEffect(() => {
    if (systemRole) {
      setRoleName(systemRole?.name);
    }
  }, [systemRole]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const toggleDialog = () => {
    setOpen(!open);
  };

  const toggleSuccessModal = () => {
    setOpenModal(false);
    setSuccessModal(!successModal);
    refetch();
  };
  const handleDisableUser = () => {
    const variables = {
      businessUserId: id
    };
    disableBusinessUser({ variables })
      .then(({ data }) => {
        const { message } = data?.disableBusinessUser || {};
        toggleSuccessModal();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const handleAction = (event, type) => {
    if (type === 'disable user') {
      setDisable(true);
      setOpenModal(!openModal);
    } else {
      setOpen(true);
    }
    handleOptions(event);
  };
  const handleSelectRole = (event, roleID) => {
    const { value } = event.target;
    if (value === selectRole) {
      setSelectRole('');
      setSelectRoleId('');
    } else {
      setSelectRole(value);
      setSelectRoleId(roleID);
    }
  };
  const toggleConfirmation = () => {
    setOpenModal(!openModal);
  };

  const handleChangeRole = () => {
    toggleDialog();
    toggleConfirmation();
  };
  const handleSave = () => {
    const variables = {
      roleId: selectRoleId,
      businessUserId: id
    };
    assignUserRole({ variables })
      .then(({ data }) => {
        const { message } = data?.createSystemRole || {};
        toggleSuccessModal();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };
  const handleConfirm = () => {
    if (disable) return handleDisableUser();
    return handleSave();
  };

  return (
    <>
      <MainTableRow item container key={index}>
        <BodyCell>
          {index + 1}
        </BodyCell>
        <BodyCell>
          {fullName}
        </BodyCell>
        <BodyCell>
          {username}
        </BodyCell>
        <BodyCell>
          {roleNames || '-'}
        </BodyCell>
        <BodyCell style={{ cursor: 'pointer' }}>
          <Dots fontSize="large" onClick={handleOptions} />
        </BodyCell>
        <ActionPopper
          action={action}
          handleAction={handleAction}
          handleClose={handleCloseModal}
        />
      </MainTableRow>

      <ContentDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <CloseStyled>
            <Close
              fontSize="small"
              onClick={handleClose}
              style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }}
            />
          </CloseStyled>
          <RoleWrapper>
            {roleName?.map(({ name, id: roleID }) => (
              <Grid key={roleID} xs={12}>
                <RoleHandle container justifyContent="space-between">
                  <Grid item xs={2}>
                    <Checkbox
                      onClick={(e) => handleSelectRole(e, roleID)}
                      icon={icon}
                      value={name}
                      checkedIcon={checkedIcon}
                      style={{ color: '#615958' }}
                      checked={selectRole === name}
                    />
                  </Grid>
                  <Grid item container xs={10} style={{ alignContent: 'center' }}>
                    <RoleNameTypo>{name}</RoleNameTypo>
                  </Grid>
                </RoleHandle>
              </Grid>
            ))}
          </RoleWrapper>
          <ButtonGrid container spacing={3}>
            <Grid item md={6}>
              <CancelButton
                variant="outlined"
                item
                fullWidth
                md={6}
                onClick={handleClose}
              >
                Cancel
              </CancelButton>
            </Grid>
            <Grid item md={6}>
              <SaveButton
                variant="contained"
                item
                md={6}
                fullWidth
                onClick={handleChangeRole}
              >
                Save
              </SaveButton>
            </Grid>
          </ButtonGrid>
        </DialogContentStyled>
      </ContentDialog>
      <ConfirmDialog
        openConfirmation={openModal}
        handleClose={toggleConfirmation}
        title={disable ? 'Disable User' : 'Are you sure you want to change role?'}
        discreption={disable ? 'Hi Pharm, are you sure you want to disable this user?' : 'Hi Pharm, are you sure you want to change this role?'}
        buttonTyle="double"
        imgType="warning"
        handleConfirm={handleConfirm}
        buttonTitle={disable ? 'Yes, Disable' : ''}
      />
      <SuccessDialog
        openConfirmation={successModal}
        handleClose={toggleSuccessModal}
        title={disable ? 'User Disable successfully' : ''}
        discreption={disable ? 'Hi Pharm, user has been successfully disable!' : ''}
      />
    </>
  );
};

ReturnRow.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired
};

ReturnRow.defaultProps = {
};

export default ReturnRow;
