import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Grid, LinearProgress, Step, StepLabel
} from '@mui/material';
import {
  DispatchStepper, DispatchTypo, DispatchSubTypo, StepperIcon,
  StepperConnector, StepContainer, StepWrapper,
  ProgressBar,
  Label,
  CustomCheckbox
} from './dispatchContainer.styles';
import CompleteIcon from '../../../assets/images/orderDispatch/Complete Step.png';
import IncompleteIcon from '../../../assets/images/orderDispatch/Incomplete Step.png';

const StepIcon = ({ completed }) => (
  <div>
    {completed
      ? <StepperIcon src={CompleteIcon} alt="complete" />
      : <StepperIcon src={IncompleteIcon} alt="process" />}
  </div>
);
StepIcon.propTypes = {
  completed: PropTypes.bool.isRequired,
};

const getSteps = () => [
  { main: 'Sort', sub: 'Prepare order for dispatch' },
  { main: 'Dispatch', sub: 'Order ready for dispatch' },
  { main: 'Deliver', sub: 'Confirm affiliate received order' },
];

const Stepper = ({ activeStep }) => {
  const steps = getSteps();

  return (
    <Grid item container>
      <ProgressBar variant="determinate" value={(activeStep / 3) * 100} />
      <DispatchStepper
        alternativeLabel
        activeStep={activeStep}
        // connector={<StepperConnector />}
      >
        {steps.map(({ main }, index) => (
          <StepContainer key={main}>
            <StepWrapper>
              <Label>
                <DispatchTypo>{ main }</DispatchTypo>
                <CustomCheckbox
                  checked={index < activeStep}
                  color="primary"
                />
              </Label>
            </StepWrapper>
          </StepContainer>
        ))}
      </DispatchStepper>
    </Grid>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired
};

export default Stepper;
