import {
  Grid, Typography, IconButton, CircularProgress,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)`
  & > * {
    padding: .5rem 3rem;
    width: 100%;
  }
`;

export const DialogTitleWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 600;
  color: #424242;
  font-size: 30px;
  margin-bottom: 10px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 16px;
  color: #424242;
  margin-bottom: 15px;
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const DividerLine = styled('hr')`
  border: 1px solid #CCCCCC;
  width:96%;
  min-width: 300px;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #E0E0E0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;
export const DialogActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;
export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const DialogOkButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  background-color: #FCED50;
  color: #424242;
  margin-bottom: 40px;

  &:hover {
    background-color: #D2C647;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  color: #424242;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 40px;
  border: 2px solid #424242;
  `;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const UploadGrid = styled(Grid)`
  background-color: #E0E0E0;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const SupplierTextField = styled(TextField)`

`;

export const TopGrid = styled(Grid)`
    & > * {
      width: calc(50% - 0.75rem);
      margin: 0 1.5rem 1.5rem 0;

      :nth-child(2n) {
        margin-right: 0;
      }
    }
`;

export const MiddleGrid = styled(Grid)`
    & > * {
      width: 100%;
      margin: 0 0 1.5rem;
    }
`;

export const BottomGrid = styled(Grid)`
    & > * {
      width: calc(33.33% - 1rem);
      margin: 0 1.5rem 1.5rem 0;

      :last-child {
        margin-right: 0;
      }
    }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;
