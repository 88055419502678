import {
  Grid, Typography, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const AffiliateGridContainer = styled(Grid)`
  padding: 30px;
`;

export const AffiliatesTab = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #CCCCCC;
  border-bottom: 2px solid #CCCCCC;
  margin-top: 50px;
  margin-bottom: 30px;

  @media(min-width: 992px) {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
`;

export const AffiliatesNumber = styled(Typography)`
  font-size: 22px;
  color: #424242;
  font-weight: 600;
  padding: 35px 0px;

  @media(min-width: 992px) {
    font-size: 20px;
    padding: 30px 0px;
  }
`;

export const IconsGridContainer = styled(Grid)`
  justify-content: flex-end;
`;

export const AffiliateCardsContainer = styled(Grid)`
  display:flex;
  @media(min-width: 992px) {
    min-height: calc(100vh - 470px);
  }
`;

export const AffiliateCardGrid = styled(Grid)`
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 30px;

  @media(max-width: 960px) {
    margin-bottom: 10px;
  }
`;

export const OrderCountText = styled(Typography)`
  font-size: 22px;
  color: #4D4F5C;
  margin: 14px 0;
  margin-bottom: 20px;
  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const TabIcon = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
`;

export const TabImage = styled('img')`
  width: 26px;
`;
