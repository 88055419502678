import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const ProductContainer = styled(Grid)`
  flex-wrap:wrap;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
  margin: 0 2.5rem;
  justify-content: space-between;
  border-bottom: 1px solid #F0F0F0;
`;

export const ProductDetailBrandNameText = styled(Typography)`
  font-size: .8rem;
  font-weight: 600;
  margin-bottom: 7px;
`;

export const ProductDetailQty = styled(Typography)`
  color: ${({ received }) => (received ? '#424242' : '#A3A3A3')} ;
`;

export const ProductDetailPackSizeText = styled(Typography)`
  font-size: .7rem;
  font-weight: 500;
`;

export const ProductDetail = styled(Grid)`
`;
