import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';

import { GET_PERMISSIONS } from '../../../../queries/user';
import { UPDATE_ROLE_BUSINESS_USER_AND_PERMISSIONS } from '../../../../mutations/auth';
import { JSONParse } from '../../../../utils/json';
import CustomTableSearchField from '../../../shared/customTableSearchField';
import {
  HeaderWrapper, MainPaper, ContactGrid, HeaderText, LightDivider,
  ContactsGrid, CustomCheckbox, UpdateButton, UpdateButtonText, SearchGrid
} from './userInfo.styles';

const initialState = {
  permissions: [],
  searchText: '',
};

const UserPermissions = ({ businessUser }) => {
  const [state, setState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const { searchText } = state;

  useLayoutEffect(() => {
    if (searchText && searchText.length >= 3) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handlePermissions = (event) => {
    const { name, checked } = event.target;
    const perms = state.permissions;
    const idx = perms.findIndex((perm) => perm.name === name);
    if (idx !== -1) {
      perms.splice(idx, 1, { ...perms[idx], allowed: checked });
    }
    setState({ ...state, permissions: perms });
  };

  const [updateRolePermissionMutation] = useMutation(UPDATE_ROLE_BUSINESS_USER_AND_PERMISSIONS);

  const handlePermissionsUpdate = () => {
    const perms = [];
    state.permissions.forEach((perm) => {
      if (perm.allowed) perms.push(+perm.id);
    });
    updateRolePermissionMutation({
      variables: {
        businessUserId: +businessUser.id,
        roleId: +businessUser.businessRole.role.id,
        permissions: perms
      }
    }).then(({ data: { updateBusinessUserRolePermission: { message } } }) => {
      toast.success(message);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const {
    loading, error, data
  } = useQuery(GET_PERMISSIONS, {
    fetchPolicy: 'cache-and-network',
    variables: { searchValue },
  });

  const simpleName = (name) => {
    const newName = name.split('_').join(' ').toLowerCase();
    return newName.charAt(0).toUpperCase() + newName.slice(1);
  };

  useEffect(() => {
    if (!loading && data && data.permissions.length) {
      const { permissions } = data;
      const { permissions: userPermissions } = businessUser;
      const userPermArray = JSONParse(userPermissions);

      const newPerms = permissions.map((perm) => {
        if (Array.isArray(userPermArray) && userPermArray.includes(Number(perm.id))) {
          return { ...perm, code: simpleName(perm.name), allowed: true };
        }
        return { ...perm, code: simpleName(perm.name), allowed: false };
      });
      setState({ ...state, permissions: newPerms });
    }
  }, [loading, data]);

  if (loading) return 'loading...';
  if (error) return error;

  return (
    <HeaderWrapper container spacing={5}>
      <ContactGrid item xs={12} md={12}>
        <MainPaper elevation={2} style={{ height: '20rem' }}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <HeaderText variant="h6" align="left">
              User Permissions
            </HeaderText>
            <SearchGrid item container xs={6}>
              <CustomTableSearchField
                searchText={searchText}
                placeholder="Search permissions"
                handleChange={(e) => handleSearch(e.target.value)}
              />
              <UpdateButton onClick={handlePermissionsUpdate}>
                <UpdateButtonText>Update</UpdateButtonText>
              </UpdateButton>
            </SearchGrid>
          </Grid>
          <LightDivider light />
          <ContactsGrid item direction="column" style={{ marginTop: '2rem' }}>
            <Grid container>
              {state.permissions.map((perm) => (
                <Grid item md={4} key={perm.id}>
                  <FormControlLabel
                    control={<CustomCheckbox checked={perm.allowed} onChange={handlePermissions} name={perm.name} />}
                    label={perm.code}
                  />
                </Grid>
              ))}
            </Grid>
          </ContactsGrid>
        </MainPaper>
      </ContactGrid>
    </HeaderWrapper>
  );
};

UserPermissions.propTypes = {
  businessUser: PropTypes.instanceOf(Object).isRequired,
};

export default UserPermissions;
