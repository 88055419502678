import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { JSONParse } from '../../../../utils/json';
import SmallCard from './smallCard';
import UserPlaceholder from '../../../../assets/images/userplaceholder.png';
import TotalOrderIcon from '../../../../assets/images/order/Total Orders Icon.png';
import GrossOrderIcon from '../../../../assets/images/order/Gross Order Value Icon.png';
import ActiveIcon from '../../../../assets/images/order/Active Orders Icon.png';
import currencyFormatter from '../../../../utils/currencyFormatter';
import {
  HeaderWrapper, MainPaper, HeaderText, LightDivider,
  SmallHeading, ContactsGrid, NormalText2, UserIcon
} from './userInfo.styles';

const UserInfo = ({ info }) => {
  const {
    id, businessRole, meta, user, saleSet
  } = info;
  const { role } = businessRole;
  const {
    profile, contacts, dateCreated
  } = user;
  const { job_title: jobTitle, starting_date: startingDate } = JSONParse(meta);
  const { last_name: lastName, first_name: firstName } = JSONParse(profile);
  const { email, mobile_number: mobile } = JSONParse(contacts);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const saleTotal = saleSet.reduce((cum, sale) => cum + sale?.total, 0);

  const stats = [
    {
      id: 1, icon: TotalOrderIcon, title: 'Role', value: role.name || 'N/A'
    },
    {
      id: 2, icon: ActiveIcon, title: 'Customers Added', value: 'N/A'
    },
    {
      id: 3, icon: TotalOrderIcon, title: 'Total Transactions', value: saleSet.length || 'N/A'
    },
    {
      id: 4, icon: GrossOrderIcon, title: 'Total Sale Amount', value: currencyFormatter(saleTotal) || 'N/A'
    }
  ];

  const date = moment(dateCreated).format('DD/MM/YYYY');

  return (
    <HeaderWrapper container spacing={5}>
      <Grid item xs={12} md={7}>
        <MainPaper elevation={2} square style={{ height: '26rem' }}>
          <HeaderText variant="h6" align="left">
            Personal Information
          </HeaderText>
          <LightDivider light />
          <ContactsGrid item direction="column">
            <Grid container item>
              <Grid item md={4}>
                <SmallHeading variant="caption">First Name</SmallHeading>
                <NormalText2>{firstName || 'N/A'}</NormalText2>
              </Grid>
              <Grid item md={4}>
                <SmallHeading variant="caption">Last Name</SmallHeading>
                <NormalText2>{lastName || 'N/A'}</NormalText2>
              </Grid>
              <Grid item md={4}>
                <SmallHeading variant="caption">User ID</SmallHeading>
                <NormalText2>{id || 'N/A'}</NormalText2>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={4}>
                <SmallHeading variant="caption">Created On</SmallHeading>
                <NormalText2>{date || 'N/A'}</NormalText2>
              </Grid>
              <Grid item md={4}>
                <SmallHeading variant="caption">Starting Date</SmallHeading>
                <NormalText2>{startingDate || 'N/A'}</NormalText2>
              </Grid>
              <Grid item md={4}>
                <SmallHeading variant="caption">Job Title</SmallHeading>
                <NormalText2>{jobTitle || 'N/A'}</NormalText2>
              </Grid>
            </Grid>
          </ContactsGrid>
          <HeaderText variant="h6" align="left">
            Contact Information
          </HeaderText>
          <LightDivider light />
          <ContactsGrid item direction="column">
            <Grid container item justifyContent="space-between">
              <Grid item md={4}>
                <SmallHeading variant="caption">Email</SmallHeading>
                <NormalText2>{email || 'N/A'}</NormalText2>
              </Grid>
              <Grid item md={4}>
                <SmallHeading variant="caption">Mobile</SmallHeading>
                <NormalText2>{mobile || 'N/A'}</NormalText2>
              </Grid>
            </Grid>
          </ContactsGrid>
        </MainPaper>
      </Grid>
      <Grid item container xs={12} md={5} direction="column">
        <Grid item container justifyContent="center">
          <UserIcon alt="user icon" src={UserPlaceholder} />
        </Grid>
        <Grid item container spacing={2} style={{ marginTop: '.5rem' }}>
          {stats.map((stat) => (
            <SmallCard key={stat.id} stat={stat} admin />
          ))}
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

UserInfo.propTypes = {
  info: PropTypes.instanceOf(Object).isRequired,
};

export default UserInfo;
