import {
  Grid, Box, Typography, Button, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem;
  justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 1.8rem;
  }
`;

export const DatePicker = styled(Grid)`
  margin: 20px 50px;
  margin-bottom: 0px;

  @media(min-width: 992px) {
    margin: 0px;
    margin-right: 15px;
  }
`;

export const TextFieldBox = styled(Box)`
  margin-bottom: 2rem;
  width: 100%;
  display: block;
`;

export const FilterGrid = styled(Grid)`
  margin-bottom: 1.5rem;
`;

export const FilterText = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
`;

export const MenuButton = styled(Button)`
`;

export const ClearText = styled(Typography)`
  color: #BA1A1A;
  font-size: 3rem;
  font-weight: 500;
  text-align: right;
`;

export const SetButton = styled(Button)`
`;

export const ArrowImg = styled('img')`
`;

export const CTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 2rem;
  }
`;
