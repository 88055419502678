import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatusesGrid = styled(Grid)`
  padding: 0.5rem 1.5rem;
  align-items: center;
  margin-bottom: .8rem;
  padding: 0 1rem;

`;
