import React, { useState } from 'react';
import Hidden from '@mui/material/Hidden';
import AffiliateDropdown from './uploadAffiliate/affiliateCreatePopper';
import AffiliatecsvDialog from './uploadAffiliate/affiliatecsvDialog';
import IndividualAffiliateDialog from './uploadAffiliate/individual/individualAffiliateDialog';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderButton, OrderButtonText, PlusIcon, ButtonsGrid,
} from './titleGrid.styles';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';

import { useStateValue } from '../../providers/stateProvider';

export default function TitleGrid() {
  const [affiliateButton, setAffiliateLogoEl] = useState(null);
  const [csvUploadDialog, setCsvUploadDialog] = useState(false);

  const [{
    affiliate: { addAffiliateDialogOpen }
  }, dispatch] = Object.values(useStateValue());

  const toggleIndividualDialog = () => {
    dispatch({ type: AffiliateActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const handleAffiliateButtonClick = (event) => {
    setAffiliateLogoEl(affiliateButton ? null : event.currentTarget);
  };
  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle>
          Affiliates
        </PrimaryTitle>
        <SecondaryTitle>
          Affiliates signed up to the portal
        </SecondaryTitle>
      </TitleTextGridContainer>
      <ButtonsGrid item>
        <OrderButton onClick={handleAffiliateButtonClick}>
          <Hidden smDown>
            <PlusIcon />
          </Hidden>
          <OrderButtonText>
            Add Affiliate
          </OrderButtonText>
        </OrderButton>
      </ButtonsGrid>

      <AffiliateDropdown
        affiliateButton={affiliateButton}
        handleAffiliateButtonClick={handleAffiliateButtonClick}
        csvDialogHandler={setCsvUploadDialog}
        individualDialogHandler={toggleIndividualDialog}
      />

      <AffiliatecsvDialog
        dialogOpen={csvUploadDialog}
        closeDialog={() => setCsvUploadDialog(false)}
      />

      <IndividualAffiliateDialog
        dialogOpen={addAffiliateDialogOpen}
        closeDialog={toggleIndividualDialog}
      />

    </TitleGridContainer>
  );
}
